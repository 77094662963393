import { IWordPressArticle, IWordPressTerm } from 'types/Article'

import { ArticlePrefix, sources } from '../$article-api/sources'
import Logger from '../Logger'
import { Fetcher } from './Fetcher'

export class GoingMoreApi {
  public static GetPostsByTagSlug = async (entityId?: string) => {
    if (!entityId) return

    try {
      const tagData = await Fetcher.get<IWordPressTerm[]>(
        `${sources[ArticlePrefix.GOING]}wp-json/wp/v2/tags?slug=${entityId}`
      )

      if (tagData?.length) {
        const { id } = tagData[0]

        return await Fetcher.get<IWordPressArticle[]>(
          `${sources[ArticlePrefix.GOING]}wp-json/wp/v2/posts?tags=${id}&_embed`
        )
      }
    } catch (error) {
      Logger.error('GetPostsByTagSlug', error)
    }
  }
}
