import * as React from 'react'

import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material'

import { Form } from 'components/_forms/Form'
import { RadioGroupInput } from 'components/_forms/RadioGroupInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { ActionLoading } from 'components/ticketManagement/TicketAction/ActionLoading'
import { useReturn } from 'components/ticketManagement/TicketAction/Return/useReturn'
import { Styled } from 'components/ticketManagement/TicketAction/TicketAction.styles'
import { ITicketActionsProps } from 'components/ticketManagement/TicketAction/TicketAction.types'
import { TicketRefundOptions } from 'services/Api/TicketManagementApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const Return = ({ actionKey, email, code, ticketIndex }: ITicketActionsProps) => {
  const { i18n } = useDictionary()
  const { activeStep, control, handleBack, handleSubmit, isLoading } = useReturn(
    code,
    email,
    parseInt(ticketIndex),
    actionKey
  )

  const submitLabel = () => {
    switch (activeStep) {
      case 1:
        return i18n.buttons.confirm
      case 2:
        return i18n.buttons.done
      default:
        return i18n.buttons.next
    }
  }

  return (
    <Styled.Root>
      <Stepper activeStep={activeStep}>
        {i18n.ticketManagement.myTicket.steps.map((label, index) => (
          <Step key={label} completed={activeStep > index || activeStep === 2}>
            <StepLabel children={label} />
          </Step>
        ))}
      </Stepper>
      <Styled.Body>
        {isLoading ? (
          <ActionLoading actionKey={actionKey} hideText={activeStep !== 1} />
        ) : /** STEP ① : START */ activeStep === 0 ? (
          <Form.Wrapper sx={{ flex: 'inherit' }}>
            <Typography variant={'h5'} children={i18n.ticketManagement.returnTicket.formTitle} />
            <RadioGroupInput
              name={FormFields.REFUNDTYPE}
              defaultValue={TicketRefundOptions.VOUCHER_GOING}
              control={control}
              options={[
                {
                  label: i18n.ticketManagement.returnTicket[TicketRefundOptions.VOUCHER_GOING],
                  value: TicketRefundOptions.VOUCHER_GOING,
                },
                {
                  label: i18n.ticketManagement.returnTicket[TicketRefundOptions.VOUCHER_EMPIK],
                  value: TicketRefundOptions.VOUCHER_EMPIK,
                },
                {
                  label: i18n.ticketManagement.returnTicket[TicketRefundOptions.TRANSFER],
                  value: TicketRefundOptions.TRANSFER,
                },
              ]}
            />
          </Form.Wrapper>
        ) : /** STEP ② : CONFIRM */ activeStep === 1 ? (
          <Styled.Content>
            <Typography
              variant={'h5'}
              mb={4}
              children={i18n.ticketManagement.myTicket.actions[actionKey].prompt}
            />
          </Styled.Content>
        ) : (
          /** STEP ③ : SUCCESS */ <Styled.Content>
            <Typography variant={'h5'} mb={4} children={i18n.ticketManagement.myTicket.thanks} />
            <Typography
              variant={'body2'}
              color={'text.secondary'}
              children={i18n.ticketManagement.myTicket.actions[actionKey].success}
            />
          </Styled.Content>
        )}
        <Styled.Footer>
          <Button
            variant={'outlined'}
            children={i18n.buttons.back}
            onClick={handleBack}
            disabled={activeStep !== 1 || isLoading}
          />
          <Button
            disabled={isLoading}
            variant={'contained'}
            type={'submit'}
            children={submitLabel()}
            onClick={handleSubmit}
          />
        </Styled.Footer>
      </Styled.Body>
    </Styled.Root>
  )
}
