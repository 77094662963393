import { Filter } from 'lucene-query-builder'
import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

export const initialState: Filter[] = []

export const variousFiltersState = atom({
  default: initialState,
  key: RecoilKeys.variousFilters,
})
