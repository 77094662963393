import { connect } from 'react-redux'

import _Store from '@Store'

import { isBrowser } from 'models/__base__/selectors'

import EmbedFrame from './EmbedFrame.component'
import { IEmbedFrameFromState, IEmbedFrameOwnProps } from './EmbedFrame.types'

const mapStateToProps = (state: _Store.IState): IEmbedFrameFromState => ({
  isBrowser: isBrowser(state),
})

export default connect<IEmbedFrameFromState, void, IEmbedFrameOwnProps, _Store.IState>(
  mapStateToProps
)(EmbedFrame)
