import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { informAboutErrorModal, resetError } from 'models/errors/actions'
import {
  getLink,
  getLinkLabel,
  getMessage,
  getPath,
  getTitle,
  isError,
} from 'models/errors/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import { buyAndPayOnline } from 'models/transaction/actions'
import { getUserData } from 'models/transaction/selectors'

import ErrorModal from './ErrorModal.component'
import { IErrorModalFromDispatch, IErrorModalFromState } from './ErrorModal.types'

const mapStateToProps = (state: _Store.IState): IErrorModalFromState => ({
  client: getIframeParams(state)?.client || '',
  isEmbed: !!getIframeParams(state),
  message: getMessage(state),
  path: getPath(state),
  show: isError(state),
  userData: getUserData(state),
  errorLink: getLink(state),
  errorMessageLinkLabel: getLinkLabel(state),
  errorTitle: getTitle(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IErrorModalFromDispatch => ({
  buyAndPayOnline: (data) => dispatch(buyAndPayOnline(data)),
  close: () => dispatch(resetError()),
  informAboutErrorModal: () => dispatch(informAboutErrorModal()),
})

export default connect<IErrorModalFromState, IErrorModalFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(ErrorModal)
