import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import sectionComponentFontColor from 'misc/helpers/theme/sectionComponentFontColor'

const useStyles = (color?: string, isDark?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      fontColor: {
        color: sectionComponentFontColor(theme, color, isDark),
      },
    })
  )

export default useStyles
