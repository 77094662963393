import { connect } from 'react-redux'

import _Store from '@Store'

import TourList from 'components/Bricks/components/TourListBrick/TourList.component'
import { ITourListFromState } from 'components/Bricks/components/TourListBrick/TourList.types'
import { getCompositionIdentifier } from 'models/pages/selectors'

const mapStateToProps = (state: _Store.IState): ITourListFromState => ({
  compositionIdentifier: getCompositionIdentifier(state),
})

export default connect(mapStateToProps)(TourList)
