import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import sectionComponentFontColor from 'misc/helpers/theme/sectionComponentFontColor'

const useStyles = (color?: string, isBgDark?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.paper,
      },
      secondary: {
        color: theme.palette.text.secondary,
      },
      // tslint:disable-next-line:object-literal-sort-keys
      fontColor: {
        color: sectionComponentFontColor(theme, color, isBgDark),
      },
    })
  )

export default useStyles
