import React from 'react'

import { Link, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import ReactMarkdown from 'react-markdown'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import useStyles from './MarkdownText.styles'
import { IMarkdownTextProps } from './MarkdownText.types'

export const MarkdownText = ({ color, highlightFirstParagraph, text }: IMarkdownTextProps) => {
  const theme = useTheme()
  const classes = useStyles(color)(theme)
  const { isDesktop } = useBreakpoints()

  if (!text) return null

  return (
    <ReactMarkdown
      className={cn(
        classes.fontColor,
        highlightFirstParagraph
          ? isDesktop
            ? classes.firstParagraph
            : cn(classes.firstParagraph, classes.firstParagraphMobile)
          : ''
      )}
      components={{
        a: ({ children, href, target, rel }) => (
          <Link
            children={children}
            href={href}
            target={target}
            rel={rel}
            className={classes.fontColor}
          />
        ),
        h1: (props) => <Typography variant={'h1'} children={props.children} />,
        h2: (props) => <Typography variant={'h2'} children={props.children} />,
        h3: (props) => <Typography variant={'h3'} children={props.children} />,
        h4: (props) => <Typography variant={'h4'} children={props.children} />,
        h5: (props) => <Typography variant={'h5'} children={props.children} />,
        h6: (props) => <Typography variant={'h6'} children={props.children} />,
        img: ({ alt, ...props }) => <img alt={alt} className={classes.image} {...props} />,
        li: ({ children, ordered, ...props }) => (
          <li
            className={classes.item}
            {...props}
            children={<Typography variant={'body2'} children={children} />}
          />
        ),
        ol: ({ ordered, ...props }) => <ol className={classes.list} {...props} />,
        p: (props) => <Typography variant={'body2'} children={props.children} />,
        ul: ({ ordered, ...props }) => <ul className={classes.list} {...props} />,
      }}
      children={text}
    />
  )
}
