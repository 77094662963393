export enum Colors {
  CRUSTA = '#fa7b39',
  EMERALD = '#34c36d',
  ERROR = '#f44336',
  HOT_PINK = '#ff4fa3',
  INFO = '#60748b',
  LAVENDER = '#d660ea',
  MALIBU = '#55a2fc',
  MANDY = '#e44e60',
  MORE = '#b7e46d',
  ROBIN_EGG_BLUE = '#08beca',
  SUCCESS = '#4caf50',
}

export const EventCategories = {
  art: 'Sztuka',
  concert: 'Koncert',
  festival: 'Festiwal',
  film: 'Film',
  food: 'Jedzenie',
  impro: 'Stand-up i impro',
  market: 'Targ',
  meeting: 'Spotkanie',
  opera: 'Opera',
  other: 'Inne',
  party: 'Impreza',
  sport: 'Sport',
  theater: 'Teatr',
  workshop: 'Warsztat',
}

export const categoryColors: { [key: string]: string } = {
  [EventCategories.art]: Colors.HOT_PINK,
  [EventCategories.concert]: Colors.MALIBU,
  [EventCategories.festival]: Colors.MALIBU,
  [EventCategories.film]: Colors.MANDY,
  [EventCategories.food]: Colors.EMERALD,
  [EventCategories.impro]: Colors.LAVENDER,
  [EventCategories.market]: Colors.EMERALD,
  [EventCategories.meeting]: Colors.LAVENDER,
  [EventCategories.other]: Colors.ROBIN_EGG_BLUE,
  [EventCategories.opera]: Colors.MANDY,
  [EventCategories.party]: Colors.CRUSTA,
  [EventCategories.sport]: Colors.ROBIN_EGG_BLUE,
  [EventCategories.theater]: Colors.MANDY,
  [EventCategories.workshop]: Colors.LAVENDER,
}
