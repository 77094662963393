import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { EMPTY as EMPTY$, from as from$ } from 'rxjs'
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { getParams } from 'models/internalRouter/selectors'
import { isActionOf } from 'typesafe-actions'

import { mounted } from './../actions'

export const sendMailWhenMounted: _Store.IEpic = (action$, state$, { mailApi }) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as RouteParams<Route.sendMailSuccess>

      return from$(mailApi.sendMail(params.id)).pipe(mergeMap$(() => EMPTY$))
    })
  )
}
