import * as React from 'react'

import { FormHelperText, styled } from '@mui/material'

const Wrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
})

const Row = styled('fieldset')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  width: '100%',
  padding: 0,
  border: 'none',
  marginInline: 0,
  paddingInline: 0,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

/**
 * Form layout components used with react-hook-form. Usage:
 *
 * <Form.Wrapper>
 *   <TextField />
 *   <TextField />
 *   <Form.Row>
 *     <TextField />
 *     <TextField />
 *   </Form.Row>
 *   <Form.TextRow>
 * </Form.Wrapper>
 */
export const Form = {
  /**
   * Form wrapper, creates <form> element.
   */
  Wrapper,
  /**
   * Form row, creates <fieldset> element with flex-row layout.
   */
  Row,
  /**
   * Adds form helper text, which takes up the entire row.
   */
  TextRow: FormHelperText,
}
