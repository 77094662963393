import { ThemeOptions } from '@mui/material'
import colors from 'theme/variants/colors/hangar'

// TODO: add date picker styles to the appropriate component.
// const pickers = {
//   MuiPickersBasePicker: {
//     container: {
//       alignItems: 'flex-start',
//     },
//     pickerView: {
//       borderColor: colors.primary.border,
//       borderStyle: 'solid',
//       borderWidth: 3,
//       boxSizing: 'border-box',
//       maxWidth: '384px',
//       width: '100%',
//       [isTablet]: {
//         maxWidth: '100%',
//       },
//     },
//     pickerViewLandscape: {
//       padding: 0,
//     },
//   },
//   MuiPickersCalendar: {
//     week: {
//       '& > div': {
//         width: '100%',
//       },
//
//       '& > div:last-child .MuiPickersDay-day': {
//         borderRight: 0,
//       },
//       '&:last-child': {
//         borderBottom: 0,
//       },
//       borderBottom: `0.1875rem solid ${colors.primary.border}`,
//       display: 'flex',
//       width: '100%',
//     },
//
//     transitionContainer: {
//       minHeight: 'unset',
//
//       '& > div': {
//         position: 'relative',
//       },
//       '& > div:nth-child(2)': {
//         maxHeight: '0!important',
//         position: 'relative',
//       },
//     },
//   },
//   MuiPickersCalendarHeader: {
//     dayLabel: {
//       margin: 0,
//       width: '100%',
//
//       '&:last-child': {
//         borderRight: 0,
//       },
//     },
//     daysHeader: {
//       borderBottom: `0.1875rem solid ${colors.primary.border}`,
//       maxHeight: 'unset',
//     },
//     switchHeader: {
//       borderBottomWidth: 3,
//       borderColor: colors.primary.border,
//       borderStyle: 'solid',
//       borderWidth: 0,
//       marginBottom: 0,
//     },
//   },
//   MuiPickersDay: {
//     day: {
//       borderRadius: 0,
//       borderRight: `0.1875rem solid ${colors.primary.border}`,
//       height: '100%',
//       margin: 0,
//       paddingTop: '100%',
//       width: '100%',
//
//       '&:hover': {
//         background: colors.primary.main,
//       },
//
//       '& span p': {
//         fontWeight: '700!important',
//       },
//
//       '& span': {
//         left: '50%',
//         position: 'absolute',
//         top: '50%',
//         transform: 'translate( -50%, -50% )',
//       },
//     },
//     daySelected: {
//       '&:hover': {
//         background: colors.primary.dark,
//       },
//       background: colors.primary.main,
//       color: colors.secondary.main,
//     },
//     hidden: {
//       color: colors.text.disabled,
//       opacity: 1,
//     },
//   },
// }

const hangar = (isMobile: string): ThemeOptions => ({
  border: {
    color: colors.primary.border,
    radius: '0',
    style: 'solid',
    width: '0.1875rem',
  },
  buttonSelect: {
    background: colors.primary.main,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          boxShadow: 'none',
        },
        root: {
          height: '6.5rem',
          [isMobile]: {
            height: '4rem',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            border: '0.1875rem solid',
            borderColor: colors.primary.border,
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            background: colors.primary.main,
            color: colors.text.primary,

            '&:disabled': {
              background: colors.actions.disabledBackground,
            },
            '&:hover': {
              background: colors.primary.dark,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            background: colors.secondary.main,

            '&:disabled': {
              background: colors.actions.disabledBackground,
            },
            '&:hover': {
              background: colors.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: colors.primary.border,
            borderWidth: 3,

            '&:disabled': {
              borderColor: colors.actions.disabled,
            },
            '&:hover': {
              background: colors.primary.main,
              borderWidth: 3,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            borderColor: colors.secondary.border,
            borderWidth: 3,

            '&:disabled': {
              borderColor: colors.actions.disabled,
              borderWidth: 3,
            },
            '&:hover': {
              backgroundColor: colors.secondary.background,
              borderColor: colors.secondary.border,
              borderWidth: 3,
            },
          },
        },
        {
          props: { variant: 'outlined', size: 'large' },
          style: {
            borderColor: colors.primary.border,
            borderWidth: 3,
            padding: '0.5rem 1.375rem',
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: 15,
            fontWeight: 700,
            letterSpacing: 0.46,
            lineHeight: '1.625rem',
          },
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: 13,
            fontWeight: 700,
            letterSpacing: 0.46,
            lineHeight: '1.375rem',
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundImage: colors.primary.main,
            display: 'inline-block',
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked:hover': {
            backgroundColor: 'transparent!important',
          },
        },
        root: {
          alignSelf: 'flex-start',
          padding: '0 0.5625rem 0 0',

          '&.MuiIconButton-colorSecondary:hover': {
            backgroundColor: 'transparent',
          },

          '&$checked': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary.main,
          height: 3,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          borderColor: 'green',
          padding: '0 0 0 0.5625rem',
          '&:has(.Mui-checked)': {
            borderImageSlice: 1,
            borderImageSource: colors.primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          boxShadow: '0 0 0 30px white inset !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          backgroundColor: colors.other.white,
          paddingLeft: 4,
          paddingRight: 4,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: '0.1875rem !important',
        },
        root: {
          '&$focused $notchedOutline': {
            borderImageSlice: 1,
            borderImageSource: colors.primary.main,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          height: 24,
          width: 24,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 35,
        },
        root: {
          fontSize: 24,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderColor: colors.primary.border,
          borderRadius: 0,
          borderStyle: 'solid',
          borderWidth: '0.1875rem',
          maxHeight: 'none',
          maxWidth: 396,
          padding: '0 16px',
          paddingBottom: 16,
          width: '100%',

          '& .MuiDayCalendar-monthContainer': {
            paddingTop: 32,
          },

          '& .MuiPickersCalendarHeader-root': {
            padding: 0,
          },

          '& .MuiPickersCalendarHeader-labelContainer': {
            marginLeft: 16,

            '& .MuiPickersCalendarHeader-label': {
              textTransform: 'capitalize',
            },
          },
          '& .MuiPickersSlideTransition-root': {
            minHeight: 292,
            position: 'static',
          },

          '& .MuiDayCalendar-weekDayLabel': {
            color: colors.text.primary,
            fontSize: 14,
            fontWeight: '500',
            width: '3rem',
          },

          '& .MuiPickersDay-root': {
            borderRadius: 0,
            fontSize: 16,
            fontWeight: '500',
            height: '3rem',
            width: '3rem',
            '&.Mui-selected': {
              backgroundColor: colors.primary.background,
            },
          },
        },
      },
    },
  },
  palette: {
    action: {
      disabled: colors.actions.disabledText,
      disabledBackground: colors.actions.disabledBackground,
    },
    background: {
      default: 'transparent',
      paper: 'transparent',
    },
    other: {
      focused: colors.primary.main,
      stroke: colors.other.stroke,
      gray: colors.other.gray,
    },
    primary: {
      contrastText: colors.primary.contrastText,
      dark: colors.primary.dark,
      main: '#000',
    },
    secondary: {
      dark: colors.secondary.dark,
      light: colors.secondary.light,
      main: colors.secondary.main,
    },
    gradient: {
      first: colors.gradient.first,
      second: colors.gradient.second,
    },
  },
  shape: {
    borderRadius: 0,
  },
  spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160, 200],
  typography: {
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '1.5rem',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '1.25rem',
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0.4,
      lineHeight: '1.625rem',
    },
    caption: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0.4,
      lineHeight: '1.25rem',
    },
    fontFamily: [
      'Campton',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    fontWeightBold: 700,

    h1: {
      fontSize: 96,
      fontWeight: 400,
      letterSpacing: -1.5,
      lineHeight: '7rem',
    },
    h2: {
      fontSize: 60,
      fontWeight: 700,
      letterSpacing: -0.5,
      lineHeight: '4.5rem',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: '3.5rem',
    },
    h4: {
      fontSize: 34,
      fontWeight: 700,
      letterSpacing: 0.25,
      lineHeight: '42px',
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '2.625rem',
    },
    h6: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 0.15,
      lineHeight: '2rem',
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1,
      lineHeight: '2rem',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '1.75rem',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0.1,
      lineHeight: '1.375rem',
    },
  },
})

export default hangar
