import { RefObject, useEffect, useState } from 'react'

const useResize = (myRef?: RefObject<HTMLDivElement>) => {
  const getDimensions = () => ({
    height: myRef?.current?.offsetHeight || 0,
    width: myRef?.current?.offsetWidth || 0,
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef?.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}

export default useResize
