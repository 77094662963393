import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import { getRemoteConfig } from 'firebase/remote-config'

import config from 'config'

const app = initializeApp(config.firebase)
const remoteConfig = getRemoteConfig(app)
getAuth(app)
initializeFirestore(app, { ssl: true, host: 'firestore.googleapis.com' })

export { app, remoteConfig }
