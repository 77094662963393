import { pl } from 'lang/pl'
import moment from 'moment'
import { createSelector } from 'reselect'

import config from 'config'
import { getEvent } from 'models/event/selectors'
import {
  getAvailabilities,
  getData,
  getHappeningEvent,
  getHappeningTerms,
  getSelected,
  getSelectedExtendedSlot,
} from 'models/happening/selectors'
import { ISelectionPerPriceType } from 'models/happening/types'
import { getPools, getSeatsGroupedByPoolId, getSelectedTickets } from 'models/pools/selectors'
import { IPoolNormalized } from 'models/pools/types'
import { getSelectedProducts } from 'models/products/selectors'

import { IBasket } from './../types'

const getBasketData = createSelector(
  [
    getEvent,
    getSelectedTickets,
    getPools,
    getSeatsGroupedByPoolId,
    getSelectedProducts,
    getSelected,
    getHappeningEvent,
    getData,
    getHappeningTerms,
    getSelectedExtendedSlot,
    getAvailabilities,
  ],
  (
    event,
    poolsSelected,
    pools,
    seats,
    products,
    selected,
    happening,
    happeningData,
    terms,
    extendedSlot,
    availabilities
  ) => {
    if (event) {
      const getEventProps = (): IBasket[] => {
        if (poolsSelected.length) {
          return poolsSelected.map((poolSelected) => {
            const currentPool =
              pools.find((poolItem) => poolItem.id === poolSelected.poolId) ||
              ({} as IPoolNormalized)

            return {
              additionalFields: currentPool.isDetailedPurchaseRequired,
              amount: poolSelected.amount,
              cityName: event.placeCityName,
              courierRequired: currentPool.courierRequired,
              currency: poolSelected.currency,
              customTerms: event.customTerms,
              eventId: event.eventId,
              hasDependency: currentPool.hasDependency,
              hour: event.friendlyHour,
              id: poolSelected.poolId,
              paymentMethods: currentPool.payments,
              place: event.placeName,
              poolName: currentPool.title,
              poolPrice: currentPool.price,
              pools: [],
              price: currentPool.price
                ? currentPool.price * poolSelected.amount +
                  (currentPool.serviceFee || 0) * poolSelected.amount
                : 0,
              rundate: event.friendlyDate,
              rundateId: event.rundateId,
              seats: seats[poolSelected.poolId],
              serviceFee: currentPool.serviceFee ? currentPool.serviceFee * poolSelected.amount : 0,
              submissions: poolSelected.submissions,
              title: event.title,
              totalPoolPrice: currentPool.price ? currentPool.price * poolSelected.amount : 0,
            }
          })
        }

        const MINUTES_IN_DAY = 1440

        if (selected.selections.length) {
          const rulePriceId =
            availabilities
              .find((availability) => availability.start === selected.slot)
              ?.spaces.find((space) => space.spaceId === selected.space)?.rulePriceId || 0

          const calculateDuration = () => {
            if (selected.selectedExtendedSlot) {
              return selected.selectedExtendedSlot.duration
            }
            if (selected.slot && selected.upsell?.isSelected) {
              const slot = moment(selected.slot, 'HH:mm')
              const upsell = moment(selected.upsell?.endTime, 'HH:mm:ss')
              const calculatedTime = moment.duration(upsell.diff(slot)).asMinutes()
              if (calculatedTime >= 0) {
                return calculatedTime / 2
              } else {
                return (MINUTES_IN_DAY + calculatedTime) / 2
              }
            }
            return undefined
          }

          const getPrice = (item: ISelectionPerPriceType) => {
            if (item.numberOfPlayers && item.priceType?.value) {
              return item.priceType?.value * item.numberOfPlayers
            }
            return selected.totalPrice
          }

          const getUpsellPrice = (item: ISelectionPerPriceType) => {
            if (item.numberOfPlayers) {
              return selected.totalPrice - (item.priceType?.value || 0) * item.numberOfPlayers
            }
            return selected.totalPrice - (item.priceType?.value || 0)
          }
          const selectedSpace = happeningData?.spaces.find((_space) => _space.id === selected.space)
          const SECONDS_IN_MINUTE = 60

          return selected.selections.map((item) => {
            const numberOfPeople =
              (happeningData?.calculatePricePerPerson
                ? item.numberOfPlayers
                : selectedSpace?.maxNumberOfPeople) || 0

            return {
              amount: numberOfPeople,
              calculatePricePerPerson: happeningData?.calculatePricePerPerson,
              currency: config.app.defaultCurrency,
              customTerms: terms,
              date: selected.dayFromSlot,
              duration: selectedSpace?.timeSlot || 0,
              eventId: event.eventId,
              extendedDuration:
                extendedSlot && selectedSpace
                  ? extendedSlot.duration * SECONDS_IN_MINUTE + selectedSpace.timeSlot
                  : undefined,
              extendedUpsell: selected.selectedExtendedSlot || undefined,
              happeningId: happeningData ? happeningData?.id : undefined,
              id: Math.round(Math.random() * 1000),
              isPlaceEvent: true,
              partnerId: happening.partnerId,
              pools: [],
              poolPrice: item.priceType?.value,
              price:
                getPrice(item) +
                getUpsellPrice(item) +
                (selected?.selectionsPerPriceType[0]?.fee || 0),
              priceType: item.priceType || undefined,
              poolName:
                item.priceType?.title === 'default'
                  ? pl.cart.defaultTicket
                  : item.priceType?.title || '',
              rulePriceId,
              slot: selected.slot,
              serviceFee: selected?.selectionsPerPriceType[0]?.fee || 0,
              spaceId: selected.space,
              totalPoolPrice:
                item.numberOfPlayers && item.priceType
                  ? item?.numberOfPlayers * item.priceType?.value
                  : 0,
              title: happening.title,
              upsell: selected.upsell
                ? {
                    ...selected.upsell,
                    price: getUpsellPrice(item),
                  }
                : undefined,
              upsellDuration: calculateDuration(),
            }
          })
        }

        return []
      }

      const getProductProps = (): IBasket[] => {
        return products.map((product) => {
          return {
            amount: product.amount,
            customTerms: [],
            currency: config.app.defaultCurrency,
            eventId: -1,
            id: product.id,
            isProduct: true,
            poolName: product.name,
            poolPrice: product.price,
            pools: [],
            price: product.price * product.amount,
            title: pl.basket.products,
            totalPoolPrice: product.price * product.amount,
          }
        })
      }
      return [...getEventProps(), ...getProductProps()]
    }

    return []
  }
)

export default getBasketData
