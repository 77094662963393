import { connect } from 'react-redux'

import _Store from '@Store'

import { getSelected, getSpaces } from 'models/happening/selectors'

import Spaces from './Spaces.component'
import { ISpacesFromState } from './Spaces.types'

const mapStateToProps = (state: _Store.IState): ISpacesFromState => {
  return {
    spaces: getSpaces(state),
    selected: getSelected(state),
  }
}

export default connect<ISpacesFromState, void, {}, _Store.IState>(mapStateToProps)(Spaces)
