import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'

const useStyles = (isEmbed: boolean, embedDialogPosition: number) => {
  const { isDesktop } = useBreakpoints()

  return makeStyles((theme: Theme) =>
    createStyles({
      actions: {
        justifyContent: 'center',
        padding: '2rem',
        paddingTop: 0,
      },
      content: {
        padding: theme.spacing(4),

        '&:first-child': {
          paddingTop: theme.spacing(4),
        },
      },
      root: {
        ...(isEmbed && {
          '& .MuiDialog-paper': {
            position: 'fixed',
            top: `calc(${embedDialogPosition}px + ${isDesktop ? 15 : 10}vh)`,
          },
        }),
        '&.MuiModal-backdrop': {
          backgroundColor: 'yellow !important',
        },
      },
      paper: {
        backgroundColor:
          theme.palette.background.default !== 'transparent'
            ? theme.palette.background.default
            : '#ffffff',
      },
    })
  )
}

export default useStyles
