import {
  ITimelineBrickOwnProps,
  ITimelineExtra,
} from 'components/Bricks/components/TimelineBrick/TimelineBrick.types'
import { ITimelineMarker } from 'components/reusable/Timeline/Timeline.types'
import { ISectionsComponentDataMutable } from 'models/pages/types/sections'

export const timeline = (
  data: ISectionsComponentDataMutable,
  extra?: ITimelineExtra | null,
  background?: string
): ITimelineBrickOwnProps => {
  const timelineData = data as ITimelineMarker[]

  return {
    background,
    color: extra?.color,
    timeline: timelineData,
  }
}
