import React, { FC } from 'react'

import ReadMore from 'components/reusable/ReadMore'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import { useAppState } from 'state/app/hooks/useAppState'

import { IReadMoreBrickProps } from './ReadMoreBrick.types'

const ReadMoreBrick: FC<IReadMoreBrickProps> = ({
  color,
  id,
  isLoading,
  link,
  text,
  background,
  compositionIdentifier,
}) => {
  const { isDarkTheme } = useAppState()
  const bgColor = isDarkTheme && background ? invertIfGrayScaleColor(background) : background
  const isBgDark = isColorDark(background) || isColorDark(bgColor)

  return (
    <ReadMore
      id={id}
      link={link}
      isLoading={isLoading}
      text={text}
      isBgDark={isBgDark}
      color={color}
      compositionIdentifier={compositionIdentifier}
    />
  )
}

export default ReadMoreBrick
