import { Link, styled } from '@mui/material'

export const Styled = {
  Root: styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  })),
  SocialLink: styled(Link)(({ theme, href }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: !!href ? theme.palette.text.primary : theme.palette.text.disabled,
  })),
}
