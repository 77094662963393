import { ComponentType } from 'react'

import { Button, ButtonProps, Paper, PaperProps, styled } from '@mui/material'

export const Styled = {
  Button: styled(Button)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: 12,
    paddingBottom: 12,
    textTransform: 'unset',

    [theme.breakpoints.up('md')]: {
      minWidth: 164,
    },
  })) as ComponentType<ButtonProps>,

  Paper: styled(Paper)(({ theme }) => ({
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: 16,
    marginBottom: theme.spacing(4),
  })) as ComponentType<PaperProps>,
}
