import { createSelector } from 'reselect'

import _Store from '@Store'

import { ICustomTerm } from 'types/EventCommon'

import { getEvent } from './../selectors'
import { IEventFull } from './../types'

export const getCustomTerms = createSelector<_Store.IState, IEventFull | null, ICustomTerm[]>(
  [getEvent],
  (event) => {
    if (event?.customTerms) {
      return event.customTerms
    }

    return []
  }
)

export default getCustomTerms
