import { connect } from 'react-redux'

import _Store from '@Store'

import {
  ICtaButtonsBrickOwnProps,
  ICtaButtonsFromState,
} from 'components/Bricks/components/CtaButtonsBrick/CtaButtonsBrick.types'
import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import CtaButtonsBrick from './CtaButtonsBrick.component'

const mapStateToProps = (state: _Store.IState) => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  }
}

export default connect<ICtaButtonsFromState, null, ICtaButtonsBrickOwnProps, _Store.IState>(
  mapStateToProps
)(CtaButtonsBrick)
