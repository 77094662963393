import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'

import { IReturnAccountBankFormInput } from 'components/ReturnAccountBank/ReturnAccountBank.types'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { useNotify } from 'components/_functional/NotificationProvider'
import { useToggle } from 'misc/hooks/useToggle'
import { ReturnBankAccountApi } from 'services/Api/ReturnBankAccountApi'
import Logger from 'services/Logger'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const useReturnAccountBank = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { isVisible, handleHide, handleShow } = useToggle(false)
  const { control, handleSubmit } = useForm<IReturnAccountBankFormInput>()
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { notify } = useNotify()
  const { hash } = useParams<RouteParams<Route.returnBankAccount>>()

  const validationRules = {
    [FormFields.BANKACCOUNTNUMBER]: validators.requiredString(
      i18n.forms.validation[FormFields.BANKACCOUNTNUMBER].required
    ),
  }

  const redirectOnSuccess = () => {
    navigate(routes[Route.index])
    handleHide()
  }

  const onSubmit = handleSubmit(async (values) => {
    if (hash) {
      setIsLoading(true)

      try {
        const res = await ReturnBankAccountApi.saveBankAccountNumber(hash, {
          bankAccount: values[FormFields.BANKACCOUNTNUMBER],
        })

        if (!res.status) {
          handleShow()
        } else notify({ text: res.error, alertColor: 'error' })
      } catch (e: any) {
        notify({ text: e.message, alertColor: 'error' })

        Logger.error('useReturnAccountBank.onSubmit', e)
      } finally {
        setIsLoading(false)
      }
    }
  })

  return {
    isLoading,
    handleSubmit: onSubmit,
    control,
    validationRules,
    isVisible,
    redirectOnSuccess,
  }
}
