import { ComponentType, ImgHTMLAttributes } from 'react'

import { Box, BoxProps, Skeleton, styled } from '@mui/material'
import { To } from '@remix-run/router'

export const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'aspectRatio' && prop !== 'transformation',
  })<{
    aspectRatio: [width: number, height: number]
  }>(({ theme, aspectRatio }) => ({
    aspectRatio: `auto ${aspectRatio[0]} / ${aspectRatio[1]}`,
    '@supports not (aspect-ratio: 1)': {
      '&::before': {
        content: '""',
        display: 'block',
        paddingTop: `${(aspectRatio[1] / aspectRatio[0]) * 100}%`,
      },
    },
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  })) as ComponentType<
    BoxProps & {
      aspectRatio: [width: number, height: number]
      to?: To // This is here because 'to' prop is not being recognized as a valid 'div' prop
    }
  >,
  Image: styled('img', {
    shouldForwardProp: (prop) => prop !== 'isLoading',
  })<{ isLoading: boolean }>(({ isLoading }) => ({
    display: isLoading ? 'none' : 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  })) as ComponentType<ImgHTMLAttributes<HTMLImageElement> & { isLoading: boolean }>,
  Skeleton: styled(Skeleton)({ display: 'flex', flex: 1, width: '100%', height: '100%' }),
}
