import { IBasket } from 'models/basket/types'
import { ICustomTerm } from 'types/EventCommon'

const getBasketTerms = (items: IBasket[]) => {
  const basketCustomTerms: ICustomTerm[] = []

  items.forEach((item) => item.customTerms.forEach((term) => basketCustomTerms.push(term)))

  return basketCustomTerms
}

export default getBasketTerms
