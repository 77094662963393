import React from 'react'

import { PowerSettingsNew } from '@mui/icons-material'
import { Button } from '@mui/material'
import { getAuth } from 'firebase/auth'

import { useUser } from 'components/_functional/UserContextProvider'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const LogoutButton = () => {
  const { i18n } = useDictionary()
  const { loading, resetForm } = useUser()

  const onLogout = async () => {
    loading.start()
    resetForm()
    await getAuth().signOut()
    loading.done()
  }

  return (
    <Button
      variant={'contained'}
      color={'error'}
      onClick={onLogout}
      children={i18n.auth.logout}
      startIcon={<PowerSettingsNew />}
      fullWidth
    />
  )
}
