import axios, { CancelTokenSource } from 'axios'
import getYouTubeId from 'get-youtube-id'
import { pl } from 'lang/pl'

import config from 'config'
import EventsListService from 'misc/classes/EventsListService'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import translatableDate from 'misc/helpers/translatableDate'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import { IEventFull } from 'models/event/types'
import { URI } from 'services/URI'
import { IRundateResponse } from 'types/EventRundate'
import { IDateTranslate } from 'types/Locale'

class EventsApi extends EventsListService {
  public cancelTokenEvent?: CancelTokenSource

  public makeKeyFromParams(params: { eventSlug: string; rundateSlug: string }) {
    return `${params.eventSlug}/${params.rundateSlug}`
  }

  public getSingleEvent(eventSlug: string, rundateSlug?: string): Promise<IRundateResponse> {
    return new Promise((resolve, reject) => {
      const eventUrl = rundateSlug
        ? URI.GetEventRundate(eventSlug, rundateSlug)
        : URI.GetEventActivity(eventSlug)

      this.cancelTokenEvent = axios.CancelToken.source()

      axios
        .get(
          eventUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenEvent.token,
            headers: EventsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IRundateResponse) => {
          resolve(response)
        })
        .catch((error) => {
          reject(catchHttpError(error))
        })
    })
  }

  public getEventById(eventId: number): Promise<IRundateResponse> {
    return new Promise((resolve, reject) => {
      const eventUrl = `${config.api.baseUrl}events/rundates/${eventId}`

      this.cancelTokenEvent = axios.CancelToken.source()

      axios
        .get(
          eventUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenEvent.token,
            headers: EventsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IRundateResponse) => {
          resolve(response)
        })
        .catch((error) => {
          reject(catchHttpError(error))
        })
    })
  }

  public normalizeFullEvent(
    rundateResponse: IRundateResponse,
    dateTranslate: IDateTranslate = pl.dates
  ): IEventFull {
    const {
      buttonLabel,
      changeMonitorType,
      customTerms,
      currency,
      endDate,
      partner,
      event: {
        artists,
        category,
        externalImages,
        formattedDescription,
        formattedPartnerDescription,
        id: eventId,
        images,
        partner: eventPartner,
        rundatesCount,
        slug: eventSlug,
        tags,
        teaser,
        titlePL,
      },
      externalImages: rundateExternalImages,
      freeOfCharge,
      hasManyPools,
      hourDescription,
      id: rundateId,
      isAvailable,
      isForPremiumUsers,
      notForSale,
      passed,
      place: {
        address: placeAddress,
        category: placeCategory,
        city: { name: placeCityName, slug: placeCitySlug },
        description: placeDescription,
        id: placeId,
        lat: placeLat,
        lon: placeLon,
        name: placeName,
        slug: placeSlug,
        thumb,
      },
      premiumAuthUrl,
      price,
      priceWithServiceFee,
      priceDescriptionPL,
      redirectToUrl,
      rundateDescription,
      shipmentAllowed,
      slug: rundateSlug,
      startDate,
      queue,
    } = rundateResponse
    const {
      id: partnerId,
      name: partnerName,
      gtmId,
      transferAmount: partnerTransferAmount,
      transferPercent: partnerTransferPercent,
    } = eventPartner
    const startDateString = EventsApi.getDateString(startDate)

    return {
      additionalDescription: formattedPartnerDescription ? formattedPartnerDescription : '',
      artists,
      buttonLabel,
      calendarEvent: false,
      category,
      changeMonitorType,
      courierDelivery: shipmentAllowed,
      customTerms,
      currency,
      description: formattedDescription ? formattedDescription : '',
      endDate: EventsApi.getDateString(endDate),
      eventId,
      eventSlug,
      externalImages,
      freeOfCharge,
      friendlyDate: translatableDate(startDateString, rundateDescription, dateTranslate),
      friendlyHour: EventsApi.getFriendlyHour(startDateString, rundateDescription, hourDescription),
      gtmId: partner?.gtmId || gtmId,
      hasManyPools,
      hourDescription,
      imagesUrl: EventsApi.getImageUrl(images),
      isAvailable,
      isPremiumEvent: isForPremiumUsers,
      notForSale,
      partnerId,
      partnerInfo: partner || eventPartner,
      partnerName,
      partnerTransferAmount,
      partnerTransferPercent,
      passed,
      placeAddress,
      placeCategory: EventsApi.normalizeEventPlaceTags(placeCategory),
      placeCityName,
      placeCitySlug: placeCitySlug ? placeCitySlug : '',
      placeDescription,
      placeFriendly: EventsApi.getFriendlyPlace(rundateResponse.place),
      placeId,
      placeImageUrl: thumb,
      placeLat,
      placeLon,
      placeName,
      placeSlug,
      premiumAuthUrl,
      price: getPriceFormatted(price),
      priceWithServiceFee: getPriceFormatted(priceWithServiceFee),
      priceDescription: EventsApi.getPriceDescription(priceDescriptionPL),
      queue,
      redirectToUrl,
      rundateDescription,
      rundateId,
      rundateSlug,
      rundatesCount,
      startDate: startDateString,
      tags: [category, ...tags],
      teaser,
      title: titlePL,
      videoId: teaser?.length ? getYouTubeId(teaser) : null,
    }
  }

  public cancelEvent() {
    if (this.cancelTokenEvent) {
      this.cancelTokenEvent.cancel()
      this.cancelTokenEvent = undefined
    }
  }

  public getCanBuyTicket(event: IEventFull | null) {
    return event?.isAvailable ?? false
  }
}

export { EventsApi }
export default new EventsApi()
