import React, { useEffect } from 'react'

import { Update } from '@mui/icons-material'
import { Button, Typography, useTheme } from '@mui/material'
import moment from 'moment'

import { Flex } from 'components/_layout/Flex'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import useUpdateEffect from 'misc/hooks/useUpdateEffect'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './Upsell.styles'
import { IUpsellProps } from './Upsell.types'

const Upsell = ({
  calculatePrice,
  isSelectedSlotLastInCurrentDay,
  mounted,
  selected,
  toggleUpsellState,
  generateIdempotencyKey,
  happeningSpaces,
}: IUpsellProps) => {
  const { isMobile, isDesktop } = useBreakpoints()
  const { i18n } = useDictionary()
  const upsellFromState = selected.upsell?.isSelected
  const theme = useTheme()

  const calculateUpsellForAllTicketTypes = () =>
    calculatePrice({
      discountCode: selected.discount || undefined,
      upsell: upsellFromState,
    })

  const toggleUpsell = () => {
    toggleUpsellState(!upsellFromState)
    calculateUpsellForAllTicketTypes()
    generateIdempotencyKey()
  }

  const timeBreak =
    (happeningSpaces?.find((item) => item.id === selected.space)?.timeBreak || 0) / 60
  // wyliczenie róznicy czasu między czasem zakończenia slotu a czasem rozpoczęcia upsellu
  const diff = moment
    .duration(moment(selected.upsell?.endTime, 'HH:mm:ss').diff(moment(selected.slot, 'HH:mm:ss')))
    .asMinutes()
  let upsellTime = 0
  // sprawdzenie czy upsell kończy się po północy
  if (diff >= 0) {
    upsellTime = diff / 2
  } else {
    upsellTime = (1440 + diff) / 2
  }
  const isDisabled = selected.space < 1
  const isUpsellCalculated = selected.upsell?.price && selected.upsell?.price > 0

  const styles = useStyles(theme)({ isDisabled })

  useUpdateEffect(() => {
    mounted()
  }, [selected.slot, selected.space, selected.totalPrice])

  useEffect(() => {
    if (isSelectedSlotLastInCurrentDay) {
      toggleUpsellState(false)
    }
  }, [isSelectedSlotLastInCurrentDay])

  return (
    <Flex
      fullWidth
      align={'center'}
      justify={'space-between'}
      gap={3}
      direction={isMobile ? 'column' : 'row'}
      sx={styles.container}
    >
      <Flex>
        <Flex gap={isMobile ? 4 : 3} align={'center'} direction={isMobile ? 'column' : 'row'}>
          <Flex>
            <Update fontSize={!isDesktop ? 'large' : 'small'} />
          </Flex>
          <Flex fullWidth flex={1} gap={8}>
            <Flex direction={'column'}>
              <Flex>
                <Typography variant={'h5'}>{i18n.happening.upsell.getAdditionalSlot}</Typography>
              </Flex>
              <Flex fullWidth>
                <Typography variant={'subtitle2'} color={'secondary'}>
                  {`${i18n.happening.upsell.upsellDuration}${
                    isNaN(upsellTime) ? 0 : upsellTime - timeBreak
                  } ${i18n.happening.upsell.minutes}`}
                </Typography>
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <Typography variant={'h5'}>
              + {isUpsellCalculated ? selected.upsell?.price : 0} zł
            </Typography>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Button
          variant={upsellFromState ? 'contained' : 'outlined'}
          color={'primary'}
          size={'large'}
          disableElevation={true}
          fullWidth={!isMobile}
          onClick={toggleUpsell}
          sx={styles.button}
        >
          {upsellFromState ? i18n.happening.upsell.added : i18n.happening.upsell.add}
        </Button>
      </Flex>
    </Flex>
  )
}

export default Upsell
