import { createSelector } from 'reselect'
import { Route } from 'router/routes'

import _Store from '@Store'

import { IInternalRouterReducer } from './../types'
import get from './get'

const getModule = createSelector<_Store.IState, IInternalRouterReducer, Route | null>(
  [get],
  (router) => router.internalRouter.module
)

export default getModule
