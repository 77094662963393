import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'

import CarouselBrick from './CarouselBrick.component'
import { ICarouselBrickFromState, ICarouselBrickOwnProps } from './CarouselBrick.types'

const mapStateToProps = (state: _Store.IState) => ({
  compositionSlug: getCompositionIdentifier(state),
  compositionIdentifier: getCompositionIdentifier(state),
})

export default connect<ICarouselBrickFromState, null, ICarouselBrickOwnProps, _Store.IState>(
  mapStateToProps
)(CarouselBrick)
