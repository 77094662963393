import React from 'react'

import { PersonOutline } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { useUser } from 'components/_functional/UserContextProvider'

export const AccountLinkButton = () => {
  const { isLoggedIn, dialog } = useUser()

  return (
    <IconButton
      {...(isLoggedIn
        ? { component: NavLink, to: routes[Route.account] }
        : { onClick: dialog.show })}
      color={'inherit'}
    >
      <PersonOutline />
    </IconButton>
  )
}
