import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import { IGetLandingPageSuccessPayload, ISetCurrentKeyPayload } from './../types'

export const landingPageMounted = createStandardAction(CONSTS.LANDING_PAGE_MOUNTED)()

export const getLandingPage = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetLandingPageSuccessPayload, Error>()

export const setCurrentKey = createStandardAction(CONSTS.SET_CURRENT_KEY)<ISetCurrentKeyPayload>()
