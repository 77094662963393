import * as React from 'react'

import { Alert, Button } from '@mui/material'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import { DateTimePlace } from 'components/reusable/DateTimePlace'
import { SwitchTicket } from 'components/ticketManagement/SwitchTicket'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { TicketResponse } from 'types/TicketManagement'

export const MyTicket = () => {
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { code, email } = useParams<RouteParams<Route.ticketManagementTicket>>()
  const { data, isLoading, error } = useSWR<TicketResponse[]>(
    !!code && !!email && URI.getTicket(code, email),
    { revalidateOnFocus: true }
  )
  const handleGoBack = () => navigate(-1)

  if (isLoading) return <TwoColumnsSkeleton />

  if (!data || !code || !email) {
    navigate(Route.ticketManagement)
    return null
  }

  if (!!data && !data.length) {
    return (
      <SectionContainer
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        title={i18n.ticketManagement.myTicket.noTicketTitle}
        container
      >
        <Alert severity={'error'} children={i18n.ticketManagement.myTicket.noTicketDescription} />
        <Button
          variant={'contained'}
          size={'large'}
          children={i18n.buttons.back}
          onClick={handleGoBack}
        />
      </SectionContainer>
    )
  }

  const eventData = data[0]?.rundate?.event ?? data[0]?.event

  return (
    <TwoColumnsLayout
      pageTitle={eventData?.title!}
      image={eventData?.images[0]?.large}
      boxComponent={
        <>
          <DateTimePlace
            startDate={data[0].rundate?.startDate}
            endDate={data[0].rundate?.endDate}
            place={
              eventData?.place && {
                name: `${eventData.place.name}, ${eventData.place.city.name}`,
                slug: eventData.place.slug,
              }
            }
          />
          <Button
            sx={{ px: 0, mt: 4, mb: 2 }}
            variant={'contained'}
            size={'large'}
            children={i18n.buttons.goBack}
            onClick={handleGoBack}
            fullWidth
          />
          <SwitchTicket email={email} />
        </>
      }
    >
      <Outlet />
    </TwoColumnsLayout>
  )
}
