import React, { FC, RefObject, createRef, useEffect, useState } from 'react'

import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { isRouteMatch } from 'router/utils/isRouteMatch'
import { prepareRoute } from 'router/utils/prepareRoute'

import { useLocations } from 'components/_functional/LocationContextProvider'
import BeautyScroll from 'components/reusable/BeautyScroll'
import Dropdown from 'components/reusable/Dropdown'
import { IBasicLocation } from 'types/Locations'

import styles from './CitiesDropdown.module.scss'
import { ICitiesDropdownProps } from './CitiesDropdown.types'

const DROPDOWN_CONTENT_PADDING = 32

const CitiesDropdown: FC<ICitiesDropdownProps> = ({ isSearchDropdown }) => {
  const { locations, userLocation, setLocation } = useLocations()
  const dropdownRef: RefObject<HTMLDivElement> = createRef()
  const [dropdownWidth, setDropdownWidth] = useState<number>()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (dropdownRef.current && isSearchDropdown) {
      const widthDropdown = dropdownRef.current.clientWidth + DROPDOWN_CONTENT_PADDING
      setDropdownWidth(widthDropdown)
    }
  })

  const handleSetLocation = (location: IBasicLocation) => () => {
    setLocation(location)
    if (isRouteMatch(routes[Route.mainPage], pathname))
      navigate(prepareRoute({ route: Route.mainPage, params: { slug: location.slug } }))
  }

  return (
    <Dropdown
      chevron={true}
      isCityDropdown={true}
      className={isSearchDropdown ? styles.searchSelect : styles.select}
      button={
        <div
          className={isSearchDropdown ? styles.searchDropdownWrapper : styles.dropdownWrapper}
          style={{ width: dropdownWidth }}
        >
          <input
            className={cn(styles.dropdown, isSearchDropdown && styles.searchDropdown)}
            type="button"
            value={userLocation.name}
          />
        </div>
      }
      content={
        <div
          ref={dropdownRef}
          className={isSearchDropdown ? styles.searchOptionsWrapper : styles.optionsWrapper}
        >
          <div className={styles.options}>
            <BeautyScroll>
              {locations.map((location) => {
                return (
                  <input
                    key={location.slug}
                    onClick={handleSetLocation(location)}
                    type={'button'}
                    value={location.name}
                  />
                )
              })}
            </BeautyScroll>
          </div>
        </div>
      }
    />
  )
}

export default CitiesDropdown
