import * as React from 'react'

import useSWR from 'swr'

import Bricks from 'components/Bricks'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/StaticPage'
import { ICompositionData } from 'models/pages/types'
import { URI } from 'services/URI'

const ABOUT_TEXT = 'Pomoc'

export const HelpPage = () => {
  const { userLocation } = useLocations()
  const { data } = useSWR<ICompositionData>(URI.getHelpPage())

  return (
    <PageWrapper fullWidth>
      <Helmet pageName={ABOUT_TEXT} isGoingHelp />
      <Bricks userLocation={userLocation} structure={data} />
    </PageWrapper>
  )
}
