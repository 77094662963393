import * as React from 'react'

import { ArrowCircleRight } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { defaultValues } from 'components/_forms/_constants/defaultValues'
import { validators } from 'components/_forms/_validators'
import {
  ISwitchTicketFormInput,
  ISwitchTicketProps,
} from 'components/ticketManagement/SwitchTicket/SwitchTicket.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const SwitchTicket = ({ email }: ISwitchTicketProps) => {
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm<ISwitchTicketFormInput>()

  const validationRules = {
    [FormFields.CODE]: validators.requiredString(i18n.forms.validation[FormFields.CODE].required),
  }

  const onSubmit = handleSubmit(({ code }) =>
    navigate(
      prepareRoute({
        route: Route.ticketManagementTicket,
        params: { code, email },
      })
    )
  )

  return (
    <Form.Wrapper onSubmit={onSubmit}>
      <TextInput<ISwitchTicketFormInput>
        label={i18n.forms.labels[FormFields.CODE]}
        name={FormFields.CODE}
        control={control}
        defaultValue={defaultValues.empty}
        rules={validationRules[FormFields.CODE]}
        helperText={i18n.ticketManagement.myTicket.switchTicket}
        InputProps={{
          endAdornment: (
            <InputAdornment position={'end'}>
              <IconButton type={'submit'}>
                <ArrowCircleRight />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Form.Wrapper>
  )
}
