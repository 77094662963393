import { EMPTY, of as of$ } from 'rxjs'
import {
  filter as filter$,
  mergeMap as mergeMap$,
  skip as skip$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { locationChange, navigate } from 'models/internalRouter/actions'
import { getLocation } from 'models/internalRouter/selectors'
import { isActionOf } from 'typesafe-actions'

import { _closeAll, _setOne, closeAll, closeLatest, open } from './../actions'
import * as MODALS from './../constants/constants'
import { getModals } from './../selectors'

// OPEN AND CLOSE
export const openOrChangeRouteWhenTryOpen: _Store.IEpic = (
  action$,
  state$,
  { modalDictionary }
) => {
  return action$.pipe(
    filter$(isActionOf(open)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const location = getLocation(state)

      return of$(
        navigate({
          to: `${location?.pathname}${location?.search}#${modalDictionary.toHash(
            action.payload.type
          )}`,
        })
      )
    })
  )
}

export const closeLatestOrChangeRouteWhenTryCloseLatest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(closeLatest)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => of$(navigate({ delta: -1 })))
  )
}

export const closeAllOrChangeRouteWhenTryCloseAll: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(closeAll)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => of$(navigate({ delta: -getModals(state).length })))
  )
}

// LOCATION
export const openModalWhenLocationChange: _Store.IEpic = (action$, state$, { modalDictionary }) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    skip$(1),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const location = getLocation(state)

      const modal = modalDictionary.fromHash(location?.hash || '') as MODALS.IModalKey

      if (modal) {
        return of$(_setOne(modal, undefined))
      } else if (getModals(state).length > 0) {
        return of$(_closeAll())
      }

      return EMPTY
    })
  )
}
