import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { buyAndPayOnsite } from 'models/transaction/actions'

import Onsite from './Onsite.component'
import { getInitialValues } from './Onsite.selectors'
import { IBuyingOnsiteFromDispatch, IBuyingOnsiteFromState } from './Onsite.types'

const mapStateToProps = (state: _Store.IState): IBuyingOnsiteFromState => ({
  initialValues: getInitialValues(),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IBuyingOnsiteFromDispatch => {
  return {
    buyAndPay: (values) => dispatch(buyAndPayOnsite(values)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onsite)
