import * as CONSTS from 'models/app/constants/constants'

class DeviceDetector {
  public determineMobileUserAgent() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/windows phone/i.test(userAgent)) {
      return CONSTS.WINDOWS
    }

    if (/android/i.test(userAgent)) {
      return CONSTS.ANDROID
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return CONSTS.IOS
    }

    return CONSTS.UNDEFINED
  }
}

export default new DeviceDetector()
