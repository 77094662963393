import { ICustomTerm } from 'types/EventCommon'
import { getType } from 'typesafe-actions'

import { clearSelectedHappening, saveTerms } from './../../actions'
import { IAction } from './../../types'

const reducer = (state: ICustomTerm[] | null = null, action: IAction) => {
  switch (action.type) {
    case getType(saveTerms.success):
      return action.payload
    case getType(clearSelectedHappening):
      return null

    default:
      return state
  }
}

export default reducer
