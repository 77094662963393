import React from 'react'

import { TextField, useTheme } from '@mui/material'
import cn from 'classnames'
import { ErrorMessage, useField } from 'formik'

import classes from './FormField.classes'
import styles from './FormField.module.scss'
import useStyles from './FormField.styles'
import { IFormFieldProps } from './FormField.types'

const FormField = ({ id, inputTextModification, ...props }: IFormFieldProps) => {
  const [field, meta] = useField(props)
  const isCorrect = !meta.error && meta.touched
  const showCorrectMessage = isCorrect && props.correct
  const isError = meta.error && meta.touched

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  return (
    <>
      <TextField
        fullWidth={true}
        id={field.name}
        name={field.name}
        type={props.type}
        value={field.value}
        onBlur={field.onBlur}
        onChange={inputTextModification ? inputTextModification : field.onChange}
        error={!!isError}
        label={props.placeholder}
        helperText={props.helperText}
        variant={'outlined'}
        className={muiStyles.root}
      />
      {showCorrectMessage && (
        <div className={cn(styles.correctMessage, classes.correctBorder)}>{props.correct}</div>
      )}
      <ErrorMessage
        className={cn(styles.error, classes.error)}
        name={props.name}
        component="span"
      />
    </>
  )
}

export default FormField
