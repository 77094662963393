import { Box, styled } from '@mui/material'

export const Styled = {
  Root: styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(6),
    minHeight: 460,
  })),
  Body: styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    flex: 1,
  })),
  Footer: styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacing(2),
  })),
  Content: styled(Box)({ flex: 'inherit' }),
}
