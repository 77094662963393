import React, { useCallback, useContext, useEffect } from 'react'

import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Field, useFormikContext } from 'formik'
import debounce from 'lodash.debounce'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { useUser } from 'components/_functional/UserContextProvider'
import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import Checkbox from 'components/formik/Checkbox'
import FormField from 'components/formik/FormField'
import config from 'config'
import icons from 'misc/helpers/icons'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import useUpdateEffect from 'misc/hooks/useUpdateEffect'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import EmpikCardNumber from './../EmpikCardNumber'
import Invoice from './../Invoice'
import ToggleField from './../ToggledField'
import classes from './ClientData.classes'
import styles from './ClientData.module.scss'
import useStyles from './ClientData.styles'
import { IClientDataProps } from './ClientData.types'

const AUTOCOMPLETE_ID = 'autocomplete'

const ClientData = ({
  autocompleteForm,
  calculatePrice,
  customTerms,
  currentBasketData,
  currentKey,
  checkEmail,
  hasDependency,
  handleTokenizeCard,
  isBasketItems,
  isBasketPay,
  isDisabled,
  lastData,
  number,
  requestDataFromFacebook,
  setFieldValue,
  showInvoiceForm,
  showPremiumCardInput,
  showPassForm,
  slotsSale,
  selected,
  setDiscountState,
  setPrepaidState,
  generateIdempotencyKey,
  selectedTheme,
  selectedSpace,
  // TODO: Temporary feature 05.05.2022
  poolsData,
  eventAutoFillData,
  payuInstance,
  notify,
  isProductsPay,
  eventData,
  isEventPremium,
}: IClientDataProps) => {
  const { i18n } = useDictionary()
  const { isWebview } = useAppState()
  const { isMobile } = useBreakpoints()
  const { search } = useLocation()
  const { goingMetadata, firebaseUser, isLoggedIn } = useUser()
  const autoFill = !!qs.parse(search, { ignoreQueryPrefix: true }).data
  const SALTOS_ID = 2459
  const GIFT_CARDS_RUN_DATES = [2353795, 2357465]
  const isGiftCard = GIFT_CARDS_RUN_DATES.includes(poolsData?.id || 0)
  // TODO: Temporary feature 05.05.2022
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const BagState = useContext(clearContext)
  const basketItems = BagState?.basketItems || []
  const { values } = useFormikContext<IBuyingOnlineFormValues>()
  const firstNameAdditional = Object.values(values.firstnameAdditional || {}).length
  const showAutocompleteButton =
    !isLoggedIn && lastData && lastData.email && lastData.firstname && lastData.lastname
  const upsellFromState = selected.upsell?.isSelected
  const isDefaultTheme = selectedTheme === 'default'
  const isEventWithoutInvoice = currentKey === 'bilet-wsparcia-dla-ukrainy/luty-2022'
  const isDefaultCurrency = ![...currentBasketData, ...basketItems].some(
    (item) => item.currency !== config.app.defaultCurrency
  )
  const excludedPartners = [2282, 3078]
  const shouldShowCheckbox = eventData?.partnerId
    ? !excludedPartners.includes(eventData.partnerId)
    : true
  const showInvoice = !isEventWithoutInvoice
  const emailCheck = useCallback(debounce(checkEmail, 1000), [])

  const autocompleteClicked = () => {
    if (autocompleteForm) {
      autocompleteForm()
      eventAutoFillData(i18n.buy.clientData.fillFormWithLastData)
    }

    const autocompleteElement = document.getElementById(AUTOCOMPLETE_ID)
    if (autocompleteElement) {
      autocompleteElement.style.height = '0'
      autocompleteElement.style.margin = '0'
      autocompleteElement.innerHTML = ''
      autocompleteElement.style.padding = '0'
    }
  }

  const isEmpikTheme = config.theme.isEmpik

  const prepaidText = !slotsSale
    ? isEmpikTheme
      ? i18n.buy.clientData.iHavePrepaidEmpik
      : i18n.buy.clientData.iHavePrepaidGoing
    : i18n.buy.clientData.iHavePrepaid

  const prepaidTextUnavailable = !isEmpikTheme
    ? !slotsSale
      ? i18n.buy.clientData.iHavePrepaidGoing +
        ' ' +
        i18n.buy.clientData.discountUnavailableForBasketSales
      : i18n.buy.clientData.iHavePrepaid + i18n.buy.clientData.discountUnavailableForBasketSales
    : !slotsSale
    ? i18n.buy.clientData.iHavePrepaidGoing +
      ' ' +
      i18n.buy.clientData.discountUnavailableForBasketSalesEmpik
    : i18n.buy.clientData.iHavePrepaid + i18n.buy.clientData.discountUnavailableForBasketSalesEmpik

  const facebookIconClicked = () => requestDataFromFacebook(setFieldValue)

  const calculateWithDiscount = async (code: string, type: 'discount' | 'prepaid') => {
    if (handleTokenizeCard && payuInstance) {
      const tokenize = await handleTokenizeCard(payuInstance)

      if (tokenize === 'ERROR') {
        return
      }
    }

    type === 'discount' ? setDiscountState(code) : setPrepaidState(code)
    generateIdempotencyKey()

    type === 'discount'
      ? calculatePrice({
          discountCode: code,
          upsell: upsellFromState,
          emailAddress: values.email,
          paymentMethod: values.paymentMethod.type,
        })
      : calculatePrice({
          prepaidCard: code,
          upsell: upsellFromState,
          emailAddress: values.email,
          paymentMethod: values.paymentMethod.type,
        })
  }

  useEffect(() => {
    if (autoFill) {
      setTimeout(() => autocompleteClicked(), 100)
    }
  }, [customTerms])

  useUpdateEffect(() => {
    if ((!values.discountCheckbox && values.discount) || isBasketItems || isBasketPay) {
      setFieldValue('discount', '')
      calculateWithDiscount('', 'discount')
    }
  }, [values.discountCheckbox, isBasketItems])

  useUpdateEffect(() => {
    if ((!values.prepaidCheckbox && values.prepaidCard) || isBasketItems || isBasketPay) {
      setFieldValue('prepaidCard', '')
      calculateWithDiscount('', 'prepaid')
    }
  }, [values.prepaidCheckbox, isBasketItems])

  useEffect(() => {
    setFieldValue('hasDependency', hasDependency)
  }, [hasDependency, firstNameAdditional])
  // TODO: find a better way to fix the bug from WEAPP-3798

  useEffect(() => {
    if (!!goingMetadata) {
      setFieldValue('firstname', goingMetadata.firstname)
      setFieldValue('lastname', goingMetadata.lastname)
    }
    if (!!firebaseUser?.email) setFieldValue('email', firebaseUser.email)
  }, [firebaseUser?.email, goingMetadata])

  // useEffect(() => {
  //   if (values.email) {
  //     emailCheck(values.email)
  //   }
  //
  //   return emailCheck.cancel
  // }, [values.email])

  return (
    <div
      className={cn(
        !slotsSale && styles.clientData,
        classes.clientData,
        isDisabled && styles.disabled,
        isBasketPay && styles.withoutMargin
      )}
    >
      {!slotsSale && (
        <Typography
          variant={'h3'}
          className={cn(muiStyles.header, classes.header)}
          data-number={number}
        >
          {i18n.buy.clientData.provideData}
        </Typography>
      )}
      {showAutocompleteButton && (
        <fieldset
          className={cn(
            styles.formPanel,
            classes.formPanel,
            styles.autoCompleteContainer,
            muiStyles.root,
            !isDefaultTheme && muiStyles.withoutPadding
          )}
          id={AUTOCOMPLETE_ID}
        >
          <label className={cn(styles.autoCompleteLabel, classes.label)}>
            <Grid
              container={true}
              justifyContent={{ xs: 'center', md: 'space-between' }}
              alignItems={'center'}
              spacing={2}
            >
              <Grid item={true} xs={12} sm={'auto'}>
                <Grid container={true} flexDirection={'column'} alignItems={'center'} spacing={2}>
                  <Grid item={true}>
                    <Typography variant="subtitle2">{i18n.buy.clientData.getLastData}</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="body1" color="secondary">
                      {lastData?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={'auto'}>
                <Button
                  onClick={autocompleteClicked}
                  size={'medium'}
                  variant={'outlined'}
                  color={'primary'}
                  sx={{ color: 'text.primary' }}
                  fullWidth={isMobile}
                >
                  {i18n.buy.clientData.fillFormWithLastData}
                </Button>
              </Grid>
            </Grid>
          </label>
        </fieldset>
      )}

      <fieldset
        className={cn(
          styles.formPanel,
          classes.formPanel,
          muiStyles.root,
          !isDefaultTheme && muiStyles.withoutPadding
        )}
      >
        <label className={cn(styles.label, classes.label)} htmlFor="email">
          {!slotsSale && (
            <Typography variant={'body1'}>
              {i18n.buy.clientData.willSendTickets}{' '}
              <Box component={'strong'} sx={{ fontWeight: 700 }}>
                {i18n.buy.clientData.validEmail}
              </Box>
            </Typography>
          )}
          {!isWebview && !isLoggedIn && (
            <Button
              size={'medium'}
              onClick={facebookIconClicked}
              fullWidth={true}
              disableElevation={true}
              className={muiStyles.facebookButton}
            >
              {i18n.buy.clientData.getDataFrom}
              <span className={cn(styles.socialIcon, classes.socialIcon, icons.facebookFrame)} />
            </Button>
          )}
          <FormField
            data-test-id={'client-data-email'}
            id="email"
            name="email"
            placeholder={i18n.buy.clientData.eMail}
            type="email"
          />
        </label>
        <div
          className={cn(
            styles.names,
            slotsSale && styles.slotsNames,
            styles.label,
            classes.names,
            classes.label
          )}
        >
          {!slotsSale && (
            <Typography variant={'body2'} sx={{ fontSize: 18 }}>
              {i18n.buy.clientData.namedTicket}{' '}
              <Box component={'strong'} sx={{ fontWeight: 700 }}>
                {i18n.buy.clientData.realData}
              </Box>
              {i18n.buy.clientData.id}
            </Typography>
          )}
          <label className={cn(styles.namesLabel, classes.namesLabel)} htmlFor="name">
            <FormField
              data-test-id={'client-data-firstname'}
              id="firstname"
              name="firstname"
              placeholder={i18n.buy.clientData.firstName}
              type="text"
            />
          </label>
          <label className={cn(styles.namesLabel, classes.namesLabel)} htmlFor="surname">
            <FormField
              data-test-id={'client-data-lastname'}
              id="lastname"
              name="lastname"
              placeholder={i18n.buy.clientData.lastName}
              type="text"
            />
          </label>
        </div>
        {slotsSale && (
          <label className={cn(styles.label, classes.label)} htmlFor="phone">
            <FormField
              id="phone"
              name="phone"
              placeholder={i18n.buy.clientData.phone}
              type="phone"
            />
          </label>
        )}

        {!isGiftCard && (
          <>
            {slotsSale ? (
              <div className={cn(styles.discountCheckBox, selectedSpace < 0 && styles.disabled)}>
                <ToggleField
                  checkboxId={'discountCheckbox'}
                  inputId={'discount'}
                  isDisabled={isDisabled || values.prepaidCheckbox}
                  open={values.discountCheckbox}
                  placeholder={i18n.buy.clientData.discountCode}
                  text={
                    config.theme.isGoing
                      ? i18n.buy.clientData.iHaveDiscountGoing
                      : i18n.buy.clientData.iHaveDiscount
                  }
                  tooltipText={
                    eventData?.partnerId === SALTOS_ID
                      ? i18n.buy.clientData.discountCodeTooltipSaltos
                      : i18n.buy.clientData.discountCodeTooltip
                  }
                />
              </div>
            ) : (
              <>
                <ToggleField
                  checkboxId={'discountCheckbox'}
                  inputId={'discount'}
                  isDisabled={isDisabled || isBasketItems || isBasketPay || values.prepaidCheckbox}
                  open={values.discountCheckbox}
                  placeholder={i18n.buy.clientData.discountCode}
                  text={
                    isBasketItems || isBasketPay
                      ? config.theme.isGoing
                        ? i18n.buy.clientData.iHaveDiscountGoing +
                          i18n.buy.clientData.discountUnavailableForBasketSales
                        : i18n.buy.clientData.iHaveDiscount +
                          i18n.buy.clientData.discountUnavailableForBasketSalesEmpik
                      : config.theme.isGoing
                      ? i18n.buy.clientData.iHaveDiscountGoing
                      : i18n.buy.clientData.iHaveDiscount
                  }
                  tooltipText={
                    eventData?.partnerId === SALTOS_ID
                      ? i18n.buy.clientData.discountCodeTooltipSaltos
                      : i18n.buy.clientData.discountCodeTooltip
                  }
                />
              </>
            )}

            {values.discountCheckbox && (
              <Button
                variant={'contained'}
                size={'large'}
                color={'primary'}
                disableElevation={true}
                fullWidth={true}
                children={i18n.buy.clientData.checkDiscount}
                onClick={
                  values.discount.length
                    ? () => calculateWithDiscount(values.discount.trim(), 'discount')
                    : () => notify(i18n.buy.clientData.discountCodeInputEmpty)
                }
              />
            )}
            {(isDefaultCurrency || slotsSale) && (
              <ToggleField
                checkboxId={'prepaidCheckbox'}
                inputId={'prepaidCard'}
                isDisabled={isDisabled || isBasketItems || isBasketPay || values.discountCheckbox}
                open={values.prepaidCheckbox}
                placeholder={
                  !slotsSale
                    ? isEmpikTheme
                      ? i18n.buy.clientData.prepaidCardEmpik
                      : i18n.buy.clientData.prepaidCardGoing
                    : i18n.buy.clientData.prepaidCard
                }
                text={isBasketItems || isBasketPay ? prepaidTextUnavailable : prepaidText}
                tooltipText={
                  !slotsSale
                    ? isEmpikTheme
                      ? i18n.buy.clientData.prepaidTooltipEmpik
                      : i18n.buy.clientData.prepaidTooltip
                    : undefined
                }
              />
            )}

            {values.prepaidCheckbox && (
              <Button
                variant={'contained'}
                size={'large'}
                color={'primary'}
                disableElevation={true}
                fullWidth={true}
                children={i18n.buy.clientData.calculatePrepaid}
                onClick={
                  values.prepaidCard.length
                    ? () => calculateWithDiscount(values.prepaidCard, 'prepaid')
                    : () => notify(i18n.buy.clientData.prepaidCardInputEmpty)
                }
              />
            )}

            {!slotsSale && isEventPremium && !isProductsPay && (
              <EmpikCardNumber
                open={!!showPremiumCardInput}
                withoutHeader={true}
                isDisabled={isDisabled}
              />
            )}

            {!!(slotsSale && shouldShowCheckbox) && (
              <div className={styles.wrapper}>
                <div className={styles.checkbox}>
                  <Field component={Checkbox} name="passCheckbox" id="passCheckbox">
                    {i18n.buy.clientData.iHavePass}
                  </Field>
                </div>

                {showPassForm && (
                  <div className={cn(styles.toggledFields)}>
                    <label className={styles.label} htmlFor="pass">
                      <FormField
                        id="pass"
                        name="pass"
                        placeholder={i18n.buy.clientData.pass}
                        type="text"
                      />
                    </label>
                  </div>
                )}
              </div>
            )}
            {showInvoice && (
              <Invoice
                isDisabled={isDisabled}
                open={showInvoiceForm}
                isDefaultCurrency={isDefaultCurrency}
              />
            )}
          </>
        )}
      </fieldset>

      <Typography sx={{ marginTop: 3, fontSize: 18 }} variant={'body2'}>
        {i18n.buy.clientData.requiredFields}
      </Typography>
    </div>
  )
}

export default ClientData
