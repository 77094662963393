import React, { MouseEvent } from 'react'

import { Button, useTheme } from '@mui/material'
import cn from 'classnames'

import useStyles from 'components/reusable/CtaButton/CtaButton.styles'
import { ICtaButtonProps } from 'components/reusable/CtaButton/CtaButton.types'
import { DataLayerEvents } from 'constants/Analytics'
import slugifyString from 'misc/helpers/slugifyString'
import Analytics from 'services/$analytics'

const SELL_WITH_GOING_BRICK_ID = 'cta_button-3014'
const CONTACT_US_BRICK_ID = 'cta_button-3017'

const CtaButton = ({
  backgroundColor,
  borderColor,
  borderWidth,
  isBorder,
  title,
  link,
  compositionIdentifier,
  brickId,
}: ICtaButtonProps) => {
  const theme = useTheme()
  const muiStyles = useStyles({
    backgroundColor,
    borderColor,
    borderWidth,
    isBorder,
  })(theme)

  const handleAddToDataLayerOnClick = (text_displayed: string) => (e: MouseEvent<HTMLElement>) =>
    link
      ? (() => {
          Analytics.pushForHomepageSelectItem(e, link, title || '')

          if (brickId === SELL_WITH_GOING_BRICK_ID || brickId === CONTACT_US_BRICK_ID) {
            Analytics.pushForB2BPageAnalytics({
              event: DataLayerEvents.Interaction,
              eventInfo: {
                detail: 'navigate to b2b contact form',
                text_displayed,
              },
            })
          }
        })()
      : undefined

  return (
    <Button
      size={'large'}
      className={cn(muiStyles.root, `${compositionIdentifier}-${brickId}-${slugifyString(title)}`)}
      children={title}
      href={link}
      onClick={handleAddToDataLayerOnClick(title)}
    />
  )
}

export default CtaButton
