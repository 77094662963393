import React, { useEffect, useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, Button, IconButton, InputAdornment } from '@mui/material'
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
  signInWithCredential,
  updatePassword,
} from 'firebase/auth'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { useNotify } from 'components/_functional/NotificationProvider'
import { useUser } from 'components/_functional/UserContextProvider'
import {
  IEditPasswordFormInput,
  IEditPasswordFormProps,
} from 'components/auth/EditPasswordForm/EditPasswordForm.types'
import { useToggle } from 'misc/hooks/useToggle'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const EditPasswordForm = ({ successCallback }: IEditPasswordFormProps) => {
  const { i18n } = useDictionary()
  const { isVisible, handleToggle } = useToggle()
  const { firebaseUser, loading } = useUser()
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IEditPasswordFormInput>()
  const { notify } = useNotify()
  const [isPasswordUser, setIsPasswordUser] = useState(false)
  const auth = getAuth()

  const onSubmit: SubmitHandler<IEditPasswordFormInput> = async ({ password, oldPassword }) => {
    if (!firebaseUser?.email) {
      notify({ text: i18n.notifications.mustLogin, alertColor: 'error' })
      return
    }

    // If the user doesn't have a password sign-in method
    if (!isPasswordUser) {
      try {
        const credential = EmailAuthProvider.credential(firebaseUser.email, password)
        // Link this credential to the currently authenticated user
        await linkWithCredential(firebaseUser, credential)

        if (!!successCallback) successCallback()
      } catch (error: any) {
        notify({ text: error.message, alertColor: 'error' })
      }
      return
    }

    // If the user already has a password sign-in method
    try {
      // First, re-authenticate the user with the old password
      const credential = EmailAuthProvider.credential(firebaseUser.email, oldPassword)
      await signInWithCredential(auth, credential)

      // If old password is correct, update to the new password
      await updatePassword(firebaseUser, password)
      if (successCallback) successCallback()
    } catch (error: any) {
      notify({ text: error.message, alertColor: 'error' })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!!firebaseUser?.email) {
        const methods = await fetchSignInMethodsForEmail(auth, firebaseUser.email)
        setIsPasswordUser(methods.includes('password') ?? false)
      }
    })()
  }, [firebaseUser?.email])

  return (
    <Form.Wrapper onSubmit={handleSubmit(onSubmit)}>
      {isPasswordUser && (
        <TextInput
          name={FormFields.OLDPASSWORD}
          label={i18n.forms.labels[FormFields.OLDPASSWORD]}
          control={control}
          rules={validators.requiredString(i18n.forms.validation[FormFields.PASSWORD].required)}
          type={isVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'start'}>
                <IconButton
                  onClick={handleToggle}
                  children={isVisible ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      <TextInput
        name={FormFields.PASSWORD}
        label={i18n.forms.labels[FormFields.PASSWORD]}
        control={control}
        rules={validators.strongPassword(
          i18n.forms.validation[FormFields.PASSWORD].required,
          i18n.forms.validation[FormFields.PASSWORD].correct,
          i18n.forms.validation[FormFields.PASSWORD].minMsg(8)
        )}
        type={isVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position={'start'}>
              <IconButton
                onClick={handleToggle}
                children={isVisible ? <VisibilityOff /> : <Visibility />}
              />
            </InputAdornment>
          ),
        }}
      />
      <Alert
        children={i18n.auth.passwordPolicy}
        color={!!errors[FormFields.PASSWORD] ? 'error' : 'info'}
      />
      <TextInput
        name={FormFields.REPEATPASSWORD}
        label={i18n.forms.labels[FormFields.REPEATPASSWORD]}
        control={control}
        rules={validators.repeatPassword(
          i18n.forms.validation[FormFields.REPEATPASSWORD].required,
          i18n.forms.validation[FormFields.REPEATPASSWORD].correct,
          watch(FormFields.PASSWORD)
        )}
        type={isVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position={'start'}>
              <IconButton
                onClick={handleToggle}
                children={isVisible ? <VisibilityOff /> : <Visibility />}
              />
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant={'contained'}
        children={i18n.buttons.save}
        type={'submit'}
        disabled={loading.isLoading}
        fullWidth
      />
    </Form.Wrapper>
  )
}
