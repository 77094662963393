import React from 'react'

import { Button, Grid, Typography } from '@mui/material'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import Paper from '../Paper'
import useStyles from './Summary.styles'
import { ISummaryProps } from './Summary.types'

const Summary = ({ handlePrevStep, formValues, confirmPersonalData }: ISummaryProps) => {
  const classes = useStyles()
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  const prepareRequest = () => {
    confirmPersonalData()
  }

  return (
    <Paper
      title={i18n.entryList.dataConfirmation.title}
      helperText={i18n.entryList.dataConfirmation.subtitle}
    >
      <Grid container={true} className={classes.container} justifyContent="center">
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.email}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {i18n.entryList.dataConfirmation.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.firstName}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {i18n.entryList.dataConfirmation.firstName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.lastName}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {i18n.entryList.dataConfirmation.lastName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.dateOfBirth}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {i18n.entryList.dataConfirmation.birthDay}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            className={globals.column}
            spacing={1}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {i18n.entryList.dataConfirmation.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={prepareRequest}
              >
                {i18n.entryList.dataConfirmation.confirm}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default Summary
