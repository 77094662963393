import { createSelector } from 'reselect'

import _Store from '@Store'

import { IInternalRouterReducer } from './../types'
import get from './get'

const getPartnerFormId = createSelector<_Store.IState, IInternalRouterReducer, string | undefined>(
  [get],
  (embed) => embed.embed.partnerFormId
)

export default getPartnerFormId
