import { from as from$, of as of$ } from 'rxjs'
import { catchError as catchError$, filter as filter$, mergeMap as mergeMap$ } from 'rxjs/operators'

import _Store from '@Store'

import { isActionOf } from 'typesafe-actions'

import { getShipmentDelivery, shipmentDeliveryMounted } from './../actions'

export const getShipmentDeliveryWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(shipmentDeliveryMounted)),
    mergeMap$((action) => {
      return of$(getShipmentDelivery.request(action.payload))
    })
  )
}

export const fetchShipmentDeliveryWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { shipmentApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getShipmentDelivery.request)),
    mergeMap$((action) => {
      return from$(shipmentApi.getShipmentDelivery(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(getShipmentDelivery.success(data))
        }),
        catchError$((error: Error) => of$(getShipmentDelivery.failure(error)))
      )
    })
  )
}
