import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore'

import { BrazeCustomAttributes } from '../../../constants/Analytics'
import { FavoriteCollectionIds, FavoritesTypes } from '../../../constants/Favorites'
import { BrazeApi } from '../../../services/Api/BrazeApi'
import Logger from '../../../services/Logger'
import { useDictionary } from '../../../state/locale/hooks/useDictionary'
import { AddFavHandler, RemoveFavHandler } from '../../../types/Favorites'
import { useNotify } from '../../_functional/NotificationProvider'
import { useUser } from '../../_functional/UserContextProvider'

/**
 * Hook used to edit user's favorites.
 */
export const useEditFavorites = () => {
  const { isLoggedIn, firebaseUser, dialog, dialogNewsletter, isNewsletter } = useUser()
  const { i18n } = useDictionary()
  const { notify } = useNotify()

  const addToFavorites: AddFavHandler = async ({ type, externalId, itemName }) => {
    if (!isNewsletter) {
      dialogNewsletter.show()
    }
    const db = getFirestore()
    try {
      if (!firebaseUser) return

      const favItemDocPath = `${FavoriteCollectionIds[type]}/${externalId}`
      await setDoc(doc(db, `${favItemDocPath}/followers/${firebaseUser.uid}`), {
        uid: firebaseUser.uid,
      })
      if (isNewsletter) notify({ text: i18n.favorites.addedToFavorites })

      await BrazeApi.logFirstActionToBraze(
        getFavoritesCustomAttributeByType(type),
        firebaseUser.uid,
        firebaseUser.email
      )
    } catch (error: any) {
      Logger.error('handleAddToFavorites', error)
    }
  }

  const removeFromFavorites: RemoveFavHandler = async ({ type, externalId, itemName }) => {
    const db = getFirestore()
    try {
      if (!firebaseUser?.uid) return

      const favItemDocPath = `${FavoriteCollectionIds[type]}/${externalId}`
      await deleteDoc(doc(db, `${favItemDocPath}/followers/${firebaseUser.uid}`))
      notify({ text: i18n.favorites.removedFromFavorites })
    } catch (error: any) {
      Logger.error('handleRemoveFromFavorites', error)
    }
  }

  const handleAdd: AddFavHandler = async ({ type, externalId, itemName }) => {
    if (!isLoggedIn) {
      notify({
        text: type === FavoritesTypes.Rundates ? i18n.favorites.logInEvents : i18n.favorites.logIn,
        action: { onPress: dialog.show, label: i18n.auth.login },
      })
    } else {
      await addToFavorites({ type, externalId, itemName })
    }
  }

  const handleRemove: RemoveFavHandler = async ({ itemName, type, externalId }) => {
    await removeFromFavorites({ itemName, type, externalId })
  }

  const handleEditFavorites =
    ({
      isFollowed,
      itemName,
      type,
      externalId,
    }: {
      isFollowed: boolean
      type: FavoritesTypes
      externalId: number
      itemName: string
    }) =>
    async () => {
      if (!isLoggedIn) {
        dialog.show()
        return
      }

      if (isFollowed)
        await handleRemove({
          itemName,
          type,
          externalId,
        })
      else await handleAdd({ itemName, type, externalId })
    }

  const getFavoritesCustomAttributeByType = (type: FavoritesTypes) => {
    switch (type) {
      case FavoritesTypes.Artists:
        return BrazeCustomAttributes.HAS_LIKED_ARTIST
      case FavoritesTypes.Places:
        return BrazeCustomAttributes.HAS_LIKED_PLACE
      case FavoritesTypes.Tags:
        return BrazeCustomAttributes.HAS_LIKED_TAG
      case FavoritesTypes.Rundates:
        return BrazeCustomAttributes.HAS_LIKED_RUNDATE
      default:
        return null
    }
  }

  return {
    addToFavorites: handleAdd,
    removeFromFavorites: handleRemove,
    handleEditFavorites,
  }
}
