import React, { CSSProperties, FC } from 'react'

import cn from 'classnames'

import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import isWhiteColor from 'misc/helpers/isWhiteColor'
import useBackgroundImageHeight from 'misc/hooks/useBackgroundImageHeight'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useAppState } from 'state/app/hooks/useAppState'

import styles from './Section.module.scss'
import { ISectionProps } from './Section.types'

const WHITE_CLASSNAME = 'white'
const DARK_CLASSNAME = 'dark'

const Section: FC<ISectionProps> = ({
  background,
  backgroundImage,
  children,
  contain,
  inner,
  inRow,
  mobileBackgroundImage,
  useGradient,
  id,
}) => {
  const { isBrowser, isDarkTheme } = useAppState()
  const { isMobile } = useBreakpoints()
  const imageSrc = mobileBackgroundImage && isMobile ? mobileBackgroundImage : backgroundImage
  const backgroundImageHeight = useBackgroundImageHeight(imageSrc, isBrowser, useGradient)
  const imageStyle = useGradient
    ? `linear-gradient(to bottom, transparent, ${background} ${backgroundImageHeight}), url(${imageSrc})`
    : `url(${imageSrc})`

  if (isDarkTheme && !backgroundImage && !mobileBackgroundImage && !isColorDark(background)) {
    background = invertIfGrayScaleColor(background)
  }

  const backgroundImg: CSSProperties = {
    backgroundColor: background,
    backgroundImage: imageStyle,
  }

  const backgroundSolid: CSSProperties = {
    backgroundColor: background,
  }

  const isWhite = isWhiteColor(background)
  const isDark = isColorDark(background)

  if (!children) {
    return null
  }

  return (
    <div
      id={`section-${id}`}
      className={cn(
        styles.wrapper,
        contain && styles.contain,
        useGradient && styles.gradient,
        isWhite && WHITE_CLASSNAME,
        isDark && DARK_CLASSNAME,
        inner && styles.inner
      )}
      style={imageSrc ? backgroundImg : backgroundSolid}
    >
      <div className={cn(styles.content, inRow && styles.row)}>{children}</div>
    </div>
  )
}

export default Section
