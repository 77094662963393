import * as React from 'react'

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { ITicketActionsProps } from 'components/ticketManagement/TicketAction/TicketAction.types'
import { formatPluralNouns } from 'misc/helpers/formatPluralNouns'
import { TicketTransferStatus } from 'services/Api/TicketManagementApi'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'
import { TicketResponse } from 'types/TicketManagement'

export const ShowHistory = ({ email, code, ticketIndex }: ITicketActionsProps) => {
  const { data: tickets } = useSWR<[TicketResponse]>(
    !!code && !!email && URI.getTicket(code, email),
    { revalidateOnMount: false }
  )
  const { i18n } = useDictionary()
  const { code: lang } = useLang()
  const ticketIndexNum = parseInt(ticketIndex)
  const navigate = useNavigate()

  const statusMap = (status: TicketTransferStatus) => {
    switch (status) {
      case TicketTransferStatus.PENDING:
        return i18n.ticketManagement.history[TicketTransferStatus.PENDING]
      case TicketTransferStatus.CANCELED:
        return i18n.ticketManagement.history[TicketTransferStatus.CANCELED]
      default:
        return i18n.ticketManagement.history.default
    }
  }

  React.useEffect(() => {
    if (isNaN(ticketIndexNum))
      navigate(
        prepareRoute({
          route: Route.ticketManagementTicket,
          params: { code, email },
        })
      )
  }, [ticketIndexNum])

  if (!tickets?.[ticketIndexNum].transfers.length) return null

  const history = tickets?.[ticketIndexNum].transfers

  return (
    <Timeline sx={{ mt: 4, minHeight: 460 }}>
      {tickets[ticketIndexNum].transfers.reverse().map((item, index) => (
        <TimelineItem key={String(index)} sx={{ '&:before': { display: 'none' } }}>
          <TimelineSeparator>
            <TimelineDot />
            {index + 1 < history.length && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              children={`${i18n.ticketManagement.history.transfer} ${
                item.quantity
              } ${formatPluralNouns(item.quantity, lang, i18n.pluralRules.ticket)} (${statusMap(
                item.status
              )})`}
            />
            <Typography variant={'caption'} color={'text.secondary'} children={item.email} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
