import {
  ITestimonialsData,
  ITestimonialsOwnProps,
} from 'components/Bricks/components/Testimonials/Testimonials.types'
import { ISectionComponentData, ISectionsComponentDataMutable } from 'models/pages/types/sections'

export const testimonials = (
  data: ISectionsComponentDataMutable,
  extra?: ISectionComponentData | null,
  sectionBgColor?: string,
  id?: string
): ITestimonialsOwnProps => {
  const testimonialData = data as ITestimonialsData[]

  return {
    data: testimonialData,
    extra,
    id,
    sectionBgColor,
  }
}
