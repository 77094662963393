// Private
export const _GET_REQUEST = 'happening/_GET_REQUEST'
export const _GET_SUCCESS = 'happening/_GET_SUCCESS'
export const _GET_FAILURE = 'happening/_GET_FAILURE'
export const _RESET_STATE = 'happening/_RESET_STATE'
export const _GET_AVAILABILITIES_REQUEST = 'happening/_GET_AVAILABILITIES_REQUEST'
export const _GET_AVAILABILITIES_SUCCESS = 'happening/_GET_AVAILABILITIES_SUCCESS'
export const _GET_AVAILABILITIES_FAILURE = 'happening/_GET_AVAILABILITIES_FAILURE'
export const CALCULATE_PRICE_REQUEST = 'happening/CALCULATE_PRICE_REQUEST'
export const CALCULATE_PRICE_SUCCESS = 'happening/CALCULATE_PRICE_SUCCESS'
export const CALCULATE_PRICE_FAILURE = 'happening/CALCULATE_PRICE_FAILURE'

// Public
export const HAPPENING_MOUNTED = 'happening/HAPPENING_MOUNTED'
export const REDIRECT_TO_HAPPENING = 'happening/REDIRECT_TO_HAPPENING'
export const HANDLE_HAPPENING = 'happening/HANDLE_HAPPENING'
export const SCROLL_TO_SECOND_STEP = 'happening/SCROLL_TO_SECOND_STEP'
export const CLEAR_SELECTED_HAPPENING = 'happening/CLEAR_SELECTED_HAPPENING'

export const SET_DAY = 'happening/SET_DAY'
export const SET_DAY_FROM_SLOT = 'happening/SET_DAY_FROM_SLOT'
export const SET_NUMBER_OF_PLAYERS = 'happening/SET_NUMBER_OF_PLAYERS'
export const SET_ONLY_ONE_PRICE_TYPE = 'happening/SET_ONLY_ONE_PRICE_TYPE'
export const SET_PRICE_TYPE = 'happening/SET_PRICE_TYPE'
export const SET_SLOT = 'happening/SET_SLOT'
export const SET_SPACE = 'happening/SET_SPACE'

export const REDIRECT_TO_TERMS = 'happening/REDIRECT_TO_TERMS'
export const SET_DURATION_TIME_AFTER_MIDNIGHT = 'happening/SET_DURATION_TIME_AFTER_MIDNIGHT'

export const RESET_SPACE_AND_SLOT = 'happening/RESET_SPACE_AND_SLOT'
export const RESET_ALL = 'happening/RESET_ALL'

export const SAVE_TERMS_REQUEST = 'happening/SAVE_TERMS_REQUEST'
export const SAVE_TERMS_SUCCESS = 'happening/SAVE_TERMS_SUCCESS'
export const SAVE_TERMS_FAILURE = 'happening/SAVE_TERMS_FAILURE'

export const RESERVE_SLOTS = 'happening/RESERVE_SLOTS'
export const RESERVE_SLOTS_ONSITE = 'happening/RESERVE_SLOTS_ONSITE'
export const SET_CALCULATED_PRICES = 'happening/SET_CALCULATED_PRICES'

export const UPSELL_MOUNTED = 'happening/UPSELL_MOUNTED'
export const SET_SELECTED_UPSELL = 'happening/SET_SELECTED_UPSELL'
export const TOGGLE_UPSELL = 'happening/TOGGLE_UPSELL'
export const SET_UPSELL_STATE = 'happening/SET_UPSELL_STATE'
export const SET_TOTAL_PRICE = 'happening/SET_TOTAL_PRICE'
export const SET_TOTAL_PRICE_IN_STATE = 'happening/SET_TOTAL_PRICE_IN_STATE'
export const NOTIFY_WHEN_UPSELL_UNAVAILABLE = 'happenig/NOTIFY_WHEN_UPSELL_UNAVAILABLE'
export const SET_DISCOUNT_STATE = 'happening/SET_DISCOUNT_STATE'
export const SET_PREPAID_STATE = 'happening/SET_PREPAID_STATE'
export const SET_PRICES_IN_STATE = 'happening/SET_PRICES_IN_STATE'
export const GENERATE_IDEMPOTENCY_KEY = 'happening/GENERATE_IDEMPOTENCY_KEY'
export const SET_EXTENDED_SLOT = 'happening/SET_EXTENDED_SLOT'
export const SET_EXTENDED_SLOT_MODAL_VISIBLE = 'happening/SET_EXTENDED_SLOT_MODAL_VISIBLE'

export const REQUEST_PARTNER_ACTIVITIES = 'happening/REQUEST_PARTNER_ACTIVITIES'
export const _GET_PARTNER_ACTIVITIES_REQUEST = 'happening/_GET_PARTNER_ACTIVITIES_REQUEST'
export const _GET_PARTNER_ACTIVITIES_SUCCESS = 'happening/_GET_PARTNER_ACTIVITIES_SUCCESS'
export const _GET_PARTNER_ACTIVITIES_FAILURE = 'happening/_GET_PARTNER_ACTIVITIES_FAILURE'

export const RESET_SLOT = 'happening/RESET_SLOT'
