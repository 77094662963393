import { useSelector } from 'react-redux'

import _Store from '@Store'

import { IGetTransactionInfoSuccessPayload } from 'models/transaction/types'

export const useTransactionInfoFromStore = () =>
  useSelector<_Store.IState, IGetTransactionInfoSuccessPayload | null>(
    ({ transaction }) => transaction.transactionInfo
  )
