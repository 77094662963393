import React from 'react'

import { Box, Card, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { Slider } from 'components/reusable/Slider'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { IWordPressArticle } from 'types/Article'

import { ArticlePrefix, sources } from '../../services/$article-api/sources'
import { DateTime } from '../../services/DateTime'
import { useDictionary } from '../../state/locale/hooks/useDictionary'
import { SectionContainer } from '../_layout/SectionContainer'
import { CardDeck } from '../reusable/CardDeck'
import ImageFixed from '../reusable/ImageFixed'
import Loading from '../reusable/loadings/DetailsLoading'
import { useStyles } from './EntityRelatedArticles.styles'
import { IEntityRelatedArticlesProps } from './EntityRelatedArticles.types'

export const EntityRelatedArticles = ({
  relatedArticlesData,
  isArticlesLoading,
}: IEntityRelatedArticlesProps) => {
  const { isMobile } = useBreakpoints()
  const { i18n } = useDictionary()
  const styles = useStyles({})

  const card = (item: IWordPressArticle) => (
    <Card sx={styles.root}>
      <Link
        sx={styles.link}
        component={RouterLink}
        to={`${sources[ArticlePrefix.GOING]}artykul/${item.slug}`}
        target={'_blank'}
      >
        <ImageFixed src={item._embedded['wp:featuredmedia'][0].source_url} />
        <Box sx={{ gap: 4, padding: 4, display: 'grid' }}>
          <Typography variant={'h6'} children={item.title.rendered} sx={styles.title} />
          <Typography
            variant={'subtitle2'}
            children={DateTime.getFriendlyDate({ isoDate: item.date })}
          />
        </Box>
      </Link>
    </Card>
  )

  return isArticlesLoading ? (
    <Loading />
  ) : !!relatedArticlesData?.length ? (
    <SectionContainer title={i18n.event.articles}>
      {isMobile ? (
        <Slider
          items={relatedArticlesData}
          edgeOffset={isMobile ? 8 : 16}
          useFixedWidth
          disableScrollbars
        >
          {({ item }) => card(item)}
        </Slider>
      ) : (
        <CardDeck>{relatedArticlesData.map(card)}</CardDeck>
      )}
    </SectionContainer>
  ) : null
}
