import axios, { CancelTokenSource } from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import { IPoolNormalized, IPoolsNormalized } from 'models/pools/types'
import { IPoolDescription, IPoolsResponse } from 'types/Pools'

class PoolsApi {
  private static salesAgentHeader = {
    'X-Sales-Agent': config.app.salesAgent,
  }

  private static getUrl({
    eventSlug,
    language,
    rundateSlug,
    isPreview,
  }: {
    eventSlug: string
    rundateSlug: string
    isPreview?: boolean
    language?: string
  }): string {
    const uri = new URL(`${config.api.baseUrl}rundate/${eventSlug}/${rundateSlug}/pools`)

    if (typeof window !== 'undefined') {
      const isSearch = window.location.search

      if (isSearch) {
        const params = new URLSearchParams(uri.search)

        if (language) {
          params.append('language', language)
        }

        if (isPreview) {
          params.append('preview', 'true')
        }

        return `${uri}?${params}`
      }
    }

    return `${uri}`
  }

  private static getProductsUrl(): string {
    return `${config.api.baseUrl}pools/products`
  }

  private cancelToken?: CancelTokenSource

  public getPools({
    eventSlug,
    rundateSlug,
    language,
    isPreview,
  }: {
    eventSlug: string
    rundateSlug: string
    language?: string
    isPreview?: boolean
  }): Promise<IPoolsResponse> {
    return new Promise((resolve, reject) => {
      const url = PoolsApi.getUrl({
        eventSlug,
        isPreview,
        language,
        rundateSlug,
      })

      this.cancelToken = axios.CancelToken.source()

      axios
        .get(
          url,
          withCacheHeader({
            cancelToken: this.cancelToken.token,
            headers: PoolsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IPoolsResponse) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public getProducts(poolIds: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = PoolsApi.getProductsUrl()

      this.cancelToken = axios.CancelToken.source()

      axios
        .get(
          url,
          withCacheHeader({
            cancelToken: this.cancelToken.token,
            params: { poolIds },
          })
        )
        .then(getData)
        .then(getData)
        // TODO: any
        .then((response: any) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelPools() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }

  public normalize(data: IPoolsResponse): IPoolsNormalized {
    return {
      ...data,
      pools: data.pools.map((pool): IPoolNormalized => {
        const description = pool.poolDescription || ({} as IPoolDescription)

        const poolHasSeatsIoCats = pool.seatsIoCategories.length > 0

        const normalizedSeatsIoCats =
          poolHasSeatsIoCats &&
          pool.seatsIoCategories.map((category) => ({
            categoryKey: category.categoryKey,
            ticketTypes: [
              {
                label: category.categoryName,
                price: category.price,
                ticketType: pool.id.toString(),
              },
            ],
          }))

        return {
          active: pool.active,
          courierRequired: pool.onlyByShipment,
          currency: pool.currency,
          description: description.description || '',
          discountEnabled: pool.discountEnabled,
          discountMaxTickets: pool.discountMaxTickets,
          forms: pool.forms,
          generalAdmission: pool.generalAdmission,
          hasDependency: pool.hasDependency,
          hasTransactionDataHydrator: pool.hasTransactionDataHydrator || false,
          id: pool.id,
          isDetailedPurchaseRequired: pool.isDetailedPurchaseRequired,
          payments: pool.payments,
          price: pool.price,
          priceWithServiceFee: pool.priceWithServiceFee,
          seatsIoCategories: normalizedSeatsIoCats || [],
          serviceFee: pool.serviceFee,
          sortOrder: pool.sortOrder,
          ticketInfo: description.ticketInfo || '',
          ticketsLimit: pool.ticketsNumLimit,
          title: description.title || '',
          vat: pool.vat,
          poolGroupEN: pool.poolGroupEN,
          poolGroupPL: pool.poolGroupPL,
        }
      }),
    }
  }
}

export default new PoolsApi()
