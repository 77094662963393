export enum RemoteConfigKeys {
  activitiesVersion = 'activitiesVersion',
  appDefinition = 'appDefinition',
  coupon = 'coupon',
  externalDictionary = 'externalDictionary',
  inspirationsVersion = 'inspirationsVersion',
  isReviewAfterPurchase = 'isReviewAfterPurchase',
  isTicketAfterPurchaseShare = 'isTicketAfterPurchaseShare',
  linkedPageSectionsVersion = 'linkedPageSectionsVersion',
  mainPageVersion = 'mainPageVersion',
  mainPageVersionSlug = 'mainPageVersionSlug',
  priceVariant = 'priceVariant',
  recommendsVersion = 'recommendsVersion',
  searchInfoBox = 'searchInfoBox',
  serviceFeeTest = 'serviceFeeTest',
  shareEventTemplate = 'shareEventTemplate',
  shareTicketTemplate = 'shareTicketTemplate',
  shouldAskForReview = 'shouldAskForReview',
  showPurchaseFavorites = 'showPurchaseFavorites',
}
