import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { SearchTypes } from '../../../constants/CloudSearch'

/**
 * Stores search type state.
 *
 * @type {RecoilState<SearchTypes | null>}
 */
export const typeFilterState = atom<SearchTypes | null>({
  default: null,
  key: RecoilKeys.typeFilter,
})
