import * as React from 'react'

import { ReturnAccountBank } from 'components/ReturnAccountBank'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/Helmet'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ReturnAccountBankPage = () => {
  const { i18n } = useDictionary()

  return (
    <PageWrapper>
      <Helmet pageName={i18n.returnAccountBank.pageName} />
      <ReturnAccountBank />
    </PageWrapper>
  )
}
