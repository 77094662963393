import * as React from 'react'

import { Box } from '@mui/material'

export const FiltersBar: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: 1,
        overflowX: 'scroll',
        maxWidth: '100%',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
      }}
      children={children}
    />
  )
}
