import qs from 'qs'
import { Route, routes } from 'router/routes'

import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { IFooterSection, IMenuLink } from 'services/$menu-api/types'

const menuGoing: IMenuLink[] = [
  {
    title: 'Kalendarium',
    link: routes[Route.search],
  },
  {
    title: 'Magazyn MORE',
    link: 'https://goingapp.pl/more/',
  },
  {
    title: 'Aplikacja Going.',
    link: routes[Route.about],
  },
  {
    title: 'Pomoc',
    link: routes[Route.help],
  },
  {
    title: 'Sprzedawaj z Going.',
    link: 'https://join.goingapp.pl',
    isSalesButton: true,
  },
]

const menuEmpik: IMenuLink[] = [
  {
    title: 'Wydarzenia',
    link: routes[Route.search],
  },
  {
    title: 'Artyści',
    link:
      routes[Route.search] +
      qs.stringify(
        {
          [HitFields.type]: SearchTypes.ARTIST,
        },
        { addQueryPrefix: true }
      ),
  },
  {
    title: 'Miejsca',
    link:
      routes[Route.search] +
      qs.stringify(
        {
          [HitFields.type]: SearchTypes.PLACE,
        },
        { addQueryPrefix: true }
      ),
  },
]

const menuEmpikOnsite: IMenuLink[] = [
  {
    title: 'Wydarzenia',
    link: routes[Route.search],
  },
  {
    title: 'Raporty i zwroty',
    link: 'https://web.goingapp.pl/backend/backend/auth/signin',
  },
]

export const menuFooterGoing: IFooterSection[] = [
  {
    title: 'Pomoc',
    links: [
      {
        title: 'Pomoc',
        link: routes[Route.help],
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/Regulamin%20Aplikacji%20Going./regulamin_aplikacji_going.pdf',
        nofollow: true,
        title: 'Regulamin (obowiązuje do 31.08.2023)',
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/Regulamin_01.09.2023.pdf',
        nofollow: true,
        title: 'Regulamin (obowiązuje od 1.09.2023)',
      },
      {
        link: 'https://web.goingapp.pl/themes/going/assets/docs/privacy_policy.pdf',
        nofollow: true,
        title: 'Polityka prywatności',
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/Informacje%20o%20Agencie%20ubezpieczeniowym/Informacja%20o%20Agencie%20ubezpieczeniowym_.pdf',
        nofollow: true,
        title: 'Informacja o agencie oraz pełnomocnictwo',
      },
      {
        link: 'https://goingapp.pl/polecamy/historia-regulaminow',
        nofollow: true,
        title: 'Historia regulaminów',
      },
    ],
  },
  {
    links: [
      {
        title: 'Współpraca',
        link: 'https://join.goingapp.pl',
      },
      {
        title: 'Zgłoś wydarzenie',
        link: 'https://goingapp.pl/zglos-wydarzenie',
        nofollow: true,
      },
      {
        title: 'System rezerwacji Going. Places',
        link: 'https://places.goingapp.pl/',
        nofollow: true,
      },
    ],
    title: 'Dla organizatorów',
  },
]

export const menuFooterEmpik = [
  {
    links: [
      {
        title: 'Kontakt',
        link: routes[Route.contact],
      },
      {
        title: 'FAQ',
        link: 'https://pomoc.empikbilety.pl/',
      },
      {
        title: 'Regulamin',
        link: 'https://web.goingapp.pl/storage/app/media/empikbilety/regulamin-portalu-biletowego-empik.pdf',
        nofollow: true,
      },
      {
        title: 'Polityka prywatności',
        link: 'https://web.goingapp.pl/storage/app/media/empikbilety/polityka-prywatnosci-portalu-biletowego-empik-bilety112019.pdf',
        nofollow: true,
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/Informacje%20o%20Agencie%20ubezpieczeniowym/Informacja%20o%20Agencie%20ubezpieczeniowym_.pdf',
        nofollow: true,
        title: 'Informacja o agencie oraz pełnomocnictwo',
      },
    ],
    title: 'Pomoc',
  },
  {
    title: 'Dla organizatorów',
    links: [
      {
        title: 'Współpraca',
        link: 'https://join.goingapp.pl/',
      },
      {
        title: 'System rezerwacji Going. Places',
        link: 'https://places.goingapp.pl/',
        nofollow: true,
      },
    ],
  },
]

export default {
  menuEmpik,
  menuEmpikOnsite,
  menuFooterEmpik,
  menuFooterGoing,
  menuGoing,
}
