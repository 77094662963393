import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import sectionComponentFontColor from 'misc/helpers/theme/sectionComponentFontColor'

const useStyles = (isBgDark: boolean, color?: string, isMobile?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      divider: {
        backgroundColor: sectionComponentFontColor(theme, color, isBgDark),
      },
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      fontColor: {
        color: sectionComponentFontColor(theme, color, isBgDark),
      },
      item: {
        padding: theme.spacing(5),
      },
      stretch: {
        alignSelf: isMobile ? 'flex-start' : 'center',
        flex: 1,
      },
    })
  )

export default useStyles
