import axios, { CancelTokenSource } from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'

import { IShipmentDeliveryResponse } from './types'

class ShipmentApi {
  private static getShipmentDeliveryUrl(countryCode?: string): string {
    return countryCode
      ? `${config.api.baseUrl}shipment-delivery/${countryCode}`
      : `${config.api.baseUrl}shipment-delivery`
  }

  private cancelTokenShipmentDelivery?: CancelTokenSource

  public getShipmentDelivery(countryCode?: string): Promise<IShipmentDeliveryResponse[]> {
    return new Promise((resolve, reject) => {
      const shipmentUri = ShipmentApi.getShipmentDeliveryUrl(countryCode)
      this.cancelTokenShipmentDelivery = axios.CancelToken.source()

      axios
        .post(shipmentUri)
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelShipmentDelivery() {
    if (this.cancelTokenShipmentDelivery) {
      this.cancelTokenShipmentDelivery.cancel()
      this.cancelTokenShipmentDelivery = undefined
    }
  }
}

export default new ShipmentApi()
