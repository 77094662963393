import React, { FC, useEffect } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'
import { Formik, FormikProps } from 'formik'
import { Link } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { lazy, object } from 'yup'

import styles from 'components/TicketReception/TicketReception.module.scss'
import useStyles from 'components/TicketReception/TicketReception.styles'
import {
  ITicketReceptionCustomTerms,
  ITicketReceptionProps,
} from 'components/TicketReception/TicketReception.types'
import WithEventDetailBox from 'components/TicketReception/WithEventDetailBox'
import { SectionContainer } from 'components/_layout/SectionContainer'
import Agreements from 'components/buying/components/Agreements/Agreements.component'
import RedirectForPayU from 'components/buying/components/RedirectForPayU'
import { customTermValidator, termValidator } from 'components/buying/constants/validators'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const TicketReception: FC<ITicketReceptionProps> = ({
  customTerms,
  mounted,
  submit,
  ticketData,
}) => {
  const { i18n } = useDictionary()

  useEffect(() => mounted(), [])

  const AgreementForm = ({ handleSubmit, setFieldValue, values }: FormikProps<any>) => (
    <form onSubmit={handleSubmit}>
      <Agreements
        isDisabled={false}
        isEmbed={false}
        number={2}
        customTerms={customTerms}
        selectedTickets={[]}
        setFieldValue={setFieldValue}
        values={values}
        selectedTheme={'default'}
      />
      <button className={styles.submitButton} type="submit" disabled={false}>
        {i18n.ticketsManagement.ticketReception.submitButton}
      </button>
    </form>
  )

  const agreementsInitialValues: ITicketReceptionCustomTerms = {
    customTerms: {},
    newsletter: false,
    terms: false,
  }

  const validators = object().shape({
    customTerms: lazy((value: { [key: string]: boolean }) =>
      customTermValidator(i18n, customTerms, value, 'exceptFVat')
    ),
    terms: termValidator(i18n.errors.checkingThisRegulationsIsRequiredValidator),
  })

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  return (
    <SectionContainer>
      <WithEventDetailBox>
        <h3 className={styles.header} data-number={1}>
          {i18n.ticketsManagement.ticketReception.getTicket}
        </h3>
        <fieldset className={cn(styles.formPanel, muiStyles.root)}>
          <p className={styles.description}>
            {i18n.ticketsManagement.ticketReception.hello}
            <b>
              {ticketData?.targetFirstname} {ticketData?.targetLastname}!
            </b>
          </p>
          <p className={styles.description}>
            {i18n.ticketsManagement.ticketReception.transferTicket}
            <br />
            {i18n.ticketsManagement.ticketReception.toReception}
          </p>
          <p className={cn(styles.description, styles.smallText)}>
            {i18n.ticketsManagement.ticketReception.namedTicket}
            <Link
              className={cn(styles.contactFormButton, muiStyles.fontColor)}
              to={routes[Route.contact]}
            >
              {i18n.ticketsManagement.ticketReception.contactForm}
            </Link>
          </p>
        </fieldset>
        <Formik
          component={AgreementForm}
          enableReinitialize={true}
          initialValues={agreementsInitialValues}
          onSubmit={submit}
          validationSchema={validators}
        />
        <RedirectForPayU />
      </WithEventDetailBox>
    </SectionContainer>
  )
}

export default TicketReception
