import { connect } from 'react-redux'

import _Store from '@Store'

import Banner from 'components/Bricks/components/Banner/Banner.component'
import { IBannerFromState } from 'components/Bricks/components/Banner/Banner.types'
import { getCompositionIdentifier } from 'models/pages/selectors'

export default connect(
  (state: _Store.IState): IBannerFromState => ({
    compositionIdentifier: getCompositionIdentifier(state),
  })
)(Banner)
