export const CAPTURE_EVENT_FROM_IFRAME = '@@internalRouter/CAPTURE_EVENT_FROM_IFRAME'
export const EMBED_LOADED = '@@internalRouter/EMBED_LOADED'
export const INIT_EMBED = '@@internalRouter/INIT_EMBED'
export const RESIZE_EMBED = '@@internalRouter/RESIZE_EMBED'
export const LOCATION_CHANGE = '@@internalRouter/LOCATION_CHANGE'
export const NAVIGATE = '@@internalRouter/NAVIGATE'
export const REDIRECT_PARENT_TO = '@@internalRouter/REDIRECT_PARENT_TO'
export const ADD_TO_GLOBAL_BASKET = '@@internalRouter/ADD_TO_GLOBAL_BASKET'
export const SET_BASKET_THEME = '@@internalRouter/SET_BASKET_THEME'
export const CLEAR_EMBED_INIT_TIME = '@@internalRouter/CLEAR_EMBED_INIT_TIME'
export const _GET_RUNDATE_SLUG_REQUEST = '@@internalRouter/_GET_RUNDATE_SLUG_REQUEST'
export const _GET_RUNDATE_SLUG_SUCCESS = '@@internalRouter/_GET_RUNDATE_SLUG_SUCCESS'
export const _GET_RUNDATE_SLUG_FAILURE = '@@internalRouter/_GET_RUNDATE_SLUG_FAILURE'
