import { createSelector } from 'reselect'

import getBasketTickets from 'models/basket/selector/getBasketTickets'
import getSelectedTickets from 'models/pools/selectors/getSelectedTickets'
import { getInsuranceProducts } from 'models/products/selectors'

const getInsuranceProductIds = createSelector(
  [getSelectedTickets, getBasketTickets, getInsuranceProducts],
  (selectedTickets, basketTickets, insuranceProducts) => {
    if (!selectedTickets.length && !basketTickets.length) return []

    const insuranceProductIds = insuranceProducts
      .sort((prev, curr) => prev.price - curr.price)
      .map((product) => product.id)

    const productIdsForTransaction: Array<{
      id: number
      amount: number
      poolId: number
    }> = []

    const getInsuranceIdPerPool = (poolPrice: number) => {
      if (5 <= poolPrice && poolPrice <= 50) return insuranceProductIds[0]
      if (50.01 <= poolPrice && poolPrice <= 100) return insuranceProductIds[1]
      if (100.01 <= poolPrice && poolPrice <= 150) return insuranceProductIds[2]
      if (150.01 <= poolPrice && poolPrice <= 200) return insuranceProductIds[3]
      if (200.01 <= poolPrice && poolPrice <= 300) return insuranceProductIds[4]
      if (300.01 <= poolPrice && poolPrice <= 400) return insuranceProductIds[5]
      if (400.01 <= poolPrice && poolPrice <= 500) return insuranceProductIds[6]
      if (500.01 <= poolPrice && poolPrice <= 1000) return insuranceProductIds[7]
      if (1000.01 <= poolPrice && poolPrice <= 2500) return insuranceProductIds[8]

      return 0
    }

    ;[...selectedTickets /*...basketTickets*/].map((pool) => {
      const isCurrentProductIdAlreadyPresent = productIdsForTransaction.find(
        (item) =>
          pool.price && item.id === getInsuranceIdPerPool(pool.price) && item.poolId === pool.poolId
      )
      const currentIdIndex = productIdsForTransaction.findIndex(
        (item) =>
          pool.price && item.id === getInsuranceIdPerPool(pool.price) && item.poolId === pool.poolId
      )

      pool.price && !isCurrentProductIdAlreadyPresent
        ? productIdsForTransaction.push({
            id: getInsuranceIdPerPool(pool.price),
            amount: pool.amount,
            poolId: pool.poolId,
          })
        : (productIdsForTransaction[currentIdIndex].amount =
            productIdsForTransaction[currentIdIndex].amount + pool.amount)
    })

    return productIdsForTransaction
  }
)

export default getInsuranceProductIds
