export enum DataLayerEvents {
  Interaction = 'interaction',
  B2BContactFormSubmit = 'b2b_contact_form_submit',
}

export enum BrazeCustomAttributes {
  HAS_CONNECTED_SPOTIFY = 'has_connected_spotify',
  HAS_LIKED_ARTIST = 'has_liked_artist',
  HAS_LIKED_PLACE = 'has_liked_place',
  HAS_LIKED_RUNDATE = 'has_liked_rundate',
  HAS_LIKED_TAG = 'has_liked,tag',
  HAS_OPENED_CALENDAR = 'has_opened_calendar',
  HAS_OPENED_NOTIFICATIONS = 'has_opened_notifications',
  HAS_TRANSFERRED_TICKET = 'has_transferred_ticket',
  HAS_SEARCHED = 'has_searched',
  HAS_SHARED_TICKET = 'has_shared_ticket',
  HOT_VIEWED_TWICE = 'hot_viewed_twice',
}
