// Import modules
import { combineEpics } from 'redux-observable'

import _Store from '@Store'

import * as __Base__ from 'models/__base__/epics'
import * as Analytics from 'models/analytics/epics'
import * as App from 'models/app/epics'
import * as Article from 'models/article/epics'
import * as Auth from 'models/auth/epics'
import * as Router from 'models/connectedRouter/epics'
import * as EntryList from 'models/entryList/epics'
import * as Errors from 'models/errors/epics'
import * as Event from 'models/event/epics'
import * as GiftCard from 'models/giftCard/epics'
import * as Happening from 'models/happening/epics'
import * as Images from 'models/images/epics'
import * as InternalRouter from 'models/internalRouter/epics'
import * as LandingPage from 'models/landingPage/epics'
import * as Mail from 'models/mail/epics'
import * as Modals from 'models/modals/epics'
import * as Pages from 'models/pages/epics'
import * as Pools from 'models/pools/epics'
import * as Premium from 'models/premium/epics'
import * as Products from 'models/products/epics'
import * as Sendgrid from 'models/sendgrid/epics'
import * as Shipment from 'models/shipment/epics'
import * as Social from 'models/social/epics'
import * as State from 'models/state/epics'
import * as Tickets from 'models/tickets/epics'
import * as TrafficParams from 'models/trafficParams/epics'
import * as Transactions from 'models/transaction/epics'

/* @@STORE_COMPONENT@@ */
const epics = [
  // __Base__
  __Base__.removeHashWhenComeFromOutside,

  // Analytics
  Analytics.addDataToGTMWhenAddPaymentInfoEvent,
  Analytics.addDataToGTMOnDeeplinkWeb,
  Analytics.addDataToGTMOnAutoFillData,
  Analytics.addDataToGTMOnProceed,
  Analytics.addDataToGTMWhenAddToCartEvent,
  Analytics.addDataToGTMWhenRemoveFromCartEvent,
  Analytics.addDataToGTMWhenBannerClicked,
  Analytics.addDataToGTMOnBuyPage,
  Analytics.addDataToGTMOnEventPage,
  Analytics.addDataToGTMOnEventPurchase,
  Analytics.addDataToGTMWhenError404,
  Analytics.addDataToGTMWhenErrorWhenSomethingWrong,
  Analytics.addDataToGTMWhenErrorModal,
  Analytics.addDataToGTMWhenEventLoaded,
  Analytics.addDataToGTMWhenFormSend,
  Analytics.addDataToGTMWhenFormValidate,
  Analytics.addDataToGTMWhenPaymentComplete,
  Analytics.addDataToGTMWhenTicketSelected,
  Analytics.addDataToGTMWhenLocationChangedOld,
  Analytics.addDataToGTMonNewsletterSub,

  // App
  App.informThatAppIsReady,
  App.resetLoadingsAlwaysOnLocationChange,
  App.determineMobileUserAgent,

  // Article
  Article.fetchArticleWhenRequested,
  Article.getArticleWhenMounted,

  // Auth
  Auth.emailCheckInit,
  Auth.emailCheckSubmit,
  Auth.generateToken,

  // EntryList
  EntryList.requestToCheckIfAgreementExistWhenFormSubmitted,
  EntryList.checkIfAgreementExistWhenRequested,
  EntryList.requestToCheckSubmissionsExistsWhenEmailPassed,
  EntryList.checkSubmissionsExistsWhenRequested,
  EntryList.selectNextStepWhenSubmissionSelected,
  EntryList.selectNextActionAfterPersonalDataFormSubmitted,
  EntryList.requestToRegisterNewSubmissionsWhenDataValid,
  EntryList.registerNewSubmissionWhenRequested,
  EntryList.requestToGetDataFromTicketWhenCodePassed,
  EntryList.getDataFromTicketWhenRequested,
  EntryList.requestToSendSmsWithCode,
  EntryList.sendSmsCodeWhenRequested,
  EntryList.checkIfAllSubmissionsAreComleted,
  EntryList.attachAllSubmissionsToTicketWhenFormComplete,

  // Errors
  Errors.setErrorOnFailure,

  // Event
  Event.fetchEventWhenRequested,
  Event.fillBuyFormFromUri,
  Event.getEventWhenMounted,
  Event.getRundateSlugWhenRequest,
  Event.redirectEventWhenLocationChange,
  Event.redirectEventWhenRundateSlugSuccess,
  Event.useAnalyticsWhenBuyFormMounted,

  // GiftCard
  GiftCard.giftCardActivationInit,
  GiftCard.submitCardActivation,

  // Happening
  Happening.fetchAvailabilitiesWhenRequested,
  Happening.getAvailabilitiesOnDayChange,
  Happening.getCustomTerms,
  Happening.getTermsWhenRequested,
  Happening.sendReserveForm,
  Happening.sendOnsiteReserveForm,
  Happening.whenHappeningMounted,
  Happening.whenHappeningRequested,
  Happening.calculatePriceForTicketType,
  Happening.getPriceCalculated,
  Happening.calculateUpsell,
  Happening.toggleUpsellState,
  Happening.calculateTotalPrice,
  Happening.notifyWhenPriceCalculationError,
  Happening.calculatePriceWhenChangeTicketType,
  Happening.openExtendedModalWhenSlotChanged,
  Happening.requestActivitiesWhenMounted,
  Happening.getPartnerActivitiesWhenRequested,

  // Images
  Images.fetchImagesFromCloudinary,
  Images.requestForImages,
  Images.getImagesByCloudinaryParams,

  // InternalRouter
  InternalRouter.captureIframeEventWhenPostMessage,
  InternalRouter.clearEmbedInitTimeWhenRequested,
  InternalRouter.establishIframeConnection,
  InternalRouter.getRundateSlugWhenRequest,
  InternalRouter.initEmbeddedPage,
  InternalRouter.loadFormDataForEmbed,
  InternalRouter.redirectEventWhenLocationChange,
  InternalRouter.redirectEventWhenRundateSlugSuccess,
  InternalRouter.redirectParentToWhenAction,
  InternalRouter.runResizeEmbed,
  InternalRouter.setBasketThemeToConfigured,
  InternalRouter.setGlobalBasketAction,

  // Landing Page
  LandingPage.fetchLandingPageWhenRequested,
  LandingPage.getLandingPageWhenMounted,

  // Modals
  Modals.closeAllOrChangeRouteWhenTryCloseAll,
  Modals.closeLatestOrChangeRouteWhenTryCloseLatest,
  Modals.openModalWhenLocationChange,
  Modals.openOrChangeRouteWhenTryOpen,

  // Mail
  Mail.sendMailWhenMounted,

  // Pages
  Pages.fetchSections,
  Pages.fetchUrlSections,
  Pages.getCustomSections,
  Pages.authenticateSections,

  // Pools
  Pools.fetchPoolsWhenRequested,
  Pools.getPoolsWhenPoolsComponentMounted,
  Pools.redirectWhenSelectPool,
  Pools.resetStateWhenBuyRouteLeft,
  Pools.updateSelectedSeatsWhenSeatDeselected,
  Pools.updateSelectedSeatsWhenSeatSelected,
  Pools.updateSelectedTicketsWhenAmountClicked,
  Pools.updateSelectedTicketsWhenSeatsSelectedUpdate,
  Pools.removeProductWhenDeselectTicket,

  // Premium
  Premium.checkIfNeedFetchCardData,
  Premium.doRedirectWhenPremiumTokenIsInvalid,
  Premium.fetchPremiumUserByToken,
  Premium.fetchPremiumWhenEventSuccess,
  Premium.fetchUserCardData,

  // Products
  Products.whenProductsMounted,
  Products.fetchProductsWhenRequested,
  Products.fetchProductsPoolsWhenRequested,
  Products.updateSelectedProductsWhenAmountClicked,
  Products.singleProductMounted,
  Products.fetchSingleProduct,
  Products.insuranceMounted,
  Products.getInsuranceProductsData,

  // Router
  Router.checkRedirectExists,
  Router.checkRouteExists,
  Router.redirectIfExists,

  // Sendgrid
  Sendgrid.checkIfUserEmailIsValid,
  Sendgrid.sendMailValidationRequest,

  // Shipment
  Shipment.fetchShipmentDeliveryWhenRequested,
  Shipment.getShipmentDeliveryWhenMounted,

  // Social
  Social.getClientDataFromFacebook,

  // State
  State.setLoaderWhenAvailabilitiesFetchFinished,
  State.setLoaderWhenAvailabilitiesFetchRequested,
  State.setLoaderWhenArticleFetchFinished,
  State.setLoaderWhenArticleFetchRequested,
  State.setLoaderWhenHappeningFetchFinished,
  State.setLoaderWhenHappeningFetchRequested,
  State.setLoaderWhenHappeningPriceFetchFinished,
  State.setLoaderWhenHappeningPriceFetchRequested,
  State.setLoaderWhenLandingPageFetchFinished,
  State.setLoaderWhenLandingPageFetchRequested,
  State.setLoaderWhenPoolsFetchFinished,
  State.setLoaderWhenPoolsFetchRequested,
  State.setLoaderWhenPremiumFetchFinished,
  State.setLoaderWhenPremiumFetchRequested,
  State.setLoaderWhenSectionsFetchFinished,
  State.setLoaderWhenSectionsFetchRequested,
  State.setLoaderWhenTicketsSendFinished,
  State.setLoaderWhenTicketsSendRequested,
  State.setLoaderWhenTransactionSendFinished,
  State.setLoaderWhenTransactionSendRequested,

  // Tickets
  Tickets.fetchEventWhenRequested,
  Tickets.fetchReceiveTicketWhenRequested,
  Tickets.fetchTransferTicketFee,
  Tickets.whenReceiveTicketMounted,
  Tickets.submitReceiveTicketForm,
  Tickets.redirectOnReset,
  Tickets.requestTicketDownloadFromTicketData,
  Tickets.requestTicketDownloadFromTransactionData,
  Tickets.downloadTheTicketFromTicketDataWhenRequested,
  Tickets.downloadTheTicketFromTransactionDataWhenRequested,

  // Transactions
  Transactions.fetchTransactionInfoWhenRequested,
  Transactions.fetchTransactionDetailsWhenRequested,
  Transactions.fetchTransactionInfoAndDetailsWhenSummaryMounted,
  Transactions.getPaymentRundateSlugWhenRequest,
  Transactions.redirectPaymentOnEmbed,
  Transactions.redirectPaymentWhenLocationChange,
  Transactions.redirectPaymentWhenRundateSlugSuccess,
  Transactions.requestOnlineTransactionWhenBuyClicked,
  Transactions.requestOnsiteTransactionWhenBuyClicked,
  Transactions.sendTransactionWhenRequested,
  Transactions.removeHoldTokenWhenTransactionWasSent,
  Transactions.removeBasketItemsWhenTransactionWasSent,
  Transactions.runRedirectAfterSaleWhenRequest,

  // UTM
  TrafficParams.getTrafficParams,
  TrafficParams.saveTrafficParamsInState,
]

export default combineEpics(...epics.filter(Boolean)) as _Store.IEpic
