import React, { ChangeEvent, useEffect } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Button, Grid, Icon, Link, Paper, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Field } from 'formik'

import { classes } from 'components/buying/components/Insurance/Insurance.classes'
import useStyles from 'components/buying/components/Insurance/Insurance.styles'
import { IInsuranceProps } from 'components/buying/components/Insurance/Insurance.types'
import ToggleField from 'components/buying/components/ToggledField'
import Checkbox from 'components/formik/Checkbox'
import ToolTip from 'components/reusable/ToolTip'
import config from 'config'
import { CurrencyISO } from 'constants/Currency'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import useUpdateEffect from 'misc/hooks/useUpdateEffect'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './Insurance.module.scss'

const OWU_LINK = 'https://web.goingapp.pl/storage/app/media/ProTicket/1.%20OWU_Going.ProTicket.pdf'
const PID_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/2-dokument-zawierajacy-informacje-o-produkcie-ubezpieczeniowympid.pdf'
const RODO_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/3-informacje-dotyczace-przetwarznia-danych-osobowychrodo.pdf'
const CONSUMER_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/4-obowiazki-informacyjne-wynikajace-z-ustawy-o-prawach-konumenta.pdf'

const Insurance = ({
  step,
  selectedTickets,
  setFieldValue,
  insuranceTotalPrice,
  insuranceProducts,
  touched,
  values,
  isDisabled,
  eventAddToCart,
  eventRemoveFromCart,
  isBasket,
  reloadInsurance,
}: IInsuranceProps) => {
  const { i18n } = useDictionary()
  const theme = useTheme()
  const { isMobile } = useBreakpoints()
  const muiStyles = useStyles(isMobile)(theme)
  const isInsuranceProperlyLoaded = insuranceProducts.length === 9 || isBasket
  const currentBasket = [...selectedTickets /*...basketTickets*/]

  const totalSelectedTicketsNumber = currentBasket
    .map((pool) => pool.amount)
    .reduce((previous, current) => previous + current, 0)
  const insuranceOptionalFieldNames = ['isAccidentInsurance']
  const insuranceRequiredFieldNames = [
    'isInsuranceAdultCheckbox',
    'insuranceBirthDate',
    'insuranceIsLivingInPoland',
  ]
  const isValidTicketsNumber = totalSelectedTicketsNumber <= config.buy.maxInsuredTicketsLimit
  const isValidTicketPrice =
    !!currentBasket.length &&
    currentBasket.every((pool) => !!pool.price && pool.price >= 5 && pool.price <= 2500)
  const isShowError =
    (values.isInsurance &&
      (!values.isInsuranceAdultCheckbox ||
        !values.insuranceBirthDate ||
        !values.insuranceIsLivingInPoland) &&
      insuranceRequiredFieldNames.find(
        (fieldName) => touched.hasOwnProperty(fieldName) && !values[fieldName]
      )) ||
    !isValidTicketsNumber ||
    !isValidTicketPrice

  const handleReloadInsurance = () => {
    reloadInsurance()
  }

  const toolTip = (
    <>
      <p>{i18n.buy.insurance.tooltipPart1}</p>
      <ul className={muiStyles.toolTipList}>
        <li className={muiStyles.toolTipListElement}>{i18n.buy.insurance.tooltipLi1}</li>
        <li className={muiStyles.toolTipListElement}>{i18n.buy.insurance.tooltipLi2}</li>
        <li className={muiStyles.toolTipListElement}>{i18n.buy.insurance.tooltipLi3}</li>
        <li className={muiStyles.toolTipListElement}>{i18n.buy.insurance.tooltipLi4}</li>
        <li className={muiStyles.toolTipListElement}>{i18n.buy.insurance.tooltipLi5}</li>
        <li className={muiStyles.toolTipListElement}>{i18n.buy.insurance.tooltipLi6}</li>
      </ul>
      <p>{i18n.buy.insurance.tooltipPart2}</p>
    </>
  )

  useEffect(() => {
    if (!isValidTicketsNumber || !isValidTicketPrice || isBasket) {
      setFieldValue('isInsurance', false)
    }
  }, [isValidTicketsNumber, isValidTicketPrice, isBasket])

  useUpdateEffect(() => {
    if (values.isInsurance) {
      eventAddToCart({
        price: insuranceTotalPrice,
        currency: CurrencyISO.PLN,
        isInsuranceAddToCartEvent: true,
        isInsuranceSelected: values.isInsurance,
      })
    } else {
      eventRemoveFromCart({
        price: insuranceTotalPrice,
        currency: CurrencyISO.PLN,
        isInsuranceAddToCartEvent: true,
      })
    }
  }, [values.isInsurance])

  const handleDateChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = e.target.value
    if (text.length < 11) {
      if (text.length === 4 || text.length === 7) {
        if (text.length > values[insuranceRequiredFieldNames[1]].length) {
          setFieldValue(insuranceRequiredFieldNames[1], text + '-')
          return
        }
      }

      setFieldValue(insuranceRequiredFieldNames[1], text)
    }
  }

  return (
    <Grid
      item={true}
      container={true}
      direction={'column'}
      className={cn(
        muiStyles.container,
        isDisabled && muiStyles.disabled,
        styles.insuranceBox,
        classes.insuranceBox
      )}
    >
      <Typography
        variant={'h3'}
        children={`${step}. ${i18n.buy.insurance.insuranceHeader}`}
        className={muiStyles.heading}
      />
      <Paper elevation={0} className={muiStyles.box}>
        {isInsuranceProperlyLoaded ? (
          <Grid container={true} direction={'column'} spacing={3}>
            <Grid container={true} item={true} spacing={3}>
              {(!!currentBasket.length && !!isShowError) ||
                (isBasket && (
                  <Grid item={true} container={true} spacing={3}>
                    <Grid item={true}>
                      <Alert severity={'error'}>
                        {isBasket
                          ? config.theme.isEmpik
                            ? i18n.buy.insurance.insuranceNotAvailableforBasketEmpik
                            : i18n.buy.insurance.insuranceNotAvailableforBasket
                          : !isValidTicketsNumber
                          ? i18n.buy.insurance.tooManyTickets
                          : !isValidTicketPrice
                          ? i18n.buy.insurance.invalidTicketPrice
                          : i18n.buy.insurance.invalidInsuranceFormData}
                      </Alert>
                    </Grid>
                  </Grid>
                ))}
              <Grid item={true} className={muiStyles.stretch}>
                <Field
                  component={Checkbox}
                  name={'isInsurance'}
                  id={'isInsurance'}
                  children={i18n.buy.insurance.choose}
                  disabled={!isValidTicketsNumber || !isValidTicketPrice || isBasket}
                />
              </Grid>
              {isValidTicketsNumber && isValidTicketPrice && (
                <Grid item={true}>
                  <Typography
                    variant={'body1'}
                    children={`+${getPriceFormatted(insuranceTotalPrice)} zł`}
                  />
                </Grid>
              )}
              <Grid item={true} className={'insurance-tooltip'}>
                <ToolTip title={toolTip}>
                  <Icon component={InfoIcon} />
                </ToolTip>
              </Grid>
            </Grid>
            {values.isInsurance && (
              <Grid
                container={true}
                item={true}
                className={muiStyles.indentation}
                direction={'column'}
              >
                <Grid item={true}>
                  <Field
                    component={Checkbox}
                    name={insuranceOptionalFieldNames[0]}
                    id={insuranceOptionalFieldNames[0]}
                    children={i18n.buy.insurance.accidentInsurance + '*'}
                  />
                </Grid>
                <Grid item={true} className={muiStyles.checkboxGap}>
                  <ToggleField
                    checkboxId={insuranceRequiredFieldNames[0]}
                    inputId={insuranceRequiredFieldNames[1]}
                    placeholder={i18n.buy.insurance.birthdayPlaceholder}
                    open={values.isInsurance && values.isInsuranceAdultCheckbox}
                    text={i18n.buy.insurance.iAmAdult + '*'}
                    helperText={i18n.buy.insurance.insuranceBirthDateHelperText}
                    inputTextModification={handleDateChange}
                  />
                </Grid>
                <Grid item={true} className={muiStyles.checkboxGap}>
                  <Field
                    component={Checkbox}
                    name={insuranceRequiredFieldNames[2]}
                    id={insuranceRequiredFieldNames[2]}
                    children={i18n.buy.insurance.iLiveInPoland + '*'}
                  />
                </Grid>
                <Grid item={true} className={cn(muiStyles.fitContent, muiStyles.checkboxGap)}>
                  <Link className={muiStyles.link} href={OWU_LINK} target={'_blank'}>
                    <Typography
                      variant={'subtitle1'}
                      children={i18n.buy.insurance.moreInsuranceInfo}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
                <Grid item={true} className={muiStyles.fitContent}>
                  <Link className={muiStyles.link} href={PID_LINK} target={'_blank'}>
                    <Typography
                      variant={'subtitle1'}
                      children={i18n.buy.insurance.insuranceProductInfo}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
                <Grid item={true} className={muiStyles.fitContent}>
                  <Link className={muiStyles.link} href={CONSUMER_LINK} target={'_blank'}>
                    <Typography
                      variant={'subtitle1'}
                      children={i18n.buy.insurance.consumerRights}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
                <Grid item={true} className={muiStyles.fitContent}>
                  <Link className={muiStyles.link} href={RODO_LINK} target={'_blank'}>
                    <Typography
                      variant={'subtitle1'}
                      children={i18n.buy.insurance.rodo}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container={true} item={true}>
            <Alert
              severity={'error'}
              action={
                <Button
                  onClick={handleReloadInsurance}
                  title={i18n.buy.insurance.tryAgain}
                  children={i18n.buy.insurance.tryAgain}
                  variant={'text'}
                  className={muiStyles.tryAgainButton}
                />
              }
            >
              {i18n.buy.insurance.invalidInsuranceData}
            </Alert>
          </Grid>
        )}
      </Paper>
    </Grid>
  )
}

export default Insurance
