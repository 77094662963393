import app from './app'
import theme from './theme'

const features = {
  basket: app.onlineSale,
  courierSummary: app.showUnfinishedFeatures,
  giftWrapper: false,
  showNewShipmentDelivery: true,
  showTicketDetails: false,
  userLogin: theme.isGoing,
}

export default features
