import React from 'react'

import PayUContextProvider from 'components/_functional/PayUContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import BasketBuyForm from 'components/buying/Basket'
import CardPaymentForm from 'components/buying/components/CardPaymentForm'
import Helmet from 'components/helmet/components/StaticPage'

const BASKET_TEXT = 'Koszyk - płatność'

export const BasketBuyPage = () => {
  return (
    <PayUContextProvider>
      <PageWrapper>
        <Helmet pageName={BASKET_TEXT} />
        <BasketBuyForm cardPaymentForm={<CardPaymentForm />} />
      </PageWrapper>
    </PayUContextProvider>
  )
}
