import React from 'react'

import { Button, Dialog, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useDictionary } from '../../../state/locale/hooks/useDictionary'
import { Checkbox } from '../../_forms/Checkbox'
import { Form } from '../../_forms/Form'
import { FormFields } from '../../_forms/_constants/FieldNames'
import { defaultValues } from '../../_forms/_constants/defaultValues'
import { validators } from '../../_forms/_validators'
import { LabelNewsletter } from '../../reusable/LabelNewsletter'
import { INewsletterDialogProps, INewsletterFormInput } from './NewsletterDialog.types'

export const NewsletterDialog = ({
  isVisible,
  onClose,
  onSubmitCallback,
}: INewsletterDialogProps) => {
  const { i18n } = useDictionary()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<INewsletterFormInput>({
    defaultValues: {
      [FormFields.NEWSLETTER]: defaultValues.unchecked,
    },
  })

  const onSubmit: SubmitHandler<INewsletterFormInput> = async (data) => {
    if (data[FormFields.NEWSLETTER]) {
      onSubmitCallback()
    }
  }

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      fullWidth
      maxWidth={'xs'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Wrapper>
        <Typography children={i18n.following.subscribeToFollow} />
        <Checkbox
          rules={validators.term(i18n.forms.validation[FormFields.NEWSLETTER].required)}
          name={FormFields.NEWSLETTER}
          control={control}
          label={<LabelNewsletter />}
        />
        <Button variant={'contained'} type={'submit'} children={i18n.buttons.save} fullWidth />
        <Button variant={'outlined'} children={i18n.buttons.cancel} onClick={onClose} fullWidth />
      </Form.Wrapper>
    </Dialog>
  )
}
