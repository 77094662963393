import { useSelector } from 'react-redux'

import _Store from '@Store'

import { IGetTransactionDetailsSuccessPayload } from 'models/transaction/types'

export const useTransactionDetailsFromStore = () =>
  useSelector<_Store.IState, IGetTransactionDetailsSuccessPayload | null>(
    ({ transaction }) => transaction.transactionDetails
  )
