import React, { useEffect } from 'react'

import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'

import { ISubmissionData } from 'models/entryList/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import stepNames from '../../steps'
import Paper from '../Paper'
import { makeValidationSchema } from './SelectSubmission.selector'
import useStyles from './SelectSubmission.styles'
import { ISelectSubmissionProps } from './SelectSubmission.types'

const SelectSubmission = ({
  submissions,
  handlePrevStep,
  selectSubmission,
  skipToSelectedStep,
  clearFormsData,
  confirmedSubmissions: confirmed,
  checkEmail,
  ticketData,
}: ISelectSubmissionProps) => {
  const { i18n } = useDictionary()
  const classes = useStyles()
  const globals = useGlobalStyles()

  const handleAddNewData = () => {
    checkEmail('')
    clearFormsData()
    skipToSelectedStep(stepNames.personalData)
  }

  useEffect(() => {
    if (ticketData?.entriesQuantity === confirmed.length) {
      handlePrevStep()
    }
  }, [])

  const getLabel = (item: ISubmissionData) => {
    return (
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={1}>
          <Grid item={true}>
            <Grid container={true} spacing={1}>
              <Grid item={true}>
                <Typography variant="body1">{item.firstname || item['4']}</Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant="body1">{item.lastname || item['5']}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Typography variant="body1" color="textSecondary">
              {item.dataurodzenia}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Paper
      title={
        !!confirmed.length
          ? i18n.entryList.selectSubmission.additionalPerson
          : i18n.entryList.selectSubmission.title
      }
      helperText={
        !!confirmed.length
          ? i18n.entryList.selectSubmission.additionalPersonHelper
          : i18n.entryList.selectSubmission.helper
      }
    >
      <Formik
        initialValues={{ submission: null }}
        onSubmit={(values) => selectSubmission(JSON.parse(values.submission || ''))}
        validationSchema={makeValidationSchema(i18n)}
      >
        {({ values, handleChange, errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormControl component="fieldset" error={!!(touched.submission && errors.submission)}>
              <RadioGroup name="submission" value={values.submission} onChange={handleChange}>
                <Grid container={true} spacing={2} justifyContent="center">
                  {!!(submissions && submissions.submission.length) &&
                    submissions.submission.map((item, index) => (
                      <Grid item={true} key={index} xs={12}>
                        <FormControlLabel
                          className={classes.radioButton}
                          value={JSON.stringify(item)}
                          control={<Radio color="primary" />}
                          label={getLabel(item.data)}
                        />
                      </Grid>
                    ))}
                  <Grid item={true} xs={12}>
                    <FormHelperText error={true}>
                      {!!(touched.submission && errors.submission) && errors.submission}
                    </FormHelperText>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      variant="text"
                      size="medium"
                      color="secondary"
                      startIcon={<AddIcon />}
                      fullWidth={true}
                      onClick={handleAddNewData}
                      className={classes.addNewSubmissionBtn}
                    >
                      {i18n.entryList.selectSubmission.addNew}
                    </Button>
                  </Grid>
                  <Grid item={true} xs={12} className={globals.gapTop}>
                    <Grid
                      container={true}
                      justifyContent="center"
                      alignItems="center"
                      className={globals.column}
                      spacing={1}
                    >
                      <Grid item={true}>
                        <Button
                          className={globals.button}
                          type="button"
                          variant="text"
                          color="primary"
                          onClick={handlePrevStep}
                        >
                          {i18n.entryList.selectSubmission.goBack}
                        </Button>
                      </Grid>
                      <Grid item={true}>
                        <Button
                          className={globals.button}
                          variant="contained"
                          size="medium"
                          color="primary"
                          type={'submit'}
                        >
                          {i18n.entryList.selectSubmission.goNext}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </form>
        )}
      </Formik>
    </Paper>
  )
}

export default SelectSubmission
