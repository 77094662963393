import { Theme, ThemeOptions, createTheme } from '@mui/material'
import { Components } from '@mui/material/styles/components'
import hangar from 'theme/variants/hangar'
import jumpcity from 'theme/variants/jumpcity'
import melt from 'theme/variants/melt'
import saltos from 'theme/variants/saltos'
import zagrywki from 'theme/variants/zagrywki'

import theme from 'config/theme'
import { IAvailableThemes } from 'models/theme/types'

export const goingTheme = {
  border: {
    radius: '1rem',
    style: 'none',
    width: '0px',
  },
  palette: {
    background: {
      default: '#f4f7f8',
      paper: '#ffffff',
    },
    gradient: {
      first: '#FE839F',
      second: '#EFE80D',
    },
    info: {
      contrastText: '#ffffff',
      main: '#60748B',
      light: '#dff3fe',
    },
    other: {
      stroke: 'rgba(0, 0, 0, 0.23)',
      gray: 'rgba(0,0,0,0.2)',
      activeRating: '#FFB400',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#ede40c',
      light: '#ffff57',
      main: '#eff200',
    },
    secondary: {
      contrastText: '#ffffff',
      darkBg: '#34495e',
      light: '#f3f3f3',
      main: '#849297',
    },
  },
  typography: {
    fontFamily: [
      '"Noir Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1rem',
    },
    fontWeightBold: 500,
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
      lineHeight: 1.2,
    },
  },
}

export const goingDarkTheme = {
  border: {
    radius: '1rem',
    style: 'none',
    width: '0px',
  },
  palette: {
    background: {
      default: '#111111',
      paper: '#282828',
    },
    gradient: {
      first: '#FE839F',
      second: '#EFE80D',
    },
    info: {
      contrastText: '#ffffff',
      main: '#60748B',
    },
    other: {
      stroke: 'rgba(255, 255, 255, 0.23)',
      gray: 'rgba(0,0,0,0.2)',
      activeRating: '#FFB400',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#ede40c',
      light: '#ffff57',
      main: '#eff200',
    },
    secondary: {
      contrastText: '#111111',
      darkBg: '#34495e',
      light: '#f3f3f3',
      main: '#849297',
    },
    text: {
      disabled: '#666666',
      hint: '#AAAAAA',
      primary: '#FFFFFF',
      secondary: '#849297',
    },
  },
  typography: {
    fontFamily: [
      '"Noir Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '1.125rem',
    },
    fontWeightBold: 500,
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
  },
}

export const empikTheme = {
  border: {
    radius: '0',
    style: 'none',
    width: '0px',
  },
  palette: {
    gradient: {
      first: '#FE839F',
      second: '#EFE80D',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#692acb',
      light: '#d58aff',
      main: '#9f5aff',
    },
    secondary: {
      contrastText: '#ffffff',
      darkBg: '#c30000',
      light: '#ff6052',
      main: '#ff1627',
    },
    other: {
      gray: 'rgba(0,0,0,0.2)',
    },
  },
  typography: {
    fontFamily: [
      '"empik Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '1.5rem',
    },
    fontWeightBold: 700,
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
}

const themeSelector = (isDark: boolean) =>
  theme.isEmpik ? empikTheme : isDark ? goingDarkTheme : goingTheme

const muiTheme = (selectedTheme?: IAvailableThemes): Theme => {
  let isDarkTheme = false

  try {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location.href)
      const { searchParams } = url

      const isDarkParam = searchParams.get('isDark')

      if (isDarkParam) isDarkTheme = JSON.parse(isDarkParam)
    }
  } catch (error) {
    console.error(error)
  }

  const currentTheme =
    selectedTheme && selectedTheme !== 'default'
      ? themes[selectedTheme]
      : themeSelector(isDarkTheme)

  return createTheme({
    ...currentTheme,
    spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160, 200],
  })
}

const currentTheme = muiTheme()

const isMobile = currentTheme.breakpoints.down('sm')
const isTablet = currentTheme.breakpoints.down('lg')
const isDesktop = currentTheme.breakpoints.up('md')

const themes: { [key: string]: ThemeOptions } = {
  Hangar646: hangar(isMobile),
  Saltos: saltos(),
  Zagrywki: zagrywki(isTablet),
  'Zagrywki Wrocław': zagrywki(isTablet),
  'Jump City': jumpcity(),
  'JumpCity Gdansk': jumpcity(),
  'JUMPCITY RUMIA': jumpcity(),
  'JumpCity Gdynia': jumpcity(),
  'Melt Museum': melt(),
}

export enum spacing {
  noSpacing,
  tiny,
  small,
  regular,
  medium,
  large,
  huge,
  humongous,
  enormous,
  colossal,
}

export const components: (currentTheme: Theme) => Components<Omit<Theme, 'components'>> = (
  currentTheme
) => ({
  MuiAppBar: {
    variants: [
      {
        props: { color: 'default' },
        style: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          boxShadow: 'none',
        },
      },
    ],
    styleOverrides: {
      root: {
        height: 64,
        [isDesktop]: {
          height: 96,
        },
      },
    },
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: '0.5rem',
      },
      textPrimary: {
        color: currentTheme.palette.primary.contrastText,
      },
    },
    variants: [
      {
        props: { color: 'primary', variant: 'outlined' },
        style: {
          '&.MuiButton-outlinedSizeLarge': {
            borderColor: theme.isEmpik ? '#EBDDFF' : currentTheme.palette.primary.main,
          },
          color: currentTheme.palette.text.primary,
        },
      },
      {
        props: { size: 'large' },
        style: {
          fontSize: 20,
          fontWeight: 700,
          lineHeight: 1,
          [isDesktop]: {
            minHeight: 64,
          },
        },
      },
      {
        props: { size: 'large', variant: 'outlined' },
        style: {
          borderColor: theme.isEmpik ? '#EBDDFF' : currentTheme.palette.text.primary,
          color: currentTheme.palette.text.primary,
          paddingBottom: currentTheme.spacing(3),
          paddingLeft: currentTheme.spacing(4),
          paddingRight: currentTheme.spacing(4),
          paddingTop: currentTheme.spacing(3),
        },
      },
      {
        props: { size: 'large', variant: 'contained' },
        style: {
          paddingBottom: currentTheme.spacing(3),
          paddingLeft: currentTheme.spacing(4),
          paddingRight: currentTheme.spacing(4),
          paddingTop: currentTheme.spacing(3),
        },
      },
      {
        props: { size: 'medium', variant: 'outlined' },
        style: {
          paddingBottom: 12,
          paddingLeft: currentTheme.spacing(3),
          paddingRight: currentTheme.spacing(3),
          paddingTop: 12,
          borderColor: currentTheme.palette.text.primary,
          color: currentTheme.palette.text.primary,
          textTransform: 'unset',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          padding: '12px 24px',
          textTransform: 'none',
          [isDesktop]: {
            minHeight: 48,
          },
        },
      },
      {
        props: { size: 'medium', variant: 'contained', color: 'secondary' },
        style: {
          padding: '12px 16px',
          backgroundColor: '#212121',
        },
      },
    ],
  },
  MuiTypography: {
    variants: [
      {
        props: { variant: 'body2' },
        style: {
          [isMobile]: {
            fontSize: '0.875rem',
          },
        },
      },
      {
        props: { variant: 'h2' },
        style: {
          [isMobile]: {
            fontSize: 32,
          },
        },
      },
    ],
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        fontSize: currentTheme.typography.caption.fontSize,
        lineHeight: 1,
        marginBottom: currentTheme.spacing(4),
        marginTop: currentTheme.spacing(4),
      },
      separator: {
        color: currentTheme.palette.primary.main,
        fontWeight: currentTheme.typography.fontWeightBold,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: currentTheme.palette.background.paper,
        borderRadius: 4,
      },
      deleteIcon: {
        color: currentTheme.palette.primary.main,
      },
    },
    variants: [
      {
        props: { color: 'primary' },
        style: {
          backgroundColor: currentTheme.palette.primary.main,
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          backgroundColor: 'transparent',
        },
      },
    ],
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        padding: 16,
        borderRadius: 16,
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: currentTheme.palette.text.primary,
        textDecorationColor: currentTheme.palette.primary.main,
        textDecorationThickness: 2,
        textUnderlineOffset: 6,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 42,
      },
    },
  },
  MuiDateCalendar: {
    styleOverrides: {
      root: {
        backgroundColor: currentTheme.palette.background.paper,
        borderColor: currentTheme.border.color,
        borderRadius: currentTheme.border.radius,
        borderStyle: currentTheme.border.style,
        borderWidth: currentTheme.border.width,
        maxHeight: 'none',
        maxWidth: 396,
        padding: '0 16px',
        paddingBottom: 16,
        width: '100%',

        '& .MuiDayCalendar-monthContainer': {
          paddingTop: 32,
        },

        '& .MuiPickersCalendarHeader-root': {
          padding: 0,
        },

        '& .MuiPickersCalendarHeader-labelContainer': {
          marginLeft: 16,

          '& .MuiPickersCalendarHeader-label': {
            textTransform: 'capitalize',
          },
        },
        '& .MuiPickersSlideTransition-root': {
          minHeight: 292,
          position: 'static',
        },

        '& .MuiDayCalendar-weekDayLabel': {
          color: currentTheme.palette.text.primary,
          fontSize: 14,
          fontWeight: '500',
          width: '3rem',
        },

        '& .MuiPickersDay-root': {
          borderRadius: currentTheme.border.radius,
          fontSize: 16,
          fontWeight: '500',
          height: '3rem',
          width: '3rem',
          '&.Mui-selected': {
            backgroundColor: currentTheme.palette.primary.main,
          },
        },
      },
    },
  },
})

export const mui = (selectedTheme?: IAvailableThemes) => {
  const currentTheme = muiTheme(selectedTheme)

  return {
    fontIconsUrl: 'https://fonts.googleapis.com/icon?family=Material+Icons',
    iconMenuId: 'icon-menu',
    mobileMenuId: 'menu',
    spacing,
    theme: {
      ...currentTheme,
      components: selectedTheme !== 'default' ? currentTheme.components : components(currentTheme),
    },
  }
}
