import React, { FC } from 'react'

import styles from 'components/TicketReception/WithEventDetailBox/WithEventDetailBox.module.scss'
import { IWithEventDetailBoxProps } from 'components/TicketReception/WithEventDetailBox/WithEventDetailBox.types'
import Summary from 'components/eventBoxes/Summary/Summary.component'

const EventDetailBox: FC<IWithEventDetailBoxProps> = ({
  children,
  event,
  isEmbed,
  isLoading,
  receivedEvent,
  summary,
  ticketTransferFee,
  receivedTickets,
}) => (
  <div className={styles.twoColumns}>
    <div className={styles.fixedBox}>
      <Summary
        event={event || receivedEvent}
        isLoading={isLoading}
        isEmbed={isEmbed}
        products={[]}
        receivedTickets={receivedTickets}
        summary={summary}
        selectedTickets={[]}
        selectedProducts={[]}
        transferTicketFee={ticketTransferFee}
        isTransferTicket={true}
        selectedTheme={'default'}
        shipments={[]}
        selectedExtendedSlot={null}
        setFinalPriceAction={() => 0}
        setInsuranceSummaryData={() => {}}
      />
    </div>

    <div className={styles.form}>{children}</div>
  </div>
)

export default EventDetailBox
