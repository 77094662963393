import { createSelector } from 'reselect'

import _Store from '@Store'

import { IArticleReducer, IArticleReducerCurrentKey } from 'types/Article'

import get from './get'

const getCurrentKey = createSelector<_Store.IState, IArticleReducer, IArticleReducerCurrentKey>(
  [get],
  (article) => article.currentKey
)

export default getCurrentKey
