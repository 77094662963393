import { Accordion, Box, Paper, styled } from '@mui/material'

export const Styled = {
  ActionsWrapper: styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    borderRadius: 16,
    overflow: 'hidden',
  })),
  Accordion: styled(Accordion)(({ theme: { breakpoints, spacing } }) => ({
    padding: spacing(3),
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
    borderRadius: 16,
    '&:before': { display: 'none' },
  })),
  Paper: styled(Paper, { shouldForwardProp: (prop) => prop !== 'isMobile' })<{ isMobile: boolean }>(
    ({ theme, isMobile }) => ({
      flex: 1,
      p: isMobile ? 3 : 4,
      borderRadius: 4,
      gap: 5,
      display: 'flex',
      flexDirection: 'column',
    })
  ),
}
