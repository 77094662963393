/**
 * Function that create Rundate normalization for calendar (slots) Event
 *
 * @param {IRundateResponse} data - Event data
 *
 * @return {IEventFull} Event object.
 */
import { CurrencyISO } from 'constants/Currency'
import { IEventFull } from 'models/event/types'
import { IRundateEventResponse } from 'types/EventRundate'

const fillRundateData = (data: IRundateEventResponse): IEventFull => {
  const {
    artists,
    category,
    externalImages,
    formattedDescription,
    formattedPartnerDescription,
    id: eventId,
    images,
    partner: { id: partnerId, name: partnerName, gtmId },
    place,
    rundatesCount,
    slug: eventSlug,
    teaser,
    titlePL,
  } = data

  return {
    additionalDescription: formattedPartnerDescription ? formattedPartnerDescription : '',
    artists,
    buttonLabel: null,
    category,
    calendarEvent: !!data?.calendarEvent,
    courierDelivery: false,
    customTerms: [],
    description: formattedDescription ? formattedDescription : '',
    eventId,
    eventSlug,
    externalImages,
    freeOfCharge: false,
    friendlyDate: '',
    gtmId,
    hasManyPools: false,
    imagesUrl: images.length > 0 ? images[0].large || '' : '',
    isAvailable: true,
    notForSale: false,
    partnerId,
    partnerName,
    passed: false,
    placeAddress: place?.address || '',
    placeCategory: null,
    placeCityName: place?.city.name || '',
    placeCitySlug: place?.city.slug || '',
    placeDescription: place?.description,
    placeFriendly: place ? `${place.name}, ${place.address}` : '',
    placeId: place?.id || 0,
    placeImageUrl: place?.thumb,
    placeLat: place?.lat || 52.2330653,
    placeLon: place?.lon || 20.9211125,
    placeName: place?.name || '',
    placeSlug: place?.slug || '',
    price: null,
    priceWithServiceFee: null,
    priceDescription: '',
    rundateId: 0,
    rundateSlug: '',
    rundatesCount,
    startDate: '',
    tags: [],
    teaser,
    title: titlePL,
    videoId: null,
    currency: CurrencyISO.PLN,
  }
}

export default fillRundateData
