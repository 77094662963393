import { IBasketPool } from 'components/eventBoxes/Summary/Summary.types'
import { IBasket } from 'models/basket/types'

const getBasketFormattedPools = (basketItems?: IBasket[]) => {
  if (basketItems) {
    basketItems.forEach((v) => {
      v.pools = []
    })

    if (basketItems.length !== 0 && basketItems.some((item) => item.isPlaceEvent === true)) {
      return basketItems
    }

    const arrayHashmap = [...basketItems].reduce((obj, item) => {
      obj[item.eventId]
        ? obj[item.eventId].pools.push({
            additionalFields: item.additionalFields,
            amount: item.amount,
            currency: item.currency,
            id: item.id,
            poolName: item.poolName,
            poolPrice: item.poolPrice,
            poolPriceWithFee: item.price,
            serviceFee: item.serviceFee,
            totalPoolPrice: item.totalPoolPrice,
          })
        : (obj[item.eventId] = { ...item })

      return obj
    }, {} as { [key: string]: IBasket })

    const mergedArray: IBasket[] = Object.values(arrayHashmap)

    const reducedPools = mergedArray.map((item) => {
      return {
        ...item,
        pools: item.pools.reduce((final, data) => {
          const isAlready = final.some((value) => value.id === data.id)

          if (!isAlready) {
            final.push(data)
          } else {
            const index = final.findIndex((value) => value.id === data.id)
            final[index].amount = final[index].amount + data.amount
            final[index].poolPriceWithFee =
              (final[index].poolPriceWithFee || 0) + (data.poolPriceWithFee || 0)
            final[index].serviceFee = (final[index].serviceFee || 0) + (data.serviceFee || 0)
            final[index].totalPoolPrice =
              (final[index].totalPoolPrice || 0) + (data.totalPoolPrice || 0)
          }

          return final
        }, [] as IBasketPool[]),
      }
    })

    const formattedItems = reducedPools?.map((item) => {
      const itemFound = item.pools.find((value) => value.id === item.id)

      if (itemFound) {
        return {
          ...item,
          amount: item.amount + itemFound.amount,
          artists: item.artists,
          pools: item.pools.filter((pool) => pool.id !== item.id),
          price: item.price + (itemFound.poolPriceWithFee || 0),
          serviceFee: (item.serviceFee || 0) + (itemFound.serviceFee || 0),
          totalPoolPrice: (item.totalPoolPrice || 0) + (itemFound.totalPoolPrice || 0),
        }
      }

      return item
    })

    return formattedItems
  }
}

export default getBasketFormattedPools
