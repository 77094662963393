import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { redirectParentTo } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'

import BasketButton from './BasketButton.component'
import {
  IBasketButtonFromDispatch,
  IBasketButtonFromState,
  IBasketButtonOwnProps,
} from './BasketButton.types'

const mapStateToProps = (state: _Store.IState): IBasketButtonFromState => ({
  addToBasketRedirect: getIframeParams(state)?.addToBasketRedirect,
})
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IBasketButtonFromDispatch => ({
  redirectParentTo: (url) => dispatch(redirectParentTo(url)),
})
export default connect<
  IBasketButtonFromState,
  IBasketButtonFromDispatch,
  IBasketButtonOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(BasketButton)
