import axios, { CancelTokenSource } from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import { ICheckEmailBody, ICheckEmailResponse } from 'services/$sendgrid-api/types'

class SendgridApi {
  public cancelTokenSendgrid?: CancelTokenSource

  private emailCheckUrl = `${config.api.baseUrl}user/address-email/check`

  public checkEmail = async (body: ICheckEmailBody): Promise<ICheckEmailResponse> =>
    new Promise((resolve, reject) => {
      this.cancelTokenSendgrid = axios.CancelToken.source()

      return axios
        .post(
          this.emailCheckUrl,
          body,
          withCacheHeader({
            cancelToken: this.cancelTokenSendgrid.token,
          })
        )
        .then(getData)
        .then((response: ICheckEmailResponse) => {
          resolve(response)
        })
        .catch((error) => {
          reject(catchHttpError(new Error(error)))
        })
    })

  public cancelSendgrid() {
    if (this.cancelTokenSendgrid) {
      this.cancelTokenSendgrid.cancel()
      this.cancelTokenSendgrid = undefined
    }
  }
}

export default new SendgridApi()
