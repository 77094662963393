import { getType } from 'typesafe-actions'

import { locationChange, navigate } from './../../actions'
import { IInternalRouterData, ITypesafeActions } from './../../types'

export const initialState: IInternalRouterData = {
  module: null,
  params: null,
  location: null,
  navigate: undefined,
}

const reducer = (
  state: IInternalRouterData = initialState,
  action: ITypesafeActions
): IInternalRouterData => {
  switch (action.type) {
    case getType(locationChange):
      return {
        ...state,
        ...action.payload,
      }

    case getType(navigate):
      return {
        ...state,
        navigate: action.payload,
      }

    default:
      return state
  }
}

export default reducer
