import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { CloudinaryPrefixes } from 'constants/Cloudinary'
import { Languages } from 'constants/Languages'
import { fetchCloudinaryList } from 'services/Api/Cloudinary'
import { Fetcher } from 'services/Api/Fetcher'
import { LuceneQueryBuilder } from 'services/Api/LuceneQueryBuilder'
import { PlaceApi } from 'services/Api/PlaceApi'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { ICloudSearchHit, ICloudSearchResponse } from 'types/CloudSearch'
import { ITag } from 'types/Common'
import { IEventResponse } from 'types/Event'
import { IActivityResponse } from 'types/EventActivity'
import { IRundateResponse } from 'types/EventRundate'
import { IPoolsResponse } from 'types/Pools'

export class EventApi {
  public static getFullEvent = async ([slug]: [slug: string]) => {
    try {
      const event = await Fetcher.get<IEventResponse>(URI.GetEvent(slug))
      const similarEvents = await EventApi.getSimilarEvents(slug, event.category, event.tags)

      return {
        event,
        similarEvents,
      }
    } catch (e) {
      Logger.error('EventApi.getFullEvent', e)
    }
  }
  public static getFullRundate = async ([slug, rundateSlug, lang]: [
    slug: string,
    rundateSlug: string,
    lang: Languages
  ]) => {
    try {
      const rundate = await Fetcher.get<IRundateResponse>(URI.GetEventRundate(slug, rundateSlug))
      const pools = await Fetcher.get<IPoolsResponse>(URI.GetPools(slug, rundateSlug, lang))
      const placeEvents = await PlaceApi.getPlaceEvents(slug, rundate.place?.id)
      const similarEvents = await EventApi.getSimilarEvents(
        slug,
        rundate.event.category,
        rundate.event.tags
      )
      const cloudinaryImages = await fetchCloudinaryList({
        slug,
        prefix: CloudinaryPrefixes.RUNDATE,
        additionalSlug: rundateSlug,
      })

      return {
        eventData: { rundate, pools },
        placeEvents,
        similarEvents,
        cloudinaryImages,
      }
    } catch (e) {
      Logger.error('EventApi.getFullRundate', e)
    }
  }

  public static getFullActivity = async (slug: string) => {
    try {
      const activity = await Fetcher.get<IActivityResponse>(URI.GetEventActivity(slug))
      const placeActivities = await PlaceApi.getPlaceActivities(slug, activity.place.id)
      const cloudinaryImages = await fetchCloudinaryList({ slug, prefix: CloudinaryPrefixes.EVENT })

      return { activityData: activity, placeActivities, cloudinaryImages }
    } catch (e) {
      Logger.error('ActivityApi.getFullActivity', e)
    }
  }

  private static getSimilarEvents = async (
    slug: string,
    category: ITag,
    tags: ITag[],
    tagsCompareLimit: number = 2
  ): Promise<ICloudSearchHit[]> => {
    const similarEventsUrl = LuceneQueryBuilder.buildCloudSearchUrl({
      and: [
        { [HitFields.type]: SearchTypes.RUNDATE },
        { [HitFields.tags_ids]: tags.map((tag) => tag.id).slice(0, tagsCompareLimit) },
      ],
      categoryFilter: {
        name: category.name,
        id: category.id,
        slug: category.slug,
      },
      not: [{ [HitFields.slug]: slug }],
      size: 4,
    })

    try {
      const similarEvents = await Fetcher.get<ICloudSearchResponse>(similarEventsUrl)

      return similarEvents.hits.hit
    } catch (e) {
      Logger.error('EventApi.getSimilarEvents', e)

      return []
    }
  }
}
