import qs from 'qs'

import config from 'config'
import { Languages } from 'constants/Languages'
import { ProfileType } from 'types/Profile'

export class URI {
  /**
   * LOCATIONS
   */
  public static GetLocations = `${config.api.baseUrl}mainpage/locations/${config.app.salesChannelId}`

  /**
   * SEARCH
   */
  public static CloudSearch = config.api.cloudSearchUrl + '/search'

  /**
   * ARTIST
   */
  public static GetArtist = (slug: string) => `${config.api.baseUrl}artist/${slug}`

  /**
   * BRAZE
   */
  public static brazeIdentifyEndpoint = `${config.braze.brazeRestApiUrl}/users/identify`
  public static brazeGetUserData = `${config.braze.brazeRestApiUrl}/users/export/ids`
  public static brazeChangeSubscription = `${config.braze.brazeRestApiUrl}/email/status`

  /**
   * EVENT
   */
  public static GetEventActivity = (slugOrId: string | number) =>
    `${config.api.baseUrl}events/${slugOrId}`
  public static GetEventRundate = (eventSlug: string, rundateSlug: string) =>
    `${config.api.baseUrl}events/rundates/${eventSlug}/${rundateSlug}`
  public static GetEvent = (eventSlug: string) => `${config.api.baseUrl}events/${eventSlug}`

  /**
   * FAVORITES
   */
  public static AddToFavorites = (lang: Languages = Languages.Polski) =>
    `${config.api.firebaseFunctionsUrl}/addToFavoritesWithUserEmailCheck?lang=${lang}`

  /**
   * PAGES
   */
  public static getMainPage = (locationSlug?: string, version?: string) =>
    `${config.api.baseUrlV2}page/${config.app.serviceName}/mainpage/${
      locationSlug ?? ''
    }${qs.stringify({ version }, { addQueryPrefix: true })}`
  public static getInspirations = (slug?: string, version?: string) =>
    `${config.api.baseUrlV2}page/${config.app.serviceName}/inspirations/${slug ?? ''}${qs.stringify(
      { version },
      { addQueryPrefix: true }
    )}`
  public static getActivities = (slug?: string, version?: string) =>
    `${config.api.baseUrlV2}page/${config.app.serviceName}/activities/${slug ?? ''}${qs.stringify(
      { version },
      { addQueryPrefix: true }
    )}`
  public static getAboutPage = () =>
    `${config.api.baseUrlV2}page/${config.app.serviceName}/recommend/o-aplikacji`
  public static getHelpPage = () =>
    `${config.api.baseUrlV2}page/${config.app.serviceName}/recommend/going-help`

  /**
   * PLACE
   */
  public static GetPlace = (placeSlug: string) => `${config.api.baseUrl}place/${placeSlug}`

  /**
   * POOLS
   */
  public static GetPools = (eventSlug: string, rundateSlug?: string, lang?: string) => {
    const uri = rundateSlug
      ? `${config.api.baseUrl}rundate/${eventSlug}/${rundateSlug}/pools`
      : `${config.api.baseUrl}rundate/${eventSlug}/pools`

    return uri + qs.stringify({ language: lang }, { addQueryPrefix: true })
  }

  /**
   * SELECTION
   */
  public static getSelection = (slug: string) => `${config.api.baseUrl}selection/${slug}`

  /**
   * TICKET MANAGEMENT
   */
  public static getTicket = (code: string, mail: string): string =>
    `${config.api.baseUrl}ticket/${code}/${mail}`
  public static downloadTicket = (ticketId: string | number, code: string, email: string) =>
    `${config.api.api3Url}/ticket/${ticketId}/pdf/code/${code}/${email}`
  public static transferTicketFee = () => `${config.api.baseUrl}products?type=transfer`
  public static transferTicket = () => `${config.api.baseUrl}transfer-ticket`
  public static returnTicket = () => `${config.api.baseUrl}ticket/request-refund`
  public static getPass = (code: string, email: string) =>
    `${config.api.baseUrl}pass/info/${code}/${email}`

  /**
   * TRANSACTIONS
   */
  public static GetExtendedPaymentTransaction = (id: string) =>
    `${config.api.baseUrl}transaction-details/${id}`
  public static FinalizeTransaction = (id: string) =>
    `${config.api.baseUrl}transaction-payment/${id}`

  /**
   * PROFILE
   */
  public static GetProfile = (type: ProfileType, slug: string) => {
    const profileType = Object.keys(ProfileType)[Object.values(ProfileType).indexOf(type)]

    return `${config.api.baseUrl}resource/${profileType}/${slug}`
  }

  public static googleReview = 'https://g.page/r/CQNxl222K-ISEBM/review'
  /**
   * RETURN BANK ACCOUNT
   */
  public static returnBankAccountNumber = (hash: string) =>
    `${config.api.baseUrl}ticket/refund-bank-update/${hash}`

  /**
   * HAPPENING
   */
  public static getPaymentMethods = (happeningSlug: string, spaceSlug: string) =>
    `${config.api.baseUrl}rundate/${happeningSlug}/${spaceSlug}/pools-payments`

  /**
   * USER
   */
  public static GetUser = `${config.api.baseUrl}user/firebase`
  public static UpdateUser = `${config.api.baseUrl}user/update/firebase`
  public static generateSignInLink = `${config.api.firebaseFunctionsUrl}/generateSignInLink`
  public static unverifyUserEmail = `${config.api.firebaseFunctionsUrl}/unverifyUserEmail`
}
