import React from 'react'

import { Avatar, AvatarGroup } from '@mui/material'

import { IAvatarsProps } from './Avatars.types'
import { images } from './_mocks'

export const Avatars = ({ length }: IAvatarsProps) =>
  length !== 0 ? (
    <AvatarGroup max={4}>
      {images.slice(0, length).map((src, index) => (
        <Avatar key={`avatar-${index}`} sx={{ width: 16, height: 16 }} src={src} />
      ))}
    </AvatarGroup>
  ) : null
