import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import {
  clearFormsErrors,
  handleNextStep,
  handlePrevStep,
  requestDataFromTicket,
  resetForm,
  setHistoryItem,
} from 'models/entryList/actions'
import { getErrors } from 'models/entryList/selectors'

import TicketCode from './TicketCode.component'
import { ITicketCodeFromDispatch, ITicketCodeFromState } from './TicketCode.types'

const mapStateToProps = (state: _Store.IState): ITicketCodeFromState => ({
  errors: getErrors(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ITicketCodeFromDispatch => ({
  handlePrevStep: () => dispatch(handlePrevStep()),
  handleNextStep: (step) => dispatch(handleNextStep(step)),
  requestDataFromTicket: (code) => dispatch(requestDataFromTicket(code)),
  clearErrors: () => dispatch(clearFormsErrors()),
  resetForm: () => dispatch(resetForm()),
  setHistoryItem: (step) => dispatch(setHistoryItem(step)),
})

export default connect<ITicketCodeFromState, ITicketCodeFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(TicketCode)
