import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import ResizeObserver from 'resize-observer-polyfill'

import { resizeEmbed } from 'models/internalRouter/actions'
import { useIsEmbedFromStore } from 'models/internalRouter/hooks/useIsEmbedFromStore'
import { useAppState } from 'state/app/hooks/useAppState'

export const EmbedResizer = () => {
  const { isBrowser } = useAppState()
  const dispatch = useDispatch()
  const { isEmbed } = useIsEmbedFromStore()

  useEffect(() => {
    if (isBrowser && isEmbed) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) dispatch(resizeEmbed(entry.contentRect.height))
      })

      resizeObserver.observe(document.body)

      return () => resizeObserver.unobserve(document.body)
    }
  }, [isEmbed])

  return null
}
