import React, { Suspense } from 'react'

import qs from 'qs'
import { useLocation, useParams } from 'react-router-dom'

import { PageWrapper } from 'components/_layout/PageWrapper'
import { SectionContainer } from 'components/_layout/SectionContainer'
import Helmet from 'components/helmet/components/ContactPage'
import SimpleLoading from 'components/reusable/SimpleLoading'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const Formio = React.lazy(() => import('components/Formio'))

export const FormioPage = () => {
  const { slug } = useParams<{ slug: string }>()
  const { search } = useLocation()
  const { i18n } = useDictionary()

  if (!slug) return <SimpleLoading />

  return (
    <PageWrapper>
      <Helmet pageName={i18n.formio.title} />
      <Suspense fallback={<SimpleLoading />}>
        <SectionContainer title={i18n.formio.title} container>
          <Formio slug={slug} queryParams={qs.parse(search, { ignoreQueryPrefix: true })} />
        </SectionContainer>
      </Suspense>
    </PageWrapper>
  )
}
