import { Selector } from 'reselect'

import _Store from '@Store'

import { ICustomTerm } from 'types/EventCommon'

const getHappeningTerms: Selector<_Store.IState, ICustomTerm[]> = (state) =>
  state.happening.terms || []

export default getHappeningTerms
