import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import _Store from '@Store'

import { validateForm } from 'models/analytics/actions'
import { basketBuyFormMounted, updateBasketStateFromContext } from 'models/basket/actions'
import { reserveSlots } from 'models/happening/actions'
import { setBasketTheme } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'
import { isDataHydratedTransaction } from 'models/pools/selectors'
import { buyAndPayOnline } from 'models/transaction/actions'

import { getInitialValues } from './../Online/Online.selectors'
import Basket from './Basket.component'
import { IBasketFromDispatch, IBasketFromState, IBasketOwnProps } from './Basket.types'

const mapStateToProps = (state: _Store.IState): IBasketFromState => ({
  initialValues: (basketCustomTerms) => getInitialValues(state, basketCustomTerms),
  isDataHydratedTransaction: isDataHydratedTransaction(state),
  basketTheme: getIframeParams(state)?.basketTheme,
})

const mapDispatchToProps = (dispatch: Dispatch): IBasketFromDispatch => {
  return {
    buyAndPay: (values) => dispatch(buyAndPayOnline(values)),
    checkout: () => dispatch(validateForm()),
    mounted: () => dispatch(basketBuyFormMounted()),
    updateBasketState: (basketState) => dispatch(updateBasketStateFromContext(basketState)),
    setBasketTheme: (theme) => dispatch(setBasketTheme(theme)),
    reserve: (values) => dispatch(reserveSlots(values)),
  }
}

export default connect<void, IBasketFromDispatch, IBasketOwnProps, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Basket)
