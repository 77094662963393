import { connectRouter } from 'connected-react-router'
import { createMemoryHistory } from 'history'
import { combineReducers } from 'redux'

// Base reducer
import { __BASE__ } from 'models/__base__/constants/constants'
import baseReducer from 'models/__base__/reducer'
// App reducer: import
import analyticsReducer from 'models/analytics/reducer'
import appReducer from 'models/app/reducer'
import articleReducer from 'models/article/reducer'
import authReducer from 'models/auth/reducer'
import basketReducer from 'models/basket/reducer'
import entryListReducer from 'models/entryList/reducer'
import errorsReducer from 'models/errors/reducer'
import eventReducer from 'models/event/reducer'
import happeningReducer from 'models/happening/reducer'
import imagesReducer from 'models/images/reducer'
import internalRouterReducer from 'models/internalRouter/reducer'
import landingPageReducer from 'models/landingPage/reducer'
import modalsReducer from 'models/modals/reducer'
import notificationsReducer from 'models/notifications/reducer'
import pagesReducer from 'models/pages/reducer'
import poolsReducer from 'models/pools/reducer'
import premiumReducer from 'models/premium/reducer'
import productsReducer from 'models/products/reducer'
import sendgridReducer from 'models/sendgrid/reducer'
import shipmentReducer from 'models/shipment/reducer'
import stateReducer from 'models/state/reducer'
import themeReducer from 'models/theme/reducer'
import ticketsReducer from 'models/tickets/reducer'
import trafficParamsReducer from 'models/trafficParams/reducer'
import transactionReducer from 'models/transaction/reducer'

import history from './history'

const reducerList = {
  // __base__
  [__BASE__]: baseReducer,

  // router
  router: connectRouter(history),

  // App reducer: usage
  analytics: analyticsReducer,
  app: appReducer,
  article: articleReducer,
  auth: authReducer,
  basket: basketReducer,
  errors: errorsReducer,
  event: eventReducer,
  happening: happeningReducer,
  images: imagesReducer,
  internalRouter: internalRouterReducer,
  landingPage: landingPageReducer,
  modals: modalsReducer,
  notifications: notificationsReducer,
  pages: pagesReducer,
  pools: poolsReducer,
  premium: premiumReducer,
  products: productsReducer,
  sendgrid: sendgridReducer,
  shipment: shipmentReducer,
  state: stateReducer,
  theme: themeReducer,
  tickets: ticketsReducer,
  transaction: transactionReducer,
  trafficParams: trafficParamsReducer,
  entryList: entryListReducer,
}

/* @@STORE_COMPONENT@@ */
const rootReducer = combineReducers(reducerList)

let hmrRootReducer = rootReducer

export const createRootReducerForSSR = (initialUrl: string) => {
  return combineReducers({
    ...reducerList,
    router: connectRouter(
      createMemoryHistory({
        initialEntries: [initialUrl],
      })
    ),
    routerIsFirstRendering: () => true,
  })
}

////////////////////////////////////////////////////////////////////////////////
//                           DEVELOPMENT MODE ONLY                            //
////////////////////////////////////////////////////////////////////////////////
if (process.env.NODE_ENV === 'development') {
  if (typeof localStorage !== 'undefined') {
    const hmr = localStorage.getItem('@@hmr')

    if (hmr) {
      const state = JSON.parse(hmr)

      if (state) {
        localStorage.removeItem('@@hmr')

        hmrRootReducer = (_state: any, action: any) => {
          if (action.type === '@@INIT') {
            return state
          }

          return rootReducer(_state, action)
        }
      }
    }
  }
}
////////////////////////////////////////////////////////////////////////////////

export default hmrRootReducer as typeof rootReducer
