import React from 'react'

import { Formik } from 'formik'

import { IParentData } from 'models/entryList/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import stepNames from '../../steps'
import ParentFormLayout from '../ParentFormLayout'
import { makeValidationSchema } from './ParentData.selector'
import { IParentDataProps } from './ParentData.types'

const ParentData = ({
  initialValues,
  setParentData,
  handleNextStep,
  generateAndSendSmsCode,
}: IParentDataProps) => {
  const { i18n } = useDictionary()
  const { code: locale } = useLang()

  const handleFormSubmit = (data: IParentData) => {
    setParentData(data)
    const generatedCode = Math.floor(1000 + Math.random() * 9000).toString()
    generateAndSendSmsCode({ code: generatedCode, lang: locale })
    handleNextStep(stepNames.smsCode)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={makeValidationSchema(i18n)}
      onSubmit={handleFormSubmit}
      component={ParentFormLayout}
    />
  )
}
export default ParentData
