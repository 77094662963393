import { createSelector } from 'reselect'

import { Languages } from 'constants/Languages'

import getData from './getData'
import getSelected from './getSelected'

const getSelectedSpaceDescription = createSelector(
  [getData, getSelected],
  (happening, selected) => {
    const space = happening?.spaces.find((_space) => _space.id === selected.space)

    return space?.description.find((desc) => desc.language === Languages.Polski)
  }
)

export default getSelectedSpaceDescription
