import { Theme } from '@mui/material'

export const useStyles = (theme: Theme) => ({
  salesButton: {
    backgroundColor: theme.palette.text.primary,
    borderRadius: '2rem',
    padding: '0.5rem 1rem',
    color: theme.palette.secondary.contrastText,
  },
})
