import React, { Dispatch, FC, SetStateAction, createContext, useEffect, useState } from 'react'

import { useTheme } from '@mui/material'

import { IPayUContextProps } from 'components/_functional/PayUContextProvider/PayUContextProvider.types'
import config from 'config'
import loadDOMScript from 'misc/helpers/loadDOMScript'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const initialState: {
  payuInstance: payu.PayU | undefined
  secureForms: payu.SecureForms | undefined
  cardNumberForm: payu.SecureForm | undefined
  cardDateForm: payu.SecureForm | undefined
  cardCVVForm: payu.SecureForm | undefined
  handleTokenizeCard: ((payu: payu.PayU) => Promise<'SUCCESS' | 'ERROR'>) | undefined
  setIsCardPaymentMethod: Dispatch<SetStateAction<boolean>> | undefined
  isCardPaymentMethod: boolean
  setCardBrand: Dispatch<SetStateAction<string | undefined>> | undefined
  cardBrand: string | undefined
  inputError: string | undefined
} = {
  payuInstance: undefined,
  secureForms: undefined,
  cardNumberForm: undefined,
  cardDateForm: undefined,
  cardCVVForm: undefined,
  handleTokenizeCard: undefined,
  setIsCardPaymentMethod: undefined,
  isCardPaymentMethod: false,
  setCardBrand: undefined,
  cardBrand: undefined,
  inputError: undefined,
}

export const PayUContext = createContext(initialState)

const PayUContextProvider: FC<IPayUContextProps> = ({ children, setError, setCardCredentials }) => {
  const { i18n } = useDictionary()
  const [isScriptLoaded, setScriptLoaded] = useState(false)
  const [isCardPaymentMethod, setIsCardPaymentMethod] = useState(false)
  const [form, setForm] = useState<{
    cardNumberForm: payu.SecureForm | undefined
    cardDateForm: payu.SecureForm | undefined
    cardCVVForm: payu.SecureForm | undefined
  }>({
    cardNumberForm: undefined,
    cardDateForm: undefined,
    cardCVVForm: undefined,
  })
  const [payuInstance, setPayuInstace] = useState<payu.PayU | undefined>()
  const [secureForms, setSecureForms] = useState<payu.SecureForms | undefined>()
  const [inputError, setInputError] = useState<string | undefined>(undefined)
  const [cardBrand, setCardBrand] = useState<string | undefined>(undefined)
  const theme = useTheme()

  useEffect(() => {
    if (!isScriptLoaded) {
      loadDOMScript(config.app.payUSdkUrl).then(() => {
        setScriptLoaded(true)
      })
    }
  }, [])

  useEffect(() => {
    if (isCardPaymentMethod && isScriptLoaded && !payuInstance) {
      const payuInstance = PayU(config.buy.payUPosId, {
        dev: config.app.showUnfinishedFeatures === 'true',
      })

      setPayuInstace(payuInstance)
      const cardFormOpts: payu.SecureFormOptions = {
        style: {
          basic: {
            fontFamily: 'Helvetica',
            fontWeight: 'bold',
            fontColor: theme.palette.text.primary,
          },
          invalid: {
            fontColor: theme.palette.error.main,
          },
          placeholder: {
            fontColor: theme.palette.text.secondary,
          },
        },
      }
      const secureForms = payuInstance.secureForms()
      setSecureForms(secureForms)
      const cardNumberForm = secureForms.add('number', cardFormOpts)
      const cardDateForm = secureForms.add('date', cardFormOpts)
      const cardCVVForm = secureForms.add('cvv', cardFormOpts)

      setForm({ cardNumberForm, cardDateForm, cardCVVForm })

      cardNumberForm?.on('change', (input) => {
        if (cardBrand && input.brand?.length && cardBrand !== input.brand)
          setInputError(i18n.buy.paymentMethod.cardBrandError)
        else setInputError(undefined)
      })
    }
  }, [isScriptLoaded, isCardPaymentMethod])

  const handleTokenizeCard = async (payu: payu.PayU) => {
    try {
      const tokenResponse = await payu.tokenize('SINGLE')

      if (tokenResponse.status === 'ERROR') {
        setError({
          message: tokenResponse.error.messages[0].message,
          name: tokenResponse.error.messages[0].code,
        })

        return tokenResponse.status
      }

      setCardCredentials(tokenResponse.body)

      return tokenResponse.status
    } catch (error: any) {
      setError({
        message: i18n.buy.paymentMethod.cardDataError,
        name: 'card.data.error',
      })

      return 'ERROR'
    }
  }

  return (
    <PayUContext.Provider
      children={children}
      value={{
        isCardPaymentMethod,
        payuInstance,
        secureForms,
        handleTokenizeCard,
        setIsCardPaymentMethod,
        setCardBrand,
        cardBrand,
        inputError,
        ...form,
      }}
    />
  )
}

export default PayUContextProvider
