import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.secondary.contrastText,
      borderRadius: '1rem',
      padding: '2rem',
      marginTop: '4.4375rem',
      marginBottom: '4.4375rem',
    },
    icon: {
      maxWidth: '4rem',
      maxHeight: '4rem',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    h3: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
    },
    button: {
      padding: '0.75rem 1.5rem',
    },
  })
)

export default useStyles
