import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import TicketReception from 'components/TicketReception/TicketReception.component'
import {
  ITicketReceptionFromDispatch,
  ITicketReceptionFromState,
} from 'components/TicketReception/TicketReception.types'
import { receiveTicketMounted, receiveTicketSubmit } from 'models/tickets/actions'
import { getReceivedCustomTerms, getReceivedTicketData } from 'models/tickets/selectors'

const mapStateToProps = (state: _Store.IState): ITicketReceptionFromState => {
  return {
    customTerms: getReceivedCustomTerms(state),
    ticketData: getReceivedTicketData(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ITicketReceptionFromDispatch => {
  return {
    mounted: () => dispatch(receiveTicketMounted()),
    submit: (form) => dispatch(receiveTicketSubmit(form)),
  }
}

export default connect<ITicketReceptionFromState, ITicketReceptionFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(TicketReception)
