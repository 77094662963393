import { createSelector } from 'reselect'

import _Store from '@Store'

import { IInsuranceSummaryData, IProductsReducer } from './../types'
import getAll from './getAll'

const getInsuranceSummaryData = createSelector<
  _Store.IState,
  IProductsReducer,
  IInsuranceSummaryData
>([getAll], (products) => products.insuranceSummaryData)

export default getInsuranceSummaryData
