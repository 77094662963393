import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useSWR from 'swr'

import { ExtendedPayment } from 'components/ExtendedPayment'
import { setBasketTheme } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'
import { TransactionsApi } from 'services/Api/TransactionsApi'

export const ExtendedPaymentPage = () => {
  const embedProps = useSelector(getIframeParams)
  const dispatch = useDispatch()

  useEffect(() => {
    if (embedProps?.basketTheme) {
      dispatch(setBasketTheme(embedProps.basketTheme))
    }
  }, [embedProps?.basketTheme])

  const location = useLocation()
  const parsedSearch = '?' + location.search.replaceAll('?', '')
  const transactionId = new URLSearchParams(parsedSearch).get('extendedPayment') || ''

  const { data, isLoading } = useSWR([transactionId], TransactionsApi.getExtendedPayment)

  return <>{!!data && <ExtendedPayment transaction={data?.transaction} />}</>
}
