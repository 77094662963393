import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import _Store from '@Store'

import { IBasketFromDispatch, IBasketFromState } from 'components/Basket/Basket.types'
import { updateBasketStateFromContext } from 'models/basket/actions'
import { clearSelectedHappening } from 'models/happening/actions'
import { redirectParentTo, setBasketTheme } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'

import Basket from './Basket.component'

const mapStateToProps = (state: _Store.IState): IBasketFromState => ({
  basketRedirect: getIframeParams(state)?.basketRedirect,
  basketTheme: getIframeParams(state)?.basketTheme,
  isEmbed: !!getIframeParams(state),
})

const mapDispatchToProps = (dispatch: Dispatch): IBasketFromDispatch => {
  return {
    updateBasketState: (basketState) => dispatch(updateBasketStateFromContext(basketState)),
    redirectParentTo: (url) => dispatch(redirectParentTo(url)),
    setBasketTheme: (theme) => dispatch(setBasketTheme(theme)),
    clearSelectedHappening: () => dispatch(clearSelectedHappening()),
  }
}

export default connect<IBasketFromState, IBasketFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Basket)
