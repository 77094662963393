import React, { FC, useContext, useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import cn from 'classnames'
import { Field, useFormikContext } from 'formik'
import { Route } from 'router/routes'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import Checkbox from 'components/formik/Checkbox'
import FormField from 'components/formik/FormField'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './Invoice.module.scss'
import { IInvoiceProps } from './Invoice.types'

const Invoice: FC<IInvoiceProps> = ({ selectedTickets, open, module, isDefaultCurrency }) => {
  const { i18n } = useDictionary()
  const { setFieldValue } = useFormikContext<IBuyingOnlineFormValues>()
  const [showLegalNote, changeLegalNoteVisibility] = useState(false)

  const legalNoteToggleClicked = () => {
    changeLegalNoteVisibility(!showLegalNote)
  }

  const basket = useContext(clearContext)
  const isInvoiceAvailable =
    module === Route.happening ||
    module === Route.buyProducts ||
    module === Route.pinHappening ||
    module === Route.pinBuyProducts
      ? true
      : selectedTickets.length > 0
      ? selectedTickets.some((ticket) => ticket.price && ticket.price > 0)
      : basket
      ? basket.basketItems.some((item) => item.poolPrice && item.poolPrice > 0)
      : false

  useEffect(() => {
    if (!isInvoiceAvailable) setFieldValue('invoiceCheckbox', false)
  }, [isInvoiceAvailable])

  return (
    <div className={styles.wrapper}>
      <div className={styles.checkbox}>
        <Field
          component={Checkbox}
          name="invoiceCheckbox"
          id="invoiceCheckbox"
          disabled={!isInvoiceAvailable}
        >
          {i18n.buy.invoice.iWantInvoice}
        </Field>
      </div>
      <div className={styles.invoiceLegalText}>
        {i18n.buy.invoice.legalA}
        {showLegalNote && i18n.buy.invoice.legalB}
        <button type="button" onClick={legalNoteToggleClicked}>
          <Typography variant={'body1'} color={'primary'}>
            {showLegalNote ? i18n.buy.invoice.less : i18n.buy.invoice.more}
          </Typography>
        </button>
      </div>

      {open && (
        <div className={cn(styles.toggledFields)}>
          <label className={styles.label} htmlFor="invoiceName">
            <FormField
              id="invoiceName"
              name="invoiceName"
              placeholder={i18n.buy.invoice.name}
              type="text"
            />
          </label>
          <label className={styles.label} htmlFor="invoiceNip">
            <FormField
              id="invoiceNip"
              name="invoiceNip"
              placeholder={i18n.buy.invoice.nip}
              type="text"
            />
          </label>
          <label className={styles.label} htmlFor="invoiceAddress">
            <FormField
              id="invoiceAddress"
              name="invoiceAddress"
              placeholder={i18n.buy.invoice.address}
              type="text"
            />
          </label>
          <div className={styles.cities}>
            <label className={styles.label} htmlFor="invoicePost">
              <FormField
                id="invoicePost"
                name="invoicePost"
                placeholder={i18n.buy.invoice.zip}
                type="text"
              />
            </label>
            <label className={styles.label} htmlFor="invoiceCity">
              <FormField
                id="invoiceCity"
                name="invoiceCity"
                placeholder={i18n.buy.invoice.city}
                type="text"
              />
            </label>
          </div>
          {!isDefaultCurrency && (
            <label className={styles.label} htmlFor="invoiceCountry">
              <FormField
                id="invoiceCountry"
                name="invoiceCountry"
                placeholder={i18n.buy.invoice.country}
                type="text"
              />
            </label>
          )}
        </div>
      )}
    </div>
  )
}

export default Invoice
