import { createSelector } from 'reselect'

import config from 'config'
import { getLocation } from 'models/connectedRouter/selectors'

import { IMetaForCurrentUrl } from './../types'

const staticPageMeta = createSelector([getLocation], (location): IMetaForCurrentUrl => {
  return {
    currentUrl: `${config.app.baseUrl}${location.pathname}`,
    customGtmId: location.query?.gtmId,
  }
})

export default staticPageMeta
