import React, { FC, MouseEvent } from 'react'

import cn from 'classnames'

import SectionLead from 'components/Bricks/components/LeadSection'
import Date from 'components/reusable/Date'
import ImageFixed from 'components/reusable/ImageFixed'
import Place from 'components/reusable/Place'
import { Tags } from 'components/reusable/Tags'
import Title from 'components/reusable/Title'
import TagsLoading from 'components/reusable/loadings/TagsLoading'
import slugifyString from 'misc/helpers/slugifyString'
import Analytics from 'services/$analytics'

import styles from './HotDeals.module.scss'
import useStyles from './HotDeals.styles'
import { IHotDealsProps } from './HotDeals.types'

const HotDeals: FC<IHotDealsProps> = ({
  color,
  hotDeals,
  id,
  isLoading,
  sectionDescription,
  sectionTitle,
  compositionIdentifier,
}) => {
  const muiStyles = useStyles(color)
  const GAClassName = (title: string) => `${compositionIdentifier}-${id}-${slugifyString(title)}`

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(e, link, textDisplayed || '', componentName)
        : undefined

  return (
    <>
      <SectionLead
        color={color}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <div className={styles.scroll} id={id}>
        <div className={styles.wrapper}>
          {hotDeals.map((hotDeal, key) => {
            return (
              <div
                key={key}
                className={styles.hotDealWrapper}
                onClick={handleAddToDataLayerOnClick(hotDeal.url, hotDeal.title!, sectionTitle!)}
              >
                <div className={styles.imageWrapper}>
                  <ImageFixed
                    src={hotDeal.imageUrl}
                    to={hotDeal.url}
                    alt={hotDeal.title}
                    className={GAClassName(hotDeal.title || key.toString(10))}
                  />
                  {!isLoading && hotDeal.badge && (
                    <div
                      className={cn(styles.badge, styles[hotDeal.badge.type])}
                      style={{ backgroundColor: hotDeal.badge.type }}
                    >
                      {hotDeal.badge.name}
                    </div>
                  )}
                </div>
                <div className={styles.contentWrapper}>
                  {hotDeal.title && (
                    <Title
                      title={hotDeal.title}
                      isLoading={isLoading}
                      customClassName={cn(
                        styles.title,
                        muiStyles.fontColor,
                        GAClassName(hotDeal.title || key.toString(10))
                      )}
                    />
                  )}
                  {hotDeal.friendlyDate && (
                    <Date
                      date={hotDeal.friendlyDate}
                      isLoading={isLoading}
                      customClassName={cn(styles.date, muiStyles.fontColor)}
                    />
                  )}
                  {hotDeal.place && (
                    <Place
                      placeSlug={hotDeal.placeSlug}
                      place={hotDeal.place}
                      customClassName={cn(
                        styles.place,
                        muiStyles.fontColor,
                        GAClassName(hotDeal.title || key.toString(10))
                      )}
                      isLoading={isLoading}
                    />
                  )}
                </div>
                {hotDeal.tags.length > 0 && (
                  <div className={styles.tagsWrapper}>
                    {!isLoading && <Tags tags={hotDeal.tags} />}
                    {isLoading && <TagsLoading />}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default HotDeals
