import React from 'react'

import { ConfirmationNumberOutlined } from '@mui/icons-material'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import qs from 'qs'
import { Link, useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'

import { SocialShare } from 'components/SocialShare'
import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import Description from 'components/reusable/Description'
import { Followers } from 'components/reusable/Followers'
import { Map } from 'components/reusable/Map'
import { SecondaryMenu } from 'components/reusable/SecondaryMenu'
import { SocialLinks } from 'components/reusable/SocialLinks'
import { Stats } from 'components/reusable/Stats'
import { SwipeableCardDeck } from 'components/reusable/SwipeableCardDeck'
import config from 'config'
import { AnchorLinks } from 'constants/AnchorLinks'
import { Colors } from 'constants/Categories'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import isHTML from 'misc/helpers/isHTML'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { Styled } from './Profile.styles'
import { IProfileProps } from './Profile.types'
import { recommendedEvents } from './_mocks'

export const Profile = ({ place, profile, recommendRundates, refresh }: IProfileProps) => {
  const { i18n } = useDictionary()
  const { isDesktop } = useBreakpoints()
  const { slug, type } = useParams<RouteParams<Route.profile>>()

  const secondaryRoutes = [
    {
      label: i18n.profile.recommended,
      anchor: AnchorLinks.RECOMMENDED_EVENTS,
      isHidden: !recommendRundates?.length,
    },
    {
      label: i18n.profile.newsMenu,
      anchor: AnchorLinks.NEWS,
      isHidden: !config.app.showUnfinishedFeatures,
    },
    { label: i18n.profile.aboutUs, anchor: AnchorLinks.ABOUT_PLACE },
    { label: i18n.profile.location, anchor: AnchorLinks.LOCATION },
    {
      label: i18n.profile.gallery,
      anchor: AnchorLinks.GALLERY,
      isHidden: !config.app.showUnfinishedFeatures,
    },
  ]

  if (!place || !profile) return null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      <TwoColumnsLayout
        boxComponent={
          <Flex gap={4}>
            <Flex>
              <Styled.Title variant={'h4'}>
                {place.name}{' '}
                {config.app.showUnfinishedFeatures && (
                  <Tooltip arrow placement={'right'} title={i18n.profile.verified}>
                    <Styled.Verified htmlColor={Colors.MALIBU} />
                  </Tooltip>
                )}
              </Styled.Title>
              <Typography color={'textSecondary'}>{place.address}</Typography>
            </Flex>

            <Flex sx={{ width: '100%' }} gap={2}>
              <Followers numberOfFollowers={profile.stats.followersCount} />
              {isDesktop && type && slug && (
                <>
                  <SocialShare
                    title={place.name}
                    url={`${config.app.baseUrl}${prepareRoute({
                      route: Route.profile,
                      params: { type, slug },
                    })}`}
                    withLabel
                  />
                  <Styled.Button
                    component={Link}
                    startIcon={<ConfirmationNumberOutlined />}
                    fullWidth={true}
                    variant={'contained'}
                    size={'medium'}
                    children={i18n.profile.tickets}
                    to={
                      routes[Route.search] +
                      qs.stringify(
                        {
                          [HitFields.place_name]: place.name,
                        },
                        { addQueryPrefix: true }
                      )
                    }
                  />
                </>
              )}
            </Flex>
          </Flex>
        }
        pageTitle={place.name}
        tags={profile.tags}
        image={place.thumb}
        isBoxTitleDisabled
      >
        <Flex gap={3}>
          <SectionContainer fullWidth container={true}>
            <Stats
              data={{
                events: profile.stats.eventCount,
                visited: profile.stats.visitorsCount,
                tookPlace: profile.stats.archiveEventCount,
              }}
            />
          </SectionContainer>

          <SecondaryMenu routes={secondaryRoutes} />
        </Flex>

        {recommendRundates && !!recommendRundates.length && (
          <SectionContainer title={i18n.profile.recommended} id={AnchorLinks.RECOMMENDED_EVENTS}>
            <SwipeableCardDeck
              bottomElement={
                <Button
                  component={Link}
                  variant={'outlined'}
                  size={'medium'}
                  color={'secondary'}
                  children={i18n.profile.allEvents}
                  to={
                    routes[Route.search] +
                    qs.stringify(
                      {
                        [HitFields.type]: SearchTypes.RUNDATE,
                        [HitFields.id_source]: recommendRundates.map(
                          (rundate) => rundate.fields.id_source
                        ),
                      },
                      { addQueryPrefix: true, arrayFormat: 'repeat' }
                    )
                  }
                />
              }
            >
              {recommendRundates.map((item) => (
                <CloudSearchCard sx={{ maxWidth: 274 }} key={item.id} item={item} />
              ))}
            </SwipeableCardDeck>
          </SectionContainer>
        )}

        {config.app.showUnfinishedFeatures && (
          <SectionContainer title={i18n.profile.news} id={AnchorLinks.NEWS}>
            <SwipeableCardDeck
              bottomElement={
                <Button
                  variant={'outlined'}
                  size={'medium'}
                  color={'secondary'}
                  children={i18n.profile.allNews}
                />
              }
            >
              {recommendedEvents.map((item) => (
                <CloudSearchCard sx={{ minWidth: 274 }} key={item.id} item={item} />
              ))}
            </SwipeableCardDeck>
          </SectionContainer>
        )}

        {config.app.showUnfinishedFeatures && (
          <SectionContainer title={i18n.profile.gallery} id={AnchorLinks.GALLERY}>
            <SwipeableCardDeck
              bottomElement={
                <Button
                  variant={'outlined'}
                  size={'medium'}
                  color={'secondary'}
                  children={i18n.profile.allPhotos}
                />
              }
            >
              {recommendedEvents.map((item) => (
                <CloudSearchCard sx={{ minWidth: 274 }} key={item.id} item={item} />
              ))}
            </SwipeableCardDeck>
          </SectionContainer>
        )}

        <SectionContainer title={i18n.profile.aboutPlace} id={AnchorLinks.ABOUT_PLACE}>
          <Flex gap={3} align={'center'}>
            <SectionContainer fullWidth container={true}>
              <SocialLinks links={profile.links} />
            </SectionContainer>
            <SectionContainer fullWidth container={true}>
              <Description
                text={place.description}
                html={isHTML(place.description) ? place.description : undefined}
              />
            </SectionContainer>

            <Button
              variant={'outlined'}
              size={'medium'}
              color={'secondary'}
              children={i18n.profile.readMore}
            />
          </Flex>
        </SectionContainer>

        <SectionContainer title={i18n.profile.location} id={AnchorLinks.LOCATION}>
          <Flex gap={3} align={'center'}>
            <SectionContainer fullWidth container={true}>
              <Map lon={place.lon} lat={place.lat} />
            </SectionContainer>

            <Button
              variant={'outlined'}
              size={'medium'}
              color={'secondary'}
              children={i18n.profile.setRoute}
              href={`https://maps.google.com/?q=${place.lat},${place.lon}`}
              target={'_blank'}
              rel={'nofollow'}
            />
          </Flex>
        </SectionContainer>
      </TwoColumnsLayout>
    </Box>
  )
}
