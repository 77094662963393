import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import { EMPTY as EMPTY$, of as of$ } from 'rxjs'
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { navigate } from 'models/internalRouter/actions'
import { selectProduct } from 'models/products/actions'
import { getSelectedProducts } from 'models/products/selectors'
import PoolsSelector from 'services/$pools-selector'
import { isActionOf } from 'typesafe-actions'

import { selectPool, selectTicket, updateSelectedSeats, updateSelectedTickets } from './../actions'
import { getSelectedTickets, getSelectedTicketsBySeats } from './../selectors'

export const updateSelectedTicketsWhenAmountClicked: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedTickets = getSelectedTickets(state)
      const selectedProducts = getSelectedProducts(state)
      const {
        poolId,
        amount,
        poolName,
        price,
        serviceFee,
        additionalFields,
        forms,
        submissions,
        paymentMethods,
        currency,
      } = action.payload

      const updatedSelected = PoolsSelector.updateSelected(
        selectedTickets,
        poolId,
        amount,
        poolName,
        currency,
        price,
        serviceFee,
        additionalFields,
        forms,
        submissions,
        paymentMethods
      )

      const foundedProduct = selectedProducts.find((product) => product.poolIds?.includes(poolId))

      if (amount === 0 && foundedProduct) {
        return of$(
          updateSelectedTickets(updatedSelected),
          selectProduct({ amount: 0, product: foundedProduct })
        )
      }

      return of$(updateSelectedTickets(updatedSelected))
    })
  )
}

export const updateSelectedTicketsWhenSeatsSelectedUpdate: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(updateSelectedSeats)),
    withLatestFrom$(state$),
    map$(([_, state]) => {
      const updatedSelected = getSelectedTicketsBySeats(state)

      return updateSelectedTickets(updatedSelected)
    })
  )
}

export const redirectWhenSelectPool: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectPool)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(
        navigate({
          to: prepareRoute({
            route: Route.buy,
            params: {
              eventSlug: action.payload.eventSlug,
              rundateSlug: action.payload.rundateSlug,
            },
          }),
        })
      )
    })
  )
}

export const removeProductWhenDeselectTicket: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(updateSelectedTickets)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedProducts = getSelectedProducts(state)

      const foundedProduct = selectedProducts.filter(
        (product) =>
          !action.payload.some((selectedTicket) => product.poolIds?.includes(selectedTicket.poolId))
      )[0]

      if (foundedProduct) {
        return of$(selectProduct({ amount: 0, product: foundedProduct }))
      }

      return EMPTY$
    })
  )
}
