import { useDispatch } from 'react-redux'

import { eventAddToCart, eventRemoveFromCart } from 'models/analytics/actions'
import { IEventAddToCart } from 'models/analytics/types'
import { deselectSeat, selectSeat } from 'models/pools/actions'
import { usePoolsData } from 'models/pools/hooks/usePoolsData'
import { usePoolsPricing } from 'models/pools/hooks/usePoolsPricing'
// import { useSelectedSeats } from 'models/pools/hooks/useSelectedSeats'
import { IPoolsSelectedSeat } from 'models/pools/types'
import { setInvalidSelection, setValidSelection } from 'models/tickets/actions'

export const useSeatsIO = () => {
  const dispatch = useDispatch()
  const pricing = usePoolsPricing()
  const poolsData = usePoolsData()

  return {
    deselectSeat: (seat: IPoolsSelectedSeat) => dispatch(deselectSeat(seat)),
    onSelectionInvalid: (reason: string) => dispatch(setInvalidSelection(reason)),
    onSelectionValid: () => dispatch(setValidSelection()),
    selectSeat: (seat: IPoolsSelectedSeat) => dispatch(selectSeat(seat)),
    eventAddToCart: (data: IEventAddToCart) => dispatch(eventAddToCart(data)),
    eventRemoveFromCart: (data: IEventAddToCart) => dispatch(eventRemoveFromCart(data)),
    pricing,
    poolsData,
  }
}
