import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { generateIdempotencyKey, setDay } from 'models/happening/actions'

import Calendar from './Calendar.component'
import { ICalendarFromDispatch } from './Calendar.types'

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ICalendarFromDispatch => ({
  generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
  setDay: (day) => dispatch(setDay(day)),
})

export default connect(null, mapDispatchToProps)(Calendar)
