import React, { useEffect } from 'react'

import * as braze from '@braze/web-sdk'
import { fetchAndActivate } from 'firebase/remote-config'
import { hotjar } from 'react-hotjar'
import { RouterProvider } from 'react-router-dom'
import router from 'router'

import { BagStore } from 'components/Basket/BasketProvider/BasketProvider'
import AddToGlobalBasket from 'components/_functional/AddToGlobalBasket'
import { LocationsContextProvider } from 'components/_functional/LocationContextProvider'
import { MaintenanceModeProvider } from 'components/_functional/MaintenanceModeProvider'
import { RouterProviderWrapper } from 'components/_functional/RouterProviderWrapper'
import { ThemeProvider } from 'components/_functional/ThemeProvider'
import { UserContextProvider } from 'components/_functional/UserContextProvider'
import SimpleLoading from 'components/reusable/SimpleLoading'
import config from 'config'
import { useDispatchAppIsReady } from 'models/app/hooks/useDispatchAppIsReady'
import { useIsEmbedFromStore } from 'models/internalRouter/hooks/useIsEmbedFromStore'
import { remoteConfig } from 'services/Firebase/firebaseInit'

import { FavoritesProvider } from '../favorites/FavoritesProvider'

export const App = () => {
  const { isEmbed } = useIsEmbedFromStore()
  const { appIsReady } = useDispatchAppIsReady()

  useEffect(() => {
    ;(async () => {
      await fetchAndActivate(remoteConfig)
    })()

    try {
      hotjar.initialize(2190634, 6)
    } catch (SSR) {}

    braze.initialize(config.braze.brazeWebAppID, {
      baseUrl: config.braze.sdkEndpoint,
      enableLogging: config.braze.enableLogging,
    })

    appIsReady()
  }, [])

  return (
    <ThemeProvider>
      <MaintenanceModeProvider>
        <UserContextProvider>
          <LocationsContextProvider>
            <FavoritesProvider>
              <BagStore>
                {isEmbed && <AddToGlobalBasket />}
                <RouterProviderWrapper />
              </BagStore>
            </FavoritesProvider>
          </LocationsContextProvider>
        </UserContextProvider>
      </MaintenanceModeProvider>
    </ThemeProvider>
  )
}
