import { ComponentType } from 'react'

import { Box, BoxProps, Paper, PaperProps, Typography, styled } from '@mui/material'

interface IStyleProps {
  isMobile: boolean
}

export const Styled = {
  Root: styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
  })<IStyleProps>(({ theme, isMobile }) => ({
    borderRadius: 16,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
  })) as ComponentType<PaperProps & IStyleProps>,

  ImageContainer: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
  })<IStyleProps>(({ theme, isMobile }) => ({
    flex: isMobile ? 'none' : '1 1 40%',
    maxWidth: isMobile ? 'none' : '40%',
  })) as ComponentType<BoxProps & IStyleProps>,

  Body: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
  })<IStyleProps>(({ theme, isMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(4),
    flex: isMobile ? 'none' : '1 1 60%',
    maxWidth: isMobile ? 'none' : '60%',
    justifyContent: 'space-between',
  })) as ComponentType<BoxProps & IStyleProps>,

  StyledDescription: styled(Typography)({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
  }),
}
