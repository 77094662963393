import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = (isMobile: boolean) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      button: {
        '&:last-of-type': {
          marginBottom: 0,
        },
        marginBottom: '1rem',
      },
      disabled: {
        cursor: 'initial',
        opacity: 0.6,
        pointerEvents: 'none',
      },
      header: {
        fontSize: '1.75rem',
        marginBottom: '2rem',
      },
      logo: {
        height: '1.5rem',
      },
      noPadding: {
        padding: '0!important',
      },
      outerContainer: {
        marginTop: '3.75rem',
      },
      root: {
        background: theme.palette.background.paper,
        borderRadius: '1rem',
        padding: isMobile ? '1.75rem 1.25rem' : '2.375rem 1.875rem',
      },
    })
  })

export default useStyles
