import { defaultMemoize } from 'reselect'
import { object, string } from 'yup'

import { ILangMap } from 'types/Locale'

import * as CONSTS from './../constants/constants'
import {
  addressNumberValidator,
  addressValidator,
  authIdValidator,
  empikCardNumberValidator,
  postCodeValidator,
} from './../constants/validators'
import { IBuyingOnsiteValues } from './Onsite.types'

export const getInitialValues = () => {
  const initialValues: IBuyingOnsiteValues = {
    authId: CONSTS.DEFAULT_EMPTY_TEXT,
    empikPremiumNumber: CONSTS.DEFAULT_EMPTY_TEXT,
    userHavePremiumCheckbox: false,

    deliveryCity: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryFirstname: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryLastname: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryLocalStreet: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryNumberStreet: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryPhone: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryStreet: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryZip: CONSTS.DEFAULT_EMPTY_TEXT,
    giftWrapper: false,

    emailAdditional: {},
    firstnameAdditional: {},
    lastnameAdditional: {},

    pickUpWay: CONSTS.DEFAULT_PICKUP_WAY,
  }

  return initialValues
}

const makeValidationSchemaToMemoize = (lang: ILangMap) => {
  return object().shape({
    authId: authIdValidator(lang),
    empikPremiumNumber: empikCardNumberValidator(lang),

    // Address - delivery by post
    deliveryCity: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryFirstname: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryLastname: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryLocalStreet: addressNumberValidator(lang.errors.incorrectData),
    deliveryNumberStreet: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      then: addressNumberValidator(lang.errors.incorrectData).required(
        lang.errors.thisFieldIsRequiredValidator
      ),
    }),
    deliveryPhone: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      // @TODO: remove when sale's ended and update PostAddress.component
      then: string().required(lang.errors.thisFieldIsRequiredValidator),
      // then: phoneNumberValidator(errors.incorrectPhoneNumber).required(
      //   errors.thisFieldIsRequiredValidator
      // ),
    }),
    deliveryStreet: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryZip: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      then: postCodeValidator(lang.errors.incorrectPostCodeValidator).required(
        lang.errors.thisFieldIsRequiredValidator
      ),
    }),
  })
}

export const makeValidationSchema = defaultMemoize(makeValidationSchemaToMemoize)
