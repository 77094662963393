import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { getIframeParams } from 'models/internalRouter/selectors'
import { closeNotification } from 'models/notifications/actions'
import { getNotifications } from 'models/notifications/selectors'

import Notification from './Notification.component'
import { INotificationFromDispatch, INotificationFromState } from './Notification.types'

const mapStateToProps = (state: _Store.IState): INotificationFromState => ({
  isEmbed: !!getIframeParams(state),
  notifications: getNotifications(state),
})
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): INotificationFromDispatch => ({
  close: (id) => dispatch(closeNotification(id)),
})
export default connect<INotificationFromState, INotificationFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Notification)
