import * as React from 'react'

import { useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'

import { SectionContainer } from 'components/_layout/SectionContainer'
import { ChangeTime } from 'components/ticketManagement/TicketAction/ChangeTime'
import { Return } from 'components/ticketManagement/TicketAction/Return'
import { ShowHistory } from 'components/ticketManagement/TicketAction/ShowHistory'
import { Transfer } from 'components/ticketManagement/TicketAction/Transfer'
import { TransferCancel } from 'components/ticketManagement/TicketAction/TransferCancel'
import { TicketActions } from 'services/Api/TicketManagementApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const TicketActionsMap = {
  [TicketActions.CANCEL_TRANSFER]: TransferCancel,
  [TicketActions.CHANGE_TIME]: ChangeTime,
  [TicketActions.HISTORY]: ShowHistory,
  [TicketActions.RETURN]: Return,
  [TicketActions.TRANSFER]: Transfer,
}

export const TicketAction = () => {
  const { i18n } = useDictionary()
  const { actionKey, ticketIndex, code, email } =
    useParams<RouteParams<Route.ticketManagementAction>>()

  if (!actionKey || !code || !email || !ticketIndex) return null

  const ActionComponent = TicketActionsMap[actionKey]

  return (
    <SectionContainer title={i18n.ticketManagement.myTicket.actions[actionKey].name} container>
      <ActionComponent actionKey={actionKey} ticketIndex={ticketIndex} code={code} email={email} />
    </SectionContainer>
  )
}
