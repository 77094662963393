import React from 'react'

import { Grid, Tooltip } from '@mui/material'
import cn from 'classnames'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './Occupancy.styles'
import { IOccupancyProps } from './Occupancy.types'

const Dot = ({ active, isDefaultTheme }: { active: boolean; isDefaultTheme: boolean }) => {
  const classes = useStyles()

  return (
    <Grid
      item={true}
      className={cn(
        classes.dot,
        active && isDefaultTheme ? classes.activeDefaultTheme : active ? classes.active : undefined
      )}
    />
  )
}

const Occupancy = ({ slot, isDefaultTheme }: IOccupancyProps) => {
  const { i18n } = useDictionary()

  const dotsCount = Math.ceil((slot.spaces[0].capacityLeft / slot.spaces[0].capacity) * 4)

  const components: JSX.Element[] = Array.from(Array(4).keys()).map((key) => (
    <Dot key={key} active={key < dotsCount} isDefaultTheme={isDefaultTheme} />
  ))

  return (
    <Tooltip title={i18n.buy.happening.availability}>
      <Grid container={true} justifyContent="center">
        {components}
      </Grid>
    </Tooltip>
  )
}

export default Occupancy
