import { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { FormFields } from 'components/_forms/_constants/FieldNames'
import { ITransferCancelFormInput } from 'components/ticketManagement/TicketAction/TransferCancel/TransferCancel.types'
import {
  TicketActionErrors,
  TicketActions,
  TicketManagementApi,
  TicketTransferStatus,
} from 'services/Api/TicketManagementApi'
import { URI } from 'services/URI'
import { TicketResponse } from 'types/TicketManagement'

export const useTransferCancel = (
  code: string,
  email: string,
  ticketIndex: number,
  actionKey: TicketActions
) => {
  const { data: tickets, mutate } = useSWR<[TicketResponse]>(
    !!code && !!email && URI.getTicket(code, email),
    { revalidateOnMount: false }
  )
  const transfers =
    tickets?.[ticketIndex].transfers.filter(
      (transfer) => transfer.status === TicketTransferStatus.PENDING
    ) ?? []
  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const ticketMainRoute = prepareRoute({
    route: Route.ticketManagementTicket,
    params: { code, email },
  })
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<ITransferCancelFormInput>()

  const onSubmit = handleSubmit(async (values) => {
    const hash = transfers[values[FormFields.INDEX]].hash
    const token = tickets?.[ticketIndex].token
    if (!!errors.root) setActiveStep(0)
    if (activeStep === 0) setActiveStep(1)
    else if (activeStep === 2) {
      setIsLoading(true)
      await mutate()
      navigate(ticketMainRoute)
    } else if (!!hash && !!token) {
      setIsLoading(true)
      const response = await TicketManagementApi.transferCancel({
        hash,
        token,
      })
      if (!response.status) {
        navigate(
          prepareRoute({
            route: Route.ticketManagementActionError,
            params: {
              code,
              email,
              ticketIndex: String(ticketIndex),
              errorCode: TicketActionErrors.UNKNOWN_ERROR,
              actionKey,
            },
          })
        )
      }
      setActiveStep(2)
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (
      isNaN(ticketIndex) ||
      tickets?.[ticketIndex].actions[TicketActions.CANCEL_TRANSFER].isAvailable === false
    ) {
      navigate(ticketMainRoute)
    }
  }, [ticketIndex])

  return {
    activeStep,
    control,
    getValues,
    handleSubmit: onSubmit,
    handleBack: () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    isLoading,
    transfers,
  }
}
