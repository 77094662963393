import { Route, routes } from 'router/routes'

/**
 * Function to translate Polish to english route
 *
 * @param {string} link - Link to translate
 * @param {boolean} isEnglish - translate or not
 *
 *
 * @return {string} english link
 */
const translateEngLink = (link: string, isEnglish: boolean) => {
  return isEnglish
    ? link?.replace(routes[Route.rundate].split('/')[1], routes[Route.enRundate].split('/')[1])
    : link
}

export default translateEngLink
