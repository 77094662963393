import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
)

export default useStyles
