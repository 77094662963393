/**
 * Function returning a promise that appends external script to the DOM
 *
 * @param {string} src - script source path
 *
 * @return {promise} promise object
 */
const loadDOMScript = (src: string) => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      script.addEventListener('load', () => {
        resolve(() => {})
      })
      script.addEventListener('error', (error) => {
        reject(error)
      })
      document.body.appendChild(script)
    } catch (error) {
      reject(error)
    }
    process.on('unhandledRejection', (error) => {
      reject(error)
    })
  })
}

export default loadDOMScript
