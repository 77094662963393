import axios from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import {
  ICheckEmailResponse,
  IGenerateTokenResponse,
  ILoginResponse,
} from 'services/$auth-api/types'

class AuthApi {
  private static checkEmailUrl = `${config.api.keyCloakUrl}auth/isUser`
  private static generateTokenUrl = `${config.api.keyCloakUrl}auth/generate-token`
  private static tokenLoginUrl = (token: string) =>
    `${config.api.keyCloakUrl}login-actions/action-token?key=${token}`

  public checkEmail(email: string): Promise<ICheckEmailResponse> {
    const params = new URLSearchParams()
    params.append('email', email)
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return new Promise<ICheckEmailResponse>((resolve, reject) => {
      axios
        .post(AuthApi.checkEmailUrl, params, withCacheHeader(axiosConfig))
        .then(getData)
        .then((response: ICheckEmailResponse) => resolve(response))
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public generateToken(email: string): Promise<IGenerateTokenResponse> {
    const params = new URLSearchParams()
    params.append('email', email)
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return new Promise<IGenerateTokenResponse>((resolve, reject) => {
      axios
        .post(AuthApi.generateTokenUrl, params, withCacheHeader(axiosConfig))
        .then(getData)
        .then((response: IGenerateTokenResponse) => resolve(response))
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public loginWithToken(token: string): Promise<ILoginResponse> {
    return new Promise<ILoginResponse>((resolve, reject) => {
      axios
        .post(AuthApi.tokenLoginUrl(token), withCacheHeader())
        .then(getData)
        .then((response: ILoginResponse) => resolve(response))
        .catch((error) => reject(catchHttpError(error)))
    })
  }
}

export default new AuthApi()
