import React from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import cn from 'classnames'
import { Field } from 'formik'

import Checkbox from 'components/formik/Checkbox'
import FormField from 'components/formik/FormField'

import clientDataStyles from './../ClientData/ClientData.module.scss'
import styles from './ToggleField.module.scss'
import useStyles from './ToggleField.styles'
import { IToggleFieldProps } from './ToggleField.types'

const ToggleField = ({
  isDisabled,
  checkboxId,
  inputId,
  open,
  text,
  placeholder,
  tooltipText,
  helperText,
  inputTextModification,
}: IToggleFieldProps) => {
  const classes = useStyles()

  return (
    <div className={cn(styles.wrapper, isDisabled && classes.disabled)}>
      <div className={cn(clientDataStyles.label, classes.label)}>
        <Field component={Checkbox} name={checkboxId} id={checkboxId}>
          {text}
        </Field>
        {tooltipText && (
          <Tooltip title={tooltipText} enterTouchDelay={100}>
            <InfoIcon className={classes.tooltip} />
          </Tooltip>
        )}
      </div>

      {open && (
        <div className={styles.form}>
          <label className={clientDataStyles.label} htmlFor={inputId}>
            <FormField
              id={inputId}
              name={inputId}
              placeholder={placeholder}
              type="text"
              helperText={helperText}
              inputTextModification={inputTextModification}
            />
          </label>
        </div>
      )}
    </div>
  )
}

export default ToggleField
