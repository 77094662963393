import { createAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import { IActionModalContext, IActionModalType } from './../types'

/*
 * Action without underscore can be dispatched from app.
 * We listen to one of action below in epics.
 */
export const closeAll = createAction(CONSTS.CLOSE_ALL)

export const closeLatest = createAction(CONSTS.CLOSE_LATEST)

export const open = createAction(
  CONSTS.OPEN,
  (action) => (type: IActionModalType, context?: IActionModalContext) =>
    action({
      context,
      type,
    })
)

/*
 * These actions are private. That means we should not use them.
 * Only for internal Modal module. These action has affect to store.
 */
export const _closeAll = createAction(CONSTS._CLOSE_ALL)

export const _closeLatest = createAction(CONSTS._CLOSE_LATEST)

export const _open = createAction(
  CONSTS._OPEN,
  (action) => (type: IActionModalType, context?: IActionModalContext) =>
    action({
      context,
      type,
    })
)

export const _setOne = createAction(
  CONSTS._SET_ONE,
  (action) => (type: IActionModalType, context?: IActionModalContext) =>
    action({
      context,
      type,
    })
)
