import React from 'react'

import { Grid } from '@mui/material'

import ArtistCard from 'components/Rundate/components/ArtistCard'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { IArtistResponse } from 'types/Artist'

import { Slider } from '../../../reusable/Slider'
import { IEventArtistsProps } from './EventArtists.types'

export const RundateArtists = ({ artists }: IEventArtistsProps) => {
  const { isMobile } = useBreakpoints()
  const artistsCoupledIntoPairs: IArtistResponse[][] = artists.reduce(
    (result: IArtistResponse[][], value, index, array) => {
      if (index % 2 === 0) {
        result.push(array.slice(index, index + 2))
      }

      return result
    },
    []
  )

  if (isMobile)
    return (
      <Slider items={artists} edgeOffset={isMobile ? 8 : 16} useFixedWidth disableScrollbars>
        {({ item }) => <ArtistCard artist={item} />}
      </Slider>
    )

  return (
    <Grid container={true} spacing={4}>
      {artistsCoupledIntoPairs.map((artistPair, index) =>
        index % 2 === 0
          ? artistPair.map((artist) => (
              <ArtistCard key={`${artist.slug}-${index}`} artist={artist} />
            ))
          : artistPair.map((artist) => (
              <ArtistCard key={`${artist.slug}-${index}`} artist={artist} reversed={true} />
            ))
      )}
    </Grid>
  )
}
