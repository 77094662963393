import React, { ChangeEvent, useEffect, useState } from 'react'

import { Button, Grid, TextField } from '@mui/material'

import stepNames from 'components/EntryList/steps'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import Paper from '../Paper'
import { ITicketCodeProps } from './TicketCode.types'

const TicketCode = ({
  handlePrevStep,
  requestDataFromTicket,
  errors,
  clearErrors,
  resetForm,
  setHistoryItem,
}: ITicketCodeProps) => {
  const [code, setCode] = useState('')
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  const error = errors?.ticketCode

  const handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCode(e.target.value)
    clearErrors()
  }

  useEffect(() => {
    resetForm()
    setHistoryItem(stepNames.starter)
  }, [])

  return (
    <Paper title={i18n.entryList.ticketCode.title}>
      <Grid container={true} spacing={4} justifyContent="center">
        <Grid item={true} xs={12}>
          <TextField
            value={code}
            onChange={(e) => handleChangeInput(e)}
            label={i18n.entryList.ticketCode.placeholder}
            variant="outlined"
            fullWidth={true}
            helperText={!!error && error}
            error={!!error?.length}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            className={globals.column}
            spacing={1}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                type="button"
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {i18n.entryList.ticketCode.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                type="submit"
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                disabled={!code.length}
                onClick={() => requestDataFromTicket(code)}
              >
                {i18n.entryList.ticketCode.goNext}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TicketCode
