import { getType } from 'typesafe-actions'

import { closeNotification, createNotification } from './../actions'
import { IAction, INotificationReducer } from './../types'

const initialState: INotificationReducer = {
  notifications: [],
}

const reducer = (
  state: INotificationReducer = initialState,
  action: IAction
): INotificationReducer => {
  switch (action.type) {
    case getType(createNotification):
      return {
        notifications: [
          ...state.notifications,
          {
            id: new Date().getTime(),
            message: action.payload,
          },
        ],
      }

    case getType(closeNotification):
      return {
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      }

    default:
      return state
  }
}

export default reducer
