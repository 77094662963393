import { Selector } from 'reselect'

import _Store from '@Store'

import { IHappeningSelected } from './../types'

const getSelected: Selector<_Store.IState, IHappeningSelected> = (state): IHappeningSelected =>
  state.happening.selected

export default getSelected
