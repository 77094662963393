import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { eventAutoFillData } from 'models/analytics/actions'
import { getBasketData } from 'models/basket/selector'
import { getCurrentKey, getCustomTerms, getEvent } from 'models/event/selectors'
import getIsEventForPremiumUsers from 'models/event/selectors/getIsEventForPremiumUsers'
import {
  generateIdempotencyKey,
  setCalculatedPrices,
  setDiscountState,
  setPrepaidState,
} from 'models/happening/actions'
import { getSelected } from 'models/happening/selectors'
import { createNotification } from 'models/notifications/actions'
import { isPoolHasDependency } from 'models/pools/selectors'
import getPoolsData from 'models/pools/selectors/getPoolsData'
import { checkEmailValidity } from 'models/sendgrid/actions'
import { getCheckEmail } from 'models/sendgrid/selectors'
import { requestDataFromFacebook } from 'models/social/actions'
import { getTheme } from 'models/theme/selectors'
import { getPreviousForm } from 'models/transaction/selectors'

import ClientData from './ClientData.component'
import {
  IClientDataFromDispatch,
  IClientDataFromState,
  IClientDataOwnProps,
} from './ClientData.types'

const mapStateToProps = (state: _Store.IState): IClientDataFromState => ({
  currentBasketData: getBasketData(state),
  currentKey: getCurrentKey(state),
  customTerms: getCustomTerms(state),
  emailValidation: getCheckEmail(state),
  hasDependency: isPoolHasDependency(state),
  lastData: getPreviousForm(),
  selected: getSelected(state),
  selectedTheme: getTheme(state),
  selectedSpace: getSelected(state).space,
  // TODO: Temporary feature 05.05.2022
  poolsData: getPoolsData(state),
  eventData: getEvent(state),
  isEventPremium: getIsEventForPremiumUsers(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IClientDataFromDispatch => ({
  calculatePrice: (value) => dispatch(setCalculatedPrices(value)),
  checkEmail: (value) => dispatch(checkEmailValidity(value)),
  generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
  requestDataFromFacebook: (setFieldValue) => dispatch(requestDataFromFacebook(setFieldValue)),
  setDiscountState: (discountCode) => dispatch(setDiscountState(discountCode)),
  setPrepaidState: (prepaidCard) => dispatch(setPrepaidState(prepaidCard)),
  eventAutoFillData: (textDisplayed) => dispatch(eventAutoFillData(textDisplayed)),
  notify: (message) => dispatch(createNotification(message)),
})

export default connect<
  IClientDataFromState,
  IClientDataFromDispatch,
  IClientDataOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ClientData)
