import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gap: '0.625rem',
    },
    checkbox: {
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    privacyPolicy: {
      display: 'inline',
      '& strong': {
        fontSize: 'inherit',
      },
      '& strong a': {
        fontSize: 'inherit',
        color: theme.palette.primary.main,
      },
    },
  })
)

export default useStyles
