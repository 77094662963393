import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { categoryFilterState } from '../atoms/categoryFilter'
import { dateFilterState } from '../atoms/dateFilter'
import { locationFilterState } from '../atoms/locationFilter'
import { sourceIdsFilterState } from '../atoms/sourceIdsFilter'
import { tagsFilterState } from '../atoms/tagsFilter'
import { typeFilterState } from '../atoms/typeFilter'
import { variousFiltersState } from '../atoms/variousFilters'

export const getFilters = selector({
  get: ({ get }) => {
    const categoryFilter = get(categoryFilterState)
    const dateFilter = get(dateFilterState)
    const locationFilter = get(locationFilterState)
    const tagsFilter = get(tagsFilterState)
    const variousFilters = get(variousFiltersState)
    const typeFilter = get(typeFilterState)
    const sourceIdsFilter = get(sourceIdsFilterState)

    return {
      category: categoryFilter,
      date: dateFilter,
      location: locationFilter,
      tags: tagsFilter,
      type: typeFilter,
      various: variousFilters,
      sourceIds: sourceIdsFilter,
    }
  },
  key: RecoilKeys.getFilters,
})
