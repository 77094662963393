import * as React from 'react'

import { Box, Button, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { IBuyButtonProps } from 'components/reusable/BuyButton/BuyButton.types'
import config from 'config'
import { QueueItId } from 'constants/QueueIt'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLocalizedRoute } from 'state/locale/hooks/useLocalizedRoute'

export const BuyButton = ({
  eventSlug,
  rundateSlug,
  redirectToUrl,
  disabled,
  label,
  queue,
  fullWidth = true,
  sx,
}: IBuyButtonProps) => {
  const { i18n, getExternalKey } = useDictionary()
  const { localizeRoute } = useLocalizedRoute()
  const DISABLED_TOOLTIP_TEXT =
    i18n.event.disabledTooltip + (config.theme.isGoing ? 'Going.' : 'Empik Bilety')
  const route = prepareRoute({
    route: localizeRoute(Route.buy, Route.enBuy),
    params: { eventSlug, rundateSlug },
  })

  const ButtonElement = (
    <Button
      sx={sx}
      component={Link}
      children={label || getExternalKey('buyButton') || i18n.event.buyTicket}
      disabled={!redirectToUrl && disabled}
      size={'large'}
      fullWidth={fullWidth}
      variant={'contained'}
      to={
        redirectToUrl ||
        (queue === QueueItId.queuegoingsite && config.theme.isGoing
          ? config.app.queueUrl + route
          : route)
      }
    />
  )

  return disabled ? (
    <Tooltip title={disabled ? DISABLED_TOOLTIP_TEXT : ''} disableHoverListener={!disabled}>
      <Box sx={{ display: 'inherit', width: '100%' }} children={ButtonElement} />
    </Tooltip>
  ) : (
    ButtonElement
  )
}
