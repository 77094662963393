import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { boolean, lazy, mixed, object, ref, string } from 'yup'

import config from 'config'
import { UserCardStatusEnum } from 'models/premium/types'
import { ICustomTerm, ScopeTerm } from 'types/EventCommon'
import { ILangMap } from 'types/Locale'

import * as CONST from './constants'

// Regular expressions
const addressNumberRegExp = /^\d+[a-zA-Z\/\-\d]*$/
const empikCardRegExp = /^04\d{11}$/
const loginRegExp = /^\d{4,6}(-\d{1,2})?$/
const nipRegExp = /^[0-9]*$/
const postCodeRegExp = /^\d\d-\d\d\d$/

// Address
export const addressValidator = (text: string) =>
  string().when('pickUpWay', {
    is: CONST.PICK_UP_WAY_POST,
    then: string().required(text),
  })

export const addressNumberValidator = (text: string) => string().matches(addressNumberRegExp, text)

export const postCodeValidator = (text: string) => string().matches(postCodeRegExp, text)

// Phone
export const phoneNumberValidator = (text: string) =>
  string().test('phoneNumber', text, (value) => {
    if (!value) {
      return false
    }

    const parsedNumber = parsePhoneNumberFromString(value, 'PL')

    if (parsedNumber) {
      return parsedNumber.isValid()
    }

    return false
  })

// Term
export const termValidator = (text: string) => boolean().oneOf([true], text)

// Discount Code
export const discountCodeValidator = (lang: ILangMap) =>
  string().when('discountCheckbox', {
    is: true,
    then: string().required(lang.errors.thisFieldIsRequiredValidator),
  })

// Empik Card
export const empikCardNumberValidator = (lang: ILangMap) =>
  string().when('userHavePremiumCheckbox', {
    is: true,
    then: string()
      .required(lang.errors.thisFieldIsRequiredValidator)
      .matches(empikCardRegExp, lang.errors.incorrectEmpikCardFormatValidator)
      .test({
        name: 'checkOnlineFormEmailFill',
        test() {
          const userCardStatusOK =
            this.resolve(ref('userCardStatusCheck')) === UserCardStatusEnum.OK

          if (!config.app.onlineSale || userCardStatusOK) {
            return true
          }

          const incorrectEmail = !this.resolve(ref('email'))
          let errorMessage = lang.errors.checkingCardValidator

          if (incorrectEmail) {
            errorMessage = lang.errors.emailAddressFieldIsRequiredValidator
          }

          return this.createError({
            message: errorMessage,
            path: this.path,
          })
        },
      }),
  })

// Auth ID
export const authIdValidator = (lang: ILangMap) =>
  lazy((value: any) => {
    const validator = string().required(lang.errors.authIdIsRequiredValidator)

    if (value && value.toString().indexOf('@') >= 0) {
      return validator.email(lang.errors.providedEmailAddressIsIncorrectValidator)
    }

    return validator.matches(loginRegExp, lang.errors.providedIdIsIncorrectValidator)
  })

// Invoice
export const invoiceRequiredFieldValidator = (text: string) =>
  string().when('invoiceCheckbox', {
    is: true,
    then: string().required(text),
  })

export const invoicePostCodeValidator = (lang: ILangMap, noValidate?: boolean) =>
  noValidate
    ? string().when('invoiceCheckbox', {
        is: true,
        then: string().required(lang.errors.thisFieldIsRequiredValidator),
      })
    : string().when('invoiceCheckbox', {
        is: true,
        then: string()
          .required(lang.errors.thisFieldIsRequiredValidator)
          .matches(postCodeRegExp, lang.errors.incorrectPostCodeValidator),
      })

export const invoiceNipValidator = (text: string, noValidate?: boolean) =>
  noValidate
    ? string().when('invoiceCheckbox', {
        is: true,
        then: string().nullable(),
      })
    : string().when('invoiceCheckbox', {
        is: true,
        then: string().matches(nipRegExp, text).nullable(),
      })

export const invoiceCountryValidator = (text: string, isDefaultCurrency?: boolean) =>
  isDefaultCurrency
    ? string().when('invoiceCheckbox', {
        is: true,
        then: string().nullable(),
      })
    : string().when('invoiceCheckbox', {
        is: true,
        then: string(),
      })

export const customTermValidator = (
  lang: ILangMap,
  customTerms: ICustomTerm[],
  values: { [key: string]: boolean },
  scope: ScopeTerm
) => {
  if (values) {
    const validationObject = termValidator(lang.errors.checkingThisRegulationsIsRequiredValidator)
    const newEntries = Object.keys(values).reduce((acc, val) => {
      const foundedTerm = customTerms.find(
        (term) => term.id === Number(val) && (term.scope === scope || term.scope === null)
      )
      return {
        ...acc,
        [val]: foundedTerm?.required ? validationObject : boolean().nullable(),
      }
    }, {})

    return object().shape(newEntries)
  }
  return mixed().notRequired()
}
