import { createSelector } from 'reselect'

import _Store from '@Store'

import {
  IPoolNormalized,
  IPoolSelectedTicket,
  IPoolsGroupedSelectedSeats,
  IPoolsSelectedTicketsReducer,
} from './../types'
import getPools from './getPools'
import getSeatsGroupedByCategoryKeysAndPools from './getSeatsGroupedByCategoryKeysAndPools'
import getSelectedTickets from './getSelectedTickets'

const getSelectedTicketsBySeats = createSelector<
  _Store.IState,
  IPoolsGroupedSelectedSeats,
  IPoolNormalized[],
  IPoolsSelectedTicketsReducer,
  IPoolSelectedTicket[]
>(
  [getSeatsGroupedByCategoryKeysAndPools, getPools, getSelectedTickets],
  (seatsGroupedByPoolNameOrKey, pools, selectedTickets) => {
    const updatedSelected: IPoolSelectedTicket[] = selectedTickets.filter(
      (ticket) => !ticket.isSeat
    )

    Object.keys(seatsGroupedByPoolNameOrKey).forEach((poolNameOrKey) => {
      const poolKeyNumber = parseInt(poolNameOrKey, 10)

      seatsGroupedByPoolNameOrKey[poolKeyNumber]?.map((poolWithSeats) => {
        const currentPool = pools.find((pool) => pool.id.toString() === poolWithSeats.poolName)
        const poolToUpdate = seatsGroupedByPoolNameOrKey[poolKeyNumber]?.find(
          (groupedPool) => groupedPool.poolName === currentPool?.id.toString()
        )

        if (currentPool && poolToUpdate) {
          updatedSelected.push({
            amount: poolToUpdate.amount,
            currency: currentPool.currency,
            poolId: currentPool.id,
            poolName: currentPool.title,
            price: currentPool.price,
            serviceFee: currentPool.serviceFee,
            paymentMethods: currentPool.payments,
            isSeat: true,
          })
        }
      })
    })

    return updatedSelected
  }
)

export default getSelectedTicketsBySeats
