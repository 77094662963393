import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { generateIdempotencyKey, setDayFromSlot, setSlot, resetSlot } from 'models/happening/actions'
import { getData, getSelected, getTimeSlots } from 'models/happening/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import { getTheme } from 'models/theme/selectors'

import TimeSlots from './TimeSlots.component'
import { ITimeSlotsFromDispatch, ITimeSlotsFromState } from './TimeSlots.types'

const mapStateToProps = (state: _Store.IState): ITimeSlotsFromState => {
  return {
    calculatePricePerPerson: getData(state)?.calculatePricePerPerson,
    happeningSpaces: getData(state)?.spaces,
    isLoadingAvailabilities: getLoading(CONST.AVAILABILITIES)(state),
    selectedDay: getSelected(state).day,
    selectedSlotFromState: getSelected(state).slot,
    selectedTheme: getTheme(state),
    selections: getSelected(state).selections,
    timeSlots: getTimeSlots(state),
    showSlotsAvailability: getData(state)?.showSlotsAvailability,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ITimeSlotsFromDispatch => {
  return {
    generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
    setDayFromSlot: (day) => dispatch(setDayFromSlot(day)),
    setSlot: (slot) => dispatch(setSlot(slot)),
    resetSlot: () => dispatch(resetSlot())
  }
}

export default connect<ITimeSlotsFromState, ITimeSlotsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(TimeSlots)
