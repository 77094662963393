import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = makeSxStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'background.paper',
    transition: '200ms',
    '&:hover': {
      transform: `translateY(-${4}px)`,
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.05)',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'inherit',
    flexDirection: 'inherit',
    flex: 'inherit',
  },
  body: {
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  title: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  footer: { mt: 4 },
  onSale: (theme) => ({ position: 'absolute', top: theme.spacing(3), right: theme.spacing(3) }),
}))
