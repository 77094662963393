import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import {
  IGetEventByIdSuccessPayload,
  IGetEventSuccessPayload,
  ISetCurrentKeyPayload,
} from './../types'

export const eventPageMounted = createStandardAction(CONSTS.EVENT_PAGE_MOUNTED)()

export const buyFormMounted = createStandardAction(CONSTS.BUY_FORM_MOUNTED)()

export const getEvent = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetEventSuccessPayload, Error>()

export const setCurrentKey = createStandardAction(CONSTS.SET_CURRENT_KEY)<ISetCurrentKeyPayload>()

export const getRundateSlug = createAsyncAction(
  CONSTS._GET_RUNDATE_SLUG_REQUEST,
  CONSTS._GET_RUNDATE_SLUG_SUCCESS,
  CONSTS._GET_RUNDATE_SLUG_FAILURE
)<number, IGetEventByIdSuccessPayload, Error>()
