import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import {
  generateIdempotencyKey,
  setCalculatedPrices,
  toggleSelectedUpsell,
  upsellMounted,
} from 'models/happening/actions'
import {
  getAvailabilities,
  getData,
  getHappeningEvent,
  getIsSelectedSlotLastInCurrentDay,
  getSelected,
} from 'models/happening/selectors'
import { IPriceToCalculate } from 'models/happening/types'

import Upsell from './Upsell.component'
import { IUpsellFromDispatch, IUpsellFromState } from './Upsell.types'

const mapStateToProps = (state: _Store.IState): IUpsellFromState => ({
  availabilities: getAvailabilities(state),
  isSelectedSlotLastInCurrentDay: getIsSelectedSlotLastInCurrentDay(state),
  selected: getSelected(state),
  happeningSpaces: getData(state)?.spaces,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IUpsellFromDispatch => ({
  calculatePrice: (value: IPriceToCalculate) => dispatch(setCalculatedPrices(value)),
  generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
  mounted: () => dispatch(upsellMounted()),
  toggleUpsellState: (value) => dispatch(toggleSelectedUpsell(value)),
})

export default connect<IUpsellFromState, IUpsellFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Upsell)
