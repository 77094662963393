import React, { FC } from 'react'

import SectionLead from 'components/Bricks/components/LeadSection'
import CardDeck from 'components/reusable/CardDeckLegacy'
import { BricksEnum } from 'models/pages/constants/bricks'
import { ISectionsComponentExtendedHotSpot } from 'models/pages/types/sections'
import sectionsApi from 'services/$sections-api'

import { IMultilineCardsProps } from './MultilineCards.types'

const MORE_BUTTON_DEFAULT_TEXT = 'Zobacz więcej'

const MultilineCards: FC<IMultilineCardsProps> = ({
  background,
  data,
  color,
  fixedWidthCards,
  hotDeals,
  id,
  isHorizontal,
  maxInRow,
  moreButton,
  moreButtonText = MORE_BUTTON_DEFAULT_TEXT,
  sectionDescription,
  sectionTitle,
  isLoading,
  compositionIdentifier,
  userLocation,
  extraFlags,
}) => {
  const cardData =
    !!data?.length && 'cloudsearch' in data[0]
      ? undefined
      : (data as ISectionsComponentExtendedHotSpot[])

  const cards = cardData ? sectionsApi.normalizeElementsToCardData(cardData, extraFlags) : []

  return (
    <>
      <SectionLead
        background={background}
        color={color}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <CardDeck
        background={background}
        cards={cards}
        color={color}
        fixedWidthCards={fixedWidthCards}
        hotDeals={hotDeals}
        id={id}
        forceHorizontalOnMobile={isHorizontal}
        maxInRow={maxInRow}
        isLoading={isLoading}
        children={moreButton && <span>{moreButtonText}</span>}
        compositionIdentifier={compositionIdentifier}
        type={BricksEnum.multiline_hotspots}
      />
    </>
  )
}

export default MultilineCards
