import React from 'react'

import StaticArticle from 'components/StaticArticle'
import { PageWrapper } from 'components/_layout/PageWrapper'

export const StaticArticlePage = () => {
  return (
    <PageWrapper>
      <StaticArticle />
    </PageWrapper>
  )
}
