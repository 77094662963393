import React, { useContext } from 'react'

import { Formik } from 'formik'

import { makeValidationSchema } from 'components/Happening/components/HappeningBuyForm/HappeningBuyForm.selector'
import {
  IHappeningBuyFormProps,
  IHappeningFormValues,
} from 'components/Happening/components/HappeningBuyForm/HappeningBuyForm.types'
import HappeningFormLayout from 'components/Happening/components/HappeningFormLayout'
import { PayUContext } from 'components/_functional/PayUContextProvider/PayUContextProvider.component'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const HappeningBuyForm = ({
  customTerms,
  initialValues,
  reserve,
  step,
}: IHappeningBuyFormProps) => {
  const { i18n } = useDictionary()
  const validationSchema = makeValidationSchema(customTerms, i18n)

  const { handleTokenizeCard, payuInstance, inputError } = useContext(PayUContext)

  const catchSaveData = async (values: IHappeningFormValues) => {
    // Functionality only for the Strefa MasterCard Music
    // TODO: Will be removed after October 12
    const regex1 = /^MC.*R$/i
    const regex2 = /^MC.{11}$/i
    const isValidCode = regex1.test(values.discount || '') || regex2.test(values.discount || '')

    if (handleTokenizeCard && payuInstance && !isValidCode) {
      const tokenize = await handleTokenizeCard(payuInstance)

      if (tokenize === 'ERROR' || !!inputError?.length) {
        return
      }
    }

    reserve(values)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={catchSaveData}
      children={(props) => <HappeningFormLayout {...props} step={step} />}
    />
  )
}

export default HappeningBuyForm
