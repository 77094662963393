import { CloudinaryPrefixes } from '../../constants/Cloudinary'
import { FavoritesTypes } from '../../constants/Favorites'
import { Route } from '../../router/routes'
import { IRouteMatchParams } from '../../router/types'
import { ICalendarYear } from '../../types/Calendar'
import { IFavoriteCategory, IFavoriteResponse, IRundateFavorite } from '../../types/Favorites'
import { ILangMap } from '../../types/Locale'
import { DataMutations } from '../DataMutations'

export class FavoritesApi {
  /**
   * Takes an array of IEventFavorite events and splits them into year, month or day "buckets": objects containing
   * an array of events and key property (year, month, day). Also handles undefined response.
   *
   * @param {IRundateFavorite | undefined} inputArray
   * @param {'year' | 'month' | 'day'} part
   *
   * @return Array<{key: string, events: IEventFavorite[]}>
   */
  private static splitFavoriteRundatesIntoDateBuckets = (
    inputArray: IRundateFavorite[] | undefined,
    part: 'year' | 'month' | 'day'
  ) => {
    if (!inputArray) return []

    const outputArray: Array<{ key: string; events: IRundateFavorite[] }> = []

    const getKey = (date: Date): string => {
      const key =
        part === 'year' ? date.getFullYear() : part === 'month' ? date.getMonth() : date.getDate()

      return key.toString()
    }

    inputArray.forEach((event) => {
      const rundate = event.time.toDate()
      const key = getKey(rundate)
      const existingIndex = outputArray.findIndex((item) => item.key === key)

      if (existingIndex > -1) outputArray[existingIndex].events.push(event)
      else outputArray.push({ key, events: [event] })
    })

    return outputArray.sort((current, next) => parseInt(current.key, 10) - parseInt(next.key, 10))
  }

  public static groupFavoriteRundates = (favoritesData: IFavoriteResponse[]) => {
    const archivedRundates: IRundateFavorite[] = []
    const upcomingRundates: IRundateFavorite[] = []

    favoritesData.map((response) => {
      if (!('time' in response.item)) return

      const isPastEvent = Date.now() > response.item.time.toDate().getTime()

      if (isPastEvent) {
        archivedRundates.push(response.item)

        return
      }

      upcomingRundates.push(response.item)
    })

    return {
      archivedRundates,
      upcomingRundates,
    }
  }

  public static sortFavoriteRundates = (rundates: IRundateFavorite[]): ICalendarYear[] => {
    const sortedByYears = FavoritesApi.splitFavoriteRundatesIntoDateBuckets(rundates, 'year')

    const sortedByYrsAndMonths = sortedByYears.map((year) => ({
      key: year.key,
      months: FavoritesApi.splitFavoriteRundatesIntoDateBuckets(year.events, 'month'),
    }))

    return sortedByYrsAndMonths.map((year) => ({
      year: year.key,
      months: year.months.map((month) => ({
        month: month.key,
        days: FavoritesApi.splitFavoriteRundatesIntoDateBuckets(month.events, 'day').map((day) => ({
          day: day.key,
          events: DataMutations.favoritesToCalendarTile(day.events),
        })),
      })),
    }))
  }

  public static favoriteTypes = {
    [FavoritesTypes.Artists]: {
      screen: Route.artist as keyof IRouteMatchParams,
      cloudinaryPrefix: CloudinaryPrefixes.ARTIST,
    },
    [FavoritesTypes.Rundates]: {
      screen: Route.calendar as keyof IRouteMatchParams,
      cloudinaryPrefix: CloudinaryPrefixes.EVENT,
    },
    [FavoritesTypes.Tags]: {
      screen: Route.search as keyof IRouteMatchParams,
      cloudinaryPrefix: CloudinaryPrefixes.TAG,
    },
    [FavoritesTypes.Selection]: {
      screen: Route.selection as keyof IRouteMatchParams,
      cloudinaryPrefix: CloudinaryPrefixes.SELECTION,
    },
    [FavoritesTypes.Places]: {
      screen: Route.place as keyof IRouteMatchParams,
      cloudinaryPrefix: CloudinaryPrefixes.PLACE,
    },
  }
}
