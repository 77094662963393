import { pl } from 'lang/pl'
import { Route } from 'router/routes'
import { EMPTY as EMPTY$, of as of$ } from 'rxjs'
import {
  filter as filter$,
  mergeMap as mergeMap$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import isEmptyObject from 'misc/helpers/isEmptyObject'
import { get as getBasket } from 'models/basket/selector'
import { getLocation } from 'models/connectedRouter/selectors'
import {
  informAboutError404,
  informAboutErrorModal,
  informAboutErrorWhenSomethingWrong,
} from 'models/errors/actions'
import { get } from 'models/errors/selectors'
import { getEvent as getEventAction } from 'models/event/actions'
import { getEvent } from 'models/event/selectors'
import { locationChange } from 'models/internalRouter/actions'
import { getIframeParams, getModule } from 'models/internalRouter/selectors'
import { selectTicket, updateSelectedTickets } from 'models/pools/actions'
import {
  getFinalPrice,
  getNumberOfInsuredTickets,
  getSelectedTickets,
} from 'models/pools/selectors'
import { getInsuranceSummaryData } from 'models/products/selectors'
import {
  buyAndPayOnline,
  getTransactionDetails as getTransactionDetailsAction,
  getTransactionInfo as getTransactionInfoAction,
} from 'models/transaction/actions'
import getTransactionDetails from 'models/transaction/selectors/getTransactionDetails'
import getTransactionInfo from 'models/transaction/selectors/getTransactionInfo'
import { isActionOf } from 'typesafe-actions'

import {
  addNewsletterSubscriptionToDataLayer,
  checkoutStep,
  eventAddPaymentInfo,
  eventAddToCart,
  eventAddToCartHappening,
  eventAutoFillData,
  eventBannerClick,
  eventBuyButtonClicked,
  eventDeeplinkWeb,
  eventProceed,
  eventRemoveFromCart,
  eventRemoveFromCartHappening,
  firstValidation,
  informAboutBuyingProcess,
  validateForm,
} from './../actions'
import { isFirstValidation, isSelectTickets } from './../selector'

export const addDataToGTMWhenAddPaymentInfoEvent: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventAddPaymentInfo)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { buttonLabel, paymentMethod } = action.payload
      const basketData = getBasket(state)
      const event = getEvent(state)
      const embedParam = getIframeParams(state)
      const isEmbed = embedParam && !isEmptyObject(embedParam)
      const finalPrice = getFinalPrice(state)
      const selectedTickets = getSelectedTickets(state)
      const insuranceSummary = getInsuranceSummaryData(state)
      if (event && basketData) {
        analytics.pushForAddPaymentInfo(
          paymentMethod,
          finalPrice,
          buttonLabel,
          !!isEmbed,
          event,
          basketData,
          selectedTickets,
          insuranceSummary
        )
      }

      if (!window?.ReactNativeWebView) return EMPTY$

      const msgPayload = {
        event: 'add_payment_info',
        event_eventInfo_detail: 'proceed to payment operator',
        event_eventInfo_text_displayed: action.payload.buttonLabel,
        event_category_event_type: 'interaction',
        event_category_primary_category: 'ecommerce',
        currency: event?.currency,
        value: finalPrice,
        payment_type: action.payload.paymentMethod,
        items: selectedTickets.map((ticket, index) => ({
          item_id: event?.eventId,
          item_name: event?.title,
          currency: ticket.currency,
          index,
          item_brand: `${event?.partnerName}|${event?.partnerId}`,
          item_category: `${event?.placeName}|${event?.placeId}`,
          item_category2: event?.category.name,
          item_category3: event?.artists?.map((art) => art.name).join(','),
          item_category4: '',
          item_category5: '',
          item_variant: event?.rundateId,
          price: (ticket.price || 0) + (ticket.serviceFee || 0),
          quantity: ticket.amount,
        })),
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(msgPayload))

      return EMPTY$
    })
  )
}

export const addDataToGTMOnDeeplinkWeb: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(eventDeeplinkWeb)),
    mergeMap$((action) => {
      const payload = action.payload
      analytics.pushForDeeplinkWeb(
        payload.textDisplayed,
        payload.destinationType,
        payload.destinationUrl
      )

      return EMPTY$
    })
  )
}

export const addDataToGTMOnAutoFillData: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(eventAutoFillData)),
    mergeMap$((action) => {
      analytics.pushForAutoFillData(action.payload)

      return EMPTY$
    })
  )
}

export const addDataToGTMOnProceed: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(eventProceed)),
    mergeMap$((action) => {
      analytics.pushForProceed(action.payload)

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenBannerClicked: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(eventBannerClick)),
    mergeMap$((action) => {
      analytics.pushBannerClick(action.payload)

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenAddToCartEvent: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf([eventAddToCart, eventAddToCartHappening])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state)
      const embedParam = getIframeParams(state)
      const isEmbed = embedParam && !isEmptyObject(embedParam)
      const numOfInsuredTickets = getNumberOfInsuredTickets(
        action.payload &&
          'isInsuranceSelected' in action.payload &&
          !!action.payload.isInsuranceSelected
      )(state)
      const module = getModule(state)
      const isActivity = event?.calendarEvent
      const embedParentUrl = embedParam?.currentUrl

      if (event && (module === Route.happening || module === Route.pinHappening)) {
        analytics.pushForAddToCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          event,
          isActivity,
          isInsurance: false,
          embedParentUrl,
        })
      }

      if (event && (module === Route.buy || module === Route.pinBuy)) {
        analytics.pushForAddToCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          numOfInsuredTickets,
          event,
          isInsurance:
            action.payload &&
            'isInsuranceAddToCartEvent' in action.payload &&
            action.payload.isInsuranceAddToCartEvent,
          embedParentUrl,
        })
      }

      if (!window?.ReactNativeWebView) return EMPTY$

      const msgPayload = {
        event: 'add_to_cart',
        event_eventInfo_detail: 'add to cart',
        event_category_event_type: 'interaction',
        event_category_primary_category: 'ecommerce',
        items: [
          {
            item_id: event?.eventId.toString(10),
            item_name: event?.title,
            currency: action.payload.currency,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: `${event?.partnerName} | ${event?.partnerId}`,
            item_category: `${event?.placeName} | ${event?.placeId}`,
            item_category2: !!event?.tags.length ? event?.tags[0].name : '',
            item_category3: event?.artists?.length
              ? event.artists.map((artist) => artist.name).join(',')
              : '',
            item_variant: event?.rundateId.toString(10),
            price: action.payload.price,
            quantity: 1,
            tags: event?.tags.map((tag) => tag.name).join(','),
            ticket_pool_id: 'poolId' in action.payload ? action.payload.poolId : 0,
          },
        ],
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(msgPayload))

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenRemoveFromCartEvent: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([eventRemoveFromCart, eventRemoveFromCartHappening])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state)
      const embedParam = getIframeParams(state)
      const isEmbed = embedParam && !isEmptyObject(embedParam)
      const module = getModule(state)
      const numOfInsuredTickets = getNumberOfInsuredTickets(
        action.payload &&
          'isInsuranceSelected' in action.payload &&
          !!action.payload.isInsuranceSelected
      )(state)
      const isActivity = event?.calendarEvent
      const embedParentUrl = embedParam?.currentUrl

      if (isActivity && event && (module === Route.happening || module === Route.pinHappening)) {
        analytics.pushForRemoveFromCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          event,
          isActivity,
          isInsurance: false,
          embedParentUrl,
        })
      }

      if (event && (module === Route.buy || module === Route.pinBuy)) {
        analytics.pushForRemoveFromCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          numOfInsuredTickets,
          event,
          isInsurance:
            action.payload &&
            'isInsuranceAddToCartEvent' in action.payload &&
            action.payload.isInsuranceAddToCartEvent,
          embedParentUrl,
        })
      }

      if (!window?.ReactNativeWebView) return EMPTY$

      const msgPayload = {
        event: 'remove_from_cart',
        event_eventInfo_detail: 'remove_from_cart',
        event_category_event_type: 'interaction',
        event_category_primary_category: 'ecommerce',
        items: [
          {
            item_id: event?.eventId.toString(10),
            item_name: event?.title,
            currency: action.payload.currency,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: `${event?.partnerName} | ${event?.partnerId}`,
            item_category: `${event?.placeName} | ${event?.placeId}`,
            item_category2: !!event?.tags.length ? event?.tags[0].name : '',
            item_category3: event?.artists?.length
              ? event.artists.map((artist) => artist.name).join(',')
              : '',
            item_variant: event?.rundateId.toString(10),
            price: action.payload.price,
            quantity: 1,
            tags: event?.tags.map((tag) => tag.name).join(','),
            ticket_pool_id: 'poolId' in action.payload ? action.payload.poolId : 0,
          },
        ],
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(msgPayload))

      return EMPTY$
    })
  )
}

export const addDataToGTMOnEventPurchase: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(getTransactionDetailsAction.success)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const transactionInfo = getTransactionInfo(state)
      const transactionDetails = getTransactionDetails(state)
      const transactionItems = transactionDetails?.transactionItems
      const transactionInsurance = transactionDetails?.transactionProducts
      const embedParam = getIframeParams(state)
      const isEmbed = !!(embedParam && !isEmptyObject(embedParam))
      const embedParentUrl = embedParam?.currentUrl

      if (transactionItems?.length !== 0 && transactionInfo) {
        analytics.pushForPurchase({
          isEmbed,
          transactionInfo,
          transactionItems,
          transactionInsurance,
          embedParentUrl,
        })
      }

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenPaymentComplete: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(getTransactionInfoAction.success)),
    withLatestFrom$(state$),
    tap$(([action, state]) => {
      const paymentDetails = action.payload
      const isEmbed = !!getIframeParams(state)

      const { products, ...actionField } = paymentDetails

      const formattedProducts = {
        ...products,
      }

      if (paymentDetails) {
        const layer = {
          ecommerce: {
            purchase: {
              actionField,
              products: [formattedProducts],
            },
          },
        }
        analytics.pushForTransactionCompletion(layer, isEmbed)
      }
    }),
    mergeMap$(() => EMPTY$)
  )
}

export const addDataToGTMWhenLocationChangedOld: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([locationChange, getEventAction.success])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const module = getModule(state)

      if (module === Route.rundate || module === Route.pinRundate) {
        return of$(informAboutBuyingProcess())
      } else {
        analytics.pushPageViewOld()
      }

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenEventLoaded: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(informAboutBuyingProcess)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const event = getEvent(state)

      if (event) {
        const layer = {
          eventId: event.eventId,
          rundateId: event.rundateId,
        }
        analytics.pushForBuyingProcessOld(layer)
      }

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenErrorModal: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(informAboutErrorModal)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const url = getLocation(state).pathname
      const error = get(state)

      analytics.pushForErrorModal(url, error)

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenErrorWhenSomethingWrong: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(informAboutErrorWhenSomethingWrong)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const url = getLocation(state).pathname

      analytics.pushForErrorWhenSomethingWrong(url)

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenError404: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(informAboutError404)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const url = getLocation(state).pathname

      analytics.pushForError404(url)

      return EMPTY$
    })
  )
}

export const addDataToGTMOnEventPage: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf([getEventAction.success, eventBuyButtonClicked])),
    withLatestFrom$(state$),
    filter$(([action, state]) => {
      const module = getModule(state)

      if (module === Route.rundate || module === Route.pinRundate) {
        return true
      }

      return false
    }),
    mergeMap$(([action, state]) => {
      const event = getEvent(state)
      const embedParam = getIframeParams(state)
      const isEmbed = embedParam && !isEmptyObject(embedParam)
      const addToCart = isActionOf(eventBuyButtonClicked)(action)

      if (event) {
        analytics.pushForEventPage(event, !!isEmbed, addToCart)
      }

      return EMPTY$
    })
  )
}

export const addDataToGTMWhenTicketSelected: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([selectTicket, updateSelectedTickets])),
    withLatestFrom$(state$),
    filter$(([_, state]) => !getSelectedTickets(state).length && isSelectTickets(state)),
    mergeMap$(() => of$(checkoutStep(1)))
  )
}

export const addDataToGTMWhenFormValidate: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(validateForm)),
    withLatestFrom$(state$),
    filter$(([_, state]) => isFirstValidation(state)),
    mergeMap$(() => of$(firstValidation(), checkoutStep(2)))
  )
}

export const addDataToGTMWhenFormSend: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(buyAndPayOnline)),
    mergeMap$(() => of$(checkoutStep(3)))
  )
}

export const addDataToGTMOnBuyPage: _Store.IEpic = (action$, state$, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(checkoutStep)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state)
      const embedParam = getIframeParams(state)
      const isEmbed = embedParam && !isEmptyObject(embedParam)
      const ticketsAmount = getSelectedTickets(state)
        .map((pool) => pool.amount)
        .reduce((a: number, b: number) => a + b, 0)
      const selectedTickets = getSelectedTickets(state)
        .map((pool) => `${pool.poolId}|${pool.amount}`)
        .join(', ')

      if (event) {
        analytics.pushForBuyPage(event, !!isEmbed, action.payload, ticketsAmount, selectedTickets)
      }

      return EMPTY$
    })
  )
}

export const addDataToGTMonNewsletterSub: _Store.IEpic = (action$, state$, { analytics }) =>
  action$.pipe(
    filter$(isActionOf(addNewsletterSubscriptionToDataLayer)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      analytics.pushNewsletterSubscription(action.payload, pl.buy.formLayout.buyAndPay)

      return EMPTY$
    })
  )
