import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.border.radius,
      padding: '2rem',
      marginBottom: '2rem',
      border: `${theme.border.width} solid ${theme.palette.primary.main}`,
      fontWeight: 500,
    },
    fontMedium: {
      fontWeight: 500,
    },
    enableOnTablet: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    disableOnTablet: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    date: {
      marginRight: '1.5rem',
    },
    close: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      height: '0.75rem',
      justifyContent: 'center',
      position: 'relative',
      width: '0.75rem',
    },
    divider: {
      backgroundColor: theme.palette.other.divider,
    },
  })
)

export default useStyles
