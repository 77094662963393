import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { CloudinaryPrefixes } from 'constants/Cloudinary'
import { fetchCloudinaryList } from 'services/Api/Cloudinary'
import { Fetcher } from 'services/Api/Fetcher'
import { LuceneQueryBuilder } from 'services/Api/LuceneQueryBuilder'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { ICloudSearchHit, ICloudSearchResponse } from 'types/CloudSearch'
import { IPlaceResponse } from 'types/Place'

export class PlaceApi {
  public static getFullPlace = async (slug: string) => {
    try {
      const placeData = await Fetcher.get<IPlaceResponse>(URI.GetPlace(slug))
      const cloudinaryImages = await fetchCloudinaryList({ slug, prefix: CloudinaryPrefixes.PLACE })
      const placeEvents = await PlaceApi.getPlaceEvents(slug, placeData.id)

      return { placeData, cloudinaryImages, placeEvents }
    } catch (e) {
      Logger.error('PlaceApi.getFullPlace', e)
    }
  }

  public static getPlaceEvents = async (
    eventSlug: string,
    placeId?: number
  ): Promise<ICloudSearchHit[]> => {
    if (!placeId) return []

    const placeEventsUrl = LuceneQueryBuilder.buildCloudSearchUrl({
      and: [
        { [HitFields.type]: [SearchTypes.RUNDATE, SearchTypes.ACTIVITY] },
        { [HitFields.place_id]: placeId },
      ],
      not: [{ [HitFields.slug]: eventSlug }],
      size: 4,
    })

    try {
      const placeEvents = await Fetcher.get<ICloudSearchResponse>(placeEventsUrl)

      return placeEvents.hits.hit
    } catch (e) {
      Logger.error('PlaceApi.getPlaceEvents', e)

      return []
    }
  }

  public static getPlaceActivities = async (slug: string, placeID: number) => {
    const placeActivitiesUrl = LuceneQueryBuilder.buildCloudSearchUrl({
      and: [{ [HitFields.type]: SearchTypes.ACTIVITY }, { [HitFields.place_id]: placeID }],
      not: [{ [HitFields.slug]: slug }],
      size: 4,
    })

    try {
      const placeActivities = await Fetcher.get<ICloudSearchResponse>(placeActivitiesUrl)

      return placeActivities.hits.hit
    } catch (e) {
      Logger.error('PlaceApi.getPlaceActivities', e)

      return []
    }
  }
}

// *:* AND (type:rundate OR type:activity) AND (place_id:4809) NOT (slug:genzie-tour-vol-2-katowice)
// *:* AND (type:rundate OR type:activity) AND (place_id:4809) NOT (slug:genzie-tour-vol-2-katowice)
