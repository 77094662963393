import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { reserveSlots } from 'models/happening/actions'
import { getHappeningTerms } from 'models/happening/selectors'

import HappeningBuyForm from './HappeningBuyForm.component'
import { getInitialValues } from './HappeningBuyForm.selector'
import { IHappeningBuyFormFromDispatch, IHappeningBuyFormFromState } from './HappeningBuyForm.types'

const mapStateToProps = (state: _Store.IState): IHappeningBuyFormFromState => ({
  customTerms: getHappeningTerms(state),
  initialValues: getInitialValues(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IHappeningBuyFormFromDispatch => ({
  reserve: (values) => dispatch(reserveSlots(values)),
})

export default connect<
  IHappeningBuyFormFromState,
  IHappeningBuyFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(HappeningBuyForm)
