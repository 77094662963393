import { ComponentType } from 'react'

import { Box, BoxProps, styled } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'

export const Styled = {
  Root: styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 4,
    textDecoration: 'none',
    '&[aria-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  })) as ComponentType<LinkProps>,
  Avatar: styled(Box)(({ theme }) => ({
    width: 96,
    height: 96,
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  })) as ComponentType<BoxProps>,
}
