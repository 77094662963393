import { ComponentType } from 'react'

import { Box, BoxProps, Paper, PaperProps, styled } from '@mui/material'

import ToolTip from 'components/reusable/ToolTip'
import { IToolTipProps } from 'components/reusable/ToolTip/ToolTip.types'

interface IStyleProps {
  isDesktop: boolean
}

export const Styled = {
  Root: styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
  })<IStyleProps>(({ theme, isDesktop }) => ({
    display: 'flex',
    flexFlow: isDesktop ? 'row nowrap' : 'column',
    padding: isDesktop ? theme.spacing(5) : theme.spacing(3),
    borderRadius: 16,
    alignItems: isDesktop ? 'center' : 'flex-start',
    gap: theme.spacing(3),
    justifyContent: isDesktop ? 'space-between' : 'center',
  })) as ComponentType<PaperProps & IStyleProps>,

  Description: styled(Box)({ flex: 1, width: '100%' }) as ComponentType<BoxProps>,

  ToolTip: styled(ToolTip)({
    position: 'relative',
    top: 1,
    left: 4,
  }) as ComponentType<IToolTipProps>,
}
