import * as React from 'react'

import { Route, routes } from 'router/routes'

import { BannerLink } from 'components/reusable/BannerLink'
import { CardDeck } from 'components/reusable/CardDeck'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import { IResultsGridProps } from 'components/search/ResultsGrid/ResultsGrid.types'
import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useInfiniteScroll } from 'misc/hooks/useInfiniteScroll'
import mobile_banner from 'misc/styles/images/baner_kalendarium_810x700.png'
import desktop_banner from 'misc/styles/images/baner_kalendarium_1248x415.png'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ResultsGrid = ({ results, onScrollEnd }: IResultsGridProps) => {
  const boxRef = React.useRef(null)
  const { i18n } = useDictionary()
  const { isDesktop, isTablet } = useBreakpoints()
  useInfiniteScroll(boxRef, onScrollEnd)

  const bannerPosition = isDesktop ? 4 : isTablet ? 3 : 2
  const bannerElement = !config.theme.isEmpik && (
    <BannerLink
      alt={i18n.promotionBanner.altText}
      mobileImage={mobile_banner}
      image={desktop_banner}
      to={routes[Route.about]}
      key={'search-banner'}
      id={'search-banner'}
      wrapperComponent={'li'}
    />
  )

  return (
    <CardDeck ref={boxRef}>
      {results.map((item, index) => {
        if (index === bannerPosition && results.length > bannerPosition)
          return (
            <React.Fragment key={item.id}>
              {bannerElement}
              <CloudSearchCard item={item} />
            </React.Fragment>
          )

        return <CloudSearchCard key={item.id} item={item} />
      })}
      {!!results && results.length <= bannerPosition && bannerElement}
    </CardDeck>
  )
}
