import config from 'config'
import { IAction, IPaymentOperatorReducer } from 'models/transaction/types'
import { getType } from 'typesafe-actions'

import { sendTransaction } from './../../actions'

const reducer = (
  state: IPaymentOperatorReducer = config.buy.defaultOperator,
  action: IAction
): IPaymentOperatorReducer => {
  switch (action.type) {
    // _POST_SUCCESS
    case getType(sendTransaction.success):
      return action.payload.paymentOperator

    default:
      return state
  }
}

export default reducer
