import * as React from 'react'

import {
  CompareArrows,
  ExpandMore,
  History,
  ManageSearch,
  PhonelinkErase,
  SettingsBackupRestore,
} from '@mui/icons-material'
import {
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { SectionContainer } from 'components/_layout/SectionContainer'
import SimpleLoading from 'components/reusable/SimpleLoading'
import { TicketDownload } from 'components/ticketManagement/TicketDownload'
import config from 'config'
import { PaymentMethods } from 'constants/PaymentMethods'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { TicketActions } from 'services/Api/TicketManagementApi'
import { URI } from 'services/URI'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { IRundateResponse } from 'types/EventRundate'
import { TicketResponse } from 'types/TicketManagement'

import { Styled } from './TicketActionsList.styles'

const Icon = {
  [TicketActions.CANCEL_TRANSFER]: PhonelinkErase,
  [TicketActions.CHANGE_TIME]: History,
  [TicketActions.HISTORY]: ManageSearch,
  [TicketActions.RETURN]: SettingsBackupRestore,
  [TicketActions.TRANSFER]: CompareArrows,
}

const FEST_PARTNER_IDS = [2988, 1910]

export const TicketActionsList = () => {
  const { i18n } = useDictionary()
  const { isWebview } = useAppState()
  const { code, email } = useParams<RouteParams<Route.ticketManagementTicket>>()
  const { data, isValidating } = useSWR<TicketResponse[]>(
    !!code && !!email && URI.getTicket(code, email)
  )
  const { isMobile } = useBreakpoints()
  const navigate = useNavigate()
  const contactFormRoute = prepareRoute({
    route: Route.contact,
    params: {
      slug: 'formularz-kontaktowy',
    },
  })

  if (!data || !email || !code) return <SimpleLoading />

  const handleClick = (actionKey: TicketActions, ticketIndex: string) => () =>
    navigate(
      prepareRoute({
        route: Route.ticketManagementAction,
        params: { code, email, ticketIndex, actionKey },
      })
    )

  const isFestPartner = (id?: number) => {
    return id ? FEST_PARTNER_IDS.includes(id) : false
  }

  const getTitle = (
    actionKey: TicketActions,
    hasSettledRundate: boolean,
    key: 'name' | 'description',
    rundate?: IRundateResponse
  ) => {
    const partnerName = key === 'name' ? rundate?.partner?.sellerName : ''
    switch (actionKey) {
      case TicketActions.RETURN:
        return !hasSettledRundate || rundate?.partner?.vatPayer
          ? i18n.ticketManagement.myTicket.actions[TicketActions.RETURN].agency.beforeSettlement[
              key
            ]
          : i18n.ticketManagement.myTicket.actions[TicketActions.RETURN].agency.afterSettlement[
              key
            ] + partnerName
      default:
        return i18n.ticketManagement.myTicket.actions[actionKey][key]
    }
  }

  return (
    <>
      {data.map(({ ticket, actions, rundate, hasSettledRundate }, ticketIndex) => (
        <React.Fragment key={String(ticket.ticketId)}>
          <SectionContainer title={i18n.ticketManagement.myTicket.downloadHeader}>
            <TicketDownload ticketId={ticket.ticketId} email={email} code={code} />
          </SectionContainer>
          {isFestPartner(rundate?.partner?.id) && (
            <SectionContainer title={i18n.ticketManagement.myTicket.seller} container={true}>
              <Typography
                variant={'body2'}
                children={
                  actions.request_return_ticket.isAvailable
                    ? i18n.ticketManagement.myTicket.goingSeller
                    : i18n.ticketManagement.myTicket.festSeller
                }
              />
            </SectionContainer>
          )}
          <SectionContainer title={i18n.ticketManagement.myTicket.actionsHeader}>
            <Styled.ActionsWrapper>
              {Object.entries(actions).map(([key, actionData]) => {
                const isDisabled =
                  !!actionData.errors?.length ||
                  (key === TicketActions.TRANSFER &&
                    config.buy.defaultPaymentMethod !== PaymentMethods.PAYU)
                const actionKey = key as TicketActions
                const IconComponent = Icon[actionKey]

                return (
                  <Styled.Accordion key={key}>
                    <AccordionSummary disabled={isDisabled} expandIcon={<ExpandMore />}>
                      <ListItem disableGutters disablePadding>
                        <ListItemIcon children={<IconComponent />} />
                        <ListItemText
                          primary={getTitle(actionKey, hasSettledRundate, 'name', rundate)}
                          secondary={
                            isDisabled &&
                            (actionData.errors?.[0] || i18n.tooltip.serviceUnavailable)
                          }
                          secondaryTypographyProps={{ variant: 'caption' }}
                        />
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        color={'text.secondary'}
                        variant={'body2'}
                        children={getTitle(actionKey, hasSettledRundate, 'description', rundate)}
                      />
                    </AccordionDetails>
                    <AccordionActions>
                      <Button
                        variant={'contained'}
                        children={i18n.buttons.begin}
                        onClick={handleClick(actionKey, String(ticketIndex))}
                      />
                    </AccordionActions>
                  </Styled.Accordion>
                )
              })}
            </Styled.ActionsWrapper>
          </SectionContainer>
          {isFestPartner(rundate?.partner?.id) && !actions.request_return_ticket.isAvailable && (
            <SectionContainer container={true}>
              <Typography
                component={'span'}
                variant={'body2'}
                children={i18n.ticketManagement.myTicket.contactOrganizer}
              />
              <Typography
                component={'span'}
                variant={'body2'}
                fontWeight={600}
                children={'tickets@followthestep.com.'}
              />
            </SectionContainer>
          )}
        </React.Fragment>
      ))}
    </>
  )
}
