import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    radius: {
      borderRadius: `${theme.border.radius} 0 0`,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
)

export default useStyles
