import { IPoolForms, IPoolSelectedTicket } from 'models/pools/types'
import { ITransactionForms } from 'models/transaction/types'
import { IPaymentMethod } from 'types/Pools'

class PoolsSelector {
  public updateSelected(
    selectedTickets: IPoolSelectedTicket[],
    poolId: number,
    amount: number,
    poolName: string,
    currency: string,
    price?: number,
    serviceFee?: number,
    additionalFields?: boolean,
    forms?: IPoolForms[],
    submissions?: ITransactionForms[],
    paymentMethods?: IPaymentMethod[]
  ): IPoolSelectedTicket[] {
    const updatedSelected = selectedTickets.filter((ticket) => ticket.isSeat)
    const alreadySelected = selectedTickets.find((item) => item.poolId === poolId)

    if (amount === 0) {
      // Already selected pool/amount clicked, so just deselect it
      updatedSelected.push(
        ...selectedTickets.filter((item) => item.poolId !== poolId && !item.isSeat)
      )
    } else if (alreadySelected?.amount !== 0) {
      // Selected different amount of already picked pool,
      // so remove previous selection and add the new one
      updatedSelected.push(
        ...selectedTickets.filter((item) => item.poolId !== poolId && !item.isSeat),
        {
          additionalFields,
          amount,
          currency,
          forms,
          poolId,
          poolName,
          price,
          serviceFee,
          submissions,
          paymentMethods,
        }
      )
    } else {
      // Selected pool which is not already selected - just select it
      updatedSelected.push(...selectedTickets, {
        additionalFields,
        amount,
        currency,
        forms,
        poolId,
        poolName,
        price,
        serviceFee,
        submissions,
        paymentMethods,
      })
    }

    return updatedSelected
  }
}

export default new PoolsSelector()
