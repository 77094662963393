import React, { useEffect, useState } from 'react'

import { Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Field } from 'formik'

import { SeatsIO } from 'components/SeatsIO'
import { SectionContainer } from 'components/_layout/SectionContainer'
import styles from 'components/buying/Pools/Pools.module.scss'
import useStyles from 'components/buying/Pools/Pools.styles'
import { IPoolsProps } from 'components/buying/Pools/Pools.types'
import GeneralAdmission from 'components/buying/Pools/components/GeneralAdmission'
import Loading from 'components/buying/Pools/components/Loading'
import Pool from 'components/buying/Pools/components/Pool'
import FormField from 'components/formik/FormField'
import { Languages } from 'constants/Languages'
import goTrough from 'misc/helpers/goTrough'
import groupBy from 'misc/helpers/groupBy'
import { useRundateID } from 'models/event/hooks/useRundateID'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

const LOADERS_COUNT = 5

const Pools = ({
  arePoolsLoading,
  isTransactionLoading,
  number,
  pools,
  poolsMounted,
  selectedPools,
  shouldShowSeatsIo,
}: IPoolsProps) => {
  const { i18n } = useDictionary()
  const { code: selectedLang } = useLang()
  const rundateID = useRundateID()
  const [showSeats, setShowSeats] = useState(false)

  const toggleSeats = () => {
    setShowSeats((prev) => !prev)
  }

  useEffect(() => poolsMounted(), [])

  const shouldShowPoolsOrSeats = !arePoolsLoading

  const noResults = !arePoolsLoading && pools.length === 0

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  const activePools = pools.filter((pool) => pool.active)

  const shouldShowGeneralAdmissionPools = activePools.some((pool) => pool.generalAdmission)
  const isOnlyGA = activePools.every((pool) => pool.generalAdmission)
  const generalAdmissionPools =
    shouldShowSeatsIo && activePools.filter((pool) => pool.generalAdmission)

  const langKey = selectedLang === Languages.English ? 'poolGroupEN' : 'poolGroupPL'
  const groupedPools = groupBy(pools, (pool) => pool[langKey])
  const groupedPoolsKeys = [
    ...Array.from(groupedPools.keys()).filter((pool) => pool),
    ...Array.from(groupedPools.keys()).filter((pool) => !pool),
  ]

  return (
    <div className={cn(styles.pools, isTransactionLoading && styles.disabled)}>
      <h3 className={styles.header} data-number={number}>
        {i18n.buy.pools.title}
      </h3>

      {noResults && <p className={styles.noResults}>{i18n.buy.pools.noPoolsAvailable}</p>}

      {shouldShowPoolsOrSeats && (
        <>
          {shouldShowSeatsIo && (
            <>
              {shouldShowGeneralAdmissionPools && (
                <>
                  {generalAdmissionPools &&
                    !!generalAdmissionPools.length &&
                    generalAdmissionPools.map((pool) => (
                      <Pool
                        key={`generalAdmissionPool-${pool.id}`}
                        pool={pool}
                        rundateID={rundateID}
                      />
                    ))}
                  {generalAdmissionPools && !showSeats && shouldShowGeneralAdmissionPools && (
                    <GeneralAdmission toggleSeats={toggleSeats} isOnlyGA={isOnlyGA} />
                  )}
                </>
              )}
              {(showSeats || !shouldShowGeneralAdmissionPools) && (
                <SectionContainer sx={{ mb: 4 }} container>
                  <SeatsIO rundateID={rundateID} />
                </SectionContainer>
              )}
              {activePools.map((pool, index) => (
                <span key={index + pool.id} data-pool-id={pool.id} />
              ))}
            </>
          )}
          <ul>
            {groupedPoolsKeys.map((key) => {
              const groupPools = groupedPools.get(key)

              if (groupPools) {
                return (
                  <Grid container={true} spacing={3} className={muiStyles.group} key={key}>
                    {!shouldShowSeatsIo && key && (
                      <Grid item={true} xs={12}>
                        <Typography variant={'h5'}>{key}</Typography>
                      </Grid>
                    )}
                    <Grid item={true} xs={12}>
                      {groupPools.map((pool) => {
                        const selectedPoolDependency = selectedPools.find(
                          (selectedPool) => selectedPool.poolId === pool.id
                        )

                        return (
                          <li key={pool.id}>
                            {!shouldShowSeatsIo && <Pool pool={pool} rundateID={rundateID} />}
                            <fieldset
                              className={cn(
                                styles.formPanel,
                                styles.autoCompleteContainer,
                                selectedPoolDependency && pool.hasDependency
                                  ? styles.hasDependency
                                  : styles.noDependency,
                                muiStyles.root
                              )}
                            >
                              {selectedPoolDependency && pool.hasDependency && (
                                <label className={styles.label} htmlFor="dependencyCode">
                                  <div className={styles.labelHeader}>
                                    {shouldShowSeatsIo && <h4>{pool.title}</h4>}
                                    <p>{i18n.buy.pools.dependency}</p>
                                  </div>
                                  <Field
                                    value={pool.hasDependency}
                                    name="hasDependency"
                                    id="hasDependency"
                                    hidden={true}
                                  />
                                  <FormField
                                    id="dependencyCode"
                                    name="dependencyCode"
                                    placeholder={i18n.buy.pools.dependencyPlaceHolder}
                                    type="text"
                                  />
                                </label>
                              )}
                            </fieldset>
                          </li>
                        )
                      })}
                    </Grid>
                  </Grid>
                )
              }
            })}
          </ul>
        </>
      )}

      {arePoolsLoading && (
        <div className={styles.loadingWrapper}>
          {goTrough(LOADERS_COUNT).map((key) => (
            <Loading key={key} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Pools
