import { Box, Divider, styled } from '@mui/material'

export const Styled = {
  ListContainer: styled(Box)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.grey['200'],
    borderRadius: 16,
    width: '100%',
  })),
  Divider: styled(Divider)(({ theme: { palette } }) => ({
    width: '100%',
    borderColor: palette.background.paper,
  })),
  CheckboxWrapper: styled(Box)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    padding: spacing(4),
    borderRadius: 16,
    backgroundColor: palette.info.light,
  })),
  Footer: styled(Box, { shouldForwardProp: (prop) => prop !== 'isDesktop' })<{
    isDesktop: boolean
  }>(({ theme: { palette, spacing }, isDesktop }) => ({
    display: 'flex',
    flexDirection: isDesktop ? 'row-reverse' : 'column',
    gap: spacing(2),
    position: 'sticky',
    bottom: -16,
    backgroundColor: palette.background.paper,
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
  })),
}
