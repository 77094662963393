import { IGiftCardActivationPayload } from 'models/giftCard/types'
import { IGiftCardActivationResponse } from 'services/$giftCard-api/types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'

export const giftCardActivation = createStandardAction(
  CONSTS.GIFT_CARD_ACTIVATION
)<IGiftCardActivationPayload>()

// Private actions
export const activateGiftCard = createAsyncAction(
  CONSTS._GIFT_CARD_ACTIVATION_REQUEST,
  CONSTS._GIFT_CARD_ACTIVATION_SUCCESS,
  CONSTS._GIFT_CARD_ACTIVATION_FAILURE
)<IGiftCardActivationPayload, IGiftCardActivationResponse, Error>()
