import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import { IPageData, IPagesRequestData } from './../types'

export const getCustomPage = createStandardAction(CONSTS.GET_CUSTOM_PAGE)()

export const openPageAuth = createStandardAction(CONSTS.OPEN_PAGE_AUTH)<IPagesRequestData>()

export const authorizePage = createStandardAction(CONSTS.AUTHORIZE_PAGE)<IPagesRequestData>()

export const fetchUrlStructure = createStandardAction(CONSTS.GET_URL_STRUCTURE)()

export const setAuthError = createStandardAction(CONSTS.SET_AUTH_ERROR)()

export const resetAuthError = createStandardAction(CONSTS.RESET_AUTH_ERROR)()

export const getSections = createAsyncAction(
  CONSTS._GET_SECTIONS_REQUEST,
  CONSTS._GET_SECTIONS_SUCCESS,
  CONSTS._GET_SECTIONS_FAILURE
)<IPagesRequestData, IPageData, Error>()
