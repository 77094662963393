import React from 'react'

import { Spring, animated, interpolate } from 'react-spring/renderprops.cjs'

import { PageWrapper } from 'components/_layout/PageWrapper'
import config from 'config'
import useSvgInterpolation from 'misc/hooks/useSvgInterpolation'

import styles from './Loading.module.scss'
import { paths } from './Loading.paths'

const LOADING_TEXT = config.theme.isEmpik ? 'Ładowanie' : 'Going'
const loadingColor = config.theme.isEmpik ? 'black' : '#eff200'
const loadingDots = ['.', '..', '...', '....']

const Loading = () => {
  const { index, interpolator, next } = useSvgInterpolation(paths)

  return (
    <PageWrapper>
      <div className={styles.container}>
        <svg width="180" viewBox="0 0 22 22">
          <g fill={loadingColor}>
            <Spring
              reset={true}
              native={true}
              from={{ transition: 0 }}
              to={{ transition: 1 }}
              onRest={next}
            >
              {({ transition }) => (
                <animated.path d={interpolate([transition], (val: number) => interpolator(val))} />
              )}
            </Spring>
          </g>
        </svg>
        <h2 className={styles.loadingText} style={{ color: loadingColor }}>
          {LOADING_TEXT + loadingDots[index]}
        </h2>
      </div>
    </PageWrapper>
  )
}

export default Loading
