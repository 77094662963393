import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: '2rem',
      fontSize: '1.75rem',

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    secondary: {
      color: theme.palette.text.secondary,
    },
    withoutPadding: {
      padding: '0!important',
    },
  })
)

export default useStyles
