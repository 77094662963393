import { IClientLastData } from 'components/buying/components/ClientData/ClientData.types'
import { StorageKeys } from 'constants/StorageKeys'
import { LocalStorage } from 'services/LocalStorage'

const getPreviousForm = () => {
  try {
    return LocalStorage.get<IClientLastData>(StorageKeys.BuyForm)
  } catch (e) {
    LocalStorage.delete(StorageKeys.BuyForm)
  }
}

export default getPreviousForm
