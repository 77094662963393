import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

export const appState = atom({
  default: {
    isWebview: false,
    isDarkTheme: false,
    isBrowser: typeof window !== 'undefined',
  },
  key: RecoilKeys.app,
})
