import axios, { CancelTokenSource } from 'axios'

import EventsListService from 'misc/classes/EventsListService'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import { IArticleFull } from 'types/Article'

import { ArticlePrefix, sources } from './sources'
import { IArticleResponse, IArticleRouterParams } from './types'

class ArticleApi extends EventsListService {
  private cancelTokenArticle?: CancelTokenSource

  public makeKeyFromParams(params: IArticleRouterParams) {
    return `${params.articleSlug}`
  }

  public getArticleUrl(sourceSlug: ArticlePrefix, articleSlug: string): string {
    return `${sources[sourceSlug]}wp-json/wp/v2/posts?_embed&slug=${articleSlug}`
  }

  public getSingleArticle(sourceSlug: ArticlePrefix, articleSlug: string): Promise<IArticleFull> {
    return new Promise((resolve, reject) => {
      const articleUrl = this.getArticleUrl(sourceSlug, articleSlug)
      this.cancelTokenArticle = axios.CancelToken.source()

      axios
        .get(
          articleUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenArticle.token,
          })
        )
        .then(getData)
        .then((response) => {
          resolve(this.normalizeFullArticle(response[0]))
        })
        .catch((error) => {
          reject(catchHttpError(error))
        })
    })
  }

  public normalizeFullArticle(response: IArticleResponse): IArticleFull {
    return {
      articleSlug: response.slug,
      authors: response._embedded.author.map((author) => ({
        avatarUrl: author.avatar_urls['96'],
        link: author.link,
        name: author.name,
      })),
      html: response.content.rendered,
      imageUrl:
        response._embedded['wp:featuredmedia'] &&
        response._embedded['wp:featuredmedia'][0].source_url,
      paragraphs: [],
      tags: [],
      title: response.title.rendered,
    }
  }
}

export default new ArticleApi()
