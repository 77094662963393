import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: '100%',
      objectFit: 'contain',
      transform: 'translateY(-11rem)',
      width: '100%',
    },
  })
)

export default useStyles
