import React from 'react'

import Timeline from 'components/reusable/Timeline'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import { useAppState } from 'state/app/hooks/useAppState'

import styles from './TimelineBrick.module.scss'
import { ITimelineBrickProps } from './TimelineBrick.types'

const TimelineBrick = ({
  background,
  color,
  id,
  isLoading,
  timeline,
  compositionIdentifier,
}: ITimelineBrickProps) => {
  const { isDarkTheme } = useAppState()

  return (
    <div className={styles.timeline} id={id}>
      <Timeline
        isDark={
          isColorDark(background) ||
          isColorDark(isDarkTheme && background ? invertIfGrayScaleColor(background) : background)
        }
        color={color}
        timeline={timeline}
        isLoading={isLoading}
        compositionIdentifier={compositionIdentifier}
        brickId={id}
      />
    </div>
  )
}

export default TimelineBrick
