import React, { useContext, useEffect } from 'react'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'

import { IAddToGlobalBasketProps } from './AddToGlobalBasket.types'

const AddToGlobalBasket = ({ addToParentBasket, partnerId }: IAddToGlobalBasketProps) => {
  const BagState = useContext(clearContext)

  useEffect(() => {
    if (BagState?.basketItems && partnerId) {
      const filtered = BagState.basketItems.filter(
        (item) => Number(item.partnerId) === Number(partnerId)
      )
      addToParentBasket(filtered)
    }
  }, [JSON.stringify(BagState?.basketItems), partnerId])

  return null
}

export default AddToGlobalBasket
