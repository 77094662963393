import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import config from 'config'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      '&:visited': {
        color: config.theme.isEmpik ? theme.palette.primary.main : theme.palette.text.primary,
      },
      color: theme.palette.text.primary,
    },
  })
)

export default useStyles
