import React from 'react'

import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { useFormikContext } from 'formik'

import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import FormField from 'components/formik/FormField'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './PostAddress.module.scss'
import { Styled } from './PostAddress.styles'
import { IPostAddressProps } from './PostAddress.types'
import { en, pl } from './countries'

const PostAddress = ({ id }: IPostAddressProps) => {
  const { i18n } = useDictionary()

  const { values, handleChange } = useFormikContext<IBuyingOnlineFormValues>()

  const countries = { pl, en }

  const formatFieldName = (name: string) => (id ? `${name}_${id}` : `${name}`)

  return (
    <div className={styles.addressBlock} data-test-id={'post-address'}>
      <div className={styles.wrap}>
        <p className={styles.info}>{i18n.buy.postAddress.infoText}</p>
        <div className={styles.name}>
          <FormField
            data-test-id={'post-address-deliveryFirstname'}
            id={formatFieldName('deliveryFirstname')}
            name={formatFieldName('deliveryFirstname')}
            placeholder={`${i18n.buy.postAddress.firstNameText}*`}
            type="text"
          />
        </div>
        <div className={styles.name}>
          <FormField
            data-test-id={'post-address-deliveryLastname'}
            id={formatFieldName('deliveryLastname')}
            name={formatFieldName('deliveryLastname')}
            placeholder={`${i18n.buy.postAddress.lastNameText}*`}
            type="text"
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.street}>
          <FormField
            data-test-id={'post-address-deliveryStreet'}
            id={formatFieldName('deliveryStreet')}
            name={formatFieldName('deliveryStreet')}
            placeholder={`${i18n.buy.postAddress.streetText}*`}
            type="text"
          />
        </div>
        <div className={styles.number}>
          <FormField
            data-test-id={'post-address-deliveryNumberStreet'}
            id={formatFieldName('deliveryNumberStreet')}
            name={formatFieldName('deliveryNumberStreet')}
            placeholder={`${i18n.buy.postAddress.streetNumberText}*`}
            type="text"
          />
        </div>
        <div className={styles.number}>
          <FormField
            id={formatFieldName('deliveryLocalStreet')}
            name={formatFieldName('deliveryLocalStreet')}
            placeholder={`${i18n.buy.postAddress.localText}`}
            type="text"
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.code}>
          <FormField
            data-test-id={'post-address-deliveryZip'}
            id={formatFieldName('deliveryZip')}
            name={formatFieldName('deliveryZip')}
            placeholder={`${i18n.buy.postAddress.zipText}*`}
            type="text"
          />
        </div>
        <div className={styles.city}>
          <FormField
            data-test-id={'post-address-deliveryCity'}
            id={formatFieldName('deliveryCity')}
            name={formatFieldName('deliveryCity')}
            placeholder={`${i18n.buy.postAddress.cityText}*`}
            type="text"
          />
        </div>
      </div>
      <Box sx={{ mt: 3 }}>
        <FormControl fullWidth>
          <Styled.InputLabel children={i18n.buy.postAddress.countryText} />

          <Select
            value={values.deliveryCountry}
            name={formatFieldName('deliveryCountry')}
            variant={'outlined'}
            onChange={handleChange}
            fullWidth
          >
            {(Object.keys(countries[i18n._lang]) as Array<keyof typeof pl>).map((country) => (
              <MenuItem key={country} value={country}>
                {countries[i18n._lang][country]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div className={styles.wrap}>
        <div className={styles.phone}>
          <FormField
            data-test-id={'post-address-deliveryPhone'}
            id={formatFieldName('deliveryPhone')}
            name={formatFieldName('deliveryPhone')}
            placeholder={`${i18n.buy.postAddress.phoneText}*`}
            type="text"
          />
        </div>
      </div>
    </div>
  )
}

export default PostAddress
