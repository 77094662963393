import * as React from 'react'

import {
  ConfirmationNumberOutlined,
  EmailOutlined,
  InsertEmoticon,
  SavingsOutlined,
} from '@mui/icons-material'
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'

import { Form } from 'components/_forms/Form'
import { RadioGroupInput } from 'components/_forms/RadioGroupInput'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { ActionLoading } from 'components/ticketManagement/TicketAction/ActionLoading'
import { Styled } from 'components/ticketManagement/TicketAction/TicketAction.styles'
import { ITicketActionsProps } from 'components/ticketManagement/TicketAction/TicketAction.types'
import { ITransferFormInput } from 'components/ticketManagement/TicketAction/Transfer/Transfer.types'
import { useTransfer } from 'components/ticketManagement/TicketAction/Transfer/useTransfer'
import { CurrencyISO, currencySymbol } from 'constants/Currency'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

export const Transfer = ({ actionKey, email, code, ticketIndex }: ITicketActionsProps) => {
  const { isMobile } = useBreakpoints()
  const {
    activeStep,
    i18n,
    control,
    handleBack,
    handleSubmit,
    isLoading,
    validationRules,
    getValues,
    transferFee,
    quantityOptions,
  } = useTransfer(code, email, parseInt(ticketIndex), actionKey)

  const submitLabel = () => {
    switch (activeStep) {
      case 1:
        return i18n.buttons.confirm
      case 2:
        return i18n.buttons.done
      default:
        return i18n.buttons.next
    }
  }

  return (
    <Styled.Root>
      <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
        {i18n.ticketManagement.myTicket.steps.map((label, index) => (
          <Step key={label} completed={activeStep > index || activeStep === 2}>
            <StepLabel children={label} />
          </Step>
        ))}
      </Stepper>
      <Styled.Body>
        {isLoading ? (
          <ActionLoading actionKey={actionKey} hideText={activeStep !== 1} />
        ) : /** STEP ① : START */ activeStep === 0 ? (
          <Form.Wrapper sx={{ flex: 'inherit' }}>
            <Typography variant={'h5'} children={i18n.ticketManagement.transferTicket.formTitle} />
            <RadioGroupInput<ITransferFormInput>
              name={FormFields.QUANTITY}
              label={i18n.ticketManagement.transferTicket.transferQuantity}
              options={quantityOptions}
              control={control}
              rules={validationRules[FormFields.QUANTITY]}
            />
            <TextInput<ITransferFormInput>
              inputMode={'email'}
              label={i18n.forms.labels[FormFields.EMAIL]}
              name={FormFields.EMAIL}
              control={control}
              rules={validationRules[FormFields.EMAIL]}
              helperText={i18n.ticketManagement.transferTicket.emailHelper}
            />
            <TextInput<ITransferFormInput>
              label={i18n.forms.labels[FormFields.FIRSTNAME]}
              name={FormFields.FIRSTNAME}
              control={control}
              rules={validationRules[FormFields.FIRSTNAME]}
            />
            <TextInput<ITransferFormInput>
              label={i18n.forms.labels[FormFields.LASTNAME]}
              name={FormFields.LASTNAME}
              control={control}
              rules={validationRules[FormFields.LASTNAME]}
              helperText={i18n.ticketManagement.transferTicket.credentialsHelper}
            />
          </Form.Wrapper>
        ) : /** STEP ② : CONFIRM */ activeStep === 1 ? (
          <Styled.Content>
            <Typography
              variant={'h5'}
              mb={4}
              children={i18n.ticketManagement.myTicket.actions[actionKey].prompt}
            />
            <List>
              <ListItem>
                <ListItemIcon children={<InsertEmoticon />} />
                <ListItemText
                  children={`${getValues(FormFields.FIRSTNAME)} ${getValues(FormFields.LASTNAME)}`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon children={<EmailOutlined />} />
                <ListItemText children={getValues(FormFields.EMAIL)} />
              </ListItem>
              <ListItem>
                <ListItemIcon children={<ConfirmationNumberOutlined />} />
                <ListItemText
                  children={
                    i18n.ticketManagement.transferTicket.numToTransfer +
                    getValues(FormFields.QUANTITY)
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon children={<SavingsOutlined />} />
                <ListItemText
                  children={
                    i18n.ticketManagement.transferTicket.yourFee +
                    transferFee.senderFee +
                    currencySymbol[CurrencyISO.PLN]
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon children={<SavingsOutlined />} />
                <ListItemText
                  children={
                    i18n.ticketManagement.transferTicket.recipientFee +
                    transferFee.recipientFee +
                    currencySymbol[CurrencyISO.PLN]
                  }
                />
              </ListItem>
            </List>
          </Styled.Content>
        ) : (
          /** STEP ③ : SUCCESS */ <Styled.Content>
            <Typography variant={'h5'} mb={4} children={i18n.ticketManagement.myTicket.thanks} />
            <Typography
              variant={'body2'}
              color={'text.secondary'}
              children={i18n.ticketManagement.myTicket.actions[actionKey].success}
            />
          </Styled.Content>
        )}
        <Styled.Footer>
          <Button
            variant={'outlined'}
            children={i18n.buttons.back}
            onClick={handleBack}
            disabled={activeStep !== 1 || isLoading}
          />
          <Button
            disabled={isLoading}
            variant={'contained'}
            type={'submit'}
            children={submitLabel()}
            onClick={handleSubmit}
          />
        </Styled.Footer>
      </Styled.Body>
    </Styled.Root>
  )
}
