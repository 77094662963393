import { CancelTokenSource } from 'axios'
import { format, isToday, isTomorrow } from 'date-fns'
import { pl } from 'date-fns/locale'

import config from 'config'
import { capitalizeFirstLetter } from 'misc/helpers/capitalizeFirstLetter'
import { ITag } from 'types/Common'
import { IDate } from 'types/DateTime'
import { IEventImage } from 'types/EventCommon'
import { IPlaceResponse } from 'types/Place'

const TODAY = 'Dzisiaj'
const TOMORROW = 'Jutro'

class EventsListService {
  public static getDateString(startDate: IDate | undefined): string {
    if (!startDate) {
      return ''
    }
    const { year, month, day, hour, minutes } = startDate

    return `${year}-${month}-${day}T${hour}:${minutes}Z`
  }

  /**
   * Parses date strings into Cloudsearch date hash.
   *
   * @param {string} inputStr - string in date format yyyy-mm-dd.
   */
  public static getDateHashFromDateString = (inputStr?: string) => {
    const arr = inputStr?.split('-')

    if (arr?.length !== 3) return

    return parseInt(
      arr
        .map((item, index) =>
          index === 1 ? (parseInt(item, 10) - 1).toString().padStart(2, '0') : item.padStart(2, '0')
        )
        .join('')
    )
  }

  public static getFriendlyDate(startDate: string, descriptiveDate?: string): string {
    const _date = new Date(startDate)
    const date = new Date(new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000))

    if (descriptiveDate) {
      return descriptiveDate
    } else if (!startDate) {
      return ''
    } else if (isToday(date)) {
      return TODAY
    } else if (isTomorrow(date)) {
      return TOMORROW
    }

    return capitalizeFirstLetter(format(date, 'EEEE, d MMMM yyyy', { locale: pl }))
  }

  public static getFriendlyPlace(place?: IPlaceResponse) {
    return place ? `${place.name}, ${place.address}` : ''
  }

  public static getImageUrl(images: IEventImage[]): string {
    return images.length > 0 ? images[0].medium : ''
  }

  public static getPriceDescription(priceDescription?: string) {
    if (priceDescription && priceDescription.length > 0) {
      return priceDescription
    }
  }

  public static normalizeEventPlaceTags(placeTag: ITag | null): ITag[] | null {
    if (!placeTag) {
      return null
    }

    return [
      {
        id: placeTag.id,
        name: placeTag.name,
        slug: placeTag.slug,
      },
    ]
  }

  protected static salesAgentHeader = {
    'X-Sales-Agent': config.app.salesAgent,
  }

  protected static getFriendlyHour(
    startDate: string,
    descriptiveDate?: string,
    hourDescription?: string
  ): string | undefined {
    const _date = new Date(startDate)
    const date = new Date(new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000))

    if (hourDescription) return hourDescription

    if (descriptiveDate) return undefined

    return format(date, 'HH:mm', { locale: pl })
  }

  protected static getShortPlace(place?: IPlaceResponse): string {
    return place ? `${place.name}, ${place.address.split(', ', 1)}` : ''
  }

  protected cancelTokenEvents?: CancelTokenSource

  public cancelEvents() {
    if (this.cancelTokenEvents) {
      this.cancelTokenEvents.cancel()
      this.cancelTokenEvents = undefined
    }
  }
}

export default EventsListService
