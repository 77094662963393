import React, { useEffect } from 'react'

import { Button, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './Modal.styles'
import { IModalProps } from './Modal.types'

export const BasicModal = ({ title, content, button, isOpen = true }: IModalProps) => {
  const styles = useStyles({})
  const { i18n } = useDictionary()
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Modal open={open}>
      <Box sx={styles.box}>
        <Grid container={true} direction={'column'} spacing={3}>
          <Grid item={true}>
            <Typography variant={'h6'} component={'h2'}>
              {title}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>{content}</Typography>
          </Grid>
          <Grid item={true}>
            {!!button ? (
              button
            ) : (
              <Button variant={'contained'} color={'primary'} onClick={handleClose}>
                {i18n.modals.ActionMessage.close}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
