import React from 'react'

import { Alert, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Route, routes } from 'router/routes'

import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { LogoutButton } from 'components/auth/LogoutButton'
import { DialogAppBar } from 'components/reusable/DialogAppBar'
import { navigate } from 'models/internalRouter/actions'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const Success = () => {
  const { i18n } = useDictionary()
  const dispatch = useDispatch()
  const { goingMetadata } = useUser()

  return (
    <Flex gap={3}>
      <DialogAppBar title={i18n.auth.hello + goingMetadata?.firstname} />
      <Alert sx={{ width: '100%' }} color={'success'} children={i18n.auth.successfulLogin} />
      <Button
        variant={'contained'}
        children={i18n.auth.goToProfile}
        onClick={() =>
          dispatch(
            navigate({
              to: routes[Route.account],
            })
          )
        }
        fullWidth
      />
      <LogoutButton />
    </Flex>
  )
}
