import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { makeSxStyles } from 'theme/makeSxStyles'

export const useSxStyles = (theme: Theme) =>
  makeSxStyles(() => ({
    container: {
      alignItems: 'center',
      background: theme.palette.background.paper,
      borderColor: theme.border.color,
      borderRadius: `${theme.border.radius}!important`,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
      padding: theme.spacing(4),
    },
    detailsButton: {
      borderRadius: 0,
      textTransform: 'capitalize',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    tooltip: {
      marginLeft: theme.spacing(2),
    },
  }))

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productImage: {
      justifyContent: 'flexStart',
      maxHeight: theme.spacing(6),
      maxWidth: theme.spacing(6),
      objectFit: 'contain',
    },
  })
)
