import { connect } from 'react-redux'

import _Store from '@Store'

import Testimonials from 'components/Bricks/components/Testimonials/Testimonials.component'
import { ITestimonialsFromState } from 'components/Bricks/components/Testimonials/Testimonials.types'
import { getCompositionIdentifier } from 'models/pages/selectors'

export default connect(
  (state: _Store.IState): ITestimonialsFromState => ({
    compositionIdentifier: getCompositionIdentifier(state),
  })
)(Testimonials)
