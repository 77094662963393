import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { redirectParentTo } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'

import BasketEmpty from './BasketEmpty.component'
import { IBasketEmptyFromDispatch, IBasketEmptyFromState } from './BasketEmpty.types'

const mapStateToProps = (state: _Store.IState): IBasketEmptyFromState => ({
  basketRedirect: getIframeParams(state)?.basketRedirect,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IBasketEmptyFromDispatch => ({
  redirectParentTo: (url: string) => dispatch(redirectParentTo(url)),
})

export default connect<IBasketEmptyFromState, IBasketEmptyFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(BasketEmpty)
