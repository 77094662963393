import * as React from 'react'

import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { IBannerLinkProps } from 'components/reusable/BannerLink/BannerLink.types'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

export const BannerLink = ({
  alt,
  className,
  id,
  image,
  mobileImage,
  to,
  useDropShadow,
  wrapperComponent,
}: IBannerLinkProps) => {
  const { isDesktop, isMobile, isTablet } = useBreakpoints()

  return (
    <Box
      {...(wrapperComponent ? { component: wrapperComponent } : {})}
      sx={{
        display: 'flex',
        gridColumn: `1 / span ${isDesktop ? 4 : isTablet ? 3 : 1}`,
        borderRadius: 4,
        overflow: 'hidden',
        transition: '200ms',
        '&:hover': {
          transform: `translateY(-${4}px)`,
          ...(useDropShadow
            ? { filter: 'drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1))' }
            : { boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.05)' }),
        },
      }}
      id={id}
      className={className}
    >
      <Box component={Link} to={to} sx={{ display: 'inherit' }}>
        <Box
          sx={{ objectFit: 'contain', width: '100%', height: '100%' }}
          component={'img'}
          alt={alt ?? ''}
          src={isMobile && !!mobileImage ? mobileImage : image}
        />
      </Box>
    </Box>
  )
}
