import React, { useContext } from 'react'

import { Typography } from '@mui/material'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import FormField from 'components/formik/FormField'
import config from 'config'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './AdditionalField.module.scss'
import { IAdditionalFieldProps } from './AdditionalField.types'

const AdditionalField = ({
  index,
  ticket,
  selectedTickets,
  step,
  setFieldValue,
  values,
}: IAdditionalFieldProps) => {
  const { i18n } = useDictionary()
  const state = useContext(clearContext)
  const basketItems = state?.basketItems || []
  const fillWithBuyerData = () => {
    const firstPoolItemWithAdditional =
      selectedTickets.length > 0
        ? selectedTickets.find((selectedTicket) => {
            return selectedTicket.additionalFields
          })
        : ''

    const firstBasketItemWithAdditional =
      basketItems.length > 0
        ? basketItems.find((selectedBasketItem) => {
            return selectedBasketItem.additionalFields
          })
        : ''

    const poolId = firstPoolItemWithAdditional
      ? firstPoolItemWithAdditional.poolId
      : firstBasketItemWithAdditional
      ? firstBasketItemWithAdditional.id
      : ''

    setFieldValue(`firstnameAdditional["id_${poolId}"].0`, values.firstname)
    setFieldValue(`lastnameAdditional["id_${poolId}"].0`, values.lastname)
    setFieldValue(`emailAdditional["id_${poolId}"].0`, values.email)
  }

  if (!values.firstnameAdditional[`id_${ticket.id}`]) {
    values.firstnameAdditional[`id_${ticket.id}`] = ['']
  }
  if (!values.lastnameAdditional[`id_${ticket.id}`]) {
    values.lastnameAdditional[`id_${ticket.id}`] = ['']
  }
  if (!values.emailAdditional[`id_${ticket.id}`]) {
    values.emailAdditional[`id_${ticket.id}`] = ['']
  }

  return (
    <div className={styles.additionalFieldsContainer}>
      <div className={styles.ticketContainer}>
        <p className={styles.ticketTitle}>{`Bilet ${step + 1}`}</p>
        <Typography variant={'subtitle2'} color={'secondary'}>
          {`${ticket.poolName} ${i18n.buy.agreements.additionalData}`}
        </Typography>
      </div>
      <div>
        <p className={styles.info}>
          {i18n.buy.clientData.namedTicket} <strong>{i18n.buy.clientData.realData}</strong>
          {i18n.buy.clientData.id}
        </p>
        <FormField
          id={`emailAdditional['id_${ticket.id}'].${index}`}
          name={`emailAdditional['id_${ticket.id}'].${index}`}
          placeholder={`${i18n.buy.clientData.eMail}*`}
          type="email"
          validate={(val) => {
            if (!val) {
              return i18n.errors.emailAddressIsRequiredValidator
            }
          }}
        />
        <FormField
          id={`firstnameAdditional['id_${ticket.id}'].${index}`}
          name={`firstnameAdditional['id_${ticket.id}'].${index}`}
          placeholder={`${i18n.buy.clientData.firstName}*`}
          type="text"
          validate={(val) => {
            if (!val) {
              return i18n.errors.firstNameIsRequiredValidator
            }
          }}
        />
        <FormField
          id={`lastnameAdditional['id_${ticket.id}'].${index}`}
          name={`lastnameAdditional['id_${ticket.id}'].${index}`}
          placeholder={`${i18n.buy.clientData.lastName}*`}
          type="text"
          validate={(val) => {
            if (!val) {
              return i18n.errors.lastNameIsRequiredValidator
            }
          }}
        />
      </div>
      {config.app.onlineSale && step === 0 && (
        <div className={styles.fillWithBuyerDataButton} onClick={() => fillWithBuyerData()}>
          {i18n.buy.agreements.fillInData}
        </div>
      )}
    </div>
  )
}

export default AdditionalField
