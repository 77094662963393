import { getNotification } from 'state/notification/selectors/getNotification'

export const enum RecoilKeys {
  app = 'app',
  categoryFilter = 'categoryFilter',
  dateFilter = 'dateFilter',
  getApp = 'getApp',
  getFilters = 'getFilters',
  getLang = 'getLang',
  getNotification = 'getNotification',
  getSearchUrl = 'getSearchUrl',
  getSorting = 'getSorting',
  locale = 'locale',
  locationFilter = 'locationFilter',
  notificationQueue = 'notificationQueue',
  searchPage = 'searchPage',
  searchPhrase = 'searchPhrase',
  setApp = 'setApp',
  setCategoryFilter = 'setCategoryFilter',
  setDateFilter = 'setDateFilter',
  setLang = 'setLang',
  setLocationFilter = 'setLocationFilter',
  setSearchPhrase = 'setSearchPhrase',
  setSorting = 'setSorting',
  setTagsFilter = 'setTagsFilter',
  setTypeFilter = 'setTypeFilter',
  setVariousFilters = 'setVariousFilters',
  setSourceIdsFilter = 'setSourceIdsFilter',
  sortField = 'sortField',
  tagsFilter = 'tagsFilter',
  translate = 'translate',
  typeFilter = 'typeFilter',
  userEmail = 'userEmail',
  variousFilters = 'variousFilters',
  sourceIdsFilter = 'sourceIdsFilter',
}
