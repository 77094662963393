import React, { useEffect, useState } from 'react'

import { DateCalendar } from '@mui/x-date-pickers'
import cn from 'classnames'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/pl'

import { Languages } from 'constants/Languages'
import { useAppState } from 'state/app/hooks/useAppState'
import { useLang } from 'state/locale/hooks/useLang'

import styles from './Calendar.module.scss'
import { ICalendarProps } from './Calendar.types'

const Calendar = ({
  happeningStartDate,
  isDateSelectionUnAvailable,
  setDay,
  generateIdempotencyKey,
}: ICalendarProps) => {
  const { isDarkTheme } = useAppState()
  const [date, setDate] = useState(happeningStartDate)
  const { code } = useLang()

  moment.locale(code === Languages.Polski ? 'pl' : 'en')

  const changeDate = (value: any | null) => {
    if (value) {
      setDate(value)
      setDay(value)
      generateIdempotencyKey()
    }
  }

  useEffect(() => {
    if (date) changeDate(date)
  }, [happeningStartDate])

  return (
    <div
      className={cn(
        styles.datePicker,
        isDateSelectionUnAvailable && styles.disabled,
        isDarkTheme && 'dark'
      )}
    >
      <DateCalendar
        value={date}
        onChange={changeDate}
        disablePast={true}
        sx={{ height: 'unset', maxWidth: 'unset' }}
      />
    </div>
  )
}

export default Calendar
