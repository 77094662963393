import React from 'react'

import { Settings } from 'components/Settings'
import { PageWrapper } from 'components/_layout/PageWrapper'

export const SettingsPage = () => {
  return (
    <PageWrapper gap={3}>
      <Settings />
    </PageWrapper>
  )
}
