import React, { FC, ReactElement } from 'react'

import { Tooltip } from '@mui/material'

import { IToolTipProps } from './ToolTip.types'

const ToolTip: FC<IToolTipProps> = ({ bottom, children, left, title, style, className }) => {
  return title ? (
    <Tooltip
      title={title}
      children={children as ReactElement}
      enterTouchDelay={1}
      style={style}
      className={className}
    />
  ) : null
}

export default ToolTip
