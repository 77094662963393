export const REDIRECT = 'Empik/premium/REDIRECT'
export const SET_TOKEN = 'Empik/premium/SET_TOKEN'
export const CHECK_CARD_STATUS = 'Empik/premium/CHECK_CARD_STATUS'

export const _GET_REQUEST = 'Empik/premium/_GET_REQUEST'
export const _GET_SUCCESS = 'Empik/premium/_GET_SUCCESS'
export const _GET_FAILURE = 'Empik/premium/_GET_FAILURE'

export const _GET_STATUS_REQUEST = 'Empik/premium/_GET_STATUS_REQUEST'
export const _GET_STATUS_SUCCESS = 'Empik/premium/_GET_STATUS_SUCCESS'
export const _GET_STATUS_FAILURE = 'Empik/premium/_GET_STATUS_FAILURE'
