import { connect } from 'react-redux'

import _Store from '@Store'

import { getCustomTerms } from 'models/event/selectors'
import { getHappeningTerms } from 'models/happening/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import { getSelectedTickets } from 'models/pools/selectors'
import { getTheme } from 'models/theme/selectors'

import Agreements from './Agreements.component'
import { IAgreementsFromState, IAgreementsOwnProps } from './Agreements.types'

const mapStateToProps = (state: _Store.IState): IAgreementsFromState => ({
  customTerms: [...getCustomTerms(state), ...getHappeningTerms(state)],
  isEmbed: !!getIframeParams(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
})

export default connect<IAgreementsFromState, void, IAgreementsOwnProps, _Store.IState>(
  mapStateToProps
)(Agreements)
