import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basketPools: {
      paddingLeft: `${theme.spacing(1)}px!important`,
      paddingRight: `${theme.spacing(1)}px!important`,
    },
    tooltip: {
      marginLeft: theme.spacing(1),
      width: '0.75rem',
    },
    fontMedium: {
      fontWeight: 500,
    },
    typographyWithTooltip: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      backgroundColor: theme.palette.other.divider,
    },
    lastDivider: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)

export default useStyles
