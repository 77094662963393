export const GET_TICKET_DATA = 'Empik/tickets/GET_TICKET_DATA'
export const GET_TRANSACTION_DATA = 'Empik/tickets/GET_TRANSACTION_DATA'
export const RECEIVE_TICKET_MOUNTED = 'Empik/tickets/RECEIVE_TICKET_MOUNTED'
export const RECEIVE_TICKET_SUBMIT = 'Empik/tickets/RECEIVE_TICKET_SUBMIT'
export const RESET = 'Empik/tickets/RESET'
export const SET_SHOW_SENDER = 'Empik/tickets/SET_SHOW_SENDER'

// Private
export const _GET_TICKETS_REQUEST = 'Empik/tickets/_GET_TICKETS_REQUEST'
export const _GET_TICKETS_SUCCESS = 'Empik/tickets/_GET_TICKETS_SUCCESS'
export const _GET_TICKETS_FAILURE = 'Empik/tickets/_GET_TICKETS_FAILURE'

export const _GET_EVENT_REQUEST = 'Empik/tickets/_GET_EVENT_REQUEST'
export const _GET_EVENT_SUCCESS = 'Empik/tickets/_GET_EVENT_SUCCESS'
export const _GET_EVENT_FAILURE = 'Empik/tickets/_GET_EVENT_FAILURE'

export const _GET_RECEIVE_REQUEST = 'Empik/tickets/_GET_RECEIVE_REQUEST'
export const _GET_RECEIVE_SUCCESS = 'Empik/tickets/_GET_RECEIVE_SUCCESS'
export const _GET_RECEIVE_FAILURE = 'Empik/tickets/_GET_RECEIVE_FAILURE'

export const _GET_RECEIVE_PAYMENT_REQUEST = 'Empik/tickets/_GET_RECEIVE_PAYMENT_REQUEST'
export const _GET_RECEIVE_PAYMENT_SUCCESS = 'Empik/tickets/_GET_RECEIVE_PAYMENT_SUCCESS'
export const _GET_RECEIVE_PAYMENT_FAILURE = 'Empik/tickets/_GET_RECEIVE_PAYMENT_FAILURE'

export const _GET_TRANSFER_TICKET_FEE_REQUEST = 'Empik/tickets/_GET_TRANSFER_TICKET_FEE_REQUEST'
export const _GET_TRANSFER_TICKET_FEE_SUCCESS = 'Empik/tickets/_GET_TRANSFER_TICKET_FEE_SUCCESS'
export const _GET_TRANSFER_TICKET_FEE_FAILURE = 'Empik/tickets/_GET_TRANSFER_TICKET_FEE_FAILURE'

export const _GET_TRANSACTION_DATA_REQUEST = 'Empik/tickets/_GET_TRANSACTION_DATA_REQUEST'
export const _GET_TRANSACTION_DATA_SUCCESS = 'Empik/tickets/_GET_TRANSACTION_DATA_SUCCESS'
export const _GET_TRANSACTION_DATA_FAILURE = 'Empik/tickets/_GET_TRANSACTION_DATA_TICKET_FAILURE'

export const _SET_INVALID_SELECTION = 'Empik/tickets/_SET_INVALID_SELECTION'
export const _SET_VALID_SELECTION = 'Empik/tickets/_SET_VALID_SELECTION'

export const DOWNLOAD_TICKET_FROM_TICKET_DATA = 'Empik/tickets/DOWNLOAD_TICKET_FROM_TICKET_DATA'

export const _DOWNLOAD_TICKET_FROM_TICKET_DATA_REQUEST =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TICKET_DATA_REQUEST'
export const _DOWNLOAD_TICKET_FROM_TICKET_DATA_SUCCESS =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TICKET_DATA_SUCCESS'
export const _DOWNLOAD_TICKET_FROM_TICKET_DATA_FAILURE =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TICKET_DATA_FAILURE'

export const DOWNLOAD_TICKET_FROM_TRANSACTION_DATA =
  'Empik/tickets/DOWNLOAD_TICKET_FROM_TRANSACTION_DATA'

export const _DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_REQUEST =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_REQUEST'
export const _DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_SUCCESS =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_SUCCESS'
export const _DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_FAILURE =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_FAILURE'
