import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { IDateFilter } from 'types/Search'

export const initialState: IDateFilter = {
  from: null,
  to: null,
}

export const dateFilterState = atom<IDateFilter>({
  default: initialState,
  key: RecoilKeys.dateFilter,
})
