import { HitFields } from 'constants/CloudSearch'
import { CloudinaryPrefixes } from 'constants/Cloudinary'
import { fetchCloudinaryList } from 'services/Api/Cloudinary'
import { Fetcher } from 'services/Api/Fetcher'
import { LuceneQueryBuilder } from 'services/Api/LuceneQueryBuilder'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { IArtistResponse } from 'types/Artist'
import { ICloudSearchHit, ICloudSearchResponse } from 'types/CloudSearch'

export class ArtistApi {
  public static getFullArtist = async (slug: string) => {
    try {
      const artistData = await Fetcher.get<IArtistResponse>(URI.GetArtist(slug))
      const cloudinaryImages = await fetchCloudinaryList({
        slug,
        prefix: CloudinaryPrefixes.ARTIST,
      })
      const artistEvents = await ArtistApi.getArtistEvents(slug)
      const lowestPrice = await ArtistApi.getLowestTicketPrice(slug)

      return { artistData, cloudinaryImages, artistEvents, lowestPrice }
    } catch (e) {
      Logger.error('ArtistApi.getFullArtist', e)
    }
  }

  private static getArtistEvents = async (slug: string): Promise<ICloudSearchHit[]> => {
    try {
      const url = LuceneQueryBuilder.buildCloudSearchUrl({
        and: [
          {
            [HitFields.artists_slugs]: slug,
          },
        ],
        sorting: [{ priority: 1, field: HitFields.start_date, sort: 'asc' }],
      })

      const artistEvents = await Fetcher.get<ICloudSearchResponse>(url)

      return artistEvents.hits.hit
    } catch (e) {
      Logger.error('ArtistApi.getArtistEvents', e)

      return []
    }
  }

  private static getLowestTicketPrice = async (slug: string): Promise<string | null> => {
    try {
      const url = LuceneQueryBuilder.buildCloudSearchUrl({
        and: [
          {
            [HitFields.artists_slugs]: slug,
          },
        ],
        sorting: [{ priority: 1, field: HitFields.price, sort: 'asc' }],
        size: 1,
      })

      const cheapestEvent = await Fetcher.get<ICloudSearchResponse>(url)

      return cheapestEvent.hits?.hit[0]?.fields.price || null
    } catch (e) {
      Logger.error('ArtistApi.getLowestTicketPrice', e)

      return null
    }
  }
}
