import * as React from 'react'

import { Cache, SWRConfig } from 'swr'

import { StorageKeys } from 'constants/StorageKeys'
import { Fetcher } from 'services/Api/Fetcher'
import { LocalStorage } from 'services/LocalStorage'

class LocalStorageCache extends Map<string, any> implements Cache {
  constructor() {
    super(LocalStorage.get(StorageKeys.SWRCache) ?? [])
    window.addEventListener('beforeunload', () => {
      LocalStorage.set(StorageKeys.SWRCache, Array.from(this.entries()))
    })
  }
}

const localStorageProvider = (): LocalStorageCache => new LocalStorageCache()

// TODO: this needs more testing and privacy check, disabled for now.
const enableLocalStorageCache = false

export const SWRConfigProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <SWRConfig
    value={{
      fetcher: Fetcher.get,
      keepPreviousData: true,
      provider:
        enableLocalStorageCache && typeof window !== 'undefined'
          ? localStorageProvider
          : () => new Map(),
      revalidateOnFocus: false,
    }}
  >
    {children}
  </SWRConfig>
)
