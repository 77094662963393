import React from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Dialog, IconButton, InputAdornment } from '@mui/material'
import { EmailAuthProvider, deleteUser, reauthenticateWithCredential } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { useNotify } from 'components/_functional/NotificationProvider'
import { useUser } from 'components/_functional/UserContextProvider'
import { useToggle } from 'misc/hooks/useToggle'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IRemoveAccountFormInput } from './RemoveAccount.types'

export const RemoveAccount = () => {
  const { i18n } = useDictionary()
  const { firebaseUser } = useUser()
  const { notify } = useNotify()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRemoveAccountFormInput>()
  const { isVisible, handleShow, handleHide } = useToggle()
  const { isVisible: isVisiblePassword, handleToggle: handleTogglePassword } = useToggle()
  const navigate = useNavigate()
  const isPasswordUser = firebaseUser?.providerData[0]?.providerId === 'password'

  const onSubmit = async ({ password }: IRemoveAccountFormInput) => {
    if (!firebaseUser?.email) {
      notify({ text: i18n.notifications.mustLogin, alertColor: 'error' })
      return
    }

    if (isPasswordUser) {
      const credential = EmailAuthProvider.credential(firebaseUser.email, password)
      try {
        await reauthenticateWithCredential(firebaseUser, credential)
      } catch (error: any) {
        notify({ text: error.message, alertColor: 'error' })
        return
      }
    }

    try {
      await deleteUser(firebaseUser)
      handleHide()
      notify({ text: i18n.auth.accountRemoved, alertColor: 'success' })
      navigate(routes[Route.index])
    } catch (error: any) {
      notify({ text: error.message, alertColor: 'error' })
    }
  }

  return (
    <>
      <Button
        onClick={handleShow}
        children={i18n.auth.removeAccount}
        variant={'outlined'}
        color={'error'}
        fullWidth
      />
      <Dialog open={isVisible} onClose={handleHide} fullWidth maxWidth={'xs'}>
        <Form.Wrapper onSubmit={handleSubmit(onSubmit)}>
          {isPasswordUser && (
            <TextInput
              name={FormFields.PASSWORD}
              label={i18n.forms.labels[FormFields.PASSWORD]}
              control={control}
              rules={validators.requiredString(i18n.forms.validation[FormFields.PASSWORD].required)}
              type={isVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'start'}>
                    <IconButton
                      onClick={handleTogglePassword}
                      children={isVisiblePassword ? <VisibilityOff /> : <Visibility />}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Button
            type={'submit'}
            color={'error'}
            variant={'contained'}
            children={i18n.auth.removeAccount}
            fullWidth
          />
        </Form.Wrapper>
        <Button onClick={handleHide} children={i18n.buttons.cancel} fullWidth />
      </Dialog>
    </>
  )
}
