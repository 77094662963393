import { IPaymentMethod } from 'types/Pools'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import {
  IGetPoolsPayload,
  ISelectPoolPayload,
  ISelectSeatPayload,
  ISelectTicketPayload,
  IUpdateSelectedSeatsPayload,
  IUpdateSelectedTicketsPayload,
} from './../types'

// Public actions
export const poolsMounted = createStandardAction(CONSTS.MOUNTED)()
export const selectPool = createStandardAction(CONSTS.SELECT_POOL)<ISelectPoolPayload>()
export const selectTicket = createStandardAction(CONSTS.SELECT_TICKET)<ISelectTicketPayload>()
export const selectSeat = createStandardAction(CONSTS.SELECT_SEAT)<ISelectSeatPayload>()
export const deselectSeat = createStandardAction(CONSTS.DESELECT_SEAT)<ISelectSeatPayload>()
export const resetTicketsState = createStandardAction(CONSTS.RESET_TICKETS_STATE)()
export const setFinalPrice = createStandardAction(CONSTS.SET_FINAL_PRICE)<number>()
export const setPaymentMethods = createStandardAction(CONSTS.SET_PAYMENT_METHODS)<
  IPaymentMethod[]
>()

// Private actions
export const getPools = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<{ eventSlug: string; rundateSlug: string } | undefined, IGetPoolsPayload, Error>()

export const resetState = createStandardAction(CONSTS._RESET_STATE)()
export const updateSelectedTickets = createStandardAction(
  CONSTS._UPDATE_SELECTED_TICKETS
)<IUpdateSelectedTicketsPayload>()
export const updateSelectedSeats = createStandardAction(
  CONSTS._UPDATE_SELECTED_SEATS
)<IUpdateSelectedSeatsPayload>()
