import React from 'react'

import { Box } from '@mui/material'

import { IPoolsProps } from 'components/Rundate/components/Pools/Pools.types'

import Pool from './Pool'

export const Pools = ({
  data: { pools, isAvailable },
  rundateSlug,
  eventSlug,
  rundateID,
}: IPoolsProps) => {
  if (!pools.length || !isAvailable) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      {pools.map((pool) => (
        <Pool
          key={pool.id}
          pool={pool}
          eventSlug={eventSlug}
          rundateSlug={rundateSlug}
          rundateID={rundateID}
        />
      ))}
    </Box>
  )
}
