import * as React from 'react'

import { Box } from '@mui/material'

import { IFlexProps } from 'components/_layout/Flex/Flex.types'

export const Flex = ({
  children,
  component,
  sx,
  gap,
  justify = 'flex-start',
  wrap,
  direction = 'column',
  align = 'flex-start',
  fullWidth,
  flex,
}: React.PropsWithChildren<IFlexProps>) => {
  return (
    <Box
      {...(component ? { component } : {})}
      display={'flex'}
      justifyContent={justify}
      flexDirection={direction}
      alignItems={align}
      flexWrap={wrap}
      gap={gap}
      sx={{ width: fullWidth ? '100%' : 'auto', ...sx }}
      children={children}
      flex={flex}
    />
  )
}
