import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      width: '100%',
      '& .MuiListItemIcon-root': {
        color: theme.palette.text.primary,
      },

      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  })
)

export default useStyles
