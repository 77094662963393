import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { categoryFilterState } from '../atoms/categoryFilter'
import { searchPageIndexState } from '../atoms/searchPageIndex'

/**
 * Category filter setter selector. The "set" method resets search page to initial state,
 * each time new value is set.
 */
export const setCategoryFilter = selector({
  get: ({ get }) => get(categoryFilterState),
  set: ({ get, set, reset }, newValue) => {
    set(categoryFilterState, newValue)
    reset(searchPageIndexState)
  },
  key: RecoilKeys.setCategoryFilter,
})
