import React, { useContext, useEffect } from 'react'

import { Button, Grid, Tooltip, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import qs from 'qs'
import { Link, useLocation } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import styles from 'components/Happening/Happening.module.scss'
import { HappeningFormLayoutProps } from 'components/Happening/components/HappeningFormLayout/HappeningFormLayout.types'
import HappeningSummary from 'components/Happening/components/HappeningSummary/HappeningSummary.container'
import Agreements from 'components/buying/components/Agreements/Agreements.container'
import AuthId from 'components/buying/components/AuthId'
import CardPaymentForm from 'components/buying/components/CardPaymentForm'
import ClientData from 'components/buying/components/ClientData/ClientData.container'
import classes from 'components/buying/components/FormLayout/FormLayout.classes'
import PaymentMethod from 'components/buying/components/PaymentMethod'
import ToggleField from 'components/buying/components/ToggledField'
import config from 'config'
import autocompleteForm from 'misc/helpers/autocompleteForm'
import isEmptyObject from 'misc/helpers/isEmptyObject'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import useUpdateEffect from 'misc/hooks/useUpdateEffect'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './HappeningFormLayout.styles'

const HappeningFormLayout = ({
  canReserve,
  customTerms,
  errors,
  handleSubmit,
  setFieldValue,
  values,
  step,
  setDiscountState,
  calculatePrice,
  upsellFromState,
  selectedTheme,
  isTransactionLoading,
  isError,
  currentBasketData,
  addToBasketRedirect,
  redirectParentTo,
  happening,
  setDay,
  isEmbed,
  clearSelectedHappening,
}: HappeningFormLayoutProps) => {
  const { i18n } = useDictionary()
  const { search } = useLocation()
  const autoFill = !!qs.parse(search, { ignoreQueryPrefix: true }).data
  const autocomplete = () => autocompleteForm(autoFill, customTerms, setFieldValue)
  const calculateWithDiscount = (discountCode: string) => {
    setDiscountState(discountCode)
    calculatePrice({
      discountCode,
      upsell: upsellFromState,
    })
  }

  // Functionality only for the Strefa MasterCard Music
  const regex = /^MC.*R$/
  const isValidCode = regex.test(values.discount || '')

  const BagState = useContext(clearContext)

  const hasHappeningEvent =
    !!BagState?.basketItems.length && BagState.basketItems.some((item) => !item.isPlaceEvent)

  const hasDifferentPartner =
    !!(BagState?.basketItems.length && happening) &&
    BagState.basketItems.some((item) => item.partnerId !== happening.partnerId)

  const handleAddToBasket = () => {
    if (BagState && currentBasketData) {
      currentBasketData.forEach((basketItem) => {
        BagState.addToBag({
          ...basketItem,
        })
      })
      BagState.handleUserData(values)
    }
    if (addToBasketRedirect) {
      redirectParentTo(addToBasketRedirect)
    }
    clearSelectedHappening()
    setDay(new Date())
  }

  const { isMobile } = useBreakpoints()
  let formikSteps = step

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  useUpdateEffect(() => {
    if (!values.discountCheckbox && values.discount) {
      setFieldValue('discount', '')
      calculateWithDiscount('')
    }
  }, [values.discountCheckbox])

  useEffect(() => {
    BagState?.readLocalStorage()
  }, [])

  const isDefaultTheme = selectedTheme === 'default'
  const shouldShowBasketButton = isEmbed && addToBasketRedirect

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          {config.app.onlineSale && (
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12}>
                <Typography variant={'h4'}>
                  {formikSteps++}. {i18n.buy.clientData.provideData}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <div className={cn(styles.box, !isDefaultTheme && muiStyles.withoutPadding)}>
                  <ClientData
                    autocompleteForm={autocomplete}
                    isDisabled={!canReserve}
                    number={0}
                    slotsSale={true}
                    setFieldValue={setFieldValue}
                    showInvoiceForm={values.invoiceCheckbox}
                    showPassForm={values.passCheckbox}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          {!config.app.onlineSale && (
            <>
              <Typography variant={'h4'}>
                {formikSteps++}. {i18n.buy.happening.enterDiscountCode}
              </Typography>
              <div
                className={cn(
                  styles.box,
                  muiStyles.root,
                  !isDefaultTheme && muiStyles.withoutPadding
                )}
              >
                <ToggleField
                  checkboxId={'discountCheckbox'}
                  inputId={'discount'}
                  isDisabled={!canReserve}
                  open={values.discountCheckbox}
                  placeholder={i18n.buy.clientData.discountCode}
                  text={
                    config.theme.isGoing
                      ? i18n.buy.clientData.iHaveDiscountGoing
                      : i18n.buy.clientData.iHaveDiscount
                  }
                />
                {values.discountCheckbox && (
                  <Button
                    variant={'contained'}
                    size={'large'}
                    color={'primary'}
                    disableElevation={true}
                    fullWidth={true}
                    children={i18n.buy.happening.checkDiscount}
                    onClick={() => calculateWithDiscount(values.discount)}
                  />
                )}
              </div>
              <AuthId isDisabled={!canReserve} number={formikSteps++} />
            </>
          )}
        </Grid>

        <Grid item={true} xs={12}>
          {config.app.onlineSale && (
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12}>
                <Typography variant={'h4'}>
                  {formikSteps++}. {i18n.buy.agreements.acceptAgreements}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <Agreements
                  slotsSale={true}
                  isDisabled={!canReserve}
                  number={0}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item={true} xs={12}>
          {!!(config.app.onlineSale && !isValidCode) && (
            <PaymentMethod
              isDisabled={!canReserve}
              step={formikSteps++}
              cardPaymentForm={<CardPaymentForm />}
            />
          )}
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={2}>
            {shouldShowBasketButton && (
              <Grid item={true} xs={12} md={6}>
                <Tooltip
                  title={
                    hasDifferentPartner
                      ? i18n.buy.summary.differentPartner
                      : i18n.buy.summary.differentType
                  }
                  disableFocusListener={!hasHappeningEvent && !hasDifferentPartner}
                  disableHoverListener={!hasHappeningEvent && !hasDifferentPartner}
                  disableTouchListener={!hasHappeningEvent && !hasDifferentPartner}
                >
                  <span>
                    <Link
                      to={
                        !canReserve || hasHappeningEvent || hasDifferentPartner
                          ? '#'
                          : routes[Route.cart]
                      }
                    >
                      <Button
                        variant={'outlined'}
                        size={'large'}
                        color={'primary'}
                        disableElevation={true}
                        disabled={!canReserve || hasHappeningEvent || hasDifferentPartner}
                        fullWidth={true}
                        onClick={() => handleAddToBasket()}
                      >
                        {i18n.buy.happening.buttonAdd}
                      </Button>
                    </Link>
                  </span>
                </Tooltip>
              </Grid>
            )}

            <Grid item={true} xs={12} md={shouldShowBasketButton ? 6 : 12}>
              {isMobile && (
                <div className={cn(styles.mobileSummary, classes.mobileSummary)}>
                  <h3 className={cn(styles.header, classes.header)}>
                    {i18n.buy.formLayout.summary}
                  </h3>
                  <HappeningSummary />
                </div>
              )}
              <Tooltip
                title={
                  hasDifferentPartner
                    ? i18n.buy.summary.differentPartner
                    : i18n.buy.summary.differentType
                }
                disableFocusListener={!hasHappeningEvent && !hasDifferentPartner}
                disableHoverListener={!hasHappeningEvent && !hasDifferentPartner}
                disableTouchListener={!hasHappeningEvent && !hasDifferentPartner}
              >
                <span>
                  <Button
                    variant={'contained'}
                    size={'large'}
                    color={'primary'}
                    type={'submit'}
                    disableElevation={true}
                    disabled={
                      !canReserve ||
                      isTransactionLoading ||
                      isError ||
                      hasHappeningEvent ||
                      hasDifferentPartner
                    }
                    fullWidth={true}
                  >
                    {i18n.buy.happening.book}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item={true} xs={12}>
              {!isEmptyObject(errors) && (
                <div className={styles.errorContainer}>{i18n.buy.summary.formInvalid}</div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default HappeningFormLayout
