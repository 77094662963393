import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { shipmentDeliveryMounted } from 'models/shipment/actions'
import { get as getShipments } from 'models/shipment/selectors'
import { getTheme } from 'models/theme/selectors'

import PickUpWay from './PickUpWay.component'
import { IPickUpFromDispatch, IPickUpWayFromState, IPickUpWayOwnProps } from './PickUpWay.types'

const mapStateToProps = (state: _Store.IState): IPickUpWayFromState => ({
  selectedTheme: getTheme(state),
  shipments: getShipments(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPickUpFromDispatch => ({
  shipmentDeliveryMounted: (countryCode) => dispatch(shipmentDeliveryMounted(countryCode)),
})

export default connect<IPickUpWayFromState, IPickUpFromDispatch, IPickUpWayOwnProps, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(PickUpWay)
