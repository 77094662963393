import React, { FC, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import * as CONSTS from './../../Gallery.constants'
import DesktopViewer from './../DesktopViewer'
import MobileViewer from './../MobileViewer'
import styles from './ViewerModal.module.scss'
import { IViewerModalProps } from './ViewerModal.types'

const NUMBERS_BASE = 10
const HIDDEN = 'hidden'
const SCROLL = 'scroll'
const MODAL_ID = 'modal'

const ViewerModal: FC<IViewerModalProps> = ({ anchor, images }: IViewerModalProps) => {
  const { isMobile } = useBreakpoints()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!document.getElementById(MODAL_ID)) {
      document.body.style.overflowY = SCROLL
    }
  }, [])

  const picture = parseInt(location.hash.split(CONSTS.GALLERY_SEPARATOR)[1], NUMBERS_BASE)

  const showModal = location.hash.startsWith(`#${anchor}`) && picture < images.length

  const closeModal = () => {
    navigate(-1)
    document.body.style.overflowY = SCROLL
  }

  const changeImage = (newImage: number) => {
    navigate(`#${anchor}${newImage}`, { replace: true })
  }

  if (!showModal) {
    return null
  }

  document.body.style.overflowY = HIDDEN

  return (
    <div className={styles.wrapper} id={MODAL_ID}>
      <i className={styles.close} onClick={closeModal}>
        ✖︎
      </i>
      <div className={styles.viewer}>
        {isMobile ? (
          <MobileViewer galleryAnchor={anchor} images={images} locationHash={location.hash} />
        ) : (
          <DesktopViewer changeImage={changeImage} chosenImage={picture} images={images} />
        )}
      </div>
    </div>
  )
}

export default ViewerModal
