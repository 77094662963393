import * as React from 'react'

import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { Box, Button, Card, Chip, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { SectionContainer } from 'components/_layout/SectionContainer'
import DeepLinkModal from 'components/reusable/DeepLinkModal'
import Description from 'components/reusable/Description'
import ImageFixed from 'components/reusable/ImageFixed'
import { Tags } from 'components/reusable/Tags'
import Title from 'components/reusable/Title'
import config from 'config'
import isHTML from 'misc/helpers/isHTML'
import removeHTMLTags from 'misc/helpers/removeHTMLTags'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { DateTime } from 'services/DateTime'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { FavoritesTypes } from '../../constants/Favorites'
import { useFavoritesContext } from '../favorites/FavoritesProvider'
import { useEditFavorites } from '../favorites/FavoritesProvider/useEditFavorites'
import styles from './Selection.module.scss'
import useStyles from './Selection.styles'
import { ISelectionProps } from './Selection.types'

const EVENTS_SECTION_TEXT = 'Wydarzenia'

export const Selection = ({ data }: ISelectionProps) => {
  const { i18n } = useDictionary()
  const { isWebview } = useAppState()
  const { isDesktop, isMobile, isTablet } = useBreakpoints()
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isSelectionInFavs = isItemInFavs(data.id, FavoritesTypes.Selection)

  return (
    <>
      <div className={styles.selectionWrapper}>
        <div className={styles.imageWrapper}>
          <ImageFixed
            src={isMobile ? data.mobileImage : data.thumbUrl}
            transformation={{
              height: isMobile ? 810 : 660,
              width: isMobile ? 810 : 1260,
            }}
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.selectionHeader}>
            <Title
              title={data.title}
              customClassName={cn(styles.titleWrapper, muiStyles.blackFontColor)}
            />
          </div>
          <div className={styles.selectionBody}>
            <Description text={data.description} hideApostrophe={config.theme.isGoing} />
            <Button
              sx={{ mt: 3 }}
              fullWidth={true}
              variant={'outlined'}
              children={
                isSelectionInFavs ? i18n.following.unfollow : i18n.following.selectionHeader
              }
              onClick={handleEditFavorites({
                isFollowed: isSelectionInFavs,
                type: FavoritesTypes.Selection,
                externalId: data.id,
                itemName: data.title,
              })}
              startIcon={isSelectionInFavs ? <Favorite /> : <FavoriteBorder />}
            />
          </div>
        </div>
        <SectionContainer title={EVENTS_SECTION_TEXT}>
          <Box component={'ul'} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {data.elements?.map((element) => (
              <Card
                key={element.slug}
                component={'li'}
                sx={{
                  display: 'flex',
                  flexFlow: isDesktop ? 'row' : 'column',
                  flexWrap: 'nowrap',
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ flex: 3, borderRadius: 4, overflow: 'hidden' }}>
                  <ImageFixed
                    transformation={isDesktop ? { height: 300, width: 300 } : undefined}
                    src={
                      element.externalImages.length ? element.externalImages[0] : element.thumbUrl
                    }
                    to={prepareRoute({
                      route: Route.rundate,
                      params: {
                        eventSlug: element.slug.split('/')[0],
                        rundateSlug: element.slug.split('/')[1],
                      },
                    })}
                  />
                </Box>
                <Box sx={{ flex: 4, p: 4, display: 'flex', flexFlow: 'column', gap: 2 }}>
                  <Tags tags={[{ ...element.category, id: 0 }]} size={'small'} />
                  <Typography
                    component={'h2'}
                    variant={isDesktop ? 'h4' : 'h6'}
                    children={element.title}
                  />
                  <Typography
                    variant={'subtitle2'}
                    color={'text.secondary'}
                    children={`${DateTime.formatRundateDates({
                      startDate: element.date,
                      endDate: element.endDate,
                      dateTranslate: i18n.dates,
                    })}, ${DateTime.getFriendlyTime(element.date)}`}
                  />
                  {!!element.description && (
                    <Typography
                      variant={'caption'}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                      }}
                      children={
                        isHTML(element.description)
                          ? removeHTMLTags(element.description)
                          : element.description
                      }
                    />
                  )}
                  {element.changeMonitorName && (
                    <Chip color={'primary'} title={element.changeMonitorName} />
                  )}
                  <Button
                    sx={{
                      mt: isDesktop ? 'auto' : 4,
                      alignSelf: isDesktop ? 'flex-start' : 'unset',
                    }}
                    component={Link}
                    children={element.buttonLabel || i18n.event.buyTicket}
                    variant={'contained'}
                    to={prepareRoute({
                      route: Route.rundate,
                      params: {
                        eventSlug: element.slug.split('/')[0],
                        rundateSlug: element.slug.split('/')[1],
                      },
                    })}
                    size={'large'}
                  />
                </Box>
              </Card>
            ))}
          </Box>
        </SectionContainer>
      </div>
      {(isMobile || isTablet) && config.theme.isGoing && !isWebview && (
        <DeepLinkModal
          url={prepareRoute({
            route: Route.selection,
            params: { slug: data.slug },
          })}
        />
      )}
    </>
  )
}
