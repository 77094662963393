import { createSelector } from 'reselect'

import _Store from '@Store'

import { ILandingPage, ILandingPageReducerCurrentKey, ILandingPageReducerData } from '../types'
import getCurrentKey from './getCurrentKey'
import getData from './getData'

const getLandingPage = createSelector<
  _Store.IState,
  ILandingPageReducerCurrentKey,
  ILandingPageReducerData,
  ILandingPage | null
>([getCurrentKey, getData], (key, data) => {
  if (key && key in data) {
    return data[key] as ILandingPage
  }

  return null
})

export default getLandingPage
