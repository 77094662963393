import React from 'react'

import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import classes from '../../../../Success.classes'
import useStyles from './SingleSegment.styles'
import { ISingleSegmentProps } from './SingleSegment.types'

const SingleSegment = ({ transactionItems }: ISingleSegmentProps) => {
  const { i18n } = useDictionary()
  const theme = useTheme()
  const muiStyles = useStyles(theme)

  const { isMobile } = useBreakpoints()

  return (
    <Grid container={true}>
      <Grid item={true} className={cn(muiStyles.iconWrapper, classes.singleSegmentIcon)}>
        <ConfirmationNumberOutlinedIcon className={muiStyles.icon} />
      </Grid>

      <Grid item={true} xs={true} className={classes.detailsSubContainer}>
        <Grid container={true} direction={'column'}>
          <Grid item={true} className={cn(muiStyles.headerContainer, classes.detailsHeader)}>
            <Typography variant={isMobile ? 'body1' : 'h5'} align={'left'} color={'textPrimary'}>
              {transactionItems.productName}
            </Typography>
          </Grid>

          <Grid item={true} xs={12} className={classes.infoDetails}>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true} className={classes.placeText}>
                    <Typography
                      variant={'body2'}
                      align={'left'}
                      className={muiStyles.secondTypography}
                    >
                      {i18n.payment.paymentSuccess.place}
                    </Typography>
                  </Grid>

                  <Grid item={true} className={classes.place}>
                    <Typography
                      variant={'body2'}
                      align={'right'}
                      className={muiStyles.secondTypography}
                    >
                      {transactionItems.place?.placeName}, {transactionItems.place?.city}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true} className={classes.ticketsText}>
                    <Typography
                      variant={'body2'}
                      align={'left'}
                      className={muiStyles.secondTypography}
                    >
                      {i18n.payment.paymentSuccess.tickets}
                    </Typography>
                  </Grid>

                  <Grid item={true} className={classes.tickets}>
                    <Typography
                      variant={'body2'}
                      align={'right'}
                      className={muiStyles.secondTypography}
                    >
                      {transactionItems.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SingleSegment
