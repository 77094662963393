import { connect } from 'react-redux'

import _Store from '@Store'

import { getIframeParams } from 'models/internalRouter/selectors'

import AuthGate from './AuthGate.component'
import { IAuthGateFromState } from './AuthGate.types'

const mapStateToProps = (state: _Store.IState): IAuthGateFromState => ({
  client: getIframeParams(state)?.client || '',
})

export default connect<IAuthGateFromState, void, {}, _Store.IState>(mapStateToProps)(AuthGate)
