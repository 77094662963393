import * as React from 'react'

import { Box } from '@mui/material'

import { IYouTubeProps } from 'components/reusable/YouTube/YouTube.types'
import { extractYoutubeID } from 'misc/helpers/extractYoutubeID'

export const YouTube = ({ source }: IYouTubeProps) => {
  const id = extractYoutubeID(source)

  if (!id) return null

  return (
    <Box
      component={'iframe'}
      sx={{
        aspectRatio: 16 / 9,
        width: '100%',
        display: 'flex',
        flex: 1,
      }}
      allow={'accelerometer; autoplay; encrypted-media; gyroscope;'}
      allowFullScreen={true}
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder={0}
    />
  )
}
