/**
 * Trims a string to a specified maximum length and appends '...' if it's longer.
 *
 * @param {string} input - The string to be trimmed.
 * @param {number} [maxLength=60] - The maximum length for the string. Defaults to 60.
 * @returns - The trimmed string, or the original string if it's shorter than the maximum length.
 */
export const trimString = (input?: string | null, maxLength: number = 60) => {
  if (!input || input.length <= maxLength) {
    return input
  }
  return `${input.substring(0, maxLength)}...`
}
