import { useEffect } from 'react'

import cn from 'classnames'

import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useClientFromStore } from 'models/internalRouter/hooks/useClientFromStore'

/**
 * This hook adds dynamic global classes to the 'root' element. This is generally
 * considered a bad practice, but it's only temporary, as we're about to remove scss styling.
 */
export const useAppClassNames = () => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { client } = useClientFromStore()

  useEffect(() => {
    const rootElement = document.getElementById('root')
    if (rootElement) {
      const classesToAdd = cn({
        desktop: isDesktop,
        empik: config.theme.isEmpik,
        going: config.theme.isGoing,
        mobile: isMobile,
        tablet: isTablet,
        [client]: client,
      }).toString()

      const classList = classesToAdd.split(' ')

      classList.forEach((className) => rootElement.classList.add(className))

      return () => {
        classList.forEach((className) => rootElement.classList.remove(className))
      }
    }
  }, [isDesktop, isMobile, isTablet, client, config.theme])
}
