import React from 'react'

import { Grid, Link, Tooltip, useTheme } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { useStyles } from 'components/_layout/Navbar/components/DesktopMenu/DesktopMenu.styles'
import config from 'config'
import { DataLayerEvents } from 'constants/Analytics'
import Analytics from 'services/$analytics'
import menu from 'services/$menu-api/index'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const menuSelector =
  config.theme.isEmpik && config.app.onlineSale
    ? menu.menuEmpik
    : config.theme.isEmpik && !config.app.onlineSale
    ? menu.menuEmpikOnsite
    : menu.menuGoing

const DesktopMenu = () => {
  const theme = useTheme()
  const styles = useStyles(theme)
  const { i18n } = useDictionary()

  const handleB2BAnalytics = (buttonLabel: string) => () => {
    Analytics.pushForB2BPageAnalytics({
      eventInfo: {
        detail: 'navigate to b2b landing page',
        text_displayed: buttonLabel,
      },
      event: DataLayerEvents.Interaction,
    })
  }

  return (
    <Grid container={true} spacing={4} justifyContent={'center'}>
      {menuSelector.map((item) => (
        <Grid item={true} key={item.title}>
          {item.link && item.link.startsWith('https://') ? (
            <Link
              href={item.link}
              color={'inherit'}
              underline={'none'}
              target={'_self'}
              variant={'body1'}
              rel={item.nofollow ? 'nofollow' : 'follow'}
              style={item.isSalesButton ? styles.salesButton : undefined}
            >
              {item.title}
            </Link>
          ) : item.link ? (
            <Link
              component={NavLink}
              to={item.link}
              color={'inherit'}
              underline={'none'}
              variant={'body1'}
              rel={item.nofollow ? 'nofollow' : 'follow'}
              style={item.isSalesButton ? styles.salesButton : undefined}
              onClick={item.isSalesButton ? handleB2BAnalytics(item.title) : undefined}
            >
              {item.title}
            </Link>
          ) : (
            <Tooltip title={i18n.menu.comingSoon}>
              <Link
                color={'inherit'}
                underline={'none'}
                variant={'body1'}
                rel={item.nofollow ? 'nofollow' : 'follow'}
              >
                {item.title}
              </Link>
            </Tooltip>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default DesktopMenu
