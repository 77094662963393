import { createSelector } from 'reselect'

import getAvailabilitiesSelector from './getAvailabilitiesSelector'
import getSelected from './getSelected'

const getSelectedSlotHasUpsell = createSelector(
  [getAvailabilitiesSelector, getSelected],
  (availabilities, selected) =>
    !!availabilities
      .find((availability) => availability.start === selected.slot)
      ?.spaces.find((space) => space.upsell)
)

export default getSelectedSlotHasUpsell
