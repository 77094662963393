import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react'

import { Alert, Button, Checkbox, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import debounce from 'lodash.debounce'

import config from 'config'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import stepNames from '../../steps'
import Paper from '../Paper'
import useStyles from './FormLayout.styles'
import { IFormLayoutProps } from './FormLayout.types'

const FormLayout = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  handlePrevStep,
  handleSubmit,
  submissions,
  validationErrors,
  setFieldValue,
  dirty,
  emailValidation,
  checkEmail,
  isEmail,
  skipToSelectedStep,
  partnerFormId,
}: IFormLayoutProps) => {
  const classes = useStyles()
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  const MAX_INACTIVE_TIME = 45000

  const userExist = validationErrors?.submissionsUser
  const emailCheck = useCallback(debounce(checkEmail, 1000), [])

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  const handleDateChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = e.target.value
    if (text.length === 2 || text.length === 5) {
      if (text.length > values.dateOfBirth.length) {
        setFieldValue('dateOfBirth', text + '/')
        return
      }
    }

    setFieldValue('dateOfBirth', text)
  }

  const resetTimer = () => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      skipToSelectedStep(stepNames.welcome)
    }, MAX_INACTIVE_TIME)
  }

  useEffect(() => {
    window.addEventListener('click', resetTimer)
    window.addEventListener('mousemove', resetTimer)

    return () => {
      clearTimeout(timerRef.current)
      window.removeEventListener('click', resetTimer)
      window.removeEventListener('mousemove', resetTimer)
    }
  }, [])

  useEffect(() => {
    resetTimer()
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [values])

  useEffect(() => {
    if (values.email) {
      emailCheck(values.email)
    }

    return emailCheck.cancel
  }, [values.email])

  return (
    <Paper
      title={
        !!submissions.length ? i18n.entryList.form.additionalPerson : i18n.entryList.form.title
      }
      helperText={i18n.entryList.form.subtitle}
    >
      <form onSubmit={handleSubmit}>
        <Grid container={true} justifyContent="center" className={classes.root}>
          {!isEmail && (
            <Grid item={true} xs={12}>
              <TextField
                name="email"
                helperText={!!(touched.email && errors.email) && errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                label={i18n.entryList.form.email}
                variant="outlined"
                fullWidth={true}
                error={!!(touched.email && errors.email)}
                inputProps={{ inputMode: 'email' }}
                autoCapitalize="false"
                autoCorrect="false"
                autoComplete="off"
              />
            </Grid>
          )}

          {!!(emailValidation && !isEmail && !!values.email.length) &&
            (!emailValidation.isValid || !!emailValidation.suggestions?.length) && (
              <Grid item={true} xs={12}>
                <Alert severity={'warning'}>
                  {!emailValidation.isValid &&
                    (emailValidation?.message || i18n.entryList.form.validEmail + '.')}
                  {!!emailValidation.suggestions?.length && (
                    <>
                      {!emailValidation.isValid && <br />}
                      {i18n.entryList.form.didYouMean +
                        emailValidation.suggestions.join(', ') +
                        '?'}
                    </>
                  )}
                </Alert>
              </Grid>
            )}
          <Grid item={true} xs={12}>
            <TextField
              name="firstName"
              helperText={!!(touched.firstName && errors.firstName) && errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={i18n.entryList.form.firstName}
              variant="outlined"
              fullWidth={true}
              error={!!(touched.firstName && errors.firstName)}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="lastName"
              helperText={!!(touched.lastName && errors.lastName) && errors.lastName}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={i18n.entryList.form.lastName}
              variant="outlined"
              fullWidth={true}
              error={!!(touched.lastName && errors.lastName)}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="dateOfBirth"
              helperText={!!(touched.dateOfBirth && errors.dateOfBirth) && errors.dateOfBirth}
              value={values.dateOfBirth}
              onChange={handleDateChange}
              onBlur={handleBlur}
              label={i18n.entryList.form.birthDay}
              variant="outlined"
              fullWidth={true}
              error={!!(touched.dateOfBirth && errors.dateOfBirth)}
              inputProps={{ inputMode: 'numeric' }}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container={true} alignItems="center" className={classes.checkbox}>
              <Grid item={true} xs={12}>
                <Checkbox
                  name="privacyPolicy"
                  color="primary"
                  checked={values.privacyPolicy}
                  onChange={handleChange}
                />
                <Typography variant="body2" className={classes.privacyPolicy}>
                  {i18n.entryList.termsOfUse.accept}
                  <strong>
                    <a
                      href={partnerFormId ? config.statutes[partnerFormId].statueLink : ''}
                      target="_blank"
                    >
                      <u>{i18n.entryList.termsOfUse.statue}</u>
                    </a>
                  </strong>
                  {i18n.entryList.termsOfUse.and}
                  <strong>
                    <a
                      href={partnerFormId ? config.statutes[partnerFormId].policyLink : ''}
                      target="_blank"
                    >
                      <u>{i18n.entryList.termsOfUse.privacyPolicy}</u>
                    </a>
                  </strong>
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <FormHelperText error={true}>
                  {!!(touched.privacyPolicy && errors.privacyPolicy) && errors.privacyPolicy}
                </FormHelperText>
              </Grid>
              {!!(userExist && userExist.length && !dirty) && (
                <FormHelperText error={true}>{userExist}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Grid
              container={true}
              justifyContent="center"
              alignItems="center"
              className={globals.column}
              spacing={1}
            >
              <Grid item={true}>
                <Button
                  className={globals.button}
                  type="button"
                  variant="text"
                  color="primary"
                  onClick={handlePrevStep}
                >
                  {i18n.entryList.form.goBack}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  className={globals.button}
                  variant="contained"
                  size="medium"
                  color="primary"
                  type={'submit'}
                >
                  {i18n.entryList.form.goNext}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default FormLayout
