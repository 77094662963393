import { createSelector } from 'reselect'
import { RouteParams } from 'router/types'

import _Store from '@Store'

import { IInternalRouterReducer } from 'models/internalRouter/types'

import get from './get'

const getParams = createSelector<_Store.IState, IInternalRouterReducer, RouteParams<any> | null>(
  [get],
  (router) => router.internalRouter.params
)

export default getParams
