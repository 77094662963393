import { defaultMemoize } from 'reselect'
import { number, object, string } from 'yup'

import { ILangMap } from 'types/Locale'

const parentData = (lang: ILangMap) => {
  const validators = {
    firstName: string().required(lang.entryList.validation.requiredField),
    lastName: string().required(lang.entryList.validation.requiredField),
    phoneNumber: number()
      .required(lang.entryList.validation.requiredField)
      .typeError(lang.entryList.validation.incorrectPhoneNumber),
  }

  return object().shape(validators)
}

export const makeValidationSchema = (lang: ILangMap) => {
  const validationSchema = () => parentData(lang)

  return defaultMemoize(validationSchema)
}
