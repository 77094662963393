import { connect } from 'react-redux'

import _Store from '@Store'

import { getTheme } from 'models/theme/selectors'

import Checkbox from './Checkbox.component'
import { ICheckboxFromState } from './Checkbox.types'

const mapStateToProps = (state: _Store.IState): ICheckboxFromState => ({
  theme: getTheme(state),
})

export default connect<ICheckboxFromState, {}, {}, _Store.IState>(mapStateToProps)(Checkbox)
