import React from 'react'

import { Alert } from '@mui/material'

import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { DialogAppBar } from 'components/reusable/DialogAppBar'
import { capitalizeFirstLetter } from 'misc/helpers/capitalizeFirstLetter'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const UseAnotherMethod = () => {
  const { i18n } = useDictionary()
  const { signInMethods, resetForm } = useUser()

  return (
    <Flex gap={3}>
      <DialogAppBar title={i18n.auth.errorOccurred} onBack={resetForm} />
      <Alert
        sx={{ width: '100%' }}
        color={'error'}
        children={i18n.auth.alertUseAnother(capitalizeFirstLetter(signInMethods[0].split('.')[0]))}
      />
    </Flex>
  )
}
