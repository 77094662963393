import { pl } from 'lang/pl'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import config from 'config'
import { Languages } from 'constants/Languages'
import { PaymentMethods } from 'constants/PaymentMethods'
import getClosestValue from 'misc/helpers/getClosestValue'
import { navigate } from 'models/internalRouter/actions'
import { getParams } from 'models/internalRouter/selectors'
import { createNotification } from 'models/notifications/actions'
import { resetLoading, setLoading } from 'models/state/actions'
import { sendTransaction } from 'models/transaction/actions'
import { ITransactionBody } from 'models/transaction/types'
import { IReceiveTicketsDataResponse } from 'services/$tickets-api/types'
import { isActionOf } from 'typesafe-actions'

import * as CONSTS from './../../state/constants/constants'
import {
  downloadTicketFromTicketData,
  downloadTicketFromTicketDataOnRequest,
  downloadTicketFromTransactionData,
  downloadTicketFromTransactionDataOnRequest,
  getEventData,
  getTransferTicketFee,
  receiveTicket,
  receiveTicketMounted,
  receiveTicketSubmit,
  resetTickets,
  setShowSender,
} from './../actions'
import { getReceivedTicketData, getTicketTransferFee, getTicketsData } from './../selectors'
import { ITicketReceiveData } from './../types'

export const whenReceiveTicketMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(receiveTicketMounted)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const urlParams = getParams(state)

      return [receiveTicket.request(urlParams.slug)]
    })
  )
}

export const fetchReceiveTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi, ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(receiveTicket.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const ticketHash = action.payload
      const translateDate = pl.dates

      return from$(ticketsApi.getReceiveTicketsData(ticketHash)).pipe(
        mergeMap$((data: IReceiveTicketsDataResponse) => {
          const ticket: ITicketReceiveData = {
            ...data.ticketData,
            pool: data.pools,
            partner: data.rundate.event.partner,
          }

          if (!data.pools.isAvailable) {
            return [
              navigate({ to: routes[Route.ticketManagement] }),
              createNotification('aktualnie niedostępne'),
            ]
          }

          return [
            getEventData.success(eventsApi.normalizeFullEvent(data.rundate, translateDate)),
            receiveTicket.success(ticket),
            getTransferTicketFee.request(),
          ]
        }),
        catchError$((error: Error) => of$(receiveTicket.failure(error)))
      )
    }),
    catchError$((error: Error) => of$(receiveTicket.failure(error)))
  )
}

export const fetchTransferTicketFee: _Store.IEpic = (action$, state$, { ticketsApi }) =>
  action$.pipe(
    filter$(isActionOf(getTransferTicketFee.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return from$(ticketsApi.getTransferFee()).pipe(
        mergeMap$((data) => {
          const receivedTicket = getReceivedTicketData(state)
          const ticketsData = getTicketsData(state)

          if (ticketsData && ticketsData.length) {
            const {
              event: { partnerTransferAmount, partnerTransferPercent },
              pool,
              numberOfTickets,
            } = ticketsData[0]

            if (pool) {
              const summaryPrice = pool.price * numberOfTickets + pool.fee

              const ticketDataTransferFee =
                partnerTransferAmount ||
                (partnerTransferPercent ? summaryPrice * (partnerTransferPercent / 100) : 0)

              const ticketDataValue = getClosestValue(data, 'price', ticketDataTransferFee / 2)

              if (summaryPrice < getClosestValue(data, 'price', 0).price) {
                return of$(getTransferTicketFee.success(ticketDataValue), setShowSender(false))
              }

              return of$(getTransferTicketFee.success(ticketDataValue), setShowSender(true))
            }

            return EMPTY$
          }

          if (!receivedTicket) {
            return EMPTY$
          }
          const selectedPool = receivedTicket.pool?.pools.find(
            (pool) => pool.id === receivedTicket.currentPoolId
          )

          if (!selectedPool) {
            return EMPTY$
          }

          const ticketTransferFee =
            receivedTicket.partner.transferAmount ||
            (receivedTicket.partner.transferPercent
              ? (selectedPool.price * receivedTicket.ticketNum + selectedPool.serviceFee) *
                (receivedTicket.partner.transferPercent / 100)
              : 0)

          const value = getClosestValue(data, 'price', ticketTransferFee / 2)

          return of$(getTransferTicketFee.success(value))
        }),
        catchError$((error) => of$(getTransferTicketFee.failure(error)))
      )
    })
  )

export const submitReceiveTicketForm: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(receiveTicketSubmit)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const urlParams = getParams(state) as RouteParams<Route.ticketReception>
      const receivedTicket = getReceivedTicketData(state)
      const transferFee = getTicketTransferFee(state)
      const paymentDetails = { type: PaymentMethods.PAYU }
      const thankYouRoute = prepareRoute({
        route: Route.ticketReceptionSuccess,
        params: { slug: urlParams.slug },
      })
      const failRoute = prepareRoute({
        route: Route.ticketReceptionFail,
        params: { slug: urlParams.slug },
      })
      const payUReturnLink = `${config.app.baseUrl}${thankYouRoute}`
      const payUFailLink = `${config.app.baseUrl}${failRoute}`
      const acceptedTerms: number[] = []
      Object.keys(action.payload.customTerms).forEach((term) => {
        if (action.payload.customTerms[term]) {
          acceptedTerms.push(parseInt(term, 10))
        }
      })

      if (!receivedTicket || !transferFee) {
        return EMPTY$
      }

      const body: ITransactionBody = {
        agent: config.app.salesAgent,
        discountCode: null,
        invoice: null,
        language: Languages.Polski,
        linkFail: payUFailLink,
        linkOk: payUReturnLink,
        paymentDetails,
        paymentOperator: config.buy.defaultOperator,
        products: [
          {
            id: transferFee.id,
            quantity: 1,
          },
        ],
        salesChannelId: config.app.salesChannelId,
        tickets: [
          {
            poolId: receivedTicket.currentPoolId,
            ticketsNum: receivedTicket.ticketNum || 0,
          },
        ],
        transferHash: urlParams.slug,
        user: {
          acceptedTerms,
          email: receivedTicket.targetEmail,
          empikCardNumber: null,
          empikPremiumJWT: null,
          facebookId: null,
          firstName: receivedTicket.targetFirstname,
          lastName: receivedTicket.targetLastname,
          newsletter: action.payload.newsletter,
          terms: action.payload.terms,
        },
      }

      return [sendTransaction.request({ body, onDone: () => null })]
    })
  )
}

export const fetchEventWhenRequested: _Store.IEpic = (action$, state$, { eventsApi }) => {
  return action$.pipe(
    filter$(isActionOf(getEventData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const slugs = action.payload.split('/')
      const translateDate = pl.dates

      return from$(eventsApi.getSingleEvent(slugs[0], slugs[1])).pipe(
        mergeMap$((data) => {
          return [getEventData.success(eventsApi.normalizeFullEvent(data, translateDate))]
        }),
        catchError$((error: Error) => of$(getEventData.failure(error)))
      )
    }),
    catchError$((error: Error) => of$(getEventData.failure(error)))
  )
}

export const redirectOnReset: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(resetTickets)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return [navigate({ to: routes[Route.ticketManagement] })]
    })
  )

export const requestTicketDownloadFromTicketData: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTicketData)),
    mergeMap$((action) => {
      return of$(
        downloadTicketFromTicketDataOnRequest.request(action.payload),
        setLoading(CONSTS.MYTICKET)
      )
    })
  )

export const downloadTheTicketFromTicketDataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTicketDataOnRequest.request)),
    mergeMap$((action) => {
      const { code, email, ticketId } = action.payload

      return from$(ticketsApi.downloadTicketFromTicketData(ticketId, code, email)).pipe(
        mergeMap$(() => {
          return of$(downloadTicketFromTicketDataOnRequest.success(), resetLoading(CONSTS.MYTICKET))
        }),
        catchError$((error) =>
          of$(downloadTicketFromTicketDataOnRequest.failure(error), resetLoading(CONSTS.MYTICKET))
        )
      )
    })
  )

export const requestTicketDownloadFromTransactionData: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTransactionData)),
    mergeMap$((action) => {
      return of$(
        downloadTicketFromTransactionDataOnRequest.request(action.payload),
        setLoading(CONSTS.MYTICKET)
      )
    })
  )

export const downloadTheTicketFromTransactionDataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTransactionDataOnRequest.request)),
    mergeMap$((action) => {
      const { ticketId, paymentSum, orderId } = action.payload

      return from$(
        ticketsApi.downloadTicketFromTransactionData(ticketId, orderId, paymentSum)
      ).pipe(
        mergeMap$(() => {
          return of$(
            downloadTicketFromTransactionDataOnRequest.success(),
            resetLoading(CONSTS.MYTICKET)
          )
        }),
        catchError$((error) =>
          of$(
            downloadTicketFromTransactionDataOnRequest.failure(error),
            resetLoading(CONSTS.MYTICKET)
          )
        )
      )
    })
  )
