import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    group: {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    seatsButton: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      fontSize: '0.875rem',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      textTransform: 'none',
      borderWidth: '0.125rem',
      marginBottom: theme.spacing(3),

      '&:hover': {
        borderWidth: '0.125rem',
      },
    },
  })
)

export default useStyles
