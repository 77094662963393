import React, { useContext, useEffect } from 'react'

import { Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Field, useField, useFormikContext } from 'formik'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import BasketItem from 'components/Basket/components/BasketItem'
import useStyles from 'components/buying/components/Agreements/Agreements.styles'
import Checkbox from 'components/formik/Checkbox'
import config from 'config'
import getBasketFormattedPools from 'misc/helpers/getBasketFormattedPools'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { ICustomTerm } from 'types/EventCommon'

import { IBuyingOnlineFormValues } from '../../Online/Online.types'
import AdditionalField from './../AdditionalField'
import CustomTerm from './../CustomTerm'
import Term from './../Term'
import classes from './Agreements.classes'
import styles from './Agreements.module.scss'
import { IAgreementsProps } from './Agreements.types'

const TERM_FIELD_NAME = 'terms'
const TERM_NEWSLETTER_ID = 185

const Agreements = ({
  customTerms,
  isBasketPay,
  isDetailedPurchase,
  isDisabled,
  isEmbed,
  number,
  selectedTickets,
  slotsSale,
  setFieldValue,
  values,
  selectedTheme,
}: IAgreementsProps) => {
  const { i18n } = useDictionary()
  const [, , { setValue }] = useField(TERM_FIELD_NAME)
  const { initialValues, errors, touched } = useFormikContext<IBuyingOnlineFormValues>()
  const state = useContext(clearContext)

  const basketItems = state?.basketItems

  const getFilteredCustomTerms = (terms: ICustomTerm[]): ICustomTerm[] =>
    terms.filter((term) =>
      values.invoiceCheckbox
        ? !term.scope || term.scope === 'forFVat'
        : !term.scope || term.scope === 'exceptFVat'
    )

  const filteredCustomTerms = getFilteredCustomTerms(customTerms)

  const formattedItems = getBasketFormattedPools(basketItems)

  useEffect(() => {
    setFieldValue('customTerms', initialValues.customTerms)
  }, [values.invoiceCheckbox])

  let step = 0

  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const isDefaultTheme = selectedTheme === 'default'

  return (
    <div
      className={cn(
        classes.agreements,
        styles.agreements,
        isDisabled && styles.disabled,
        slotsSale && styles.withoutTopMargin
      )}
    >
      {!slotsSale && (
        <Typography
          variant={'h3'}
          className={cn(muiStyles.header, classes.header)}
          data-number={number}
        >
          {isDetailedPurchase
            ? i18n.buy.agreements.acceptAgreementsDetailed
            : i18n.buy.agreements.acceptAgreements}
        </Typography>
      )}

      <fieldset className={cn(styles.formPanel, classes.formPanel)}>
        {!isEmbed &&
          formattedItems?.map((item, i) => {
            return !item.additionalFields &&
              !item.pools.some((pool) => pool.additionalFields) ? null : (
              <div
                key={`basketItem_${i}`}
                className={cn(
                  styles.agreement,
                  classes.agreement,
                  muiStyles.root,
                  !isDefaultTheme && muiStyles.withoutPadding
                )}
              >
                <BasketItem basketItem={item} withoutPrice={true} />
                {[...Array(item.amount)].map((v, index) => {
                  if (item.additionalFields) {
                    return (
                      <AdditionalField
                        key={`additional_${step}`}
                        ticket={item}
                        index={index}
                        step={step++}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    )
                  }
                })}
                {item.pools.map((pool) => {
                  return [...Array(pool.amount)].map((v, index) => {
                    if (pool.additionalFields) {
                      return (
                        <AdditionalField
                          key={`additional_${step}`}
                          ticket={pool}
                          index={index}
                          step={step++}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )
                    }
                  })
                })}

                <div className={styles.agreementsContainer}>
                  <h3 className={styles.title}>{i18n.buy.agreements.agreementsAndRegulations}</h3>
                  <p className={styles.description}>{i18n.buy.agreements.selectConsents}</p>
                </div>
                {getFilteredCustomTerms(item.customTerms).map((term) => (
                  <div
                    className={cn(
                      styles.checkbox,
                      classes.checkbox,
                      !term.selectable && styles.withoutCheckbox,
                      !term.selectable && classes.withoutCheckbox
                    )}
                    data-test-id={'agreements-checkbox'}
                    key={term.id}
                  >
                    {term.selectable ? (
                      <Field
                        component={Checkbox}
                        data-test-id={'agreements-checkbox'}
                        name={`customTerms.${term.id}`}
                        id={String(term.id)}
                      >
                        <CustomTerm term={term} />
                      </Field>
                    ) : (
                      <CustomTerm term={term} />
                    )}
                  </div>
                ))}
              </div>
            )
          })}
        {formattedItems?.map((item) => {
          if (
            !item.isProduct &&
            !item.additionalFields &&
            !item.pools.some((pool) => pool.additionalFields)
          ) {
            const termsToRender = getFilteredCustomTerms(item.customTerms).filter(
              (term) => !filteredCustomTerms.some((_item) => _item.id === term.id)
            )

            if (!!termsToRender.length) {
              return (
                <div
                  className={cn(
                    styles.agreement,
                    classes.agreement,
                    muiStyles.root,
                    !isDefaultTheme && muiStyles.withoutPadding
                  )}
                  key={`agreement-${item.id}`}
                >
                  {!!termsToRender.length && <BasketItem basketItem={item} withoutPrice={true} />}
                  {termsToRender.map((term) => (
                    <div
                      className={cn(
                        styles.checkbox,
                        classes.checkbox,
                        !term.selectable && styles.withoutCheckbox,
                        !term.selectable && classes.withoutCheckbox
                      )}
                      data-test-id={'agreements-checkbox'}
                      key={term.id}
                    >
                      {term.selectable ? (
                        <Field
                          component={Checkbox}
                          name={`customTerms.${term.id}`}
                          id={String(term.id)}
                        >
                          <CustomTerm term={term} />
                        </Field>
                      ) : (
                        <CustomTerm term={term} />
                      )}
                    </div>
                  ))}
                </div>
              )
            }
          }
        })}
        {!isEmbed &&
          formattedItems?.map((item) =>
            item.pools.map((pool) => {
              if (!pool.additionalFields) {
                return (
                  <div
                    className={cn(
                      styles.agreement,
                      classes.agreement,
                      muiStyles.root,
                      !isDefaultTheme && muiStyles.withoutPadding
                    )}
                    key={`agreement-${pool.id}`}
                  >
                    <BasketItem basketItem={item} withoutPrice={true} />
                    {getFilteredCustomTerms(item.customTerms).map((term) => (
                      <div
                        className={cn(
                          styles.checkbox,
                          classes.checkbox,
                          !term.selectable && styles.withoutCheckbox,
                          !term.selectable && classes.withoutCheckbox
                        )}
                        data-test-id={'agreements-checkbox'}
                        key={term.id}
                      >
                        {term.selectable ? (
                          <Field
                            component={Checkbox}
                            name={`customTerms.${term.id}`}
                            id={String(term.id)}
                          >
                            <CustomTerm term={term} />
                          </Field>
                        ) : (
                          <CustomTerm term={term} />
                        )}
                      </div>
                    ))}
                  </div>
                )
              }
            })
          )}
        <div
          className={cn(
            styles.agreement,
            classes.agreement,
            muiStyles.root,
            !isDefaultTheme && muiStyles.withoutPadding
          )}
        >
          {selectedTickets.map((ticket) => {
            return [...Array(ticket.amount)].map((value, index) => {
              if (ticket.additionalFields) {
                return (
                  <AdditionalField
                    key={`additional_${index}`}
                    ticket={{ id: ticket.poolId, poolName: ticket.poolName }}
                    index={index}
                    step={step++}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                )
              }
            })
          })}
          {config.theme.isGoing && isDefaultTheme && (
            <div className={cn(styles.checkbox, classes.checkbox)}>
              <Field component={Checkbox} name="newsletter" id="newsletter">
                <div>
                  <div>{i18n.buy.agreements.iWantNewsletter}</div>
                  <p
                    className={cn(
                      styles.newsletterText,
                      classes.newsletterText,
                      muiStyles.secondary
                    )}
                  >
                    {i18n.buy.agreements.newsletterAgreement}
                  </p>
                </div>
              </Field>
            </div>
          )}

          <div
            className={cn(styles.checkbox, classes.checkbox)}
            data-test-id={'agreements-checkbox'}
          >
            <Field
              component={Checkbox}
              data-test-id={'agreements-checkbox'}
              name={TERM_FIELD_NAME}
              id={TERM_FIELD_NAME}
              isRequiredTerm={true}
              errorMessage={touched.terms && errors.terms}
              error={!!(touched.terms && errors.terms)}
            >
              <Term />
            </Field>
          </div>

          {!isBasketPay &&
            filteredCustomTerms.map((term) => {
              if (!isDefaultTheme && term.id === TERM_NEWSLETTER_ID) {
                return null
              }

              return (
                <div
                  className={cn(
                    styles.checkbox,
                    classes.checkbox,
                    !term.selectable && styles.withoutCheckbox,
                    !term.selectable && classes.withoutCheckbox
                  )}
                  data-test-id={'agreements-checkbox'}
                  key={term.id}
                >
                  {term.selectable ? (
                    <Field
                      component={Checkbox}
                      name={`customTerms.${term.id}`}
                      id={String(term.id)}
                      isRequiredTerm={term.required}
                      errorMessage={touched.customTerms?.[term.id] && errors.customTerms?.[term.id]}
                      error={!!(touched.customTerms?.[term.id] && errors.customTerms?.[term.id])}
                    >
                      <CustomTerm term={term} />
                    </Field>
                  ) : (
                    <CustomTerm term={term} />
                  )}
                </div>
              )
            })}
        </div>
        <Typography variant={'body2'} sx={{ marginTop: 3, fontSize: 18 }}>
          {i18n.buy.agreements.requiredFields}
        </Typography>
      </fieldset>
    </div>
  )
}

export default Agreements
