import { IFaqBrickProps, IFaqExtra } from 'components/Bricks/components/FaqBrick/FaqBrick.types'
import {
  ISectionsComponentDataMutable,
  ISectionsComponentFaqElement,
} from 'models/pages/types/sections'

export const faq = (
  data: ISectionsComponentDataMutable,
  extra?: IFaqExtra | null,
  background?: string
): IFaqBrickProps => {
  const faqElements = data as ISectionsComponentFaqElement[]

  return {
    background,
    color: extra?.color,
    faq: faqElements,
  }
}
