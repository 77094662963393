import * as React from 'react'

import { Box } from '@mui/material'

import config from 'config'
import image from 'misc/styles/images/main-page-background.svg'

export const EmpikTriangleBackground = () => {
  if (!config.theme.isEmpik) return null

  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '90%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: -1,
      }}
    />
  )
}
