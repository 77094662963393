import { createSelector } from 'reselect'

import _Store from '@Store'

import { getEvent } from './../selectors'
import { IEventFull } from './../types'

export const isCourierAvailable = createSelector<_Store.IState, IEventFull | null, boolean>(
  [getEvent],
  (event) => event?.courierDelivery || false
)

export default isCourierAvailable
