import api from './api'
import app from './app'
import aws from './aws'
import braze from './braze'
import buy from './buy'
import cloudFunctions from './cloudFunctions'
import deepLink from './deepLink'
import features from './features'
import filters from './filters'
import { firebase } from './firebase'
import formIo from './formIo'
import images from './images'
import loading from './loading'
import premium from './premium'
import redirections from './redirections'
import resizer from './resizer'
import sitemap from './sitemap'
import social from './social'
import statutes from './statute'
import stickyHeader from './stickyHeader'
import storage from './storage'
import theme from './theme'

const config = {
  api,
  app,
  aws,
  buy,
  braze,
  cloudFunctions,
  deepLink,
  features,
  filters,
  firebase,
  formIo,
  images,
  loading,
  premium,
  redirections,
  resizer,
  sitemap,
  social,
  stickyHeader,
  storage,
  theme,
  statutes,
}

export default config
