import { createSelector } from 'reselect'

import getSelected from './getSelected'

const getSelectedExtendedSlot = createSelector(
  [getSelected],
  (selected) => selected.selectedExtendedSlot
)

export default getSelectedExtendedSlot
