import { IEventFull } from 'models/event/types'
import { getType } from 'typesafe-actions'

import { getEventData, resetTickets } from './../../actions'
import { IAction } from './../../types'

const reducer = (state: IEventFull | null = null, action: IAction): IEventFull | null => {
  switch (action.type) {
    case getType(getEventData.success):
      return action.payload

    case getType(resetTickets):
      return null

    default:
      return state
  }
}

export default reducer
