import * as React from 'react'

import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { defaultValues } from 'components/_forms/_constants/defaultValues'
import { validators } from 'components/_forms/_validators'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { ITicketFormInput } from 'components/ticketManagement/TicketForm/TicketForm.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const TicketForm = () => {
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm<ITicketFormInput>()
  const [searchParams] = useSearchParams()

  const onSubmit = handleSubmit(({ email, code }) => {
    navigate(prepareRoute({ route: Route.ticketManagementTicket, params: { code, email } }))
  })

  const validationRules = {
    [FormFields.EMAIL]: validators.email(
      i18n.forms.validation[FormFields.EMAIL].required,
      i18n.forms.validation[FormFields.EMAIL].correct
    ),
    [FormFields.CODE]: validators.requiredString(i18n.forms.validation[FormFields.CODE].required),
  }

  React.useEffect(() => {
    const code = searchParams.get('entryToken')
    const email = searchParams.get('email')

    if (code && email) {
      navigate(prepareRoute({ route: Route.ticketManagementTicket, params: { code, email } }))
    }
  }, [searchParams])

  return (
    <SectionContainer title={i18n.ticketManagement.ticketForm.ticketDataTitle} container>
      <Form.Wrapper>
        <Form.Row>
          <TextInput<ITicketFormInput>
            name={FormFields.CODE}
            label={i18n.forms.labels[FormFields.CODE]}
            defaultValue={defaultValues.empty}
            control={control}
            rules={validationRules[FormFields.CODE]}
            trimValue
          />
          <TextInput<ITicketFormInput>
            name={FormFields.EMAIL}
            label={i18n.forms.labels[FormFields.EMAIL]}
            defaultValue={defaultValues.empty}
            control={control}
            rules={validationRules[FormFields.EMAIL]}
            helperText={i18n.ticketManagement.ticketForm.email}
            trimValue
          />
        </Form.Row>
        <Button
          variant={'contained'}
          size={'large'}
          onClick={onSubmit}
          children={i18n.buttons.next}
        />
      </Form.Wrapper>
    </SectionContainer>
  )
}
