import { useMediaQuery, useTheme } from '@mui/material'

export const useBreakpoints = () => {
  const theme = useTheme()
  const isWideDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isTablet = useMediaQuery(theme.breakpoints.up('sm')) && !isDesktop
  const isMobile = !isDesktop && !isTablet

  return {
    isWideDesktop,
    isDesktop,
    isMobile,
    isTablet,
  }
}
