import theme from 'config/theme'

const itemsNum = theme.isGoing ? 14 : 5

const filters = {
  howManyItems: itemsNum,
  showFiltersIds: process.env.NODE_ENV === 'development',
}

export default filters
