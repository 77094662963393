import React, { useState } from 'react'

import { ChevronRight } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'

import { Flex } from 'components/_layout/Flex'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './SellerInfoButton.styles'
import { ISellerInfoButtonProps } from './SellerInfoButton.types'

export const SellerInfoButton = ({ partnerInfo }: ISellerInfoButtonProps) => {
  const { i18n } = useDictionary()

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  const [openSellerModal, setOpenSellerModal] = useState<boolean>(false)

  const handleClickOpenSellerModal = () => {
    setOpenSellerModal(true)
  }

  const handleCloseSellerModal = () => {
    setOpenSellerModal(false)
  }

  return (
    <>
      <ListItemButton
        onClick={handleClickOpenSellerModal}
        className={muiStyles.textColor}
        disableGutters
      >
        <ListItemText
          primary={`${i18n.sellerModal.buttonText} ${partnerInfo.sellerName}`}
          primaryTypographyProps={{ variant: 'subtitle2', color: 'primaryText' }}
        />
        <ListItemIcon sx={{ justifyContent: 'flex-end' }} children={<ChevronRight />} />
      </ListItemButton>
      <Dialog
        open={openSellerModal}
        onClose={handleCloseSellerModal}
        aria-labelledby="seller-dialog-title"
      >
        <DialogTitle id="seller-dialog-title" sx={{ paddingTop: 0 }}>
          {i18n.sellerModal.title}
        </DialogTitle>
        <DialogContent>
          <Flex gap={3}>
            <Typography>{`${i18n.sellerModal.subtitle} ${partnerInfo.sellerName}`}</Typography>
            <Box>
              <Typography color={'secondary'} variant={'subtitle2'}>
                {partnerInfo.sellerName} {i18n.sellerModal.headquarters}
              </Typography>
              <Typography color={'secondary'} variant={'subtitle2'}>
                {partnerInfo.address}
              </Typography>
              <Typography color={'secondary'} variant={'subtitle2'}>
                {partnerInfo.city} {partnerInfo.zip}
              </Typography>
              <Typography color={'secondary'} variant={'subtitle2'}>
                {i18n.sellerModal.nip}
                {partnerInfo.nip}
              </Typography>
            </Box>
            <Typography color={'secondary'} variant={'subtitle2'}>
              {i18n.sellerModal.agent(partnerInfo.sellerName)}
            </Typography>
          </Flex>
        </DialogContent>
        <DialogActions sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 0 }}>
          <Button
            sx={({ palette }) => ({ padding: 0, color: palette.text.primary })}
            onClick={handleCloseSellerModal}
          >
            {i18n.sellerModal.accept}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
