import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import config from 'config'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    root: {
      backgroundColor: config.theme.isEmpik ? 'transparent' : theme.palette.background.paper,
    },
  })
)

export default useStyles
