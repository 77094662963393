export enum HitFields {
  _score = '_score',
  artists_ids = 'artists_ids',
  artists_names = 'artists_names',
  artists_slugs = 'artists_slugs',
  caption = 'caption',
  category_id = 'category_id',
  category_name = 'category_name',
  category_slug = 'category_slug',
  change_monitor_name = 'change_monitor_name',
  currency = 'currency',
  description_en = 'description_en',
  description_pl = 'description_pl',
  end_date = 'end_date',
  id_source = 'id_source',
  locations_ids = 'locations_ids',
  locations_names = 'locations_names',
  locations_slugs = 'locations_slugs',
  name_en = 'name_en',
  name_pl = 'name_pl',
  partner_name = 'partner_name',
  place_id = 'place_id',
  place_name = 'place_name',
  place_slug = 'place_slug',
  price = 'price',
  price_description = 'price_description',
  price_with_service_fee = 'price_with_service_fee',
  price_service_fee = 'price_service_fee',
  private_tags_names = 'private_tags_names',
  pub_date = 'pub_date',
  resource_weight = 'resource_weight',
  rundate_slug = 'rundate_slug',
  sale_enabled = 'sale_enabled',
  slug = 'slug',
  start_date = 'start_date',
  tags_ids = 'tags_ids',
  tags_names = 'tags_names',
  tags_slugs = 'tags_slugs',
  thumbnail = 'thumbnail',
  timezone = 'timezone',
  type = 'type',
}

export enum ExprsFields {
  WEIGHT = 'weight',
}

export enum Expressions {
  SORTING = 'sort_direction',
}

export enum SearchTypes {
  ACTIVITY = 'activity',
  ARTIST = 'artist',
  EVENT = 'event',
  PLACE = 'place',
  RUNDATE = 'rundate',
  TAG = 'tag',
}

export enum QueryOperators {
  AND = 'and',
  OR = 'or',
}

/**
 * Sorting option value should use camelCase and include sorting
 * direction (asc / desc) separated with an underscore (_).
 *
 * Each value should also be mapped to a corresponding option in
 * getSorting selector.
 */
export enum SortOptions {
  'DEFAULT' = 'default_asc',
  'START_DATE_ASC' = 'startDate_asc',
  'PRICE_ASC' = 'price_asc',
  'PRICE_DESC' = 'price_desc',
}

export const CloudSearch = {
  resultsLength: 24,
  fuzzyLetters: 5,
  defaultFuzzyLevel: 1,
  expressions: {
    [ExprsFields.WEIGHT]: Expressions.SORTING,
  },
  facets: {
    [HitFields.tags_names]: JSON.stringify({}),
    [HitFields.tags_ids]: JSON.stringify({}),
    [HitFields.tags_slugs]: JSON.stringify({}),
    [HitFields.category_id]: JSON.stringify({ size: 18 }),
    [HitFields.category_name]: JSON.stringify({ size: 18 }),
    [HitFields.category_slug]: JSON.stringify({ size: 18 }),
  },
  searchFields: [
    { fieldName: HitFields.name_pl, boost: 10 },
    { fieldName: HitFields.name_en, boost: 10 },
    { fieldName: HitFields.description_pl, boost: 7 },
    { fieldName: HitFields.description_en, boost: 7 },
    { fieldName: HitFields.caption, boost: 5 },
    { fieldName: HitFields.category_name, boost: 2 },
  ],
  searchHighlightsCount: 3,
  searchHighlightsTags: {
    post: '<e>',
    pre: '<s>',
  },
  sortPriorityCityId: 1,
  staticSorting: [
    { priority: 1, field: ExprsFields.WEIGHT, sort: 'desc' },
    { priority: 2, field: HitFields.sale_enabled, sort: 'asc' },
    { priority: 3, field: HitFields.start_date, sort: 'asc' },
  ],
}

export enum DynamicDates {
  Week = 'week',
  TwoWeeks = 'twoWeeks',
  Weekend = 'weekend',
  None = 'none',
  Today = 'today',
  Tomorrow = 'tomorrow',
  Month = 'month',
}

export enum SEODateParams {
  Today = 'dzis',
  Tomorrow = 'jutro',
  Weekend = 'weekend',
  ThisWeek = 'w-tym-tygodniu',
  ThisMonth = 'w-tym-miesiacu',
}
