export const en: typeof pl = {
  AD: 'Andorra',
  AL: 'Albania',
  AT: 'Austria',
  BA: 'Bosnia and Herzegovina',
  BE: 'Belgium',
  BG: 'Bulgaria',
  BY: 'Belarus',
  CH: 'Switzerland',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland',
  IS: 'Iceland',
  IT: 'Italy',
  KOS: 'Kosovo',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MK: 'North Macedonia',
  MT: 'Malta',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
  SM: 'San Marino',
  UA: 'Ukraine',
  VA: 'Vatican City',
}

export const pl = {
  AD: 'Andora',
  AL: 'Albania',
  AT: 'Austria',
  BA: 'Bośnia i Hercegowina',
  BE: 'Belgia',
  BG: 'Bułgaria',
  BY: 'Białoruś',
  CH: 'Szwajcaria',
  CY: 'Cypr',
  CZ: 'Czechy',
  DE: 'Niemcy',
  DK: 'Dania',
  EE: 'Estonia',
  ES: 'Hiszpania',
  FI: 'Finlandia',
  FR: 'Francja',
  GB: 'Wielka Brytania',
  GR: 'Grecja',
  HR: 'Chorwacja',
  HU: 'Węgry',
  IE: 'Irlandia',
  IS: 'Islandia',
  IT: 'Włochy',
  KOS: 'Kosowo',
  LI: 'Liechtenstein',
  LT: 'Litwa',
  LU: 'Luksemburg',
  LV: 'Łotwa',
  MC: 'Monako',
  MD: 'Mołdawia',
  ME: 'Czarnogóra',
  MK: 'Macedonia Północna',
  MT: 'Malta',
  NL: 'Holandia',
  NO: 'Norwegia',
  PL: 'Polska',
  PT: 'Portugalia',
  RO: 'Rumunia',
  RS: 'Serbia',
  RU: 'Rosja',
  SE: 'Szwecja',
  SI: 'Słowenia',
  SK: 'Słowacja',
  SM: 'San Marino',
  UA: 'Ukraina',
  VA: 'Watykan',
}
