import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { notificationQueueState } from 'state/notification/atoms/notificationQueue'

export const getNotification = selector({
  get: ({ get }) => {
    const queue = get(notificationQueueState)
    return queue.length ? queue[0] : null
  },
  key: RecoilKeys.getNotification,
})
