import React, { FC, useContext } from 'react'

import { ShoppingCartOutlined } from '@mui/icons-material'
import { Badge, Button } from '@mui/material'
import { Route, routes } from 'router/routes'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import useStyles from 'components/Basket/components/BasketButton/BasketButton.styles'
import Link from 'components/reusable/DualLink'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IBasketButtonProps } from './BasketButton.types'

const BasketButton: FC<IBasketButtonProps> = ({
  isError,
  addToBasketRedirect,
  redirectParentTo,
}) => {
  const { i18n } = useDictionary()
  const state = useContext(clearContext)

  const itemsLength = state?.basketItems.length

  const redirect = () => {
    if (addToBasketRedirect) {
      redirectParentTo(addToBasketRedirect)
    }
  }

  const classes = useStyles()

  return (
    <>
      {addToBasketRedirect ? (
        <Button
          onClick={redirect}
          className={classes.root}
          variant={'text'}
          size={'large'}
          startIcon={
            <Badge color={'primary'} badgeContent={isError ? 'X' : itemsLength}>
              <ShoppingCartOutlined />
            </Badge>
          }
        >
          {i18n.basket.basketButton.basket}
        </Button>
      ) : (
        <Button
          component={Link}
          to={routes[Route.cart]}
          className={classes.root}
          variant={'text'}
          size={'large'}
          startIcon={
            <Badge color={'primary'} badgeContent={isError ? 'X' : itemsLength}>
              <ShoppingCartOutlined />
            </Badge>
          }
        >
          {i18n.basket.basketButton.basket}
        </Button>
      )}
    </>
  )
}

export default BasketButton
