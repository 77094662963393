import React from 'react'

import { Box, Button, Typography } from '@mui/material'
import { fetchSignInMethodsForEmail, getAuth } from 'firebase/auth'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { IEmailCheckFormInput } from 'components/auth/EmailCheckForm/EmailCheckForm.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const EmailCheckForm = () => {
  const { i18n } = useDictionary()
  const { loading, setSignInMethods, setUserEmail } = useUser()
  const { control, handleSubmit } = useForm<IEmailCheckFormInput>({
    defaultValues: {
      [FormFields.EMAIL]: '',
    },
  })

  const onSubmit: SubmitHandler<IEmailCheckFormInput> = async ({ email }) => {
    loading.start()
    setSignInMethods(await fetchSignInMethodsForEmail(getAuth(), email))
    setUserEmail(email)
    loading.done()
  }

  return (
    <Flex justify={'center'} gap={6}>
      <Box margin={'auto'}>
        <Typography variant={'h4'} children={i18n.auth.login} align={'center'} />
        <Typography children={i18n.auth.orSignUp} align={'center'} />
      </Box>
      <Form.Wrapper onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name={FormFields.EMAIL}
          label={i18n.forms.labels[FormFields.EMAIL]}
          control={control}
          rules={validators.email(
            i18n.forms.validation[FormFields.EMAIL].required,
            i18n.forms.validation[FormFields.EMAIL].correct
          )}
        />
        <Button variant={'contained'} type={'submit'} children={i18n.buttons.next} fullWidth />
      </Form.Wrapper>
    </Flex>
  )
}
