import * as React from 'react'

import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'

import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/StaticPage/StaticPage.container'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const PaymentPage = () => {
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { transactionId } = useParams<RouteParams<Route.payment>>()
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')

  React.useEffect(() => {
    if (transactionId) {
      navigate(
        prepareRoute({
          route: !!error ? Route.paymentFail : Route.paymentSuccess,
          params: { transactionId },
          query: {
            utm_campaign: searchParams.get('utm_campaign') ?? undefined,
            utm_medium: searchParams.get('utm_medium') ?? undefined,
            utm_source: searchParams.get('utm_source') ?? undefined,
            gtmId: searchParams.get('gtmId') ?? undefined,
            error: error ?? undefined,
          },
        }),
        { replace: true }
      )
    }
  }, [transactionId])

  return (
    <PageWrapper>
      <Helmet pageName={i18n.payment.paymentSuccess.confirm} />
      <Outlet />
    </PageWrapper>
  )
}
