import * as React from 'react'

import { Link, Paper, Typography } from '@mui/material'
import { Outlet, useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const TicketManagementPage = () => {
  const params = useParams()
  const { isMobile } = useBreakpoints()
  const { i18n } = useDictionary()

  /**
   * TODO: add 'current' breadcrumb recoil atom.
   */
  const paramKeys = Object.keys(params)
  const isEditScreen = paramKeys.includes('code') && paramKeys.includes('email')

  return (
    <PageWrapper>
      <Breadcrumbs
        base={[{ link: routes[Route.ticketManagement], label: i18n.breadcrumbs.ticketsManagement }]}
        current={isEditScreen ? i18n.breadcrumbs.ticketEdit : undefined}
      />
      <Outlet />
    </PageWrapper>
  )
}
