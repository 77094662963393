import { getRemoteConfig, getString } from 'firebase/remote-config'

import { RemoteConfigKeys } from 'constants/RemoteConfig'

interface IGetServiceFeeTestParams<T, U> {
  rundateID: number | string | false
  price?: number | string | null
  serviceFee?: T
  priceWithServiceFee?: U
  isWebview: boolean
}

interface IServiceFeeTest<T, U> {
  serviceFee: T
  priceWithServiceFee: U
  isCustomFee: boolean
}

interface IServiceFeeTestRundate {
  rundateID: number
  fee: number
  platform: 'web' | 'app' | null
}

/**
 * Gets service fee test values for a given rundate.
 * TODO: remove this after the test is finished.
 */
export const getServiceFeeTest = <
  T extends number | string | null | undefined,
  U extends number | string | null | undefined
>({
  rundateID,
  priceWithServiceFee: initialPriceWithServiceFee,
  serviceFee: initialServiceFee,
  price: initialPrice,
  isWebview,
}: IGetServiceFeeTestParams<T, U>): IServiceFeeTest<T, U> => {
  const defaultReturn = {
    priceWithServiceFee: initialPriceWithServiceFee,
    serviceFee: initialServiceFee,
    isCustomFee: false,
  } as IServiceFeeTest<T, U>

  if (rundateID === false) return defaultReturn

  const serviceFeeTest = getString(getRemoteConfig(), RemoteConfigKeys.serviceFeeTest)

  if (!serviceFeeTest) return defaultReturn

  const parsedServiceFeeTest: IServiceFeeTestRundate[] = JSON.parse(serviceFeeTest)
  const feeTestRundate = parsedServiceFeeTest.find(
    ({ rundateID: id }) => String(id) === String(rundateID)
  )

  if (
    !feeTestRundate ||
    (feeTestRundate.platform === 'web' && isWebview) ||
    (feeTestRundate.platform === 'app' && !isWebview)
  ) {
    return defaultReturn
  }

  const price = initialPrice != null ? parseFloat(String(initialPrice)) : 0
  let serviceFee = initialServiceFee != null ? parseFloat(String(initialServiceFee)) : 0

  if (serviceFee >= feeTestRundate.fee) return defaultReturn

  if (feeTestRundate.fee != null) serviceFee = feeTestRundate.fee
  const priceWithServiceFee: number = price + serviceFee

  return {
    priceWithServiceFee: (typeof initialPriceWithServiceFee === 'string'
      ? String(priceWithServiceFee)
      : priceWithServiceFee) as U,
    serviceFee: (typeof initialServiceFee === 'string' ? String(serviceFee) : serviceFee) as T,
    isCustomFee: true,
  }
}
