import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { handleNextStep } from 'models/entryList/actions'
import { getIframeParams } from 'models/internalRouter/selectors'

import Welcome from './Welcome.component'
import { IWelcomeFromDispatch, IWelcomeFromState } from './Welcome.types'

const mapStateToProps = (state: _Store.IState) => ({
  formId: getIframeParams(state)?.partnerFormId,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IWelcomeFromDispatch => ({
  handleNextStep: (step) => dispatch(handleNextStep(step)),
})

export default connect<IWelcomeFromState, IWelcomeFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Welcome)
