import * as React from 'react'

import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { isBefore, isSameDay, isWithinInterval } from 'date-fns'

import { IDateFilter } from 'types/Search'

import { StyledDay } from './Day.styles'

export const Day = ({
  day,
  selectedDates: { from, to },
  ...other
}: PickersDayProps<Date> & { selectedDates: IDateFilter }) => {
  if (!from) return <PickersDay day={day} {...other} />

  const disabled = !!from && isBefore(day, from) && !to
  const dayIsBetween = from && to ? isWithinInterval(day, { start: from, end: to }) : false
  const isFirstDay = from ? isSameDay(day, from) : false
  const isLastDay = to ? isSameDay(day, to) : false

  return (
    <StyledDay
      {...other}
      day={day}
      disabled={disabled || other.disabled}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  )
}
