import React from 'react'

import * as braze from '@braze/web-sdk'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, Button, IconButton, InputAdornment } from '@mui/material'
import {
  User,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailLink,
  updatePassword,
  updateProfile,
} from 'firebase/auth'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Checkbox } from 'components/_forms/Checkbox'
import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { defaultValues } from 'components/_forms/_constants/defaultValues'
import { validators } from 'components/_forms/_validators'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { useNotify } from 'components/_functional/NotificationProvider'
import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { ISignUpFormInput } from 'components/auth/SignUpForm/SignUpForm.types'
import { DialogAppBar } from 'components/reusable/DialogAppBar'
import { LabelNewsletter } from 'components/reusable/LabelNewsletter'
import { LabelTerms } from 'components/reusable/LabelTerms'
import { useToggle } from 'misc/hooks/useToggle'
import { Fetcher } from 'services/Api/Fetcher'
import { URI } from 'services/URI'
import { UserApi } from 'services/UserApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { IUpdateUserPayload } from 'types/User'

export const SignUpForm = () => {
  const { i18n } = useDictionary()
  const { userLocation } = useLocations()
  const { userEmail, loading, resetForm } = useUser()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<ISignUpFormInput>({
    defaultValues: {
      [FormFields.EMAIL]: userEmail || defaultValues.empty,
      [FormFields.FIRSTNAME]: defaultValues.empty,
      [FormFields.LASTNAME]: defaultValues.empty,
      [FormFields.PASSWORD]: defaultValues.empty,
      [FormFields.REPEATPASSWORD]: defaultValues.empty,
      [FormFields.NEWSLETTER]: defaultValues.unchecked,
      [FormFields.TERMS]: defaultValues.unchecked,
    },
  })
  const { isVisible, handleToggle } = useToggle()
  const { notify } = useNotify()

  const handleUserAfterRegistrationOrProviderLinking = async (
    user: User,
    body: IUpdateUserPayload
  ) => {
    await updateProfile(user, { displayName: `${body.firstName} ${body.lastName}` })
    await sendEmailVerification(user)
    notify({
      text: i18n.auth.alertVerificationSent,
      // action: { onPress: openInbox, label: i18n.notifications.goToInbox }, // TODO: implement openInbox
    })
    await new Promise((res) => setTimeout(res, 4000)) // pause request so api user create hook can be executed
    await UserApi.updateApiUser(body, user)
  }

  const onSubmit: SubmitHandler<ISignUpFormInput> = async ({
    email,
    password,
    newsletter,
    firstName,
    lastName,
    repeatPassword,
    terms,
  }) => {
    const settings = { language: i18n._lang, locationId: userLocation.id }
    const body = {
      ...settings,
      firstName,
      lastName,
      newsletter,
      retypedPassword: repeatPassword,
      terms,
    }
    const auth = getAuth()
    loading.start()

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password)

      braze.changeUser(user.uid)
      const brazeUser = braze.getUser()
      if (brazeUser) {
        brazeUser.addAlias(email, 'email')
        brazeUser.setEmail(email)

        if (newsletter) {
          brazeUser.setEmailNotificationSubscriptionType(
            braze.User.NotificationSubscriptionTypes.SUBSCRIBED
          )
        }
      }

      await handleUserAfterRegistrationOrProviderLinking(user, body)
    } catch (error: any) {
      /**
       * Firebase createUserWithEmailAndPassword error possibilities
       *
       * @error auth/email-already-in-use Thrown if there already exists an account with the given email address.
       * @error auth/invalid-email Thrown if the email address is not valid.
       * @error auth/operation-not-allowed Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
       * @error auth/weak-password Thrown if the password is not strong enough.
       */
      switch (!!error.message) {
        case error.message.includes('auth/weak-password'):
          setError('password', {
            type: 'manual',
            message: i18n.forms.validation[FormFields.PASSWORD].correct,
          })
          notify({ text: i18n.auth.passwordPolicy, alertColor: 'error' })
          break
        case error.message.includes('auth/user-disabled'):
        case error.message.includes('auth/too-many-requests'):
          notify({ text: i18n.auth.disabledUser, alertColor: 'error' })
          break
        case error.message.includes('auth/email-already-in-use'):
          const loginMethodsBody = JSON.stringify({ email })
          const { link } = await Fetcher.post<{ link: string }>(
            URI.generateSignInLink,
            loginMethodsBody
          )
          const { user } = await signInWithEmailLink(auth, email, link)
          await Fetcher.post(URI.unverifyUserEmail, loginMethodsBody)
          await updatePassword(user, password)
          await handleUserAfterRegistrationOrProviderLinking(user, body)
          await user.reload()
          break
        default:
          break
      }
    } finally {
      loading.done()
    }
  }

  return (
    <Flex gap={4}>
      <DialogAppBar title={i18n.auth.signUp} onBack={resetForm} />
      <Form.Wrapper onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name={FormFields.EMAIL}
          label={i18n.forms.labels[FormFields.EMAIL]}
          control={control}
          rules={validators.email(
            i18n.forms.validation[FormFields.EMAIL].required,
            i18n.forms.validation[FormFields.EMAIL].correct
          )}
          disabled={!!userEmail}
          type={'email'}
        />
        <Form.Row>
          <TextInput
            autoFocus={true}
            autoCapitalize={'words'}
            name={FormFields.FIRSTNAME}
            label={i18n.forms.labels[FormFields.FIRSTNAME]}
            control={control}
            rules={validators.requiredString(i18n.forms.validation[FormFields.FIRSTNAME].required)}
          />
          <TextInput
            autoCapitalize={'words'}
            name={FormFields.LASTNAME}
            label={i18n.forms.labels[FormFields.LASTNAME]}
            control={control}
            rules={validators.requiredString(i18n.forms.validation[FormFields.LASTNAME].required)}
          />
        </Form.Row>
        <TextInput
          name={FormFields.PASSWORD}
          label={i18n.forms.labels[FormFields.PASSWORD]}
          control={control}
          rules={validators.strongPassword(
            i18n.forms.validation[FormFields.PASSWORD].required,
            i18n.forms.validation[FormFields.PASSWORD].correct,
            i18n.forms.validation[FormFields.PASSWORD].minMsg(8)
          )}
          type={isVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'start'}>
                <IconButton
                  onClick={handleToggle}
                  children={isVisible ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            ),
          }}
        />
        <Alert
          children={i18n.auth.passwordPolicy}
          color={!!errors[FormFields.PASSWORD] ? 'error' : 'info'}
        />
        <TextInput
          name={FormFields.REPEATPASSWORD}
          label={i18n.forms.labels[FormFields.REPEATPASSWORD]}
          control={control}
          rules={validators.repeatPassword(
            i18n.forms.validation[FormFields.REPEATPASSWORD].required,
            i18n.forms.validation[FormFields.REPEATPASSWORD].correct,
            watch(FormFields.PASSWORD)
          )}
          type={isVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'start'}>
                <IconButton
                  onClick={handleToggle}
                  children={isVisible ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            ),
          }}
        />
        <Checkbox
          name={FormFields.TERMS}
          control={control}
          rules={validators.term(i18n.forms.validation[FormFields.TERMS].required)}
          label={<LabelTerms />}
        />
        <Checkbox name={FormFields.NEWSLETTER} control={control} label={<LabelNewsletter />} />
        <Button
          variant={'contained'}
          type={'submit'}
          children={i18n.buttons.save}
          disabled={loading.isLoading}
          fullWidth
        />
      </Form.Wrapper>
    </Flex>
  )
}
