import { Grid, styled } from '@mui/material'

export const Styled = {
  Field: styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    borderColor: theme.palette.other.stroke,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'inline-flex',
    padding: theme.spacing(3),
    width: '100%',

    '&:hover': {
      borderColor: '#8f8f8f',
    },
  })),
  Card: styled('div')({
    width: 'calc(100% - 16px)',
  }),
}
