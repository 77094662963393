import { createSelector } from 'reselect'

import _Store from '@Store'

import { IAuthReducer } from './../types'
import get from './get'

const getUserEmail = createSelector<_Store.IState, IAuthReducer, string>(
  [get],
  (auth) => auth.email
)

export default getUserEmail
