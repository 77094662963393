import * as React from 'react'

import { RouteObject } from 'react-router-dom'
import { baseRoutes } from 'router/baseRoutes'

import { Page } from 'components/_functional/InternalRouter'
import { AppLayout } from 'components/_layout/AppLayout'
import { AboutPage } from 'components/_pages/About'
import { AccountPage } from 'components/_pages/Account'
import { ActivitiesPage } from 'components/_pages/Activities'
import { ArtistPage } from 'components/_pages/Artist'
import { DeeplinkTicketPage } from 'components/_pages/DeeplinkTicket'
import { FormioPage } from 'components/_pages/Formio'
import { HelpPage } from 'components/_pages/Help'
import { HomePage } from 'components/_pages/Home'
import { InspirationsPage } from 'components/_pages/Inspirations'
import { LandingPage } from 'components/_pages/Landing'
import { MaintenancePage } from 'components/_pages/Maintenance'
import { MyPassPage } from 'components/_pages/MyPass'
import { MyPassDetailsPage } from 'components/_pages/MyPassDetails'
import { PlacePage } from 'components/_pages/Place'
import { ProfilePage } from 'components/_pages/Profile'
import { RecommendPage } from 'components/_pages/Recommend'
import { ReturnAccountBankPage } from 'components/_pages/ReturnAccountBank'
import { SearchPage } from 'components/_pages/Search'
import { SendMailSuccessPage } from 'components/_pages/SendMailSuccess'
import { SettingsPage } from 'components/_pages/Settings'
import { StaticArticlePage } from 'components/_pages/StaticArticle'
import { TicketManagementPage } from 'components/_pages/TicketManagement'
import { TicketReceptionPage } from 'components/_pages/TicketReception'
import Error404 from 'components/errors/Error404'
import { PaymentFail } from 'components/payment/Fail'
import { PaymentSuccess } from 'components/payment/Success'
import { MyTicket } from 'components/ticketManagement/MyTicket'
import { TicketAction } from 'components/ticketManagement/TicketAction'
import { ActionError } from 'components/ticketManagement/TicketAction/ActionError'
import { TicketActionsList } from 'components/ticketManagement/TicketActionsList'
import { TicketForm } from 'components/ticketManagement/TicketForm'

import { CalendarPage } from '../../components/_pages/Calendar'
import { FavoritesPage } from '../../components/_pages/Favorites'
import { Route, routes } from './../routes'

export const routeObjects = (isMaintenance: boolean | null): RouteObject[] => [
  {
    path: routes[Route.index],
    element: <AppLayout />,
    errorElement: <AppLayout children={<Error404 />} />,
    children: isMaintenance
      ? [
          ...baseRoutes,
          {
            path: routes[Route.help],
            element: <Page PageComponent={HelpPage} module={Route.help} />,
          },
          {
            path: '*',
            element: <Page PageComponent={MaintenancePage} module={Route.error404} />,
          },
        ]
      : [
          ...baseRoutes,
          {
            path: routes[Route.events],
            element: <Page PageComponent={SearchPage} module={Route.search} />,
          },
          {
            path: routes[Route.about],
            element: <Page PageComponent={AboutPage} module={Route.about} />,
          },
          {
            path: routes[Route.help],
            element: <Page PageComponent={HelpPage} module={Route.help} />,
          },
          {
            path: routes[Route.account],
            element: <Page PageComponent={AccountPage} module={Route.account} />,
          },
          {
            path: routes[Route.settings],
            element: <Page PageComponent={SettingsPage} module={Route.settings} />,
          },
          {
            path: routes[Route.contact],
            element: <Page PageComponent={FormioPage} module={Route.contact} />,
          },
          {
            path: routes[Route.form],
            element: <Page PageComponent={FormioPage} module={Route.form} />,
          },
          {
            path: routes[Route.calendar],
            element: <Page PageComponent={CalendarPage} module={Route.calendar} />,
          },
          {
            path: routes[Route.favorites],
            element: <Page PageComponent={FavoritesPage} module={Route.favorites} />,
          },
          {
            path: routes[Route.inspirations],
            element: <Page PageComponent={InspirationsPage} module={Route.inspirations} />,
          },
          {
            path: routes[Route.inspirationsDefault],
            element: <Page PageComponent={InspirationsPage} module={Route.inspirationsDefault} />,
          },
          {
            path: routes[Route.activities],
            element: <Page PageComponent={ActivitiesPage} module={Route.activities} />,
          },
          {
            path: routes[Route.activitiesDefault],
            element: <Page PageComponent={ActivitiesPage} module={Route.activitiesDefault} />,
          },
          {
            path: routes[Route.landingPage],
            element: <Page PageComponent={LandingPage} module={Route.landingPage} />,
          },

          {
            path: routes[Route.place],
            element: <Page PageComponent={PlacePage} module={Route.place} />,
          },
          {
            path: routes[Route.artist],
            element: <Page PageComponent={ArtistPage} module={Route.artist} />,
          },
          {
            path: routes[Route.deeplinkTicket],
            element: <Page PageComponent={DeeplinkTicketPage} module={Route.deeplinkTicket} />,
          },
          {
            path: routes[Route.profile],
            element: <Page PageComponent={ProfilePage} module={Route.profile} />,
          },
          {
            path: routes[Route.returnBankAccount],
            element: (
              <Page PageComponent={ReturnAccountBankPage} module={Route.returnBankAccount} />
            ),
          },
          {
            path: routes[Route.staticArticle],
            element: <Page PageComponent={StaticArticlePage} module={Route.staticArticle} />,
          },
          /** TICKET MANAGEMENT */
          {
            element: <Page PageComponent={TicketManagementPage} module={Route.ticketManagement} />,
            path: routes[Route.ticketManagement],
            children: [
              {
                element: <TicketForm />,
                index: true,
              },
              {
                path: routes[Route.ticketManagementTicket],
                element: <MyTicket />,
                children: [
                  {
                    element: <TicketActionsList />,
                    index: true,
                  },
                  {
                    path: routes[Route.ticketManagementAction],
                    element: <TicketAction />,
                  },
                  {
                    path: routes[Route.ticketManagementActionError],
                    element: <ActionError />,
                  },
                ],
              },
            ],
          },
          {
            path: routes[Route.sendMailSuccess],
            element: <Page PageComponent={SendMailSuccessPage} module={Route.sendMailSuccess} />,
          },
          {
            path: routes[Route.ticketReception],
            element: <Page PageComponent={TicketReceptionPage} module={Route.ticketReception} />,
          },
          {
            path: routes[Route.ticketReceptionSuccess],
            element: <Page PageComponent={PaymentSuccess} module={Route.ticketReceptionSuccess} />,
          },
          {
            path: routes[Route.ticketReceptionFail],
            element: <Page PageComponent={PaymentFail} module={Route.ticketReceptionFail} />,
          },
          {
            path: routes[Route.myPass],
            element: <Page PageComponent={MyPassPage} module={Route.myPass} />,
          },
          {
            path: routes[Route.myPassDetails],
            element: <Page PageComponent={MyPassDetailsPage} module={Route.myPassDetails} />,
          },
        ],
  },
]
