import React from 'react'

import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { Flex } from 'components/_layout/Flex'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './../../Happening.module.scss'
import useStyles from './ExtendedSlot.styles'
import { IExtendedSlotProps } from './ExtendedSlot.types'

const ExtendedSlot = ({
  selectedExtendedSlot,
  selectedSlot,
  resetExtendedSlot,
  selectedTheme,
}: IExtendedSlotProps) => {
  const { isMobile } = useBreakpoints()
  const { i18n } = useDictionary()
  const theme = useTheme()
  const sxStyles = useStyles(theme)({})
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  if (!selectedExtendedSlot || !selectedSlot) {
    return null
  }

  return (
    <Flex fullWidth={isMobile}>
      <Grid
        container={true}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={selectedTheme !== 'default' ? sxStyles.container : sxStyles.root}
        className={selectedTheme === 'default' ? cn(styles.box, styles.calendarBox) : undefined}
        spacing={2}
      >
        <Grid item={true}>
          <Typography variant={'body1'} align={matches ? 'center' : undefined}>
            {i18n.buy.extendedSlots.info}
            {`${selectedSlot.start}-${moment(selectedSlot.start, 'HH:mm')
              .add(selectedExtendedSlot.duration, 'minutes')
              .add(selectedSlot.duration, 'seconds')
              .format('HH:mm')}.`}
          </Typography>
        </Grid>
        <Grid item={true} xs={matches ? 12 : undefined}>
          <Button
            variant={'outlined'}
            size={'large'}
            color={'primary'}
            onClick={resetExtendedSlot}
            fullWidth={matches}
          >
            {i18n.buy.extendedSlots.delete}
          </Button>
        </Grid>
      </Grid>
    </Flex>
  )
}

export default ExtendedSlot
