import { useRecoilValue } from 'recoil'

import { getLang } from '../selectors/getLang'

/**
 * Hook used to retrieve app language setting.
 *
 * @returns {{code: Languages, language: string}}
 */
export const useLang = () => useRecoilValue(getLang)
