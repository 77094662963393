import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { searchPageIndexState } from '../atoms/searchPageIndex'
import { variousFiltersState } from '../atoms/variousFilters'

/**
 * Various filters setter selector. The "set" method resets search page to initial state,
 * each time new value is set.
 */
export const setVariousFilters = selector({
  get: ({ get }) => get(variousFiltersState),
  set: ({ get, set, reset }, newValue) => {
    set(variousFiltersState, newValue)
    reset(searchPageIndexState)
  },
  key: RecoilKeys.setVariousFilters,
})
