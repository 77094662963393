import React from 'react'

import { Typography } from '@mui/material'

import useStyles from './Counter.styles'
import { ICounterProps } from './Counter.types'

const Counter = ({ submissionsAdded, submissionsLeft }: ICounterProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.content}>
        {submissionsAdded}z{submissionsLeft}
      </Typography>
    </div>
  )
}

export default Counter
