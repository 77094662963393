import React from 'react'

import { useParams } from 'react-router-dom'

import { Route } from '../../../router/routes'
import { RouteParams } from '../../../router/types'
import { PageWrapper } from '../../_layout/PageWrapper'
import { Favorites } from '../../favorites/Favorites'
import { useFavoritesContext } from '../../favorites/FavoritesProvider'

export const FavoritesPage = () => {
  const { type } = useParams<RouteParams<Route.favorites>>()
  const { favorites } = useFavoritesContext()

  if (!type) return null

  return (
    <PageWrapper>
      <Favorites favorites={favorites[type]} />
    </PageWrapper>
  )
}
