import qs from 'qs'

import { Fetcher } from 'services/Api/Fetcher'
import { DataMutations } from 'services/DataMutations'
import Logger from 'services/Logger'
import { ICloudSearch, ICloudSearchResponse } from 'types/CloudSearch'

export class CloudSearchApi {
  public static get = async (url: string): Promise<ICloudSearch | null> => {
    try {
      const response: ICloudSearchResponse = await Fetcher.get(url)

      if (response.error) {
        throw new Error(response.error.message)
      }

      if (response) {
        const {
          hits: { start, hit, found },
          facets: { category_id, category_name, category_slug, tags_names, tags_ids, tags_slugs },
        } = response

        if (found === 0) {
          const phrase = qs.parse(url.split('?')[1]).q

          if (phrase && typeof phrase === 'string') {
            // TODO: await Analytics.logEvent(FirebaseEvents.SEARCH_EMPTY, { phrase })
          }
        }

        return {
          categories: DataMutations.combineFacets(category_id, category_name, category_slug),
          data: hit,
          found,
          hasMore: start < found,
          start,
          tags: DataMutations.combineFacets(tags_ids, tags_names, tags_slugs),
          rid: response.status.rid,
        }
      }

      throw new Error('404')
    } catch (e) {
      Logger.error('CloudSearchApi.get', e)

      return null
    }
  }
}
