import React from 'react'

import qs from 'qs'
import { useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { ActionBar } from 'components/ActionBar'
import { ActionBarType } from 'components/ActionBar/ActionBar.types'
import { Activity } from 'components/Activity'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import Helmet from 'components/helmet/Helmet'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { EventApi } from 'services/Api/EventApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ActivityPage = () => {
  const { eventSlug } = useParams<RouteParams<Route.activity>>()
  const { data, isLoading } = useSWR(eventSlug, EventApi.getFullActivity)
  const { i18n } = useDictionary()
  const { userLocation } = useLocations()
  const { isDesktop } = useBreakpoints()

  const route =
    userLocation.slug === 'warszawa'
      ? prepareRoute({
          route: Route.activities,
          params: { slug: userLocation.slug },
        })
      : routes[Route.search] +
        qs.stringify(
          {
            [HitFields.type]: SearchTypes.ACTIVITY,
          },
          { addQueryPrefix: true }
        )

  return (
    <PageWrapper>
      {!!data?.activityData && (
        <Helmet pageName={data?.activityData.title} currentUrl={window?.location.href} />
      )}
      <Breadcrumbs
        base={[
          {
            link: route,
            label: i18n.breadcrumbs.activities,
          },
        ]}
        current={data?.activityData.title}
      />
      {isLoading ? (
        <TwoColumnsSkeleton />
      ) : (
        !!data && (
          <Activity
            activityData={data.activityData}
            cloudinaryImages={data.cloudinaryImages}
            placeActivities={data.placeActivities}
          />
        )
      )}
      {!isDesktop && <ActionBar data={data?.activityData} type={ActionBarType.ACTIVITY} />}
    </PageWrapper>
  )
}
