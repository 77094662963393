import * as React from 'react'

import { PageWrapper } from 'components/_layout/PageWrapper'
import ProductsForm from 'components/buying/Products'
import Helmet from 'components/helmet/components/BuyPage'

export const BuyProductsPage = () => {
  return (
    <PageWrapper>
      <Helmet />
      <ProductsForm />
    </PageWrapper>
  )
}
