import TransactionError from 'misc/classes/TransactionError'
import { getType } from 'typesafe-actions'

import { resetError, setError } from './../actions'
import { IAction, IErrorsReducer } from './../types'

const initialState: IErrorsReducer = {
  code: null,
  id: null,
  isError: false,
  message: null,
  path: null,
  errorLink: null,
  errorMessageLinkLabel: null,
  errorTitle: null,
}

const reducer = (state: IErrorsReducer = initialState, action: IAction): IErrorsReducer => {
  switch (action.type) {
    // SET_ERROR
    case getType(setError):
      if (action.payload instanceof TransactionError) {
        return {
          code: 0,
          id: '',
          isError: true,
          message: action.payload.message,
          path: [],
          errorLink: action.payload._errorLink,
          errorMessageLinkLabel: action.payload._errorMessageLinkLabel,
          errorTitle: action.payload._errorTitle,
        }
      }
      return {
        code: 0,
        id: '',
        isError: true,
        message: action.payload.message,
        path: [],
        errorLink: null,
        errorMessageLinkLabel: null,
        errorTitle: null,
      }

    // RESET_ERROR
    case getType(resetError):
      return initialState

    default:
      return state
  }
}

export default reducer
