import React, { MouseEvent, useEffect, useState } from 'react'

import { Cloudinary } from '@cloudinary/url-gen'
import cn from 'classnames'

import Link from 'components/reusable/DualLink/DualLink.component'
import ImageFixed from 'components/reusable/ImageFixed'
import config from 'config'
import getUrlWithTransformation from 'misc/helpers/cloudinary/getUrlWithTransformation'
import slugifyString from 'misc/helpers/slugifyString'
import Analytics from 'services/$analytics'

import styles from './ArtistTile.module.scss'
import { IArtistTileProps } from './ArtistTile.types'

const ArtistTile = ({
  componentTitle,
  isDark,
  tile,
  mounted,
  images,
  isLoading,
  compositionIdentifier,
  sliderId,
}: IArtistTileProps) => {
  const [cloudinaryImg, setCloudinaryImg] = useState<string>()

  const dimensions = [110, 110]
  const cldKey = `${config.images.artistPrefix}${tile.slug}`
  const src = getUrlWithTransformation(dimensions, tile.img || cloudinaryImg)

  const cld = new Cloudinary({
    cloud: {
      cloudName: config.images.cloudName,
    },
  })

  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    tile.link
      ? Analytics.pushForHomepageSelectItem(e, tile.link, tile.title, componentTitle)
      : undefined

  useEffect(() => {
    if (tile.slug) {
      mounted({ prefix: config.images.artistPrefix, slug: tile.slug })
    }
  }, [])

  useEffect(() => {
    if (images && images[cldKey] && images[cldKey].length > 0) {
      const cldArtistImageSrc = cld.image(images[cldKey][0]).toURL()
      setCloudinaryImg(cldArtistImageSrc)
    }
  }, [images])

  return (
    <li className={styles.artistTile}>
      {tile.link && (
        <Link
          to={tile.link}
          className={`${compositionIdentifier}-${sliderId}-${slugifyString(tile.title)}`}
          onClick={handleAddToDataLayerOnClick}
        >
          <div className={cn(styles.avatarContainer, isLoading && styles.loading)}>
            <ImageFixed
              src={src}
              alt={tile.title}
              aspectRatio={[1, 1]}
              className={cn(
                styles.avatar,
                `${compositionIdentifier}-${sliderId}-${slugifyString(tile.title)}`
              )}
            />
          </div>
          <p className={cn(styles.artistName, isDark && styles.dark)}>{tile.title}</p>
        </Link>
      )}
      {!tile.link && (
        <>
          <div className={cn(styles.avatarContainer, isLoading && styles.loading)}>
            <div className={styles.avatar}>
              <ImageFixed src={src} alt={tile.title} aspectRatio={[1, 1]} />
            </div>
          </div>
          <p className={cn(styles.artistName, isDark && styles.dark)}>{tile.title}</p>
        </>
      )}
    </li>
  )
}

export default ArtistTile
