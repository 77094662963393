import React from 'react'

import { Formik } from 'formik'

import HappeningFormLayout from 'components/Happening/components/HappeningFormLayout'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { makeValidationSchema } from './HappeningBuyFormOnsite.selector'
import { IHappeningOnsiteBuyFormProps } from './HappeningBuyFormOnsite.types'

const HappeningBuyFormOnsite = ({ initialValues, reserve, step }: IHappeningOnsiteBuyFormProps) => {
  const { i18n } = useDictionary()
  const validationSchema = makeValidationSchema(i18n)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={reserve}
      component={(props) => <HappeningFormLayout {...props} step={step} />}
    />
  )
}

export default HappeningBuyFormOnsite
