import * as React from 'react'

import Loading from 'components/Bricks/components/Loading'
import { PageWrapper } from 'components/_layout/PageWrapper'

export const DeeplinkTicketPage = () => {
  return (
    <PageWrapper fullWidth>
      <Loading />
    </PageWrapper>
  )
}
