import { IAvailableThemes } from 'models/theme/types'
import { getType } from 'typesafe-actions'

import { embedLoaded } from './../../actions'
import { EmbedActions } from './../../constants/embedActions'
import { IInternalRouterEmbed, IInternalRouterEvent } from './../../types'

export const initialState: IInternalRouterEmbed = {}

const reducer = (
  state: IInternalRouterEmbed = initialState,
  action: IInternalRouterEvent
): IInternalRouterEmbed => {
  switch (action.type) {
    case EmbedActions.LOAD_EXTERNAL_STYLE:
      return {
        ...state,
        externalStyle: action.payload as string,
      }

    case EmbedActions.SET_APP_URL:
      return {
        ...state,
        appUrl: action.payload as string,
      }

    case EmbedActions.SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.payload as string,
      }

    case EmbedActions.SET_PARENT:
      return {
        ...state,
        parentId: action.payload as string,
      }

    case EmbedActions.SET_CLIENT:
      return {
        ...state,
        client: action.payload as string,
      }

    case EmbedActions.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload as string,
      }

    case EmbedActions.SET_REDIRECT_AFTER_SALE_URL:
      return {
        ...state,
        redirectAfterSaleUrl: action.payload as string,
      }

    case EmbedActions.SET_GTM_KEY:
      return {
        ...state,
        gtmKey: action.payload as string,
      }

    case EmbedActions.SET_GTAG_KEY:
      return {
        ...state,
        gtagKey: action.payload as string,
      }

    case EmbedActions.SET_FB_PIXEL_KEY:
      return {
        ...state,
        fbPixelKey: action.payload as string,
      }

    case EmbedActions.SET_DEFAULT_STORAGE_ID:
      return {
        ...state,
        defaultStorageId: action.payload as number,
      }

    case EmbedActions.SET_PARTNER_FORM_ID:
      return {
        ...state,
        partnerFormId: action.payload as string,
      }

    case EmbedActions.SET_CART_BUTTON_ENABLE:
      return {
        ...state,
        cartButtonEnabled: action.payload as boolean,
      }
    case EmbedActions.SET_BASKET_REDIRECT:
      return {
        ...state,
        basketRedirect: action.payload as string,
      }
    case EmbedActions.SET_ADD_BASKET_REDIRECT:
      return {
        ...state,
        addToBasketRedirect: action.payload as string,
      }
    case EmbedActions.SET_BASKET_THEME:
      return {
        ...state,
        basketTheme: action.payload as IAvailableThemes,
      }

    case EmbedActions.SET_PARTNER_ID:
      return {
        ...state,
        partnerId: action.payload as number,
      }
    case EmbedActions.SET_HAPPENING_REDIRECT:
      return {
        ...state,
        happeningRedirect: action.payload as string,
      }
    case getType(embedLoaded):
      return {
        ...state,
        loaded: true,
      }

    default:
      return state
  }
}

export default reducer
