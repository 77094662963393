import { createSelector } from 'reselect'

import { IEventFull } from 'models/event/types'

import { ITicketsListData } from './../types'
import getTicketsData from './getTicketsData'

const getEventFromTicket = createSelector(
  [getTicketsData],
  (ticketList: ITicketsListData) =>
    (ticketId: string): IEventFull | null => {
      if (ticketList) {
        const foundTicket = ticketList.find((ticket) => ticket.ticketId.toString() === ticketId)

        return foundTicket ? foundTicket.event : null
      }

      return null
    }
)

export default getEventFromTicket
