import React from 'react'

import { Grid } from '@mui/material'

import { getSelectedPriceType } from 'misc/helpers/happening/getSelectedPriceType'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import PriceTypePicker from './../../../PriceTypePicker'
import { IPriceTypeProps } from './PriceType.types'

const PriceType = ({
  isCalculatedPerPerson,
  priceType,
  selections,
  selectedSpace,
  selectedSlotPriceTypes,
}: IPriceTypeProps) => {
  const { isDesktop } = useBreakpoints()

  const isSelected = !!selections.find(
    (selection) =>
      selection.priceType?.type === priceType.type &&
      selection.numberOfPlayers &&
      selection.numberOfPlayers > 0
  )

  return (
    <Grid
      container={true}
      item={true}
      direction={isDesktop && !isCalculatedPerPerson ? 'row' : 'column'}
      key={`form-${priceType}`}
    >
      <Grid item={true} xs={12} md={isCalculatedPerPerson ? 12 : 4}>
        <PriceTypePicker
          happeningPriceType={priceType}
          selectedSpace={selectedSpace}
          priceType={getSelectedPriceType(priceType.type, selectedSlotPriceTypes)}
          counter={priceType.counter}
          isSelected={isSelected}
          defaultCount={priceType.defaultCount}
          counterAsMin={priceType.counterAsMin}
        />
      </Grid>
    </Grid>
  )
}

export default PriceType
