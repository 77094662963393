import { createSelector } from 'reselect'

import _Store from '@Store'

import { IEntryListReducer, IErrorsProps } from './../types'
import get from './get'

const getErrors = createSelector<_Store.IState, IEntryListReducer, IErrorsProps | null>(
  [get],
  (state) => state.errors
)

export default getErrors
