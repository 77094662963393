import React from 'react'

import { Box } from '@mui/material'

import { Rundate } from './Rundate'
import { IRundatesProps } from './Rundates.types'

export const Rundates = ({ rundates, eventSlug }: IRundatesProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {rundates.map((rundate) => (
        <Rundate key={rundate.id} rundate={rundate} eventSlug={eventSlug} />
      ))}
    </Box>
  )
}
