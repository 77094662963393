import { Languages } from 'constants/Languages'

const statutes: Record<
  string,
  {
    smsMessage: (code: string, lang: Languages) => string
    statueLink: string
    policyLink: string
    welcomeMessage: {
      pl: string
      en: string
    }
  }
> = {
  // HANGAR DEV
  '60acac73bd3a4991b3bcc543': {
    smsMessage: (code, lang) =>
      lang === Languages.Polski
        ? `Twój kod: ${code}. Podając kod w formularzu rejestracyjnym wyrażasz zgodę na wejścia Twojego dziecka do hangarów 646 i akceptujesz treść regulaminu dostępnego na www.hangar646.pl/regulamin`
        : `Your code: ${code}. By entering the code in the registration form, you agree to your child's entry into hangars 646 and accept the content of the regulations available at www.hangar646.pl/regulamin`,
    statueLink: 'https://web.goingapp.pl/storage/app/media/hangar/hangar_regulamin.pdf',
    policyLink: 'https://web.goingapp.pl/storage/app/media/hangar/hangar_regulamin.pdf',
    welcomeMessage: {
      pl: 'Hangarze 646',
      en: 'Hangar 646',
    },
  },
  // HANGAR PROD
  '60f905ff6114346ba06101eb': {
    smsMessage: (code, lang) =>
      lang === Languages.Polski
        ? `Twój kod: ${code}. Podając kod w formularzu rejestracyjnym wyrażasz zgodę na wejścia Twojego dziecka do hangarów 646 i akceptujesz treść regulaminu dostępnego na www.hangar646.pl/regulamin`
        : `Your code: ${code}. By entering the code in the registration form, you agree to your child's entry into hangars 646 and accept the content of the regulations available at www.hangar646.pl/regulamin`,
    statueLink: 'https://web.goingapp.pl/storage/app/media/hangar/hangar_regulamin.pdf',
    policyLink: 'https://web.goingapp.pl/storage/app/media/hangar/hangar_regulamin.pdf',
    welcomeMessage: {
      pl: 'Hangarze 646',
      en: 'Hangar 646',
    },
  },
  // SALTOS DEV
  '61406bc6704ec8361e9287fa': {
    smsMessage: (code, lang) =>
      lang === Languages.Polski
        ? `KOD: ${code} Podajac kod podczas rejestracji wyrazasz zgode na wejscia Twojego dziecka do saltosa i akceptujesz tresc regulaminu: https://www.saltos.pl/files/pages/41/Regulamin_SALTOS_01.07.2022_aktualizacja.pdf.`
        : `CODE: ${code} By entering the code during registration, you agree to your child's entry into the saltos and accept the content of the regulations: https://www.saltos.pl/files/pages/41/Regulamin_SALTOS_01.07.2022_aktualizacja.pdf.`,
    statueLink: 'https://docs.goingapp.pl/Regulamin_SALTOS.pdf',
    policyLink: 'https://www.saltos.pl/files/pages/41/SALTOS-P0LITYKAprywatnosci.pdf',
    welcomeMessage: {
      pl: 'Saltos',
      en: 'Saltos',
    },
  },
  // SALTOS PROD
  '614059da704ec89099918eff': {
    smsMessage: (code, lang) =>
      lang === Languages.Polski
        ? `KOD: ${code} Podajac kod podczas rejestracji wyrazasz zgode na wejscia Twojego dziecka do saltosa i akceptujesz tresc regulaminu: https://www.saltos.pl/files/pages/41/Regulamin_SALTOS_01.07.2022_aktualizacja.pdf.`
        : `CODE: ${code} By entering the code during registration, you agree to your child's entry into the saltos and accept the content of the regulations: https://www.saltos.pl/files/pages/41/Regulamin_SALTOS_01.07.2022_aktualizacja.pdf.`,
    statueLink: 'https://docs.goingapp.pl/Regulamin_SALTOS.pdf',
    policyLink: 'https://www.saltos.pl/files/pages/41/SALTOS-P0LITYKAprywatnosci.pdf',
    welcomeMessage: {
      pl: 'Saltos',
      en: 'Saltos',
    },
  },
  // JUMPCITY DEV
  '62b2d050f99e7b33b55f01fb': {
    smsMessage: (code, lang) =>
      lang === Languages.Polski
        ? `KOD: ${code} Podajac kod podczas rejestracji wyrazasz zgode na wejscia Twojego dziecka do JUMPCITY`
        : `CODE: ${code} By entering the code during registration, you agree to your child's entry into the JUMPCITY`,
    statueLink: 'https://docs.goingapp.pl/regulamin-jumpcity.pdf',
    policyLink: 'https://docs.goingapp.pl/regulamin-jumpcity.pdf',
    welcomeMessage: {
      pl: 'JUMPCITY',
      en: 'JUMPCITY',
    },
  },
  // JUMPCITY PROD
  '62a9d510ecd3f7c8bdefaef0': {
    smsMessage: (code, lang) =>
      lang === Languages.Polski
        ? `KOD: ${code} Podajac kod podczas rejestracji wyrazasz zgode na wejscia Twojego dziecka do JUMPCITY`
        : `CODE: ${code} By entering the code during registration, you agree to your child's entry into the JUMPCITY`,
    statueLink: 'https://docs.goingapp.pl/regulamin-jumpcity.pdf',
    policyLink: 'https://docs.goingapp.pl/regulamin-jumpcity.pdf',
    welcomeMessage: {
      pl: 'JUMPCITY',
      en: 'JUMPCITY',
    },
  },
}
export default statutes
