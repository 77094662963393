import { combineReducers } from 'redux'

import eventReducer from './event'
import receivingTicketsReducer from './receivingTickets'
import seatsReducer from './seats'
import ticketTransferFeeReducer from './ticketTransferFee'
import ticketsReducer from './tickets'

const reducer = combineReducers({
  eventData: eventReducer,
  receivingTicket: receivingTicketsReducer,
  seats: seatsReducer,
  ticketTransferFee: ticketTransferFeeReducer,
  ticketsData: ticketsReducer,
})

export default reducer
