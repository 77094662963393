import { useSelector } from 'react-redux'

import _Store from '@Store'

import { IPricing } from 'models/pools/types'

export const usePoolsPricing = () =>
  useSelector<_Store.IState, IPricing[]>(({ pools: { all } }) => {
    const pools = all.poolsData?.pools ?? []
    const pricing: IPricing[] = []
    const filteredPools = pools.filter((pool) => pool.seatsIoCategories.length > 0)

    filteredPools.forEach((pool) => {
      if (pool.seatsIoCategories.length > 0) {
        pool.seatsIoCategories.forEach((category) => {
          const poolCategoryKey = category.categoryKey
          const poolTicketTypes = category.ticketTypes
          const categoryInPricing = pricing.find(
            (pricingCategory) => pricingCategory.category === poolCategoryKey
          )
          const categoryInPricingIndex = pricing.findIndex(
            (pricingCategory) => pricingCategory.category === poolCategoryKey
          )
          const numOfPoolsWithCurrentCategory = filteredPools.filter((poolWithCategory) =>
            poolWithCategory.seatsIoCategories.some(
              (seatsCategory) => seatsCategory.categoryKey === poolCategoryKey
            )
          ).length
          const categoryHasMoreThanOnePoolAssigned = numOfPoolsWithCurrentCategory > 1
          const poolCategories = (): IPricing => {
            if (categoryHasMoreThanOnePoolAssigned) {
              if (categoryInPricing && categoryInPricing.ticketTypes) {
                pricing.splice(categoryInPricingIndex, 1)

                return {
                  ...categoryInPricing,
                  ticketTypes: [...categoryInPricing.ticketTypes, ...poolTicketTypes],
                }
              }

              return {
                category: poolCategoryKey,
                ticketTypes: poolTicketTypes,
              }
            } else {
              return {
                category: poolCategoryKey,
                price: poolTicketTypes[0].price,
              }
            }
          }

          pricing.push(poolCategories())
        })
      }
    })

    return pricing
  })
