import * as React from 'react'

import { Box, Paper, Typography } from '@mui/material'

import { ISectionContainerProps } from './SectionContainer.types'

export const SectionContainer = ({
  children,
  container,
  disabled,
  title,
  id,
  sx,
  fullWidth,
  smallTitle,
}: React.PropsWithChildren<ISectionContainerProps>) => {
  return disabled ? null : (
    <Box
      component={'section'}
      id={id}
      sx={{ width: fullWidth ? '100%' : 'initial', position: 'relative' }}
    >
      {title && (
        <Typography variant={smallTitle ? 'body1' : 'h4'} children={title} sx={{ mb: 3, mt: 5 }} />
      )}
      {container ? <Paper sx={{ p: 3, borderRadius: 4, ...sx }} children={children} /> : children}
    </Box>
  )
}
