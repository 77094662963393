import { pl } from 'lang/pl'
import moment from 'moment'
import { createSelector } from 'reselect'

import { ISummaryEvent } from 'components/eventBoxes/Summary/Summary.types'

import calculateDuration from './calculateDuration'
import getData from './getData'
import getDescription from './getDescription'
import getSelected from './getSelected'
import getSelectedExtendedSlot from './getSelectedExtendedSlot'

const SELECT_DATE = 'Wybierz datę'

const getHappeningEvent = createSelector(
  [getData, getDescription, getSelected, calculateDuration, getSelectedExtendedSlot],
  (happening, description, selected, duration, selectedExtendedSlot): ISummaryEvent => {
    const startDate = () => {
      return moment(selected.day).utc().toString()
    }

    const selectTimeTranslation = pl.buy.happening.selectTime

    const getFriendlyHour = () => {
      return selected.slot && !selected.upsell?.isSelected
        ? selectedExtendedSlot
          ? `${selected.slot} - ${moment(selected.slot, 'HH:mm')
              .add(duration, 'minutes')
              .add(selectedExtendedSlot.duration, 'minutes')
              .format('HH:mm')}`
          : `${selected.slot} - ${moment(selected.slot, 'HH:mm')
              .add(duration, 'minutes')
              .format('HH:mm')}`
        : selected.upsell?.isSelected
        ? `${selected.slot} - ${moment(selected.upsell.endTime, 'HH:mm:ss').format('HH:mm')}`
        : selectTimeTranslation
    }

    return {
      friendlyHour: getFriendlyHour(),
      imagesUrl: happening?.imageUrl,
      placeFriendly: happening?.partner,
      placeSlug: happening?.partner.toLowerCase().replace(/\d+/g, ''),
      rundateDescription: selected.day ? undefined : SELECT_DATE,
      startDate: startDate(),
      title: description?.title || '',
      eventId: happening?.id,
      partnerName: happening?.partner,
      partnerId: happening?.partnerId,
    }
  }
)

export default getHappeningEvent
