import * as React from 'react'

import { ConfirmationNumberOutlined } from '@mui/icons-material'
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { ITicketDownloadProps } from 'components/ticketManagement/TicketDownload/TicketDownload.types'
import { TicketManagementApi } from 'services/Api/TicketManagementApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const TicketDownload = ({ ticketId, code, email }: ITicketDownloadProps) => {
  const { i18n } = useDictionary()
  /**
   *  TODO: use SWR to get the link and use anchor for the download button.
   */
  // const { data } = useSWR<string>(URI.downloadTicket(ticketId, code, email))

  const handleDownload = async () => await TicketManagementApi.downloadTicket(ticketId, code, email)

  return (
    <ListItem sx={{ backgroundColor: 'background.paper', borderRadius: 4, py: 4 }}>
      <ListItemIcon children={<ConfirmationNumberOutlined />} />
      <ListItemText children={i18n.ticketManagement.myTicket.downloadTicket} />
      <Button onClick={handleDownload} variant={'contained'} children={i18n.buttons.download} />
    </ListItem>
  )
}
