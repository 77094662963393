import React from 'react'

import cn from 'classnames'

import styles from './WrongBasketItem.module.scss'
import { IWrongBasketItemProps } from './WrongBasketItem.types'

const LOCAL_CURRENCY = 'zł'

const WrongBasketItem = ({ wrongBasketItem }: IWrongBasketItemProps) => {
  const { title, hour, rundate, place, cityName, poolPrice, amount } = wrongBasketItem

  return (
    <section className={styles.section}>
      <div className={styles.contentWrapper}>
        <div className={styles.container}>
          <div className={styles.box}>
            <p className={styles.title}>{title}</p>
            <div className={styles.placeContainer}>
              <p className={styles.rundate}>{hour ? `${rundate}, ${hour}` : rundate}</p>
              <p className={styles.place}>{`${place}, ${cityName}`}</p>
            </div>
          </div>
        </div>
        <div className={cn(styles.box, styles.boxPrice)}>
          <div className={styles.boxWrapper}>
            <div>
              <div className={styles.priceWrapper}>
                <p className={styles.price}>
                  {poolPrice}
                  <span className={styles.currency}>{LOCAL_CURRENCY}</span>
                </p>
                <p className={styles.amount}>
                  <span className={styles.ticketIcon} />
                  <span>{`x ${amount}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WrongBasketItem
