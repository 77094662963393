import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    withoutPadding: {
      padding: '0!important',
    },
  })
)

export default useStyles
