import { defaultMemoize } from 'reselect'
import { object, string } from 'yup'

import { ILangMap } from 'types/Locale'

const personalDataValidation = (lang: ILangMap) => {
  const validators = {
    submission: string().required(lang.entryList.validation.requiredField).nullable(),
  }

  return object().shape(validators)
}

export const makeValidationSchema = (lang: ILangMap) => {
  const validationSchema = () => personalDataValidation(lang)

  return defaultMemoize(validationSchema)
}
