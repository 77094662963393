import * as React from 'react'

import { Check, KeyboardArrowDown } from '@mui/icons-material'
import { Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useRecoilState, useResetRecoilState } from 'recoil'

import { ISearchTypeFilterProps } from 'components/search/SearchTypeFilter/SearchTypeFilter.types'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { typeFilterState } from 'state/cloudSearch/atoms/typeFilter'
import { setTypeFilter } from 'state/cloudSearch/selectors/setTypeFilter'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const SearchTypeFilter = ({ searchParams }: ISearchTypeFilterProps) => {
  const { i18n } = useDictionary()
  const [type, setType] = useRecoilState(setTypeFilter)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const handleClear = useResetRecoilState(typeFilterState)
  const handleShow = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)
  const handleSelect = (type: SearchTypes) => () => {
    setType(type)
    handleClose()
  }

  React.useEffect(() => {
    if (!!searchParams && searchParams.has(HitFields.type)) {
      const type = searchParams.get(HitFields.type) as SearchTypes
      if (type && Object.values(SearchTypes).includes(type)) setType(type)
    }
  }, [searchParams])

  return (
    <>
      <Chip
        id={'type-filter-button'}
        aria-controls={isOpen ? 'type-filter-menu' : undefined}
        aria-haspopup={'true'}
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleShow}
        icon={<KeyboardArrowDown />}
        label={type ? i18n.searchTypesFilter[type] : i18n.searchTypesFilter.all}
        onDelete={type ? handleClear : undefined}
        size={'small'}
      />
      <Menu
        id={'type-filter-menu'}
        title={i18n.searchTypesFilter.filterTitle}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'type-filter-button' }}
      >
        {Object.values(SearchTypes).map((value) => (
          <MenuItem key={value} onClick={handleSelect(value)}>
            {type === value && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText
              inset={!!type && type !== value}
              children={i18n.searchTypesFilter[value]}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
