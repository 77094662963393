import { useState } from 'react'

import { PickerSelectionState } from '@mui/x-date-pickers/internals'
import { endOfDay, endOfToday, endOfTomorrow, startOfToday, startOfTomorrow } from 'date-fns'
import { useRecoilState } from 'recoil'

import { useToggle } from 'misc/hooks/useToggle'
import { DateTime } from 'services/DateTime'
import { initialState } from 'state/cloudSearch/atoms/dateFilter'
import { setDateFilter } from 'state/cloudSearch/selectors/setDateFilter'
import { IDateFilter } from 'types/Search'

export const useCalendar = () => {
  const { isVisible, handleToggle, handleHide } = useToggle()
  const [{ from }, setDates] = useRecoilState(setDateFilter)
  const [selectedDates, selectDate] = useState<IDateFilter>(initialState)

  /**
   * Handles date or interval selection in the calendar UI.
   */
  const handleSelect = (value: Date | null, selectionState?: PickerSelectionState | undefined) => {
    if (!value) return

    if (selectedDates.from && !selectedDates.to) {
      selectDate({ ...selectedDates, to: value })
    } else if (selectedDates.from && selectedDates.to) {
      selectDate({ from: value, to: null })
    } else {
      selectDate({ ...selectedDates, from: value })
    }
  }

  /**
   * Confirms selected dates and activates filter.
   *
   * @returns {Promise<void>}
   */
  const filterDates = async () => {
    // await Analytics.logEvent(FirebaseEvents.FILTER_DATE, { selectedDates })

    selectDate({
      ...selectedDates,
      to: selectedDates.to ? selectedDates.to : selectedDates.from,
    })
    setDates({
      from: selectedDates.from,
      to: selectedDates.to
        ? endOfDay(selectedDates.to)
        : selectedDates.from
        ? endOfDay(selectedDates.from)
        : null,
    })
    handleHide()
  }

  /**
   * Clears selected dates and purges filter.
   */
  const clear = () => {
    selectDate(initialState)
    setDates(initialState)
  }

  const setToday = () => {
    const makeToday = { from: startOfToday(), to: endOfToday() }

    selectDate(makeToday)
    setDates(makeToday)
    handleHide()
  }

  const setTomorrow = () => {
    const makeTomorrow = { from: startOfTomorrow(), to: endOfTomorrow() }

    selectDate(makeTomorrow)
    setDates(makeTomorrow)
    handleHide()
  }

  const setWeekend = () => {
    const [from, to] = DateTime.nearestWeekend()
    const makeWeekend = { from, to }

    selectDate(makeWeekend)
    setDates(makeWeekend)
    handleHide()
  }

  const setThisWeek = () => {
    const [from, to] = DateTime.thisWeek()
    const makeWeek = { from, to }

    selectDate(makeWeek)
    setDates(makeWeek)
    handleHide()
  }

  const setThisMonth = () => {
    const [from, to] = DateTime.thisMonth()
    const makeMonth = { from, to }

    selectDate(makeMonth)
    setDates(makeMonth)
    handleHide()
  }

  return {
    active: Boolean(from),
    clear,
    filterDates,
    handleSelect,
    selectedDates,
    setToday,
    setTomorrow,
    handleToggle,
    handleHide,
    isVisible,
    setWeekend,
    setThisWeek,
    setThisMonth,
  }
}
