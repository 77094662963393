import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&:hover': {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
    facebookButton: {
      backgroundColor: '#3B5998',
      color: '#fff',
      padding: `1rem 0.75rem`,
      WebkitBackgroundClip: 'unset',
      WebkitTextFillColor: 'unset',
      backgroundImage: 'none',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#3c68c7',
      },
    },
    header: {
      marginBottom: theme.spacing(5),
      fontSize: '1.75rem',

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.border.radius,
      padding: theme.spacing(5),
      '&:first-child': {
        marginBottom: theme.spacing(5),
      },
    },
    withoutPadding: {
      padding: `0!important`,
    },
  })
)

export default useStyles
