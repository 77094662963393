import axios from 'axios'

import config from 'config'

/** Legacy */
class BrazeApi {
  private addToNewsletterUrl = `${config.cloudFunctions.url}/addToNewsletter`

  public addUserToNewsletter = async (emailAddress: string) => {
    const body = {
      email: emailAddress,
    }

    try {
      return await axios.post(this.addToNewsletterUrl, body)
    } catch (error) {
      console.error(error)
    }
  }
}

export default new BrazeApi()
