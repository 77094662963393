import axios from 'axios'

import config from 'config'
import EventsListService from 'misc/classes/EventsListService'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'

import { ILandingPageResponse } from './types'

class LandingPageApi extends EventsListService {
  public getLandingPage(landingSlug: string): Promise<ILandingPageResponse> {
    const hash = Date.now()
    const landingUri = `${config.app.landingPageUrl}${landingSlug}.json?rev=${hash}`

    return new Promise((resolve, reject) => {
      axios
        .get(landingUri)
        .then(getData)
        .then((response: ILandingPageResponse) => {
          resolve(response)
        })
        .catch((error) => {
          reject(catchHttpError(error))
        })
    })
  }
}

export default new LandingPageApi()
