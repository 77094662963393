import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { ICtaButtonStyleProps } from 'components/reusable/CtaButton/CtaButton.types'
import isColorDark from 'misc/helpers/isColorDark'

const useStyles = ({ backgroundColor, isBorder, borderWidth, borderColor }: ICtaButtonStyleProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        backgroundColor,
        border: isBorder
          ? `${borderWidth}px solid ${borderColor ? borderColor : theme.palette.text.primary}`
          : undefined,
        color: isColorDark(backgroundColor) ? theme.palette.grey['50'] : theme.palette.grey['900'],
        marginBottom: '4rem',
      },
    })
  )

export default useStyles
