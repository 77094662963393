import { getType } from 'typesafe-actions'

import { getTransferTicketFee, setShowSender } from '../../actions'
import { IAction, ITicketTransferFeeReducer } from '../../types'
import './../../types'

const initialState: ITicketTransferFeeReducer = {
  feeProduct: null,
  shouldShowSender: true,
}

const reducer = (
  state: ITicketTransferFeeReducer = initialState,
  action: IAction
): ITicketTransferFeeReducer => {
  switch (action.type) {
    case getType(getTransferTicketFee.success):
      return { ...state, feeProduct: action.payload }
    case getType(setShowSender):
      return { ...state, shouldShowSender: action.payload }

    default:
      return state
  }
}

export default reducer
