import React from 'react'

import { Typography } from '@mui/material'

import { FormFields } from 'components/_forms/_constants/FieldNames'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const LabelNewsletter = () => {
  const { i18n } = useDictionary()

  return (
    <>
      <Typography children={i18n.forms.labels[FormFields.NEWSLETTER].header} />
      <Typography
        variant={'caption'}
        color={'secondary'}
        children={i18n.forms.labels[FormFields.NEWSLETTER].agreement}
      />
    </>
  )
}
