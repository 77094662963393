import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

export const initialState: string[] = []

export const sourceIdsFilterState = atom({
  default: initialState,
  key: RecoilKeys.sourceIdsFilter,
})
