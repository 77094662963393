import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { selectTicket } from 'models/pools/actions'
import { getSelectedTickets } from 'models/pools/selectors'

import Forms from './Forms.component'
import { IFormsFromDispatch, IFormsFromState, IFormsOwnProps } from './Forms.types'

const mapStateToProps = (state: _Store.IState): IFormsFromState => ({
  selectedTickets: getSelectedTickets(state),
})
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IFormsFromDispatch => ({
  selectTicket: (
    poolId,
    amount,
    poolName,
    currency,
    paymentMethods,
    price,
    serviceFee,
    additionalFields,
    forms,
    submissions
  ) =>
    dispatch(
      selectTicket({
        additionalFields,
        amount,
        currency,
        forms,
        paymentMethods,
        poolId,
        poolName,
        price,
        serviceFee,
        submissions,
      })
    ),
})

export default connect<IFormsFromState, IFormsFromDispatch, IFormsOwnProps, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Forms)
