import React from 'react'

import { Box, Container, Grid } from '@mui/material'

import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import AppDownload from './components/AppDownload'
import FooterMenu from './components/FooterMenu'
import PhoneMockup from './components/PhoneMockup'

export const Footer = () => {
  const { isMobile } = useBreakpoints()

  return (
    <Box
      component={'footer'}
      sx={(theme) => ({
        backgroundColor: config.theme.isEmpik ? 'common.black' : 'primary.main',
        color: config.theme.isEmpik ? 'secondary.contrastText' : 'primary.contrastText',
        py: 8,
        px: 4,
        maxWidth: '100vw',
        [theme.breakpoints.down('sm')]: {
          overflowX: 'hidden',
        },
      })}
    >
      <Container maxWidth={'lg'}>
        <Grid container={true} direction={isMobile ? 'column' : 'row'} spacing={6}>
          <Grid item={true} sm={8}>
            <FooterMenu />
            {config.theme.isGoing && <AppDownload />}
          </Grid>
          {!isMobile && config.theme.isGoing && <PhoneMockup />}
        </Grid>
      </Container>
    </Box>
  )
}
