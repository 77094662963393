import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      borderRadius: theme.border.radius,
      background: `linear-gradient(105.47deg, ${theme.palette.gradient.first} -1.46%, ${theme.palette.gradient.second} 99.99%)`,
      padding: theme.spacing(6),
      gap: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(5),
      },
    },
    alert: {
      position: 'fixed',
      maxWidth: '15.7rem',
      top: '17.2rem',
      left: '0rem',
      right: '0rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      zIndex: 900,
    },
    button: {
      marginLeft: theme.spacing(3),
    },
    buttonsWrapper: { marginTop: theme.spacing(3) },
    header: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(4),
      },
    },
    inputAndButtonWrapper: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    image: {
      maxHeight: '37rem',
    },
    images: {
      marginRight: '2rem',
      objectFit: 'contain',
      width: '100%',
    },
  })
)

export default useStyles
