import * as React from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './MyPassDetails.styles'
import { IPassProps } from './MyPassDetails.types'

export const MyPassDetails = ({ details }: IPassProps) => {
  const styles = useStyles({})
  const { i18n } = useDictionary()
  const navigate = useNavigate()

  const handleBack = () => navigate(routes[Route.myPass])

  return (
    <>
      <Typography variant={'h2'}>{i18n.passManagement.pageTitle}</Typography>
      <Paper elevation={0} sx={styles.wrapper}>
        <Grid container={true} justifyContent={'space-between'}>
          <Grid item={true}>
            <Grid container={true} direction="column">
              <Grid item={true}>
                <Typography variant={'h6'} sx={styles.textValue}>
                  {details.remainingEntrances}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'caption'} color={'secondary'}>
                  {i18n.passManagement.passDetails.remainingEntries}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'column'}>
              <Grid item={true}>
                <Typography variant={'h6'} sx={styles.textValue}>
                  {format(parseISO(details.expireDate.date), 'dd.MM.yyyy')}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'caption'} color={'secondary'}>
                  {i18n.passManagement.passDetails.expireDate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction="column">
              <Grid item={true}>
                <Typography variant={'h6'} sx={styles.textValue}>
                  {details.isPersonal
                    ? i18n.passManagement.passDetails.isPersonal.individual
                    : i18n.passManagement.passDetails.isPersonal.family}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'caption'} color={'secondary'}>
                  {i18n.passManagement.passDetails.type}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Button
        variant={'text'}
        color={'secondary'}
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{
          width: 'fit-content',
          textTransform: 'none',
          mt: 3,
        }}
      >
        {i18n.passManagement.passDetails.backButton}
      </Button>
    </>
  )
}
