import { MouseEvent } from 'react'

import axios from 'axios'
import { isEqual } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { Filter } from 'lucene-query-builder'

import config from 'config'
import { DataLayerEvents } from 'constants/Analytics'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { CurrencyISO } from 'constants/Currency'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import { IEventAddToCart, IHappeningAddToCart } from 'models/analytics/types'
import { IBasket } from 'models/basket/types'
import { IErrorsDefinition } from 'models/errors/types'
import { IEventFull } from 'models/event/types'
import { IPoolSelectedTicket } from 'models/pools/types'
import { IInsuranceSummaryData } from 'models/products/types'
import { IGetTransactionInfoSuccessPayload } from 'models/transaction/types'
import { DateTime } from 'services/DateTime'
import { ICloudSearchHit } from 'types/CloudSearch'
import { IRundateResponse } from 'types/EventRundate'
import { IDateFilter, ITagsFilterState } from 'types/Search'

import versionJson from '../../../package.json'
import {
  IB2BPageAnalyticsPayload,
  IBuyingProcessPayload,
  IEcommerceObject,
  ITransactionCompleteLayer,
  ITransactionDetailsResponse,
  ITransactionInfoResponse,
  ITransactionItem,
  ITransactionProduct,
} from './types'

const CALENDAR_TEXT = 'Kalendarium'

class Analytics {
  public static getTransactionInfoUrl(transactionId: string) {
    return `${config.api.baseUrl}transaction-info/${transactionId}`
  }

  public static getTransactionDetailsUrl(transactionId: string) {
    return `${config.api.baseUrl}transaction-details/${transactionId}`
  }

  public ecommerceObject: IEcommerceObject = {
    item_id: '',
    item_name: '',
    currency: CurrencyISO.PLN,
    index: 0,
    discount: 0,
    coupon: '',
    item_brand: '',
    item_category: '',
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    item_variant: 0,
    price: 0,
    quantity: 0,
  }

  private pageViewLayer = {
    event: 'PageView',
  }

  private transactionCompleteLayer = {
    event: 'transactionComplete',
  }

  private transactionCompleteEmbedLayer = {
    event: 'transactionCompleteEmbed',
  }

  private errorLayer = {
    event: '',
    eventAction: 'Page View',
    eventCategory: '',
    eventNonInteractive: '',
    eventURL: '',
    eventValue: '',
  }

  public constructor() {
    if (typeof window !== 'undefined' && !window.dataLayer) {
      window.dataLayer = [] as unknown as {
        push: (layer: object) => void
      }
    }
  }

  public getTransactionInfo(transactionId: string): Promise<ITransactionInfoResponse> {
    return new Promise((resolve, reject) => {
      const transactionInfoUrl = Analytics.getTransactionInfoUrl(transactionId)

      axios
        .get(transactionInfoUrl)
        .then(getData)
        .then((response: ITransactionInfoResponse) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public getTransactionDetails(transactionId: string): Promise<ITransactionDetailsResponse> {
    return new Promise((resolve, reject) => {
      const transactionDetailsUrl = Analytics.getTransactionDetailsUrl(transactionId)

      axios
        .get(transactionDetailsUrl)
        .then(getData)
        .then((response: ITransactionDetailsResponse) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public pushPageViewOld() {
    this.addToDataLayer(this.pageViewLayer)
  }

  public pushPageView({
    pageName,
    isEmbed,
    previousUrl,
    embedHostname,
    userEmail,
  }: {
    pageName: string
    isEmbed: boolean
    previousUrl?: string
    embedHostname?: string
    userEmail?: string
  }) {
    const layer = {
      event: 'page_view',
      page: {
        info: {
          name: pageName,
          url: window.location.href,
          path: window.location.pathname,
          referrer: isEmbed
            ? new URLSearchParams(window.location.search).get('docReferrer') ?? ''
            : previousUrl ?? document.referrer,
          environment: process.env.NODE_ENV,
          hostname: isEmbed ? embedHostname : window.location.hostname,
        },
        attributes: {
          is_widget_embed: isEmbed ? 'true' : 'false',
          widget_id: undefined,
          widget_version: versionJson.version || '',
          host_id: undefined,
          host_name: isEmbed ? embedHostname : window.location.hostname,
        },
      },
      user: {
        profile: {
          id: undefined,
          email: userEmail,
          type: undefined,
          crm_status: undefined,
          is_app: undefined,
        },
        attributes: {
          lt_transactions: undefined,
          ltv: undefined,
          account_age: undefined,
          account_created_date: undefined,
        },
      },
    }
    this.addToDataLayer(layer)
  }

  public pushBannerClick(label: string) {
    const layer = {
      event: 'click',
      eventCategory: 'promo-banner',
      eventLabel: label,
    }

    this.addToDataLayer(layer)
  }

  /**
   * This is dedicated for buying process. We mix 'PageView' with data
   * about event.
   */
  public pushForBuyingProcessOld(layer: IBuyingProcessPayload) {
    this.addToDataLayer(this.pageViewLayer, layer)
  }

  public pushForTransactionCompletion(layer: ITransactionCompleteLayer, isEmbed?: boolean) {
    this.addToDataLayer(
      isEmbed ? this.transactionCompleteEmbedLayer : this.transactionCompleteLayer,
      layer
    )
  }

  public pushForErrorModal(url: string, error: IErrorsDefinition) {
    const layer = {
      event: 'GAEventErrorModal',
      eventAction: `${error.id} | ${error.code}`,
      eventCategory: 'Kod błędu i komunikat',
      eventLabel: `${error.message}`,
      eventURL: url,
    }

    this.addToDataLayer(this.errorLayer, layer)
  }

  public pushForErrorWhenSomethingWrong(url: string) {
    const layer = {
      event: 'GAEventErrorUPS',
      eventCategory: 'Error UPS',
      eventLabel: url,
    }

    this.addToDataLayer(this.errorLayer, layer)
  }

  public pushForError404(url: string) {
    const layer = {
      event: 'GAEventError404',
      eventCategory: 'Error 404',
      eventLabel: url,
    }

    this.addToDataLayer(this.errorLayer, layer)
  }

  public pushForEventsList(
    items: ICloudSearchHit[],
    offset: number,
    item_list_id: string,
    item_list_name: string
  ) {
    this.addToDataLayer({
      event: 'view_item_list',
      event_eventInfo_detail: 'product list viewed',
      event_category_event_type: 'interaction',
      event_category_primary_category: 'ecommerce',
      item_list_id,
      item_list_name,
      ecommerce: {
        items: items
          .filter(
            (item) =>
              item.fields[HitFields.type] === SearchTypes.RUNDATE ||
              item.fields[HitFields.type] === SearchTypes.ACTIVITY
          )
          .map((event, position) => ({
            item_brand: event.fields[HitFields.partner_name],
            item_category: event.fields[HitFields.category_name],
            item_id: event.fields[HitFields.slug],
            item_list_name: CALENDAR_TEXT,
            item_name: event.fields[HitFields.name_pl],
            position,
            index: offset + position + 1,
            price: event.fields[HitFields.price],
            item_variant: event.id,
          })),
      },
    })
  }

  public pushForEventPage(event: IEventFull, isEmbed?: boolean, addToCart?: boolean) {
    const miliSecondToHours = 60 * 60 * 1000
    const timeShift = 2 * miliSecondToHours
    const howLongToEvent = Number(new Date(event.startDate)) - Number(new Date()) - timeShift
    const products = [
      {
        brand: `${event.partnerName}|${event.partnerId}`,
        category: !!event.tags.length ? event.tags[0]?.name : '',
        dimension1: `${event.placeFriendly}|${event.placeId}`,
        dimension2: `${event.placeCityName}`,
        dimension4: `${(howLongToEvent / miliSecondToHours).toFixed(2)} h`,
        dimension5: `${event.friendlyDate}|${event.friendlyHour}`,
        dimension8: isEmbed ? 'embed' : 'no-embed',
        id: event.eventId,
        name: event.title,
        price: event.price,
        variant: event.rundateId,
      },
    ]
    if (addToCart) {
      const layer = {
        ecommerce: {
          add: {
            products,
          },
          currencyCode: CurrencyISO.PLN,
        },
        event: 'addToCart',
      }
      this.addToDataLayer(layer)
    } else {
      const layer = {
        ecommerce: {
          currencyCode: CurrencyISO.PLN,
          detail: {
            actionField: {
              list: `Events`,
            },
            products,
          },
        },
        event: 'viewProduct',
      }
      this.addToDataLayer(layer)
    }
  }

  public pushForBuyPage(
    event: IEventFull,
    isEmbed: boolean,
    step: number,
    tickets: number,
    pools: string
  ) {
    const miliSecondToHours = 60 * 60 * 1000
    const timeShift = 2 * miliSecondToHours
    const howLongToEvent = Number(new Date(event.startDate)) - Number(new Date()) - timeShift
    const layer = {
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
          products: [
            {
              brand: `${event.partnerName}|${event.partnerId}`,
              category: !!event.tags.length ? event.tags[0]?.name : '',
              dimension1: `${event.placeFriendly}|${event.placeId}`,
              dimension10: pools,
              dimension2: `${event.placeCityName}`,
              dimension4: `${(howLongToEvent / miliSecondToHours).toFixed(2)} h`,
              dimension5: `${event.friendlyDate}|${event.friendlyHour}`,
              dimension8: isEmbed ? 'embed' : 'no-embed',
              id: event.eventId,
              name: event.title,
              price: event.price,
              quantity: tickets,
              variant: event.rundateId,
            },
          ],
        },
        currencyCode: CurrencyISO.PLN,
      },
      event: 'checkout',
    }

    this.addToDataLayer(layer)
  }

  public pushForViewEvent(rundate: IRundateResponse, isEmbed: boolean, embedParentUrl?: string) {
    const layer = {
      event: 'view_item',
      eventInfo: {
        detail: 'product detail viewed',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: rundate.partner?.id,
        host_name:
          isEmbed && embedParentUrl ? new URL(embedParentUrl).hostname : window.location.hostname,
        tags: rundate.event.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        items: [
          {
            item_id: String(rundate.event.id),
            item_name: rundate.event.title,
            currency: CurrencyISO.PLN,
            index: 0,
            item_brand: `${rundate.partner?.name}|${rundate.partner?.id}`,
            item_category: `${rundate.place.name}|${rundate.place.id}`,
            item_category2: rundate.event.tags[0]?.name ?? '',
            item_category3: rundate.event.artists?.map((a) => a.name).join(',') ?? '',
            item_category4: '',
            item_category5: '',
            item_variant: rundate.id,
            price: Number(rundate.price ?? 0),
            quantity: rundate.event.rundatesCount,
          },
        ],
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForAddToCartEvent({
    isEmbed,
    payload,
    isInsurance,
    numOfInsuredTickets,
    event,
    isActivity,
    embedParentUrl,
  }: {
    isEmbed: boolean
    payload: IEventAddToCart | IHappeningAddToCart
    isInsurance?: boolean
    numOfInsuredTickets?: number
    event?: IEventFull
    isActivity?: boolean
    embedParentUrl?: string
  }) {
    const layer = {
      event: 'add_to_cart',
      eventInfo: {
        detail: 'add to cart',
        text_displayed: '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event?.partnerId,
        host_name:
          isEmbed && embedParentUrl ? new URL(embedParentUrl).hostname : window.location.hostname,
        tags: event?.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        items: [
          {
            item_id: String(isInsurance ? undefined : event?.eventId),
            item_name: event?.title,
            currency: CurrencyISO.PLN,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: isInsurance ? undefined : `${event?.partnerName}|${event?.partnerId}`,
            item_category: isInsurance ? 'insurance' : `${event?.placeFriendly}|${event?.placeId}`,
            item_category2: isInsurance ? '' : !!event?.tags.length ? event?.tags[0].name : '',
            item_category3: !!event?.artists?.length
              ? event.artists.map((artist) => artist.name).join(',')
              : '',
            item_category4: '',
            item_category5: isInsurance ? event?.eventId : '',
            item_variant: isInsurance ? undefined : event?.rundateId,
            price: Number(payload?.price),
            quantity: isInsurance ? numOfInsuredTickets : 1,
            ticket_pool_id: 'poolId' in payload ? payload.poolId : '',
          },
        ],
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForRemoveFromCartEvent({
    isEmbed,
    payload,
    isInsurance,
    numOfInsuredTickets,
    event,
    isActivity,
    embedParentUrl,
  }: {
    isEmbed: boolean
    payload: IEventAddToCart | IHappeningAddToCart
    isInsurance?: boolean
    numOfInsuredTickets?: number
    event?: IEventFull
    isActivity?: boolean
    embedParentUrl?: string
  }) {
    const layer = {
      event: 'remove_from_cart',
      eventInfo: {
        detail: 'remove from cart',
        text_displayed: '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event?.partnerId,
        host_name:
          isEmbed && embedParentUrl ? new URL(embedParentUrl).hostname : window.location.hostname,
        tags: event?.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        items: [
          {
            item_id: String(isInsurance ? undefined : event?.eventId),
            item_name: event?.title,
            currency: CurrencyISO.PLN,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: isInsurance ? undefined : `${event?.partnerName}|${event?.partnerId}`,
            item_category: isInsurance ? 'insurance' : `${event?.placeFriendly}|${event?.placeId}`,
            item_category2: isInsurance ? '' : !!event?.tags.length ? event?.tags[0].name : '',
            item_category3: !!event?.artists?.length
              ? event.artists.map((artist) => artist.name).join(',')
              : '',
            item_category4: '',
            item_category5: isInsurance ? event?.eventId : '',
            item_variant: isInsurance ? undefined : event?.rundateId,
            price: isInsurance
              ? Number(payload?.price)
              : Number(isActivity ? payload.price : event?.price),
            quantity: isInsurance ? numOfInsuredTickets : 1,
            ticket_pool_id: 'poolId' in payload ? payload.poolId : '',
          },
        ],
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForAddPaymentInfo(
    paymentMethod: string,
    finalPrice: number,
    buttonLabel: string,
    isEmbed: boolean,
    event?: IEventFull,
    basketData?: IBasket[],
    selectedTickets?: IPoolSelectedTicket[],
    insuranceSummary?: IInsuranceSummaryData
  ) {
    const itemsArray: IEcommerceObject[] = []

    if (insuranceSummary?.isSelected && event) {
      const insuranceProduct = {
        ...this.ecommerceObject,
        item_id: undefined,
        item_name: event.title,
        item_brand: undefined,
        item_category: 'insurance',
        item_variant: undefined,
        price: insuranceSummary.value ? insuranceSummary.value : 0,
        quantity: 1,
      }

      itemsArray.push(insuranceProduct)
    }

    if (event && !!selectedTickets?.length) {
      const selectedPools = selectedTickets.map((pool) => ({
        ...this.ecommerceObject,
        item_id: String(event.eventId),
        item_name: event.title,
        item_brand: `${event.partnerName}|${event.partnerId}`,
        item_category: `${event.placeFriendly}|${event.placeId}`,
        item_category2: !!event.tags.length ? event.tags[0].name : '',
        item_category3: !!event.artists?.length
          ? event.artists.map((artist) => artist.name).join(',')
          : '',
        item_variant: event.rundateId,
        item_ticket_pool_id: pool.poolId,
        price: pool.price || 0,
        quantity: pool.amount,
      }))

      itemsArray.push(...selectedPools)
    }

    if (basketData && basketData.length > 0) {
      const basketPools = basketData
        .map((basketEvent) =>
          basketEvent.pools.map((pool) => ({
            ...this.ecommerceObject,
            item_id: String(basketEvent.eventId),
            item_name: basketEvent.title ? basketEvent.title : '',
            item_brand: `${basketEvent.partnerName}|${basketEvent.partnerId}`,
            item_category: `${basketEvent.placeFriendly}|${basketEvent.placeId}`,
            item_category2: !!basketEvent?.tags?.length ? basketEvent.tags[0].name : '',
            item_category3: !!basketEvent.artists?.length
              ? basketEvent.artists.map((artist) => artist.name).join(',')
              : '',
            item_variant: basketEvent.rundateId || 0,
            price: pool.poolPrice || 0,
            quantity: pool.amount,
          }))
        )
        .flat()

      itemsArray.push(...basketPools)
    }

    const layer = {
      event: 'add_payment_info',
      eventInfo: {
        detail: 'proceed to payment operator',
        text_displayed: buttonLabel,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed,
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event?.partnerId,
        host_name: window.location.hostname,
        tags: event?.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        currency: CurrencyISO.PLN,
        value: finalPrice,
        payment_type: paymentMethod ? paymentMethod : '',
        items: itemsArray.map((item, index) => ({ ...item, index })),
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForPurchase({
    isEmbed,
    transactionItems,
    transactionInfo,
    transactionInsurance,
    embedParentUrl,
  }: {
    isEmbed: boolean
    transactionInfo: IGetTransactionInfoSuccessPayload
    transactionItems?: ITransactionItem[]
    transactionInsurance?: ITransactionProduct[]
    embedParentUrl?: string
  }) {
    const itemsArray: IEcommerceObject[] = []

    if (transactionItems && transactionItems.length > 0) {
      const productsArray = transactionItems.map((product, index) => ({
        ...this.ecommerceObject,
        item_name: product.productName,
        price: product.price,
        quantity: product.quantity,
        item_variant: Number(transactionInfo.products.variant),
        item_brand: transactionInfo.products.brand,
        item_ticket_pool_id: Number(transactionInfo.products.dimension10),
        item_category: transactionInfo.products.dimension1,
        item_category2: transactionInfo.products.dimension7,
        item_category3: product.artists?.join(',') || '',
        item_id: product.eventId?.toString() || '',
        currency: product.currency,
        index,
      }))

      itemsArray.push(...productsArray)
    }

    if (transactionInsurance && transactionInsurance.length > 0) {
      const insuranceArray = transactionInsurance.map((insurance) => ({
        ...this.ecommerceObject,
        item_name: insurance.productName,
        price: insurance.price,
        quantity: insurance.quantity,
      }))

      itemsArray.push(...insuranceArray)
    }

    const layer = {
      event: 'purchase',
      eventInfo: {
        detail: 'purchase',
        text_displayed: '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: undefined,
        host_name:
          isEmbed && embedParentUrl ? new URL(embedParentUrl).hostname : window.location.hostname,
        tags: [
          transactionInfo.products.category,
          transactionItems &&
            transactionItems?.map((transactionItem) => transactionItem.tags.join(',')),
        ].join(','), // TODO: fix when API updated
      },
      ecommerce: {
        transaction_id: `${transactionInfo.id}`,
        value: transactionInfo.revenue,
        tax: transactionInfo.tax ? transactionInfo.tax : 0,
        shipping: transactionInfo.shipping,
        currency: CurrencyISO.PLN,
        coupon: transactionInfo.coupon,
        items: itemsArray,
      },
    }

    this.addToDataLayer(layer)

    try {
      if (window?.top && isEmbed) {
        window.top.postMessage(JSON.stringify(layer), '*')
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  public pushForSearch(
    phrase: string,
    totalResults: number,
    dates: IDateFilter,
    variousFilters: Filter[],
    tagsFilter: ITagsFilterState,
    locationName?: string | null,
    categoryName?: string | null
  ) {
    let date = ''

    const legacyTagsNames = variousFilters
      .map((filter) => {
        const [key, value] = Object.entries(filter)[0]

        if (key === 'tags_names') return value
      })
      .filter(Boolean)
    const tagsNames = tagsFilter.map((tag) => tag.name)

    if (dates.from && dates.to) {
      if (isEqual(dates.from, dates.to))
        date = formatInTimeZone(dates.from, DateTime.defaultTimeZone, 'yyyy-MM-dd')
      else
        date = `${formatInTimeZone(
          dates.from,
          DateTime.defaultTimeZone,
          'yyyy-MM-dd'
        )} - ${formatInTimeZone(dates.to, DateTime.defaultTimeZone, 'yyyy-MM-dd')}`
    }

    const layer = {
      event: 'search',
      eventInfo: {
        detail: 'search',
        text_displayed: 'bar',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'search',
      },
      attributes: {
        search_destination_url: window.location.href,
        search_phrase: phrase,
        search_results: totalResults,
        search_view: 'default',
        search_date: date,
        search_category: categoryName || '',
        search_tag: [...tagsNames, legacyTagsNames].join(', '),
        search_city: locationName || '',
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForHomepageSelectItem(
    clickEvent: MouseEvent<HTMLElement>,
    destinationUrl: string,
    textDisplayed: string,
    componentName?: string
  ) {
    clickEvent.stopPropagation()

    this.addToDataLayer({
      event: 'interaction',
      eventInfo: {
        detail: 'select_item',
        text_displayed: textDisplayed,
        component_name: componentName || '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'navigation',
      },
      attributes: {
        destination_url: destinationUrl,
      },
    })
  }

  public pushForProceed(textDisplayed: string) {
    const layer = {
      event: 'proceed',
      eventInfo: {
        detail: 'proceed to checkout',
        text_displayed: textDisplayed,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'transaction',
      },
      attributes: {
        destination_url: window.location.href,
      },
      transaction: {
        type: 'buy',
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForAutoFillData(textDisplayed: string) {
    const layer = {
      event: 'interaction',
      eventInfo: {
        detail: 'checkout',
        text_displayed: textDisplayed,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'checkout',
      },
    }

    this.addToDataLayer(layer)
  }

  public pushForDeeplinkWeb(
    textDisplayed: string,
    destinationType: string,
    destinationUrl: string
  ) {
    const layer = {
      event: 'interaction',
      eventInfo: {
        detail: 'choose service version',
        text_displayed: textDisplayed,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'product_page',
      },
      attributes: {
        destination_url: destinationUrl,
        destination_type: destinationType,
      },
    }

    this.addToDataLayer(layer)
  }

  /**
   * Sends information about newsletter subscription to data layer.
   *
   * @param {string} email - SHA256 hashed mail
   * @param buttonText - button label from translations
   */
  public pushNewsletterSubscription(email: string, buttonText: string) {
    this.addToDataLayer({
      event: 'newsletter_subscription',
      eventInfo: {
        detail: 'checkout checkbox',
        text_displayed: buttonText,
      },
      category: {
        event_type: 'newsletter_subscription',
        primary_category: 'checkout',
      },
      user: {
        profile: {
          email,
        },
      },
    })
  }

  public pushAddToFavorites({
    destination_url,
    item_id,
    item_name,
    email,
  }: {
    destination_url: string
    item_id: number
    item_name: string
    email: string
  }) {
    this.addToDataLayer({
      event: 'wishlist_add',
      eventInfo: {
        detail: 'add event to wishlist',
      },
      category: {
        event_type: 'wishlist_add',
        primary_category: 'wishlist',
      },
      attributes: {
        destination_url,
        item_id,
        item_name,
      },
      user: {
        profile: {
          email,
        },
      },
    })
  }

  public pushForB2BPageAnalytics({ eventInfo, event }: IB2BPageAnalyticsPayload) {
    this.addToDataLayer({
      event,
      eventInfo,
      category: {
        event_type: DataLayerEvents.Interaction,
        primary_category: 'b2b_contact_form',
      },
    })
  }

  protected isDataLayerEnabled() {
    return typeof window !== 'undefined' && window.dataLayer
  }

  protected addToDataLayer(commonLayer: object, layer?: object) {
    if (this.isDataLayerEnabled()) {
      window.dataLayer.push({
        ecommerce: null,
      })
      window.dataLayer.push({
        ...commonLayer,
        ...layer,
      })
    }
  }
}

export default new Analytics()
