import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import sectionComponentFontColor from 'misc/helpers/theme/sectionComponentFontColor'

const useStyles = (color?: string, isBgDark?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      fontColor: {
        color: sectionComponentFontColor(theme, color, isBgDark) + '!important',
      },
    })
  )

export default useStyles
