import { ValidationRules } from 'components/_forms/_validators/types'

export const minNumberValidator = (
  minValue: number,
  requiredMsg: string,
  minMsg: (min: number) => string
): ValidationRules => ({
  required: requiredMsg,
  min: { value: minValue, message: minMsg(minValue) },
})
