import * as TEXTS from 'services/$transactions-api/helpers/texts'

class TransactionError extends Error {
  public readonly _errorTitle: string | null
  public readonly _errorMessageLinkLabel: string | null
  public readonly _errorLink: string | null
  private readonly _code: number
  private readonly _id: string
  private readonly _path: string[] | null

  constructor(
    message?: string,
    id?: string,
    code?: number,
    path?: string[],
    errorTitle?: string,
    errorMessageLinkLabel?: string,
    errorLink?: string
  ) {
    const _message = message ?? TEXTS.DEFAULT_ERROR_TEXT
    super(_message)

    this._errorTitle = errorTitle ?? null
    this._errorMessageLinkLabel = errorMessageLinkLabel ?? null
    this._errorLink = errorLink ?? null
    this._path = path ?? null
    this._code = code ?? TEXTS.DEFAULT_ERROR_CODE
    this._id = id ?? TEXTS.DEFAULT_ERROR_ID
  }

  get path(): string[] | null {
    return this._path
  }

  get code(): number {
    return this._code
  }

  get id(): string {
    return this._id
  }
}

export default TransactionError
