import { useDispatch } from 'react-redux'

import { appIsReady } from 'models/app/actions'

export const useDispatchAppIsReady = () => {
  const dispatch = useDispatch()

  return {
    appIsReady: () => dispatch(appIsReady()),
  }
}
