export enum CloudinaryPrefixes {
  ARTIST = 'artist',
  EVENT = 'event',
  PLACE = 'place',
  SELECTION = 'selection',
  TAG = 'tag',
  RUNDATE = 'rundate',
}

export enum CloudinaryFontId {
  NOIR_PRO_BOLD = 'eskicbybzslwiiibum62.otf',
  NOIR_PRO_HEAVY = 'p3qxht0v8j94lh6zh5ie.otf',
  NOIR_PRO_LIGHT = 'r79cwowbdzvmrrne8tjz.otf',
  NOIR_PRO_MEDIUM = 'h72dsklrg8llat8blyuh.otf',
  NOIR_PRO_REGULAR = 'b2t8jr1os6ezvwdqwkcn.otf',
}

export enum CloudinaryNamedTransformations {
  ShareEventTemplate = 'share_event_template',
  ShareEventTemplateBranded = 'share_event_template_branded',
}
