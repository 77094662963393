import * as React from 'react'

import { Typography } from '@mui/material'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Flex } from 'components/_layout/Flex'
import { ISearchStatusBarProps } from 'components/search/SearchStatusBar/SearchStatusBar.types'
import { formatPluralNouns } from 'misc/helpers/formatPluralNouns'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

export const SearchStatusBar = ({ found, isLoading, terms }: ISearchStatusBarProps) => {
  const { i18n } = useDictionary()
  const { code } = useLang()

  return (
    <Flex
      direction={'row'}
      sx={{ pt: 6 }}
      justify={!!terms.length ? 'space-between' : 'flex-end'}
      align={'center'}
    >
      {!!terms.length && (
        <Breadcrumbs
          component={'h1'}
          base={terms.map((term) => ({
            label: term.name,
            link: prepareRoute({
              route: Route.search,
              params: undefined,
              query: {
                [term.fieldNames.name]: term.name,
                [term.fieldNames.id]: term.id?.toString(),
              },
            }),
          }))}
        />
      )}
      <Typography
        align={'right'}
        variant={'subtitle2'}
        color={'secondary'}
        children={`${i18n.search.found} ${isLoading ? '...' : found} ${formatPluralNouns(
          found,
          code,
          i18n.search.items
        )}`}
      />
    </Flex>
  )
}
