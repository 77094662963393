import React from 'react'

import { AppBar, Slide, Toolbar, styled, useScrollTrigger } from '@mui/material'

import Brand from 'components/_layout/Brand'
import useStyles from 'components/_layout/Navbar/Navbar.styles'
import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import DesktopMenu from './components/DesktopMenu'
import EmpikBar from './components/EmpikBar'
import IconBar from './components/IconBar'

const Offset = styled('div')(({ theme }) => ({
  minHeight: 64,
  [theme.breakpoints.up('md')]: {
    minHeight: 96,
  },
}))

export const Navbar = () => {
  const classes = useStyles()
  const { isDesktop } = useBreakpoints()
  const trigger = useScrollTrigger()

  return (
    <>
      <Slide appear={false} direction={'down'} in={!trigger}>
        <AppBar color={'default'}>
          {config.theme.isEmpik && isDesktop && <EmpikBar />}
          <Toolbar className={classes.toolbar}>
            <Brand />
            {isDesktop && <DesktopMenu />}
            <IconBar />
          </Toolbar>
        </AppBar>
      </Slide>
      <Offset />
    </>
  )
}
