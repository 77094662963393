import * as React from 'react'

import { Search } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import debounce from 'lodash.debounce'
import { useRecoilState } from 'recoil'

import { setSearchPhrase } from 'state/cloudSearch/selectors/setSearchPhrase'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { StyledTextField } from './Searchbar.styles'

export const Searchbar = () => {
  const { i18n } = useDictionary()
  const [value, setValue] = React.useState('')
  const [getPhrase, setPhrase] = useRecoilState(setSearchPhrase)
  const debouncedSetPhrase = React.useCallback(debounce(setPhrase, 500), [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setValue(inputValue)
    debouncedSetPhrase(inputValue)
  }

  React.useEffect(() => setValue(getPhrase), [])

  return (
    <StyledTextField
      placeholder={i18n.search.searchbarPlaceholder}
      color={'primary'}
      value={value}
      onChange={handleChange}
      size={'medium'}
      type={'search'}
      fullWidth={true}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'}>
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )
}
