import React from 'react'

import cn from 'classnames'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import styles from './Triangle.module.scss'
import { ITriangleProps } from './Triangle.types'

const Triangle = ({ light }: ITriangleProps) => {
  const { isMobile } = useBreakpoints()

  return (
    <div className={cn(styles.triangleWrapper, isMobile && styles.withMarginBottom)}>
      <div className={styles.triangle}>
        <div className={cn(styles.shadow, light && styles.light)} />
      </div>
      <div className={styles.fakeShadow} />
      <div className={styles.square} />
    </div>
  )
}

export default Triangle
