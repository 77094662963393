import _dictionary, { IModalDictionary } from './dictionary'

class ModalDictionary {
  private readonly regExpModal = /^@@modals\/([a-z0-9_]+)$/i
  private readonly regExpHash = /^#([a-z]+)$/i
  private readonly notFound = 'modalNotFound'
  private hashes: IModalDictionary = {}

  constructor(private dictionary: IModalDictionary) {
    this.invertKeys()
  }

  public invertKeys() {
    Object.keys(this.dictionary).forEach((modal) => {
      this.hashes[this.dictionary[modal]] = `@@modals/${modal}`
    })
  }

  public toHash(modalType: string): string {
    const modal = this.regExpModal.exec(modalType)

    if (modal) {
      const key = modal[1]

      if (Object.keys(this.dictionary).indexOf(key) >= 0) {
        return this.dictionary[key]
      }
    }

    return this.notFound
  }

  public fromHash(hash: string) {
    const modal = this.regExpHash.exec(hash)

    if (modal) {
      const key = modal[1]

      if (Object.keys(this.hashes).indexOf(key) >= 0) {
        return this.hashes[key]
      }
    }

    return null
  }
}

export default new ModalDictionary(_dictionary)
