import { filter as filter$, map as map$ } from 'rxjs/operators'

import _Store from '@Store'

import { getPools } from 'models/pools/actions'
import { isActionOf } from 'typesafe-actions'

import { resetLoading, setLoading } from './../actions'
import * as CONSTS from './../constants/constants'

export const setLoaderWhenPoolsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getPools.request)),
    map$(() => setLoading(CONSTS.POOLS))
  )
}

export const setLoaderWhenPoolsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) => isActionOf([getPools.success, getPools.failure], action)),
    map$(() => resetLoading(CONSTS.POOLS))
  )
}
