import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { getEvent } from 'models/event/selectors'
import { getIframeParams, getModule } from 'models/internalRouter/selectors'
import { setFinalPrice } from 'models/pools/actions'
import { getInsurancePrice, getSelectedTickets, getSummary } from 'models/pools/selectors'
import { setInsuranceSummaryData } from 'models/products/actions'
import { getProductsSummary, getSelected, getSelectedProducts } from 'models/products/selectors'
import { get as getShipments } from 'models/shipment/selectors'
import { EVENT } from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import { getTheme } from 'models/theme/selectors'
import getReceivedTicket from 'models/tickets/selectors/getReceivedTicket'

import Summary from './Summary.formik'
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types'

const mapStateToProps = (state: _Store.IState): ISummaryFromState => ({
  event: getEvent(state),
  isEmbed: !!getIframeParams(state),
  isLoading: getLoading(EVENT)(state),
  module: getModule(state),
  products: getSelected(state),
  receivedTickets: getReceivedTicket(state),
  selectedProducts: getSelectedProducts(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
  shipments: getShipments(state),
  summary: getSummary(state),
  summaryProducts: getProductsSummary(state),
  insurancePrice: getInsurancePrice(state),
  selectedExtendedSlot: null,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ISummaryFromDispatch => {
  return {
    setFinalPriceAction: (value) => dispatch(setFinalPrice(value)),
    setInsuranceSummaryData: (value) => dispatch(setInsuranceSummaryData(value)),
  }
}

export default connect<ISummaryFromState, ISummaryFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Summary)
