import React, { FC, useRef, useState } from 'react'

import cn from 'classnames'

import Chevron from 'components/reusable/Chevron'
import cancelClick from 'misc/helpers/cancelClick'
import useOutsideClick from 'misc/hooks/useOutsideClick'

import styles from './Dropdown.module.scss'
import { IDropdownProps } from './Dropdown.types'

const Dropdown: FC<IDropdownProps> = ({ button, chevron, className, content, isCityDropdown }) => {
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleOpen = () => setOpen(!open)

  const closeDropdown = () => {
    setOpen(false)
  }

  if (isCityDropdown) {
    useOutsideClick(dropdownRef, open, closeDropdown)
  }

  return (
    <div className={cn(className, styles.dropdown)} ref={dropdownRef}>
      {React.cloneElement(button, {
        onClick: handleOpen,
      })}
      <div
        className={cn(styles.content, isCityDropdown && styles.cityDropdown, open && styles.open)}
        onClick={cancelClick}
        onTouchEnd={cancelClick}
      >
        {React.cloneElement(content, {
          key: Date.now(),
          onClick: closeDropdown,
        })}
      </div>
      {chevron && <Chevron isActive={open} open={handleOpen} />}
    </div>
  )
}

export default Dropdown
