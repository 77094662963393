import { createSelector } from 'reselect'

import _Store from '@Store'

import { PaymentMethods } from 'constants/PaymentMethods'
import getCommonObjectFromArrays from 'misc/helpers/getCommonObjectFromArrays'
import { getEvent } from 'models/event/selectors'
import { IEventFull } from 'models/event/types'
import { getSelectedTickets } from 'models/pools/selectors'
import { IPaymentMethod } from 'types/Pools'

import { IPaymentMethodsReducer, IPoolsSelectedTicketsReducer } from './../types'
import getPaymentMethods from './getPaymentMethods'

const eCardEvents = [3219347, 3219588, 3219587, 3219586]

const getAvailablePaymentMethods = createSelector<
  _Store.IState,
  IPaymentMethodsReducer,
  IPoolsSelectedTicketsReducer,
  IEventFull | null,
  IPaymentMethod[]
>([getPaymentMethods, getSelectedTickets, getEvent], (payment, selectedTickets, event) => {
  const eCardMethod: IPaymentMethod[] = eCardEvents.some((id) => id === event?.eventId)
    ? [{ type: PaymentMethods.ECARD }]
    : []

  if (selectedTickets.length) {
    return getCommonObjectFromArrays(
      selectedTickets.map((ticket) => [...(ticket.paymentMethods || []), ...eCardMethod])
    )
  }

  return [...payment.paymentMethods, ...eCardMethod]
})

export default getAvailablePaymentMethods
