import { createSelector } from 'reselect'

import _Store from '@Store'

import { ICardCredentials, ITransactionReducer } from 'models/transaction/types'

import get from './get'

const getCardCredentials = createSelector<
  _Store.IState,
  ITransactionReducer,
  ICardCredentials | undefined
>([get], (transaction) => transaction.cardCredentials || undefined)

export default getCardCredentials
