import { Fetcher } from 'services/Api/Fetcher'
import { URI } from 'services/URI'
import { IReturnAccountBankPayload, IReturnAccountBankResponse } from 'types/ReturnAccountBank'

export class ReturnBankAccountApi {
  public static saveBankAccountNumber = async (
    hash: string,
    payload: IReturnAccountBankPayload
  ): Promise<IReturnAccountBankResponse> => {
    return await Fetcher.post(URI.returnBankAccountNumber(hash), JSON.stringify(payload))
  }
}
