import React, { useEffect, useState } from 'react'

import { ConfirmationNumberOutlined, Event, EventBusyOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { Route, routes } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import classes from 'components/Rundate/Rundate.classes'
import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { BannerLink } from 'components/reusable/BannerLink'
import { BuyButton } from 'components/reusable/BuyButton'
import { CardDeck } from 'components/reusable/CardDeck'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import { DateTimePlace } from 'components/reusable/DateTimePlace'
import DeepLinkModal from 'components/reusable/DeepLinkModal'
import Description from 'components/reusable/Description'
import { PlaceCard } from 'components/reusable/PlaceCard'
import { SellerInfoButton } from 'components/reusable/SellerInfoButton'
import { YouTube } from 'components/reusable/YouTube'
import config from 'config'
import isHTML from 'misc/helpers/isHTML'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import mobile_banner from 'misc/styles/images/event_baner_page_breaker_810.png'
import desktop_banner from 'misc/styles/images/event_baner_page_breaker_1280x264.png'
import { DataMutations } from 'services/DataMutations'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { FavoritesTypes } from '../../constants/Favorites'
import { useAppState } from '../../state/app/hooks/useAppState'
import { getServiceFeeTest } from '../../state/remoteConfig/selectors/getServiceFeeTest'
import { EntityRelatedArticles } from '../EntityRelatedArticles'
import { useFavoritesContext } from '../favorites/FavoritesProvider'
import { useEditFavorites } from '../favorites/FavoritesProvider/useEditFavorites'
import Gallery from './../reusable/Gallery'
import { IRundateProps } from './Rundate.types'
import { RundateArtists } from './components/EventArtists'
import { Pools } from './components/Pools'

export const Rundate = ({
  eventData: { rundate, pools },
  similarEvents,
  placeEvents,
  cloudinaryImages,
  relatedArticlesData,
  isArticlesLoading,
}: IRundateProps) => {
  const { event, slug, externalImages, redirectToUrl, changeMonitorType } = rundate
  const { isDesktop, isMobile, isTablet } = useBreakpoints()
  const { i18n } = useDictionary()
  const [key, setKey] = useState<string | null>(null)
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isRundateInFavs = isItemInFavs(rundate.id, FavoritesTypes.Rundates)

  /** TODO: remove when service fee test is over */
  const { isWebview } = useAppState()
  const [serviceFeePriceTest, setServiceFeePriceTest] = useState<
    typeof rundate.priceWithServiceFee
  >(rundate.priceWithServiceFee)
  useEffect(() => {
    const feeTest = getServiceFeeTest({
      rundateID: rundate.id,
      price: rundate.price,
      priceWithServiceFee: rundate.priceWithServiceFee,
      isWebview,
    })
    if (feeTest.isCustomFee) setServiceFeePriceTest(feeTest.priceWithServiceFee)
  }, [rundate.id, rundate.price, rundate.priceWithServiceFee, isWebview])
  /** remove when service fee test is over */

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && (event.code === 'KeyE' || event.code === 'KeyF')) setKey(event.code)
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.altKey || event.code === 'KeyE' || event.code === 'KeyF') setKey(null)
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const onImageClick = () => {
    let url = ''
    if (key === 'KeyE') {
      url = `${config.app.cmsUrl}events/events/update/${rundate.event.id}`
    } else if (key === 'KeyF') {
      url = `${config.app.newCmsUrl}/edytuj-wydarzenie/${rundate.event.id}`
    }
    if (url) window.open(url, '_blank')
    setKey(null)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TwoColumnsLayout
        onImageClick={onImageClick}
        boxComponent={
          <>
            <DateTimePlace
              rundateDescription={rundate.rundateDescription}
              hourDescription={rundate.hourDescription}
              startDate={rundate.startDate}
              runDate={rundate.runDate}
              endDate={rundate.endDate}
              place={
                rundate.place
                  ? {
                      name: `${rundate.place.name}, ${rundate.place.city.name}`,
                      slug: rundate.place.slug,
                    }
                  : undefined
              }
            />
            {!!changeMonitorType?.name && <Chip color={'primary'} label={changeMonitorType.name} />}
            <Box sx={{ mt: 4 }} />
            <ListItem disableGutters disablePadding>
              <ListItemIcon children={<ConfirmationNumberOutlined />} />
              <ListItemText
                primaryTypographyProps={{ variant: 'h5' }}
                primary={DataMutations.friendlyPrice({
                  price: serviceFeePriceTest,
                  currency: rundate.currency,
                  priceDescription: rundate.priceDescriptionPL,
                  prefix: i18n.price.from,
                })}
                secondary={
                  rundate.priceDescriptionPL !== 'TBA' && !!serviceFeePriceTest && !!rundate.price
                    ? i18n.event.serviceFeeCaption(
                        DataMutations.formatServiceFeePrice(
                          // TODO: this value should be in the API response
                          serviceFeePriceTest - rundate.price,
                          rundate.currency
                        )
                      )
                    : undefined
                }
                style={{ flex: 'unset' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>

            <Flex gap={3} direction={'column'} sx={{ mt: 2 }}>
              {!event.partner.vatPayer ? (
                <>
                  <Divider flexItem sx={{ display: { md: 'none' } }} />
                  <SellerInfoButton partnerInfo={event.partner} />
                </>
              ) : (
                <>
                  <Divider flexItem sx={{ display: { md: 'none' } }} />
                  <Typography variant={'subtitle2'}>
                    {i18n.event.goingSeller}
                  </Typography>
                </>
              )}
              {isDesktop && (
                <>
                  <BuyButton
                    eventSlug={event.slug}
                    rundateSlug={slug}
                    redirectToUrl={redirectToUrl}
                    queue={rundate.queue}
                    disabled={!rundate.isAvailable}
                    label={rundate.buttonLabel}
                  />
                  {config.features.userLogin && (
                    <Button
                      fullWidth={true}
                      variant={'outlined'}
                      children={
                        isRundateInFavs
                          ? i18n.following.rundateRemove
                          : i18n.following.rundateHeader
                      }
                      startIcon={isRundateInFavs ? <EventBusyOutlined /> : <Event />}
                      onClick={handleEditFavorites({
                        isFollowed: isRundateInFavs,
                        type: FavoritesTypes.Rundates,
                        externalId: rundate.id,
                        itemName: rundate.event.title,
                      })}
                    />
                  )}
                </>
              )}
            </Flex>
          </>
        }
        pageTitle={rundate.event.title}
        tags={[rundate.event.category, ...rundate.event.tags]}
        image={
          externalImages?.[0] ||
          event.externalImages?.[0] ||
          cloudinaryImages?.[0] ||
          event.images[0]?.large
        }
      >
        {event.description && (
          <Description
            text={event.description}
            isVideo={!!event.teaser}
            html={isHTML(event.description) ? event.description : undefined}
          />
        )}
        {event.artists && event.artists.length > 0 && config.theme.isGoing && (
          <SectionContainer title={i18n.event.participatingArtists}>
            <RundateArtists artists={event.artists} />
          </SectionContainer>
        )}
        {event.formattedPartnerDescription && !config.theme.isEmpik && (
          <SectionContainer title={i18n.event.additionalDescriptionTitle}>
            <Description
              text={event.formattedPartnerDescription}
              html={
                isHTML(event.formattedPartnerDescription)
                  ? event.formattedPartnerDescription
                  : undefined
              }
            />
          </SectionContainer>
        )}
        <SectionContainer disabled={!event.teaser} container={true}>
          <YouTube source={event.teaser} />
        </SectionContainer>
        <SectionContainer
          disabled={!!redirectToUrl || !pools.pools.length}
          title={i18n.event.tickets}
        >
          <Pools data={pools} rundateSlug={slug} eventSlug={event.slug} rundateID={rundate.id} />
        </SectionContainer>
        <SectionContainer title={i18n.event.place}>
          <PlaceCard data={rundate.place} />
        </SectionContainer>
      </TwoColumnsLayout>
      {!config.theme.isEmpik && (
        <BannerLink
          alt={i18n.promotionBanner.altText}
          className={classes.gaClass}
          id={classes.gaClass}
          mobileImage={mobile_banner}
          image={desktop_banner}
          to={routes[Route.about]}
          useDropShadow
        />
      )}
      <SectionContainer
        disabled={!(!!event.externalImages && event.externalImages.length > 1)}
        title={i18n.event.gallery}
      >
        <Gallery photos={event.externalImages} isLoading={false} vertical={true} />
      </SectionContainer>
      <SectionContainer
        title={i18n.event.samePlaceEventsTitle}
        disabled={!placeEvents || !placeEvents.length}
      >
        <CardDeck>
          {placeEvents?.map((item) => (
            <CloudSearchCard key={item.id} item={item} />
          ))}
        </CardDeck>
      </SectionContainer>
      <SectionContainer
        title={i18n.event.similarEventsTitle}
        disabled={!similarEvents || !similarEvents.length}
      >
        <CardDeck>
          {similarEvents.map((item) => (
            <CloudSearchCard key={item.id} item={item} />
          ))}
        </CardDeck>
      </SectionContainer>
      {config.theme.isGoing && (
        <EntityRelatedArticles
          isArticlesLoading={isArticlesLoading}
          relatedArticlesData={relatedArticlesData}
        />
      )}
      {(isMobile || isTablet) && config.theme.isGoing && (
        <DeepLinkModal
          url={prepareRoute({
            route: Route.rundate,
            params: { eventSlug: event.slug, rundateSlug: slug },
          })}
        />
      )}
    </Box>
  )
}
