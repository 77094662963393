import React, { useEffect } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { PageWrapper } from 'components/_layout/PageWrapper'
import config from 'config'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './../styles/errors.module.scss'
import useStyles from './../styles/errors.styles'
import { IError404Props } from './Error404.types'

const Error404 = ({ informAboutError }: IError404Props) => {
  const { i18n } = useDictionary()
  const { pathname } = useLocation()
  useEffect(() => {
    informAboutError()
  }, [])

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  return (
    <PageWrapper>
      <Helmet>
        <meta name={'prerender-status-code'} content={'404'} />
      </Helmet>
      <div
        className={cn(styles.error, muiStyles.root, config.theme.isEmpik && styles.withApostrophe)}
      >
        <div className={styles.content}>
          <h2>{i18n.errorModals.errorCode}</h2>
        </div>
        <div className={styles.tryAgain}>
          {i18n.errorModals.notFound}
          <br />
          {i18n.errorModals.question} <strong>{pathname}</strong> ?
        </div>
      </div>
    </PageWrapper>
  )
}

export default Error404
