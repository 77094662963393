import React from 'react'

import moment from 'moment'

import SimpleLoading from 'components/reusable/SimpleLoading'
import { StorageKeys } from 'constants/StorageKeys'
import { IBasket } from 'models/basket/types'
import { LocalStorage } from 'services/LocalStorage'

import styles from './Spaces.module.scss'
import { IAvailableSpaceWithDescription, ISpacesProps } from './Spaces.types'
import Space from './components/Space'

const Spaces = ({ spaces, selected }: ISpacesProps) => {
  const basketItems: IBasket[] = LocalStorage.get(StorageKeys.BasketItems) ?? []

  const normalizedSpaces: IAvailableSpaceWithDescription[] = []
  spaces.forEach((space) => {
    const selectedDay = moment(selected.day).format('YYYY-MM-DD')
    basketItems.some(
      (basket) =>
        basket.spaceId === space.spaceId &&
        moment(basket.date).format('YYYY-MM-DD') === selectedDay &&
        selected.slot === basket.slot
    )
      ? normalizedSpaces.push({ ...space, available: false })
      : normalizedSpaces.push(space)
  })

  if (!spaces) {
    return <SimpleLoading />
  }

  return (
    <ul className={styles.spaces}>
      {normalizedSpaces.map((space, index) => (
        <Space key={index} space={space} />
      ))}
    </ul>
  )
}

export default Spaces
