const aws = {
  fuzzySearchLetters: 3,
  fuzzySearchLevel: 0.2,
  facets: [
    {
      name: 'facet_public_tags_names',
    },
    {
      name: 'public_tags_ids',
    },
    {
      name: 'event_category_id',
      size: 18,
    },
    {
      name: 'facet_event_category',
      size: 18,
    },
    {
      name: 'facet_event_category_slug',
      size: 18,
    },
  ],
  searchFields: [
    { name: 'title_pl', boost: 10 },
    { name: 'description_pl', boost: 1 },
    { name: 'event_category_name', boost: 2 },
    { name: 'place_name', boost: 1 },
    { name: 'city_name', boost: 2 },
    { name: 'locations_names', boost: 1 },
    { name: 'partner_name', boost: 2 },
    { name: 'artists_names', boost: 7 },
    { name: 'private_tags_names', boost: 1 },
    { name: 'public_tags_names', boost: 1 },
  ],
  searchHighlights: [
    { name: 'event_category_name', maxPhrases: 3 },
    { name: 'place_name', maxPhrases: 3 },
    { name: 'city_name', maxPhrases: 3 },
    { name: 'locations_names', maxPhrases: 3 },
    { name: 'partner_name', maxPhrases: 3 },
    { name: 'artists_names', maxPhrases: 3 },
    { name: 'private_tags_names', maxPhrases: 3 },
    { name: 'public_tags_names', maxPhrases: 3 },
  ],
  searchHighlightsCount: 3,
  searchHighlightsTags: {
    post: '<e>',
    pre: '<s>',
  },
  searchSuggestListCount: 5,
  searchPaginationPageSize: 5,
  searchSuggestListFields: ['title_pl', 'square_image'],
  sortPriorityCityId: 1,
  sorting: [
    { priority: 1, field: 'rundate_not_for_sale', sort: 'asc' },
    { priority: 2, field: 'partner_premium_level', sort: 'desc' },
    { priority: 3, field: 'place_premium_level', sort: 'desc' },
    { priority: 4, field: '_score', sort: 'desc' },
    { priority: 5, field: 'rundate_rundate', sort: 'asc' },
    { priority: 6, field: 'city_id', sort: 'asc' },
  ],
  emptyPhraseSorting: [
    { priority: 1, field: 'calendar_event', sort: 'asc' },
    { priority: 2, field: 'rundate_not_for_sale', sort: 'asc' },
    { priority: 3, field: 'partner_premium_level', sort: 'desc' },
    { priority: 4, field: 'place_premium_level', sort: 'desc' },
    { priority: 5, field: '_score', sort: 'desc' },
    { priority: 6, field: 'rundate_rundate', sort: 'asc' },
    { priority: 7, field: 'city_id', sort: 'asc' },
  ],
}

export default aws
