import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingBottom: `0!important`,
      paddingTop: `${theme.spacing(4)}px!important`,
    },
    fontColor: {
      color: theme.palette.text.primary,
    },
    imageWrapper: {
      '&>div': {
        borderRadius: `${theme.border.radius} ${theme.border.radius} 0 0`,
      },
    },
    infoBox: {
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    lineThrough: {
      textDecoration: 'line-through',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    rootDefault: {
      paddingTop: `${theme.spacing(3)}px !important`,
    },
    insuranceShipment: {
      paddingTop: '0.75rem',
    },
    title: {
      padding: `0 ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    withoutSidePaddings: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
    divider: {
      marginTop: '2rem',
    },
    dividerPrimary: {
      backgroundColor: theme.palette.divider,
    },
  })
)

export default useStyles
