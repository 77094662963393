import axios, { CancelTokenSource } from 'axios'

import { ICard } from 'components/reusable/Card/Card.types'
import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import makeFriendlyDate from 'misc/helpers/makeFriendlyDate'
import prepareHotSpotUri from 'misc/helpers/prepareHotSpotsUri'
import { PagesEnum } from 'models/pages/constants/pages'
import {
  IComponentExtraProps,
  ISectionsComponentExtendedHotSpot,
} from 'models/pages/types/sections'

import { ICompositionResponse } from './types'

class SectionsApi {
  private static getSectionsUrl(
    page: PagesEnum,
    slug: string,
    groupSlug?: string,
    email?: string | null,
    entryToken?: string | null,
    version?: string
  ): string {
    const authParams = email && entryToken ? `?email=${email}&entryToken=${entryToken}` : ''
    const isAuthParams = authParams.length > 0
    const versionParam = version ? `${isAuthParams ? '&' : '?'}version=${version}` : ''
    const formattedSlug = slug && slug !== 'undefined' ? slug : ''

    if (groupSlug) {
      return `${config.api.baseUrlV2}page/${config.app.serviceName}/${PagesEnum[page]}/${groupSlug}/${formattedSlug}${authParams}${versionParam}`
    }

    return `${config.api.baseUrlV2}page/${config.app.serviceName}/${PagesEnum[page]}/${formattedSlug}${authParams}${versionParam}`
  }

  private cancelTokenSections?: CancelTokenSource

  public getSections(
    page: PagesEnum,
    slug: string,
    groupSlug?: string,
    email?: string | null,
    entryToken?: string | null,
    version?: string
  ): Promise<ICompositionResponse> {
    return new Promise((resolve, reject) => {
      const sectionUri = SectionsApi.getSectionsUrl(
        page,
        slug,
        groupSlug,
        email,
        entryToken,
        version
      )

      this.cancelTokenSections = axios.CancelToken.source()

      axios
        .get(sectionUri)
        .then(getData)
        .then((response: ICompositionResponse) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelSections() {
    if (this.cancelTokenSections) {
      this.cancelTokenSections.cancel()
      this.cancelTokenSections = undefined
    }
  }

  public normalizeElementsToCardData(
    hotSpotsData: ISectionsComponentExtendedHotSpot[],
    extra?: IComponentExtraProps
  ): ICard[] {
    const cards: ICard[] = []

    hotSpotsData.map((card) =>
      cards.push({
        badge: card.badge,
        ctaButton: card.ctaButton,
        date: card.dateDesc ? card.dateDesc : makeFriendlyDate(card.date),
        description: extra?.disableDescription ? null : card.description,
        link: prepareHotSpotUri(card),
        place: extra?.disablePlace ? null : card.place,
        placeSlug: card.placeSlug || null,
        secondaryCtaButton: card.secondaryCtaButton,
        subtitle: card.subtitle || null,
        thumb: card.thumbUrl || null,
        title: extra?.disableTitle ? null : card.title,
      })
    )

    return cards
  }
}

export default new SectionsApi()
