import React from 'react'

import cn from 'classnames'

import useArrowsSlider from 'misc/hooks/useArrowsSlider'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import styles from './ArtistSlider.module.scss'
import { IArtistSliderProps } from './ArtistSlider.types'
import ArtistTile from './components/ArtistTile'

const ArtistSlider = ({ artistSection, componentTitle, isDark, id }: IArtistSliderProps) => {
  const { isDesktop, isMobile } = useBreakpoints()
  const isCentered =
    (!isMobile && artistSection.length < 4) || (isMobile && artistSection.length < 3)

  const artistList = artistSection.map((artist, i) => (
    <ArtistTile
      tile={artist}
      isDark={isDark}
      key={i.toString()}
      sliderId={id}
      componentTitle={componentTitle}
    />
  ))

  if (!!artistList.length && typeof window !== 'undefined') {
    const { containerRef, listRef, load, next, previous, showNext, showPrevious, useNativeScroll } =
      useArrowsSlider(artistSection.length, isDesktop, 28, 24)

    return (
      <div className={styles.artistSliderContainer} ref={containerRef}>
        <ul
          className={cn(
            styles.artistSlider,
            isCentered && styles.centered,
            useNativeScroll && styles.nativeScroll
          )}
          ref={listRef}
        >
          {load && artistList}
        </ul>
        {showPrevious && isDesktop && (
          <div className={cn(styles.arrow, styles.left)} onClick={previous} />
        )}
        {showNext && isDesktop && <div className={cn(styles.arrow, styles.right)} onClick={next} />}
      </div>
    )
  }

  return (
    <div className={styles.artistSliderContainer}>
      <ul className={styles.artistSlider}>{artistList}</ul>
    </div>
  )
}

export default ArtistSlider
