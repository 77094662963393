import React, { FC } from 'react'

import cn from 'classnames'

import FormField from 'components/formik/FormField'

import styles from './AuthId.module.scss'
import { IAuthIdProps } from './AuthId.types'

const ID_TEXT = 'Identyfikator'
const PROVIDE_YOUR_DATA_TEXT = 'Podaj dane do autoryzacji (ID: login / email)'
const WE_WILL_SEND_BOUGHT_TICKETS_TO_GIVEN_ID_TEXT =
  'Podany identyfikator zostanie użyty jako unikalny identyfikator sprzedawcy'

const AuthId: FC<IAuthIdProps> = ({ isDisabled, number }) => (
  <div className={cn(styles.clientData, isDisabled && styles.disabled)}>
    <h3 className={styles.header} data-number={number}>
      {PROVIDE_YOUR_DATA_TEXT}
    </h3>
    <fieldset className={styles.formPanel}>
      <label className={styles.label} htmlFor="authId">
        <span>{WE_WILL_SEND_BOUGHT_TICKETS_TO_GIVEN_ID_TEXT}</span>
        <FormField id="authId" name="authId" placeholder={ID_TEXT} type="text" />
      </label>
    </fieldset>
  </div>
)

export default AuthId
