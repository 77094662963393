import React, { useContext, useEffect } from 'react'

import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { IBasketProps } from 'components/Basket/Basket.types'
import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import BasketButton from 'components/Basket/components/BasketButton'
import getBasketFormattedPools from 'misc/helpers/getBasketFormattedPools'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import { IAvailableThemes } from 'models/theme/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './Basket.module.scss'
import useStyles from './Basket.styles'
import BasketEmpty from './components/BasketEmpty'
import BasketItem from './components/BasketItem'
import BasketPlaceItem from './components/BasketPlaceItem'

const Basket = ({
  updateBasketState,
  redirectParentTo,
  basketRedirect,
  basketTheme,
  setBasketTheme,
  isEmbed,
  clearSelectedHappening,
}: IBasketProps) => {
  const state = useContext(clearContext)
  const { i18n } = useDictionary()
  const classes = useStyles()

  const basketItems = state?.basketItems

  const finalPrice = state?.basketItems.length
    ? state?.basketItems
        .map((item) => item.price)
        .reduce((a, b) => {
          return a + b
        })
    : null

  const formattedItems = getBasketFormattedPools(basketItems)

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const redirect = () => {
    if (basketRedirect) {
      redirectParentTo(basketRedirect)
    }
  }

  useEffect(() => {
    if (basketItems) {
      updateBasketState(basketItems)
    }
  }, [basketItems])

  useEffect(() => {
    if (basketTheme) {
      setBasketTheme(basketTheme as IAvailableThemes)
    }
  }, [basketTheme])

  const removeTerms = () => {
    clearSelectedHappening()
  }

  return (
    <div className={cn(styles.basketWrapper, classes.spaceAround)}>
      <div className={styles.yourBasketWrapper}>
        <BasketButton iconClassName={styles.icon} itemsLengthClassName={styles.itemsLength} />
      </div>
      {!!state?.basketItems.length && (
        <>
          <div className={styles.content}>
            {formattedItems?.map((basketItem) =>
              basketItem.isPlaceEvent ? (
                <BasketPlaceItem basketItem={basketItem} />
              ) : (
                <BasketItem
                  basketItem={basketItem}
                  key={`basketItem-${basketItem.rundateId || basketItem.id}`}
                />
              )
            )}
            <Grid
              container={true}
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={3}
              className={classes.priceContainer}
            >
              <Grid item={true}>
                <Typography
                  variant={isTablet ? 'body1' : 'h5'}
                  color="textPrimary"
                  className={classes.fontMedium}
                >
                  {i18n.cart.toPay}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} alignItems={'flex-end'} spacing={1}>
                  <Grid item={true}>
                    <Typography variant={'h4'} color={'textPrimary'} className={classes.fontMedium}>
                      {getPriceFormatted(finalPrice)}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'h5'} color={'textPrimary'} className={classes.fontMedium}>
                      {formattedItems?.[0]?.currency || i18n.cart.localCurrency}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container={true} spacing={3} className={classes.root}>
            <Grid item={true} xs={12} md={6}>
              {basketRedirect ? (
                <Button
                  variant={'outlined'}
                  size={'large'}
                  color={'primary'}
                  disableElevation={true}
                  fullWidth={true}
                  onClick={redirect}
                  className={classes.basketButton}
                >
                  {i18n.cart.continueBuying}
                </Button>
              ) : (
                <Button
                  component={Link}
                  to={routes[Route.search]}
                  variant={'outlined'}
                  size={'large'}
                  color={'primary'}
                  disableElevation={true}
                  fullWidth={true}
                  className={classes.basketButton}
                >
                  {i18n.cart.continueBuying}
                </Button>
              )}
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Button
                component={Link}
                to={isEmbed ? routes[Route.pinCartBuy] : routes[Route.cartBuy]}
                variant={'contained'}
                size={'large'}
                color={'primary'}
                type={'submit'}
                disableElevation={true}
                fullWidth={true}
                className={classes.basketButton}
                onClick={removeTerms}
              >
                {i18n.cart.payText}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {!state?.basketItems.length && <BasketEmpty />}
    </div>
  )
}

export default Basket
