import React, { JSXElementConstructor, useContext, useEffect } from 'react'

import { Formik } from 'formik'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { PayUContext } from 'components/_functional/PayUContextProvider/PayUContextProvider.component'
import { PaymentMethods } from 'constants/PaymentMethods'
import getBasketTerms from 'misc/helpers/getBasketTerms'
import { IAvailableThemes } from 'models/theme/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { makeValidationSchema } from './../Online/Online.selectors'
import { IBuyingOnlineFormValues } from './../Online/Online.types'
import FormLayout from './../components/FormLayout'
import { IBasketProps } from './Basket.types'

const Basket = ({
  checkout,
  buyAndPay,
  initialValues,
  isDataHydratedTransaction,
  mounted,
  updateBasketState,
  cardPaymentForm,
  basketTheme,
  setBasketTheme,
  reserve,
}: IBasketProps) => {
  const { i18n } = useDictionary()
  const basketContext = useContext(clearContext)
  const { payuInstance, handleTokenizeCard } = useContext(PayUContext)

  const basketCustomTerms = basketContext?.basketItems
    ? getBasketTerms(basketContext?.basketItems)
    : []

  const validationSchema = makeValidationSchema(
    basketCustomTerms,
    i18n,
    checkout,
    !isDataHydratedTransaction
  )

  const catchSaveData = async (data: IBuyingOnlineFormValues) => {
    if (handleTokenizeCard && payuInstance) {
      const tokenize = await handleTokenizeCard(payuInstance)

      if (tokenize === 'ERROR') {
        return
      }
    }
    const normalizedData = {
      ...data,
      pass: '',
      phone: '',
      products: {},
      paymentMethod: { type: PaymentMethods.PAYU },
    }
    basketContext?.basketItems.some((item) => item.isPlaceEvent)
      ? reserve(normalizedData)
      : buyAndPay({
          basketItems: basketContext?.basketItems ? basketContext.basketItems : [],
          data,
          onDone: basketContext?.clearBasket ? basketContext.clearBasket : () => null,
        })
  }

  useEffect(() => {
    mounted()
  }, [])

  useEffect(() => {
    if (basketContext?.basketItems) {
      updateBasketState(basketContext.basketItems)
    }
  }, [basketContext?.basketItems])

  useEffect(() => {
    if (basketTheme) {
      setBasketTheme(basketTheme as IAvailableThemes)
    }
  }, [basketTheme])

  return (
    <Formik
      children={(props) => (
        <FormLayout
          {...props}
          isBasketPay={true}
          cardPaymentForm={cardPaymentForm}
          payuInstance={payuInstance}
        />
      )}
      initialValues={initialValues(basketCustomTerms)}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
      validateOnMount={false}
    />
  )
}

export default Basket as JSXElementConstructor<unknown>
