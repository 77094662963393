import { IFormioBrickOwnProps } from 'components/Bricks/components/FormioBrick/FormioBrick.types'
import {
  ISectionsComponentDataMutable,
  ISectionsComponentFormio,
} from 'models/pages/types/sections'

export const formio = (data: ISectionsComponentDataMutable): IFormioBrickOwnProps => {
  const formElements = data as ISectionsComponentFormio[]

  return {
    formSlug: formElements[0].formSlug,
  }
}
