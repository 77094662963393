import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { checkUserCardStatus } from 'models/premium/actions'
import { getUserCardStatus } from 'models/premium/selectors'

import EmpikCardNumber from './EmpikCardNumber.component'
import {
  IEmpikCardNumberFromDispatch,
  IEmpikCardNumberFromState,
  IEmpikCardNumberOwnProps,
} from './EmpikCardNumber.types'

const mapStateToProps = (state: _Store.IState): IEmpikCardNumberFromState => ({
  userCardStatus: getUserCardStatus(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IEmpikCardNumberFromDispatch => ({
  checkUserCardStatus: (cardNumber, email) => dispatch(checkUserCardStatus({ cardNumber, email })),
})

export default connect<
  IEmpikCardNumberFromState,
  IEmpikCardNumberFromDispatch,
  IEmpikCardNumberOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EmpikCardNumber)
