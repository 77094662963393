import { connect } from 'react-redux'

import _Store from '@Store'

import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import EmbedBrick from './EmbedBrick.component'
import { IEmbedBrickFromState, IEmbedBrickOwnProps } from './EmbedBrick.types'

const mapStateToProps = (state: _Store.IState): IEmbedBrickFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
})

export default connect<IEmbedBrickFromState, void, IEmbedBrickOwnProps, _Store.IState>(
  mapStateToProps
)(EmbedBrick)
