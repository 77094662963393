import React, { useState } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import styles from 'components/buying/Pools/components/Description/Description.module.scss'
import useStyles from 'components/buying/Pools/components/Description/Description.styles'
import { IDescriptionProps } from 'components/buying/Pools/components/Description/Description.types'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const Description = ({ text }: IDescriptionProps) => {
  const { i18n } = useDictionary()
  const [showDescription, setShowDescription] = useState(false)
  const { isMobile } = useBreakpoints()

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  const toggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setShowDescription(!showDescription)
  }

  return (
    <div className={cn(styles.description, muiStyles.description, muiStyles.overrideFlex)}>
      <button
        className={cn(
          muiStyles.primaryText,
          isMobile ? muiStyles.centerSelf : muiStyles.alignSelfLeft
        )}
        onClick={toggle}
      >
        {i18n.buy.pools.details}
        <i
          className={cn(styles.arrow, muiStyles.arrow, {
            [styles.down]: !showDescription,
            [styles.up]: showDescription,
          })}
        />
      </button>
      {showDescription && (
        <div
          className={cn(
            styles.descriptionText,
            muiStyles.secondaryText,
            isMobile && muiStyles.centerSelf
          )}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default Description
