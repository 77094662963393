import { Dispatch } from 'react'

import { connect } from 'react-redux'
import { AnyAction } from 'redux'

import _Store from '@Store'

import { setDay, setNumberOfPlayers } from 'models/happening/actions'
import { selectTicket } from 'models/pools/actions'
import getPoolsData from 'models/pools/selectors/getPoolsData'
import { selectProduct } from 'models/products/actions'
import { getTheme } from 'models/theme/selectors'
import { getTicketTransferFee } from 'models/tickets/selectors'

import Pools from './Pools.component'
import { IPoolsFromDispatch, IPoolsFromState } from './Pools.types'

const mapStateToProps = (state: _Store.IState): IPoolsFromState => ({
  isRundateWithSeatsIo: getPoolsData(state).seatsIoPublicKey,
  selectedTheme: getTheme(state),
  ticketTransferFee: getTicketTransferFee(state)?.price,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPoolsFromDispatch => {
  return {
    selectPriceType: (value) => dispatch(setNumberOfPlayers(value)),
    selectProduct: (product, amount) => dispatch(selectProduct({ product, amount })),
    selectTicket: (
      poolId,
      amount,
      poolName,
      currency,
      paymentMethods,
      price,
      serviceFee,
      additionalFields
    ) =>
      dispatch(
        selectTicket({
          additionalFields,
          amount,
          currency,
          paymentMethods,
          poolId,
          poolName,
          price,
          serviceFee,
        })
      ),
    setDay: (day) => dispatch(setDay(day)),
  }
}

export default connect<IPoolsFromState, IPoolsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Pools)
