import React from 'react'

import cn from 'classnames'

import classes from 'components/eventBoxes/Summary/Summary.classes'
import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import productIconEmpik from 'misc/styles/images/product-empik.svg'
import productIcon from 'misc/styles/images/product.svg'

import BasketItemPool from './../BasketItemPool'
import styles from './BasketItem.module.scss'
import useStyles from './BasketItem.styles'
import { IBasketItemProps } from './BasketItem.types'

const BasketItem = ({ basketItem, withoutPrice }: IBasketItemProps) => {
  const {
    id,
    title,
    hour,
    rundate,
    place,
    cityName,
    poolName,
    poolPrice,
    amount,
    serviceFee,
    price,
    pools,
    isProduct,
    paymentMethods: payments,
    currency,
  } = basketItem
  const { isDesktop } = useBreakpoints()
  const muiStyles = useStyles()

  return (
    <section
      key={id}
      className={cn(styles.section, withoutPrice && styles.withoutPrice, muiStyles.root)}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.container}>
          {isDesktop && (
            <>
              {!isProduct ? (
                <span className={cn(styles.ticketIcon, classes.ticketIcon)} />
              ) : (
                <img
                  src={config.theme.isEmpik ? productIconEmpik : productIcon}
                  className={styles.productIcon}
                />
              )}
            </>
          )}
          <div className={cn(styles.box, styles.firstBox, withoutPrice && styles.boxWithoutPrice)}>
            <div className={styles.titleWrapper}>
              <p className={styles.title}>{title}</p>
            </div>
            {place && cityName && (
              <div className={styles.placeContainer}>
                <p className={styles.rundate}>{hour ? `${rundate}, ${hour}` : rundate}</p>

                <p className={styles.place}>{`${place}, ${cityName}`}</p>
              </div>
            )}
          </div>
        </div>
        {!withoutPrice && (
          <div className={styles.box}>
            <div className={styles.boxWrapper}>
              <div className={styles.poolContainer}>
                <BasketItemPool
                  basketItemPool={{
                    amount,
                    id,
                    payments,
                    poolName,
                    poolPrice,
                    poolPriceWithFee: price,
                    serviceFee,
                    totalPoolPrice: price,
                    currency,
                  }}
                />
                {pools.map((pool) => {
                  return <BasketItemPool key={`pool-${pool.id}`} basketItemPool={pool} />
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default BasketItem
