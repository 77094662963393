import { User } from 'firebase/auth'

import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { IUpdateUserPayload, IUpdateUserResponse, IUserResponse } from 'types/User'

export class UserApi {
  public static getApiUser = async (firebaseUser: User | null) => {
    try {
      const token = await firebaseUser?.getIdToken()

      if (token) {
        const rawData = await fetch(URI.GetUser, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`,
          },
        })

        const data: IUserResponse = await rawData.json()

        if (!data.isSuccess) return null

        return data
      }

      return null
    } catch (error) {
      Logger.error('getApiUser', error)
    }
  }

  public static updateApiUser = async (body: IUpdateUserPayload, firebaseUser: User | null) => {
    try {
      const token = await firebaseUser?.getIdToken()

      const rawData = await fetch(URI.UpdateUser, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      })

      const data: IUpdateUserResponse = await rawData.json()

      return data
    } catch (error: any) {
      Logger.error('updateApiUser', error)

      return {
        isSuccess: false,
        errors: [error.message],
      }
    }
  }
}
