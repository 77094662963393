import React from 'react'

import { Box, Grid, Link, Toolbar } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import empikFotoImage from 'misc/styles/images/empik-foto.svg'
import empikGoImage from 'misc/styles/images/empik-go.svg'
import empikPremiumImage from 'misc/styles/images/empik-premium.svg'
import empikImage from 'misc/styles/images/empik.svg'

import useStyles from './EmpikBar.styles'

const EmpikBar = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container={true} direction={'row'} wrap={'nowrap'}>
          <Link className={classes.link} href={'https://www.empik.com'} target={'_blank'}>
            <img className={classes.image} src={empikImage} alt="empik" />
          </Link>
          <Link className={classes.link} href={'https://www.empik.com/premium'} target={'_blank'}>
            <img className={classes.image} src={empikPremiumImage} alt="empik-premium" />
          </Link>
          <Link className={classes.link} href={'https://www.empikfoto.pl'} target={'_blank'}>
            <img className={classes.image} src={empikFotoImage} alt="empik-foto" />
          </Link>
          <Link
            className={classes.link}
            href={'https://www.empik.com/abonamenty'}
            target={'_blank'}
          >
            <img className={classes.image} src={empikGoImage} alt="empik-go" />
          </Link>
        </Grid>

        <Box>
          <Link
            className={classes.textLink}
            href={'https://pomoc.empikbilety.pl/'}
            target={'_blank'}
            underline={'none'}
          >
            Pomoc
          </Link>
          <Link
            className={classes.textLink}
            component={NavLink}
            to={prepareRoute({
              route: Route.contact,
              params: { slug: 'formularz-kontaktowy' },
            })}
            underline={'none'}
          >
            Kontakt
          </Link>
        </Box>
      </Toolbar>
    </Box>
  )
}

export default EmpikBar
