import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { confirmPersonalData, handlePrevStep } from 'models/entryList/actions'
import { getFormValues } from 'models/entryList/selectors'

import Summary from './Summary.component'
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types'

const mapStateToProps = (state: _Store.IState): ISummaryFromState => ({
  formValues: getFormValues(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ISummaryFromDispatch => ({
  handlePrevStep: () => dispatch(handlePrevStep()),
  confirmPersonalData: () => dispatch(confirmPersonalData()),
})

export default connect<ISummaryFromState, ISummaryFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Summary)
