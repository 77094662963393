import React, { MouseEvent } from 'react'

import { ArrowRight } from '@mui/icons-material'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import cn from 'classnames'

import useStyles from 'components/Bricks/components/TourListBrick/TourList.styles'
import {
  ITourListElementProps,
  ITourListProps,
} from 'components/Bricks/components/TourListBrick/TourList.types'
import DualLink from 'components/reusable/DualLink'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import slugifyString from 'misc/helpers/slugifyString'
import translatableDate from 'misc/helpers/translatableDate'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import Analytics from 'services/$analytics'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const TourListElement = ({
  item,
  index,
  dataLength,
  compositionIdentifier,
  isMobile,
  muiStyles,
  brickId,
  onClick,
}: ITourListElementProps) => {
  const { i18n } = useDictionary()
  const startDate = translatableDate(item.startDate, undefined, i18n.dates)
  const endDate = translatableDate(item.endDate, undefined, i18n.dates)
  const isDate = item.startDate && item.endDate
  const isDivider = index < dataLength - 1

  return (
    <Grid spacing={6} container={true} item={true}>
      <Grid
        spacing={6}
        item={true}
        container={true}
        className={muiStyles.item}
        alignItems={'center'}
      >
        <Grid
          item={true}
          container={true}
          className={muiStyles.stretch}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item={true} className={muiStyles.stretch}>
            <Typography variant={'h4'} children={item.title} className={cn(muiStyles.fontColor)} />
          </Grid>
          <Grid item={true} className={muiStyles.stretch}>
            <Typography
              variant={'body1'}
              children={isDate ? `${startDate} - ${endDate}` : 'TBA'}
              className={cn(muiStyles.fontColor)}
            />
          </Grid>
        </Grid>
        <Grid item={true}>
          <DualLink
            to={item.link ? item.link : ''}
            className={`${compositionIdentifier}-${brickId}-${slugifyString(item.title)}`}
            onClick={onClick(item.link, item.title)}
          >
            <IconButton disabled={!item.link}>
              <ArrowRight color={'primary'} />
            </IconButton>
          </DualLink>
        </Grid>
      </Grid>
      {isDivider && (
        <Grid item={true} className={muiStyles.stretch}>
          <Divider className={muiStyles.divider} />
        </Grid>
      )}
    </Grid>
  )
}

const TourList = ({ data, color, bgColor, compositionIdentifier, id }: ITourListProps) => {
  const { isDarkTheme } = useAppState()
  const isBgDark =
    isColorDark(bgColor) ||
    isColorDark(isDarkTheme && bgColor ? invertIfGrayScaleColor(bgColor) : bgColor)
  const { isMobile } = useBreakpoints()
  const muiStyles = useStyles(isBgDark, color, isMobile)()

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(e, link, textDisplayed || '', componentName)
        : undefined

  return (
    <Grid spacing={6} container={true} direction={'column'} className={muiStyles.root}>
      {data.map((item, index) => (
        <TourListElement
          item={item}
          index={index}
          isMobile={isMobile}
          compositionIdentifier={compositionIdentifier}
          muiStyles={muiStyles}
          dataLength={data.length}
          brickId={id}
          onClick={handleAddToDataLayerOnClick}
        />
      ))}
    </Grid>
  )
}

export default TourList
