import { PagesEnum } from 'models/pages/constants/pages'

// test pipeline

const app = {
  aboutAppUrl:
    'https://goingapp.pl/o-aplikacji?utm_source=empikbilety&utm_medium=link&utm_campaign=typ-button',
  airbrakeId: parseInt(process.env.REACT_APP_AIRBRAKE_ID || '', 10),
  airbrakeKey: process.env.REACT_APP_AIRBRAKE_KEY || '',
  baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost',
  cmsUrl: 'https://web.goingapp.pl/backend/going/',
  deepLinkApp: process.env.REACT_APP_DEEP_LINK,
  defaultCurrency: 'PLN',
  expoAppUrl: 'https://going-expo-app.netlify.app',
  descriptionsUrl: 'https://descriptions.goingapp.pl/',
  entryListUrl: process.env.REACT_APP_FORMIO_ENTRY_LIST_URL,
  formIoId: process.env.REACT_APP_FORMIO_ID,
  goingTestPartnerId: 1381,
  howOldCanBeStaticPage: 60 * 60 * 24 * 1000, // one day in milliseconds
  isProduction: process.env.REACT_APP_PRODUCTION,
  landingPageUrl: 'https://4km187qefb.execute-api.eu-central-1.amazonaws.com/mock/',
  mainpage: process.env.REACT_APP_MAINPAGE || '',
  mainpageCategory: Object.keys(PagesEnum)
    .filter((k) => isNaN(Number(k)))
    .indexOf(process.env.REACT_APP_MAINPAGE_CATEGORY || 'mainpage') as PagesEnum,
  newCmsUrl: 'https://panel.goingapp.pl',
  onlineSale: /true/i.test(process.env.REACT_APP_ONLINE_SALE as string),
  payUSdkUrl: process.env.REACT_APP_PAYU_SDK_URL || 'https://secure.snd.payu.com/javascript/sdk',
  productionUrl: process.env.REACT_APP_PROD_URL || 'https://goingapp.pl',
  queueUrl: 'https://queue.goingapp.pl',
  salesAgent: process.env.REACT_APP_SALES_AGENT!, // TODO: set default
  salesChannelId: parseInt(process.env.REACT_APP_SALES_CHANNEL_ID || '11', 10),
  serviceName: process.env.REACT_APP_SERVICE_NAME || 'empik-bilety',
  showUnfinishedFeatures: process.env.REACT_APP_UNFINISHED_FEATURE,
  siteIndexation: /true/i.test(process.env.REACT_APP_SITE_INDEXATION as string),
  styleExtensionsUrl:
    process.env.REACT_APP_STYLE_EXTENSIONS_URL ||
    'https://embed-config-meqesdpgvc.s3-eu-west-1.amazonaws.com/style-extensions/main.css',
  zagrywkiProductsAuthToken: process.env.REACT_APP_ZAGRYWKI_PRODUCTS_TOKEN!, // TODO: set default
}

export default app
