import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { ICategoryFilterState } from 'types/Search'

export const initialState: ICategoryFilterState = null

export const categoryFilterState = atom<ICategoryFilterState>({
  default: initialState,
  key: RecoilKeys.categoryFilter,
})
