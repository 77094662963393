import axios, { CancelTokenSource } from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import {
  ICheckSubmissionsExistsResponse,
  IDataFromTicketPayloadProps,
  IRegisterNewSubmissionRequestPayload,
  ISubmissionAttachSuccessPayload,
} from 'models/entryList/types'

import {
  ICheckAgreementsExistsParams,
  ISendSmsCodePayloadProps,
  ISubmissionToAttachProps,
} from './types'

class FormIoApi {
  private static getFormIoUserWhenExistUrl = () => `${config.api.formioUrl}submissions-user-forms`

  private static getDataFromTicketUrl = () => `${config.api.formioUrl}submissions`

  private static sendSmsCodeUrl = () => `${config.api.smsGateApi}`

  private static registerNewSubmissionUrl = () => `${config.api.formioUrl}form-hook`

  private static attachSubmissionUrl = () => `${config.api.formioUrl}submissions-attach`

  public cancelTokenList?: CancelTokenSource

  public getFormIoUserWhenExist(
    data: ICheckAgreementsExistsParams,
    partnerFormId: string
  ): Promise<ICheckSubmissionsExistsResponse> {
    return new Promise<ICheckSubmissionsExistsResponse>((resolve, reject) => {
      axios
        .get(
          FormIoApi.getFormIoUserWhenExistUrl(),
          withCacheHeader({
            params: {
              formId: partnerFormId,
              ...data,
            },
          })
        )
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response[0])
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public registerNewSubmission(data: IRegisterNewSubmissionRequestPayload): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.cancelTokenList = axios.CancelToken.source()

      axios
        .post(FormIoApi.registerNewSubmissionUrl(), data, {
          cancelToken: this.cancelTokenList.token,
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public attachSubmissionToTicket(
    data: ISubmissionToAttachProps
  ): Promise<ISubmissionAttachSuccessPayload> {
    return new Promise<ISubmissionAttachSuccessPayload>((resolve, reject) => {
      this.cancelTokenList = axios.CancelToken.source()
      axios
        .post(FormIoApi.attachSubmissionUrl(), data, {
          cancelToken: this.cancelTokenList.token,
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => catchHttpError(error))
    })
  }

  public sendSmsCode(data: ISendSmsCodePayloadProps): Promise<undefined> {
    return new Promise<undefined>((resolve, reject) => {
      axios
        .post(FormIoApi.sendSmsCodeUrl(), JSON.stringify(data))
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public getDataFromTicket(
    token: string,
    partnerFormId: string
  ): Promise<IDataFromTicketPayloadProps> {
    return new Promise<IDataFromTicketPayloadProps>((resolve, reject) => {
      this.cancelTokenList = axios.CancelToken.source()

      axios
        .get(
          FormIoApi.getDataFromTicketUrl(),
          withCacheHeader({
            cancelToken: this.cancelTokenList.token,
            params: {
              formId: partnerFormId,
              entryToken: token,
            },
          })
        )
        .then(getData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  public cancelEvent() {
    if (this.cancelTokenList) {
      this.cancelTokenList.cancel()
      this.cancelTokenList = undefined
    }
  }
}

export default new FormIoApi()
