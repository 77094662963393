import * as React from 'react'

import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { INotification } from 'components/_functional/NotificationProvider/NotificationProvider.types'
import { notificationQueueState } from 'state/notification/atoms/notificationQueue'
import { getNotification } from 'state/notification/selectors/getNotification'

export const useNotify = () => {
  const setQueue = useSetRecoilState(notificationQueueState)

  return {
    notify: (notification: INotification) => setQueue((prev) => [...prev, notification]),
  }
}

export const NotificationProvider = () => {
  const [queue, setQueue] = useRecoilState(notificationQueueState)
  const notification = useRecoilValue(getNotification)

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (queue.length > 0) setQueue((prev) => prev.slice(1))
    }, 5000)

    return () => clearInterval(timer)
  }, [queue, setQueue])

  const handleClose = () => setQueue((prev) => prev.slice(1))

  return (
    <Snackbar open={!!notification} autoHideDuration={4000} onClose={handleClose}>
      <Alert
        elevation={6}
        variant={'filled'}
        onClose={handleClose}
        severity={notification?.alertColor}
      >
        {notification?.text}
        {notification?.action && (
          <Button
            color={'inherit'}
            size={'small'}
            onClick={notification.action.onPress}
            children={notification.action.label}
          />
        )}
      </Alert>
    </Snackbar>
  )
}
