import React from 'react'

import {
  ChatBubbleOutline,
  EmailOutlined,
  LocationCityOutlined,
  SettingsOutlined,
} from '@mui/icons-material'
import { Divider, IconButton, List, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { IAccountProps } from 'components/Account/Account.types'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { LogoutButton } from 'components/auth/LogoutButton'
import { ListItemButton } from 'components/reusable/ListItemButton'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { FavoritesTypes } from '../../constants/Favorites'
import { useBreakpoints } from '../../misc/hooks/useBreakpoints'
import { FavoritesApi } from '../../services/Api/FavoritesApi'
import { FavoriteCategory } from '../favorites/FavoriteCategory'
import { Slider } from '../reusable/Slider'

export const Account = ({ user }: IAccountProps) => {
  const { i18n } = useDictionary()
  const { dialog, userLocation } = useLocations()
  const { isMobile } = useBreakpoints()
  const navigate = useNavigate()

  return (
    <>
      <SectionContainer container>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          children={<SettingsOutlined />}
          onClick={() => navigate(routes[Route.settings])}
        />
        <Flex gap={3}>
          <Typography variant={'h4'} children={user.displayName} />
          {!!user.email && <Typography children={user.email} color={'secondary'} />}
          {!!user.phoneNumber && <Typography children={user.phoneNumber} color={'secondary'} />}
        </Flex>
      </SectionContainer>
      <SectionContainer container>
        <List disablePadding>
          <ListItemButton
            title={i18n.account.myCity}
            icon={<LocationCityOutlined />}
            subtitle={userLocation.name}
            onClick={dialog.open}
          />
        </List>
      </SectionContainer>
      <SectionContainer title={i18n.account.following} smallTitle>
        <Slider
          items={Object.values(FavoritesTypes).map((type) => ({ type }))}
          disableScrollbars
          edgeOffset={isMobile ? 8 : 16}
        >
          {({ item }) => <FavoriteCategory type={item.type} />}
        </Slider>
      </SectionContainer>
      <SectionContainer title={i18n.account.helpAndFeedback} smallTitle container>
        <List disablePadding>
          <ListItemButton
            title={i18n.account.faq}
            icon={<ChatBubbleOutline />}
            onClick={() => navigate(routes[Route.help])}
          />
          <Divider />
          <ListItemButton
            title={i18n.account.contact}
            icon={<EmailOutlined />}
            onClick={() =>
              navigate(
                prepareRoute({
                  route: Route.contact,
                  params: { slug: 'formularz-kontaktowy' },
                })
              )
            }
          />
        </List>
      </SectionContainer>
      <LogoutButton />
    </>
  )
}
