import { IBasket } from 'models/basket/types'

export const getErrorFromPath = (paths: string[] | null, basket?: IBasket[]): IBasket[] | null => {
  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined
  }

  if (paths && basket && paths.length && basket.length) {
    const array = paths.map((path) => {
      const errors = path.split(']')

      const ticketIdError = errors.find((error) => error.includes('ticket'))

      if (ticketIdError) {
        const ticketId = ticketIdError.split('[')[1] || '0'

        const basketObject = basket.find((item) => item.id.toString() === ticketId)

        if (basketObject) {
          return basketObject
        } else {
          return null
        }
      } else {
        return null
      }
    })

    return array.filter(notEmpty)
  }

  return null
}
