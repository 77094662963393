import { IHelmetProps } from 'components/helmet/Helmet/Helmet.types'
import config from 'config'
import { CloudinaryPrefixes } from 'constants/Cloudinary'
import { CurrencyISO, currencySymbol } from 'constants/Currency'
import { Texts } from 'constants/Texts'
import { capitalizeFirstLetter } from 'misc/helpers/capitalizeFirstLetter'
import isNullOrUndefined from 'misc/helpers/isNullOrUndefined'
import { trimString } from 'misc/helpers/trimString'
import createSchemaFromEvent from 'models/meta/helpers/createSchemaFromEvent'
import { DateTime } from 'services/DateTime'
import Logger from 'services/Logger'
import { IArtistResponse } from 'types/Artist'
import { IFacets } from 'types/CloudSearch'
import { ITag } from 'types/Common'
import { IEventResponse } from 'types/Event'
import { IRundateResponse } from 'types/EventRundate'
import { IFavorite, IRundateFavorite } from 'types/Favorites'
import { ILangMap } from 'types/Locale'
import { IPlaceResponse } from 'types/Place'
import { IProductResponse } from 'types/Products'
import { TicketResponse } from 'types/TicketManagement'

import { FavoritesTypes } from '../constants/Favorites'

export class DataMutations {
  public static artistsToFavorites = (data?: IArtistResponse[]): IFavorite[] => {
    if (!data) return []

    return data.map((item) => ({
      id: item.id,
      externalId: item.id,
      type: FavoritesTypes.Artists,
      name: item.name,
      imagePath: item.slug,
      slug: item.slug,
    }))
  }

  public static placeToFavorite = (data?: IPlaceResponse): IFavorite | null => {
    if (!data) return null

    return {
      externalId: data.id,
      type: FavoritesTypes.Places,
      name: data.name,
      imagePath: data.thumb ?? '',
      slug: data.slug,
      extraInfo: data.address,
    }
  }

  public static tagsToFavorites = (tags?: ITag[]): IFavorite[] =>
    tags?.map((tag) => ({
      id: Number(tag.id),
      externalId: Number(tag.id),
      type: FavoritesTypes.Tags,
      name: tag.name,
      imagePath: tag.slug,
      slug: tag.slug,
    })) || []

  public static favoriteCloudinaryPrefix = (type: FavoritesTypes) => {
    switch (type) {
      case FavoritesTypes.Artists:
        return CloudinaryPrefixes.ARTIST
      case FavoritesTypes.Places:
        return CloudinaryPrefixes.PLACE
      case FavoritesTypes.Rundates:
        return CloudinaryPrefixes.EVENT
      case FavoritesTypes.Tags:
        return CloudinaryPrefixes.TAG
      default:
        return null
    }
  }

  public static combineFacets = (facetIds: IFacets, facetNames: IFacets, facetSlugs: IFacets) =>
    facetIds.buckets.map((facet, index) => ({
      count: facet.count,
      id: facet.value,
      name: facetNames.buckets[index].value,
      slug: facetSlugs.buckets[index].value,
      verified: facet.count === facetNames.buckets[index].count,
    }))

  /**
   * TODO: Get this shit calculated from API.
   */
  public static calculateTicketTransferFee = (
    ticket?: TicketResponse,
    products?: IProductResponse[]
  ): { recipientFee: number; senderFee: number } => {
    if (ticket?.rundate && !!products) {
      const {
        ticket: { quantity },
        pool: { price, fee },
        rundate: {
          event: {
            partner: { transferAmount, transferPercent },
          },
        },
      } = ticket

      // Calculate the ticket's full price
      const ticketFullPrice = price * quantity + fee

      // Calculate the transfer fee based on the transferAmount or transferPercent and divide between sender and recipient
      const ticketDataTransferFee: number =
        (transferAmount || (transferPercent ? ticketFullPrice * (transferPercent / 100) : 0)) / 2

      // Find the closest transfer fee in the products array
      const closestProduct = products.reduce(
        (closest, product) => {
          const productPrice = product.price
          const priceDiff = Math.abs(productPrice - ticketDataTransferFee)
          const closestDiff = Math.abs(closest.price - ticketDataTransferFee)

          if (priceDiff < closestDiff) {
            return { price: productPrice, noSenderFee: false }
          }
          return closest
        },
        { price: Infinity, noSenderFee: true }
      )

      // Check if the calculated transfer fee is larger than the full ticket price
      if (closestProduct.price > ticketFullPrice) {
        return { recipientFee: closestProduct.price, senderFee: 0 }
      }

      return { recipientFee: closestProduct.price, senderFee: closestProduct.price }
    }

    return { recipientFee: 0, senderFee: 0 }
  }

  /**
   * Formats price to 2 fraction digits if price has fractions,
   * removes fractions if they are .00, or less than .01.
   *
   * formatPrice(190.4)) // Outputs: "190.40"
   * formatPrice(190.45)) // Outputs: "190.45"
   * formatPrice(190.005)) // Outputs: "190"
   * formatPrice(190.0)) // Outputs: "190"
   * formatPrice('190.45')) // Outputs: "190.45"
   * formatPrice('190.4')) // Outputs: "190.40"
   * formatPrice('190.004')) // Outputs: "190"
   * formatPrice('190')) // Outputs: "190"
   *
   * @param price
   */
  public static formatPriceNumber = (price: string | number): string => {
    const priceNumber = typeof price === 'string' ? parseFloat(price) : price
    const decimalPart = priceNumber % 1

    if (isNaN(priceNumber)) {
      Logger.error('formatPriceNumber', `invalid price: ${price}`)

      return '0'
    }

    if (decimalPart > 0.01) return priceNumber.toFixed(2)
    else return Math.floor(priceNumber).toString()
  }

  public static formatServiceFeePrice = (
    price?: string | number | null,
    currency: CurrencyISO = CurrencyISO.PLN
  ): string => {
    if (isNullOrUndefined(price)) return `0 ${currencySymbol[currency]}`

    return `${DataMutations.formatPriceNumber(price!)}\u00A0${currencySymbol[currency]}`
  }

  public static friendlyPrice = ({
    price,
    priceDescription,
    prefix,
    currency = CurrencyISO.PLN,
  }: {
    price?: string | number | null
    priceDescription?: string
    currency?: CurrencyISO
    prefix?: string
  }): string => {
    if (!!priceDescription) return priceDescription

    if (isNullOrUndefined(price)) return Texts.TBA

    const p = `${DataMutations.formatPriceNumber(price!)}\u00A0${currencySymbol[currency]}`

    if (!!prefix) return `${prefix} ${p}`
    return p
  }

  public static combineCloudsearchTags = ({
    i18n,
    category_id,
    category_name,
    category_slug,
    tags_ids,
    tags_names,
    tags_slugs,
    isTour,
  }: {
    i18n: ILangMap
    category_id?: string
    category_name?: string
    category_slug?: string
    tags_names?: string[]
    tags_ids?: string[]
    tags_slugs?: string[]
    isTour?: boolean
  }): ITag[] => {
    const combinedTags: ITag[] = []

    if (category_id && category_name && category_slug && !isTour) {
      combinedTags.push({ id: category_id, name: category_name, slug: category_slug })
    }

    if (isTour)
      combinedTags.push({
        name: capitalizeFirstLetter(i18n.searchCard.event),
        id: '',
        slug: i18n.searchCard.event,
      })

    if (
      tags_names &&
      tags_ids &&
      tags_slugs &&
      tags_names.length === tags_ids.length &&
      tags_names.length === tags_slugs.length
    ) {
      for (let i = 0; i < tags_names.length; i++) {
        combinedTags.push({ id: tags_ids[i], name: tags_names[i], slug: tags_slugs[i] })
      }
    }

    return combinedTags
  }

  public static rundateHelmetMeta = (rundate: IRundateResponse, i18n: ILangMap): IHelmetProps => {
    const pageName = [rundate.event.title, `${i18n.meta.ticketsFor} ${i18n.meta.event}`]
    if (!!rundate.event.place?.city.name) pageName.push(rundate.event.place.city.name)

    const ogImage =
      rundate.externalImages?.[0] ||
      rundate.event.externalImages?.[0] ||
      rundate.event.images?.[0]?.large

    return {
      pageName,
      currentUrl: window?.location.href,
      description: [
        `${i18n.meta.eventInfo} ${rundate.event.title}`,
        DateTime.formatRundateDates({
          startDate: rundate.startDate,
          endDate: rundate.endDate,
          dateTranslate: i18n.dates,
        }),
        rundate.place.address,
      ],
      ogDescription: [
        rundate.event.category.name,
        rundate.event.title,
        trimString(
          rundate.event.description ??
            rundate.event.descriptionPL ??
            rundate.event.formattedDescription ??
            rundate.event.formattedPartnerDescription
        ) ?? '',
        i18n.meta.buyTicket,
      ],
      ogImage,
      noIndex: rundate.partner?.id === config.app.goingTestPartnerId,
      schema: rundate ? [createSchemaFromEvent(rundate, ogImage)] : null,
    }
  }

  public static eventHelmetData = (event: IEventResponse, i18n: ILangMap): IHelmetProps => {
    const pageName = [event.title, `${i18n.meta.ticketsFor} ${i18n.meta.event}`]
    if (!!event.place?.city.name) pageName.push(event.place.city.name)

    return {
      pageName,
      currentUrl: window?.location.href,
      description: [`${i18n.meta.eventInfo} ${event.title}`, event.place.address],
      ogDescription: [
        event.category.name,
        event.title,
        trimString(
          event.description ??
            event.descriptionPL ??
            event.formattedDescription ??
            event.formattedPartnerDescription
        ) ?? '',
        i18n.meta.buyTicket,
      ],
      ogImage: event.images?.[0]?.large,
      customGtmId: event.partner?.gtmId,
      noIndex: event.partner?.id === config.app.goingTestPartnerId,
    }
  }

  public static favoritesToCalendarTile = (data: IRundateFavorite[]): IRundateFavorite[] =>
    data
      .map((event, index) => {
        const {
          place,
          imagePath,
          name,
          category,
          externalId,
          params,
          time,
          partnerId,
          partnerName,
          placeSlug,
          type,
          slug,
        } = event

        return {
          category,
          imagePath,
          time,
          externalId,
          params,
          place,
          tags: [category.name],
          name,
          index,
          partnerId,
          partnerName,
          placeSlug,
          type,
          slug,
        }
      })
      .sort((tile, prevTile) => tile.time.toMillis() - prevTile.time.toMillis())
}
