import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { generateAndSendSmsCode, handleNextStep, setParentData } from 'models/entryList/actions'
import { getParentData } from 'models/entryList/selectors'

import ParentData from './ParentData.component'
import { IParentDataFromDispatch, IParentDataFromState } from './ParentData.types'

const mapStateToProps = (state: _Store.IState): IParentDataFromState => ({
  initialValues: getParentData(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IParentDataFromDispatch => ({
  setParentData: (data) => dispatch(setParentData(data)),
  handleNextStep: (step) => dispatch(handleNextStep(step)),
  generateAndSendSmsCode: (code) => dispatch(generateAndSendSmsCode(code)),
})

export default connect<IParentDataFromState, IParentDataFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(ParentData)
