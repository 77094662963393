import React, { useEffect, useState } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { Flex } from 'components/_layout/Flex'
import SimpleLoading from 'components/reusable/SimpleLoading'
import config from 'config'
import empikInfo from 'misc/styles/images/empik-info.svg'
import exclamationIcon from 'misc/styles/images/exclamation.svg'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './TimeSlots.styles'
import { IBookingTimeSlot, ITimeSlotsProps } from './TimeSlots.types'
import Occupancy from './components/Occupancy'

const TimeSlots = ({
  isLoadingAvailabilities,
  selectedDay,
  setDayFromSlot,
  setSlot,
  happeningSpaces,
  timeSlots,
  generateIdempotencyKey,
  selectedSlotFromState,
  selectedTheme,
  selections,
  calculatePricePerPerson,
  showSlotsAvailability,
  resetSlot,
}: ITimeSlotsProps) => {
  const { i18n } = useDictionary()

  const [_, changeSelected] = useState('')
  const setSelected = (slot: IBookingTimeSlot) => {
    changeSelected(slot.start)
    setSlot(slot.start)
    const formattedSlotDate = moment(slot.data).toDate()
    setDayFromSlot(formattedSlotDate)
    generateIdempotencyKey()
  }
  let showedSlots: number = timeSlots?.length || 0
  const infoIcon = config.theme.isEmpik ? empikInfo : exclamationIcon

  const isDisabled =
    !selections.some((selection) => selection.numberOfPlayers) && calculatePricePerPerson

  useEffect(() => changeSelected(''), [])
  useEffect(() => {
    if (timeSlots.length === 1) {
      setSelected(timeSlots[0])
    }
    if (selectedDay) {
      setDayFromSlot(selectedDay)
    }
  }, [selectedDay])
  useEffect(() => {
    if (!timeSlots.some((slot) => slot.start === selectedSlotFromState)) resetSlot()
  }, [timeSlots.length])

  const theme = useTheme()
  const classes = useStyles(theme)
  const isHangar = selectedTheme === 'Hangar646'
  const isDefaultTheme = selectedTheme === 'default'

  if (isLoadingAvailabilities) {
    return <SimpleLoading />
  }

  return (
    <Flex fullWidth>
      {isHangar && (
        <Flex fullWidth>
          <Flex fullWidth gap={2} direction={'row'} align={'center'}>
            <Flex fullWidth>
              <InfoIcon fontSize={'large'} className={classes.infoIcon} />
            </Flex>
            <Flex fullWidth>
              <Typography variant={'subtitle2'}>{i18n.buy.timeSlots.rememberText}</Typography>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex fullWidth>
        <Flex fullWidth gap={2} direction={'row'} wrap={'wrap'}>
          {happeningSpaces &&
            timeSlots.map((slot) => {
              const isTimeOnSlotScoped = happeningSpaces.find(
                (space) => space.id === slot.spaces[0].spaceId
              )?.isScopeView

              const slotStartInSeconds = moment.duration(slot.start).asSeconds()
              const slotDuration = happeningSpaces.find(
                (space) => space.id === slot.spaces[0].spaceId
              )?.timeSlot
              const slotEndInSeconds = slotDuration ? slotStartInSeconds + slotDuration : 0
              const slotEndInHoursAndMinutes = moment
                .utc()
                .startOf('day')
                .add(slotEndInSeconds, 'seconds')
                .format('HH:mm')

              const slotTime = moment(slot.start, 'HH:mm')
              const userTime = moment(new Date())
              const isBefore = slotTime.isBefore(moment(userTime, 'HH:mm'))
              const isSameDay = moment(slot.data, 'YYYY-MM-DD').isSame(
                moment(userTime, 'YYYY-MM-DD')
              )

              if (slot.isDisabled || !!(isSameDay && isBefore)) {
                showedSlots--

                return null
              }

              const isSelectedSlot = selectedSlotFromState === slot.start
              const onClick = () => setSelected(slot)

              return (
                <Flex
                  key={slot.start}
                  flex={1}
                  sx={{ flexBasis: 'calc(33.3333% - 6px)', maxWidth: 'calc(33.3333% - 6px)' }}
                >
                  <Button
                    disabled={slot.isDisabled || isDisabled}
                    type={'button'}
                    onClick={onClick}
                    size={'medium'}
                    variant={isSelectedSlot ? 'contained' : 'outlined'}
                    color={config.theme.isEmpik ? 'secondary' : 'primary'}
                    disableElevation={true}
                    fullWidth={true}
                    className={cn(
                      classes.slot,
                      isDefaultTheme && classes.slotDefault,
                      isDefaultTheme && isSelectedSlot && classes.defaultSelected
                    )}
                    sx={{ color: isSelectedSlot ? 'text.contrastText' : 'text.primary' }}
                  >
                    <Grid container={true} spacing={1}>
                      <Grid item={true} xs={12}>
                        <Typography variant={'button'}>
                          {isTimeOnSlotScoped
                            ? `${slot.start} - ${slotEndInHoursAndMinutes}`
                            : slot.start}
                        </Typography>
                      </Grid>
                      {showSlotsAvailability && (
                        <Grid item={true} xs={12}>
                          <Occupancy slot={slot} isDefaultTheme={isDefaultTheme} />
                        </Grid>
                      )}
                    </Grid>
                  </Button>
                </Flex>
              )
            })}
        </Flex>
      </Flex>
      {!showedSlots && (
        <Flex fullWidth>
          <Flex fullWidth>
            <Flex fullWidth /*className={classes.infoIcon}*/>
              <img src={infoIcon} alt="" />
            </Flex>
            <Flex fullWidth>
              <Typography variant={'subtitle1'}>{i18n.buy.happening.noMoreSlots}</Typography>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default TimeSlots
