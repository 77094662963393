import TransactionError from 'misc/classes/TransactionError'
import { createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'

export const informAboutErrorWhenSomethingWrong = createStandardAction(
  CONSTS.INFORM_ABOUT_ERROR_WHEN_SOMETHING_WRONG
)()

export const informAboutError404 = createStandardAction(CONSTS.INFORM_ABOUT_ERROR_404)()

export const informAboutErrorModal = createStandardAction(CONSTS.INFORM_ABOUT_ERROR_MODAL)()

export const resetError = createStandardAction(CONSTS.RESET_ERROR)()

export const setError = createStandardAction(CONSTS.SET_ERROR)<Error | TransactionError>()
