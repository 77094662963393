import { ComponentType, ElementType } from 'react'

import {
  Box,
  BoxProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material'

interface IStyleProps {
  isDesktop: boolean
}

export const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  })) as ComponentType<BoxProps>,

  ColumnLayout: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
  })<IStyleProps>(({ isDesktop, theme }) => ({
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
    gap: theme.spacing(3),
    position: 'relative',
    marginTop: theme.spacing(4),
  })) as ComponentType<BoxProps & IStyleProps>,

  LeftColumn: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
  })<IStyleProps>(({ isDesktop, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: isDesktop ? 2 : 1,
    minWidth: 0,
    gap: theme.spacing(3),
  })) as ComponentType<BoxProps & IStyleProps>,

  ImageBox: styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(4),
    overflow: 'hidden',
  })) as ComponentType<BoxProps>,

  FloatingBox: styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
  })<IStyleProps>(({ isDesktop, theme }) => ({
    flex: isDesktop ? 1 : 0,
    minWidth: 0,
    position: 'sticky',
    top: theme.spacing(4),
    height: '100%',
    padding: theme.spacing(4),
    borderRadius: 16,
  })) as ComponentType<PaperProps & IStyleProps>,

  Title: styled(Typography)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    mb: theme.spacing(4),
    fontSize: '1.75 rem',
  })) as ComponentType<TypographyProps & { component?: ElementType }>,

  TitleSkeleton: styled(Typography)(() => ({
    fontSize: '1.75 rem',
  })) as ComponentType<TypographyProps & { component?: ElementType }>,
}
