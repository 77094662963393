import { getType } from 'typesafe-actions'

import { getPartnerActivities } from '../../actions'
import { IAction, IActivitiesData } from '../../types'

const initialState: IActivitiesData = { items: [] }

const reducer = (state: IActivitiesData = initialState, action: IAction): IActivitiesData => {
  switch (action.type) {
    case getType(getPartnerActivities.success):
      return action.payload
    case getType(getPartnerActivities.failure):
      return initialState

    default:
      return state
  }
}

export default reducer
