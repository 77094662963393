import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPremiumUser } from './../types'
import getUser from './getUser'

const getIsUserPremium = createSelector<_Store.IState, IPremiumUser | null, boolean>(
  [getUser],
  (user) => {
    return Boolean(user)
  }
)

export default getIsUserPremium
