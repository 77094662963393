import * as React from 'react'

import { Outlet, useLocation } from 'react-router-dom'

import CookieBar from 'components/CookieBar/CookieBar.container'
import { EmbedResizer } from 'components/_functional/EmbedResizer'
import { NotificationProvider } from 'components/_functional/NotificationProvider'
import { useAppClassNames } from 'components/_layout/AppLayout/useAppClassNames'
import { EmpikTriangleBackground } from 'components/_layout/EmpikTriangleBackground'
import { Footer } from 'components/_layout/Footer'
import { Navbar } from 'components/_layout/Navbar'
import ErrorModal from 'components/errors/ErrorModal/ErrorModal.container'
import LegacyNotification from 'components/modals/Notification/Notification.container'
import scrollToTopOnMessageEvent from 'misc/helpers/scrollToTopOnMessageEvent'
import { useIsEmbedFromStore } from 'models/internalRouter/hooks/useIsEmbedFromStore'
import { useAppState } from 'state/app/hooks/useAppState'

/**
 * This component sets up app's layout elements (nav, main, footer) and theme context.
 * Should be rendered just once in the component tree by the react-router top route.
 * All descendant elements (_pages) are rendered by router in the <Outlet /> component.
 */
export const AppLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { isBrowser, isWebview } = useAppState()
  const { isEmbed } = useIsEmbedFromStore()
  useAppClassNames()

  // this scrolls to top on every route change
  React.useEffect(() => {
    if (isBrowser) window.scrollTo(0, 0)
  }, [pathname])

  // this sets up scroll message listener for the mobile app's Webview.
  React.useEffect(() => {
    if (isBrowser) {
      window.addEventListener('message', scrollToTopOnMessageEvent)

      return () => window.removeEventListener('message', scrollToTopOnMessageEvent)
    }
  }, [])

  return (
    <>
      {!(isWebview || isEmbed) && <Navbar />}
      <EmpikTriangleBackground />
      {!!children ? children : <Outlet />}
      {!(isWebview || isEmbed) && <Footer />}

      {/** BELOW ONLY COMPONENTS WHICH ARE OUTSIDE STANDARD PAGE FLOW */}
      <ErrorModal />
      <EmbedResizer />
      <NotificationProvider />
      <LegacyNotification />
      {!(isWebview || isEmbed) && <CookieBar />}
    </>
  )
}
