import { ComponentType } from 'react'

import { Box, BoxProps, styled } from '@mui/material'

import { ISliderStyleProps } from './Slider.types'

export const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'disableScrollbars' && prop !== 'edgeOffset',
  })<ISliderStyleProps>(({ theme, disableScrollbars, edgeOffset }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: theme.spacing(3),
    overflowX: 'auto',
    listStyle: 'none',
    scrollbarWidth: disableScrollbars ? 'none' : 'auto',
    '::-webkit-scrollbar': {
      display: disableScrollbars ? 'none' : 'auto',
    },
    ...(edgeOffset && {
      paddingLeft: edgeOffset,
      paddingRight: edgeOffset,
      width: `calc(100% + ${edgeOffset * 2}px)`,
      transform: `translateX(-${edgeOffset}px)`,
    }),
  })) as ComponentType<BoxProps & ISliderStyleProps>,
  Item: styled(Box)(({ theme }) => ({
    flex: '0 0 auto',
  })),
}
