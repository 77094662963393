import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { articlePageMounted } from 'models/article/actions'
import { getArticle } from 'models/article/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import StaticArticle from './StaticArticle.component'
import { IArticleFromDispatch, IArticleFromState } from './StaticArticle.types'

const mapStateToProps = (state: _Store.IState): IArticleFromState => {
  return {
    article: getArticle(state),
    isArticleLoading: getLoading(CONST.STATIC_ARTICLE)(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IArticleFromDispatch => {
  return {
    mounted: () => dispatch(articlePageMounted()),
  }
}

export default connect<IArticleFromState, IArticleFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(StaticArticle)
