const colors = {
  actions: {
    background: 'rgba(0, 0, 0, 0.08);',
    disabled: 'rgba(0,0,0,0.12);',
    disabledBackground: 'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12))',
    disabledText: 'rgba(0, 0, 0, 0.26);',
    main: '#E0E0E0',
  },
  background: {
    paper: '#FFBFBF',
  },
  other: {
    activeRating: '#FFB400',
    backdrop: 'rgba(0, 0, 0, 0.5);',
    divider: '#FAADAD',
    snackbar: '#323232',
    stroke: '#2C2D53',
    white: '#ffffff',
  },
  primary: {
    background: 'rgba(0, 167, 216, 0.08);',
    border: '#110A6B',
    contrastText: '#ffffff',
    dark: '#3D8CB9',
    light: '#00BBFF',
    main: '#110A6B',
  },
  secondary: {
    background: 'rgba(44, 45, 83, 0.1);',
    border: '#2C2D53',
    contrastText: '#ffffff',
    dark: '#28293D',
    light: '#373737',
    main: '#26856D',
  },
  text: {
    disabled: 'rgba(17, 10, 107, 0.4);',
    hint: 'rgba(0, 0, 0, 0.38);',
    primary: '#110A6B',
    secondary: 'rgba(17, 10, 107, 0.5);',
  },
}
export default colors
