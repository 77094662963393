import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    buyButton: {
      fontSize: '1.25rem',
      fontWeight: 500,
      marginBottom: '1.375rem',
      padding: '1.25rem 0rem',
      width: '100%',
    },
  })
)

export default useStyles
