import { useEffect, useState } from 'react'

/**
 * Hook used to debounce a value. The first value is returned immediately.
 *
 * @param value
 * @param delay
 */
export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
