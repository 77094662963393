import stepNames, { steps } from 'components/EntryList/steps'
import { getType } from 'typesafe-actions'

import {
  appendToSubmissionsSummary,
  checkSubmissionsExists,
  clearFormsData,
  clearFormsErrors,
  generateAndSendSmsCode,
  getDataFromTicket,
  handleNextStep,
  handlePrevStep,
  prepareSubmissionsToAttach,
  requestDataFromTicket,
  requestToCheckSubmissionsExists,
  resetForm,
  setFormErrors,
  setFormValues,
  setHistoryItem,
  setIsViewOnly,
  setParentData,
  setTicketSubmissionsComplete,
  skipToSelectedStep,
} from '../actions'
import { IAction, IEntryListReducer } from '../types'

const initialState: IEntryListReducer = {
  foundedSubmissions: null,
  isEmail: false,
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    privacyPolicy: false,
  },
  parentData: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    prefix: '+48',
  },
  activeStep: stepNames.welcome,
  history: [stepNames.welcome],
  smsCode: '',
  ticketCode: '',
  ticketData: null,
  submissions: [],
  errors: null,
  submissionsSummary: [],
  submissionsComplete: false,
  viewOnly: false,
}

const reducer = (state: IEntryListReducer = initialState, action: IAction): IEntryListReducer => {
  switch (action.type) {
    case getType(checkSubmissionsExists.success):
      return {
        ...state,
        foundedSubmissions: action.payload,
      }

    case getType(requestToCheckSubmissionsExists):
      return {
        ...state,
        initialValues: { ...state.initialValues, email: action.payload },
        isEmail: true,
      }

    case getType(setIsViewOnly):
      return {
        ...state,
        viewOnly: action.payload,
      }

    case getType(setFormValues):
      return {
        ...state,
        initialValues: action.payload,
      }
    case getType(setParentData):
      return {
        ...state,
        parentData: action.payload,
      }

    case getType(handleNextStep):
      return {
        ...state,
        history: [...state.history, state.activeStep],
        activeStep: action.payload,
      }
    case getType(handlePrevStep):
      const prevStep = state.history[state.history.length - 1]
      const updated = state.history.slice(0, -1)
      return {
        ...state,
        history: updated,
        activeStep: prevStep,
      }

    case getType(skipToSelectedStep):
      const index = steps.findIndex((stp) => stp.name === action.payload)
      const step = steps[index]

      return {
        ...state,
        history: [...state.history, state.activeStep],
        activeStep: step.name,
      }

    case getType(requestDataFromTicket):
      return {
        ...state,
        ticketCode: action.payload,
      }

    case getType(setHistoryItem):
      return {
        ...state,
        history: [...state.history, action.payload],
      }

    case getType(getDataFromTicket.success):
      const data = !!action.payload.data.length && action.payload.data[0]
      if (data && data.hasOwnProperty('owner')) {
        return {
          ...state,
          initialValues: {
            ...state.initialValues,
            firstName: data.owner.firstName,
            lastName: data.owner.lastName,
            email: data.owner.email,
          },
          ticketData: data,
        }
      }

    case getType(resetForm):
      return {
        ...state,
        initialValues: initialState.initialValues,
        parentData: initialState.parentData,
        ticketCode: initialState.ticketCode,
        ticketData: initialState.ticketData,
        history: initialState.history,
        smsCode: initialState.smsCode,
        submissions: initialState.submissions,
        errors: initialState.errors,
        submissionsComplete: initialState.submissionsComplete,
        submissionsSummary: initialState.submissionsSummary,
        viewOnly: initialState.viewOnly,
        foundedSubmissions: initialState.foundedSubmissions,
      }

    case getType(generateAndSendSmsCode):
      return {
        ...state,
        smsCode: action.payload.code,
      }

    case getType(prepareSubmissionsToAttach):
      const { foundedSubmissions, history } = state
      const filtered = foundedSubmissions?.submission.filter(
        (item) => item.submissionId !== action.payload.submissionId
      )
      const updatedHistory = history.filter((item) => item !== stepNames.selectSubmission)
      return {
        ...state,
        history: updatedHistory,
        foundedSubmissions: { submission: filtered ? filtered : [] },
        submissions: [...state.submissions, action.payload],
      }

    case getType(clearFormsData):
      return {
        ...state,
        isEmail: false,
        initialValues: initialState.initialValues,
        parentData: initialState.parentData,
      }

    case getType(getDataFromTicket.failure):
      return {
        ...state,
        errors: {
          ...state.errors,
          ticketCode: action.payload.response?.data.message,
        },
      }

    case getType(clearFormsErrors):
      return {
        ...state,
        errors: null,
      }
    case getType(setTicketSubmissionsComplete):
      return {
        ...state,
        submissionsComplete: action.payload,
      }
    case getType(appendToSubmissionsSummary):
      return {
        ...state,
        submissionsSummary: [...state.submissionsSummary, action.payload],
      }

    case getType(setFormErrors):
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.type]: action.payload.value,
        },
      }
    default:
      return state
  }
}

export default reducer
