import React, { useEffect } from 'react'

import { Button, Grid } from '@mui/material'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import stepNames from '../../steps'
import Paper from '../Paper'
import { IAgreementExistProps } from './AgreementExist.types'

const AgreementExist = ({
  resetForm,
  handlePrevStep,
  submissionsComplete,
  skipToSelectedStep,
}: IAgreementExistProps) => {
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  const handleResetForm = () => {
    resetForm()
    skipToSelectedStep(stepNames.welcome)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetForm()
      skipToSelectedStep(stepNames.welcome)
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Paper
      title={
        submissionsComplete
          ? i18n.entryList.agreementExist.submissionsCompleteTitle
          : i18n.entryList.agreementExist.title
      }
      helperText={
        submissionsComplete
          ? i18n.entryList.agreementExist.submissionsCompleteHelper
          : i18n.entryList.agreementExist.subtitle
      }
    >
      <Grid item={true} xs={12}>
        <Grid
          container={true}
          justifyContent="center"
          alignItems="center"
          className={globals.column}
          spacing={1}
        >
          <Grid item={true}>
            <Button
              className={globals.button}
              type="button"
              variant="text"
              color="primary"
              onClick={handlePrevStep}
            >
              {i18n.entryList.agreementExist.goBack}
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              className={globals.button}
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleResetForm}
            >
              {i18n.entryList.agreementExist.homepage}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AgreementExist
