import { createSelector, defaultMemoize } from 'reselect'
import { object, string } from 'yup'

import _Store from '@Store'

import * as CONSTS from 'components/buying/constants/constants'
import { discountCodeValidator } from 'components/buying/constants/validators'
import { getProducts } from 'models/products/selectors'
import { IProduct } from 'models/products/types'
import { ILangMap } from 'types/Locale'

import {
  IHappeningFormOnsiteValidators,
  IHappeningFormOnsiteValues,
} from './HappeningBuyFormOnsite.types'

export const getInitialValues = createSelector<
  _Store.IState,
  IProduct[],
  IHappeningFormOnsiteValues
>([getProducts], (products) => {
  const initialValues: IHappeningFormOnsiteValues = {
    authId: CONSTS.DEFAULT_EMPTY_TEXT,
    discount: CONSTS.DEFAULT_EMPTY_TEXT,
    discountCheckbox: false,
    empikPremiumNumber: CONSTS.DEFAULT_EMPTY_TEXT,
    products: {},
  }

  Object.values(products).forEach((product) => {
    initialValues.products[`${product.id}`] = product.autocomplete ? 1 : 0
  })

  return initialValues
})

const makeValidationSchemaToMemoize = (lang: ILangMap) => {
  const validators: Partial<IHappeningFormOnsiteValidators> = {
    authId: string().required(lang.errors.lastNameIsRequiredValidator),
    discount: discountCodeValidator(lang),
  }

  return object().shape(validators as IHappeningFormOnsiteValidators)
}

export const makeValidationSchema = defaultMemoize(makeValidationSchemaToMemoize)
