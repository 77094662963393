import { Theme } from '@mui/material'
import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = (theme: Theme) =>
  makeSxStyles(() => ({
    chooseButton: {
      borderColor: theme.border.color,
      borderRadius: theme.border.radius,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
    },
  }))
