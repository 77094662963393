import { FormFields } from 'components/_forms/_constants/FieldNames'
import { SearchTypes } from 'constants/CloudSearch'
import { FavoritesTypes } from 'constants/Favorites'
import { Languages } from 'constants/Languages'
import {
  TicketActionErrors,
  TicketActions,
  TicketRefundOptions,
  TicketTransferStatus,
} from 'services/Api/TicketManagementApi'

export const pl = {
  _lang: Languages.Polski,
  account: {
    helpAndFeedback: 'Pomoc i feedback',
    myCity: 'Moje miasto',
    faq: 'FAQ',
    following: 'Obserwowane',
    contact: 'Kontakt',
    loginFirst: 'Zaloguj się, aby mieć dostęp do swojego konta.',
  },
  actionBar: {
    noSales: 'Nie prowadzimy sprzedaży na to wydarzenie.',
    salesStart: (date: string, time: string) => `Sprzedaż rusza ${date} o ${time}.`,
  },
  activity: {
    samePlaceActivitiesTitle: 'inne aktywności w tym miejscu',
  },
  artist: {
    nearest: 'Najbliższy koncert:',
    noUpcomingEvents: 'Aktualnie nigdzie nie występuje. Zaobserwuj artystę, by być na bieżąco.',
    pastEvents: 'Minione wydarzenia',
    upcomingEvents: 'Nadchodzące wydarzenia',
    showMore: 'Pokaż więcej',
    onTour: 'Jest w trasie!',
  },
  auth: {
    accountRemoved: 'Twoje konto zostało usunięte.',
    alertVerificationSent:
      'Na podany adres e-mail wysłaliśmy link aktywacyjny. Kliknij w niego, aby aktywować konto.',
    alertUseAnother: (method: string) =>
      `Twój email jest powiązany z inną metodą logowania, użyj ${method} aby zalogować się na swoje konto.`,
    disabledUser: 'Użytkownik został zablokowany, spróbuj ponownie za chwilę',
    errorOccurred: 'Wystąpił błąd',
    facebookLogin: 'Zaloguj przez Facebook',
    forgotPassword: 'Nie pamiętasz hasła?',
    googleLogin: 'Zaloguj przez Google',
    appleLogin: 'Zaloguj przez Apple',
    goToProfile: 'Przejdź do profilu',
    hello: 'Witaj ',
    login: 'Zaloguj się',
    loginOrRegister: 'Zaloguj się lub załóż konto',
    toKeepTickets: 'Zaloguj się lub załóż konto, by wszystkie bilety mieć w jednym miejscu.',
    logout: 'Wyloguj się',
    orSignUp: 'lub załóż konto',
    removeAccount: 'Usuń konto',
    signUp: 'Załóż konto w Going.',
    successfulLogin: 'Zalogowano pomyślnie',
    passwordPolicy:
      'Hasło powinno składać się z min. 8 znaków, w tym z przynajmniej jednej wielkiej litery i jednej małej litery. Powinno zawierać jedną cyfrę i jeden znak specjalny.',
    resetLinkSent: 'Na podany adres e-mail wysłaliśmy link do resetu hasła.',
    userUpdated: 'Dane użytkownika zostały zaktualizowane',
    withoutLogin: 'Kontynuuj bez logowania',
    wrongPassword: 'Nieprawidłowe hasło',
  },
  basket: {
    courierDelivery: 'Wysyłka kurierem:',
    details: 'Szczegóły',
    hide: 'Ukryj',
    pieces: 'szt.',
    pricePerItem: 'Cena/szt.:',
    products: 'Produkty niebiletowe',
    serviceFee: 'Opłata serwisowa:',
    ticketsNum: 'Liczba biletów:',
    totalPrice: 'Łączna cena:',
    basketButton: {
      basket: 'Koszyk',
    },
    basketEmpty: {
      continue: 'Kontynuuj zakupy',
      empty: 'Nie masz biletów w koszyku.',
      find: 'Znajdź coś dla siebie',
    },
  },
  breadcrumbs: {
    activities: 'aktywności',
    events: 'wydarzenia',
    artists: 'artyści',
    places: 'miejsca',
    selections: 'selekcje',
    ticketsManagement: 'zarządzaj biletami',
    yourPass: 'mój karnet',
    ticketEdit: 'mój bilet',
  },
  buttons: {
    back: 'Cofnij',
    begin: 'Rozpocznij',
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    done: 'Gotowe',
    download: 'Pobierz',
    goBack: 'Powrót',
    next: 'Dalej',
    save: 'Zapisz',
    close: 'Zamknij',
    ok: 'Ok',
    remove: 'USUŃ',
    add: 'DODAJ',
    buyTicket: 'Kup bilet',
    join: 'Dołącz',
    disabled: 'Niedostępne',
    details: 'Szczegóły',
    share: 'Udostępnij',
    copy: 'Kopiuj',
    tickets: 'Bilety',
  },
  buy: {
    agreements: {
      acceptAgreements: 'Zaakceptuj zgody',
      acceptAgreementsDetailed: 'Zaakceptuj zgody i podaj dane dla biletów',
      additionalData: '(wymaga podania dodatkowych danych)',
      agreementsAndRegulations: 'Zgody i regulaminy',
      fillInData: 'Uzupełnij danymi nabywcy',
      iWantNewsletter: 'Chcę dostawać newsletter Going. Mustsee.',
      newsletterAgreement:
        'Zapisując się do Newslettera Aplikacji Going wyrażam zgodę na przetwarzanie podanych przeze mnie moich danych osobowych przez GOING Spółkę z ograniczoną odpowiedzialnością z siedzibą w Warszawie, Aleje Jerozolimskie 107, 02–011 Warszawa w celu otrzymywania informacji handlowych według rozumienia art. 9 ustawy z dnia 9 czerwca 2017 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2017 r. poz. 1219)',
      requiredFields:
        '*aby skutecznie złożyć zamówienie niezbędne jest zaznaczenie pól oznaczonych gwiazdką',
      requiredFieldsAdditional:
        'Dla biletów, które nie wymagają podania unikalnych danych użyte zostaną informacje wprowadzone w sekcji 3.',
      selectConsents: 'Zaznacz zgody dla tego wydarzenia',
    },
    clientData: {
      didYouMean: 'Czy chodziło Ci o: ',
      discountAdded: 'Dodano zniżkę.',
      discountRemoved: 'Zniżka usunięta.',
      discountCode: 'Podaj kod rabatowy',
      discountCodeTooltip: 'Kod wygenerowany w ramach akcji promocyjnych',
      discountCodeTooltipSaltos: 'Kody rabatowe dla kart partnerskich znajdziesz na grafice u góry',
      discountCodeInputEmpty: 'Pole na kod rabatowy nie może być puste.',
      discountUnavailableForBasketSales:
        '. UWAGA: kody zniżkowe są tymczasowo niedostępne dla biletów dodanych do koszyka. Aby użyć kodu zniżkowego, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Going.',
      discountUnavailableForBasketSalesEmpik:
        '. UWAGA: kody zniżkowe są tymczasowo niedostępne dla biletów dodanych do koszyka. Aby użyć kodu zniżkowego, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Empik Bilety',
      eMail: 'Adres e-mail*',
      fillFormWithLastData: 'Uzupełnij danymi',
      firstName: 'Imię*',
      getDataFrom: 'Pobierz swój adres z:',
      getLastData: 'Czy chcesz uzupełnić formularz ostatnio podanymi danymi?',
      iHaveDiscount: 'Mam kod rabatowy',
      iHaveDiscountGoing: 'Mam kod rabatowy',
      iHavePass: 'Posiadam karnet',
      iHavePrepaid: 'Mam kod vouchera',
      iHavePrepaidGoing: 'Mam kod vouchera lub karty podarunkowej Going.',
      iHavePrepaidEmpik: 'Mam kod vouchera lub karty podarunkowej.',
      id: '. Dane te mogą być weryfikowane w chwili wejścia na wydarzenie przez organizatora koncertu lub jego przedstawicieli.',
      lastName: 'Nazwisko*',
      namedTicket: 'Podaj',
      pass: 'Numer karnetu',
      phone: 'Numer telefonu',
      prepaidCard: 'Podaj kod karty podarunkowej.',
      prepaidCardGoing: 'Podaj kod karty podarunkowej Going.',
      prepaidCardEmpik: 'Podaj kod karty podarunkowej',
      prepaidCardInputEmpty: 'Pole na kartę podarunkową lub voucher nie może być puste.',
      prepaidTooltip:
        'Voucher Going. (otrzymany w ramach zwrotu biletu lub reklamacji) Karta Podarunkowa Going. (zakupiona przez Empik.com)',
      prepaidTooltipEmpik:
        'Voucher (otrzymany w ramach zwrotu biletu lub reklamacji) lub Karta Podarunkowa (zakupiona przez Empik.com)',
      provideData: 'Podaj swoje dane',
      realData: 'prawdziwe dane osobowe',
      validEmail: 'Upewnij się, że e-mail jest poprawny',
      willSendTickets: 'Zakupione bilety wyślemy na wskazany adres.',
      checkDiscount: 'Sprawdź zniżkę',
      calculatePrepaid: 'Zrealizuj kod',
      requiredFields:
        '*aby skutecznie złożyć zamówienie niezbędne jest wypełnienie pól oznaczonych gwiazdką',
    },
    empikCardNumber: {
      buyNow: 'Kup teraz',
      cardDescription:
        'Jeżeli jesteś Klientem Empik Premium, podaj swój 13-cyfrowy kod Klienta przypisany do wskazanego przez Ciebie adresu e-mail. Kod Klienta znajdziesz w aplikacji Empik w zakładce Premium.',
      empikCardNumber: 'Kod klienta premium',
      here: 'tutaj',
      premiumUser: 'Jestem klientem Empik Premium',
      premiumUserData: 'Dane klienta Empik Premium',
    },
    formLayout: {
      buttonAdd: 'Dodaj do koszyka',
      buttonPay: 'Przejdź do płatności',
      buyAndPay: 'Kupuję i płacę',
      formInvalid: 'Coś się nie zgadza. Popraw dane w formularzu i ruszaj w miasto!',
      summary: 'Podsumowanie',
    },
    happening: {
      selectNumberOfPlayers: 'Wybierz liczbę osób',
      selectDateAndTime: 'Wybierz datę i godzinę',
      date: 'Data',
      time: 'Godzina',
      selectSpace: 'Wybierz przestrzeń',
      selectTicketType: 'Wybierz rodzaj biletu',
      relatedProducts: 'Powiązane produkty',
      upsell: 'Wydłuż atrakcję',
      book: 'Rezerwuj',
      checkDiscount: 'Sprawdź zniżkę',
      enterDiscountCode: 'Wpisz kod rabatowy',
      noMoreSlots: 'Niestety w wybranym dniu nie można wykonać rezerwacji.',
      selectTime: 'Wybierz godzinę',
      availability: 'Dostępność',
      buttonAdd: 'Dodaj do koszyka',
    },
    insurance: {
      accidentInsurance:
        'Czy jesteś zainteresowany/a ubezpieczeniem biletu na wypadek braku możliwości udziału w wydarzeniu np. z powodu urazu ciała w wyniku NW, czasowej niezdolności do pracy, kolizji drogowej, lub poważnego zachorowania?',
      birthdayPlaceholder: 'RRRR-MM-DD',
      choose: 'Chcę ubezpieczyć bilet/y.',
      consumerRights: 'Obowiązki informacyjne wynikające z ustawy o prawach konsumenta',
      iAmAdult: 'Czy masz ukończone 18 lat?',
      iLiveInPoland: 'Czy mieszkasz na stałe w Polsce?',
      insuranceBirthDateHelperText: 'Podaj swoją datę urodzenia.',
      insuranceHeader: 'Ubezpieczenie biletów',
      insuranceProductInfo: 'Informacje o produkcie ubezpieczeniowym (PID)',
      invalidInsuranceData: 'Dane ubezpieczeń zostały załadowane niepoprawnie.',
      invalidInsuranceFormData:
        'Sprawdź, czy możesz zaznaczyć pola z gwiazdką. Jeśli nie, to ubezpieczenie nie jest odpowiednie dla Ciebie.',
      insuranceNotAvailableforBasket:
        'Ubezpieczenie jest tymczasowo niedostępne dla biletów dodanych do koszyka. Aby dokupić ubezpieczenie, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Going.',
      insuranceNotAvailableforBasketEmpik:
        'Ubezpieczenie jest tymczasowo niedostępne dla biletów dodanych do koszyka. Aby dokupić ubezpieczenie, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Empik Bilety.',
      invalidTicketPrice:
        'Ubezpieczenie dostępne tylko dla biletów w cenie od 5 do 2500 zł. Aby skorzystać z ubezpieczenia, usuń z koszyka bilety w cenie spoza tego przedziału.',
      moreInsuranceInfo: 'Ogólne warunki ubezpieczenia',
      rodo: 'Przetwarzanie danych osobowych',
      tooManyTickets:
        'Maksymalna liczba biletów, które można objąć ubezpieczeniem to 6. Aby móc z niego skorzystać, usuń bilety z koszyka tak, aby nie przekraczały tej liczby.',
      tooltipPart1: 'Ubezpieczenie obejmuje nieobecność na wydarzeniu w wyniku np.:',
      tooltipPart2:
        'Możesz uzyskać zwrot ceny zakupu biletu/-ów na wydarzenie. Szczegóły ubezpieczenia znajdziesz w ogólnych warunkach ubezpieczenia. Polisę prześlemy w wiadomości e-mail po zawarciu umowy ubezpieczenia. Ubezpieczycielem jest UNIQA.',
      tooltipLi1: 'wypadku lub kolizji drogowej',
      tooltipLi2: 'opóźnienia środka transportu',
      tooltipLi3: 'poważnego zachorowania',
      tooltipLi4: 'złamania kości',
      tooltipLi5: 'urazu ciała w wyniku NW',
      tooltipLi6: 'zachorowania dziecka',
      tryAgain: 'Spróbuj ponownie',
    },
    invoice: {
      address: 'Adres',
      city: 'Miasto',
      iWantInvoice: 'Chcę otrzymać fakturę',
      legalA:
        'Zgodnie ze zmianami obowiązującymi od 01 stycznia 2020 roku, aby otrzymać fakturę VAT należy przy zakupie zaznaczyć opcję "chcę otrzymać fakturę" ',
      legalB:
        'i wskazać dane do jej wystawienia. Zgodnie ze zmianami art. 106b ust. 5–7, art. 109a ustawy z 11 marca 2004 r. o podatku od towarów i usług j.t. Dz.U. z 2018 r. poz. 2174, ost.zm. Dz.U. z 2019 r. poz. 2200 oraz art. 15 ustawy z 4 lipca 2019 r. o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw Dz.U. z 2019 r. poz. 1520, gdy opcja ta nie jest zaznaczona sprzedawca nie ma obowiązku wystawienia kupującemu faktury VAT. W przypadku zaznaczenia chęci otrzymania faktury wyrażasz zgodę na przesłanie jej drogą elektroniczną. ',
      less: '[mniej]',
      more: '[więcej]',
      name: 'Nazwa firmy lub imię i nazwisko',
      nip: 'NIP',
      zip: 'Kod pocztowy',
      country: 'Kraj',
    },
    paymentMethod: {
      blik: 'BLIK',
      card: 'Karta płatnicza',
      choosePaymentMethod: 'Wybierz metodę płatności',
      payu: 'PayU',
      mBank: 'mBank',
      twisto: 'Twisto',
      twistoDescription: 'Kup teraz, zapłać za 30 dni',
      apple: 'Apple Pay',
      google: 'Google Pay',
      emptyPaymentMethods:
        'Brak dostępnych metod płatności. Niektóre z zaznaczonych biletów mogą mieć wymuszone metody platności.',
      confirmCardData: 'Potwierdź dane karty',
      cardDataConfirmed: 'Dane karty potwierdzone poprawnie!',
      cardDataError: 'Nie udało się potwierdzić danych karty. Spróbuj ponownie.',
      paypal: 'PayPal',
      cardBrandError: 'Nie obsługujemy tego typu karty.',
      ecard: 'BLIK, karta, przelew',
    },
    pickUpWay: {
      courierDelivery: 'Wysyłka kurierem ',
      pickUp: 'Wybierz sposób odbioru',
      pickUpProduct: 'Podaj dane do przesyłki produktów',
      rightAway: 'Bilety otrzymasz od razu',
      sendAsEmail: 'Wysyłka na adres e-mail',
      tenDaysDispatch: 'Wysyłka w ciągu 10 dni',
    },
    pools: {
      additionalData: 'Dane dodatkowe',
      dependency: 'Wpisz kod biletu lub karnetu upoważniający do wyboru danej puli.',
      dependencyPlaceHolder: 'Kod biletu lub kod karnetu',
      details: 'Szczegóły',
      noPoolsAvailable: 'Brak dostępnych pul biletowych',
      sameCurrency: 'W zamówieniu znajduje się bilet w innej walucie. Usuń go, aby wybrać tę pulę.',
      differentType:
        'W koszyku znajduje się typ biletu, który wyklucza wspólny zakup. Usuń go, aby kontynuować',
      title: 'Wybierz rodzaj i liczbę biletów',
      choosePlaces: 'Wybierz miejsca',
      floorPlan: 'Zobacz plan sali',
      numberedSeats: 'Miejsca numerowane',
      unNumberedSeats: 'Miejsca nienumerowane',
    },
    postAddress: {
      cityText: 'Miasto',
      collectorsTicket: 'Wzór biletu kolekcjonerskiego',
      countryText: 'Kraj',
      firstNameText: 'Imię',
      infoText:
        'Wybierając tę formę odbioru, wyrażasz zgodę na przekazanie danych osobowych do operatora wysyłki.',
      lastNameText: 'Nazwisko',
      localText: 'Lokal',
      phoneText: 'Numer telefonu',
      streetNumberText: 'Numer',
      streetText: 'Ulica',
      zipText: 'Kod pocztowy',
    },
    products: {
      title: 'Wybierz rodzaj i liczbę produktów',
      collectorTicket:
        'Bilet kolekcjonerski nie upoważnia do wstępu na wydarzenie. Wejście możliwe wyłącznie przy pomocy biletu elektronicznego.',
      ticket: 'Bilet',
      collectorTicketData: 'Podaj dane, które mają się pojawić na bilecie kolekcjonerskim.',
    },
    summary: {
      additionalProducts: 'Dodatkowe produkty:',
      amount: 'Liczba:',
      courierDelivery: 'Wysyłka kurierem:',
      currency: ' zł',
      defaultPriceType: 'Bilet normalny',
      empty: 'brak',
      finalPrice: 'Razem do zapłaty:',
      formInvalid: 'Niestety formularz nie jest kompletny. Wypełnij brakujące pola.',
      giftWrapper: 'Opakowanie prezentowe:',
      iHaveCoupon: 'Mam kod rabatowy',
      insurance: 'Ubezpieczenie:',
      insuranceWithCurrentEventTickets: 'Ubezpieczenie (koszyk + aktywne wydarzenie):',
      noData: 'Brak danych',
      numberOfPlayers: 'Liczba osób:',
      payment: 'do zapłaty:',
      pricePerItem: 'Cena/szt.:',
      serviceFee: 'Opłata serwisowa:',
      serviceFeeInformation:
        'Opłata serwisowa obejmuje koszty opłaty transakcyjnej, automatyzacji wysyłki biletu, obsługi posprzedażowej oraz obsługę ewentualnych zgłoszeń indywidualnych i reklamacji. Jest pobierana przy zakupie biletu wraz ze stawką bazową.',
      shipment: 'Dostawa:',
      space: 'Wybrana przestrzeń:',
      ticketTransferFee: 'Opłata za przekazanie biletu:',
      ticketsAmount: 'Liczba biletów:',
      ticketsPrice: 'Cena biletów:',
      time: 'Czas:',
      totalPrice: 'Łączna cena:',
      yourBasket: 'Twój koszyk',
      yourReservation: 'Twoja rezerwacja',
      extendedSlotPrice: 'Cena normalna',
      extendedSlotDiscount: 'Kwota zniżki',
      upsell: 'Wydłużenie gry',
      differentType:
        'W koszyku znajduje się typ biletu, który wyklucza wspólny zakup. Usuń go, aby kontynuować',
      differentPartner:
        'W koszyku znajdują się bilety sprzedawane przez różnych sprzedawców. Usuń je, aby kontynuować',
    },
    term: {
      and: 'oraz',
      iAccept: 'Akceptuję',
      iAcceptTerms: 'Akceptuję regulamin',
      privacyPolicy: (appName: string) => `Politykę prywatności ${appName}`,
      read: '[przeczytaj]',
      regulations: (appName: string) => `Regulamin ${appName}`,
    },
    extendedSlots: {
      delete: 'Usuń',
      info: 'Twoja gra została wydłużona! Potrwa od ',
    },
    multipleSlot: {
      extendGame: 'Wydłuż swoją grę!',
      description:
        'Daj sobie więcej czasu! Wybierz, o ile chcesz przedłużyć grę lub pomiń ten krok.',
      decline: 'Nie chcę wydłużać',
      extend: 'Przedłuż grę',
      min: 'min',
      cheaper: 'taniej',
    },
    timeSlots: {
      rememberText: 'Pamiętaj żeby pojawić się na miejscu przynajmniej 20 min. przed czasem!',
    },
  },
  cookieBar: {
    title: 'Ta strona używa plików cookie.',
    readMore: 'Dowiedz się więcej',
  },
  eventBoxes: {
    summary: {
      afterMidnight: '(po pólnocy)',
    },
  },
  dates: {
    locale: Languages.Polski,
    today: 'Dzisiaj',
    tomorrow: 'Jutro',
  },
  datesFilter: {
    clear: 'Wyczyść',
    date: 'Data',
    filterName: 'data',
    selectDate: 'wybierz datę lub zakres',
    addDate: 'dodaj drugą datę, aby wybrać zakres',
    show: 'Pokaż wyniki',
    thisMonth: 'miesiąc',
    thisWeek: 'tydzień',
    today: 'dziś',
    tomorrow: 'jutro',
    weekend: 'weekend',
  },
  eventEmbed: {
    check: 'Sprawdź',
    ticketsFrom: 'Bilety od',
  },
  forms: {
    labels: {
      [FormFields.FIRSTNAME]: 'imię',
      [FormFields.LASTNAME]: 'nazwisko',
      [FormFields.EMAIL]: 'e-mail',
      [FormFields.CODE]: 'kod biletu',
      [FormFields.BANKACCOUNTNUMBER]: 'Numer rachunku bankowego',
      [FormFields.PASSWORD]: 'hasło',
      [FormFields.PHONE]: 'numer telefonu',
      [FormFields.OLDPASSWORD]: 'stare hasło',
      [FormFields.REPEATPASSWORD]: 'potwierdź hasło',
      [FormFields.TERMS]: {
        iAccept: 'Akceptuję ',
        termsLink: 'regulamin sprzedaży',
        and: ' oraz ',
        privacyPolicyLink: 'politykę prywatności',
      },
      [FormFields.NEWSLETTER]: {
        header: 'Chcę dostawać newsletter Going. Mustsee.',
        agreement:
          'Zapisując się do Newslettera Aplikacji Going wyrażam zgodę na przetwarzanie podanych przeze mnie moich danych osobowych przez GOING Spółkę z ograniczoną odpowiedzialnością z siedzibą w Warszawie, Aleje Jerozolimskie 107, 02–011 Warszawa w celu otrzymywania informacji handlowych według rozumienia art. 9 ustawy z dnia 9 czerwca 2017 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2017 r. poz. 1219)',
      },
    },
    validation: {
      [FormFields.FIRSTNAME]: { required: 'Podaj imię' },
      [FormFields.LASTNAME]: { required: 'Podaj nazwisko' },
      [FormFields.EMAIL]: { required: 'Podaj e-mail', correct: 'Podaj poprawny e-mail' },
      [FormFields.QUANTITY]: {
        required: 'Wybierz ilość',
        minMsg: (min: number) => `Wybierz conajmniej ${min}`,
      },
      [FormFields.CODE]: { required: 'Podaj kod biletu' },
      [FormFields.BANKACCOUNTNUMBER]: { required: 'Numer konta bankowego jest wymagany' },
      termField: { required: 'Twoja zgoda jest niezbędna' },
      [FormFields.PASSWORD]: {
        required: 'Musisz podać hasło',
        correct: 'Podaj poprawne hasło',
        minMsg: (min: number) => `Hasło musi mieć conajmniej ${min} znaków`,
      },
      [FormFields.REPEATPASSWORD]: {
        required: 'Musisz potwierdzić hasło',
        correct: 'Hasła muszą być takie same',
      },
      [FormFields.TERMS]: {
        required: 'Musisz zaakceptować regulamin',
      },
      [FormFields.NEWSLETTER]: {
        required: 'Musisz wyrazić zgodę na otrzymywanie newslettera',
      },
      [FormFields.PHONE]: {
        required: 'Podaj numer telefonu',
        correct: 'Podaj poprawny numer telefonu',
      },
    },
  },
  locationFilter: {
    filterTitle: 'miasto',
  },
  maintenance: {
    featureUnavailable: 'Funkcjonalność tymczasowo niedostępna.',
    goToSearch: 'Idź do kalendarium',
  },
  meta: {
    buyTicket: 'KUP BILET',
    concertTickets: 'Bilety na koncerty',
    event: 'Wydarzenie',
    eventInfo: 'Bilety i informacje o wydarzeniu:',
    events: 'Wydarzenia',
    loading: 'Ładowanie...',
    metaDesc:
      'Going. Twój przewodnik do świata muzyki i kultury. Znajdź najciekawsze wydarzenia w okolicy i kup bilety już dziś!',
    place: 'Miejsce',
    selection: 'Selekcja',
    ticketsFor: 'Bilety na',
    ticketsForEvents: 'Bilety na wydarzenia',
  },
  search: {
    searchbarPlaceholder: 'Czego szukasz?',
    found: 'Znaleziono:',
    noResults: 'Brak wyników.',
    items: {
      zero: 'wyników',
      one: 'wynik',
      two: 'wyniki',
      few: 'wyniki',
      many: 'wyników',
      other: 'wyników',
    },
  },
  searchCard: {
    [SearchTypes.TAG]: 'tag',
    [SearchTypes.PLACE]: 'miejsce',
    [SearchTypes.ARTIST]: 'artysta',
    [SearchTypes.RUNDATE]: 'wydarzenie',
    [SearchTypes.EVENT]: 'trasa',
    [SearchTypes.ACTIVITY]: 'aktywność',
    onSale: 'W sprzedaży!',
  },
  searchTypesFilter: {
    all: 'typ',
    [SearchTypes.TAG]: 'tagi',
    [SearchTypes.PLACE]: 'miejsca',
    [SearchTypes.ARTIST]: 'artyści',
    [SearchTypes.RUNDATE]: 'wydarzenia',
    [SearchTypes.EVENT]: 'trasy',
    [SearchTypes.ACTIVITY]: 'aktywności',
    filterTitle: 'Wybierz typ',
  },
  share: {
    facebook: 'Udostępnij na Facebooku',
    twitter: 'Udostępnij na Twitterze',
    whatsapp: 'Udostępnij na WhatsApp',
    telegram: 'Udostępnij na Telegram',
    vk: 'Udostępnij na VK',
    email: 'Udostępnij przez e-mail',
    copyLink: 'Kopiuj link',
  },
  entryListL: {
    addBuyerStep: {
      title: 'Czy chcesz dopisać do biletu osobę, która dokonała zakupu?',
    },
    addNewPeopleToTicketStep: {
      addNewPerson: 'Dodaj kolejną osobę',
      checkData: 'Sprawdź, czy dane się zgadzają',
      confirm: 'Potwierdzam',
      title: 'Aby dokończyć proces potrzeba jeszcze',
    },
    addPeopleToTicketStep: {
      noData: 'Brak powiązanych wpisów',
      title: 'Zaznacz osoby, które chcesz dopisać do biletu',
    },
    back: 'Wstecz',
    entryTerm: {
      confirm: 'Potwierdź',
      sendEmail: 'Wyślij email',
    },
    haveTicketCodeStep: {
      haveTicketCode: 'Czy posiadasz kod biletu?',
      title: 'Uzupełnij dodatkowe dane',
    },
    next: 'Dalej',
    no: 'Nie',
    numberOfPeopleStep: {
      inputHelperText: 'Wpisz ile osób wybiera się na wydarzenie w ramach zakupionego biletu',
      inputText: 'Liczba osób na bilecie',
    },
    registerNewPerson: {
      title: 'Rejestracja',
    },
    thanksStep: {
      back: 'Powrót do początku',
      code: 'Kod Twojego powiązania to:',
      thanks: 'Dziękujemy!',
    },
    ticketStep: {
      enterTicketCode: 'Wpisz kod biletu, aby dodać do niego osoby',
      ticketCode: 'Kod biletu',
      ticketCodeHelper: 'Wpisz kod biletu otrzymany mailowo',
    },
    title: 'Lista wejściowa',
    yes: 'Tak',
  },
  errors: {
    authIdIsRequiredValidator: 'Identyfikator jest wymagany',
    cardActiveText:
      'Karta Mój Empik została odnaleziona. Korzyści usługi Premium zostały aktywowane.',
    checkingCardErrorValidator:
      'Nie udało się zweryfikować poprawności karty. Spróbuj ponownie później.',
    checkingCardInactiveValidator:
      'Karta Mój Empik została odnaleziona ale nie posiada ona usługi Premium.',
    checkingCardNomatchValidator:
      'Adres e-mail i kod klienta Premium nie są zgodne lub nie posiadasz aktywnej usługi Empik Premium.',
    checkingCardValidator: 'Trwa sprawdzanie numeru karty...',
    checkingThisRegulationsIsRequiredValidator: 'Zaznaczenie tej zgody jest wymagane',
    dependencyCodeIsRequired: 'Kod biletu / karnetu jest wymagany',
    discountCodeIsRequired: 'Kod rabatowy jest wymagany',
    emailAddressFieldIsRequiredValidator: 'Uzupełnij pole z adresem e-mail',
    emailAddressIsRequiredValidator: 'Adres e-mail jest wymagany',
    firstNameIsRequiredValidator: 'Imię jest wymagane',
    incorrectData: 'Zły format',
    incorrectEmpikCardFormatValidator: 'Niepoprawny format numeru karty',
    incorrectNip: 'NIP może zawierać tylko cyfry',
    incorrectPhoneNumber: 'Podany numer jest nieprawidłowy',
    incorrectPostCodeValidator: 'Niepoprawny kod pocztowy',
    paymentMethodIsRequired: 'Wybierz metodę płatności',
    lastNameIsRequiredValidator: 'Nazwisko jest wymagane',
    countryIsRequiredValidator: 'Kraj jest wymagany',
    providedEmailAddressIsIncorrectValidator: 'Podany adres e-mail jest niepoprawny',
    providedIdIsIncorrectValidator: 'Podany identyfikator jest nieprawidłowy',
    sorry: 'Przepraszamy',
    thisFieldIsRequiredValidator: 'Uzupełnij',
    wrongBirthDate: 'Wprowadzona data urodzenia jest nieprawidłowa lub użyto złego formatu',
  },
  errorModals: {
    title: 'Popraw formularz zgodnie z informacją powyżej.',
    helper:
      'Jeśli to nie pomoże, możesz też usunąć bilety powodujące problemy i przejść do płatności, klikając OK.',
    checkInternet:
      'Podczas ładowania strony wystąpił błąd. Upewnij się, że masz dostęp do internetu i',
    wentWrong: 'Upss... Coś poszło nie tak',
    refreshPage: 'odśwież stronę',
    sendCode: 'Gdyby błąd nadal występował wyślij nam informację z tym kodem',
    errorCode: '404 - Strony nie znaleziono',
    notFound: 'Strona o podanym adresie nie istnieje.',
    question: 'Czy na pewno szukasz ',
  },
  event: {
    additionalDescriptionTitle: 'informacja organizatora',
    andOthers: 'i inne',
    anotherDateText: 'Zobacz inne terminy',
    articles: 'artykuły',
    artistBio: 'o artyście',
    buyTicket: 'Kup bilet',
    choose: 'WYBIERZ',
    currency: ' zł',
    details: 'Rodzaje biletów',
    disabledTooltip: 'Bilety aktualnie niedostępne w ',
    eventStart: 'start wydarzenia',
    forFansOf: 'podobni artyści',
    from: 'od',
    gallery: 'galeria',
    gatesOpening: 'otwarcie bram',
    hour: 'godz.',
    noDescription: 'brak opisu',
    noInfoAboutPrice: 'Brak informacji o cenie biletu',
    otherDates: 'inne terminy',
    place: 'miejsce',
    placeLink: 'przejdź do strony tego miejsca',
    readMore: 'Czytaj więcej',
    rundates: 'terminy',
    participatingArtists: 'występują',
    samePlaceEventsTitle: 'inne wydarzenia w tym miejscu',
    serviceFeeCaption: (fee: string) => `Cena zawiera ${fee} opłaty serwisowej.`,
    serviceFeeInformationBox:
      'Podana cena zawiera opłatę serwisową, jej wysokość można sprawdzić bezpośrednio w koszyku. Więcej informacji w Regulaminie.',
    similarEventsTitle: 'podobne wydarzenia',
    tickets: 'bilety',
    timetable: 'Plan Wydarzenia',
    goingSeller:
      'Going. jest sprzedawcą biletu i nie jest odpowiedzialny za właściwe przygotowanie i przeprowadzenie wydarzenia.',
  },
  favorites: {
    stayFresh: 'Bądź na bieżąco!',
    [FavoritesTypes.Artists]: 'obserwuj artystów',
    [FavoritesTypes.Places]: 'obserwuj miejsca',
    [FavoritesTypes.Tags]: 'obserwuj tagi',
    termHeader:
      'Aby móc obserwować artystów, miejsca i tagi, wymagana jest akceptacja poniższej zgody.',
    noFavorites: 'Wybierz conajmniej jeden element.',
    labels: {
      [FavoritesTypes.Artists]: 'Artyści',
      [FavoritesTypes.Rundates]: 'Wydarzenia',
      [FavoritesTypes.Places]: 'Miejsca',
      [FavoritesTypes.Selection]: 'Listy',
      [FavoritesTypes.Tags]: 'Tagi',
    },
    addedToFavorites: 'Dodano do ulubionych',
    removedFromFavorites: 'Usunięto z ulubionych',
    logInEvents: 'Zaloguj się, aby dodać do kalendarza',
    logIn: 'Zaloguj się, aby dodać do ulubionych',
  },
  following: {
    unfollow: 'Przestań obserwować',
    selectionHeader: 'Obserwuj selekcję',
    artistHeader: 'Obserwuj artystę',
    artistPrompt: 'Podaj swój adres e-mail, aby otrzymać informacje dotyczące tego artysty. ',
    term: 'Chcę otrzymywać od Going. na podany przeze mnie adres e-mail informacje marketingowe dotyczące aplikacji i serwisów Going. oraz spółki Going.',
    getUpdates:
      'Otrzymuj powiadomienia o istotnych zmianach, nowych wydarzeniach i innych aktualnościach.',
    salesHeader: 'Powiadom mnie o starcie sprzedaży',
    salesPrompt:
      'Podaj swój adres e-mail, aby otrzymać informację o starcie sprzedaży na to wydarzenie.',
    ticketsHeader: 'Powiadom mnie o biletach',
    ticketsPrompt:
      'Bilety zostały wyprzedane. Podaj swój adres e-mail, aby otrzymać informację, jeśli bilety ponownie pojawią się w sprzedaży.',
    placeHeader: 'Obserwuj miejsce',
    placePrompt:
      'Podaj swój adres e-mail, aby otrzymać informacje dotyczące wydarzeń w tym miejscu.',
    rundateHeader: 'Dodaj do kalendarza',
    rundateRemove: 'Usuń z kalendarza',
    rundatePrompt:
      'Podaj swój adres e-mail, aby obserwować to wydarzenie i dodać je do kalendarza.',
    yourEmail: 'Twój adres e-mail',
    success: 'Udało się!',
    successDownload: 'Pobierz aplikację Going., by mieć wszystko pod ręką i być zawsze na bieżąco.',
    successButtonYes: 'Pobieram',
    successButtonNope: 'Nie chcę teraz',
    failure: 'Coś poszło nie tak, spróbuj jeszcze raz.',
    subscribeToFollow:
      'Wyraź zgodę, aby otrzymywać powiadomienia e-mail o obserwowanych artystach, wydarzeniach i miejscach',
  },
  formik: {
    checkbox: {
      choose: 'Wybierz',
      chosen: 'Wybrano',
    },
  },
  formio: {
    langButton: 'Click here for english',
    title: 'Formularz zgłoszeniowy',
  },
  happening: {
    priceTypes: {
      choose: 'Wybierz',
      chosen: 'Wybrano',
      defaultTicket: 'Bilet normalny',
    },
    space: {
      unavailableTicketTypes:
        'Ta przestrzeń jest niedostępna ponieważ zaznaczono następujące typy biletów: ',
    },
    upsell: {
      add: 'Dodaj',
      added: 'Dodano',
      clickAgainToResign: 'Kliknij ponownie, aby zrezygnować',
      getAdditionalSlot: 'Dodaj dodatkowy czas',
      upsellDuration: 'Rezerwacja zostanie przedłużona o ',
      minutes: 'minut',
    },
  },
  landing: {
    faqSection: 'Faq',
    partners: 'Partnerzy',
    tickets: 'Bilety',
  },
  menu: {
    comingSoon: 'Dostępne wkrótce',
  },
  modals: {
    ActionMessage: {
      close: 'Zamknij',
      no: 'NIE',
      yes: 'TAK',
    },
  },
  notifications: {
    linkCopied: 'Skopiowano link do schowka',
    goToInbox: 'Przejdź do skrzynki odbiorczej',
    genericError: 'Coś poszło nie tak. Spróbuj ponownie później.',
    mustLogin: 'Musisz być zalogowany, aby móc korzystać z tej funkcji.',
  },
  passwordReset: {
    passwordResetForm: {
      confirmNewPass: 'Potwierdź hasło',
      emailAddress: 'Adres email:',
      emailAddressBoundToAccount: 'Adres email przypisany do konta',
      incorrectEmail: 'Podany adres e-mail jest niepoprawny',
      newPass: 'Nowe hasło',
      pageTitle: 'Resetuj hasło',
      requiredField: 'Uzupełnij pole',
      submitButton: 'RESETUJ HASŁO',
      successMessage: 'Udało się! Twoje hasło zostało zmienione.',
    },
  },
  payment: {
    paymentFail: {
      contactWithUs:
        'Skontaktuj się z nami i daj nam znać, co się stało opisując dokładnie sytuację, w której wystąpił błąd. Sprawdzimy, co jest grane najszybciej jak to możliwe.',
      header: 'Coś poszło nie tak...',
      report: 'Zgłoś problem',
      title: 'Błąd podczas transakcji',
    },
    paymentSuccess: {
      altText: 'aplikacja Going.',
      altText2: 'Pobierz aplikację',
      beUpToDate:
        'Bądź na bieżąco z najlepszymi wydarzeniami, skomponuj swój kalendarz eventowy i trzymaj bilety zawsze pod ręką w aplikacji.',
      confirm: 'Potwierdzenie transakcji',
      currency: 'zł',
      discoverApp: 'Odkryj aplikację Going.',
      discoverApp2: 'odkryj apkę going.',
      downloadApp: 'Pobierz apkę Going.',
      doYouLikeGoing:
        'Podoba Ci się Going.? Oceń naszą pracę na Google i daj nam znać jak sobie radzimy.',
      enterNumber:
        'Wpisz numer, na który chcesz otrzymać SMS z linkiem do pobrania aplikacji Going.',
      error: 'Błąd',
      getMore: 'Zyskaj jeszcze więcej z aplikacją Going.!',
      getTicketByEmail:
        'Bilety dostaniesz mailem maksymalnie w ciągu 24h, ale z reguły uwijamy się szybciej. Bilety znajdziesz również w naszej aplikacji.',
      getTicketByEmailEmpik:
        'Bilety dostaniesz mailem maksymalnie w ciągu 24h, ale z reguły uwijamy się szybciej. Bilety znajdziesz również w aplikacji Going.',
      header: 'Dzięki za złożenie zamówienia!',
      helperText: 'Numer jest niepoprawny',
      importantInfo: 'Ważne info!',
      informationWillBeSend:
        'Zaproszenie do przejęcia biletów zostanie wysłane na adres wskazany w formularzu przekazania.',
      iWantToReviewNow: 'Chcę ocenić teraz',
      openApp: 'otwórz aplikację',
      orDownload: 'lub pobierz bezpośrednio z Twojego sklepu',
      place: 'Miejsce:',
      prefix: '+48',
      reviewUs: 'Oceń nas!',
      reviewUsInGoogle: 'Oceń nas w Google',
      noMaybeLater: 'Nie, może później',
      send: 'wyślij',
      somethingWentWrong: 'Coś poszło nie tak',
      successMessage: 'Sms wysłany!',
      summary: 'Razem:',
      sugestNotToPrint:
        'Przypomnij osobie, która odbiera bilet, że nie musi drukować tego biletu. Może go pokazać na ekranie telefonu.',
      thanks: 'Dziękujemy.',
      thanksForReceiving: 'Dziękujemy za odbiór biletu.',
      tickets: 'Liczba biletów:',
      transfer: 'Proces przekazania biletów został rozpoczęty.',
      waiting: 'Teraz czekamy na potwierdzenie transakcji...',
      yourBank:
        'Jeśli Twój bank nie potwierdzi transakcji w ciągu 10 min, zamówienie może zostać anulowane.',
      yourOrder: 'Twoje zamówienie',
    },
  },
  place: {
    check: 'Zobacz',
    checkEvents: 'Sprawdź wydarzenia',
    location: 'Lokalizacja',
    showMore: 'Pokaż więcej',
    noEvents: 'Brak wyników...',
    header: 'aktualne wydarzenia',
    map: 'zobacz na mapie',
    info: 'informacje ogólne',
  },
  pluralRules: {
    ticket: {
      zero: 'biletów',
      one: 'bilet',
      two: 'bilety',
      few: 'bilety',
      many: 'biletów',
      other: 'biletów',
    },
  },
  price: {
    from: 'od',
  },
  promotionBanner: {
    altText: 'o aplikacji',
  },
  searchbar: {
    placeholder: 'Czego szukasz?',
  },
  sendMailSuccess: {
    title: 'Bilety są już w drodze!',
    description: 'Wysłaliśmy bilety na podany w zamówieniu adres e-mail.',
    button: 'Przejdź do strony głównej',
  },
  settings: {
    account: 'Konto',
    editData: 'Edytuj dane',
    editPassword: 'Zmień hasło',
    communication: 'Komunikacja',
    newsletter: 'Newsletter',
    letUsWrite: 'Pozwól nam czasem wysłać do Ciebie list',
    misc: 'Pozostałe',
    language: 'Język',
    deleteAccount: 'Usuń konto',
  },
  showMore: {
    less: 'schowaj',
    more: 'szczegóły',
  },
  similarPlaces: {
    header: 'Podobne miejsca',
  },
  ticketManagement: {
    temporarilyUnavailable: {
      contactUs: 'skontaktuj się z nami.',
      ifYouHaveAnyQuestions: 'Jeśli masz pytania,',
      formAvailableUnder: 'Formularz dostępny pod adresem:',
      wereSorry: 'Przepraszamy, usługa jest tymczasowo niedostępna.',
    },
    ticketForm: {
      ticketDataTitle: 'Podaj dane biletu',
      email: 'Podaj email użyty podczas zakupu biletu.',
    },
    myTicket: {
      thanks: 'Dziękujemy!',
      noTicketTitle: 'Nie znaleziono biletu',
      noTicketDescription:
        'Sprawdź czy podałeś poprawny adres e-mail i kod biletu. Jeśli problem będzie się powtarzał, skontaktuj się z nami, używając formularza w zakładce "pomoc".',
      steps: ['Start', 'Potwierdź', 'Gotowe'],
      actionsHeader: 'Zarządzanie biletem',
      downloadHeader: 'Pobierz bilet',
      downloadTicket: 'Twój bilet (.PDF)',
      switchTicket: 'Przejdź do innego biletu zakupionego na ten sam e-mail.',
      seller: 'Sprzedawca',
      festSeller:
        'FEST FESTIVAL SP. Z O.O z siedzibą w Warszawie 00-375, ul. Smolna 38/1, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla M. St. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS0000766893, posiadająca NIP 5252777162 oraz REGON 382330543.',
      goingSeller:
        'Going sp. z o.o. z siedzibą w Warszawie, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000588000; zarejestrowana przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego; kapitał zakładowy w wysokości: 21.900,00 zł.; NIP: 5272751272; REGON: 363058596',
      contactOrganizer: 'W celu zwrotu kwoty za bilet skontaktuj się z organizatorem: ',
      actions: {
        [TicketActions.CANCEL_TRANSFER]: {
          name: 'Anuluj przekazanie',
          prompt: 'Czy na pewno chcesz anulować przekazanie biletu?',
          description:
            'Możesz anulować przekazanie biletu, jeśli nie zostało jeszcze zatwierdzone przez odbiorcę.',
          success:
            'Przekazanie biletu zostało anulowane. Twój bilet powinien wrócić do Ciebie w ciągu godziny.',
          loading: 'Trwa anulowanie przekazania biletu...',
        },
        [TicketActions.CHANGE_TIME]: {
          name: 'Zmień godzinę',
          prompt: 'Czy na pewno chcesz zmienić godzinę?',
          description: 'Możesz zmienić godzinę wejścia na bilecie.',
          success: 'Twoje zgłoszenie zostało przyjęte. Bilet został zmieniony.',
          loading: 'Trwa zmiana godziny...',
        },
        [TicketActions.HISTORY]: {
          name: 'Historia biletu',
          prompt: 'Czy pobrać historię biletu?',
          description: 'Pobierz historię swojego biletu i jego aktualny status.',
          success: 'Historia biletu została pobrana.',
          loading: 'Trwa pobieranie historii biletu...',
        },
        [TicketActions.RETURN]: {
          name: 'Zwróć bilet',
          agency: {
            beforeSettlement: {
              name: 'Zwróć bilet i opłatę serwisową',
              description: 'Możesz zwrócić zakupiony bilet i opłatę serwisową.',
            },
            afterSettlement: {
              name: 'Zwróć opłatę serwisową. Po zwrot ceny biletu zgłoś się do organizatora ',
              description: 'Możesz zwrócić opłatę serwisową.',
            },
          },
          prompt: 'Czy na pewno chcesz zwrócić bilet?',
          description: 'Możesz zwrócić zakupiony bilet.',
          success:
            'Twoje zgłoszenie zostało zarejestrowane. O dalszych krokach poinformujemy Cię drogą mailową.',
          loading: 'Trwa zwracanie biletu...',
        },
        [TicketActions.TRANSFER]: {
          name: 'Przekaż bilet',
          prompt: 'Czy na pewno chcesz przekazać bilet innej osobie?',
          description:
            'Tutaj możesz przekazać swój bilet! Uzupełnij dane osoby, której chcesz przekazać bilet oraz wybierz liczbę biletów do przekazania . Osoba odbierająca otrzyma wiadomość, w której potwierdza odbiór biletu, opłaca cenę biletu i opłatę za przekazanie. Następnie otrzymasz wiadomość z potwierdzeniem przekazania oraz zwrot za bilet pomniejszony o opłatę manipulacyjną. Wysokość opłaty jest zmienna i zależna od ustaleń z organizatorem wydarzenia - poznasz ją w kolejnych krokach.',
          success:
            'Zaproszenie do odbioru biletu zostało wysłane na podany adres e-mail. Osoba, której przekazujesz bilet ma 72h na jego akceptację. Po upływie tego czasu bilet powróci do Ciebie.',
          loading: 'Trwa przekazywanie biletu...',
        },
      },
      actionErrorTitle: 'Wystąpił nieoczekiwany błąd',
      actionErrors: {
        [TicketActionErrors.INTERNAL_ERROR]: 'Błąd wewnętrzny',
        [TicketActionErrors.INVALID_ACTION]:
          'Nieprawidłowa akcja dla tego biletu. Spróbuj ponownie z innym biletem.',
        [TicketActionErrors.INVALID_EMAIL]:
          'Adres email odbiorcy biletu jest niepoprawny. Spróbuj ponownie.',
        [TicketActionErrors.INVALID_TOKEN]:
          'Sesja przedawniona. Naciśnij przycisk poniżej, aby rozpocząć ponownie.',
        [TicketActionErrors.UNKNOWN_ERROR]: 'Nieznany błąd. Spróbuj ponownie.',
      },
    },
    transferTicket: {
      formTitle: 'Dane osoby odbierającej',
      emailHelper:
        'Przekazane bilety wyślemy na adres podany powyżej. Upewnij się, że jest on poprawny.',
      credentialsHelper:
        'Bilet jest imienny. Podaj prawdziwe dane osoby, która otrzyma bilet, ponieważ obsługa może poprosić ją o dokument potwierdzający tożsamość.',
      numToTransfer: 'Liczba biletów do przekazania: ',
      yourFee: 'Ty zapłacisz: ',
      recipientFee: 'Odbiorca zapłaci: ',
      transferQuantity: 'Liczba biletów do przekazania:',
    },
    history: {
      transfer: 'Przekazanie:',
      default: 'nieznany',
      [TicketTransferStatus.PENDING]: 'oczekuje',
      [TicketTransferStatus.CANCELED]: 'anulowany',
    },
    cancelTransfer: {
      formTitle: 'Wybierz transfer biletu, który chcesz anulować',
    },
    returnTicket: {
      formTitle: 'Wybierz formę zwrotu środków',
      [TicketRefundOptions.TRANSFER]: 'Przelew na konto',
      [TicketRefundOptions.VOUCHER_EMPIK]: 'Voucher Empik',
      [TicketRefundOptions.VOUCHER_GOING]: 'Voucher Going',
    },
  },
  ticketsManagement: {
    changeDateTitle: 'zmień datę',
    transferTicketTitle: 'przekaż bilet',
    receiveTitle: 'odbierz bilet',
    changeDate: {
      selectDate: 'Wybierz datę',
      selectTime: 'Wybierz godzinę',
    },
    ticketReception: {
      contactForm: 'formularz kontaktowy.',
      getTicket: 'Odbierz bilety',
      hello: 'Witaj, ',
      namedTicket:
        'Pamiętaj, że bilety są imienne. Jeśli w Twoich danych pojawił się błąd, od razu nam to zgłoś poprzez ',
      poolSaleUnavailable:
        'Niestety przekazywanie biletów na wydarzenia z wyłączoną lub zakończoną sprzedażą chwilowo nie jest możliwe. Prosimy o kontakt z naszym działem Supportu poprzez formularz kontaktowy (link dostępny w stopce strony pod opisem "Kontakt") oraz zawarcie w zgłoszeniu kodu przekazywanego biletu. Dziękujemy za wyrozumiałość.',
      submitButton: 'Odbierz bilety',
      toReception:
        'Aby je przyjąć, zaakceptuj poniższe zgody i użyj przycisku ODBIERZ BILETY na dole strony.',
      transferTicket: 'Na tej stronie możesz odebrać przesłane Ci bilety na to wydarzenie.',
    },
  },
  entryList: {
    welcome: {
      title: 'Witamy w ',
      secondTitle: 'Formularz rejestracyjny',
      buttonText: 'Start',
    },
    starter: {
      title: 'Wypełnij formularz',
      subtitle: 'Czy posiadasz kod biletu?',
      yes: 'Tak',
      no: 'Nie',
    },
    form: {
      title: 'Twoje dane',
      additionalPerson: 'Dane osoby towarzyszącej',
      subtitle: 'Uzupełnij poniższy formularz i zaakceptuj regulamin',
      firstName: 'Imię*',
      lastName: 'Nazwisko*',
      email: 'Adres e-mail*',
      birthDay: 'Data urodzenia (DD/MM/RRRR)*',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      validEmail: 'Upewnij się, że e-mail jest poprawny',
      didYouMean: 'Czy chodziło Ci o: ',
    },
    termsOfUse: {
      accept: 'Akceptuję ',
      statue: 'regulamin',
      and: ' oraz ',
      privacyPolicy: 'politykę prywatności*',
    },
    dataConfirmation: {
      title: 'Potwierdź dane',
      subtitle: 'Sprawdź, czy wszystko się zgadza',
      goBack: 'Wstecz',
      confirm: 'Zatwierdź',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      email: 'Adres e-mail',
      birthDay: 'Data urodzenia',
    },
    greeting: {
      title: 'Dziękujemy za rejestrację!',
      subtitle: 'Wszystko złatwione. Udanej zabawy!',
      button: 'Przejdź do strony głównej',
    },
    parentForm: {
      title: 'Zgoda opiekuna',
      subtitle:
        'Rejestracja osób niepełnoletnich wymaga zgody rodzica lub opiekuna prawnego. Wpisz dane i numer telefonu takiej osoby. Otrzyma ona od nas SMS z kodem potwierdzającym zgodę i tożsamość.',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      firstName: 'Imię rodzica lub opiekuna*',
      lastName: 'Nazwisko rodzica lub opiekuna*',
      phoneNumber: 'Numer telefonu*',
    },
    smsCode: {
      title: 'Zgoda opiekuna',
      subtitle: 'Wpisanie kodu SMS jest jednoznaczne z akceptacją regulaminu.',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      code: 'Kod SMS*',
      sendAgain: 'Wyślij ponownie kod',
      codeError: 'Niepoprawny kod',
    },
    validation: {
      requiredField: 'Pole wymagane',
      incorrectEmail: 'E-mail niepoprawny',
      invalidDate: 'Niepoprawny format daty. Poprawny format DD/MM/RRRR',
      privacyPolicy: 'Musisz zaakceptować regulamin i politykę prywatności',
      incorrectPhoneNumber: 'Niepoprawny numer telefonu',
    },
    agreementExist: {
      title: 'Użytkownik jest już zarejestrowany',
      subtitle: 'Użytkownik o podanych przez Ciebie danych został już wcześniej zarejestrowany.',
      submissionsCompleteTitle: 'Dziękujemy za rejestrację',
      submissionsCompleteHelper: 'Lista wejściowa jest już wypełniona.',
      homepage: 'Przejdź do strony głównej',
      goBack: 'Wróć',
    },
    ticketCode: {
      title: 'Wpisz kod biletu',
      placeholder: 'Kod biletu*',
      goBack: 'Wróć',
      goNext: 'Dalej',
    },
    submissionsSummary: {
      title: 'Lista wejściowa',
      helper: 'Lista osób przypisanych do biletu.',
      goBack: 'Wstecz',
      confirm: 'Zatwierdź',
      submissionExist: 'Osoba o podanych danych jest już dodana do biletu',
    },
    checkSubmission: {
      title: 'Adres e-mail',
      helper: 'Wprowadź swój adres e-mail',
      placeholder: 'Adres e-mail*',
      goBack: 'Wstecz',
      goNext: 'Dalej',
    },
    selectSubmission: {
      title: 'Dane uczestnika',
      helper: 'Wybierz dane z listy lub wprowadź nowe',
      addNew: 'Dodaj nowe dane',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      additionalPerson: 'Dane osoby towarzyszącej',
      additionalPersonHelper: 'Wybierz osobę towarzyszącą lub dodaj nową',
    },
  },
  cart: {
    continueBuying: 'Kontynuuj zakupy',
    toPay: 'do zapłaty:',
    localCurrency: 'zł',
    payText: 'Przejdź do płatności',
    pricePerItem: 'Cena/szt.: ',
    totalPrice: 'Łączna cena: ',
    amount: 'Liczba:',
    pcs: 'szt.',
    upsell: 'Wydłużenie gry',
    upsellTime: 'Czas',
    defaultTicket: 'Bilet normalny',
  },
  activities: {
    selectEvent: 'Wybierz aktywność',
    book: 'Zarezerwuj',
  },
  passManagement: {
    pageTitle: 'Twój karnet',
    passDataForm: {
      usePassData: 'Wprowadź dane karnetu:',
      placeholder: {
        identity: 'Kod karnetu',
        email: 'Adres e-mail użyty przy zakupie',
      },
      buttonSubmit: 'Sprawdź',
    },
    passError: {
      title: 'Nie znaleziono karnetu',
      content: 'Sprawdź poprawność danych i spróbuj ponownie.',
      button: 'Wpisz poprawne dane',
    },
    passDetails: {
      remainingEntries: 'Wejścia do wykorzystania',
      expireDate: 'Data ważności',
      isPersonal: {
        individual: 'Indywidualny',
        family: 'Rodzinny',
      },
      type: 'Typ karnetu',
      backButton: 'Wpisz inny kod karnetu',
    },
  },
  followers: {
    follows: 'obserwuje',
  },
  profile: {
    tickets: 'Bilety',
    recommended: 'Polecane',
    allEvents: 'Wszystkie wydarzenia',
    news: 'Nowości',
    allNews: 'Wszystkie aktualności',
    gallery: 'Galeria',
    allPhotos: 'Wszystkie zdjęcia',
    aboutPlace: 'O miejscu',
    readMore: 'Czytaj więcej',
    location: 'Lokalizacja',
    setRoute: 'Wyznacz trasę',
    aboutUs: 'O nas',
    newsMenu: 'Aktualności',
    verified: 'Profil zweryfikowany',
  },
  stats: {
    rating: 'Ocena',
    opinion: 'opini',
    events: 'Wydarzeń',
    visited: 'Odwiedziło',
    future: 'przyszłych',
    friends: 'Znajomych',
    related: 'Powiązanych',
    profile: 'profili',
    tookPlace: 'Odbyło się',
  },
  extendedPayment: {
    title: 'Twoja rezerwacja',
    subtitle: 'Zanim klikniesz “Zapłać”, sprawdź czy wszystko się zgadza.',
    clientData: 'Twoje dane',
    firstName: 'Imię: ',
    lastName: 'Nazwisko: ',
    emailAddress: 'Adres e-mail: ',
    summary: 'Podsumowanie',
    toPay: 'do zapłaty:',
    payNow: 'Zapłać teraz',
  },
  returnAccountBank: {
    title: 'Numer konta',
    pageName: 'Zwrot - Numer konta bankowego',

    success: {
      title: 'Udało się!',
      description: 'Twój numer konta został pomyślnie dodany.',
    },
  },
  sellerModal: {
    title: 'Sprzedający',
    buttonText: 'Sprzedaje: ',
    subtitle: 'Sprzedawcą biletów na to wydarzenie jest ',
    accept: 'Rozumiem',
    agent: (partnerName?: string) =>
      `Going. pełni rolę pośrednika w procesie sprzedaży biletów przez organizatora ${partnerName} i nie jest odpowiedzialny za właściwe przygotowanie i przeprowadzenie wydarzenia.`,
    nip: 'NIP: ',
    headquarters: 'z siedzibą pod adresem: ',
  },
  tooltip: {
    serviceUnavailable: 'Przepraszamy, usługa jest tymczasowo niedostępna.',
  },
}
