import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerAfterSellContainer: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    bigIconContainer: {
      minWidth: '6rem',
      height: '6rem',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      border: '0.1875rem solid ',
      borderRadius: '3rem',
      [theme.breakpoints.down('xs')]: {
        minWidth: '4rem',
        height: '4rem',
      },
    },
    mainText: {
      alignSelf: 'center',
      marginLeft: '1rem !important',
      color: theme.palette.text.primary,
    },
    baseAlertContainer: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.border.radius,
      padding: '0.5rem',
    },
    baseAlertText: {
      alignSelf: 'center',
      marginLeft: '0.6rem !important',
    },
    baseAlertIcon: {
      display: 'flex',
    },
  })
)

export default useStyles
