const classes = {
  content: 'summary-content',
  final: 'summary-final',
  imageWrapper: 'summary-image-wrapper',
  info: 'summary-info',
  infoBox: 'summary-info-box',
  linkBox: 'summary-link-box',
  sticky: 'summary-sticky',
  summary: 'summary',
  summaryBox: 'summary-box',
  text: 'summary-text',
  ticketIcon: 'summary-ticket-icon',
  title: 'summary-title',
  triangleWrapper: 'summary-triangle-wrapper',
  value: 'summary-value',
  wrapper: 'summary-wrapper',
}

export default classes
