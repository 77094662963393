import { SearchTypes } from 'constants/CloudSearch'
import { Fetcher } from 'services/Api/Fetcher'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { ICloudSearchHit, ICloudSearchResponse } from 'types/CloudSearch'
import { IPlaceResponse } from 'types/Place'
import { IProfileResponse, ProfileType } from 'types/Profile'

import { LuceneQueryBuilder } from './LuceneQueryBuilder'

export class ProfileApi {
  public static getFullResource = async ([type, slug]: [type: ProfileType, slug: string]) => {
    try {
      let placeData
      let recommendRundates
      const profile = await Fetcher.get<IProfileResponse>(URI.GetProfile(type, slug))
      if (type === ProfileType.place) {
        placeData = await Fetcher.get<IPlaceResponse>(URI.GetPlace(slug))
        recommendRundates = await ProfileApi.getRecommendedRundates(
          profile.data.recommendRundateIds.map(String)
        )
      }

      return { placeData, profile: profile.data, recommendRundates }
    } catch (e) {
      Logger.error('ResourceApi.getFullResource', e)
    }
  }

  private static getRecommendedRundates = async (
    rundateIds: string[]
  ): Promise<ICloudSearchHit[]> => {
    if (rundateIds.length) {
      const recommendedRundatesUrl = LuceneQueryBuilder.buildCloudSearchUrl({
        typeFilter: SearchTypes.RUNDATE,
        sourceIds: rundateIds,
        size: 8,
      })

      try {
        const recommendedRundates = await Fetcher.get<ICloudSearchResponse>(recommendedRundatesUrl)

        return recommendedRundates.hits.hit
      } catch (e) {
        Logger.error('ResourceApi.getRecommendedRundates', e)

        return []
      }
    }

    return []
  }
}
