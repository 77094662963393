import { Theme } from '@mui/material'
import { makeSxStyles } from 'theme/makeSxStyles'

const useStyles = (theme: Theme) =>
  makeSxStyles(() => ({
    container: {
      borderColor: theme.border.color,
      borderRadius: theme.border.radius,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(5),
    },
  }))

export default useStyles
