import { useResetRecoilState } from 'recoil'

import { categoryFilterState } from 'state/cloudSearch/atoms/categoryFilter'
import { dateFilterState } from 'state/cloudSearch/atoms/dateFilter'
import { locationFilterState } from 'state/cloudSearch/atoms/locationFilter'
import { searchPageIndexState } from 'state/cloudSearch/atoms/searchPageIndex'
import { searchPhraseState } from 'state/cloudSearch/atoms/searchPhrase'
import { tagsFilterState } from 'state/cloudSearch/atoms/tagsFilter'
import { typeFilterState } from 'state/cloudSearch/atoms/typeFilter'
import { variousFiltersState } from 'state/cloudSearch/atoms/variousFilters'

/**
 * This hook returns a function that resets all search filters to the initial state, without subscribing to the state updates.
 */
export const useResetSearchFilters = () => {
  const resetDatesFilter = useResetRecoilState(dateFilterState)
  const resetLocationFilter = useResetRecoilState(locationFilterState)
  const resetCategoryFilter = useResetRecoilState(categoryFilterState)
  const resetTagsFilter = useResetRecoilState(tagsFilterState)
  const resetTypeFilter = useResetRecoilState(typeFilterState)
  const resetVariousFilters = useResetRecoilState(variousFiltersState)
  const resetPhrase = useResetRecoilState(searchPhraseState)
  const resetPageIndex = useResetRecoilState(searchPageIndexState)

  return {
    resetFilters: (shouldResetPhrase: boolean = true) => {
      resetDatesFilter()
      resetLocationFilter()
      resetCategoryFilter()
      resetTagsFilter()
      resetTypeFilter()
      resetVariousFilters()
      if (shouldResetPhrase) resetPhrase()
      resetPageIndex()
    },
  }
}
