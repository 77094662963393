import { updateBasketStateFromContext } from 'models/basket/actions'
import { IAction, IBasketReducer } from 'models/basket/types'
import { getType } from 'typesafe-actions'

const initialState: IBasketReducer = []

const reducer = (state: IBasketReducer = initialState, action: IAction) => {
  switch (action.type) {
    case getType(updateBasketStateFromContext):
      return action.payload
    default:
      return state
  }
}

export default reducer
