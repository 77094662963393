import React, { useContext, useEffect, useRef, useState } from 'react'

import { Button, useTheme } from '@mui/material'
import cn from 'classnames'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import classes from 'components/errors/ErrorModal/ErrorModal.classes'
import useStyles from 'components/errors/ErrorModal/styles/ErrorModal.styles'
import Portal from 'components/modals/Portal'
import config from 'config'
import cancelClick from 'misc/helpers/cancelClick'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useLayoutEffect } from 'misc/hooks/useIsomorphicLayoutEffect'
import iframeProvider from 'services/$iframe-provider'
import { getErrorFromPath } from 'services/$transactions-api/helpers/getErrorFromPath'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './ErrorModal.module.scss'
import { IErrorModalProps } from './ErrorModal.types'
import WrongBasketItem from './components/WrongBasketItem'

const appClass = config.theme.isEmpik ? styles.empik : styles.going

const ErrorModal = ({
  close,
  errorLink,
  errorMessageLinkLabel,
  errorTitle,
  isEmbed,
  message,
  show,
  client,
  path,
  informAboutErrorModal,
  userData,
  buyAndPayOnline,
}: IErrorModalProps) => {
  const { i18n } = useDictionary()
  const { isDesktop } = useBreakpoints()
  const prevMessage = useRef({ message }).current
  const [embedDialogPosition, setEmbedDialogPosition] = useState(0)

  const state = useContext(clearContext)

  const basketError = getErrorFromPath(path, state?.basketItems)

  useEffect(() => {
    if (show && message !== prevMessage.message) {
      informAboutErrorModal()
    }
  }, [])

  const handleContinueTransaction = () => {
    if (basketError && basketError.length && userData) {
      basketError.forEach((error) => {
        if (error) {
          state?.removeFromBag(error.id)
        }
      })

      const newList = state?.basketItems.filter((basketItem) => {
        return !basketError.some((errorItem) => {
          return errorItem.id === basketItem.id
        })
      })
      close()
      if (newList?.length) {
        buyAndPayOnline({
          basketItems: newList || [],
          data: { ...userData, customTerms: {} },
          onDone: state && state.clearBasket ? state.clearBasket : () => null,
        })
      } else {
        buyAndPayOnline({
          basketItems: [],
          data: userData,
          onDone: state && state.clearBasket ? state.clearBasket : () => null,
        })
      }
    }
  }

  const theme = useTheme()
  const muiStyles = useStyles(embedDialogPosition, isEmbed, isDesktop)(theme)

  useLayoutEffect(() => {
    if (isEmbed && show) {
      iframeProvider.getIframeRelativeScrollPosition(setEmbedDialogPosition)
    }

    return () => window.removeEventListener('message', () => {})
  }, [show])

  return (
    <Portal>
      {show && (
        <div
          className={cn(
            styles.errorModal,
            {
              [client]: client,
            },
            isEmbed && styles.embedError
          )}
          onClick={close}
          role="button"
        >
          <div className={muiStyles.paper}>
            {!basketError ? (
              <>
                {isDesktop && (
                  <button className={styles.close} onClick={close}>
                    {i18n.buttons.close}
                  </button>
                )}
                <div
                  className={cn(
                    styles.panel,
                    appClass,
                    styles.witIcon,
                    muiStyles.root,
                    classes.errorModalPanel
                  )}
                  onClick={cancelClick}
                >
                  <h2 className={styles.title}>{errorTitle ? errorTitle : i18n.errors.sorry}</h2>
                  <p className={styles.message}>
                    {message || ''}

                    {!!(errorMessageLinkLabel && errorLink) && (
                      <a href={errorLink || ''}>{errorMessageLinkLabel}</a>
                    )}
                  </p>
                  <div className={styles.buttonWrapper}>
                    <Button variant={'contained'} color={'primary'} size={'medium'} onClick={close}>
                      {i18n.buttons.ok}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={cn(styles.panel, appClass, styles.panelBasket)}
                  onClick={cancelClick}
                >
                  {basketError.map((error) => {
                    return <WrongBasketItem key={error.id} wrongBasketItem={error} />
                  })}
                  <h2 className={styles.message}>{message}</h2>
                  <h2 className={styles.confirm}>
                    {i18n.errorModals.title}
                    <br />
                    {i18n.errorModals.helper}
                  </h2>
                  <div className={styles.buttonsWrapper}>
                    <div className={styles.buttonWrapper}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'medium'}
                        onClick={handleContinueTransaction}
                      >
                        {i18n.buttons.confirm}
                      </Button>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'medium'}
                        onClick={close}
                        className={styles.cancelButton}
                      >
                        {i18n.buttons.confirm}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Portal>
  )
}

export default ErrorModal
