import {
  IReadMoreBrickOwnProps,
  IReadMoreExtra,
} from 'components/Bricks/components/ReadMoreBrick/ReadMoreBrick.types'
import prepareHotSpotUri from 'misc/helpers/prepareHotSpotsUri'

import { ISectionsComponentDataMutable, ISectionsComponentLink } from './../types/sections'

export const read_more = (
  data: ISectionsComponentDataMutable,
  extra?: IReadMoreExtra | null,
  background?: string
): IReadMoreBrickOwnProps => {
  if (!data) {
    return {} as IReadMoreBrickOwnProps
  }

  const linkObject = data[0] as ISectionsComponentLink

  return {
    background,
    color: extra?.color,
    link: prepareHotSpotUri(linkObject),
    text: linkObject.description,
  }
}
