import qs from 'qs'
import { routes } from 'router/routes'
import { IRouteMatchParams } from 'router/types'

import Logger from 'services/Logger'

interface IPrepareRouteParams<T extends keyof IRouteMatchParams = any> {
  route: T
  params: IRouteMatchParams[T]
  query?: qs.ParsedQs
}

/**
 * Takes an object containing a route template string, parameters to replace in the route,
 * and an optional query object. Returns the final route string with parameters replaced
 * and query string appended.
 *
 * @param {IPrepareRouteParams} options - An object containing the route, params, and optional query.
 * @returns {string} The final route string with parameters replaced and query string appended.
 * @throws {Error} Will log an error if a required parameter is missing from the `params` object.
 */
export const prepareRoute = <T extends keyof IRouteMatchParams = any>({
  route,
  params = {} as any,
  query,
}: IPrepareRouteParams<T>): string => {
  const paramRecord = params as Record<string, string | number | undefined>
  const routeTemplate = routes[route]

  let finalRoute = routeTemplate.replace(/:([a-zA-Z0-9]+)\??/g, (_, paramName) => {
    if (paramName in paramRecord && paramRecord[paramName]) {
      return String(paramRecord[paramName])
    } else {
      Logger.debug('prepareRoute', `"${paramName}" not found in params.`)
      return ''
    }
  })

  finalRoute = finalRoute.replace(/\/\?/g, '')

  if (finalRoute.endsWith('/')) finalRoute = finalRoute.slice(0, -1)

  if (query) finalRoute += qs.stringify(query, { addQueryPrefix: true })

  return encodeURI(finalRoute.replace(/\/$/, ''))
}
