import React, { FC, useEffect } from 'react'

import { useTheme } from '@mui/material'

import { getImageUrl } from 'services/Api/Cloudinary'

import styles from './MobileViewer.module.scss'
import { IMobileViewerProps } from './MobileViewer.types'

const MobileViewer: FC<IMobileViewerProps> = ({ galleryAnchor, images, locationHash }) => {
  const theme = useTheme()

  useEffect(() => {
    const locationWithoutHash = locationHash.slice(1)
    const chosenImg = document.getElementById(locationWithoutHash)
    chosenImg?.scrollIntoView({ block: 'center' })
  })

  return (
    <>
      {images.map((image, index) => (
        <img
          alt={image}
          className={styles.image}
          id={galleryAnchor + index}
          key={index}
          src={getImageUrl(image, {
            width: theme.breakpoints.values.md,
            height: theme.breakpoints.values.md,
          })}
        />
      ))}
    </>
  )
}

export default MobileViewer
