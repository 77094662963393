import { connect } from 'react-redux'

import _Store from '@Store'

import WithEventDetailBox from 'components/TicketReception/WithEventDetailBox/WithEventDetailBox.component'
import { IWithEventDetailBoxFromState } from 'components/TicketReception/WithEventDetailBox/WithEventDetailBox.types'
import { getLocation } from 'models/connectedRouter/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import { EVENT } from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import {
  getEventFromTicket,
  getReceivedEvent,
  getReceivedSummary,
  getTicketTransferFee,
} from 'models/tickets/selectors'
import getReceivedTicket from 'models/tickets/selectors/getReceivedTicket'

const mapStateToProps = (state: _Store.IState): IWithEventDetailBoxFromState => ({
  event: getEventFromTicket(state)(getLocation(state).pathname.split('/')[2]),
  isEmbed: !!getIframeParams(state),
  isLoading: getLoading(EVENT)(state),
  receivedEvent: getReceivedEvent(state),
  receivedTickets: getReceivedTicket(state),
  summary: getReceivedSummary(state),
  ticketTransferFee: getTicketTransferFee(state)?.price,
})

export default connect<IWithEventDetailBoxFromState, void, {}, _Store.IState>(mapStateToProps)(
  WithEventDetailBox
)
