import { useEffect } from 'react'

import { useSetRecoilState } from 'recoil'

import { setApp } from 'state/app/selectors/setApp'

export const useSetAppState = (searchParams: URLSearchParams) => {
  const setAppState = useSetRecoilState(setApp)
  const isDark = searchParams.has('isDark')
  const isWebview = searchParams.has('isWebview')

  useEffect(() => {
    if (isDark || isWebview) {
      setAppState((prev) => ({
        ...prev,
        isDarkTheme: searchParams.get('isDark') === 'true',
        isWebview: searchParams.get('isWebview') === 'true',
      }))
    }
  }, [isWebview, isDark])
}
