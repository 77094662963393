import { createSelector, defaultMemoize } from 'reselect'
import { lazy, object, string } from 'yup'

import _Store from '@Store'

import {
  IHappeningFormValidators,
  IHappeningFormValues,
} from 'components/Happening/components/HappeningBuyForm/HappeningBuyForm.types'
import * as CONSTS from 'components/buying/constants/constants'
import {
  customTermValidator,
  discountCodeValidator,
  invoiceNipValidator,
  invoicePostCodeValidator,
  invoiceRequiredFieldValidator,
  phoneNumberValidator,
  termValidator,
} from 'components/buying/constants/validators'
import { PaymentMethods } from 'constants/PaymentMethods'
import { getHappeningTerms } from 'models/happening/selectors'
import getPaymentMethods from 'models/pools/selectors/getPaymentMethods'
import { IPaymentMethodsReducer } from 'models/pools/types'
import { getProducts } from 'models/products/selectors'
import { IProduct } from 'models/products/types'
import { ICustomTerm } from 'types/EventCommon'
import { ILangMap } from 'types/Locale'

export const getInitialValues = createSelector<
  _Store.IState,
  ICustomTerm[],
  IProduct[],
  IPaymentMethodsReducer,
  IHappeningFormValues
>([getHappeningTerms, getProducts, getPaymentMethods], (terms, products, availableMethods) => {
  const isPayUAvailable = availableMethods.paymentMethods.some(
    (payment) => payment.type === PaymentMethods.PAYU
  )

  const initialValues: IHappeningFormValues = {
    customTerms: {},
    discount: CONSTS.DEFAULT_EMPTY_TEXT,
    discountCheckbox: false,
    email: CONSTS.DEFAULT_EMPTY_TEXT,
    firstname: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceAddress: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceCheckbox: false,
    invoiceCity: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceName: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceNip: CONSTS.DEFAULT_EMPTY_TEXT,
    invoicePost: CONSTS.DEFAULT_EMPTY_TEXT,
    lastname: CONSTS.DEFAULT_EMPTY_TEXT,
    newsletter: false,
    pass: CONSTS.DEFAULT_EMPTY_TEXT,
    passCheckbox: false,
    paymentMethod:
      !isPayUAvailable && availableMethods.paymentMethods.length
        ? availableMethods.paymentMethods[0]
        : { type: PaymentMethods.PAYU },
    phone: CONSTS.DEFAULT_EMPTY_TEXT,
    prepaidCard: CONSTS.DEFAULT_EMPTY_TEXT,
    prepaidCheckbox: false,
    products: {},
    terms: false,
  }

  Object.values(products).forEach((product) => {
    initialValues.products[`${product.id}`] = product.autocomplete ? 1 : 0
  })

  terms.forEach((term) => {
    initialValues.customTerms[`${term.id}`] = false
  })

  return initialValues
})

const makeValidationSchemaToMemoize = (customTerms: ICustomTerm[], lang: ILangMap) => {
  const validators: Partial<IHappeningFormValidators> = {
    discount: discountCodeValidator(lang),
    email: string()
      .required(lang.errors.emailAddressIsRequiredValidator)
      .email(lang.errors.providedEmailAddressIsIncorrectValidator),
    firstname: string().required(lang.errors.firstNameIsRequiredValidator),
    invoiceAddress: invoiceRequiredFieldValidator(lang.errors.thisFieldIsRequiredValidator),
    invoiceCity: invoiceRequiredFieldValidator(lang.errors.thisFieldIsRequiredValidator),
    invoiceName: invoiceRequiredFieldValidator(lang.errors.thisFieldIsRequiredValidator),
    invoiceNip: invoiceNipValidator(lang.errors.incorrectData),
    invoicePost: invoicePostCodeValidator(lang),
    lastname: string().required(lang.errors.lastNameIsRequiredValidator),
    phone: phoneNumberValidator(lang.errors.incorrectPhoneNumber).required(
      lang.errors.thisFieldIsRequiredValidator
    ),
    terms: termValidator(lang.errors.checkingThisRegulationsIsRequiredValidator),
    customTerms: lazy((value: { [key: string]: boolean }) =>
      customTermValidator(lang, customTerms, value, null).when('invoiceCheckbox', {
        is: true,
        then: customTermValidator(lang, customTerms, value, 'forFVat'),
        otherwise: customTermValidator(lang, customTerms, value, 'exceptFVat'),
      })
    ),
  }

  return object().shape(validators as IHappeningFormValidators)
}

export const makeValidationSchema = defaultMemoize(makeValidationSchemaToMemoize)
