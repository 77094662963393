import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { searchPageIndexState } from '../atoms/searchPageIndex'
import { searchPhraseState } from '../atoms/searchPhrase'

/**
 * Search phrase setter selector. The "set" method resets search page to initial state,
 * each time new value is set.
 */
export const setSearchPhrase = selector({
  get: ({ get }) => get(searchPhraseState),
  set: ({ get, set, reset }, newValue) => {
    set(searchPhraseState, newValue)
    reset(searchPageIndexState)
  },
  key: RecoilKeys.setSearchPhrase,
})
