import * as React from 'react'

import { Actions } from '@cloudinary/url-gen'
import { Delivery } from '@cloudinary/url-gen/actions'
import { Format, Quality } from '@cloudinary/url-gen/qualifiers'
import { Box, useTheme } from '@mui/material'
import useSWR from 'swr'

import { FavoritesIcon } from 'components/FollowingDialog/components/FavoritesIcon'
import ImageFixed from 'components/reusable/ImageFixed/'
import { cloudinary, fetchCloudinaryList } from 'services/Api/Cloudinary'
import { DataMutations } from 'services/DataMutations'

import { IFavoriteAvatarProps } from './FavoriteAvatar.types'

const {
  Resize: { fill },
} = Actions
const { quality, format } = Delivery
const { webp } = Format
const { autoGood } = Quality

export const FavoriteAvatar = ({ stroke, size = 64, slug, type }: IFavoriteAvatarProps) => {
  const theme = useTheme()
  const prefix = DataMutations.favoriteCloudinaryPrefix(type)
  const { data: cloudinaryImages, isLoading } = useSWR<string[]>(
    { slug, prefix },
    fetchCloudinaryList,
    {}
  )

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: size / 2,
        display: 'flex',
        height: size,
        justifyContent: 'center',
        width: size,
      }}
    >
      {cloudinaryImages?.length ? (
        <ImageFixed
          aspectRatio={[1, 1]}
          src={cloudinary
            .image(cloudinaryImages[0])
            .resize(fill().width(size).height(size))
            .delivery(quality(autoGood()))
            .delivery(format(webp()))
            .toURL()}
        />
      ) : (
        <FavoritesIcon size={size} stroke={stroke ?? theme.palette.text.primary} type={type} />
      )}
    </Box>
  )
}
