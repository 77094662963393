import { combineReducers } from 'redux'

import * as CONSTS from './../constants/constants'
import createSubStateReducer from './createSubStateReducer'

export const reducers = {
  [CONSTS.AVAILABILITIES]: createSubStateReducer(CONSTS.AVAILABILITIES),
  [CONSTS.ARTIST]: createSubStateReducer(CONSTS.ARTIST),
  [CONSTS.EVENT]: createSubStateReducer(CONSTS.EVENT),
  [CONSTS.EVENTS]: createSubStateReducer(CONSTS.EVENTS),
  [CONSTS.FILTERS]: createSubStateReducer(CONSTS.FILTERS),
  [CONSTS.HAPPENING]: createSubStateReducer(CONSTS.HAPPENING),
  [CONSTS.HAPPENING_PRICE]: createSubStateReducer(CONSTS.HAPPENING_PRICE),
  [CONSTS.LANDING_PAGE]: createSubStateReducer(CONSTS.LANDING_PAGE),
  [CONSTS.MYTICKET]: createSubStateReducer(CONSTS.MYTICKET),
  [CONSTS.PASSWORDRESET]: createSubStateReducer(CONSTS.PASSWORDRESET),
  [CONSTS.PLACE]: createSubStateReducer(CONSTS.PLACE),
  [CONSTS.PLACES]: createSubStateReducer(CONSTS.PLACES),
  [CONSTS.POOLS]: createSubStateReducer(CONSTS.POOLS),
  [CONSTS.PREMIUM]: createSubStateReducer(CONSTS.PREMIUM),
  [CONSTS.SECTIONS]: createSubStateReducer(CONSTS.SECTIONS),
  [CONSTS.SELECTION]: createSubStateReducer(CONSTS.SELECTION),
  [CONSTS.STATIC_ARTICLE]: createSubStateReducer(CONSTS.STATIC_ARTICLE),
  [CONSTS.TRANSACTION]: createSubStateReducer(CONSTS.TRANSACTION),
  [CONSTS.TRANSLATIONS]: createSubStateReducer(CONSTS.TRANSLATIONS),
}
export default combineReducers(reducers)
