import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = makeSxStyles(() => ({
  dialog: (theme) => ({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: theme.spacing(4),
  }),
  filterBar: (theme) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: theme.spacing(2),
  }),
}))
