import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      gap: '0.625rem',
    },
    buttons: {
      marginTop: '1.375rem',
    },
  })
)

export default useStyles
