import { createAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import { _ANY } from './../constants/constants'
import { IPayloadMetaAction, ISetStateModule } from './../types'

function createHandler<T extends string>(
  actionCallback: <P = undefined, M = undefined>(
    payload?: P,
    meta?: M
  ) => IPayloadMetaAction<T, P, M>
) {
  return (module: ISetStateModule) =>
    actionCallback({
      module,
    })
}

// Loading
export const resetAllLoading = createAction(
  CONSTS.RESET_LOADING,
  (actionCallback) => () => actionCallback({ module: _ANY })
)

export const resetLoading = createAction(CONSTS.RESET_LOADING, createHandler)

export const setLoading = createAction(CONSTS.SET_LOADING, createHandler)

// Requested
export const resetRequested = createAction(CONSTS.RESET_REQUESTED, createHandler)

export const setRequested = createAction(CONSTS.SET_REQUESTED, createHandler)

// Resource Url
export const resetResourceUrl = createAction(CONSTS.RESET_RESOURCE_URL, createHandler)

export const setResourceUrl = createAction(CONSTS.SET_RESOURCE_URL, (actionCallback) => {
  return (module: ISetStateModule, url: string) =>
    actionCallback({
      module,
      url,
    })
})

// Skip Next Request
export const resetSkipNextRequest = createAction(CONSTS.RESET_SKIP_NEXT_REQUEST, createHandler)

export const setSkipNextRequest = createAction(CONSTS.SET_SKIP_NEXT_REQUEST, createHandler)
