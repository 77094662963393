import * as React from 'react'

import { RouterProvider } from 'react-router-dom'
import router from 'router'

import SimpleLoading from 'components/reusable/SimpleLoading'

import { useMaintenance } from '../MaintenanceModeProvider'

export const RouterProviderWrapper = () => {
  const { isMaintenance } = useMaintenance()

  return <RouterProvider router={router(isMaintenance)} fallbackElement={<SimpleLoading />} />
}
