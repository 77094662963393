import React, { useContext, useEffect } from 'react'

import { Formik } from 'formik'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { PayUContext } from 'components/_functional/PayUContextProvider/PayUContextProvider.component'
import { IFormLayoutProps } from 'components/buying/components/FormLayout/FormLayout.types'
import config from 'config'
import { Languages } from 'constants/Languages'
import { QueueItId } from 'constants/QueueIt'
import getBasketTerms from 'misc/helpers/getBasketTerms'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import FormLayout from './../components/FormLayout'
import { makeValidationSchema } from './Online.selectors'
import { IBuyingOnlineFormValues, IBuyingOnlineProps } from './Online.types'

const Online = ({
  buyAndPay,
  checkout,
  customTerms,
  eventSlug,
  rundateSlug,
  initialValues,
  isDataHydratedTransaction,
  isEmbed,
  mounted,
  queue,
  updateBasketState,
  cardPaymentForm,
  currentBasketData,
}: IBuyingOnlineProps) => {
  const { i18n } = useDictionary()
  const { code } = useLang()
  const { isWebview } = useAppState()
  useEffect(() => mounted(), [])
  const state = useContext(clearContext)
  const { handleTokenizeCard, payuInstance } = useContext(PayUContext)

  const basketItems = state?.basketItems || []
  const loadBasket = state?.basketItems && !isEmbed
  const basketCustomTerms = loadBasket ? getBasketTerms(basketItems) : []
  const seatsIoBasketItems = basketItems.filter((item) => item.seats && item.seats.length > 0)
  const isDefaultCurrency = ![...currentBasketData, ...basketItems].some(
    (item) => item.currency !== config.app.defaultCurrency
  )

  const validationSchema = makeValidationSchema(
    [...customTerms, ...basketCustomTerms],
    i18n,
    checkout,
    !isDataHydratedTransaction,
    code === Languages.English,
    isDefaultCurrency
  )

  const catchSaveData = async (data: IBuyingOnlineFormValues) => {
    if (handleTokenizeCard && payuInstance) {
      const tokenize = await handleTokenizeCard(payuInstance)

      if (tokenize === 'ERROR') {
        return
      }
    }

    buyAndPay({
      basketItems,
      data,
      onDone: state?.clearBasket ? state.clearBasket : () => null,
      language: code,
    })
  }

  useEffect(() => {
    try {
      /*
       * https://goingapp.atlassian.net/browse/WEAPP-2768
       */
      if (
        queue === QueueItId.queuegoingsite &&
        !isWebview &&
        config.app.onlineSale &&
        !window.location.href.includes('queue')
      ) {
        if (rundateSlug && !isEmbed) {
          window.location.assign(
            `${config.app.queueUrl}/${
              code === Languages.English ? 'buy-ticket' : 'kup-bilety'
            }/${eventSlug}/${rundateSlug}`
          )
        } else if (isEmbed) {
          window.top?.postMessage(JSON.stringify({ queue: true }), '*')
        }
      }
    } catch (noWindowObjectBecauseSsrOrEmbed) {}
  }, [rundateSlug, queue, code])

  useEffect(() => {
    updateBasketState(basketItems)
  }, [basketItems])

  useEffect(() => {
    /*
     *
     * TODO: REMOVE WHEN SEATS.IO SUPPORT FOR BASKET IS DONE
     *
     * */

    try {
      if (seatsIoBasketItems && seatsIoBasketItems?.length > 0) {
        seatsIoBasketItems.map((item) => state?.removeFromBag(item.id))
      }
    } catch (e) {}
  }, [seatsIoBasketItems?.length])

  return (
    <Formik
      children={(props: IFormLayoutProps) => (
        <FormLayout
          {...props}
          cardPaymentForm={cardPaymentForm}
          handleTokenizeCard={handleTokenizeCard}
          payuInstance={payuInstance}
        />
      )}
      initialValues={initialValues(basketCustomTerms)}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={false}
    />
  )
}

export default Online
