import * as React from 'react'

import { Box, Chip } from '@mui/material'

import config from 'config'
import { EventCategories, categoryColors } from 'constants/Categories'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { ITagsProps } from './Tags.types'

export const Tags = ({ tags, size }: ITagsProps) => {
  const { i18n } = useDictionary()
  if (!tags || tags.length === 0) return null

  return (
    <Box component={'ul'} sx={{ display: 'flex', flexFlow: 'row wrap', gap: 1 }}>
      {tags?.map(({ name, id, slug }, index) => (
        <Chip
          size={size}
          component={'li'}
          key={id}
          sx={{
            backgroundColor:
              index === 0
                ? slug === i18n.searchCard.event
                  ? categoryColors[EventCategories.concert]
                  : categoryColors[name]
                : undefined,
          }}
          label={config.theme.isGoing ? name : `#${name}`}
          variant={index === 0 ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  )
}
