import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      justifyContent: 'space-between',
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
      width: 'inherit',
    },
  })
)

export default useStyles
