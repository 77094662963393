import React, { useContext, useEffect } from 'react'

import { Box, Grid, Typography, useTheme } from '@mui/material'

import { PayUContext } from 'components/_functional/PayUContextProvider/PayUContextProvider.component'
import { Flex } from 'components/_layout/Flex'

import { Styled } from './CardPaymentForm.styles'

const CardPaymentForm = () => {
  const { cardNumberForm, cardDateForm, cardCVVForm, isCardPaymentMethod, inputError } =
    useContext(PayUContext)
  const theme = useTheme()

  const payuContainerIdentifiers = {
    number: 'payu-card-number',
    date: 'payu-card-date',
    cvv: 'payu-card-cvv',
  }

  useEffect(() => {
    cardNumberForm?.remove()
    cardDateForm?.remove()
    cardCVVForm?.remove()
    if (isCardPaymentMethod && cardNumberForm && cardDateForm && cardCVVForm) {
      cardNumberForm?.render('#' + payuContainerIdentifiers.number)
      cardDateForm?.render('#' + payuContainerIdentifiers.date)
      cardCVVForm?.render('#' + payuContainerIdentifiers.cvv)
    }
  }, [isCardPaymentMethod, cardNumberForm, cardDateForm, cardCVVForm])

  return (
    <Flex sx={{ paddingLeft: 2, paddingTop: 3 }} gap={3}>
      <Box sx={{ width: '100%' }}>
        <Styled.Field
          item={true}
          sx={inputError ? { borderColor: theme.palette.error.main, marginBottom: 0 } : undefined}
        >
          <Styled.Card className={'payu-card-form'} id={payuContainerIdentifiers.number} />
        </Styled.Field>
        {inputError && (
          <Grid item={true}>
            <Typography variant={'caption'} color={'error'}>
              {inputError}
            </Typography>
          </Grid>
        )}
      </Box>
      <Styled.Field item={true}>
        <Styled.Card className={'payu-card-form'} id={payuContainerIdentifiers.date} />
      </Styled.Field>
      <Styled.Field item={true}>
        <Styled.Card className={'payu-card-form'} id={payuContainerIdentifiers.cvv} />
      </Styled.Field>
    </Flex>
  )
}

export default CardPaymentForm
