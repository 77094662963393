import HttpError from 'misc/classes/HttpError'

const catchHttpError = (error: any) => {
  if (error?.response?.data?.code) {
    return new HttpError(error.response.data.message, error.response.data.code)
  }

  if (error?.response?.status) {
    return new HttpError('Http Error', error.response.status)
  }

  return error
}

export default catchHttpError
