import { getType } from 'typesafe-actions'

import { receiveTicket } from '../../actions'
import { IAction, ITicketReceiveData } from '../../types'

const reducer = (
  state: ITicketReceiveData | null = null,
  action: IAction
): ITicketReceiveData | null => {
  switch (action.type) {
    case getType(receiveTicket.success):
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export default reducer
