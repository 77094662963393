import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import _Store from '@Store'

import isEmptyObject from 'misc/helpers/isEmptyObject'

export const useClientFromStore = () =>
  useSelector<_Store.IState, { client: string }>(
    createSelector([(state: _Store.IState) => state.internalRouter.embed], (embed) => ({
      client: (isEmptyObject(embed) ? null : embed)?.client || '',
    }))
  )
