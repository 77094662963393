import React, { useContext, useEffect, useState } from 'react'

import { Button, Tooltip } from '@mui/material'
import cn from 'classnames'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import classes from 'components/buying/Pools/components/Buttons/Buttons.classes'
import styles from 'components/buying/Pools/components/Buttons/Buttons.module.scss'
import { IButtonProps } from 'components/buying/Pools/components/Buttons/Buttons.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const Buttons = ({
  poolId,
  selectTicket,
  howManyTickets,
  poolName,
  price,
  isActive,
  serviceFee,
  selectedTickets,
  additionalFields,
  forms,
  paymentMethods,
  eventAddToCart,
  eventRemoveFromCart,
  currency,
  basket,
}: IButtonProps) => {
  const { i18n } = useDictionary()
  const [amount, setAmount] = useState(0)
  const state = useContext(clearContext)

  const selectedTicket = selectedTickets.find((ticket) => ticket.poolId === poolId)

  const isButtonPlusActive = amount !== howManyTickets && isActive

  const isButtonMinusActive = isActive && amount > 0

  const isSameCurrency = selectedTickets[0]
    ? currency === selectedTickets[0].currency
    : basket[0]
    ? currency === basket[0].currency
    : true

  const hasPlaceEvent =
    !!state?.basketItems.length && state.basketItems.some((item) => item.isPlaceEvent)

  useEffect(() => {
    if (!selectedTicket) {
      setAmount(0)
    }
  }, [selectedTicket])

  const subtract = () => {
    if (amount > 0) {
      eventRemoveFromCart({ currency, price: price || 0, poolId })
      setAmount(amount - 1)
    }
  }

  const add = () => {
    if (isSameCurrency && !hasPlaceEvent) {
      if (howManyTickets) {
        if (amount < howManyTickets) {
          setAmount(amount + 1)
          eventAddToCart({ currency, price: price || 0, poolId })
        }
      } else {
        setAmount(amount + 1)
      }
    }
  }

  useEffect(() => {
    if (poolId && poolName) {
      selectTicket(
        poolId,
        amount,
        poolName,
        currency,
        price,
        serviceFee,
        additionalFields,
        forms,
        undefined,
        paymentMethods
      )
    }
  }, [amount])

  return (
    <div className={cn(styles.buttons, classes.buttons)}>
      <Button
        disableElevation={true}
        variant={'contained'}
        color={'primary'}
        className={cn(
          styles.button,
          amount === 0 && styles.disabled,
          isButtonMinusActive && classes.buttonMinus
        )}
        data-test-id={`button-${poolId}-subtract`}
        onClick={subtract}
      >
        -
      </Button>
      <p className={styles.amount}>{amount}</p>
      <Tooltip
        title={hasPlaceEvent ? i18n.buy.pools.differentType : i18n.buy.pools.sameCurrency}
        disableHoverListener={isSameCurrency && !hasPlaceEvent}
        disableFocusListener={isSameCurrency && !hasPlaceEvent}
        disableTouchListener={isSameCurrency && !hasPlaceEvent}
      >
        <Button
          disableElevation={true}
          variant={'contained'}
          color={'primary'}
          className={cn(
            styles.button,
            ((howManyTickets && amount === howManyTickets) || !isSameCurrency || hasPlaceEvent) &&
              styles.disabled,
            isButtonPlusActive && classes.buttonPlus
          )}
          data-test-id={`button-${poolId}-add`}
          data-active-pool-button={isButtonPlusActive && 'button-pool'}
          onClick={add}
        >
          +
        </Button>
      </Tooltip>
    </div>
  )
}

export default Buttons
