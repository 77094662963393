import React, { useEffect } from 'react'

import Description from 'components/reusable/Description'
import Image from 'components/reusable/Image'
import { Tags } from 'components/reusable/Tags'
import Title from 'components/reusable/Title'
import DetailsLoading from 'components/reusable/loadings/DetailsLoading'
import TagsLoading from 'components/reusable/loadings/TagsLoading'
import config from 'config'

import styles from './StaticArticle.module.scss'
import { IArticle } from './StaticArticle.types'
import Authors from './components/Authors'

const StaticArticle = ({ article, isArticleLoading, mounted }: IArticle) => {
  useEffect(() => mounted(), [])

  return (
    <>
      <div className={styles.staticArticleWrapper}>
        <div className={styles.imageWrapper}>
          <Image imageUrl={article?.imageUrl} isLoading={isArticleLoading} />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <Title
              title={article?.title}
              isLoading={isArticleLoading}
              customClassName={styles.titleWrapper}
            />
            <Description html={article?.html} isFullWidth={true} hideApostrophe={true} />
          </div>
          {isArticleLoading && <DetailsLoading isFullWidth={true} />}
          {config.theme.isEmpik && (
            <>
              <div className={styles.tagsWrapper}>
                {isArticleLoading && <TagsLoading />}
                {!isArticleLoading && article && article.tags && <Tags tags={article.tags} />}
              </div>
              <Authors data={article?.authors} />
            </>
          )}
          {config.theme.isGoing && (
            <>
              <Authors data={article?.authors} />
              <div className={styles.tagsWrapper}>
                {isArticleLoading && <TagsLoading />}
                {!isArticleLoading && article && article.tags && <Tags tags={article.tags} />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default StaticArticle
