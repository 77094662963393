import React from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import Link from 'components/reusable/DualLink'
import { Languages } from 'constants/Languages'
import translateEngLink from 'misc/helpers/translateEngLink'
import { useLang } from 'state/locale/hooks/useLang'

import styles from './Title.module.scss'
import useStyles from './Title.styles'
import { ITitleProps } from './Title.types'

const Title = ({
  customClassName,
  forcedVertical,
  isLoading,
  link: _link,
  title,
  onClick,
}: ITitleProps) => {
  const { code } = useLang()
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const link = translateEngLink(_link || '', code === Languages.English)

  if (isLoading || !title) {
    return <div className={styles.fakeTitle} />
  }

  return (
    <h2
      className={cn(
        forcedVertical ? styles.forcedVerticalTitle : styles.title,
        muiStyles.fontColor,
        customClassName
      )}
    >
      {link && (
        <Link to={link} className={cn(muiStyles.fontColor, customClassName)} onClick={onClick}>
          {title}
        </Link>
      )}
      {!link && title}
    </h2>
  )
}

export default Title
