import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { ILocationFilterState } from 'types/Search'

export const initialState: ILocationFilterState = null

export const locationFilterState = atom<ILocationFilterState>({
  default: initialState,
  key: RecoilKeys.locationFilter,
})
