import React from 'react'

import { Button, Grid } from '@mui/material'

import statute from 'config'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import stepNames from '../../steps'
import Paper from '../Paper'
import { IWelcomeProps } from './Welcome.types'

const Welcome = ({ handleNextStep, formId }: IWelcomeProps) => {
  const { i18n } = useDictionary()
  const { code: lang } = useLang()
  const onClick = () => {
    handleNextStep(stepNames.starter)
  }
  const prepareTitle = () => {
    if (formId) {
      return i18n.entryList.welcome.title + statute.statutes[formId].welcomeMessage[lang]
    }
    return i18n.entryList.welcome.secondTitle
  }

  return (
    <Paper title={prepareTitle()}>
      <Grid container={true} justifyContent={'center'}>
        <Grid item={true} xs={12} sm={6}>
          <Button
            onClick={onClick}
            variant={'contained'}
            size={'medium'}
            color={'primary'}
            fullWidth={true}
          >
            {i18n.entryList.welcome.buttonText}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Welcome
