import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = makeSxStyles(() => ({
  wrapper: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    borderRadius: theme.border.radius,
    p: 5,
    mt: 6,
  }),
  textValue: (theme) => ({
    fontWeight: theme.typography.fontWeightBold,
  }),
  textSubtitle: (theme) => ({
    color: theme.palette.secondary.main,
  }),
}))
