import { filter as filter$, map as map$ } from 'rxjs/operators'

import _Store from '@Store'

import { getSections } from 'models/pages/actions'
import { isActionOf } from 'typesafe-actions'

import { resetLoading, setLoading } from './../actions'
import * as CONSTS from './../constants/constants'

export const setLoaderWhenSectionsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getSections.request])),
    map$(() => setLoading(CONSTS.SECTIONS))
  )
}

export const setLoaderWhenSectionsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getSections.success, getSections.failure], action)
    ),
    map$(() => resetLoading(CONSTS.SECTIONS))
  )
}
