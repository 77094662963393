import React, { useContext, useEffect } from 'react'

import { connect, useFormikContext } from 'formik'
import { Route, routes } from 'router/routes'
import { isRouteMatch } from 'router/utils/isRouteMatch'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import * as CONST from 'components/buying/constants/constants'
import config from 'config'
import deepCopy from 'misc/helpers/deepCopy'

import Summary from './Summary.component'
import { ISummaryProps } from './Summary.types'

const SummaryFormik = (props: any) => {
  const { values } = useFormikContext<IBuyingOnlineFormValues>()

  const copiedProps: ISummaryProps = {
    ...props,
    summary: deepCopy(props.summary),
  }

  const shipment = copiedProps.shipments.find(
    (value) => values.pickUpWay === String(value.productId)
  )

  if (copiedProps.summary) {
    if (values.pickUpWay && values.pickUpWay === CONST.PICK_UP_WAY_POST) {
      copiedProps.summary.finalPrice += config.buy.courierDeliveryPrice
      copiedProps.withCourier = true

      if (values.giftWrapper) {
        copiedProps.summary.finalPrice += config.buy.giftWrapperPrice
        copiedProps.giftWrap = true
      }
    } else if (shipment) {
      copiedProps.summary.finalPrice += shipment.price
      copiedProps.selectedShipment = shipment
    }
  }

  const state = useContext(clearContext)
  useEffect(() => {
    if (isRouteMatch(routes[Route.cartBuy], location.pathname) && state?.basketItems.length === 0) {
      props.history.push(routes[Route.cart])
    }
  }, [state?.basketItems.length])

  return <Summary {...copiedProps} />
}

export default connect(SummaryFormik)
