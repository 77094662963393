import { createSelector } from 'reselect'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'

import _Store from '@Store'

import { getParams } from 'models/internalRouter/selectors'

const getTransactionIdFromLocation = createSelector<
  _Store.IState,
  RouteParams<Route.payment> | null,
  string | null
>([getParams], (_params) => {
  const params = _params

  if (params) {
    return params.transactionId
  }

  return null
})

export default getTransactionIdFromLocation
