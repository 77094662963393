import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    tooltip: {
      position: 'relative',
      top: '0.25rem',
      left: '0.25rem',
    },
  })
)

export default useStyles
