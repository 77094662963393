import React from 'react'

import cn from 'classnames'
import { Field, useFormikContext } from 'formik'

import Checkbox from 'components/formik/Checkbox'
import FormField from 'components/formik/FormField'
import config from 'config'
import { UserCardStatusEnum } from 'models/premium/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IBuyingOnlineFormValues } from '../../Online/Online.types'
import clientDataStyles from './../ClientData/ClientData.module.scss'
import styles from './EmpikCardNumber.module.scss'
import { IEmpikCardNumberProps } from './EmpikCardNumber.types'

const ONSITE_EMPIK_CARD_DESCRITPION_TEXT = 'Wpisz 13 cyfrowy kod karty Mój Empik klienta.'

const EmpikCardNumber = ({
  checkUserCardStatus,
  isDisabled,
  number,
  open,
  userCardStatus,
  withoutHeader,
}: IEmpikCardNumberProps) => {
  const { i18n } = useDictionary()
  const { values, errors, setErrors, setFieldValue } = useFormikContext<IBuyingOnlineFormValues>()
  const email = values.email
  const userCardNumber = values.empikPremiumNumber
  const getCardStatus =
    errors.empikPremiumNumber === i18n.errors.checkingCardValidator && !errors.email
  const isPremiumInactive = userCardStatus === UserCardStatusEnum.INACTIVE
  let activeCardText = ''

  const setUserCardErrorText = (errorMessage: string | undefined) => {
    setErrors({ ...errors, empikPremiumNumber: errorMessage })
  }

  if (getCardStatus) {
    checkUserCardStatus(userCardNumber, email)
  }

  switch (userCardStatus) {
    case UserCardStatusEnum.OK:
      if (values.userCardStatusCheck !== UserCardStatusEnum.OK) {
        setFieldValue('userCardStatusCheck', UserCardStatusEnum.OK)
      }
      setUserCardErrorText(undefined)
      activeCardText = i18n.errors.cardActiveText
      break
    case UserCardStatusEnum.INACTIVE:
      setUserCardErrorText(i18n.errors.checkingCardInactiveValidator)
      break
    case UserCardStatusEnum.NOMATCH:
      setUserCardErrorText(i18n.errors.checkingCardNomatchValidator)
      break
    case UserCardStatusEnum.ERROR:
      setUserCardErrorText(i18n.errors.checkingCardErrorValidator)
      break
    default:
  }

  return (
    <div
      className={cn(
        withoutHeader && styles.wrapper,
        !withoutHeader && clientDataStyles.clientData,
        isDisabled && clientDataStyles.disabled,
        clientDataStyles.premiumCard
      )}
    >
      {!withoutHeader && (
        <h3 className={clientDataStyles.header} data-number={number}>
          {i18n.buy.empikCardNumber.premiumUserData}
        </h3>
      )}

      <div className={cn(!withoutHeader && clientDataStyles.formPanel)}>
        <div className={cn(clientDataStyles.label)}>
          <Field component={Checkbox} name="userHavePremiumCheckbox" id="userHavePremiumCheckbox">
            {i18n.buy.empikCardNumber.premiumUser}
          </Field>
        </div>

        {open && (
          <div className={styles.form}>
            <span className={clientDataStyles.premiumDescription}>
              {config.app.onlineSale && i18n.buy.empikCardNumber.cardDescription}
              {!config.app.onlineSale && ONSITE_EMPIK_CARD_DESCRITPION_TEXT}
            </span>

            <label className={clientDataStyles.label} htmlFor="empikPremiumNumber">
              <FormField
                id="empikPremiumNumber"
                name="empikPremiumNumber"
                placeholder={i18n.buy.empikCardNumber.empikCardNumber}
                type="text"
                correct={activeCardText}
              />
              {isPremiumInactive && (
                <a href={config.premium.url} className={styles.buyPremiumLink} target="blank">
                  {i18n.buy.empikCardNumber.buyNow}
                </a>
              )}
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default EmpikCardNumber
