import TransactionError from 'misc/classes/TransactionError'

import { ITransactionError } from './../types'
import * as TEXTS from './texts'

const getError = (errors: ITransactionError[]): TransactionError => {
  const error = errors[0]

  return new TransactionError(
    error.message || TEXTS.DEFAULT_ERROR_TEXT,
    TEXTS.DEFAULT_ERROR_ID,
    error.type,
    [error.path || '']
  )
}

export default getError
