import React, { useContext } from 'react'

import { Close } from '@mui/icons-material'
import { Divider, Grid, IconButton, Theme, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/pl'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { Languages } from 'constants/Languages'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import useStyles from './BasketPlaceItem.styles'
import { IBasketPlaceItemProps } from './BasketPlaceItem.types'

const BasketPlaceItem = ({ basketItem }: IBasketPlaceItemProps) => {
  const {
    title,
    date,
    poolName,
    poolPrice,
    amount,
    currency,
    slot,
    upsellDuration,
    extendedUpsell,
    id,
    upsell,
    serviceFee,
    calculatePricePerPerson,
  } = basketItem
  const classes = useStyles()
  const { code } = useLang()
  const { i18n } = useDictionary()
  const state = useContext(clearContext)
  moment.locale(code === Languages.Polski ? 'pl' : 'en')

  const removeFromBasket = () => {
    state?.removeFromBag(id)
  }

  const deleteUpsell = () => {
    const founded = state?.basketItems.find((item) => item.id === id)
    const reduced = {
      ...founded,
      upsell: undefined,
      extendedUpsell: undefined,
      upsellDuration: undefined,
      price: founded?.amount && founded.poolPrice ? founded?.amount * founded?.poolPrice : 0,
    }
    // @ts-ignore
    state?.updateItem(reduced)
  }

  const renderDeleteButton = () => (
    <IconButton className={classes.close} onClick={removeFromBasket} color={'primary'}>
      <Close />
    </IconButton>
  )

  const removeUpsell = () => (
    <IconButton className={classes.close} onClick={deleteUpsell} color={'primary'}>
      <Close />
    </IconButton>
  )

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const calculatePoolPrice = () => {
    if (calculatePricePerPerson && poolPrice) {
      return amount ? poolPrice * amount + (serviceFee || 0) : poolPrice
    } else {
      return (poolPrice || 0) + (serviceFee || 0)
    }
  }

  return (
    <Grid container={true} spacing={3} className={classes.root}>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <Typography variant={'h4'} color={'textPrimary'} className={classes.fontMedium}>
              {title}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md={'auto'} className={!isTablet ? classes.date : undefined}>
            <Typography variant={'body1'} className={classes.fontMedium}>
              {`${moment(date).format('dddd')}, ${moment(date).format('DD MMM YYYY')}`}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md={'auto'}>
            <Typography variant={'body1'} className={classes.fontMedium}>
              {slot}
              {upsellDuration && ` + ${upsellDuration} min`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item={true} xs={12}>
        <Divider variant={'fullWidth'} className={classes.divider} />
      </Grid>

      <Grid item={true} xs={12}>
        <Grid
          container={true}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={isTablet ? 2 : 0}
        >
          <Grid item={true} xs={12} md={'auto'}>
            <Grid
              container={true}
              alignItems={'center'}
              justifyContent={isTablet ? 'space-between' : 'flex-start'}
            >
              <Grid item={true}>
                <Typography variant={'body1'} color={'textPrimary'} className={classes.fontMedium}>
                  {poolName}
                </Typography>
              </Grid>
              <Grid item={true} className={classes.enableOnTablet}>
                {renderDeleteButton()}
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12} md={'auto'}>
            <Grid
              container={true}
              alignItems={'center'}
              spacing={1}
              justifyContent={isTablet ? 'space-between' : 'flex-start'}
            >
              <Grid item={true}>
                <Typography
                  variant={'body1'}
                  color={'textSecondary'}
                  className={classes.fontMedium}
                >
                  {i18n.cart.pricePerItem}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography
                  variant={isTablet ? 'body1' : 'h5'}
                  color={isTablet ? 'textSecondary' : 'textPrimary'}
                  className={classes.fontMedium}
                >
                  {getPriceFormatted(poolPrice || 0)} {currency}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12} md={'auto'}>
            <Grid
              container={true}
              spacing={1}
              alignItems={'center'}
              justifyContent={isTablet ? 'space-between' : 'flex-start'}
            >
              <Grid item={true} className={classes.enableOnTablet}>
                <Typography variant={'body1'} color="textSecondary" className={classes.fontMedium}>
                  {i18n.cart.amount}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography
                  variant={isTablet ? 'body1' : 'h5'}
                  color={isTablet ? 'textSecondary' : 'textPrimary'}
                  className={classes.fontMedium}
                >
                  {amount}
                </Typography>
              </Grid>
              <Grid item={true} className={classes.disableOnTablet}>
                <Typography variant={'h5'} color="textPrimary" className={classes.fontMedium}>
                  {i18n.cart.pcs}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12} md={'auto'}>
            <Grid
              container={true}
              spacing={1}
              alignItems={'center'}
              justifyContent={isTablet ? 'space-between' : 'flex-start'}
            >
              <Grid item={true}>
                <Typography
                  variant={'body1'}
                  color={isTablet ? 'textPrimary' : 'textSecondary'}
                  className={classes.fontMedium}
                >
                  {i18n.cart.totalPrice}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography
                  variant={isTablet ? 'body1' : 'h5'}
                  color={'textPrimary'}
                  className={classes.fontMedium}
                >
                  {getPriceFormatted(calculatePoolPrice())} {currency}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} className={classes.disableOnTablet}>
            {renderDeleteButton()}
          </Grid>
        </Grid>
      </Grid>

      {/* upsell */}
      {((upsell && upsell.isSelected) || extendedUpsell) && (
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={isTablet ? 2 : 0}
          >
            <Grid item={true} xs={12} md={'auto'}>
              <Grid
                container={true}
                alignItems={'center'}
                justifyContent={isTablet ? 'space-between' : 'flex-start'}
              >
                <Grid item={true}>
                  <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    className={classes.fontMedium}
                  >
                    {i18n.cart.upsell}
                  </Typography>
                </Grid>

                <Grid item={true} className={classes.enableOnTablet}>
                  {removeUpsell()}
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} md={1} className={classes.disableOnTablet} />

            <Grid item={true} xs={12} md={'auto'}>
              <Grid
                container={true}
                alignItems={'center'}
                justifyContent={isTablet ? 'space-between' : 'flex-start'}
              >
                <Grid item={true} className={classes.enableOnTablet}>
                  <Typography
                    variant={'body1'}
                    color={'textSecondary'}
                    className={classes.fontMedium}
                  >
                    {i18n.cart.upsellTime}
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <Typography
                    variant={isTablet ? 'body1' : 'h5'}
                    color={isTablet ? 'textSecondary' : 'textPrimary'}
                    className={classes.fontMedium}
                  >
                    +{upsellDuration || extendedUpsell?.duration} min
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} xs={12} md={'auto'}>
              <Grid
                container={true}
                spacing={1}
                alignItems={'center'}
                justifyContent={isTablet ? 'space-between' : 'flex-start'}
              >
                <Grid item={true}>
                  <Typography
                    variant={'body1'}
                    color={isTablet ? 'textPrimary' : 'textSecondary'}
                    className={classes.fontMedium}
                  >
                    {i18n.cart.totalPrice}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography
                    variant={isTablet ? 'body1' : 'h5'}
                    color={'textPrimary'}
                    className={classes.fontMedium}
                  >
                    {getPriceFormatted(upsell?.price || 0)} {currency}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} className={classes.disableOnTablet}>
              {removeUpsell()}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default BasketPlaceItem
