import * as React from 'react'

import EntryListStepper from 'components/EntryList'
import { PageWrapper } from 'components/_layout/PageWrapper'

export const EntryListPage = () => (
  <PageWrapper fullWidth>
    <EntryListStepper />
  </PageWrapper>
)
