/**
 * Declare route / module here.
 *
 * IMPORTANT! Types for every route params should be declared in ./types.ts.
 */
export enum Route {
  about = '<ABOUT>',
  account = '<ACCOUNT>',
  activities = '<ACTIVITIES>',
  activitiesDefault = '<ACTIVITIESDEFAULT>',
  activity = '<ACTIVITY>',
  artist = '<ARTIST>',
  auth = '<AUTH>',
  booking = '<BOOKING>',
  buy = '<BUY>',
  buyLegacy = '<BUYLEGACY>',
  buyProducts = '<BUYPRODUCTS>',
  calendar = '<CALENDAR>',
  cart = '<CART>',
  cartBuy = '<CARTBUY>',
  contact = '<CONTACT>',
  deeplinkTicket = '<DEEPLINKTICKET>',
  entryList = '<ENTRYLIST>',
  error = '<ERROR>',
  error404 = '<ERROR404>',
  event = '<EVENT>',
  eventLegacy = '<EVENTLEGACY>',
  events = '<EVENTS>',
  form = '<FORM>',
  favorites = '<FAVORITES>',
  giftCardActivation = '<GIFTCARDACTIVATION>',
  happening = '<HAPPENING>',
  help = '<HELP>',
  index = '<INDEX>',
  inspirations = '<INSPIRATIONS>',
  inspirationsDefault = '<INSPIRATIONSDEFAULT>',
  landingPage = '<LANDINGPAGE>',
  mainPage = '<MAINPAGE>',
  myPass = '<MYPASS>',
  myPassDetails = '<MYPASSDETAILS>',
  payment = '<PAYMENT>',
  paymentFail = '<PAYMENTFAIL>',
  paymentSuccess = '<PAYMENTSUCCESS>',
  place = '<PLACE>',
  profile = '<PROFILE>',
  recommend = '<RECOMMEND>',
  rundate = '<RUNDATE>',
  search = '<SEARCH>',
  searchWithParams = '<SEARCHWITHPARAMS>',
  selection = '<SELECTION>',
  selectionLegacy = '<SELECTIONLEGACY>',
  sendMailSuccess = '<SENDMAILSUCCESS>',
  settings = '<SETTINGS>',
  staticArticle = '<STATICARTICLE>',
  ticketManagement = '<TICKETMANAGEMENT>',
  ticketManagementAction = '<TICKETMANAGEMENTACTION>',
  ticketManagementActionError = '<TICKETMANAGEMENTACTIONERROR>',
  ticketManagementTicket = '<TICKETMANAGEMENTTICKET>',
  ticketReception = '<TICKETRECEPTION>',
  ticketReceptionFail = '<TICKETRECEPTIONFAIL>',
  ticketReceptionSuccess = '<TICKETRECEPTIONSUCCESS>',
  returnBankAccount = '<RETURNBANKACCOUNT>',
  /** EMBEDS */
  pinActivity = '<PINACTIVITY>',
  pinCartBuy = '<PINCARTBUY>',
  pinBuyLegacy = '<PINBUYLEGACY>',
  pinBuyProducts = '<PINBUYPRODUCTS>',
  pinBuy = '<PINBUY>',
  pinCart = '<PINCART>',
  pinEntryList = '<PINENTRYLIST>',
  pinEventLegacy = '<PINEVENTLEGACY>',
  pinEvents = '<PINEVENTS>',
  pinForm = '<PINFORM>',
  pinHappening = '<PINHAPPENING>',
  pinRundate = '<PINRUNDATE>',
  pinTicketReception = '<PINTICKETRECEPTION>',
  pinExtendedPayment = '<PINEXTENDEDPAYMENT>',
  pinEventModal = '<PINEVENTMODAL>',
  /** ENGLISH */
  enBuy = '<ENBUY>',
  enHappening = '<ENHAPPENING>',
  enPaymentFail = '<ENPAYMENTFAIL>',
  enPaymentSuccess = '<ENPAYMENTSUCCESS>',
  enPayment = '<ENPAYMENT>',
  enRundate = '<ENRUNDATE>',
}

const embedRoutes = {
  [Route.pinActivity]: '/activities',
  [Route.pinCartBuy]: '/payment',
  [Route.pinBuyLegacy]: '/buying-id/:id',
  [Route.pinBuyProducts]: '/buy-products/:slug',
  [Route.pinBuy]: '/buying/:eventSlug/:rundateSlug',
  [Route.pinCart]: '/cart',
  [Route.pinEntryList]: '/entry-list',
  [Route.pinEventLegacy]: '/event-id/:id',
  [Route.pinRundate]: '/event/:eventSlug/:rundateSlug',
  [Route.pinEvents]: '/embed',
  [Route.pinForm]: '/formio/:slug',
  [Route.pinHappening]: '/buy-happening/:slug',
  [Route.pinTicketReception]: '/accept-ticket/:slug',
  [Route.pinExtendedPayment]: '/extended-payment',
  [Route.pinEventModal]: '/event-embed/:eventSlug/:rundateSlug',
}

const enRoutes = {
  [Route.enBuy]: '/buy-ticket/:eventSlug/:rundateSlug',
  [Route.enHappening]: '/buy-ticket/:slug',
  [Route.enPaymentFail]: '/payment/:transactionId/failure',
  [Route.enPaymentSuccess]: '/payment/:transactionId/success',
  [Route.enPayment]: '/payment/:transactionId',
  [Route.enRundate]: '/event/:eventSlug/:rundateSlug',
}

export const routes = {
  [Route.about]: '/o-aplikacji',
  [Route.account]: '/moje-konto',
  [Route.activitiesDefault]: '/aktywnosci',
  [Route.activities]: '/aktywnosci/:slug',
  [Route.activity]: '/aktywnosc/:eventSlug',
  [Route.artist]: '/artysta/:slug',
  [Route.auth]: '/autoryzacja/:token',
  [Route.booking]: '/rezerwacja/:slug',
  [Route.buyLegacy]: '/buy/:id',
  [Route.buyProducts]: '/kup-produkty/:slug',
  [Route.buy]: '/kup-bilety/:eventSlug/:rundateSlug',
  [Route.calendar]: '/ulubione',
  [Route.cartBuy]: '/koszyk-platnosc',
  [Route.cart]: '/koszyk',
  [Route.contact]: '/kontakt/:slug',
  [Route.deeplinkTicket]: '/ticket/:ticketId',
  [Route.eventLegacy]: '/evt/:id/:eventSlug?',
  [Route.events]: '/wydarzenia',
  [Route.event]: '/wydarzenie/:eventSlug',
  [Route.favorites]: '/ulubione/:type',
  [Route.form]: '/form/:slug',
  [Route.giftCardActivation]: '/karta-prezentowa',
  [Route.happening]: '/kup-bilety/:slug',
  [Route.help]: '/pomoc-going',
  [Route.index]: '/',
  [Route.inspirationsDefault]: '/inspiracje',
  [Route.inspirations]: '/inspiracje/:slug',
  [Route.landingPage]: '/lp/:slug',
  [Route.mainPage]: '/miasto/:slug',
  [Route.myPass]: '/moj-karnet',
  [Route.myPassDetails]: '/moj-karnet/:identity/:email',
  [Route.paymentFail]: '/rezultat-platnosci/:transactionId/platnosc-nieudana',
  [Route.paymentSuccess]: '/rezultat-platnosci/:transactionId/platnosc-udana',
  [Route.payment]: '/rezultat-platnosci/:transactionId',
  [Route.place]: '/miejsce/:slug',
  [Route.profile]: '/profil/:type/:slug',
  [Route.rundate]: '/wydarzenie/:eventSlug/:rundateSlug',
  [Route.ticketReceptionFail]: '/odbierz-bilet/blad/:slug',
  [Route.ticketReceptionSuccess]: '/odbierz-bilet/odebrano/:slug',
  [Route.ticketReception]: '/odbierz-bilet/:slug',
  [Route.ticketManagement]: '/moj-bilet',
  [Route.ticketManagementTicket]: '/moj-bilet/manager/:code/:email',
  [Route.ticketManagementAction]: '/moj-bilet/manager/:code/:email/:ticketIndex/:actionKey',
  [Route.ticketManagementActionError]:
    '/moj-bilet/manager/:code/:email/:ticketIndex/:actionKey/error/:errorCode',
  [Route.recommend]: '/polecamy/:slug/:extraSlug?',
  [Route.search]: '/szukaj',
  [Route.searchWithParams]: '/szukaj/:locationSlug/:categorySlug?/:tagSlug?/:dateSetting?',
  [Route.selectionLegacy]: '/selection/:id/:slug?',
  [Route.selection]: '/selekcja/:slug',
  [Route.sendMailSuccess]: '/wyslij-mailem/:id/wyslano',
  [Route.settings]: '/ustawienia',
  [Route.staticArticle]: '/artykul/:sourceSlug/:articleSlug',
  [Route.returnBankAccount]: '/zwroty/numer-konta/:hash',
  ...embedRoutes,
  ...enRoutes,
}
