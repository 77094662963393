import React from 'react'

import cn from 'classnames'

import styles from 'components/buying/Pools/components/Loading/Loading.module.scss'
import config from 'config'
import goTrough from 'misc/helpers/goTrough'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

const Loading = () => {
  const { isDesktop } = useBreakpoints()

  return (
    <div className={styles.loading}>
      <div className={cn(styles.column, styles.columnLeft)}>
        {isDesktop && <div className={styles.fakeImage} />}
        <div className={styles.ticketInfo}>
          <div className={styles.fakeTitle} />
          <div className={styles.fakeDescription} />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.fakePrice} />
      </div>
      <div className={styles.column}>
        <div className={styles.fakeButtons}>
          {goTrough(config.buy.maxTicketsCount).map((key) => (
            <div key={key} className={styles.fakeButton} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Loading
