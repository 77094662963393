import { filter as filter$, map as map$ } from 'rxjs/operators'

import _Store from '@Store'

import { getHappening } from 'models/happening/actions'
import { isActionOf } from 'typesafe-actions'

import { resetLoading, setLoading } from './../actions'
import * as CONSTS from './../constants/constants'

export const setLoaderWhenHappeningFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getHappening.request])),
    map$(() => setLoading(CONSTS.HAPPENING))
  )
}

export const setLoaderWhenHappeningFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getHappening.success, getHappening.failure], action)
    ),
    map$(() => resetLoading(CONSTS.HAPPENING))
  )
}
