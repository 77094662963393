export const BUY_AND_PAY_ONLINE = 'Empik/transaction/BUY_AND_PAY_ONLINE'
export const BUY_AND_PAY_ONSITE = 'Empik/transaction/BUY_AND_PAY_ONSITE'

export const UNMOUNT = 'Empik/transaction/UNMOUNT'

export const FAIL_SUMMARY_MOUNTED = 'Empik/transaction/FAIL_SUMMARY_MOUNTED'
export const SUCCESS_SUMMARY_MOUNTED = 'Empik/transaction/SUCCESS_SUMMARY_MOUNTED'
export const REDIRECT_AFTER_SALE = 'Empik/transaction/REDIRECT_AFTER_SALE'

export const SET_CARD_CREDENTIALS = 'Empik/transaction/SET_CARD_CREDENTIALS'

// Private
export const _POST_REQUEST = 'Empik/transaction/_POST_REQUEST'
export const _POST_SUCCESS = 'Empik/transaction/_POST_SUCCESS'
export const _POST_FAILURE = 'Empik/transaction/_POST_FAILURE'

export const _GET_TRANSACTION_INFO_FAILURE = 'Empik/transaction/_GET_TRANSACTION_INFO_FAILURE'
export const _GET_TRANSACTION_INFO_REQUEST = 'Empik/transaction/_GET_TRANSACTION_INFO_REQUEST'
export const _GET_TRANSACTION_INFO_SUCCESS = 'Empik/transaction/_GET_TRANSACTION_INFO_SUCCESS'

export const _GET_TRANSACTION_DETAILS_FAILURE = 'Empik/transaction/_GET_TRANSACTION_DETAILS_FAILURE'
export const _GET_TRANSACTION_DETAILS_REQUEST = 'Empik/transaction/_GET_TRANSACTION_DETAILS_REQUEST'
export const _GET_TRANSACTION_DETAILS_SUCCESS = 'Empik/transaction/_GET_TRANSACTION_DETAILS_SUCCESS'

export const _GET_PAYMENT_RUNDATE_SLUG_REQUEST = 'Empik/event/_GET_PAYMENT_RUNDATE_SLUG_REQUEST'
export const _GET_PAYMENT_RUNDATE_SLUG_SUCCESS = 'Empik/event/_GET_PAYMENT_RUNDATE_SLUG_SUCCESS'
export const _GET_PAYMENT_RUNDATE_SLUG_FAILURE = 'Empik/event/_GET_PAYMENT_RUNDATE_SLUG_FAILURE'
