import { connect } from 'react-redux'

import _Store from '@Store'

import { getModule } from 'models/internalRouter/selectors'
import { getSelectedTickets } from 'models/pools/selectors'

import Invoice from './Invoice.component'
import { IInvoiceFromState, IInvoiceOwnProps } from './Invoice.types'

const mapStateToProps = (state: _Store.IState): IInvoiceFromState => ({
  module: getModule(state),
  selectedTickets: getSelectedTickets(state),
})

export default connect<IInvoiceFromState, void, IInvoiceOwnProps, _Store.IState>(mapStateToProps)(
  Invoice
)
