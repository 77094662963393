import React from 'react'

import { INotice, Notifier } from '@airbrake/browser'

import config from 'config'

import { IErrorBoundaryOwnState, IErrorBoundaryProps } from './ErrorBoundary.types'

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryOwnState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
    window.airbrake = new Notifier({
      projectId: config.app.airbrakeId,
      projectKey: config.app.airbrakeKey,
    })
    window.airbrake.addFilter((notice: INotice) => {
      const isResizeObserverError =
        notice?.errors?.length &&
        notice?.errors?.length > 0 &&
        notice.errors[0].message.includes('ResizeObserver')

      if (isResizeObserverError) {
        return null
      }

      return notice
    })
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true })
    const jQueryError = error.message.search('jQuery')
    const resizeObserverError = error.message.search('ResizeObserver')

    if (!jQueryError && !resizeObserverError) {
      // Send error to Airbrake
      window.airbrake.notify({
        error,
        params: { ...info, embed: this.props.isEmbed },
      })
    }
  }

  public render() {
    return this.props.children
  }
}

export default ErrorBoundary
