export const SET_LOADING = 'Empik/state/SET_LOADING'
export const RESET_LOADING = 'Empik/state/RESET_LOADING'

export const SET_REQUESTED = 'Empik/state/SET_REQUESTED'
export const RESET_REQUESTED = 'Empik/state/RESET_REQUESTED'

export const SET_RESOURCE_URL = 'Empik/state/SET_RESOURCE_URL'
export const RESET_RESOURCE_URL = 'Empik/state/RESET_RESOURCE_URL'

export const SET_SKIP_NEXT_REQUEST = 'Empik/state/SET_SKIP_NEXT_REQUEST'
export const RESET_SKIP_NEXT_REQUEST = 'Empik/state/RESET_SKIP_NEXT_REQUEST'
