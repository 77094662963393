import { connect } from 'react-redux'

import _Store from '@Store'

import { isBrowser } from 'models/__base__/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import { getCardCredentials, getData, isPayUPaymentAvailable } from 'models/transaction/selectors'

import PayURedirect from './RedirectForPayU.component'
import { IRedirectForPayUData, IRedirectForPayUProps } from './RedirectForPayU.types'

const mapStateToProps = (state: _Store.IState): IRedirectForPayUProps => ({
  data: getData(state) as IRedirectForPayUData,
  isBrowser: isBrowser(state) && !getIframeParams(state),
  isPaymentAvailable: isPayUPaymentAvailable(state),
  isCardPayment: !!getCardCredentials(state),
})

export default connect<IRedirectForPayUProps, null, {}, _Store.IState>(mapStateToProps)(
  PayURedirect
)
