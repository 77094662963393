import { Theme } from '@mui/material'
import { StyleRules, createStyles, makeStyles } from '@mui/styles'

const useStyles = (reversed: boolean, isMobile: boolean, isBgDark: boolean, color?: string) => {
  const flexDirection = () => {
    if (isMobile) {
      return 'column-reverse'
    } else {
      return reversed ? 'row' : 'row-reverse'
    }
  }

  return makeStyles((theme: Theme) => {
    const fontColor = color
      ? color
      : isBgDark
      ? theme.palette.grey['50']
      : theme.palette.grey['900']

    return createStyles({
      button: {
        borderColor: fontColor,
        borderRadius: '4rem',
        borderStyle: 'solid',
        borderWidth: 1,
        minHeight: '3rem',
        textAlign: 'center',
      },
      buttonContainer: {
        '& > *': {
          flex: 1,
        },
        display: 'flex',
      },
      centerAlign: {
        justifyContent: 'center',
      },
      fontColor: {
        color: fontColor,
      },
      h2mobile: {
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      image: {
        borderRadius: '1rem',
        width: '100%',
      },
      root: {
        '& > div': {
          '& > *': {
            marginBottom: '1.5rem',
          },
          '&:first-of-type': reversed
            ? {
                marginRight: isMobile ? 'unset' : '2rem',
              }
            : undefined,
          '&:last-of-type': !reversed
            ? {
                marginRight: isMobile ? 'unset' : '2rem',
              }
            : undefined,
          display: 'flex',
          flex: isMobile ? undefined : 1,
          flexDirection: 'column',
        },
        display: 'flex',
        flexDirection,
        justifyContent: isMobile ? 'flex-end' : undefined,
        marginBottom: isMobile ? '3rem' : undefined,
        minHeight: isMobile ? undefined : '100vh',
      },
    } as StyleRules)
  })
}

export default useStyles
