import React, { FC, PropsWithChildren, useEffect, useState } from 'react'

import { Actions, Qualifiers } from '@cloudinary/url-gen'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

import config from 'config'
import { cloudinary } from 'services/Api/Cloudinary'

import { Styled } from './ImageFixed.styles'
import { IImageFixedProps } from './ImageFixed.types'

const {
  Delivery: { format, quality },
  Resize: { fill },
} = Actions
const {
  Quality: { autoEco },
  Format: { webp },
} = Qualifiers

export const ImageFixed: FC<PropsWithChildren<IImageFixedProps>> = ({
  alt,
  aspectRatio,
  children,
  className,
  onClick,
  src,
  to,
  transformation = { width: 810, height: 700 },
}) => {
  const fallbackMosaic = `${config.app.baseUrl}/static_legacy/fallbackImg/mosaic_${
    Math.round(Math.random() * 10) + 1
  }.jpg`
  const [inViewRef, inView] = useInView({ threshold: 0.01, triggerOnce: true })
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [source, changeSource] = useState<string>(src || fallbackMosaic)

  const onError = () => {
    if (!isError) {
      setError(true)
      changeSource(fallbackMosaic)
    }
  }

  useEffect(() => {
    if (!src) changeSource(fallbackMosaic)
    else changeSource(src)
  }, [src])

  return (
    <Styled.Root
      to={to}
      component={!!to ? Link : 'div'}
      aspectRatio={aspectRatio || [transformation.width, transformation.height]}
      ref={inViewRef}
      onClick={onClick}
    >
      {inView && (
        <Styled.Image
          isLoading={isLoading}
          onLoad={() => setLoading(false)}
          src={
            isError || source.startsWith('https://') || source.startsWith('http://')
              ? source
              : cloudinary
                  .image(source)
                  .resize(fill().width(transformation.width).height(transformation.height))
                  .delivery(format(webp()))
                  .delivery(quality(autoEco()))
                  .toURL()
          }
          alt={alt || 'Image preview'}
          className={className}
          draggable={'false'}
          onError={onError}
          width={transformation.width}
          height={transformation.height}
        />
      )}
      {isLoading ? <Styled.Skeleton variant={'rectangular'} /> : children}
    </Styled.Root>
  )
}

export default ImageFixed
