import * as TEXTS from 'services/$tickets-api/helpers/texts'

class TicketTransferError extends Error {
  private readonly _errorCode: string
  private readonly _errorMessage: string | undefined
  private readonly _status: boolean

  constructor(status: boolean, errorCode?: string, errorMessage?: string) {
    const _message = errorMessage ?? TEXTS.DEFAULT_ERROR_TEXT
    super(_message)

    this._errorCode = errorCode ?? TEXTS.DEFAULT_ERROR_CODE
    this._status = status
  }

  get status(): boolean {
    return this._status
  }

  get code(): string | undefined {
    return this._errorCode
  }

  get message(): string {
    return this._errorMessage ? this._errorMessage : ''
  }
}

export default TicketTransferError
