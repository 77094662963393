import React from 'react'

import { Button, Typography } from '@mui/material'

import { Account } from 'components/Account'
import { useUser } from 'components/_functional/UserContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const AccountPage = () => {
  const { i18n } = useDictionary()
  const { goingMetadata, firebaseUser, dialog } = useUser()

  if (!firebaseUser || !goingMetadata)
    return (
      <PageWrapper gap={3}>
        <Typography children={i18n.account.loginFirst} />
        <Button variant={'contained'} onClick={dialog.show} children={i18n.auth.login} fullWidth />
      </PageWrapper>
    )

  return (
    <PageWrapper gap={3}>
      <Account user={firebaseUser} />
    </PageWrapper>
  )
}
