import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { addToGlobalBasket } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'

import AddToGlobalBasket from './AddToGlobalBasket.component'
import {
  IAddToGlobalBasketFromDispatch,
  IAddToGlobalBasketFromState,
} from './AddToGlobalBasket.types'

const mapStateToProps = (state: _Store.IState): IAddToGlobalBasketFromState => ({
  partnerId: getIframeParams(state)?.partnerId,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IAddToGlobalBasketFromDispatch => ({
  addToParentBasket: (items) => dispatch(addToGlobalBasket(items)),
})

export default connect<
  IAddToGlobalBasketFromState,
  IAddToGlobalBasketFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AddToGlobalBasket)
