import React from 'react'

import { Formik } from 'formik'

import { IFormValues } from 'models/entryList/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import stepNames from '../../steps'
import FormLayout from '../FormLayout'
import { makeValidationSchema } from './PersonalData.selector'
import { IPersonalDataProps } from './PersonalData.types'

const PersonalData = ({ handleNextStep, initialValues, setFormValues }: IPersonalDataProps) => {
  const { i18n } = useDictionary()

  const handleFormSubmit = (values: IFormValues) => {
    setFormValues(values)
    handleNextStep(stepNames.summary)
  }

  return (
    <Formik
      validationSchema={makeValidationSchema(i18n)}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      component={FormLayout}
      enableReinitialize={true}
    />
  )
}

export default PersonalData
