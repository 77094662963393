import axios, { CancelTokenSource } from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'

class MailApi {
  private static sendMailUrl = (id: string) =>
    `${config.api.api3Url}/transaction/${id}/send-tickets`

  public cancelTokenMail?: CancelTokenSource

  public sendMail = async (hashId: string): Promise<[]> =>
    new Promise<[]>((resolve, reject) => {
      this.cancelTokenMail = axios.CancelToken.source()

      return axios
        .post(
          MailApi.sendMailUrl(hashId),
          {},
          withCacheHeader({
            cancelToken: this.cancelTokenMail.token,
          })
        )
        .then(getData)
        .then((data) => resolve(data))
        .catch((error) => {
          reject(catchHttpError(new Error(error)))
        })
    })

  public cancelMail() {
    if (this.cancelTokenMail) {
      this.cancelTokenMail.cancel()
      this.cancelTokenMail = undefined
    }
  }
}

export default new MailApi()
