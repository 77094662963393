import React, { FC } from 'react'

import { Radio as MaterialRadio } from '@mui/material'
import cn from 'classnames'
import { ErrorMessage } from 'formik'

import classes from './Radio.classes'
import styles from './Radio.module.scss'
import { IRadioProps } from './Radio.types'

const Radio: FC<IRadioProps> = ({ field: { name, value, onChange, onBlur }, id, children }) => {
  return (
    <div className={cn(styles.radioButton, classes.radioButton)}>
      <label htmlFor={id} className={classes.label}>
        <MaterialRadio
          name={name}
          id={id}
          value={id}
          checked={id === value}
          onChange={onChange}
          onBlur={onBlur}
          color={'primary'}
        />
        {children}
      </label>
      <ErrorMessage name={name} component="span" className={classes.error} />
    </div>
  )
}

export default Radio
