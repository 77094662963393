import React from 'react'

import { ArrowForward } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import Brand from 'components/_layout/Brand'
import { Flex } from 'components/_layout/Flex'
import ImageFixed from 'components/reusable/ImageFixed'
import { currencySymbol } from 'constants/Currency'
import { DateTime } from 'services/DateTime'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { IRundateResponse } from 'types/EventRundate'
import { IPoolsResponse } from 'types/Pools'

export const EventModal = ({
  eventData: {
    rundate,
    pools: { pools },
  },
  cloudinaryImages,
  params,
}: {
  eventData: {
    rundate: IRundateResponse
    pools: IPoolsResponse
  }
  cloudinaryImages: string[]
  params: qs.ParsedQs
}) => {
  const { i18n } = useDictionary()
  const { isDarkTheme } = useAppState()
  const cheapestPool = pools.length
    ? pools.sort((pool, next) => pool.price - next.price)[0]
    : undefined
  const isImageHidden = 'isImageHidden' in params && params.isImageHidden

  return (
    <Flex sx={{ borderRadius: 4, overflow: 'hidden' }}>
      {!isImageHidden && (
        <ImageFixed
          src={
            rundate.event.externalImages?.[0] ||
            cloudinaryImages?.[0] ||
            rundate.event.images[0]?.large
          }
        />
      )}
      <Flex fullWidth>
        <Flex sx={{ p: 4, gap: 2, backgroundColor: 'background.paper' }} fullWidth>
          <Typography variant={'h5'} children={rundate.event.title} />
          <Typography
            color={'text.secondary'}
            variant={'body1'}
            children={DateTime.getFriendlyDate({
              isoDate: DateTime.getIsoDateString(rundate.startDate),
            })}
          />
          <Button
            children={
              !cheapestPool
                ? i18n.eventEmbed.check
                : `${i18n.eventEmbed.ticketsFrom} ${cheapestPool.price} ${
                    currencySymbol[cheapestPool.currency]
                  }`
            }
            fullWidth
            endIcon={<ArrowForward />}
            href={prepareRoute({
              route: Route.rundate,
              params: { eventSlug: rundate.event.slug, rundateSlug: rundate.slug },
            })}
            target={'_blank'}
            variant={'contained'}
            size={'medium'}
          />
        </Flex>
        <Flex
          fullWidth
          justify={'center'}
          align={'center'}
          sx={{ backgroundColor: isDarkTheme ? '#212121' : '#f5f5f5' }}
        >
          <Brand target={'_blank'} />
        </Flex>
      </Flex>
    </Flex>
  )
}
