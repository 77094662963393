import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

type StyleDefinitions<Props extends object, ThemeType extends object = Theme> = (
  props: Props
) => Record<string, SxProps<ThemeType>>

/**
 * Function simplifies writing sx props for MUI components. Usage:
 *
 * // Define styles and props type
 * const useStyles = makeSxStyles(({ isLoading }: { isLoading: boolean }) => ({
 *     root: {
 *       backgroundColor: isLoading ? 'red' : 'blue, // plain object without theme
 *     }
 *     input: (theme) => ({
 *       backgroundColor: theme.palette.primary.main, // function with theme
 *     }),
 *   })
 * )
 *
 * // Call hook in a component (if no props are needed, just call useStyles({}) with an empty object).
 * const styles = useStyles({ isLoading })
 *
 * // Use styles in a component. TODO: Add type check on styles object.
 * <MuiComponent1 sx={styles.root} />
 * <MuiComponent2 sx={styles.input} />
 */
export const makeSxStyles =
  <Props extends object, ThemeType extends object = Theme>(
    styleDefinitions: StyleDefinitions<Props, ThemeType>
  ) =>
  (props: Props) =>
    styleDefinitions(props)
