import { isToday, isTomorrow } from 'date-fns'
import format from 'date-fns/format'
import { enGB, pl } from 'date-fns/locale'

import EventsListService from 'misc/classes/EventsListService'
import { capitalizeFirstLetter } from 'misc/helpers/capitalizeFirstLetter'
import { IDate } from 'types/DateTime'
import { IDateTranslate } from 'types/Locale'

const DEFAULT_DATE_TRANSLATE = {
  locale: 'pl',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
}

/**
 * Function to prepare url with Date from DateObject for specified locale
 *
 * @param {IDate | undefined} date - date Object as year, month, day, hour, minutes
 * @param rundateDescription
 *
 * @param dateTranslate
 * @param endDate
 * @return {string} friendlyDate
 */
const translatableDate = (
  date: IDate | string | undefined,
  rundateDescription: string | undefined,
  dateTranslate: IDateTranslate,
  endDate?: IDate | string
) => {
  if (rundateDescription) {
    return rundateDescription
  }
  let startDate: string = ''
  let newEndDate: string = ''

  if (date && typeof date !== 'string') {
    startDate = EventsListService.getDateString(date)
  } else if (typeof date === 'string') {
    startDate = date
  }

  if (!startDate) {
    return ''
  }

  if (endDate && typeof endDate !== 'string') {
    newEndDate = EventsListService.getDateString(endDate)
  } else if (typeof endDate === 'string') {
    newEndDate = endDate
  }

  const _date = new Date(startDate)
  const timezonedDate = new Date(new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000))

  if (isToday(timezonedDate) && !endDate) {
    return dateTranslate?.today || DEFAULT_DATE_TRANSLATE.today
  } else if (isTomorrow(timezonedDate) && !endDate) {
    return dateTranslate?.tomorrow || DEFAULT_DATE_TRANSLATE.tomorrow
  }

  const locale = dateTranslate?.locale === 'en' ? enGB : pl

  if (!endDate || endDate.toString().split('T')[0] === date?.toString().split('T')[0]) {
    return capitalizeFirstLetter(format(timezonedDate, 'EEEE, d MMMM yyyy', { locale }))
  }

  const _endDate = new Date(newEndDate)
  const timezonedEndDate = new Date(
    new Date(_endDate.valueOf() + _endDate.getTimezoneOffset() * 60 * 1000)
  )

  const timezonedFullDateLeft = capitalizeFirstLetter(
    capitalizeFirstLetter(format(timezonedDate, 'EEEE, d MMMM yyyy', { locale }))
  )
  const timezonedFullDateRight = capitalizeFirstLetter(
    format(timezonedEndDate, 'EEEE, d MMMM yyyy', { locale })
  )

  if (timezonedFullDateLeft === timezonedFullDateRight) {
    return timezonedFullDateLeft
  }

  const timezonedDateLeft = timezonedDate.getFullYear()
  const timezonedDateRight = timezonedEndDate.getFullYear()

  const isSameYear = timezonedDateLeft === timezonedDateRight

  return (
    capitalizeFirstLetter(
      format(timezonedDate, isSameYear ? 'd.MM - ' : 'd.MM.yy - ', { locale })
    ) + capitalizeFirstLetter(format(timezonedEndDate, 'd.MM.yy', { locale }))
  )
}

export default translatableDate
