import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { SendMailSuccess } from 'components/SendMailSuccess'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/StaticPage'
import { mounted } from 'models/mail/actions'

const SEND_MAIL_SUCCESS_TEXT = 'Wyślij bilety mailem'

export const SendMailSuccessPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(mounted())
  }, [])

  return (
    <PageWrapper>
      <Helmet pageName={SEND_MAIL_SUCCESS_TEXT} />
      <SendMailSuccess />
    </PageWrapper>
  )
}
