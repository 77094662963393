import { getType } from 'typesafe-actions'

import { getEvent, setCurrentKey } from './../actions'
import { IAction, IEventReducer } from './../types'

const initialState: IEventReducer = {
  currentKey: null,
  data: {},
  requestTimes: {},
}

const reducer = (state: IEventReducer = initialState, action: IAction): IEventReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getEvent.success):
      const { event, key } = action.payload

      return {
        ...state,
        currentKey: key,
        data: {
          ...state.data,
          [key]: event,
        },
        requestTimes: {
          ...state.requestTimes,
          [key]: Date.now(),
        },
      }

    case getType(setCurrentKey):
      return {
        ...state,
        currentKey: action.payload,
      }

    default:
      return state
  }
}

export default reducer
