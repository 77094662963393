/**
 * Check if a given path matches a route pattern. THIS WILL NOT WORK WITH A FULL URL.
 *
 * @param {string} route - The route pattern with ':params'.
 * @param {string} path - The path to check against the route pattern.
 *
 * @returns {boolean} - True if the path matches the route pattern, false otherwise.
 */
export const isRouteMatch = (route: string, path: string): boolean => {
  const routeParts = route.split('/')
  const pathParts = path.split('/')

  if (routeParts.length !== pathParts.length) {
    return false
  }

  for (let i = 0; i < routeParts.length; i++) {
    if (routeParts[i] !== pathParts[i] && !routeParts[i].startsWith(':')) {
      return false
    }
  }

  return true
}
