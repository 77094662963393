import React from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Link, Typography } from '@mui/material'
import { animated, useTransition } from 'react-spring'

import { IShowMoreProps } from 'components/reusable/ShowMore/ShowMore.types'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useToggle } from 'misc/hooks/useToggle'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './ShowMore.styles'

const AnimatedBox = animated(Box)

export const ShowMore = ({ text }: IShowMoreProps) => {
  const { i18n } = useDictionary()
  const { isVisible, handleToggle } = useToggle(false)
  const { isDesktop } = useBreakpoints()
  const styles = useStyles({ isDesktop })
  const transitions = useTransition(isVisible ? text : '', (item) => item, {
    from: { opacity: 0, maxHeight: 0, marginTop: 0 },
    enter: { opacity: 1, maxHeight: 400, marginTop: 8 },
    leave: { opacity: 0, maxHeight: 0, marginTop: 0 },
    config: { tension: 280, friction: 60 },
  })

  if (!text) return null

  return (
    <Box sx={styles.root}>
      <Link
        component={Button}
        sx={styles.button}
        size={'small'}
        variant={'button'}
        onClick={handleToggle}
      >
        {isVisible ? (
          <ExpandLess sx={styles.icon} fontSize={'small'} />
        ) : (
          <ExpandMore sx={styles.icon} fontSize={'small'} />
        )}
        {isVisible ? i18n.showMore.less : i18n.showMore.more}
      </Link>
      {transitions.map(({ item, key, props }) => (
        <AnimatedBox sx={{ overflow: 'hidden' }} key={key} style={props}>
          <Typography align={'left'} variant={'body2'} color={'secondary'} children={item} />
        </AnimatedBox>
      ))}
    </Box>
  )
}
