import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  controlButton: {
    borderColor: theme.border.color,
    borderRadius: theme.border.radius,
    borderStyle: theme.border.style,
    borderWidth: theme.border.width,
    height: theme.spacing(6),
    maxWidth: theme.spacing(6),
    minWidth: theme.spacing(6),
  },
  slider: {
    margin: `auto ${theme.spacing(4)}`,
    height: 3,
    position: 'relative',
    '& div.rc-slider-rail': {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
      position: 'absolute',
      width: '100%',
    },
    '& div.rc-slider-track': {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
      position: 'absolute',
    },
    '& div.rc-slider-handle': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 6,
      height: 12,
      position: 'absolute',
      top: -5,
      width: 12,
    },
  },
  expand: {
    flex: 1,
  },
  fullWidth: {
    width: '100%',
  },
  icon: {
    color: theme.palette.text.primary,
  },
  sliderWrapper: {
    alignSelf: 'center',
    flex: 1,
    width: '100%',
  },
  value: {
    paddingRight: theme.spacing(3),
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.border.radius,
    borderWidth: theme.border.width,
    borderColor: theme.border.color,
    borderStyle: theme.border.style,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(5),
    },
  },
  detailsButton: {
    borderRadius: 0,
    textTransform: 'capitalize',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}))

export default useStyles
