import { FormFields } from 'components/_forms/_constants/FieldNames'
import { SearchTypes } from 'constants/CloudSearch'
import { FavoritesTypes } from 'constants/Favorites'
import { Languages } from 'constants/Languages'
import {
  TicketActionErrors,
  TicketActions,
  TicketRefundOptions,
  TicketTransferStatus,
} from 'services/Api/TicketManagementApi'
import { ILangMap } from 'types/Locale'

export const en: ILangMap = {
  _lang: Languages.English,
  account: {
    helpAndFeedback: 'Help and feedback',
    myCity: 'My city',
    faq: 'FAQ',
    following: 'Following',
    contact: 'Contact',
    loginFirst: 'Log in to access your account.',
  },
  actionBar: {
    noSales: "We're not selling tickets for this event.",
    salesStart: (date: string, time: string) => `Sales start on ${date} at ${time}`,
  },
  activity: {
    samePlaceActivitiesTitle: 'other activities in this place',
  },
  artist: {
    nearest: 'Nearest show:',
    noUpcomingEvents: 'Currently not on tour. Follow the artist to stay up to date.',
    pastEvents: 'Past events',
    upcomingEvents: 'Upcoming events',
    showMore: 'Show more',
    onTour: 'On tour!',
  },
  auth: {
    accountRemoved: 'Account removed successfully.',
    alertVerificationSent: 'Verification email sent. Check your inbox.',
    alertUseAnother: (method: string) =>
      `Your email is linked to another login method, use ${method} to log in to your account.`,
    disabledUser: 'User is temporarily disabled, try again later.',
    errorOccurred: 'An error occurred.',
    facebookLogin: 'Sign in with Facebook',
    forgotPassword: 'Forgot password?',
    googleLogin: 'Sign in with Google',
    appleLogin: 'Sign in with Apple',
    goToProfile: 'Go to profile',
    hello: 'Hello ',
    login: 'Log in',
    loginOrRegister: 'Log in or sign up',
    toKeepTickets: 'Log in or sign up to keep your tickets in one place.',
    logout: 'Log out',
    orSignUp: 'or sign up',
    removeAccount: 'Remove account',
    signUp: 'Sign up to Going.',
    successfulLogin: 'You have successfully logged in.',
    passwordPolicy:
      'Password must contain at least 8 characters, including at least one uppercase and one lowercase letter. It should contain one digit and one special character.',
    resetLinkSent: 'Password reset email sent. Check your inbox.',
    userUpdated: 'User data updated successfully.',
    withoutLogin: 'Continue without logging in',
    wrongPassword: 'Wrong password.',
  },
  basket: {
    courierDelivery: 'Courier delivery:',
    details: 'Details',
    hide: 'Hide',
    pieces: 'pcs',
    pricePerItem: 'Price/pcs:',
    products: 'Products',
    serviceFee: 'Service fee:',
    ticketsNum: 'Quantity:',
    totalPrice: 'Total price:',
    basketButton: {
      basket: 'Your cart',
    },
    basketEmpty: {
      continue: 'Continue shopping',
      empty: 'You have no tickets in your cart.',
      find: 'Get your tickets',
    },
  },
  breadcrumbs: {
    activities: 'activities',
    events: 'events',
    artists: 'artists',
    places: 'places',
    selections: 'selections',
    ticketsManagement: 'manage tickets',
    yourPass: 'my pass',
    ticketEdit: 'my ticket',
  },
  buttons: {
    back: 'Back',
    begin: 'Begin',
    cancel: 'Cancel',
    confirm: 'Confirm',
    done: 'Done',
    download: 'Download',
    goBack: 'Go back',
    next: 'Next',
    save: 'Subscribe',
    close: 'Zamknij',
    ok: 'Ok',
    remove: 'REMOVE',
    add: 'ADD',
    buyTicket: 'Buy ticket',
    join: 'Join',
    disabled: 'Not available',
    details: 'Details',
    share: 'Share',
    copy: 'Copy',
    tickets: 'Tickets',
  },
  buy: {
    agreements: {
      acceptAgreements: 'Accept necessary fields',
      acceptAgreementsDetailed: 'Accept necessary fields and provide ticket details',
      additionalData: '(requires additional data)',
      agreementsAndRegulations: 'Consents and regulations',
      fillInData: "Fill with buyer's data",
      iWantNewsletter: "I'd like to receive Going. Mustsee newsletter.",
      newsletterAgreement:
        'By subscribing to the Going Application Newsletter I consent to processing of provided personal data by Going Spółka z ograniczoną odpowiedzialnością with its registered seat in Warsaw, ul. Jana Szymczak 3/36, 01 - 227 Warsaw, for the purpose of receiving commercial information, according to the art. 9 of the Act of June 9, 2017 on the provision of electronic services (Dz. U. from 2017, pos. 1219).',
      requiredFields:
        '*to successfully place an order, it is necessary to select the fields marked with an asterisk',
      requiredFieldsAdditional:
        'For tickets that do not require unique data, the information entered in section 3 will be used.',
      selectConsents: 'Select the consents for this event',
    },
    clientData: {
      didYouMean: 'Did you mean: ',
      discountAdded: 'Added a discount.',
      discountRemoved: 'Discount removed.',
      discountCode: 'Discount code',
      discountCodeTooltip: 'Discount code generated as part of promotional campaigns',
      discountCodeTooltipSaltos:
        'Discount codes for partner cards can be found in the graphic above',
      discountCodeInputEmpty: 'Discount code input cannot be empty',
      discountUnavailableForBasketSales:
        '. NOTE: Discount codes are temporarily unavailable for tickets added to the cart. To use a discount code, remove tickets from your cart and make a purchase directly on the event page in Going.',
      discountUnavailableForBasketSalesEmpik:
        '. NOTE: Discount codes are temporarily unavailable for tickets added to the cart. To use a discount code, remove tickets from your cart and make a purchase directly on the event page in Empik Bilety',
      eMail: 'E-mail address*',
      fillFormWithLastData: 'Complete with data',
      firstName: 'Name*',
      getDataFrom: 'Get your e-mail from:',
      getLastData: 'Do you want to complete the form with the last given data?',
      iHaveDiscount: "I've got discount code",
      iHaveDiscountGoing: 'I have a discount code',
      iHavePass: 'I Have Pass',
      iHavePrepaid: 'I have a gift card',
      iHavePrepaidGoing: 'I have a Going gift card.',
      iHavePrepaidEmpik: 'I have a gift card.',
      id: '. These data may be verified upon entry to the event by the concert organizer or its representatives.',
      lastName: 'Last name*',
      namedTicket: 'Provide',
      pass: 'Pass number',
      phone: 'Phone number',
      prepaidCard: 'Enter the gift card code.',
      prepaidCardGoing: 'Enter the Going gift card code.',
      prepaidCardEmpik: 'Enter your gift card code',
      prepaidCardInputEmpty: 'Input for a gift card or voucher cannot be empty.',
      prepaidTooltip:
        'Going voucher. (received as part of the ticket return or complaint) Going Gift Card. (purchased by Empik.com)',
      prepaidTooltipEmpik:
        'Voucher (received as part of a ticket refund or complaint) or Gift Card (purchased via Empik.com)',
      provideData: 'Provide your data',
      realData: 'real personal data',
      validEmail: 'Make sure the e-mail address is correct',
      willSendTickets: 'Purchased tickets will be sent to the address provided.',
      checkDiscount: 'Check the discount',
      calculatePrepaid: 'Redeem the code',
      requiredFields:
        '*to effectively place an order, it is necessary to fill out the fields marked with an asterisk',
    },
    empikCardNumber: {
      buyNow: 'Buy now',
      cardDescription:
        'If you are an Empik Premium Customer, enter your Customer code assigned to the e-mail address provided by you. The Customer Code can be found in the Empik application in the Premium tab. Find out more ',
      empikCardNumber: 'Premium user number',
      here: 'here',
      premiumUser: 'Empik Premium user',
      premiumUserData: 'Empik Premium user data',
    },
    formLayout: {
      buttonAdd: 'Add to cart',
      buttonPay: 'Go to payment',
      buyAndPay: 'Buy and pay',
      formInvalid: "Something's wrong. Correct the details in the form and go to the city!",
      summary: 'Summary',
    },
    happening: {
      selectNumberOfPlayers: 'Choose the number of players',
      selectDateAndTime: 'Choose a date and time',
      date: 'Date',
      time: 'Time',
      selectSpace: 'Choose a space',
      selectTicketType: 'Select the type of ticket',
      relatedProducts: 'Related products',
      upsell: 'Add an extra time slot',
      book: 'Book it',
      checkDiscount: 'Check discount',
      enterDiscountCode: 'Enter the discount code',
      noMoreSlots: 'Unfortunately, reservations cannot be made on the selected date.',
      selectTime: 'Choose the hour',
      availability: 'Availability',
      buttonAdd: 'Add to cart',
    },
    insurance: {
      accidentInsurance:
        'Are you interested in insuring your ticket in case of inability to participate in the event, e.g. due to personal injury as a result of accidental accident, temporary inability to work, road collision, or serious illness?',
      birthdayPlaceholder: 'YYYY-MM-DD',
      choose: 'I would like to insure the ticket(s).',
      consumerRights: 'Information obligations resulting from the Act on consumer rights',
      iAmAdult: 'Are you over 18 years?',
      iLiveInPoland: 'Do you live permanently in Poland?',
      insuranceBirthDateHelperText: 'Please enter your date of birth.',
      insuranceHeader: 'Ticket insurance',
      insuranceProductInfo: 'Insurance Product Information (PID)',
      insuranceNotAvailableforBasket:
        'Insurance is temporarily unavailable for tickets added to the cart. To buy insurance, remove tickets from your cart and make a purchase directly on the event page in Going.',
      insuranceNotAvailableforBasketEmpik:
        'Insurance is temporarily unavailable for tickets added to the cart. To buy insurance, remove tickets from your cart and make a purchase directly on the event page in Empik Bilety.',
      moreInsuranceInfo: 'General insurance Conditions',
      rodo: 'Processing of personal data',
      tooManyTickets:
        'The maximum number of tickets that can be insured is 6. Please remove tickets from the basket so their number does not exceed the limit.',
      invalidInsuranceData: 'Invalid insurance data.',
      invalidInsuranceFormData:
        'See if you can check the boxes with an asterisk. If not, then the insurance coverage is not suitable for you.',
      invalidTicketPrice:
        'Insurance is only available for tickets priced between 5 PLN and 2500 PLN. Remove tickets priced outside this range from your cart to add the insurance.',
      tooltipPart1: 'The insurance covers absence from the event as a result of, for example:',
      tooltipPart2:
        'You can get a refund of the purchase price of the ticket (s) for the event. Details of the insurance can be found in the general terms and conditions of the insurance. We will send the policy by e-mail after the conclusion of the insurance contract. The insurer is UNIQA.',
      tooltipLi1: 'accident or road collision',
      tooltipLi2: 'delay of the means of transport',
      tooltipLi3: 'you are seriously ill',
      tooltipLi4: 'bone fractures',
      tooltipLi5: 'body trauma as a result of an unexpected accident',
      tooltipLi6: 'your child becomes ill',
      tryAgain: 'Try again',
    },
    invoice: {
      address: 'Address',
      city: 'City',
      iWantInvoice: 'I want to get an invoice',
      legalA:
        'In accordance with the law changes from January 1st 2020, you will be able to receive a VAT invoice only when you select the option "Receive an invoice" and provide the necessary data.',
      legalB:
        ' Legal note: art. 106b ust. 5–7, art. 109a ustawy z 11 marca 2004 r. o podatku od towarów i usług j.t. Dz.U. z 2018 r. poz. 2174, ost.zm. Dz.U. z 2019 r. poz. 2200 oraz art. 15 ustawy z 4 lipca 2019 r. o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw Dz.U. z 2019 r. poz. 1520 ',
      less: '[less]',
      more: '[more]',
      name: 'Company name or first and last name',
      nip: 'Tax Identification Number',
      zip: 'ZIP code',
      country: 'Country',
    },
    paymentMethod: {
      blik: 'BLIK',
      card: 'Card',
      choosePaymentMethod: 'Choose payment method',
      payu: 'PayU',
      mBank: 'mBank',
      twisto: 'Twisto',
      twistoDescription: 'Buy now, pay in 30 days',
      apple: 'Apple Pay',
      google: 'Google Pay',
      emptyPaymentMethods:
        'No payment method. Some of the selected tickets may have forced payment methods.',
      confirmCardData: 'Confirm card data',
      cardDataConfirmed: 'Card data confirmed successfully!',
      cardDataError: 'Card data confirmation failed. Please try again.',
      paypal: 'PayPal',
      cardBrandError: 'We do not support this type of card.',
      ecard: 'BLIK, card, transfer',
    },
    pickUpWay: {
      courierDelivery: 'Courier delivery ',
      pickUp: 'Decide how you want to get your tickets',
      pickUpProduct: 'Provide data for product shipments',
      rightAway: 'You will get your tickets immediately',
      sendAsEmail: 'E-ticket (e-mail)',
      tenDaysDispatch: 'Shipping within 10 days',
    },
    pools: {
      additionalData: 'Additional data',
      dependency:
        'Enter the ticket or pass code authorizing you to purchase from the selected pool.',
      dependencyPlaceHolder: 'Ticket code or pass code',
      details: 'Details',
      noPoolsAvailable: 'No pools available at the moment.',
      sameCurrency:
        'The order includes a ticket in a different currency. Remove it to select this pool.',
      differentType:
        'There is a ticket type in the basket that excludes joint purchase. Delete it to continue',
      title: 'Choose ticket types and quantity',
      choosePlaces: 'Choose places',
      floorPlan: 'View the floor plan',
      numberedSeats: 'Numbered seats',
      unNumberedSeats: 'Unnumbered seats',
    },
    postAddress: {
      cityText: 'City',
      collectorsTicket: "Collector's ticket pattern",
      countryText: 'Country',
      firstNameText: 'First name',
      infoText:
        'By choosing courier delivery, you agree to the transfer of your personal data to the shipping operator.',
      lastNameText: 'Last name',
      localText: 'Flat',
      phoneText: 'Phone number',
      streetNumberText: 'Number',
      streetText: 'Street',
      zipText: 'ZIP code',
    },
    products: {
      title: 'Choose product types and quantity',
      collectorTicket: `The collector's ticket does not entitle you to enter the event. Entrance possible only with an electronic ticket.`,
      ticket: 'Ticket',
      collectorTicketData: `Provide the data to appear on the collector's ticket.`,
    },
    summary: {
      additionalProducts: 'Additional products:',
      amount: 'Quantity:',
      courierDelivery: 'Courier delivery:',
      currency: ' zł',
      defaultPriceType: 'Normal ticket',
      empty: 'no selected activity',
      finalPrice: 'Total:',
      formInvalid: 'Unfortunately, the form is not complete. Please fill in the missing fields.',
      giftWrapper: 'Gift wrap:',
      iHaveCoupon: 'I have a coupon',
      insurance: 'Insurance:',
      insuranceWithCurrentEventTickets: 'Insurance (basket + active event):',
      noData: 'No data',
      numberOfPlayers: 'Number of players:',
      payment: 'payment:',
      pricePerItem: 'Price for pc.:',
      serviceFee: 'Service fee:',
      serviceFeeInformation:
        'Service fee covers the costs of the transaction fee, ticket shipping automation, after-sales service and handling any individual notifications and complaints. It is charged together with the base rate when purchasing a ticket.',
      shipment: 'Delivery:',
      space: 'Chosen space:',
      ticketTransferFee: 'Ticket transfer fee:',
      ticketsAmount: 'Quantity:',
      ticketsPrice: 'Tickets price:',
      time: 'Time:',
      totalPrice: 'Total price:',
      yourBasket: 'Your basket',
      yourReservation: 'Your reservation',
      extendedSlotPrice: 'Normal price',
      extendedSlotDiscount: 'Amount of the discount',
      upsell: 'Additional time',
      differentType:
        'There is a ticket type in the basket that excludes joint purchase. Delete it to continue',
      differentPartner:
        'There is a ticket type in the basket that excludes joint purchase. Delete it to continue',
    },
    term: {
      and: 'and',
      iAccept: 'I accept',
      iAcceptTerms: 'I accept terms',
      privacyPolicy: (appName: string) => `${appName} privacy policy`,
      read: '[read]',
      regulations: (appName: string) => `${appName} terms and conditions`,
    },
    extendedSlots: {
      delete: 'Remove',
      info: 'Your game has been extended! It will last from ',
    },
    multipleSlot: {
      extendGame: 'Extend your game!',
      description:
        'Give yourself more time! Choose how much you want to extend the game or skip this step.',
      decline: "I don't want to extend",
      extend: 'Extend game',
      min: 'min',
      cheaper: 'cheaper',
    },
    timeSlots: {
      rememberText: 'Remember to arrive to the venue at least 20 minutes before time!',
    },
  },
  cookieBar: {
    title: 'This website uses cookies.',
    readMore: 'Learn more',
  },
  eventBoxes: {
    summary: {
      afterMidnight: '(after midnight)',
    },
  },
  dates: {
    locale: Languages.English,
    today: 'Today',
    tomorrow: 'Tomorrow',
  },
  datesFilter: {
    clear: 'Clear',
    date: 'Date',
    filterName: 'date',
    selectDate: 'select a date or range',
    addDate: 'add a date to select a range',
    show: 'Show results',
    thisMonth: 'month',
    thisWeek: 'week',
    today: 'today',
    tomorrow: 'tomorrow',
    weekend: 'weekend',
  },
  eventEmbed: {
    check: 'Check',
    ticketsFrom: 'Tickets from',
  },
  forms: {
    labels: {
      [FormFields.FIRSTNAME]: 'first name',
      [FormFields.LASTNAME]: 'last name',
      [FormFields.EMAIL]: 'email',
      [FormFields.CODE]: 'ticket code',
      [FormFields.BANKACCOUNTNUMBER]: 'Bank account number',
      [FormFields.PASSWORD]: 'password',
      [FormFields.PHONE]: 'phone number',
      [FormFields.OLDPASSWORD]: 'old password',
      [FormFields.REPEATPASSWORD]: 'confirm password',
      [FormFields.TERMS]: {
        iAccept: 'I accept ',
        termsLink: 'terms and conditions',
        and: ' and ',
        privacyPolicyLink: 'privacy policy',
      },
      [FormFields.NEWSLETTER]: {
        header: "I'd like to receive the Going. Mustsee newsletter.",
        agreement:
          'By subscribing to the Going Application Newsletter I consent to processing of provided personal data by Going Spółka z ograniczoną odpowiedzialnością with its registered seat in Warsaw, ul. Jana Szymczak 3/36, 01 - 227 Warsaw, for the purpose of receiving commercial information, according to the art. 9 of the Act of June 9, 2017 on the provision of electronic services (Dz. U. from 2017, pos. 1219).',
      },
    },
    validation: {
      [FormFields.FIRSTNAME]: { required: 'Enter the first name' },
      [FormFields.LASTNAME]: { required: 'Enter the last name' },
      [FormFields.EMAIL]: { required: 'Enter the email', correct: 'Enter a valid email' },
      [FormFields.QUANTITY]: {
        required: 'Select quantity',
        minMsg: (min: number) => `Select at least ${min}`,
      },
      [FormFields.CODE]: { required: 'Enter ticket code' },
      [FormFields.BANKACCOUNTNUMBER]: { required: 'Bank account number is required' },
      termField: { required: 'You must accept this term' },
      [FormFields.PASSWORD]: {
        required: 'Enter the password',
        correct: 'Enter a valid password',
        minMsg: (min: number) => `Enter at least ${min} characters`,
      },
      [FormFields.REPEATPASSWORD]: {
        required: 'Confirm the password',
        correct: 'Passwords do not match',
      },
      [FormFields.TERMS]: { required: 'You must accept terms and conditions' },
      [FormFields.NEWSLETTER]: {
        required: 'You must accept the newsletter',
      },
      [FormFields.PHONE]: {
        required: 'Enter the phone number',
        correct: 'Enter a valid phone number',
      },
    },
  },
  locationFilter: {
    filterTitle: 'city',
  },
  maintenance: {
    featureUnavailable: 'Feature is temporarily unavailable.',
    goToSearch: 'Go to search page',
  },
  meta: {
    buyTicket: 'BUY TICKET',
    concertTickets: 'Concert tickets',
    event: 'Event',
    eventInfo: 'Tickets and info about the event:',
    events: 'Events',
    loading: 'Loading...',
    metaDesc:
      'Going. Your guide to the world of music and culture. Find the most interesting events in the area and buy tickets today!',
    place: 'Venue',
    selection: 'Selection',
    ticketsFor: 'Tickets for',
    ticketsForEvents: 'Tickets for events',
  },
  search: {
    searchbarPlaceholder: 'What are you looking for?',
    found: 'Found:',
    noResults: 'No results found.',
    items: {
      zero: 'results',
      one: 'result',
      two: 'results',
      few: 'results',
      many: 'results',
      other: 'results',
    },
  },
  searchCard: {
    [SearchTypes.TAG]: 'tag',
    [SearchTypes.PLACE]: 'venue',
    [SearchTypes.ARTIST]: 'artist',
    [SearchTypes.RUNDATE]: 'event',
    [SearchTypes.EVENT]: 'tour',
    [SearchTypes.ACTIVITY]: 'activity',
    onSale: 'Tickets available!',
  },
  searchTypesFilter: {
    all: 'Show all',
    [SearchTypes.TAG]: 'tags',
    [SearchTypes.PLACE]: 'venues',
    [SearchTypes.ARTIST]: 'artists',
    [SearchTypes.RUNDATE]: 'events',
    [SearchTypes.EVENT]: 'tours',
    [SearchTypes.ACTIVITY]: 'activities',
    filterTitle: 'type',
  },
  showMore: {
    less: 'less',
    more: 'more',
  },
  entryListL: {
    addBuyerStep: {
      title: 'Do you want to add the person who made the purchase to the ticket?',
    },
    addNewPeopleToTicketStep: {
      addNewPerson: 'Add another person',
      checkData: 'Check that the data is correct',
      confirm: 'I confirm',
      title: 'You need more to complete the process',
    },
    addPeopleToTicketStep: {
      noData: 'No related entries',
      title: 'Select the people you want to add to the ticket',
    },
    back: 'Back',
    entryTerm: {
      confirm: 'Confirm',
      sendEmail: 'Send email',
    },
    haveTicketCodeStep: {
      haveTicketCode: 'Do you have a ticket code?',
      title: 'Fill in the additional data',
    },
    next: 'Next',
    no: 'No',
    numberOfPeopleStep: {
      inputHelperText:
        'Enter how many people are going to the event as part of the purchased ticket',
      inputText: 'Number of people on the ticket',
    },
    registerNewPerson: {
      title: 'Registration',
    },
    thanksStep: {
      back: 'Back to start',
      code: 'Your code binding is:',
      thanks: 'Thank you!',
    },
    ticketStep: {
      enterTicketCode: 'Enter the ticket code to add people to it',
      ticketCode: 'Ticket code',
      ticketCodeHelper: 'Enter the ticket code received by e-mail',
    },
    title: 'Entry list',
    yes: 'Yes',
  },
  errors: {
    authIdIsRequiredValidator: 'Identifier is required',
    cardActiveText: 'Mój Empik card matched, premium benefits activated.',
    checkingCardErrorValidator: 'Card validation failed. Try again later.',
    checkingCardInactiveValidator: 'Mój Empik card matched, but Premium service it not active.',
    checkingCardNomatchValidator:
      'Provided e-mail address does not match Mój Empik card number or the card number is invalid.',
    checkingCardValidator: 'Checking card number...',
    checkingThisRegulationsIsRequiredValidator: 'Checking this box is required',
    dependencyCodeIsRequired: 'The ticket / passcode is required',
    discountCodeIsRequired: 'The discount code is required.',
    emailAddressFieldIsRequiredValidator: 'Fill the e-mail address box',
    emailAddressIsRequiredValidator: 'E-mail address is required',
    firstNameIsRequiredValidator: 'First name is required.',
    incorrectData: 'Incorrect format',
    incorrectEmpikCardFormatValidator: 'Entered card number is not in the correct format',
    incorrectNip: 'TIN must contain digits only',
    incorrectPhoneNumber: 'Incorrect phone number',
    incorrectPostCodeValidator: 'Incorrect ZIP code',
    lastNameIsRequiredValidator: 'Last name is required',
    paymentMethodIsRequired: 'Select payment method',
    countryIsRequiredValidator: 'Country is required',
    providedEmailAddressIsIncorrectValidator: 'Entered e-mail address is incorrect',
    providedIdIsIncorrectValidator: 'Entered identifier is incorrect',
    sorry: `We're Sorry`,
    thisFieldIsRequiredValidator: 'Fill this box',
    wrongBirthDate: 'The birth date entered is incorrect or a wrong format was used',
  },
  errorModals: {
    title: 'Correct the form according to the information above.',
    helper:
      "If this doesn't help, you can also remove the tickets causing issues and proceed to payment by clicking OK.",
    checkInternet:
      'An error occurred while loading the page. Please make sure you have internet access and',
    wentWrong: 'Oops... Something went wrong',
    refreshPage: 'refresh the page',
    sendCode: 'If the error persists, please send us a message with this code',
    errorCode: '404 - Page Not Found',
    notFound: 'The page at the specified address does not exist.',
    question: 'Are you sure you are looking for ',
  },
  event: {
    additionalDescriptionTitle: 'organizer information',
    andOthers: 'and others',
    anotherDateText: 'See other dates',
    articles: 'articles',
    artistBio: 'artist bio',
    buyTicket: 'Buy ticket',
    choose: 'CHOOSE',
    currency: ' zł',
    details: 'Types of tickets',
    disabledTooltip: 'Tickets currently unavailable at ',
    eventStart: 'event start',
    forFansOf: 'similar artists',
    from: 'from',
    gallery: 'gallery',
    gatesOpening: 'gates opening',
    hour: 'hour',
    noDescription: 'no description',
    noInfoAboutPrice: 'No information on the ticket price',
    otherDates: 'other dates',
    place: 'place',
    placeLink: 'go to the site of this place',
    readMore: 'Read more',
    participatingArtists: 'performing',
    rundates: 'event dates',
    samePlaceEventsTitle: 'other events at this place',
    serviceFeeCaption: (fee: string) => `The price includes a ${fee} service fee.`,
    serviceFeeInformationBox:
      'A service fee is already included in the ticket price, its amount can be checked directly in the basket. More information in the Terms and Conditions.',
    similarEventsTitle: 'similar events',
    tickets: 'tickets',
    timetable: 'Event plan',
    goingSeller:
      'Going. is the ticket seller and is not responsible for the proper preparation and conduct of the event.',
  },
  favorites: {
    stayFresh: 'Stay up to date!',
    [FavoritesTypes.Artists]: 'follow artists',
    [FavoritesTypes.Places]: 'follow places',
    [FavoritesTypes.Tags]: 'follow tags',
    termHeader: 'In order to follow artists, places and tags, the following consent is required.',
    noFavorites: 'Select at least one element.',
    labels: {
      [FavoritesTypes.Artists]: 'Artists',
      [FavoritesTypes.Rundates]: 'Events',
      [FavoritesTypes.Places]: 'Places',
      [FavoritesTypes.Selection]: 'Selections',
      [FavoritesTypes.Tags]: 'Tags',
    },
    addedToFavorites: 'Added to favorites',
    removedFromFavorites: 'Removed from favorites',
    logInEvents: 'Log in to add to calendar',
    logIn: 'Log in to add to favorites',
  },
  following: {
    unfollow: 'Unfollow',
    selectionHeader: 'Follow selection',
    artistHeader: 'Follow artist',
    artistPrompt: 'Enter your email address to receive information about this artist. ',
    term: 'I want to receive marketing information about the Going. application and Going. services to the email address I have provided from Going. and the Going. company.',
    getUpdates: 'Receive notifications about important changes, new events and other news.',
    salesHeader: 'Notify me when sales start',
    salesPrompt:
      'Enter your e-mail address to receive information about the start of sales for this event.',
    ticketsHeader: 'Notify me about tickets',
    ticketsPrompt:
      'Tickets sold out. Enter your e-mail address to be notified when tickets go on sale again.',
    placeHeader: 'Watch place',
    placePrompt: 'Enter your e-mail address to receive information about events in this place.',
    rundateHeader: 'Add to calendar',
    rundateRemove: 'Remove from calendar',
    rundatePrompt: 'Enter your email address to follow this event and add it to your calendar.',
    yourEmail: 'Your e-mail address',
    success: 'Success!',
    successDownload: 'Download the Going. app, to have everything at hand and always up to date.',
    successButtonYes: 'Yes please',
    successButtonNope: 'Not right now',
    failure: 'Something went wrong, please try again.',
    subscribeToFollow:
      'Opt-in to receive email notifications about artists, events and places you follow',
  },
  formik: {
    checkbox: {
      choose: 'Choose',
      chosen: 'Chosen',
    },
  },
  formio: {
    langButton: 'Zmień na język polski',
    title: 'Contact form',
  },
  happening: {
    priceTypes: {
      choose: 'Choose',
      chosen: 'Chosen',
      defaultTicket: 'Normal ticket',
    },
    space: {
      unavailableTicketTypes:
        'This space is unavailable because you chose following ticket types: ',
    },
    upsell: {
      add: 'Add',
      added: 'Added',
      clickAgainToResign: 'Click again to resign',
      getAdditionalSlot: 'Add an additional slot',
      upsellDuration: 'The reservation will be extended ',
      minutes: 'minutes',
    },
  },
  landing: {
    faqSection: 'Faq',
    partners: 'Partners',
    tickets: 'Tickets',
  },
  menu: {
    comingSoon: 'Coming soon',
  },
  modals: {
    ActionMessage: {
      close: 'Close',
      no: 'No',
      yes: 'Yes',
    },
  },
  notifications: {
    linkCopied: 'Link copied to clipboard',
    goToInbox: 'Go to inbox',
    genericError: 'Something went wrong. Please try again later.',
    mustLogin: 'You must be logged in to do this.',
  },
  passwordReset: {
    passwordResetForm: {
      confirmNewPass: 'Confirm the new password',
      emailAddress: 'Email address',
      emailAddressBoundToAccount: 'Email address bound to the account',
      incorrectEmail: 'Your e-mail is incorrect',
      newPass: 'Enter the new password',
      pageTitle: 'Reset the password',
      requiredField: 'Please fill this field',
      submitButton: 'RESET THE PASSWORD',
      successMessage: 'We did it! Your password has been changed.',
    },
  },
  payment: {
    paymentFail: {
      contactWithUs:
        'Contact us and let us know what happened, describing the exact situation in which the error occurred. We will check what is going on as soon as possible.',
      header: 'Something went wrong...',
      report: 'Report a problem',
      title: 'Error during transaction',
    },
    paymentSuccess: {
      altText: 'Going app.',
      altText2: 'Download the app',
      beUpToDate:
        'Stay up to date with the best events, compose your event calendar and keep tickets always at hand in the app.',
      confirm: 'Confirmation of the transaction',
      currency: ' zł',
      discoverApp: 'Discover the Going. app',
      discoverApp2: 'discover the going. app',
      downloadApp: 'Download the Going. app',
      doYouLikeGoing: "Do you like Going.? Review us on Google and let us know how we're doing.",
      enterNumber:
        'Enter the number to which you want to receive an SMS with the link to download the Going. application.',
      error: 'Error',
      getMore: 'Get even more with the Going app.!',
      getTicketByEmail:
        'You will get the tickets by e-mail within 24 hours, but we usually bust faster. You can also find tickets in our app.',
      getTicketByEmailEmpik:
        'You will get the tickets by e-mail within 24 hours, but usually we work faster. You can also find tickets in the Going. app',
      header: 'Thanks for placing the order!',
      helperText: 'The phone number is incorrect',
      importantInfo: 'Important info!',
      informationWillBeSend:
        'An invitation to take over the tickets will be sent to the address indicated on the form.',
      iWantToReviewNow: 'I want to review now',
      noMaybeLater: 'No, maybe later',
      openApp: 'open app',
      orDownload: 'or download directly from your store',
      place: 'Place:',
      prefix: '+48',
      reviewUs: 'Review us!',
      reviewUsInGoogle: 'Review us in Google',
      send: 'send',
      somethingWentWrong: 'Something went wrong',
      successMessage: 'SMS sent!',
      summary: 'Summary:',
      sugestNotToPrint:
        'Remind the person who receives the ticket that they do not have to print the ticket. They can show it by using their phone.',
      thanks: 'Thank you.',
      thanksForReceiving: 'Thank you for ticket reception.',
      tickets: 'Number of tickets:',
      transfer: 'The ticket transfer process has started.',
      waiting: 'Now we are waiting for the confirmation of the transaction...',
      yourBank:
        'If your bank does not confirm the transaction within 10 minutes, the order may be canceled.',
      yourOrder: 'Your order',
    },
  },
  pluralRules: {
    ticket: {
      zero: 'tickets',
      one: 'ticket',
      two: 'tickets',
      few: 'tickets',
      many: 'tickets',
      other: 'tickets',
    },
  },
  place: {
    check: 'Check',
    checkEvents: 'Check events',
    location: 'Location',
    showMore: 'Show more',
    noEvents: 'No results...',
    header: 'events in this place',
    map: 'map location',
    info: 'basic info',
  },
  price: {
    from: 'from',
  },
  promotionBanner: {
    altText: 'about aplication',
  },
  searchbar: {
    placeholder: 'What are you looking for?',
  },
  sendMailSuccess: {
    title: 'Tickets are on their way!',
    description: 'We have sent the tickets to the e-mail address provided in the order.',
    button: 'Go to the homepage',
  },
  settings: {
    account: 'Account',
    editData: 'Edit details',
    editPassword: 'Change password',
    communication: 'Communication',
    letUsWrite: 'Let us write you a letter sometimes',
    newsletter: 'Newsletter',
    misc: 'Miscellaneous',
    language: 'Language',
    deleteAccount: 'Delete account',
  },
  share: {
    facebook: 'Share on Facebook',
    twitter: 'Share on Twitter',
    whatsapp: 'Share on WhatsApp',
    telegram: 'Share on Telegram',
    vk: 'Share on VK',
    email: 'Share by e-mail',
    copyLink: 'Copy link',
  },
  similarPlaces: {
    header: 'Similar places',
  },
  ticketManagement: {
    temporarilyUnavailable: {
      contactUs: 'please contact us.',
      ifYouHaveAnyQuestions: 'If you have any questions,',
      formAvailableUnder: 'The contact form is available under:',
      wereSorry: 'We are sorry, the service is temporarily disabled. If you have any questions,',
    },
    ticketForm: {
      ticketDataTitle: 'Enter your ticket data',
      email: 'Enter the e-mail used to purchase the ticket',
    },
    myTicket: {
      thanks: 'Thank You!',
      noTicketTitle: 'Ticket not found',
      noTicketDescription:
        'Please check if you have entered the correct email address and ticket code. If the problem persists, contact us using the form in the "Help" tab.',
      steps: ['Start', 'Confirm', 'Done'],
      actionsHeader: 'Ticket Management',
      downloadHeader: 'Download Ticket',
      downloadTicket: 'Your Ticket (.PDF)',
      switchTicket: 'Go to another ticket purchased using this e-mail',
      seller: 'Seller',
      festSeller:
        'FEST FESTIVAL SP. Z O.O with its registered office in Warsaw, 00-375, 38/1 Smolna Street, entered into the National Court Register kept by the District Court for the Capital City of Warsaw in Warsaw, XII Economic Department of the National Court Register under the KRS number 0000766893, with Tax Identification Number (NIP) 5252777162 and National Business Registry Number (REGON) 382330543.',
      goingSeller:
        'Going sp. z o.o. with its registered office in Warsaw, entered into the National Court Register under the KRS number 0000588000; registered by the District Court for the Capital City of Warsaw in Warsaw, XII Economic Department of the National Court Register; share capital: 21,900.00 PLN; Tax Identification Number (NIP): 5272751272; National Business Registry Number (REGON): 363058596',
      contactOrganizer: 'To request a ticket refund, please contact the organizer: ',
      actions: {
        [TicketActions.CANCEL_TRANSFER]: {
          name: 'Cancel Transfer',
          prompt: 'Are you sure you want to cancel the ticket transfer?',
          description:
            'You can cancel the ticket transfer if it has not been approved by the recipient yet.',
          success:
            'The ticket transfer has been canceled. Your ticket should be returned to you within an hour.',
          loading: 'Canceling ticket transfer...',
        },
        [TicketActions.CHANGE_TIME]: {
          name: 'Change Time',
          prompt: 'Are you sure you want to change the time?',
          description: 'You can change the entry time on the ticket.',
          success: 'Your request has been accepted. The ticket has been changed.',
          loading: 'Changing time...',
        },
        [TicketActions.HISTORY]: {
          name: 'Ticket history',
          prompt: 'Do you want to download the ticket history?',
          description: "Get a history of your ticket and it's current status.",
          success: 'The ticket history has been downloaded.',
          loading: 'Downloading ticket history...',
        },
        [TicketActions.RETURN]: {
          name: 'Return Ticket',
          agency: {
            beforeSettlement: {
              name: 'Refund the ticket and service fee',
              description: 'You can return the purchased ticket and service fee.',
            },
            afterSettlement: {
              name: 'Refund a service fee. Please contact the organizer for a refund of the ticket price ',
              description: 'You can return the service fee.',
            },
          },
          prompt: 'Are you sure you want to return the ticket?',
          description: 'You can return the purchased ticket.',
          success:
            'Your request has been registered. Further steps will be communicated to you by email.',
          loading: 'Returning ticket...',
        },
        [TicketActions.TRANSFER]: {
          name: 'Transfer Ticket',
          prompt: 'Are you sure you want to transfer the ticket to another person?',
          description:
            'Here you can transfer your ticket! Provide the details of the person you want to transfer the ticket to and select the number of tickets to transfer. The recipient will receive a message confirming the ticket transfer, and they will need to pay the ticket price and the transfer fee. You will then receive a confirmation message with the refund for the ticket, minus the handling fee. The amount of the fee is variable and depends on the arrangements made with the event organizer - you will find out the exact amount in the next steps.',
          success:
            'An invitation to collect the ticket has been sent to the provided email address. The person to whom you are transferring the ticket has 72 hours to accept it. After this time, the ticket will be returned to you.',
          loading: 'Transferring ticket...',
        },
      },
      actionErrorTitle: 'Unexpected error occurred',
      actionErrors: {
        [TicketActionErrors.INTERNAL_ERROR]: 'Internal error',
        [TicketActionErrors.INVALID_ACTION]:
          'Invalid action for this ticket. Please try again with a different ticket.',
        [TicketActionErrors.INVALID_EMAIL]:
          'The email address of the ticket recipient is invalid. Please try again.',
        [TicketActionErrors.INVALID_TOKEN]: 'Session expired. Press the button below to restart.',
        [TicketActionErrors.UNKNOWN_ERROR]: 'Unknown error. Please try again.',
      },
    },
    transferTicket: {
      formTitle: 'Recipient Information',
      emailHelper:
        'We will send the transferred tickets to the provided email address. Please ensure it is correct.',
      credentialsHelper:
        'The ticket is personalized. Please provide the accurate information of the person who will receive the ticket, as the staff may ask for an identity document.',
      numToTransfer: 'Number of tickets to transfer: ',
      yourFee: 'You will pay: ',
      recipientFee: 'Recipient will pay: ',
      transferQuantity: 'Number of tickets to transfer:',
    },
    history: {
      transfer: 'Transfer:',
      default: 'unknown',
      [TicketTransferStatus.PENDING]: 'pending',
      [TicketTransferStatus.CANCELED]: 'canceled',
    },
    cancelTransfer: {
      formTitle: 'Select the ticket transfer you wish to cancel.',
    },
    returnTicket: {
      formTitle: 'Select refund option',
      [TicketRefundOptions.TRANSFER]: 'Transfer',
      [TicketRefundOptions.VOUCHER_EMPIK]: 'Voucher Empik',
      [TicketRefundOptions.VOUCHER_GOING]: 'Voucher Going.',
    },
  },
  ticketsManagement: {
    changeDateTitle: 'change date',
    transferTicketTitle: 'transfer ticket',
    receiveTitle: 'receive ticket',
    changeDate: {
      selectDate: 'Select a date',
      selectTime: 'Choose the hour',
    },
    ticketReception: {
      contactForm: 'contact form.',
      getTicket: 'Get the tickets',
      hello: 'Hello, ',
      namedTicket:
        'Remember that tickets are personal. If you notice an error in your data, please report it to us immediately through the ',
      poolSaleUnavailable:
        'Unfortunately passing tickets for events with closed sales is temporarily not available. We kindly ask you to contact our Support team via the contact form (the link is available in the bottom of the page under the "Kontakt" label). Please include the original ticket code in the message. Thank you for your patience.',
      submitButton: 'Pick up your tickets',
      toReception:
        'To get them, accept below agreements and use the PICK UP YOUR TICKETS button on the bottom of the page.',
      transferTicket: 'On this site you can pick up the tickets sent to you for this event.',
    },
  },
  entryList: {
    welcome: {
      title: 'Welcome to ',
      secondTitle: 'Register form',
      buttonText: 'Start',
    },
    starter: {
      title: 'Fill in the form',
      subtitle: 'Do you have a ticket code?',
      yes: 'Yes',
      no: 'No',
    },
    form: {
      title: 'Your data',
      additionalPerson: 'Accompanying person details',
      subtitle: 'Complete the form below and accept the terms and conditions',
      firstName: 'First name*',
      lastName: 'Last name*',
      email: 'E-mail address*',
      birthDay: 'Date of birth (DD/MM/YYYY)*',
      goBack: 'Back',
      goNext: 'Next',
      validEmail: 'Make sure the e-mail address is correct',
      didYouMean: 'Did you mean: ',
    },
    termsOfUse: {
      accept: 'I accept the ',
      statue: 'regulations',
      and: ' and ',
      privacyPolicy: 'privacy policy*',
    },
    dataConfirmation: {
      title: 'Confirm data',
      subtitle: 'Check, if everything is correct',
      goBack: 'Back',
      confirm: 'Confirm',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail address',
      birthDay: 'Date of birth',
    },
    greeting: {
      title: 'Thank you for your registration!',
      subtitle: 'Everything made easy. Have fun!',
      button: 'Go to the homepage',
    },
    parentForm: {
      title: 'Parent consent',
      subtitle:
        "Registration of minors requires the consent of a parent or legal guardian. Enter the person's details and phone number. They will receive an SMS from us with a code confirming consent and identity.",
      goBack: 'Back',
      goNext: 'Next',
      firstName: 'First name of the parent or guardian*',
      lastName: 'Last name of the parent or guardian*',
      phoneNumber: 'Phone number*',
    },
    smsCode: {
      title: 'Parent consent',
      subtitle: 'Entering the SMS code is tantamount to accepting the regulations.',
      goBack: 'Back',
      goNext: 'Next',
      code: 'SMS code*',
      sendAgain: 'Resend the code',
      codeError: 'Invalid code',
    },
    validation: {
      requiredField: 'Field required',
      incorrectEmail: 'Incorrect e-mail',
      invalidDate: 'Invalid date format. The correct format is DD.MM.YYYY',
      privacyPolicy: 'You must accept the terms and conditions of privacy',
      incorrectPhoneNumber: 'Incorrect phone number',
    },
    agreementExist: {
      title: 'The user is already registered',
      subtitle: 'The user with the data provided by you has already been registered.',
      submissionsCompleteTitle: 'Thanks for your registartion',
      submissionsCompleteHelper: 'The entry list is already filled.',
      homepage: 'Go to the homepage',
      goBack: 'Back',
    },
    ticketCode: {
      title: 'Enter ticket code',
      placeholder: 'Ticket code*',
      goBack: 'Back',
      goNext: 'Next',
    },
    submissionsSummary: {
      title: 'Entry list',
      helper: 'List of people assigned to the ticket.',
      goBack: 'Back',
      confirm: 'Confirm',
      submissionExist: 'The person with the given data is already added to the ticket',
    },
    checkSubmission: {
      title: 'E-mail address',
      helper: 'Enter your email address',
      placeholder: 'E-mail address*',
      goBack: 'Back',
      goNext: 'Next',
    },
    selectSubmission: {
      title: 'Participant data',
      helper: 'Select data from the list or enter a new one',
      addNew: 'Add new data',
      goBack: 'Back',
      goNext: 'Next',
      additionalPerson: 'Accompanying person details',
      additionalPersonHelper: 'Choose a companion or add a new one',
    },
  },
  cart: {
    continueBuying: 'Continue shopping',
    toPay: 'to pay:',
    localCurrency: 'zł',
    payText: 'Go to payment',
    pricePerItem: 'Price per item: ',
    totalPrice: 'Total price: ',
    amount: 'Amount:',
    pcs: 'pcs.',
    upsell: 'Upsell',
    upsellTime: 'Time',
    defaultTicket: 'Normal ticket',
  },
  activities: {
    selectEvent: 'Select an activity',
    book: 'Book',
  },
  passManagement: {
    pageTitle: 'Your pass',
    passDataForm: {
      usePassData: 'Enter your pass data',
      placeholder: {
        identity: 'Pass number',
        email: 'E-mail address used to purchase the pass',
      },
      buttonSubmit: 'Check',
    },
    passError: {
      title: 'Pass not found',
      content: 'Check the data and try again',
      button: 'Enter correct data',
    },
    passDetails: {
      remainingEntries: 'Remaining entries',
      expireDate: 'Expiration date',
      isPersonal: {
        individual: 'Individual',
        family: 'Family',
      },
      type: 'Type of pass',
      backButton: 'Enter another pass number',
    },
  },
  followers: {
    follows: 'follows',
  },
  profile: {
    tickets: 'Tickets',
    recommended: 'Recommended',
    allEvents: 'All events',
    news: 'News',
    allNews: 'All news',
    gallery: 'Gallery',
    allPhotos: 'All photos',
    aboutPlace: 'About place',
    readMore: 'Read more',
    location: 'Location',
    setRoute: 'Set a route',
    aboutUs: 'About us',
    newsMenu: 'News',
    verified: 'Verified profile',
  },
  stats: {
    rating: 'Rating',
    opinion: 'opinion',
    events: 'Events',
    visited: 'Visited',
    future: 'future',
    friends: 'Friends',
    related: 'Related',
    profile: 'profile',
    tookPlace: 'Took place',
  },
  extendedPayment: {
    title: 'Your Reservation',
    subtitle: 'Before you click "Pay", please check if everything is correct.',
    clientData: 'Your Information',
    firstName: 'First Name: ',
    lastName: 'Last Name: ',
    emailAddress: 'Email Address: ',
    summary: 'Summary',
    toPay: 'to pay:',
    payNow: 'Pay now',
  },
  returnAccountBank: {
    title: 'Bank account number',
    pageName: 'Refund - Bank account number',

    success: {
      title: 'It worked!',
      description: 'Your account number has been successfully added.',
    },
  },
  sellerModal: {
    title: 'Seller',
    buttonText: 'Sell: ',
    subtitle: 'The ticket seller for this event is ',
    accept: 'Accept',
    agent: (partnerName?: string) =>
      `Going. acts as an intermediary in the ticket sales process by the organizer ${partnerName} and is not responsible for the proper preparation and conduct of the event.`,
    nip: 'NIP: ',
    headquarters: 'with its registered office at: ',
  },
  tooltip: {
    serviceUnavailable: 'Sorry, the service is temporarily unavailable.',
  },
}
