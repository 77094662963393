import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import TimelineBrick from './TimelineBrick.component'
import { ITimelineBrickFromState, ITimelineBrickOwnProps } from './TimelineBrick.types'

const mapStateToProps = (state: _Store.IState): ITimelineBrickFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
  compositionIdentifier: getCompositionIdentifier(state),
})

export default connect<ITimelineBrickFromState, null, ITimelineBrickOwnProps, _Store.IState>(
  mapStateToProps
)(TimelineBrick)
