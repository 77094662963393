import { useSetRecoilState } from 'recoil'

import { Languages } from 'constants/Languages'
import { setLang } from 'state/locale/selectors/setLang'

/**
 * Hook used to change the user language.
 */
export const useSetLang = () => {
  const setLanguage = useSetRecoilState(setLang)
  return {
    handleLanguageChange: (lang: Languages) => () => setLanguage(lang),
    setLanguage,
  }
}
