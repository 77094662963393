import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { getParams } from 'models/internalRouter/selectors'
import { IArticleFull } from 'types/Article'
import { isActionOf } from 'typesafe-actions'

import { articlePageMounted, getArticle } from '../actions'

export const getArticleWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(articlePageMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return [getArticle.request()]
    })
  )
}

export const fetchArticleWhenRequested: _Store.IEpic = (action$, state$, { articleApi }) => {
  return action$.pipe(
    filter$(isActionOf(getArticle.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as RouteParams<Route.staticArticle>

      if (params) {
        return from$(articleApi.getSingleArticle(params.sourceSlug, params.articleSlug)).pipe(
          mergeMap$((data: IArticleFull) => {
            const normalizedArticleKey = articleApi.makeKeyFromParams(data)

            return [
              getArticle.success({
                article: data,
                key: normalizedArticleKey,
              }),
            ]
          }),
          catchError$((error: Error) => of$(getArticle.failure(error)))
        )
      }

      return EMPTY$
    }),
    catchError$((error: Error) => of$(getArticle.failure(error)))
  )
}
