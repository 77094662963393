import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { getEvent } from 'models/event/selectors'
import { getAvailablePaymentMethods } from 'models/pools/selectors'
import { getTheme } from 'models/theme/selectors'
import { setCardCredentials } from 'models/transaction/actions'

import PaymentMethod from './PaymentMethod.component'
import { IPaymentMethodFromDispatch, IPaymentMethodFromState } from './PaymentMethod.types'

const mapStateToProps = (state: _Store.IState): IPaymentMethodFromState => {
  return {
    event: getEvent(state),
    paymentMethodsFromState: getAvailablePaymentMethods(state),
    selectedTheme: getTheme(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPaymentMethodFromDispatch => ({
  setCardCredentials: (cardCredentials) => dispatch(setCardCredentials(cardCredentials)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)
