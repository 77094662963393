/**
 * Function formatting plural nouns in a selected locale.
 *
 * @param { number } count - number in which to draw a noun
 * @param { string } locale - selected language
 * @param {{[key: Intl.LDMLPluralRule]: string}} nouns - plural versions of the noun
 *   e.g. for Polish: {many: 'wyników', one: 'wynik', few: 'wyniki'}
 *   e.g. for english/german: {one: 'file', other: 'files'}
 * @returns {string}
 */
export const formatPluralNouns = (
  count: number,
  locale: string,
  nouns: {
    [key in Intl.LDMLPluralRule]: string
  }
): string => {
  const format = new Intl.PluralRules(locale)
  const quantity = format.select(count)

  return nouns[quantity]
}
