import { ISectionsComponentDataMutable, ISectionsCtaButton } from 'models/pages/types/sections'

export const cta_button = (data: ISectionsComponentDataMutable) => {
  const cta = data as ISectionsCtaButton[]
  const buttonsData = {
    data: cta,
  }

  return buttonsData
}
