const classes = {
  clientData: 'client-data',
  description: 'client-data-description',
  formPanel: 'client-data-form-panel',
  header: 'client-data-header',
  importData: 'client-data-import-data',
  label: 'client-data-label',
  labelHeader: 'client-data-label-header',
  names: 'client-data-names',
  namesLabel: 'client-data-names-label',
  socialIcon: 'client-data-social-icon',
}

export default classes
