import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

import * as braze from '@braze/web-sdk'
import { User, getAuth } from 'firebase/auth'
import useSWR from 'swr'

import { AuthDialog } from 'components/auth/AuthDialog'
import { values } from 'constants/Values'
import { useToggle } from 'misc/hooks/useToggle'
import { UserApi } from 'services/UserApi'
import { useSetLang } from 'state/locale/hooks/useSetLang'

import { BrazeApi } from '../../../services/Api/BrazeApi'
import { NewsletterDialog } from '../../auth/NewsletterDialog'
import { useMaintenance } from '../MaintenanceModeProvider'
import { IUserContext } from './UserContextProvider.types'

const UserContext = createContext<IUserContext>({
  firebaseUser: null,
  goingMetadata: undefined,
  isLoggedIn: false,
  isUserAnonymous: null,
  loading: {
    isLoading: true,
    start: values.noop,
    done: values.noop,
  },
  refreshUser: values.noopPromise,
  isNewsletter: false,
  toggleNewsletter: values.noopPromise,
  dialog: {
    isVisible: false,
    hide: values.noop,
    show: values.noop,
  },
  dialogNewsletter: {
    isVisible: false,
    hide: values.noop,
    show: values.noop,
  },
  userEmail: null,
  signInMethods: [],
  setSignInMethods: values.noop,
  setUserEmail: values.noop,
  resetForm: values.noop,
})

export const useUser = () => useContext(UserContext)

export const UserContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { isMaintenance } = useMaintenance()
  const { setLanguage } = useSetLang()
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
  const notAnonymous = !!(firebaseUser && !firebaseUser.isAnonymous)
  const isAnonymous = !!(firebaseUser && firebaseUser.isAnonymous)
  const [isLoading, setLoading] = useState<boolean>(true)
  const { data, mutate } = useSWR(
    isMaintenance !== null && !isMaintenance && notAnonymous && firebaseUser,
    UserApi.getApiUser,
    {
      refreshInterval: (data) => (data ? 0 : 4000),
    }
  )
  const [isNewsletter, setIsNewsletter] = useState(false)
  const { isVisible, handleHide, handleShow } = useToggle()
  const {
    isVisible: isNewsletterVisible,
    handleHide: handleHideNewsletter,
    handleShow: handleShowNewsletter,
  } = useToggle()
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const [signInMethods, setSignInMethods] = useState<string[]>([])

  const onAuthStateChanged = (firebaseUser: User | null) => {
    setFirebaseUser(firebaseUser)
    setLoading(false)
  }

  const subscribeToNewsletter = async () => {
    const brazeUser = braze.getUser()
    brazeUser?.setEmailNotificationSubscriptionType(
      braze.User.NotificationSubscriptionTypes.SUBSCRIBED
    )
    setIsNewsletter(true)
    handleHideNewsletter()
  }

  const toggleNewsletter = async () => {
    const brazeUser = braze.getUser()
    if (isNewsletter) {
      brazeUser?.setEmailNotificationSubscriptionType(
        braze.User.NotificationSubscriptionTypes.UNSUBSCRIBED
      )
      setIsNewsletter(false)
    } else {
      handleShowNewsletter()
    }
  }

  useEffect(() => {
    if (data?.language) {
      setLanguage(data.language)
      braze.getUser()?.setLanguage(data.language)
    }
  }, [data?.language])

  useEffect(() => {
    const auth = getAuth()
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged)

    return () => subscriber()
  }, [])

  useEffect(() => {
    if (firebaseUser) {
      braze.changeUser(firebaseUser.uid)
      const brazeUser = braze.getUser()
      if (firebaseUser.email && brazeUser) {
        brazeUser.addAlias(firebaseUser.email, 'email')
        brazeUser.setEmail(firebaseUser.email)
      }
    }
  }, [firebaseUser?.uid])

  useEffect(() => {
    if (!!firebaseUser) {
      braze.getUser()?.getUserId((userId) => {
        if (userId) {
          BrazeApi.getBrazeUser(firebaseUser.uid, userId).then((data) => {
            setIsNewsletter(data.isNewsletter)
          })
        }
      })
    }
  }, [firebaseUser?.uid])

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: !!firebaseUser,
        firebaseUser,
        refreshUser: async () => {
          await firebaseUser?.reload()
          await mutate()
        },
        loading: {
          isLoading,
          start: () => setLoading(true),
          done: () => setLoading(false),
        },
        isUserAnonymous: isAnonymous,
        goingMetadata: data,
        isNewsletter,
        toggleNewsletter,
        dialog: {
          isVisible,
          hide: handleHide,
          show: handleShow,
        },
        dialogNewsletter: {
          isVisible: isNewsletterVisible,
          hide: handleHideNewsletter,
          show: handleShowNewsletter,
        },
        userEmail,
        signInMethods,
        setUserEmail,
        setSignInMethods,
        resetForm: () => {
          setUserEmail(null)
          setSignInMethods([])
        },
      }}
    >
      {children}
      <AuthDialog isVisible={isVisible} onClose={handleHide} />
      <NewsletterDialog
        isVisible={isNewsletterVisible}
        onClose={handleHideNewsletter}
        onSubmitCallback={subscribeToNewsletter}
      />
    </UserContext.Provider>
  )
}
