import React from 'react'

import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import config from 'config'
import _L from 'misc/helpers/local'
import empikLogo from 'misc/styles/images/empik-bilety.svg'
import goingLogo from 'misc/styles/images/going.svg'
import * as LOCALS from 'vars/constants'

import styles from './Brand.module.scss'
import { IBrandProps } from './Brand.types'

const LOGO_ALT_TEXT = _L(LOCALS.HELMET__AUTHOR)

const Brand = ({ isSticky, target, module }: IBrandProps) => {
  const containerProps = {
    className: styles.logoContainer,
    itemScope: true,
    itemType: 'http://schema.org/Organization',
  }
  const innerContent = (
    <Link
      className={cn(styles.logoLink, isSticky && styles.sticky)}
      to={routes[Route.index]}
      itemProp={'url'}
      target={target}
    >
      <img
        className={styles.logo}
        src={config.theme.isEmpik ? empikLogo : goingLogo}
        alt={LOGO_ALT_TEXT}
        itemProp={'logo'}
      />
    </Link>
  )
  return module === Route.search ? (
    <h2 {...containerProps}>{innerContent}</h2>
  ) : (
    <h1 {...containerProps}>{innerContent}</h1>
  )
}

export default Brand
