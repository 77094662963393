export const PRODUCTS_MOUNTED = 'products/PRODUCTS_MOUNTED'
export const INSURANCE_MOUNTED = 'products/INSURANCE_MOUNTED'
export const RELOAD_INSURANCE = 'products/RELOAD_INSURANCE'

export const SELECT_PRODUCTS = 'products/SELECT_PRODUCTS'
export const SELECT_PRODUCT = 'products/SELECT_PRODUCT'
export const UPDATE_PRODUCT_DATA = 'products/UPDATE_PRODUCT_DATA'
export const _UPDATE_SELECTED_PRODUCTS = 'Empik/pools/_UPDATE_SELECTED_PRODUCTS'
export const PRODUCT_MOUNTED = 'Empik/products/PRODUCT_MOUNTED'
export const SET_INSURANCE_SUMMARY_DATA = 'Empik/products/SET_INSURANCE_SUMMARY_DATA'

export const _GET_REQUEST = 'products/_GET_REQUEST'
export const _GET_SUCCESS = 'products/_GET_SUCCESS'
export const _GET_FAILURE = 'products/_GET_FAILURE'
export const _RESET_STATE = 'products/_RESET_STATE'

export const _GET_PRODUCTS_POOLS_REQUEST = 'Empik/pools/_GET_PRODUCTS_POOLS_REQUEST'
export const _GET_PRODUCTS_POOLS_SUCCESS = 'Empik/pools/_GET_PRODUCTS_POOLS_SUCCESS'
export const _GET_PRODUCTS_POOLS_FAILURE = 'Empik/pools/_GET_PRODUCTS_POOLS_FAILURE'

export const _GET_SINGLE_PRODUCT_REQUEST = 'Empik/pools/_GET_SINGLE_PRODUCT_REQUEST'
export const _GET_SINGLE_PRODUCT_SUCCESS = 'Empik/pools/_GET_SINGLE_PRODUCT_SUCCESS'
export const _GET_SINGLE_PRODUCT_FAILURE = 'Empik/pools/_GET_SINGLE_PRODUCT_FAILURE'

export const _GET_INSURANCE_PRODUCTS_REQUEST = 'Empik/pools/_GET_INSURANCE_PRODUCTS_REQUEST'
export const _GET_INSURANCE_PRODUCTS_SUCCESS = 'Empik/pools/_GET_INSURANCE_PRODUCTS_SUCCESS'
export const _GET_INSURANCE_PRODUCTS_FAILURE = 'Empik/pools/_GET_INSURANCE_PRODUCTS_FAILURE'
