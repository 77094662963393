import { setInvalidSelection, setValidSelection } from 'models/tickets/actions'
import { IAction, ISeatsData } from 'models/tickets/types'
import { getType } from 'typesafe-actions'

const reducer = (
  state: ISeatsData = {
    isSelectionValid: true,
  },
  action: IAction
): ISeatsData => {
  switch (action.type) {
    case getType(setInvalidSelection):
      return {
        isSelectionValid: false,
      }

    case getType(setValidSelection):
      return {
        isSelectionValid: true,
      }

    default:
      return state
  }
}

export default reducer
