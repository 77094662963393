import React, { MouseEvent } from 'react'

import cn from 'classnames'

import Link from 'components/reusable/DualLink/DualLink.component'
import getUrlWithTransformation from 'misc/helpers/cloudinary/getUrlWithTransformation'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import Analytics from 'services/$analytics'

import styles from './CarouselSlide.module.scss'
import { ICarouselSlideProps } from './CarouselSlide.types'

const CarouselSlide = ({ image, GAclass }: ICarouselSlideProps) => {
  const { isMobile } = useBreakpoints()
  const { squareImage, wideImage } = image
  const transformedUrl = isMobile
    ? squareImage
      ? getUrlWithTransformation([], squareImage)
      : getUrlWithTransformation([], wideImage)
    : getUrlWithTransformation([], wideImage)
  const isLink = !!image.link

  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    isLink
      ? Analytics.pushForHomepageSelectItem(e, image.link, image.title || image.alt || '')
      : undefined

  return (
    <>
      {isLink && (
        <Link
          className={cn(styles.linkContainer, GAclass)}
          to={image.link}
          onClick={handleAddToDataLayerOnClick}
        >
          <div className={styles.carouselSlide}>
            <img
              className={styles.carouselImage}
              draggable="false"
              src={transformedUrl}
              alt={image.alt}
            />
          </div>
        </Link>
      )}
      {!isLink && (
        <div className={styles.carouselSlide}>
          <img
            className={styles.carouselImage}
            draggable="false"
            src={transformedUrl}
            alt={image.alt}
          />
        </div>
      )}
    </>
  )
}

export default CarouselSlide
