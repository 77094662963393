import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isToday,
  isTomorrow,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { formatInTimeZone, format as formatTZ } from 'date-fns-tz'
import { enGB, pl } from 'date-fns/locale'

import { IDay } from 'components/search/DateFilter/DateFilter.types'
import { Languages } from 'constants/Languages'
import { Texts } from 'constants/Texts'
import { capitalizeFirstLetter } from 'misc/helpers/capitalizeFirstLetter'
import Logger from 'services/Logger'
import { IDate, IFormatRundateDatesProps, IGetFriendlyDateProps } from 'types/DateTime'

export class DateTime {
  public static defaultTimeZone = 'Europe/Warsaw'

  /**
   * Function converting date and time object to ISO date string
   * with zero offset (this must be accounted for later).
   *
   * @param {IDate} dateObject - date and time object
   * @param {string} timeZone - (default: 'Europe/Warsaw') time zone
   *
   * @return {string} date-time ISO string
   */
  public static getIsoDateString = (
    dateObject: IDate,
    timeZone: string = DateTime.defaultTimeZone
  ): string => {
    const year = parseInt(dateObject.year, 10)
    const month = parseInt(dateObject.month, 10) - 1
    const day = parseInt(dateObject.day, 10)
    const hour = parseInt(dateObject.hour, 10)
    const minutes = parseInt(dateObject.minutes, 10)

    return formatInTimeZone(
      new Date(year, month, day, hour, minutes),
      timeZone,
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    )
  }

  public static checkIfDatesAreEqual(startDate: IDate | string, endDate: IDate | string) {
    if (
      typeof startDate === 'string' &&
      typeof endDate === 'string' &&
      endDate.toString().split('T')[0] === startDate.toString().split('T')[0]
    ) {
      return true
    }

    return (
      typeof startDate !== 'string' &&
      typeof endDate !== 'string' &&
      startDate.day === endDate.day &&
      startDate.month === endDate.month &&
      startDate.year === endDate.year
    )
  }

  public static compareTwoDates(firstDate: IDate, secondDate: IDate) {
    const isoFirst = this.getIsoDateString(firstDate)
    const isoSecond = this.getIsoDateString(secondDate)

    return new Date(isoFirst) < new Date(isoSecond)
  }

  /**
   * Function translates ISO string date to human friendly format with localization
   *
   * @param {string} isoDate - ISO date string
   * @param {IDateTranslate} dateTranslate - date translation
   * @param isShort - should the date be in 'd.MM.yy' format
   * @param removeWeekDay
   * @param timeZone (default: Europe/Warsaw)
   *
   * @return {string} - human friendly localized date string
   */
  public static getFriendlyDate = ({
    isoDate,
    dateTranslate,
    isShort,
    removeWeekDay,
    timeZone = DateTime.defaultTimeZone,
  }: IGetFriendlyDateProps): string => {
    try {
      const date = new Date(isoDate.split('T')[0])

      if (isToday(date)) return dateTranslate?.today || Texts.TODAY

      if (isTomorrow(date)) return dateTranslate?.tomorrow || Texts.TOMORROW

      return capitalizeFirstLetter(
        formatTZ(date, isShort ? 'd.MM.yy' : removeWeekDay ? 'd MMMM yyyy' : 'EE, d MMMM yyyy', {
          locale: DateTime.getLocale(dateTranslate?.locale),
          timeZone,
        })
      )
    } catch (e) {
      Logger.error('getFriendlyDate', {
        name: 'getFriendlyDate exception',
        message: 'Wrong date format.',
        stack: isoDate,
      })

      return JSON.stringify(isoDate)
    }
  }

  public static formatRundateDates = ({
    startDate,
    endDate,
    dateTranslate,
    timeZone = DateTime.defaultTimeZone,
  }: IFormatRundateDatesProps): string => {
    const getDateString = (date: IDate | string): string =>
      typeof date === 'string' ? date : DateTime.getIsoDateString(date)

    if (!startDate) return ''

    const isoStart = getDateString(startDate)
    const start = DateTime.getFriendlyDate({
      isoDate: isoStart,
      dateTranslate,
      timeZone,
    })

    if (endDate && !this.checkIfDatesAreEqual(startDate, endDate)) {
      const isoEnd = getDateString(endDate)

      return `${formatTZ(new Date(isoStart), 'd.MM.yy', {
        timeZone,
      })} - ${formatTZ(new Date(isoEnd), 'd.MM.yy', { timeZone })}`
    }

    return start
  }

  public static getFriendlyTime = (
    date: string | number | Date | IDate,
    timeZone: string = DateTime.defaultTimeZone
  ): string => {
    if (DateTime.isDateObject(date)) return `${date.hour}:${date.minutes}`

    return formatInTimeZone(date, timeZone, 'HH:mm')
  }

  /**
   * Returns date array of the nearest weekend date range.
   *
   * @return {[Date, Date]}
   */
  public static nearestWeekend = (): [Date, Date] => {
    const today = new Date()
    const dayOfWeek = getDay(today)
    const daysUntilFriday = dayOfWeek <= 4 ? 5 - dayOfWeek : 6 - dayOfWeek
    const daysUntilSunday = dayOfWeek <= 6 ? 7 - dayOfWeek : 0
    const startOfWeekend = addDays(startOfDay(today), daysUntilFriday)
    const endOfWeekend = addDays(endOfDay(today), daysUntilSunday)

    return [startOfWeekend, endOfWeekend]
  }

  /**
   * Returns date array of this week date range.
   *
   * @return {[Date, Date]}
   */
  public static thisWeek = (): [Date, Date] => {
    const today = new Date()
    const firstDay = startOfWeek(today, { weekStartsOn: 1 })
    const lastDay = endOfWeek(today, { weekStartsOn: 1 })

    return [firstDay, lastDay]
  }

  /**
   * Returns date array of current two weeks date range.
   *
   * @return {[Date, Date]}
   */
  public static theseTwoWeeks = (): [Date, Date] => {
    const [firstDay, lastDay] = this.thisWeek()

    return [firstDay, addDays(lastDay, 7)]
  }

  /**
   * Returns date array of current month date range.
   *
   * @return {[Date, Date]}
   */
  public static thisMonth = (): [Date, Date] => {
    const today = new Date()
    const firstDay = startOfMonth(today)
    const lastDay = endOfMonth(today)

    return [firstDay, lastDay]
  }

  /**
   * Splits an array of days into an array of weeks.
   *
   * @param {IDay[]} dates
   * @returns {IDay[][]}
   */
  public static splitIntoWeeks = (dates: IDay[]): IDay[][] => {
    const chunks: IDay[][] = []

    for (let i = 0; i < dates.length; i += 7) chunks.push(dates.slice(i, i + 7))

    return chunks
  }

  /**
   * Returns an array of localized short weekdays names.
   *
   * @returns {string[]}
   */
  public static getWeekDays = (anyDate: Date, lang: Languages): string[] => {
    const weekStartDate = startOfWeek(anyDate, { weekStartsOn: 1 })
    const weekDays: string[] = []
    for (let day = 0; day < 7; day++)
      weekDays.push(
        format(addDays(weekStartDate, day), 'E', {
          locale: DateTime.getLocale(lang),
        })
      )

    return weekDays
  }

  /**
   * Returns localized full month name and year for a given date.
   *
   * @param {Date} date
   * @param {Languages} lang
   * @returns {string}
   */
  public static getMonthName = (date: Date, lang: Languages) =>
    format(date, 'LLLL yyyy', { locale: DateTime.getLocale(lang) })

  /**
   * Converts language code to date-fns locale.
   *
   * @param {Languages} lang
   * @returns {Locale}
   */
  private static getLocale = (lang?: Languages) => (lang === Languages.English ? enGB : pl)

  /**
   * Type-guard function - checks if the date param is of IDate type.
   *
   * @param date
   */
  private static isDateObject = (date: any): date is IDate =>
    typeof date.year === 'string' &&
    typeof date.month === 'string' &&
    typeof date.day === 'string' &&
    typeof date.hour === 'string' &&
    typeof date.minutes === 'string'
}
