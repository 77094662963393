import * as React from 'react'

import qs from 'qs'
import { useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Event } from 'components/Event'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import Helmet from 'components/helmet/Helmet'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { EventApi } from 'services/Api/EventApi'
import { DataMutations } from 'services/DataMutations'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const EventPage = () => {
  const { eventSlug } = useParams<RouteParams<Route.event>>()
  const { data, isLoading } = useSWR([eventSlug], EventApi.getFullEvent, {})
  const { i18n } = useDictionary()

  return (
    <PageWrapper>
      {!!data?.event && <Helmet {...DataMutations.eventHelmetData(data.event, i18n)} />}
      <Breadcrumbs
        base={[
          {
            link:
              routes[Route.search] +
              qs.stringify({ [HitFields.type]: SearchTypes.RUNDATE }, { addQueryPrefix: true }),
            label: i18n.breadcrumbs.events,
          },
        ]}
        current={data?.event.title}
        isLoading={isLoading}
      />
      {isLoading ? (
        <TwoColumnsSkeleton />
      ) : (
        !!data && <Event event={data.event} similarEvents={data.similarEvents} />
      )}
    </PageWrapper>
  )
}
