import React, { FC } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import SectionLead from 'components/Bricks/components/LeadSection'
import ImageFixed from 'components/reusable/ImageFixed'
import ReadMore from 'components/reusable/ReadMore'
import ShortDescription from 'components/reusable/ShortDescription'
import { Tags } from 'components/reusable/Tags'
import Title from 'components/reusable/Title'
import TagsLoading from 'components/reusable/loadings/TagsLoading'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import slugifyString from 'misc/helpers/slugifyString'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useAppState } from 'state/app/hooks/useAppState'

import styles from './WideArticle.module.scss'
import useStyles from './WideArticle.styles'
import { IWideArticleProps } from './WideArticle.types'

const WideArticle: FC<IWideArticleProps> = ({
  articleData,
  color,
  id,
  isLoading,
  sectionDescription,
  sectionTitle,
  bgColor,
  compositionIdentifier,
}) => {
  const { isMobile } = useBreakpoints()
  const { isDarkTheme } = useAppState()
  const showReadMore = articleData.url && !isMobile
  const GAClassName = `${compositionIdentifier}-${id}-${slugifyString(articleData.title)}`

  const isBgDark =
    isColorDark(bgColor) ||
    isColorDark(isDarkTheme && bgColor ? invertIfGrayScaleColor(bgColor) : bgColor)

  const theme = useTheme()
  const muiStyles = useStyles(color, isBgDark)(theme)

  return (
    <>
      <SectionLead
        color={color}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <div className={cn(styles.wideArticle, styles.normal, muiStyles.root)} id={id}>
        <div className={styles.image}>
          <ImageFixed
            src={articleData.imageUrl}
            to={articleData.url}
            alt={articleData.title}
            className={GAClassName}
          />
        </div>
        <div className={styles.details}>
          {!isMobile && (
            <div className={styles.tags}>
              {!isLoading && <Tags tags={articleData.tags} />}
              {isLoading && <TagsLoading />}
            </div>
          )}
          {articleData.title && (
            <Title
              title={articleData.title}
              link={articleData.url}
              isLoading={isLoading}
              customClassName={cn(styles.title, muiStyles.fontColor, GAClassName)}
            />
          )}
          {articleData.date && <h4 className={styles.date}>{articleData.date}</h4>}
          {articleData.description && (
            <ShortDescription
              description={articleData.description}
              isLoading={isLoading}
              customClassName={cn(styles.description, muiStyles.fontColor, muiStyles.secondary)}
            />
          )}
          {isMobile && (
            <div className={styles.tags}>
              {!isLoading && <Tags tags={articleData.tags} />}
              {isLoading && <TagsLoading />}
            </div>
          )}

          {showReadMore && (
            <ReadMore
              link={articleData.url}
              isLoading={isLoading}
              isBgDark={isBgDark}
              className={GAClassName}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default WideArticle
