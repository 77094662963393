import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPremiumReducer, UserCardStatusEnum } from './../types'
import get from './get'

const getUser = createSelector<_Store.IState, IPremiumReducer, UserCardStatusEnum>(
  [get],
  (slice) => {
    return slice.userCardStatus
  }
)

export default getUser
