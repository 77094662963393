import { providers } from 'goingapp-shared'

import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import { IBuyingOnsiteValues } from 'components/buying/Onsite/Onsite.types'
import { IBasket } from 'models/basket/types'

export const BagContext = providers.GoingBasketProvider<
  IBasket,
  IBuyingOnlineFormValues | IBuyingOnsiteValues
>()

export const BagContextConsumer = BagContext.Consumer
export const BagStore = BagContext.Store
export const clearContext = BagContext.clearContext
