const colors = {
  text: {
    primary: '#000000',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  actions: {
    main: '#E0E0E0',
    background: 'rgba(0, 0, 0, 0.08)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledText: 'rgba(0, 0, 0, 0.26)',
  },
  primary: {
    main: '#FF6B00',
    dark: '#CB6A24',
    light: '#FF8E3D',
    background: 'rgba(255, 107, 0, 0.16)',
    border: '#FF6B00',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#000000',
    dark: '#000000',
    light: '#606060',
    background: 'rgba(44, 45, 83, 0.1)',
    border: '#000000',
    contrastText: '#ffffff',
  },
  other: {
    stroke: '#2C2D53',
    divider: '#E0E0E0',
    backdrop: 'rgba(0, 0, 0, 0.5)',
    snackbar: '#323232',
    activeRating: '#FFB400',
    gray: 'rgba(0,0,0,0.2)',
  },
  gradient: {
    first: '#2C2D53',
    second: '#2C2D53',
  },
}

export default colors
