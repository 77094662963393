import React, { useEffect } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Chip, Dialog, IconButton, Slide, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { DateCalendar } from '@mui/x-date-pickers'
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay'

import { IDateFilterProps } from 'components/search/DateFilter/DateFilter.types'
import { Day } from 'components/search/DateFilter/Day'
import { DynamicDates, SEODateParams } from 'constants/CloudSearch'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './DateFilter.styles'
import { useCalendar } from './useCalendar'

const Transition = React.forwardRef<
  unknown,
  TransitionProps & { children: React.ReactElement<any, any> }
>((props, ref) => <Slide direction={'up'} ref={ref} {...props} />)

export const DateFilter = ({ searchParams, dateSetting }: IDateFilterProps) => {
  const styles = useStyles({})
  const { isMobile } = useBreakpoints()
  const { i18n } = useDictionary()
  const {
    active,
    clear,
    filterDates,
    handleHide,
    handleSelect,
    handleToggle,
    isVisible,
    selectedDates,
    setThisMonth,
    setThisWeek,
    setToday,
    setTomorrow,
    setWeekend,
  } = useCalendar()

  React.useEffect(() => {
    if (!!searchParams && searchParams.has('date')) {
      switch (searchParams.get('date')) {
        case DynamicDates.Today:
          setToday()
          break
        case DynamicDates.Tomorrow:
          setTomorrow()
          break
        case DynamicDates.Weekend:
          setWeekend()
          break
        case DynamicDates.Week:
          setThisWeek()
          break
        case DynamicDates.Month:
          setThisMonth()
          break
        default:
          break
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (dateSetting) {
      switch (dateSetting) {
        case SEODateParams.Today:
          setToday()
          break
        case SEODateParams.Tomorrow:
          setTomorrow()
          break
        case SEODateParams.Weekend:
          setWeekend()
          break
        case SEODateParams.ThisWeek:
          setThisWeek()
          break
        case SEODateParams.ThisMonth:
          setThisMonth()
          break
        default:
          break
      }
    }
  }, [dateSetting])

  const DayComponent = (props: PickersDayProps<Date>) => (
    <Day selectedDates={selectedDates} {...props} />
  )

  return (
    <>
      <Chip
        label={i18n.datesFilter.filterName}
        onClick={handleToggle}
        onDelete={active ? clear : undefined}
        size={'small'}
      />
      <Dialog
        open={isVisible}
        onClose={handleHide}
        TransitionComponent={Transition}
        fullScreen={isMobile}
        title={i18n.datesFilter.filterName}
        PaperProps={{ sx: styles.dialog }}
      >
        {isMobile && (
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
            }}
            onClick={handleHide}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Box sx={styles.filterBar}>
          <Chip color={'primary'} label={i18n.datesFilter.today} onClick={setToday} />
          <Chip color={'primary'} label={i18n.datesFilter.tomorrow} onClick={setTomorrow} />
          <Chip color={'primary'} label={i18n.datesFilter.weekend} onClick={setWeekend} />
          <Chip color={'primary'} label={i18n.datesFilter.thisWeek} onClick={setThisWeek} />
          <Chip color={'primary'} label={i18n.datesFilter.thisMonth} onClick={setThisMonth} />
        </Box>
        <DateCalendar
          value={selectedDates.from}
          onChange={handleSelect}
          disablePast={true}
          showDaysOutsideCurrentMonth={true}
          fixedWeekNumber={6}
          slots={{ day: DayComponent }}
        />
        <Typography
          variant={'caption'}
          children={
            selectedDates.from && !selectedDates.to
              ? i18n.datesFilter.addDate
              : i18n.datesFilter.selectDate
          }
          align={'center'}
        />
        <Button
          onClick={filterDates}
          children={i18n.datesFilter.show}
          variant={'contained'}
          fullWidth={true}
        />
        <Button
          onClick={clear}
          children={i18n.datesFilter.clear}
          disabled={!(selectedDates.to || selectedDates.from)}
        />
      </Dialog>
    </>
  )
}
