import * as React from 'react'

import { useParams, useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Route } from 'router/routes'
import { IRouteMatchParams } from 'router/types'

import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/Helmet'
import SimpleLoading from 'components/reusable/SimpleLoading'
import { CategoryFilter } from 'components/search/CategoryFilter'
import { DateFilter } from 'components/search/DateFilter'
import { FiltersBar } from 'components/search/FiltersBar'
import { LocationFilter } from 'components/search/LocationFilter'
import { ResultsGrid } from 'components/search/ResultsGrid'
import { SearchStatusBar } from 'components/search/SearchStatusBar'
import { SearchTypeFilter } from 'components/search/SearchTypeFilter'
import { Searchbar } from 'components/search/Searchbar'
import { SourceIdsFilter } from 'components/search/SourceIdsFilter'
import { TagsFilter } from 'components/search/TagsFilter'
import { VariousFilters } from 'components/search/VariousFilters'
import { HitFields } from 'constants/CloudSearch'
import { useLayoutEffect } from 'misc/hooks/useIsomorphicLayoutEffect'
import { categoryFilterState } from 'state/cloudSearch/atoms/categoryFilter'
import { locationFilterState } from 'state/cloudSearch/atoms/locationFilter'
import { tagsFilterState } from 'state/cloudSearch/atoms/tagsFilter'
import { useResetSearchFilters } from 'state/cloudSearch/hooks/useResetSearchFilters'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useCloudSearchPage } from './useCloudSearchPage'

export const SearchPage = () => {
  const { i18n } = useDictionary()
  const params = useParams<{} | IRouteMatchParams[Route.searchWithParams]>()
  const [searchParams] = useSearchParams()
  const { data, onScrollEnd, found, categories, tags, isValidating } = useCloudSearchPage()
  const { resetFilters } = useResetSearchFilters()
  const category = useRecoilValue(categoryFilterState)
  const tagsFromState = useRecoilValue(tagsFilterState)
  const location = useRecoilValue(locationFilterState)
  const terms = [
    ...(category
      ? [
          {
            ...category,
            fieldNames: {
              name: HitFields.category_name,
              id: HitFields.category_id,
            },
          },
        ]
      : []),
    ...(tagsFromState.length
      ? tagsFromState.map((tag) => ({
          ...tag,
          fieldNames: {
            name: HitFields.tags_names,
            id: HitFields.tags_ids,
          },
        }))
      : []),
    ...(location
      ? [
          {
            ...location,
            fieldNames: {
              name: HitFields.locations_names,
              id: HitFields.locations_ids,
            },
          },
        ]
      : []),
  ]
  const metaTerms = terms
    .map((term) => (Array.isArray(term) ? term.map((t) => t.name).join(', ') : term.name))
    .join(' ')
  const pageName = terms.length
    ? `${metaTerms} - ${i18n.meta.ticketsForEvents}`
    : i18n.meta.ticketsForEvents

  useLayoutEffect(() => {
    if (!searchParams.entries().next().done) {
      resetFilters()
      if (typeof window !== 'undefined') window.scrollTo(0, 0)
    }
  }, [searchParams])

  return (
    <PageWrapper>
      <Helmet
        pageName={pageName}
        description={i18n.meta.metaDesc}
        ogDescription={i18n.meta.metaDesc}
      />
      <Searchbar />
      <FiltersBar>
        <DateFilter
          dateSetting={'dateSetting' in params ? params.dateSetting : undefined}
          searchParams={searchParams}
        />
        <LocationFilter
          locationSlug={'locationSlug' in params ? params.locationSlug : undefined}
          searchParams={searchParams}
        />
        <SearchTypeFilter searchParams={searchParams} />
        <VariousFilters searchParams={searchParams} />
        <CategoryFilter
          categorySlug={'categorySlug' in params ? params.categorySlug : undefined}
          categories={categories}
          searchParams={searchParams}
        />
        <TagsFilter
          tagSlug={'tagSlug' in params ? params.tagSlug : undefined}
          tags={tags}
          searchParams={searchParams}
        />
        <SourceIdsFilter searchParams={searchParams} />
      </FiltersBar>
      <SearchStatusBar found={found} isLoading={isValidating} terms={terms} />
      <ResultsGrid results={data} onScrollEnd={onScrollEnd} />
      {isValidating && <SimpleLoading relative={true} />}
    </PageWrapper>
  )
}
