import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    header: {
      fontSize: '1.75rem',
      marginBottom: '2rem',

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    withoutPaddings: {
      padding: '0!important',
    },
  })
)

export default useStyles
