import { getRemoteConfig, getString } from 'firebase/remote-config'

import { Languages } from 'constants/Languages'
import { RemoteConfigKeys } from 'constants/RemoteConfig'

// TODO: if more keys are added, consider moving this to an enum or a separate file.
export type DictionaryKeys = 'buyButton' | 'buyButtonFinal'

/**
 * Gets external dictionary from Remote Config.
 */
export const getExternalDictionary = () => {
  const externalDictionary = getString(getRemoteConfig(), RemoteConfigKeys.externalDictionary)

  if (externalDictionary) {
    return JSON.parse(externalDictionary) as Record<string, Record<Languages, string>>
  }

  return {}
}
