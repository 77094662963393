/**
 * Function that return localized description for slot sales
 *
 * @param {IHappeningDescription[]} descriptions - array of descriptions
 * @param {string} selectedlanguage - selected language code
 *
 * @return {IHappeningDescription} localized description
 */
import { Languages } from 'constants/Languages'
import { IHappeningDescription } from 'models/happening/types'

const getLocalizedDescription = (
  descriptions: IHappeningDescription[] | undefined,
  selectedLanguage?: Languages
) => {
  return descriptions?.find(
    (description) => description.language.toLocaleLowerCase() === Languages.Polski || null
  )
}

export default getLocalizedDescription
