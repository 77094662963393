import { EMPTY, of as of$ } from 'rxjs'
import {
  filter as filter$,
  mergeMap as mergeMap$,
  take as take$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { getLocation } from 'models/connectedRouter/selectors'
import { locationChange, navigate } from 'models/internalRouter/actions'
import { isActionOf } from 'typesafe-actions'

export const removeHashWhenComeFromOutside: _Store.IEpic = (
  action$,
  state$,
  { modalDictionary }
) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    take$(1),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const location = getLocation(state)
      const modal = modalDictionary.fromHash(location.hash || '')

      if (modal) {
        const { pathname, search } = location

        return of$(navigate({ to: `${pathname}${search}`, navigateOptions: { replace: true } }))
      }

      return EMPTY
    })
  )
}
