import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import config from 'config'
import getArtistImages from 'models/images/selectors/getArtistImages'
import { getModule, getParams } from 'models/internalRouter/selectors'
import { isActionOf } from 'typesafe-actions'

import { getImages, getImagesByCloudinaryPrefixAndSlug, loadImages } from './../actions'
import { getEventImages, getPlaceImages } from './../selectors'

export const requestForImages: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(loadImages)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const module = getModule(state)
      let key = ''
      let checkIfGotImages = true
      if (module === Route.place) {
        const slugs = getParams(state) as RouteParams<Route.place>
        key = `${config.images.placePrefix}${slugs.slug}`
        checkIfGotImages = !!getPlaceImages(state)
      } else if (
        module === Route.rundate ||
        module === Route.activity ||
        module === Route.pinRundate ||
        module === Route.pinActivity
      ) {
        const slugs = getParams(state) as RouteParams<Route.activity | Route.rundate>
        key = `${config.images.eventPrefix}${slugs.eventSlug}`
        checkIfGotImages = !!getEventImages(state)
      } else if (module === Route.artist) {
        const slugs = getParams(state) as RouteParams<Route.artist>
        key = `${config.images.artistPrefix}${slugs.slug}`
        checkIfGotImages = !!getArtistImages(state)
      }
      if (checkIfGotImages) {
        return EMPTY$
      }

      return of$(getImages.request(key))
    })
  )
}

export const getImagesByCloudinaryParams: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getImagesByCloudinaryPrefixAndSlug)),
    mergeMap$((action) => {
      const { prefix, slug, rundateSlug } = action.payload
      const key = `${prefix}${slug}${rundateSlug ? `/${rundateSlug}` : ''}`

      return of$(getImages.request(key))
    })
  )
}

export const fetchImagesFromCloudinary: _Store.IEpic = (action$, state$, { cloudinaryApi }) => {
  return action$.pipe(
    filter$(isActionOf([getImages.request])),
    mergeMap$((action) => {
      return from$(cloudinaryApi.getImagesByTag(action.payload)).pipe(
        map$((_data) => {
          const images = _data as string[]

          return getImages.success({ images, key: action.payload })
        }),
        catchError$((error: Error) => of$(getImages.failure(error)))
      )
    })
  )
}
