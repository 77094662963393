import React from 'react'

import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { Box, Button, Link } from '@mui/material'
import qs from 'qs'
import { Link as RouterLink } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { CardDeck } from 'components/reusable/CardDeck'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import { DateTimePlace } from 'components/reusable/DateTimePlace'
import Description from 'components/reusable/Description'
import Gallery from 'components/reusable/Gallery'
import { Map } from 'components/reusable/Map'
import { AnchorLinks } from 'constants/AnchorLinks'
import { HitFields } from 'constants/CloudSearch'
import isHTML from 'misc/helpers/isHTML'
import scrollTo from 'misc/helpers/scrollTo'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import config from '../../config'
import { FavoritesTypes } from '../../constants/Favorites'
import { useFavoritesContext } from '../favorites/FavoritesProvider'
import { useEditFavorites } from '../favorites/FavoritesProvider/useEditFavorites'
import { IPlaceProps } from './Place.types'

export const Place = ({
  data: { slug, name, id, city, description, address, thumb, category, lon, lat },
  cloudinaryImages,
  placeEvents,
}: IPlaceProps) => {
  const { i18n } = useDictionary()
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isPlaceInFavs = isItemInFavs(id, FavoritesTypes.Places)

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
      <TwoColumnsLayout
        boxComponent={
          <>
            <DateTimePlace place={{ slug, name: address }} />
            <Box sx={{ mt: 4 }} />
            <Flex gap={3} direction={'column'} sx={{ mt: 2 }}>
              <Button
                fullWidth={true}
                size={'large'}
                variant={'contained'}
                onClick={(event) => scrollTo(event, AnchorLinks.PLACE_EVENTS)}
                children={i18n.place.checkEvents}
              />
              {config.features.userLogin && (
                <Button
                  fullWidth={true}
                  variant={'outlined'}
                  children={isPlaceInFavs ? i18n.following.unfollow : i18n.following.placeHeader}
                  onClick={handleEditFavorites({
                    isFollowed: isPlaceInFavs,
                    type: FavoritesTypes.Places,
                    externalId: id,
                    itemName: name,
                  })}
                  startIcon={isPlaceInFavs ? <Favorite /> : <FavoriteBorder />}
                />
              )}
            </Flex>
          </>
        }
        pageTitle={name}
        image={thumb ?? cloudinaryImages[0]}
        tags={category ? [category] : undefined}
      >
        <Description text={description} html={isHTML(description) ? description : undefined} />
        <SectionContainer title={i18n.place.map} container={true}>
          <Map lon={lon} lat={lat} />
        </SectionContainer>
        {!!cloudinaryImages?.length && (
          <Gallery
            isLoading={false}
            photos={!!cloudinaryImages?.length ? cloudinaryImages : undefined}
          />
        )}
      </TwoColumnsLayout>
      {!!placeEvents && !!placeEvents.length && (
        <SectionContainer title={i18n.place.header} id={AnchorLinks.PLACE_EVENTS}>
          <CardDeck>
            {placeEvents?.map((event) => (
              <CloudSearchCard key={event.id} item={event} />
            ))}
          </CardDeck>
          <Link
            align={'center'}
            display={'block'}
            mt={4}
            component={RouterLink}
            to={
              routes[Route.search] +
              qs.stringify(
                {
                  [HitFields.place_name]: placeEvents[0].fields.place_name,
                },
                { addQueryPrefix: true }
              )
            }
            children={i18n.place.showMore}
          />
        </SectionContainer>
      )}
      {/* @TODO: add <SimilarPlaces places={similarPlaces}  */}
    </Box>
  )
}
