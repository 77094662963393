import axios from 'axios'

import EventsListService from 'misc/classes/EventsListService'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import { IHtmlResponse } from 'services/$html-api/types'

class HtmlApi extends EventsListService {
  public getHtml(url: string): Promise<IHtmlResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: 'document',
        })
        .then(getData)
        .then((response: IHtmlResponse) => {
          resolve(response)
        })
        .catch((error) => {
          reject(catchHttpError(error))
        })
    })
  }
}

export default new HtmlApi()
