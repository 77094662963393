import { createSelector } from 'reselect'

import config from 'config'
import { getLocation } from 'models/connectedRouter/selectors'
import { getEvent } from 'models/event/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'

import * as TEXTS from './../constants/texts'
import { IBuyPageMeta, IMetaValue } from './../types'

const buyPageMeta = createSelector(
  [getLocation, getEvent, getIframeParams],
  (location, event, embed): IBuyPageMeta => {
    let pageName: IMetaValue = TEXTS.LOADING
    let description: IMetaValue = TEXTS.LOADING
    let ogDescription: IMetaValue = TEXTS.LOADING

    if (event) {
      const category = event.tags?.[0] || null
      const categoryName = category?.name || TEXTS.EVENTS

      let eventDescription = event.description
      if (eventDescription?.length > 60) {
        eventDescription = `${eventDescription.substr(0, 60)}...`
      }

      pageName = [event.title, `${TEXTS.BUY_TICKETS_FOR} ${categoryName}`, event.placeAddress]

      description = [
        `${TEXTS.BUY_TICKETS_FOR_EVENT} ${event.title}`,
        event.friendlyDate,
        event.placeAddress,
      ]

      ogDescription = [categoryName, event.title, eventDescription, TEXTS.BUY_TICKET]
    }

    return {
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      description,
      ogDescription,
      pageName,
      customGtmId: embed?.gtmKey || event?.gtmId,
      isEmbed: embed !== null,
      customGtagId: embed?.gtagKey,
      fbPixelKey: embed?.fbPixelKey,
    }
  }
)

export default buyPageMeta
