import React, { useContext, useEffect } from 'react'

import { Grid, Typography } from '@mui/material'
import cn from 'classnames'
import { Field, useFormikContext } from 'formik'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { PayUContext } from 'components/_functional/PayUContextProvider/PayUContextProvider.component'
import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import classes from 'components/buying/components/PaymentMethod/PaymentMethod.classes'
import { IPaymentMethodProps } from 'components/buying/components/PaymentMethod/PaymentMethod.types'
import RadioButton from 'components/formik/Radio'
import config from 'config'
import { Languages } from 'constants/Languages'
import { CardTypes, PaymentMethods } from 'constants/PaymentMethods'
import getCommonObjectFromArrays from 'misc/helpers/getCommonObjectFromArrays'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'
import { IPaymentMethod } from 'types/Pools'

import useStyles from './PaymentMethod.styles'

const PAYMENT_METHOD_TYPE = 'paymentMethod.type'

const PaymentMethod = ({
  isDisabled,
  step,
  paymentMethodsFromState,
  selectedTheme,
  isSlotsSale,
  isBasketPay,
  cardPaymentForm,
  setCardCredentials,
  event,
}: IPaymentMethodProps) => {
  const { i18n } = useDictionary()
  const { isMobile } = useBreakpoints()
  const styles = useStyles(isMobile)()
  const state = useContext(clearContext)
  const { code } = useLang()
  const { setIsCardPaymentMethod, setCardBrand } = useContext(PayUContext)
  const basketItems = state?.basketItems
  const paymentMethods: IPaymentMethod[] | undefined =
    isBasketPay && basketItems
      ? getCommonObjectFromArrays(basketItems.map((item) => item.paymentMethods || []))
      : paymentMethodsFromState

  const isActivity = event?.calendarEvent
  const isDefaultPayU = config.buy.defaultPaymentMethod === PaymentMethods.PAYU
  const filteredPaymentMethods =
    isActivity || isDefaultPayU
      ? paymentMethods?.filter((method) => {
          if (method.type === PaymentMethods.APPLE_PAY) {
            return window.ApplePaySession
          }

          // eCard (PayPal) payment method should be available only on english route [HUSSAR CUP EVENT]
          if (method.type === PaymentMethods.ECARD) {
            return code === Languages.English
          }

          return true
        })
      : [{ type: config.buy.defaultPaymentMethod }]

  const isDefaultTheme = selectedTheme === 'default'
  const { values } = useFormikContext<IBuyingOnlineFormValues>()

  const iconFileUrl = (method: PaymentMethods, cardType?: CardTypes) => {
    switch (method) {
      case PaymentMethods.PAYU:
        return 'payu.png'
      case PaymentMethods.BLIK:
        return 'blik.png'
      case PaymentMethods.MBANK:
        return 'mbank.png'
      case PaymentMethods.CARD:
        return cardIconFileUrl(cardType)
      case PaymentMethods.TWISTO:
        return 'twisto.png'
      case PaymentMethods.GOOGLE_PAY:
        return 'google.png'
      case PaymentMethods.APPLE_PAY:
        return 'apple.png'
      case PaymentMethods.ECARD:
        return 'ecard.png'
      default:
        return
    }
  }

  const cardIconFileUrl = (cardType?: CardTypes) => {
    switch (cardType) {
      case CardTypes.VISA:
        return 'card.png'
      case CardTypes.MASTERCARD:
        return 'mastercard.png'
      default:
        return 'card.png'
    }
  }

  const methodName = (method: PaymentMethods) => {
    switch (method) {
      case PaymentMethods.PAYU:
        return i18n.buy.paymentMethod.payu
      case PaymentMethods.BLIK:
        return i18n.buy.paymentMethod.blik
      case PaymentMethods.MBANK:
        return i18n.buy.paymentMethod.mBank
      case PaymentMethods.CARD:
        return i18n.buy.paymentMethod.card
      case PaymentMethods.TWISTO:
        return i18n.buy.paymentMethod.twisto
      case PaymentMethods.GOOGLE_PAY:
        return i18n.buy.paymentMethod.google
      case PaymentMethods.APPLE_PAY:
        return i18n.buy.paymentMethod.apple
      case PaymentMethods.ECARD:
        return i18n.buy.paymentMethod.ecard
      default:
        return ''
    }
  }

  const methodDescription = (method: PaymentMethods) => {
    switch (method) {
      case PaymentMethods.TWISTO:
        return i18n.buy.paymentMethod.twistoDescription
      default:
        return ''
    }
  }

  useEffect(() => {
    if (values.paymentMethod.type === PaymentMethods.CARD && setIsCardPaymentMethod) {
      setIsCardPaymentMethod(true)
      if (values.paymentMethod.limitationValue && setCardBrand) {
        setCardBrand(values.paymentMethod.limitationValue)
      }
    } else if (setIsCardPaymentMethod && setCardBrand) {
      setIsCardPaymentMethod(false)
      setCardCredentials(null)
      setCardBrand(undefined)
    }
  }, [values.paymentMethod.type])

  return (
    <Grid
      container={true}
      className={cn(
        styles.outerContainer,
        isDisabled ? styles.disabled : undefined,
        classes.paymentMethods
      )}
    >
      <Typography
        variant={isSlotsSale ? 'h4' : 'h3'} // TODO: unify this between forms
        children={`${step}. ${i18n.buy.paymentMethod.choosePaymentMethod}`}
        className={styles.header}
      />
      <Grid
        container={true}
        direction={'column'}
        className={cn(styles.root, !isDefaultTheme && styles.noPadding)}
      >
        {filteredPaymentMethods && filteredPaymentMethods.length ? (
          filteredPaymentMethods.map((method, index) => (
            <Grid
              container={true}
              item={true}
              direction={'column'}
              className={cn(styles.button, classes.paymentMethod)}
              key={`payment-method-${index}`}
            >
              <Grid item={true}>
                <Field name={PAYMENT_METHOD_TYPE} component={RadioButton} id={method.type}>
                  <Grid
                    container={true}
                    sx={{ minWidth: method.limitationValue === CardTypes.MASTERCARD ? 0 : 262 }}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item={true}>
                      <Typography>{methodName(method.type)}</Typography>
                      <Typography variant={'caption'} color={'secondary'}>
                        {methodDescription(method.type)}
                      </Typography>
                    </Grid>
                    <Grid item={true} sx={{ minWidth: 53 }}>
                      <img
                        src={`/static_legacy/payment_methods/${iconFileUrl(
                          method.type,
                          method.limitationValue
                        )}`}
                        className={styles.logo}
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
              {method.type === PaymentMethods.CARD &&
                values.paymentMethod.type === PaymentMethods.CARD &&
                cardPaymentForm}
            </Grid>
          ))
        ) : (
          <Typography>{i18n.buy.paymentMethod.emptyPaymentMethods}</Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default PaymentMethod
