import React from 'react'

import { DeleteOutline } from '@mui/icons-material'
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import qs from 'qs'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { HitFields } from '../../../constants/CloudSearch'
import { FavoritesTypes } from '../../../constants/Favorites'
import { routes } from '../../../router/routes'
import { prepareRoute } from '../../../router/utils/prepareRoute'
import { fetchCloudinaryList } from '../../../services/Api/Cloudinary'
import { FavoritesApi } from '../../../services/Api/FavoritesApi'
import { FavoritesIcon } from '../../_svg/FavoritesIcon'
import { useEditFavorites } from '../FavoritesProvider/useEditFavorites'
import { IFavoriteItemProps } from './FavoriteItem.types'

export const FavoriteItem = ({ item: { item } }: IFavoriteItemProps) => {
  const { data: cloudinaryImages } = useSWR<string[]>(
    {
      slug: item.slug,
      prefix: FavoritesApi.favoriteTypes[item.type].cloudinaryPrefix,
    },
    fetchCloudinaryList,
    {}
  )
  const navigate = useNavigate()
  const { removeFromFavorites } = useEditFavorites()

  const handleClick = () => {
    if (item.type === FavoritesTypes.Tags) {
      navigate(
        routes[FavoritesApi.favoriteTypes[item.type].screen] +
          qs.stringify(
            {
              [HitFields.tags_names]: item.name,
              [HitFields.tags_ids]: item.externalId,
              [HitFields.tags_slugs]: item.slug,
            },
            { addQueryPrefix: true }
          )
      )
    } else {
      navigate(
        prepareRoute({
          route: FavoritesApi.favoriteTypes[item.type].screen,
          params: { slug: item.slug },
        })
      )
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    removeFromFavorites({ type: item.type, externalId: item.externalId, itemName: item.name })
  }

  return (
    <ListItemButton onClick={handleClick} title={item.name} disableGutters>
      <ListItemAvatar>
        <Avatar src={cloudinaryImages?.[0]} children={<FavoritesIcon type={item.type} />} />
      </ListItemAvatar>
      <ListItemText primary={item.name} />
      <ListItemSecondaryAction>
        <IconButton onClick={handleDelete}>
          <DeleteOutline />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}
