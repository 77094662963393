import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gap: '0.625rem',
    },
    buttons: {
      marginTop: '1.375rem',
    },
    menu: {
      backgroundColor:
        theme.palette.background.default !== 'transparent'
          ? theme.palette.background.default + '!important'
          : '#ffffff !important',
    },
  })
)

export default useStyles
