import { checkMailValidation } from 'models/sendgrid/actions'
import { getType } from 'typesafe-actions'

import { IAction, ISendgridReducer } from '../types'

const initialState: ISendgridReducer = {
  checkEmail: null,
}

const reducer = (state: ISendgridReducer = initialState, action: IAction): ISendgridReducer => {
  switch (action.type) {
    case getType(checkMailValidation.success):
      return {
        ...state,
        checkEmail: action.payload,
      }
    default:
      return state
  }
}

export default reducer
