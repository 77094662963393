import React from 'react'

import { FacebookRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { FacebookAuthProvider, getAuth, signInWithCredential } from 'firebase/auth'

import { useNotify } from 'components/_functional/NotificationProvider'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const FacebookLogin = () => {
  const { i18n } = useDictionary()
  const { notify } = useNotify()

  const handleClick = async () => {
    try {
      const FB = window.FB
      FB.login(
        async (response: any) => {
          if (response.authResponse) {
            const token = response.authResponse.accessToken
            const credential = FacebookAuthProvider.credential(token)

            await signInWithCredential(getAuth(), credential)
          } else {
            notify({ text: i18n.notifications.genericError })
          }
        },
        { scope: 'email' }
      )
    } catch (error: any) {
      notify({ text: error.message })
    }
  }

  return (
    <Button
      startIcon={<FacebookRounded />}
      children={i18n.auth.facebookLogin}
      sx={{
        backgroundColor: '#3B5998',
        color: 'secondary.contrastText',
        '&:hover': {
          backgroundColor: '#273f70',
        },
      }}
      onClick={handleClick}
      fullWidth
    />
  )
}
