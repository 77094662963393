import React, { useEffect, useState } from 'react'

import { Button } from '@mui/material'

import SliderComponent from 'components/formik/Slider'
import { CurrencyISO } from 'constants/Currency'
import usePreviousState from 'misc/hooks/usePreviousState'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IPriceTypesPickerProps } from './PriceTypesPicker.types'

const PriceTypesPicker = ({
  capacity,
  counter,
  isCalculatedPerPerson,
  isSelected,
  priceType,
  selections,
  selectionsPerPriceType,
  selectedSlot,
  selectedSpace,
  setOnlyOnePrice,
  setPeopleCount,
  generateIdempotencyKey,
  eventAddToCartHappening,
  eventRemoveFromCartHappening,
  defaultCount,
  happeningPriceType,
  counterAsMin,
}: IPriceTypesPickerProps) => {
  const { i18n } = useDictionary()
  const [state, setState] = useState(defaultCount || 0)
  const numberOfPlayers = selectionsPerPriceType.find(
    (sel) => sel.priceType?.type === priceType.type
  )?.numberOfPlayers
  const prevNumOfPlayers = usePreviousState(numberOfPlayers) || 0
  const prevSpace = usePreviousState(selectedSpace)
  const [defaultNumberOfPeople, setDefaultNumberOfPeople] = useState(defaultCount || 0)
  const selectedSinglePrice = selections.find(
    (selection) => selection.numberOfPlayers && selection.numberOfPlayers > 0
  )

  const setPlayerNumberForPriceType = (val: number) => {
    setState(val)

    isCalculatedPerPerson
      ? setPeopleCount({
          numberOfPlayers: val,
          priceType,
        })
      : setOnlyOnePrice({
          numberOfPlayers: val,
          priceType,
        })
    generateIdempotencyKey()
  }

  useEffect(() => {
    if (defaultCount) {
      setDefaultNumberOfPeople(defaultCount)
      setPeopleCount({
        numberOfPlayers: defaultCount,
        priceType,
      })
    } else {
      setDefaultNumberOfPeople(0)
      setPeopleCount({
        numberOfPlayers: 0,
        priceType,
      })
    }
  }, [defaultCount])

  useEffect(() => {
    if (isCalculatedPerPerson) {
      setPeopleCount({
        numberOfPlayers: state,
        priceType,
      })
    }
    if (selectedSinglePrice && selectedSinglePrice.priceType?.type === priceType.type) {
      setOnlyOnePrice({
        ...selectedSinglePrice,
        priceType,
      })
    }
  }, [selectedSpace, selectedSlot])

  useEffect(() => {
    if (selectedSpace !== -1 && isCalculatedPerPerson) {
      if (numberOfPlayers && numberOfPlayers > prevNumOfPlayers) {
        const diff = numberOfPlayers - prevNumOfPlayers
        for (let i = 0; i < diff; i++) {
          eventAddToCartHappening({
            price: priceType.value,
            currency: CurrencyISO.PLN,
          })
        }
      }

      if (numberOfPlayers || 0 < prevNumOfPlayers) {
        const diff = prevNumOfPlayers - (numberOfPlayers || 0)
        for (let i = 0; i < diff; i++) {
          eventRemoveFromCartHappening({
            price: priceType.value,
            currency: CurrencyISO.PLN,
          })
        }
      }

      if (numberOfPlayers && prevSpace !== selectedSpace) {
        for (let i = 0; i < numberOfPlayers; i++) {
          eventRemoveFromCartHappening({
            price: priceType.value,
            currency: CurrencyISO.PLN,
          })
        }
      }

      if (numberOfPlayers && prevSpace === -1 && selectedSpace !== -1) {
        for (let i = 0; i < numberOfPlayers; i++) {
          eventAddToCartHappening({
            price: priceType.value,
            currency: CurrencyISO.PLN,
          })
        }
      }
    }
  }, [selectedSpace, numberOfPlayers, selectedSlot])

  return (
    <>
      {isCalculatedPerPerson && (
        <div>
          <SliderComponent
            priceType={happeningPriceType}
            maxValue={capacity}
            onChange={setPlayerNumberForPriceType}
            minValue={counterAsMin ? defaultCount : 0}
            step={counter}
            value={defaultNumberOfPeople}
          />
        </div>
      )}
      {!isCalculatedPerPerson && (
        <div>
          <Button
            onClick={() => setPlayerNumberForPriceType(1)}
            size={'large'}
            color={'primary'}
            variant={isSelected ? 'contained' : 'outlined'}
            disableElevation={true}
            fullWidth={true}
          >
            {isSelected ? i18n.happening.priceTypes.chosen : i18n.happening.priceTypes.choose}
          </Button>
        </div>
      )}
    </>
  )
}

export default PriceTypesPicker
