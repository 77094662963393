import React, { Fragment, useState } from 'react'

import { Search } from '@mui/icons-material'
import { Divider, InputAdornment, List, TextField } from '@mui/material'

import { IRundateFavorite } from '../../../types/Favorites'
import { Flex } from '../../_layout/Flex'
import { CalendarItem } from '../CalendarItem'
import { ICalendarProps } from './Calendar.types'

export const Calendar = ({ items }: ICalendarProps) => {
  const [phrase, setPhrase] = useState('')
  const filteredFavorites = items
    .filter((favorite) => favorite.item.name.toLowerCase().includes(phrase.toLowerCase()))
    .sort((a, b) => {
      if ('time' in a.item && 'time' in b.item) {
        return b.item.time.toMillis() - a.item.time.toMillis()
      }

      return 1
    })

  return (
    <Flex gap={3}>
      <TextField
        value={phrase}
        size={'medium'}
        type={'search'}
        variant={'outlined'}
        onChange={(e) => setPhrase(e.target.value)}
        fullWidth={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <List sx={{ width: '100%' }} disablePadding>
        {filteredFavorites.map((favorite, index) => (
          <Fragment key={favorite.item.externalId}>
            {index !== 0 && <Divider />}
            <CalendarItem item={favorite.item as IRundateFavorite} />
          </Fragment>
        ))}
      </List>
    </Flex>
  )
}
