import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import Landing from 'components/Landing/Landing.component'
import { ILandingPageFromDispatch, ILandingPageFromState } from 'components/Landing/Landing.types'
import { landingPageMounted } from 'models/landingPage/actions'
import { getLandingPage } from 'models/landingPage/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

const mapStateToProps = (state: _Store.IState): ILandingPageFromState => ({
  isLoading: getLoading(CONST.LANDING_PAGE)(state),
  landingPage: getLandingPage(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ILandingPageFromDispatch => {
  return {
    mounted: () => dispatch(landingPageMounted()),
  }
}

export default connect<ILandingPageFromState, ILandingPageFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Landing)
