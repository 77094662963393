import { combineReducers } from 'redux'

import allReducer from './all'
import finalPriceReducer from './finalPrice'
import paymentMethodsReducer from './paymentMethods'
import selectedSeatsReducer from './selectedSeats'
import selectedTicketsReducer from './selectedTickets'

const reducer = combineReducers({
  all: allReducer,
  paymentMethods: paymentMethodsReducer,
  selectedSeats: selectedSeatsReducer,
  selectedTickets: selectedTicketsReducer,
  finalPrice: finalPriceReducer,
})

export default reducer
