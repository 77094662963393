import { ComponentType } from 'react'

import { styled } from '@mui/material'

import ToolTip from 'components/reusable/ToolTip'
import { IToolTipProps } from 'components/reusable/ToolTip/ToolTip.types'

export const Styled = {
  ToolTip: styled(ToolTip)({
    position: 'relative',
    top: 4,
    left: 4,
  }) as ComponentType<IToolTipProps>,
}
