import React from 'react'

import { createSvgIcon } from '@mui/material/utils'

const SeatsIcon = createSvgIcon(
  <>
    <g clip-path="url(#clip0_1904_2312)">
      <path
        d="M11.9824 1.38867H18.0623C22.1104 1.38867 25.4043 4.70621 25.4043 8.8007V16.1739C25.4043 19.0253 23.1108 21.3328 20.2941 21.3328H9.76597C6.94153 21.3328 4.65582 19.0175 4.65582 16.1739V8.8007C4.64042 4.70621 7.92662 1.38867 11.9824 1.38867Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M25.0422 25.9863H24.9653H4.99414V30.2284H24.9653H25.0422H45.0057V25.9863H25.0422Z"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M31.9457 1.38867H38.0256C42.0737 1.38867 45.3676 4.70621 45.3676 8.8007V16.1739C45.3676 19.0253 43.0742 21.3328 40.2574 21.3328H29.7293C26.9049 21.3328 24.6191 19.0175 24.6191 16.1739V8.8007C24.6191 4.71398 27.9053 1.38867 31.9611 1.38867H31.9457Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M45.606 14.3477H44.3208C42.6585 14.3477 41.3117 15.7073 41.3117 17.3855V20.9439H28.6517V17.3855C28.6517 16.966 28.5671 16.5697 28.4131 16.2045C27.9591 15.1168 26.8893 14.3477 25.6426 14.3477H24.3573C22.695 14.3477 21.3482 15.7073 21.3482 17.3855V20.9439H8.68828V17.3855C8.68828 15.7073 7.34148 14.3477 5.67914 14.3477H4.3939C2.73157 14.3477 1.38477 15.7073 1.38477 17.3855V22.9484C1.38477 24.6266 2.73157 25.9862 4.3939 25.9862H5.33282H24.2881C24.2881 25.9862 24.3112 25.9862 24.3189 25.9862C24.3189 25.9862 24.3343 25.9862 24.342 25.9862C24.342 25.9862 24.3497 25.9862 24.3573 25.9862H25.2963H44.2515C44.2515 25.9862 44.2746 25.9862 44.2823 25.9862C44.29 25.9862 44.3054 25.9862 44.3131 25.9862H45.5983C47.2607 25.9862 48.6075 24.6266 48.6075 22.9484V17.3855C48.6075 15.7073 47.2607 14.3477 45.5983 14.3477H45.606Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path d="M16.1612 5.99609H13.8755" stroke="#1D1D1B" stroke-linejoin="round" />
      <path d="M36.1323 5.99609H33.8389" stroke="#1D1D1B" stroke-linejoin="round" />
      <line x1="25" y1="26" x2="25" y2="30" stroke="#1D1D1B" stroke-width="2" />
    </g>
    <defs>
      <clipPath id="clip0_1904_2312">
        <rect width="50" height="31" fill="white" />
      </clipPath>
    </defs>
  </>,
  'Seats'
)

export default SeatsIcon
