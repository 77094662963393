import * as braze from '@braze/web-sdk'

import config from '../../config'
import { BrazeCustomAttributes } from '../../constants/Analytics'
import { IBrazeUserExportResponse } from '../../types/User'
import { URI } from '../URI'
import { Fetcher } from './Fetcher'

export class BrazeApi {
  public static getBrazeUser = async (uid: string, brazeId: string) => {
    const body = JSON.stringify({
      external_ids: [uid],
    })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.braze.brazeApiKey}`,
    }

    const brazeUser: IBrazeUserExportResponse = await Fetcher.post(
      URI.brazeGetUserData,
      body,
      headers
    )

    return {
      brazeUser,
      isNewsletter:
        !!brazeUser.users.length &&
        brazeUser.users.find((user) => user.external_id === uid)?.email_subscribe === 'subscribed',
    }
  }

  public static logFirstActionToBraze = async (
    attrName?: BrazeCustomAttributes | null,
    uid?: string,
    email_address?: string | null
  ) => {
    if (!attrName || !uid || !email_address) return

    const brazeUser = braze.getUser()

    if (!brazeUser) return

    braze.getUser()?.setCustomUserAttribute(attrName, true)
  }
}
