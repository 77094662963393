import { styled } from '@mui/material'

export const Styled = {
  Root: styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey['200'],
    borderRadius: 16,
    bottom: 16,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    left: 16,
    overflow: 'hidden',
    right: 16,
    position: 'fixed',
    display: 'flex',
    flexFlow: 'column',
  })),
  Wrapper: styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
  })),
}
