import { en } from 'lang/en'
import { pl } from 'lang/pl'
import { selector } from 'recoil'

import { Languages } from 'constants/Languages'
import { RecoilKeys } from 'constants/RecoilKeys'
import { locale } from 'state/locale/atoms/locale'
import { ILangMap } from 'types/Locale'

export const translate = selector<ILangMap>({
  get: ({ get }) => {
    const language = get(locale)

    switch (language) {
      case Languages.Polski:
        return pl
      case Languages.English:
        return en
      default:
        return pl
    }
  },
  key: RecoilKeys.translate,
})
