import React from 'react'

import { createSvgIcon } from '@mui/material/utils'

const PackageIcon = createSvgIcon(
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 17C21 17.38 20.79 17.71 20.47 17.88L12.57 22.32C12.41 22.44 12.21 22.5 12 22.5C11.79 22.5 11.59 22.44 11.43 22.32L3.53 17.88C3.21 17.71 3 17.38 3 17V8C3 7.62 3.21 7.29 3.53 7.12L11.43 2.68C11.59 2.56 11.79 2.5 12 2.5C12.21 2.5 12.41 2.56 12.57 2.68L20.47 7.12C20.79 7.29 21 7.62 21 8V17ZM12 4.65L10.11 5.72L16 9.11L17.96 8L12 4.65ZM6.04 8L12 11.35L13.96 10.25L8.08 6.85L6.04 8ZM5 16.41L11 19.79V13.08L5 9.71V16.41ZM19 16.41V9.71L13 13.08V19.79L19 16.41Z"
      fill="#111111"
    />
  </svg>,
  'Package'
)

export default PackageIcon
