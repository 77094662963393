import { connect } from 'react-redux'

import _Store from '@Store'

import compositionPage from 'models/meta/selectors/compositionPage'
import { IMeta } from 'models/meta/types'

import Helmet from './../../Helmet'

const mapStateToProps = (state: _Store.IState): IMeta => compositionPage(state)

export default connect<IMeta, void, { noIndex?: boolean }, _Store.IState>(mapStateToProps)(Helmet)
