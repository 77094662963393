import React, { useContext } from 'react'

import { Formik } from 'formik'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import FormLayout from './../components/FormLayout'
import { makeValidationSchema } from './Onsite.selectors'
import { IBuyingOnsiteProps, IBuyingOnsiteValues } from './Onsite.types'

const Onsite = ({ buyAndPay, initialValues }: IBuyingOnsiteProps) => {
  const { i18n } = useDictionary()
  const validationSchema = makeValidationSchema(i18n)

  const state = useContext(clearContext)

  const catchSaveData = (data: IBuyingOnsiteValues) => {
    buyAndPay({
      basketItems: state?.basketItems ? state.basketItems : [],
      data,
      onDone: state?.clearBasket ? state.clearBasket : () => null,
    })
  }

  return (
    <Formik
      component={FormLayout}
      initialValues={initialValues}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
    />
  )
}

export default Onsite
