import React from 'react'

import { Divider, Typography } from '@mui/material'

import { Flex } from 'components/_layout/Flex'
import { Avatars } from 'components/reusable/Avatars'

import { IStatsCardProps } from './StatsCard.types'

export const StatsCard = ({
  title,
  value,
  subtitle,
  isAvatar,
  startIcon,
  withoutDivider,
}: IStatsCardProps) => (
  <>
    <Flex align={'center'}>
      <Typography noWrap fontWeight={500} variant={'caption'} color={'text.primary'}>
        {title}
      </Typography>
      <Typography variant={'h5'} color={'text.primary'} noWrap>
        <Flex direction={'row'} gap={1} align={'center'}>
          {startIcon} {value}
        </Flex>
      </Typography>
      {!isAvatar ? (
        <Typography noWrap fontWeight={500} variant={'caption'} color={'text.secondary'}>
          {subtitle}
        </Typography>
      ) : (
        <Avatars length={Number(value)} />
      )}
    </Flex>

    {!withoutDivider && <Divider orientation={'vertical'} flexItem />}
  </>
)
