import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import config from 'config'
import { updateProductData } from 'models/products/actions'
import { getSelectedProducts } from 'models/products/selectors'

import Product from './Product.component'
import { IProductFromDispatch, IProductFromState } from './Product.types'

const mapStateToProps = (state: _Store.IState): IProductFromState => ({
  productsLimit: config.buy.maxTicketsCount,
  selectedProducts: getSelectedProducts(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IProductFromDispatch => ({
  updateProductData: (values) => dispatch(updateProductData(values)),
})

export default connect<IProductFromState, IProductFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Product)
