import { getType } from 'typesafe-actions'

import { getShipmentDelivery } from './../actions'
import { IAction, IShipmentReducer } from './../types'

const initialState: IShipmentReducer = []

const reducer = (state: IShipmentReducer = initialState, action: IAction): IShipmentReducer => {
  switch (action.type) {
    case getType(getShipmentDelivery.success):
      return action.payload

    default:
      return state
  }
}

export default reducer
