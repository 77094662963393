import { ComponentType } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  IconProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material'

interface IStyleProps {
  isDesktop: boolean
}

export const Styled = {
  Root: styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
  })<IStyleProps>(({ theme, isDesktop }) => ({
    display: 'flex',
    flexFlow: isDesktop ? 'row nowrap' : 'column',
    padding: theme.spacing(4),
    borderRadius: 16,
    alignItems: 'center',
    gap: theme.spacing(4),
    justifyContent: isDesktop ? 'space-between' : 'center',
  })) as ComponentType<PaperProps & IStyleProps>,

  Description: styled(Box)({ flex: 1 }) as ComponentType<BoxProps>,

  Price: styled(Typography)({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  }) as ComponentType<TypographyProps>,

  InfoIcon: styled(InfoOutlined)(({ theme }) => ({
    marginLeft: theme.spacing(2),
  })) as ComponentType<IconProps>,
}
