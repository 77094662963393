import React, { FC } from 'react'

import { Button, useTheme } from '@mui/material'

import config from 'config'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './SimpleCheckbox.module.scss'
import { useStyles } from './SimpleCheckbox.styles'
import { ISimpleCheckboxProps } from './SimpleCheckbox.types'

const SimpleCheckbox: FC<ISimpleCheckboxProps> = ({
  checked,
  children,
  hideCheck,
  showChooseButon,
}) => {
  const { i18n } = useDictionary()
  const hideCheckbox = hideCheck || showChooseButon
  const theme = useTheme()
  const sxStyles = useStyles(theme)({})

  return (
    <div className={styles.simpleCheckbox}>
      <label className={styles.label}>
        <input checked={checked} type="checkbox" readOnly={true} />
        {!hideCheckbox && <span className={styles.selector} />}
        {children}
      </label>
      {showChooseButon && (
        <Button
          variant={!checked ? 'outlined' : 'contained'}
          color={'secondary'}
          size={'large'}
          disableElevation={true}
          fullWidth={true}
          sx={sxStyles.chooseButton}
        >
          {!checked ? i18n.formik.checkbox.choose : i18n.formik.checkbox.chosen}
        </Button>
      )}
    </div>
  )
}

export default SimpleCheckbox
