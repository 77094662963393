import { createSelector } from 'reselect'

import _Store from '@Store'

import { IArticleFull, IArticleReducerCurrentKey, IArticleReducerData } from 'types/Article'

import getCurrentKey from './getCurrentKey'
import getData from './getData'

const getArticle = createSelector<
  _Store.IState,
  IArticleReducerCurrentKey,
  IArticleReducerData,
  IArticleFull | null
>([getCurrentKey, getData], (key, data) => {
  if (key && key in data) {
    return data[key] as IArticleFull
  }

  return null
})

export default getArticle
