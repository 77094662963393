import * as React from 'react'

import { Event, EventBusyOutlined, Favorite, FavoriteBorder } from '@mui/icons-material'
import { Button, Divider, IconButton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { Styled } from 'components/ActionBar/ActionBar.styles'
import { ActionBarType, IActionBarProps } from 'components/ActionBar/ActionBar.types'
import { SocialShare } from 'components/SocialShare'
import { BuyButton } from 'components/reusable/BuyButton'
import config from 'config'
import { HitFields } from 'constants/CloudSearch'
import { DateTime } from 'services/DateTime'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { IActivityResponse } from 'types/EventActivity'
import { IRundateResponse } from 'types/EventRundate'
import { IPlaceResponse } from 'types/Place'
import { ProfileType } from 'types/Profile'

import { FavoritesTypes } from '../../constants/Favorites'
import { useFavoritesContext } from '../favorites/FavoritesProvider'
import { useEditFavorites } from '../favorites/FavoritesProvider/useEditFavorites'

const AnimatedWrapper = animated(Styled.Root)

const Rundate = ({ data }: { data: IRundateResponse }) => {
  const { i18n } = useDictionary()
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isRundateInFavs = isItemInFavs(data.id, FavoritesTypes.Rundates)
  const isArtistInFavs = !!data.event.artists?.[0]
    ? isItemInFavs(data.event.artists[0].id, FavoritesTypes.Artists)
    : false

  return (
    <>
      {!data.isAvailable && (
        <Typography
          sx={{ p: 1 }}
          variant={'caption'}
          children={
            !!data.saleStartDate
              ? i18n.actionBar.salesStart(
                  DateTime.getFriendlyDate({
                    isoDate: DateTime.getIsoDateString(data.saleStartDate),
                    dateTranslate: i18n.dates,
                    removeWeekDay: true,
                  }),
                  DateTime.getFriendlyTime(data.saleStartDate)
                )
              : i18n.actionBar.noSales
          }
          align={'center'}
          color={'secondary'}
        />
      )}
      <Styled.Wrapper>
        {!data.isAvailable && !!data.event.artists?.[0] ? (
          <Button
            fullWidth
            color={'secondary'}
            children={isArtistInFavs ? i18n.following.unfollow : i18n.following.artistHeader}
            startIcon={isArtistInFavs ? <Favorite /> : <FavoriteBorder />}
            onClick={handleEditFavorites({
              isFollowed: isArtistInFavs,
              type: FavoritesTypes.Artists,
              externalId: data.event.artists[0].id,
              itemName: data.event.artists[0].name,
            })}
          />
        ) : (
          <BuyButton
            sx={{ borderRadius: 0, minHeight: 64 }}
            eventSlug={data.event.slug}
            rundateSlug={data.slug}
            redirectToUrl={data.redirectToUrl}
            queue={data.queue}
            disabled={!data.isAvailable}
            label={data.buttonLabel}
            fullWidth
          />
        )}
        <Divider orientation={'vertical'} flexItem />
        <IconButton
          children={isRundateInFavs ? <EventBusyOutlined /> : <Event />}
          onClick={handleEditFavorites({
            isFollowed: isRundateInFavs,
            type: FavoritesTypes.Rundates,
            externalId: data.id,
            itemName: data.event.title,
          })}
        />
        <SocialShare
          title={data.event.title}
          url={`${config.app.baseUrl}${prepareRoute({
            route: Route.rundate,
            params: { eventSlug: data.event.slug, rundateSlug: data.slug },
          })}`}
        />
      </Styled.Wrapper>
    </>
  )
}

const Activity = ({ data }: { data: IActivityResponse }) => {
  const { i18n, getExternalKey } = useDictionary()

  return (
    <Button
      sx={{ borderRadius: 0, minHeight: 64 }}
      component={Link}
      variant={'contained'}
      size={'large'}
      children={getExternalKey('buyButton') || i18n.buttons.buyTicket}
      to={prepareRoute({
        route: Route.happening,
        params: { slug: data.slug },
      })}
      fullWidth
    />
  )
}

const Place = ({ data }: { data: IPlaceResponse }) => {
  const { i18n } = useDictionary()
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isPlaceInFavs = isItemInFavs(data.id, FavoritesTypes.Places)

  return (
    <>
      <Button
        sx={{ borderRadius: 0, minHeight: 64 }}
        component={Link}
        variant={'contained'}
        size={'large'}
        children={i18n.buttons.tickets}
        to={prepareRoute({
          route: Route.search,
          params: undefined,
          query: {
            [HitFields.place_name]: data.name,
          },
        })}
        fullWidth
      />
      <IconButton
        children={isPlaceInFavs ? <Favorite /> : <FavoriteBorder />}
        onClick={handleEditFavorites({
          isFollowed: isPlaceInFavs,
          type: FavoritesTypes.Places,
          externalId: data.id,
          itemName: data.name,
        })}
      />
      <SocialShare
        title={data.name}
        url={`${config.app.baseUrl}${prepareRoute({
          route: Route.profile,
          params: { type: ProfileType.place, slug: data.slug },
        })}`}
      />
    </>
  )
}

export const ActionBar = ({ data, type }: IActionBarProps) => {
  const animation = useSpring({
    bottom: !!data ? 16 : -100,
    delay: 500,
  })

  if (!data) return null

  const getComponent = () => {
    switch (type) {
      case ActionBarType.ACTIVITY:
        return <Activity data={data as IActivityResponse} />
      case ActionBarType.EVENT:
        return <Rundate data={data as IRundateResponse} />
      case ActionBarType.PLACE:
        return <Place data={data as IPlaceResponse} />
      default:
        return null
    }
  }

  return <AnimatedWrapper style={animation}>{getComponent()}</AnimatedWrapper>
}
