import { ValidationRules } from 'components/_forms/_validators/types'

export const repeatPasswordValidator = (
  requiredMsg: string,
  correctMsg: string,
  password: string
): ValidationRules => ({
  required: {
    value: true,
    message: requiredMsg,
  },
  validate: (value: string) => value === password || correctMsg,
})
