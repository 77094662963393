import { ICarouselBrickOwnProps } from 'components/Bricks/components/CarouselBrick/CarouselBrick.types'
import { ICarouselFixedImage } from 'components/reusable/CarouselFixed/CarouselFixed.types'

import { ISectionsCarouselImage, ISectionsComponentDataMutable } from '../types/sections'

export const carousel = (data: ISectionsComponentDataMutable): ICarouselBrickOwnProps => {
  const imagesData = data as ISectionsCarouselImage[]
  const imagesMutation: ICarouselFixedImage[] = []

  imagesData.map((image: ISectionsCarouselImage, index) =>
    imagesMutation.push({
      alt: image.alt ? image.alt : index.toString(),
      link: image.link,
      squareImage: image.thumbUrl,
      title: image.title,
      wideImage: image.src,
    })
  )

  return {
    images: imagesMutation,
  }
}
