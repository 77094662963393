import { IRouteMatchParams } from 'router/types'

import { Languages } from 'constants/Languages'
import { useLang } from 'state/locale/hooks/useLang'

export const useLocalizedRoute = () => {
  const { code } = useLang()

  return {
    localizeRoute: (plRoute: keyof IRouteMatchParams, enRoute: keyof IRouteMatchParams) =>
      code === Languages.Polski ? plRoute : enRoute,
  }
}
