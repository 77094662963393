import { connect } from 'react-redux'

import _Store from '@Store'

import { buyPage } from 'models/meta/selectors'
import { IMeta } from 'models/meta/types'

import Helmet from './../../Helmet'

const mapStateToProps = (state: _Store.IState): IMeta => buyPage(state)

export default connect<IMeta, void, { noIndex?: boolean }, _Store.IState>(mapStateToProps)(Helmet)
