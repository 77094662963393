import { createHash } from 'crypto'

import { ITransactionItem } from 'services/$analytics/types'

export interface ITradeDoublerScriptArgs {
  orderNumber: number
  orderValue: number
  rundateId: number | string
  transactionItems: ITransactionItem[]
  userEmail: string
  voucher?: string
}
export const assembleTradeDoublerScript = ({
  orderNumber,
  orderValue,
  rundateId,
  transactionItems,
  userEmail,
  voucher,
}: ITradeDoublerScriptArgs) => {
  const hashedEmail = createHash('sha256').update(userEmail).digest('hex')
  const reportInfo = (items: ITransactionItem[]): string => {
    let returnString = ''

    items.map((item, index) => {
      let escapedPName = ''
      const splitPName = item.productName.split('|')
      splitPName.map((substring) => {
        escapedPName += substring
      }) // remove pipe character from event title

      const itemString = `f1=${rundateId}&f2=${escapedPName}&f3=${item.price}&f4=${item.quantity}`

      returnString += itemString
      if (index !== items.length - 1) {
        returnString += '|'
      }
    })

    return returnString
  }

  return `
  var orderNumber = "${orderNumber.toString()}";
  var orderValue = "${orderValue.toString()}";
  var currency = "PLN";
  var reportInfo = "${reportInfo(transactionItems)}";
  var extid = "${hashedEmail}";
  var exttype = "1";
  var voucher = "${voucher}";
  var event = 414552;
  var organization = 2295986;
  reportInfo = encodeURIComponent(reportInfo);
  function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
  begin = dc.indexOf(prefix);
  if (begin != 0) return null;
} else {
  begin += 2;
}
  var end = document.cookie.indexOf(";", begin);
  if (end == -1) {
  end = dc.length;
}
  return unescape(dc.substring(begin + prefix.length, end));
}
  var tduid = getCookie("TRADEDOUBLER");
  /***** IMPORTANT: *****/
  /***** Please consult Tradedoubler before modifying the code below this line. *****/
  //
  var trackBackUrl = "https://tbs.tradedoubler.com/report" + "?organization=" + organization + "&event=" + event + "&orderNumber=" + orderNumber + "&orderValue=" + orderValue + "&currency=" + currency + "&voucher=" + voucher + "&tduid=" + tduid + "&extid=" + extid + "&exttype=" + exttype + "&type=iframe&reportInfo=" + reportInfo;
  //
  function prepareFrame(tburl){
  ifrm = document.createElement("IFRAME");
  ifrm.setAttribute("src", tburl);
  ifrm.style.width = 1+"px";
  ifrm.style.height = 1+"px";
  ifrm.style.border = "none";
  document.body.appendChild(ifrm);
}
  prepareFrame(trackBackUrl);
  //
  (function() {
  if (tduid) {
  var img = document.createElement('img');
  img.src = 'https://imgstatic.eu/report?o=' + organization + '&e=' + event + '&ordnum=' + orderNumber + '&ordval=' + orderValue + '&curr=' + currency + '&tduid=' + tduid + '&reportInfo=' + reportInfo;
  document.body.appendChild(img);
}
})();
`
}
