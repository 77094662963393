import { createSelector } from 'reselect'

import _Store from '@Store'

import { ICheckEmailResponse } from 'services/$sendgrid-api/types'

import { ISendgridReducer } from './../types'
import get from './get'

const getCheckEmail = createSelector<_Store.IState, ISendgridReducer, ICheckEmailResponse | null>(
  [get],
  (state) => state.checkEmail
)

export default getCheckEmail
