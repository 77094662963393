import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import _Store from '@Store'

import isEmptyObject from 'misc/helpers/isEmptyObject'

export const useIsEmbedFromStore = () =>
  useSelector(
    createSelector([(state: _Store.IState) => state.internalRouter.embed], (embed) => ({
      isEmbed: !!(isEmptyObject(embed) ? null : embed),
    }))
  )
