import { connect } from 'react-redux'

import _Store from '@Store'

import { getModule } from 'models/internalRouter/selectors'

import Brand from './Brand.component'

const mapStateToProps = (state: _Store.IState) => ({
  module: getModule(state),
})

export default connect(mapStateToProps)(Brand)
