import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

import useSWR from 'swr'

import { LocationsDialog } from 'components/_functional/LocationContextProvider/LocationsDialog'
import { useUser } from 'components/_functional/UserContextProvider'
import { StorageKeys } from 'constants/StorageKeys'
import { values } from 'constants/Values'
import { useToggle } from 'misc/hooks/useToggle'
import { LocalStorage } from 'services/LocalStorage'
import { URI } from 'services/URI'
import { UserApi } from 'services/UserApi'
import { IBasicLocation, ILocation } from 'types/Locations'

import { useMaintenance } from '../MaintenanceModeProvider'
import { ILocationContext } from './LocationContextProvider.types'

export const initialState: ILocationContext = {
  userLocation: LocalStorage.get<IBasicLocation>(StorageKeys.UserLocation) ?? {
    id: 1,
    slug: 'warszawa',
    name: 'Warszawa',
  },
  setLocation: values.noop,
  locations: [],
  isLoading: true,
  dialog: {
    isVisible: false,
    close: values.noop,
    open: values.noop,
  },
}

const LocationsContext = createContext(initialState)

export const useLocations = () => useContext(LocationsContext)

export const LocationsContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { isMaintenance } = useMaintenance()
  const { data, isValidating } = useSWR<ILocation[]>(!isMaintenance && URI.GetLocations, {
    keepPreviousData: true,
  })
  const { goingMetadata, firebaseUser, refreshUser } = useUser()
  const [userLocation, setUserLocation] = useState<IBasicLocation>(initialState.userLocation)
  const { isVisible, handleHide, handleShow } = useToggle()

  useEffect(() => {
    LocalStorage.set(StorageKeys.UserLocation, userLocation)
    if (!!goingMetadata && !!firebaseUser && goingMetadata.location?.id !== userLocation?.id) {
      ;(async () => {
        await UserApi.updateApiUser({ locationId: userLocation.id }, firebaseUser)
        await refreshUser()
      })()
    }
  }, [userLocation?.id])

  useEffect(() => {
    if (goingMetadata?.location) setUserLocation(goingMetadata.location)
  }, [goingMetadata?.location.id])

  return (
    <LocationsContext.Provider
      value={{
        isLoading: isValidating,
        locations: data ?? [],
        userLocation,
        setLocation: (location) => setUserLocation(location),
        dialog: {
          isVisible,
          close: handleHide,
          open: handleShow,
        },
      }}
    >
      {children}
      <LocationsDialog
        isVisible={isVisible}
        onClose={handleHide}
        locations={data}
        setLocation={(location) => {
          setUserLocation(location)
          handleHide()
        }}
        currentLocationID={userLocation.id}
      />
    </LocationsContext.Provider>
  )
}
