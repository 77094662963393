import { connect } from 'react-redux'

import _Store from '@Store'

import { getSelectedTickets } from 'models/pools/selectors'

import AdditionalField from './AdditionalField.component'
import { IAdditionalFieldFromState, IAdditionalFieldOwnProps } from './AdditionalField.types'

const mapStateToProps = (state: _Store.IState): IAdditionalFieldFromState => ({
  selectedTickets: getSelectedTickets(state),
})

export default connect<IAdditionalFieldFromState, void, IAdditionalFieldOwnProps, _Store.IState>(
  mapStateToProps
)(AdditionalField)
