import { RefObject, useEffect, useRef, useState } from 'react'

export const useInfiniteScroll = (
  containerRef: RefObject<HTMLElement>,
  callback: () => void,
  threshold = 300,
  throttle = 150
) => {
  const [loading, setLoading] = useState(false)
  const callbackRef = useRef(callback)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const handleScroll = () => {
    const currentRef = containerRef.current
    if (!currentRef || loading) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(async () => {
      const windowHeight =
        'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight
      const boxRect = currentRef.getBoundingClientRect()
      const boxBottomPosition = boxRect.bottom

      if (boxBottomPosition - threshold <= windowHeight) {
        setLoading(true)
        await callbackRef.current()
        setLoading(false)
      }
    }, throttle)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [loading])
}
