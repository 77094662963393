import { getType } from 'typesafe-actions'

import { setUserAgent } from './../../actions'
import * as CONSTS from './../../constants/constants'
import { IAction, IReducerAgent } from './../../types'

const initialState: IReducerAgent = CONSTS.UNDEFINED

const reducer = (state: IReducerAgent = initialState, action: IAction): IReducerAgent => {
  switch (action.type) {
    case getType(setUserAgent):
      return action.payload

    default:
      return state
  }
}

export default reducer
