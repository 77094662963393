import { regExp } from 'constants/RegExp'

/**
 * Function extracts YouTube ID from an url.
 * Returns the id if it already matches pattern or null if there is no url or match.
 *
 * @param source
 */
export const extractYoutubeID = (source?: string): string | null => {
  if (!source) return null

  const matches = source.match(regExp.youtubeId)

  if (matches) {
    return matches[1]
  } else if (source.length === 11 && !source.includes(' ')) {
    return source
  }

  return null
}
