import React from 'react'

import { useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { Breadcrumbs } from 'components/Breadcrumbs'
import Loading from 'components/Bricks/components/Loading'
import { Selection } from 'components/Selection'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/Helmet'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { ISelectionResponse } from 'types/Selection'

export const SelectionPage = () => {
  const { slug } = useParams<RouteParams<Route.selection>>()
  const { i18n } = useDictionary()
  const { data, isValidating } = useSWR<ISelectionResponse>(!!slug && URI.getSelection(slug))

  return (
    <PageWrapper>
      {!!data && (
        <Helmet
          pageName={[data.title, i18n.meta.selection]}
          description={[data.description, i18n.meta.selection]}
          ogDescription={[data.description, i18n.meta.selection]}
          currentUrl={window?.location.href}
        />
      )}
      <Breadcrumbs
        base={[{ label: i18n.breadcrumbs.selections, link: routes[Route.index] }]}
        current={data?.title}
        isLoading={isValidating}
      />
      {isValidating || !data ? <Loading /> : <Selection data={data} />}
    </PageWrapper>
  )
}
