const groupBy = <K, V>(list: V[], keyGetter: (input: V) => K): Map<K, V[]> => {
  const map = new Map<K, V[]>()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })

  return map
}

export default groupBy
