import React, { FC, MouseEvent } from 'react'

import DualLink from 'components/reusable/DualLink'
import slugifyString from 'misc/helpers/slugifyString'
import Analytics from 'services/$analytics'

import styles from './Banner.module.scss'
import { IBannerProps } from './Banner.types'

const Banner: FC<IBannerProps> = ({ id, link, title, compositionIdentifier }) => {
  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    Analytics.pushForHomepageSelectItem(e, link, title, title)

  return (
    <div className={styles.wrapper} id={id}>
      <DualLink
        className={`${compositionIdentifier}-${id}-${slugifyString(title)}`}
        to={link}
        onClick={handleAddToDataLayerOnClick}
      >
        {title}
      </DualLink>
    </div>
  )
}

export default Banner
