import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.text.primary,
      // @ts-ignore TODO
      fontWeight: theme.typography.fontWeightBold,
    },
  })
)

export default useStyles
