import { useSelector } from 'react-redux'

import _Store from '@Store'

import { IAvailableThemes } from 'models/theme/types'

export const useThemeFromStore = () =>
  useSelector<_Store.IState, IAvailableThemes>((store) => {
    return store.theme
  })
