import { useState } from 'react'

import { interpolate } from 'flubber'

const interpolators: Array<(t: number) => string> = []

/**
 * Custom hook morphing svg paths in an array
 *
 * paths - array of svg path strings
 * smoothness - optional accuracy - smaller the number - smoother the morph and worse performance (default 0.1)
 *
 * @return { number, function, function }
 * index - path index number,
 * interpolator - function returning in-between states of morphed svg paths from passed in 0-1 numbers,
 * next - function progressing index and passing next path set to the interpolator
 * @param paths
 * @param smoothness
 */
const useSvgInterpolation = (paths: string[], smoothness: number | undefined = 0.1) => {
  const [index, runIndex] = useState(0)

  for (let i = 0; i < paths.length; i++) {
    interpolators.push(
      interpolate(paths[i], paths[i + 1] || paths[0], {
        maxSegmentLength: smoothness,
      })
    )
  }

  const next = () => runIndex(index + 1 >= paths.length ? 0 : index + 1)

  const interpolator = interpolators[index]

  return { index, interpolator, next }
}

export default useSvgInterpolation
