import FileDownload from 'js-file-download'

import { Fetcher } from 'services/Api/Fetcher'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import {
  IReturnTicketPayload,
  IReturnTicketResponse,
  ITransferCancelPayload,
  ITransferCancelResponse,
  ITransferTicketPayload,
  ITransferTicketResponse,
} from 'types/TicketManagement'

export enum TicketActions {
  CANCEL_TRANSFER = 'cancel_transfer',
  CHANGE_TIME = 'change_entry_time',
  HISTORY = 'show_history',
  RETURN = 'request_return_ticket',
  TRANSFER = 'start_transfer',
}

export enum TicketActionErrors {
  INTERNAL_ERROR = 'internal_error',
  INVALID_EMAIL = 'invalid_email',
  INVALID_TOKEN = 'invalid_token',
  INVALID_ACTION = 'invalid_action',
  UNKNOWN_ERROR = 'unknown_error',
}

// TODO: add other statuses
export enum TicketTransferStatus {
  PENDING = 'pending',
  CANCELED = 'canceled',
}

export enum TicketRefundOptions {
  TRANSFER = 'transfer',
  VOUCHER_GOING = 'voucherGoing',
  VOUCHER_EMPIK = 'voucherEmpik',
}

export class TicketManagementApi {
  public static downloadTicket = async (ticketId: string | number, code: string, email: string) => {
    try {
      const response = await fetch(URI.downloadTicket(ticketId, code, email))
      const blob = await response.blob()
      FileDownload(blob, 'bilet.pdf')
    } catch (e) {
      Logger.error('TicketManagementApi.downloadTicket', e)
    }
  }

  public static transferTicket = async (
    payload: ITransferTicketPayload
  ): Promise<ITransferTicketResponse> => {
    return await Fetcher.post<ITransferTicketResponse>(
      URI.transferTicket(),
      JSON.stringify(payload)
    )
  }

  public static transferCancel = async (
    payload: ITransferCancelPayload
  ): Promise<ITransferCancelResponse> => {
    return await Fetcher.delete(URI.transferTicket(), JSON.stringify(payload))
  }

  public static returnTicket = async (
    payload: IReturnTicketPayload
  ): Promise<IReturnTicketResponse> => {
    return await Fetcher.post(URI.returnTicket(), JSON.stringify(payload))
  }
}
