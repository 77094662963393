import { pl } from 'lang/pl'
import { base64decode } from 'nodejs-base64'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import { isRouteMatch } from 'router/utils/isRouteMatch'
import { prepareRoute } from 'router/utils/prepareRoute'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import config from 'config'
import { StorageKeys } from 'constants/StorageKeys'
import convertBinaryToUnicode from 'misc/helpers/convertBinaryToUnicode'
import fillRundateData from 'misc/helpers/fillRundateData'
import { informAboutBuyingProcess } from 'models/analytics/actions'
import { getLocation } from 'models/connectedRouter/selectors'
import { getImages, loadImages } from 'models/images/actions'
import { locationChange, navigate } from 'models/internalRouter/actions'
import { getIframeParams, getModule, getParams } from 'models/internalRouter/selectors'
import { fetchUrlStructure } from 'models/pages/actions'
import { getPools } from 'models/pools/actions'
import { LocalStorage } from 'services/LocalStorage'
import { IRundateEventResponse, IRundateResponse } from 'types/EventRundate'
import { isActionOf } from 'typesafe-actions'

import { buyFormMounted, eventPageMounted, getEvent, getRundateSlug } from './../actions'
import { IGetEventByIdSuccessPayload } from './../types'

export const getEventWhenMounted: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(eventPageMounted)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const { eventSlug, rundateSlug } = getParams(state)

      return eventSlug !== ':eventSlug' && rundateSlug !== ':rundateSlug'
    }),
    filter$(([_, state]) => !isRouteMatch(routes[Route.eventLegacy], getLocation(state).pathname)),
    mergeMap$(([_, state]) => {
      return [getEvent.request(), fetchUrlStructure()]
    })
  )

export const redirectEventWhenLocationChange: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] === routes[Route.eventLegacy].split('/')[1]
    ),
    mergeMap$(([_, state]) =>
      of$(getRundateSlug.request(Number(getLocation(state).pathname.split('/')[2])))
    )
  )

export const getRundateSlugWhenRequest: _Store.IEpic = (action$, state$, { eventsApi }) =>
  action$.pipe(
    filter$(isActionOf(getRundateSlug.request)),
    mergeMap$((action) =>
      from$(eventsApi.getEventById(action.payload)).pipe(
        map$((data: IGetEventByIdSuccessPayload) => getRundateSlug.success(data)),
        catchError$((error: Error) =>
          of$(getRundateSlug.failure(error), navigate({ to: routes[Route.search] }))
        )
      )
    )
  )

export const redirectEventWhenRundateSlugSuccess: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getRundateSlug.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const params = {
        eventSlug: action.payload.event.slug,
        rundateSlug: action.payload.slug,
      }
      const module = getModule(state)
      const isEmbed = getIframeParams(state)

      if (isEmbed) {
        return of$(
          locationChange({ module, params }),
          loadImages(),
          getPools.request(params),
          getEvent.request()
        )
      }

      return of$(
        navigate({
          to: prepareRoute({
            route: Route.rundate,
            params: {
              eventSlug: params.eventSlug,
              rundateSlug: params.rundateSlug,
            },
          }),
        })
      )
    })
  )
}

export const fetchEventWhenRequested: _Store.IEpic = (action$, state$, { eventsApi }) =>
  action$.pipe(
    filter$(isActionOf(getEvent.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const params = getParams(state) as RouteParams<Route.rundate>

      if ('eventSlug' in params) return !!params.eventSlug && !!params.rundateSlug
      return 'id' in params || 'slug' in params
    }),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as RouteParams<
        Route.rundate | Route.activity | Route.eventLegacy | Route.happening
      >
      const translateDate = pl.dates

      if (params) {
        return from$(
          'eventSlug' && 'rundateSlug' in params
            ? eventsApi.getSingleEvent(params.eventSlug, params.rundateSlug)
            : 'id' in params
            ? eventsApi.getEventById(parseInt(params.id, 10))
            : 'slug' in params
            ? eventsApi.getSingleEvent(params.slug)
            : eventsApi.getSingleEvent(params.eventSlug)
        ).pipe(
          mergeMap$((data: IRundateResponse | IRundateEventResponse) => {
            if ('event' in data) {
              const normalizedEvent = eventsApi.normalizeFullEvent(data, translateDate)
              const normalizedEventKey = eventsApi.makeKeyFromParams(normalizedEvent)
              const module = getModule(state)
              const key = `${config.images.placePrefix}${data.place.slug}`

              if (module === Route.rundate || module === Route.pinRundate) {
                return [
                  getEvent.success({
                    event: normalizedEvent,
                    key: normalizedEventKey,
                  }),
                  getImages.request(key),
                ]
              } else {
                return [
                  getEvent.success({
                    event: normalizedEvent,
                    key: normalizedEventKey,
                  }),
                ]
              }
            } else {
              return [
                getEvent.success({
                  event: fillRundateData(data),
                  key: data.slug,
                }),
              ]
            }
          }),
          takeUntil$(
            action$.pipe(
              filter$(isActionOf(locationChange)),
              tap$(() => eventsApi.cancelEvent())
            )
          ),
          catchError$((error: Error) => of$(getEvent.failure(error)))
        )
      }

      return EMPTY$
    }),
    catchError$((error: Error) => of$(getEvent.failure(error)))
  )

export const useAnalyticsWhenBuyFormMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([buyFormMounted, getEvent.success])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const module = getModule(state)

      if (module === Route.buy || module === Route.pinBuy) {
        return of$(informAboutBuyingProcess())
      }

      return EMPTY$
    })
  )
}

export const fillBuyFormFromUri: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([buyFormMounted])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const uri = getLocation(state)

      if (uri?.query?.data) {
        try {
          const dataEncoded = base64decode(uri.query.data)
          let values

          /*
           *
           * Below: If data param comes from new mobile app (isWebview === 'true' - we have to check for a string,
           * not boolean), we need to convert binary string to unicode, after we decode base64 string. This is because
           * of the fact that mobile app converts the data string from unicode to binary before base64 encoding
           * (due to JS limitations).
           * If data param comes from the "Expo" form, we don't do that - otherwise the app would crash (and vice versa).
           *
           */

          if (uri.query.isWebview === 'true') {
            values = convertBinaryToUnicode(dataEncoded)
          } else {
            values = dataEncoded
          }

          LocalStorage.set(StorageKeys.BuyForm, JSON.parse(values))
        } catch (noSSR) {}
      }

      return EMPTY$
    })
  )
}
