import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `0.1875rem solid ${theme.palette.other.gray}`,
      borderRadius: theme.border.radius,
      padding: '0.375rem 0.5rem',
      width: 'fit-content',
    },
    content: {
      color: theme.palette.other.gray,
    },
  })
)

export default useStyles
