/**
 * Function to check if value is null or undefined
 *
 * @param {number | string | null | undefined} value - value to check
 *
 * @return {boolean} true if the value is undefined or null
 *
 */
const isNullOrUndefined = (value: number | string | null | undefined): boolean =>
  value === null || typeof value === 'undefined'

export default isNullOrUndefined
