import React from 'react'

import { Skeleton } from '@mui/material'
import qs from 'qs'
import { useLocation, useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { EventModal } from 'components/EventModal'
import { EventApi } from 'services/Api/EventApi'
import { useLang } from 'state/locale/hooks/useLang'

export const EventModalEmbed = () => {
  const { search } = useLocation()
  const { eventSlug, rundateSlug } = useParams<RouteParams<Route.pinEventModal>>()
  const { code } = useLang()
  const { data, isLoading } = useSWR([eventSlug, rundateSlug, code], EventApi.getFullRundate, {})
  const parsedGetParams = qs.parse(search, { ignoreQueryPrefix: true })

  if (!data?.eventData) return null

  if (isLoading) return <Skeleton />

  return (
    <EventModal
      eventData={data.eventData}
      cloudinaryImages={data.cloudinaryImages}
      params={parsedGetParams}
    />
  )
}
