import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolsSummary } from 'models/pools/types'

import { ITicketReceiveData } from '../types'
import getReceivedTicketData from './getReceivedTicket'

const getReceivedSummary = createSelector<
  _Store.IState,
  ITicketReceiveData | null,
  IPoolsSummary | null
>([getReceivedTicketData], (receivedTicket) => {
  if (!receivedTicket || !receivedTicket.pool) {
    return null
  }

  const amount = receivedTicket.ticketNum
  const pool = receivedTicket.pool.pools[0]
  const summedPrice = pool ? pool.price * amount : 0
  const serviceFee = pool ? pool.serviceFee * amount : 0

  return {
    amount,
    finalPrice: summedPrice + serviceFee,
    serviceFee,
    summedPrice,
  }
})

export default getReceivedSummary
