import React, { FC, createRef, useEffect } from 'react'

import styles from './RedirectForSale.module.scss'
import { IRedirectForSaleProps } from './RedirectForSale.types'

const formFields = [
  'transactionId',
  'totalQuantityCount',
  'userId',
  'urlTransactionSuccess',
  'urlTransactionFail',
  'urlTransactionCancel',
  'totalValue',
  'saleCodeVat8',
  'serviceFeeValue',
]

const RedirectForSale: FC<IRedirectForSaleProps> = ({ data, isPaymentAvailable }) => {
  const formRef = createRef<HTMLFormElement>()

  useEffect(() => {
    if (isPaymentAvailable && formRef.current) {
      formRef.current.submit()
    }
  })

  return (
    <form
      action={data.redirect}
      className={styles.form}
      method="post"
      target="_parent"
      ref={formRef}
      encType="application/x-www-form-urlencoded"
    >
      {formFields.map((key) => (
        <input type="hidden" name={key} key={key} value={data[key]} readOnly={true} />
      ))}
    </form>
  )
}

export default RedirectForSale
