import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = (reversed: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      cardContent: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 16,
      },
      followButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        position: 'absolute',
        marginTop: 16,
        left: reversed ? undefined : 16,
        right: reversed ? 16 : undefined,
        zIndex: 9,
      },
      reversed: {
        flexDirection: 'row-reverse',
      },
      root: {
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          textDecoration: 'none',
        },
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.border.radius,
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        transition: '0.5s',
      },
      title: {
        backgroundColor: 'inherit',
        padding: theme.spacing(5),
      },
      titleText: {
        color: theme.palette.grey[900],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    })
  )

export default useStyles
