import React from 'react'

import IframeResizer from 'iframe-resizer-react'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import styles from './EmbedFrame.module.scss'
import { IEmbedFrameProps } from './EmbedFrame.types'

const EmbedFrame = ({ embedSrc, isBrowser, title }: IEmbedFrameProps) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const desktopParam = `?isDesktop=${isDesktop}`
  const mobileParam = `&isMobile=${isMobile}`
  const tabletParam = `&isTablet=${isTablet}`

  return isBrowser && embedSrc ? (
    <div className={styles.iframeWrapper}>
      <IframeResizer
        heightCalculationMethod={'bodyScroll'}
        autoResize={true}
        checkOrigin={false}
        className={styles.iframe}
        frameBorder={0}
        scrolling={false}
        src={embedSrc + desktopParam + mobileParam + tabletParam}
        title={title}
      />
    </div>
  ) : null
}

export default EmbedFrame
