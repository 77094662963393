import { QueryTerm, fq, query } from 'lucene-query-builder'
import qs from 'qs'

import { CloudSearch, HitFields } from 'constants/CloudSearch'
import { URI } from 'services/URI'
import { ICloudSearchOptions, ICloudSearchSort, IHighlight, ISearchField } from 'types/CloudSearch'

export class LuceneQueryBuilder {
  public static and = (query: string): string => {
    return ` AND ${query}`
  }

  public static or = (queries: string[]): string => {
    return queries.join(' OR ')
  }

  public static not = (query: string): string => {
    return ` NOT ${query}`
  }

  /**
   * Function creates sorting query from sort config objects array.
   *
   * @param {ICloudSearchSort[]} sorting
   *
   * @return {string}
   */
  public static sort = (sorting: ICloudSearchSort[]): string => {
    return sorting
      .slice()
      .sort((a, b) => a.priority - b.priority)
      .map((sort) => `${sort.field} ${sort.sort}`)
      .join(', ')
  }

  /**
   * Returns search query fields q.option with additional boosting.
   *
   * @param {ISearchField[]} fields - array of ISearchFields objects.
   *
   * @return {string} return should be wrapped in .option method with 'q.option' parameter.
   */
  public static searchFieldsOption = (fields: ISearchField[]) => {
    const searchFields = fields.map((field) => `"${field.fieldName}^${field.boost}"`).toString()

    return `{"fields":[${searchFields}]}`
  }

  /**
   * Return fields query builder.
   *
   * @param {string[]} returnValues - list of fields to return. Use '_all_fields' to return everything.
   *
   * @return {string} return query
   */
  public static returnFields = (returnValues?: string[]) => {
    return returnValues ? `${returnValues.join(',')}` : '_all_fields,_score'
  }

  /**
   * Returns an object with formatted highlight options.
   *
   * @param {IHighlight[]} highlights
   * @returns {{[p: string]: string}}
   */
  public static highlights = (highlights: IHighlight[]) =>
    highlights.reduce(
      (target, highlight) => ({
        ...target,
        [`highlight.${highlight.fieldName}`]: `{"max_phrases":${highlight.maxPhrases},"format":"text","pre_tag":"${CloudSearch.searchHighlightsTags.pre}","post_tag":"${CloudSearch.searchHighlightsTags.post}"}`,
      }),
      {}
    )

  /**
   * Function builds CloudSearch query URL.
   *
   * @param {string} phrase - search phrase, use empty string to search all
   * @param {QueryTerm[]} and - AND query terms - include in results if match
   * @param {QueryTerm[]} not - NOT query terms - remove from results if match
   * @param {string[]} returnValues - array of entity fields to return, default all
   * @param {IDateFilter} dates - datesFilter
   * @param {number} size - length of the results (default 12)
   * @param {number} page - page number, starting at 0
   *
   * @return {string}
   */
  public static buildCloudSearchUrl = ({
    and,
    categoryFilter,
    datesFilter,
    locationFilter,
    typeFilter,
    tagsFilter = [],
    not,
    phrase,
    returnValues,
    size = CloudSearch.resultsLength,
    page = 0,
    variousFilters = [],
    sourceIds = [],
    sorting,
  }: ICloudSearchOptions): string => {
    const { sort, searchFieldsOption, returnFields } = LuceneQueryBuilder
    const startDate = datesFilter?.from?.toISOString() || null
    const endDate = datesFilter?.to?.toISOString() || startDate

    return (
      URI.CloudSearch +
      qs.stringify(
        {
          q: query({
            phrase,
            and,
            not,
            dates: {
              [HitFields.start_date]: [startDate, endDate],
              [HitFields.end_date]: [startDate, endDate],
            },
          }),
          fq: fq([
            { [HitFields.locations_ids]: locationFilter?.id },
            { [HitFields.category_name]: categoryFilter?.name },
            { [HitFields.type]: typeFilter },
            { [HitFields.id_source]: sourceIds },
            ...tagsFilter.map(({ id }) => ({ [HitFields.tags_ids]: id })),
            ...variousFilters,
          ]),
          'q.parser': 'lucene',
          'q.options': searchFieldsOption(CloudSearch.searchFields),
          facet: CloudSearch.facets,
          return: returnFields(returnValues),
          expr: CloudSearch.expressions,
          size,
          start: size * page,
          sort: sort(sorting ?? CloudSearch.staticSorting),
        },
        { addQueryPrefix: true, allowDots: true }
      )
    )
  }
}
