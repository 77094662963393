import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = makeSxStyles(() => ({
  box: (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: theme.palette.background.paper,
    borderRadius: theme.border.radius,
    boxShadow: 24,
    p: 4,
  }),
}))
