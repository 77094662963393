import { useMemo } from 'react'

/**
 * Memoized function to compute center of image
 *
 * @param {number} width - image width
 * @param {number} height - image height
 *
 * @return {[number, number]} position of image center
 */
const useCenterImage = (width: number, height: number): [number, number] => {
  return useMemo(() => [width / 2, height / 2], [width, height])
}

export default useCenterImage
