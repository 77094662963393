import React from 'react'

import { Typography, useTheme } from '@mui/material'
import cn from 'classnames'

import useStyles from 'components/Bricks/components/ArtistSliderBrick/ArtistSliderBrick.styles'
import ArtistSlider from 'components/reusable/ArtistSlider'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import { useAppState } from 'state/app/hooks/useAppState'

import styles from './ArtistSliderBrick.module.scss'
import { IArtistSliderBrickProps } from './ArtistSliderBrick.types'

const ArtistSliderBrick = ({
  artists,
  bgColor,
  color,
  id,
  sectionDescription,
  sectionTitle,
}: IArtistSliderBrickProps) => {
  const theme = useTheme()
  const { isDarkTheme } = useAppState()
  const isBgDark =
    isColorDark(bgColor) ||
    isColorDark(isDarkTheme && bgColor ? invertIfGrayScaleColor(bgColor) : bgColor)
  const muiStyles = useStyles(isBgDark, color)(theme)
  const showHeaderSection = sectionDescription || sectionTitle

  return (
    <div className={cn(styles.artists, muiStyles.fontColor)} id={id}>
      {showHeaderSection && (
        <div className={muiStyles.headerSection}>
          {sectionTitle && (
            <Typography className={muiStyles.heading} variant={'h4'} children={sectionTitle} />
          )}
          {sectionDescription && <Typography variant={'body1'} children={sectionDescription} />}
        </div>
      )}
      <ArtistSlider artistSection={artists} id={id} componentTitle={sectionTitle} />
    </div>
  )
}

export default ArtistSliderBrick
