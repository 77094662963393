import React, { Fragment, useState } from 'react'

import { Search } from '@mui/icons-material'
import { Divider, InputAdornment, List, TextField } from '@mui/material'

import { Flex } from '../../_layout/Flex'
import { FavoriteItem } from '../FavoriteItem'
import { IFavoritesProps } from './Favorites.types'

export const Favorites = ({ favorites }: IFavoritesProps) => {
  const [phrase, setPhrase] = useState('')
  const filteredFavorites = favorites.filter((favorite) =>
    favorite.item.name.toLowerCase().includes(phrase.toLowerCase())
  )

  return (
    <Flex gap={3}>
      <TextField
        value={phrase}
        size={'medium'}
        type={'search'}
        variant={'outlined'}
        onChange={(e) => setPhrase(e.target.value)}
        fullWidth={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <List sx={{ width: '100%' }} disablePadding>
        {filteredFavorites.map((favorite, index) => (
          <Fragment key={favorite.item.externalId}>
            {index !== 0 && <Divider />}
            <FavoriteItem item={favorite} />
          </Fragment>
        ))}
      </List>
    </Flex>
  )
}
