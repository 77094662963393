import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import Insurance from 'components/buying/components/Insurance/Insurance.component'
import {
  IInsuranceFromDispatch,
  IInsuranceFromState,
  IInsuranceOwnProps,
} from 'components/buying/components/Insurance/Insurance.types'
import { eventAddToCart, eventRemoveFromCart } from 'models/analytics/actions'
import { getInsurancePrice, getSelectedTickets } from 'models/pools/selectors'
import { reloadInsurance } from 'models/products/actions'
import { getInsuranceProducts } from 'models/products/selectors'

const mapStateToProps = (state: _Store.IState): IInsuranceFromState => ({
  selectedTickets: getSelectedTickets(state),
  insuranceTotalPrice: getInsurancePrice(state),
  insuranceProducts: getInsuranceProducts(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IInsuranceFromDispatch => ({
  reloadInsurance: () => dispatch(reloadInsurance()),
  eventAddToCart: (params) => dispatch(eventAddToCart(params)),
  eventRemoveFromCart: (params) => dispatch(eventRemoveFromCart(params)),
})

export default connect<
  IInsuranceFromState,
  IInsuranceFromDispatch,
  IInsuranceOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Insurance)
