import { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { FormFields } from 'components/_forms/_constants/FieldNames'
import { defaultValues } from 'components/_forms/_constants/defaultValues'
import { validators } from 'components/_forms/_validators'
import { ITransferFormInput } from 'components/ticketManagement/TicketAction/Transfer/Transfer.types'
import { formatPluralNouns } from 'misc/helpers/formatPluralNouns'
import { TicketActions, TicketManagementApi } from 'services/Api/TicketManagementApi'
import { DataMutations } from 'services/DataMutations'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'
import { IProductsResponse } from 'types/Products'
import { TicketResponse } from 'types/TicketManagement'

export const useTransfer = (
  code: string,
  email: string,
  ticketIndex: number,
  actionKey: TicketActions
) => {
  const { data: tickets, mutate } = useSWR<[TicketResponse]>(
    !!code && !!email && URI.getTicket(code, email),
    { revalidateOnMount: false }
  )
  const { data: products } = useSWR<IProductsResponse>(URI.transferTicketFee)
  const transferFee = DataMutations.calculateTicketTransferFee(
    tickets?.[ticketIndex],
    products?.data
  )
  const { code: lang } = useLang()
  const { i18n } = useDictionary()
  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const quantity = tickets?.[ticketIndex].ticket.quantity || 1
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<ITransferFormInput>({
    defaultValues: {
      [FormFields.EMAIL]: defaultValues.empty,
      [FormFields.FIRSTNAME]: defaultValues.empty,
      [FormFields.LASTNAME]: defaultValues.empty,
      [FormFields.QUANTITY]: quantity,
    },
  })
  const ticketMainRoute = prepareRoute({
    route: Route.ticketManagementTicket,
    params: { code, email },
  })

  const validationRules = {
    [FormFields.FIRSTNAME]: validators.requiredString(
      i18n.forms.validation[FormFields.FIRSTNAME].required
    ),
    [FormFields.LASTNAME]: validators.requiredString(
      i18n.forms.validation[FormFields.LASTNAME].required
    ),
    [FormFields.EMAIL]: validators.email(
      i18n.forms.validation[FormFields.EMAIL].required,
      i18n.forms.validation[FormFields.EMAIL].correct
    ),
    [FormFields.QUANTITY]: validators.minimumNumber(
      1,
      i18n.forms.validation[FormFields.QUANTITY].required,
      i18n.forms.validation[FormFields.QUANTITY].minMsg
    ),
  }

  const onSubmit = handleSubmit(async (values) => {
    if (!!errors.root) setActiveStep(0)
    if (activeStep === 0) setActiveStep(1)
    else if (activeStep === 2) {
      setIsLoading(true)
      await mutate()
      navigate(ticketMainRoute)
    } else {
      setIsLoading(true)
      const response = await TicketManagementApi.transferTicket({
        ...values,
        token: tickets?.[ticketIndex].token!,
      })
      if (!response.status) {
        navigate(
          prepareRoute({
            route: Route.ticketManagementActionError,
            params: {
              code,
              email,
              ticketIndex: String(ticketIndex),
              errorCode: response.errorCode!,
              actionKey,
            },
          })
        )
      }
      setActiveStep(2)
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (
      isNaN(ticketIndex) ||
      tickets?.[ticketIndex].actions[TicketActions.TRANSFER].isAvailable === false
    ) {
      navigate(ticketMainRoute)
    }
  }, [ticketIndex])

  return {
    activeStep,
    i18n,
    control,
    validationRules,
    handleBack: () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    handleSubmit: onSubmit,
    getValues,
    isLoading,
    transferFee,
    quantityOptions: Array.from({ length: quantity ?? 1 }, (_, index) => ({
      label: index + 1 + '\u00A0' + formatPluralNouns(index + 1, lang, i18n.pluralRules.ticket),
      value: index + 1,
    })),
  }
}
