export enum CurrencyISO {
  PLN = 'PLN',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

export const currencySymbol = {
  [CurrencyISO.PLN]: 'zł',
  [CurrencyISO.EUR]: '€',
  [CurrencyISO.GBP]: '£',
  [CurrencyISO.USD]: '$',
}
