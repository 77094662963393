import React, { FC } from 'react'

import { ChevronRight } from '@mui/icons-material'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton as MuiListItemButton,
  Typography,
} from '@mui/material'

import { IListItemButtonProps } from 'components/reusable/ListItemButton/ListItemButton.types'

export const ListItemButton: FC<IListItemButtonProps> = ({ title, icon, subtitle, onClick }) => {
  const content = (
    <>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      {subtitle && (
        <ListItemText
          secondary={
            <Typography
              variant={'body2'}
              textAlign={'right'}
              color={'text.secondary'}
              children={subtitle}
            />
          }
        />
      )}
      {onClick && <ChevronRight fontSize="large" sx={{ ml: 3 }} />}
    </>
  )

  return !!onClick ? (
    <MuiListItemButton onClick={onClick} disableGutters>
      {content}
    </MuiListItemButton>
  ) : (
    <ListItem disableGutters disablePadding>
      {content}
    </ListItem>
  )
}
