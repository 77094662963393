import { createSelector } from 'reselect'

import _Store from '@Store'

import { IGetTransactionDetailsSuccessPayload, ITransactionReducer } from './../types'
import get from './get'

const getTransactionDetails = createSelector<
  _Store.IState,
  ITransactionReducer,
  IGetTransactionDetailsSuccessPayload
>(
  [get],
  (transaction) => transaction.transactionDetails || ({} as IGetTransactionDetailsSuccessPayload)
)

export default getTransactionDetails
