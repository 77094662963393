import app from './app'

export enum imagePriorityEnum {
  api,
  cloudinary,
}

const images = {
  artistPrefix: 'artist/',
  cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME || 'going-app-dev',
  cloudinaryApiKey: process.env.REACT_APP_CLOUDINARY_API_KEY || '844884669662748',
  cloudinaryApiSecret: process.env.REACT_APP_CLOUDINARY_API_SECRET || 'GKd67LKzZ41wynrH2AB4LghR3_s',
  eventPrefix: 'event/',
  placePrefix: 'place/',
}

export default images
