import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'

import PayUContextProvider from 'components/_functional/PayUContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Online from 'components/buying/Online'
import Onsite from 'components/buying/Onsite'
import CardPaymentForm from 'components/buying/components/CardPaymentForm'
import Helmet from 'components/helmet/components/BuyPage'
import config from 'config'
import { eventPageMounted } from 'models/event/actions'
import { getEvent } from 'models/event/selectors'

export const BuyPage = () => {
  const { eventSlug, rundateSlug } = useParams<RouteParams<Route.buy>>()
  const dispatch = useDispatch()
  const eventData = useSelector(getEvent)

  React.useEffect(() => {
    dispatch(eventPageMounted())
  }, [eventSlug, rundateSlug, dispatch])

  return (
    <PageWrapper>
      <Helmet noIndex={!eventData?.isAvailable} />
      {config.app.onlineSale ? (
        <PayUContextProvider>
          <Online cardPaymentForm={<CardPaymentForm />} />
        </PayUContextProvider>
      ) : (
        <Onsite />
      )}
    </PageWrapper>
  )
}
