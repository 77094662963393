import { ComponentType } from 'react'

import { TextField, styled } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'

import config from 'config'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: config.theme.isEmpik
    ? theme.palette.secondary.contrastText
    : theme.palette.primary.main,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
})) as ComponentType<TextFieldProps>
