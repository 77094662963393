import React, { FC } from 'react'

import { LockOutlined, Person2Outlined, TranslateOutlined } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemText,
  Switch,
} from '@mui/material'

import { SectionContainer } from 'components/_layout/SectionContainer'
import { EditDataForm } from 'components/auth/EditDataForm'
import { EditPasswordForm } from 'components/auth/EditPasswordForm'
import { RemoveAccount } from 'components/auth/RemoveAccountButton'
import { ListItemButton } from 'components/reusable/ListItemButton'
import { Languages } from 'constants/Languages'
import { useToggle } from 'misc/hooks/useToggle'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useSetLang } from 'state/locale/hooks/useSetLang'

import { useUser } from '../_functional/UserContextProvider'

/**
 * TODO: Move to separate file if needed elsewhere.
 */
const DialogWrapper: FC<{ isVisible: boolean; onClose: () => void }> = ({
  children,
  isVisible,
  onClose,
}) => {
  const { i18n } = useDictionary()

  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth={'xs'}>
      {children}
      <DialogActions>
        <Button onClick={onClose} children={i18n.buttons.cancel} fullWidth />
      </DialogActions>
    </Dialog>
  )
}

export const Settings = () => {
  const { i18n } = useDictionary()
  const { setLanguage } = useSetLang()
  const { isNewsletter, toggleNewsletter } = useUser()
  const {
    isVisible: isEditDataDialogVisible,
    handleHide: handleHideEditDataDialog,
    handleShow: handleShowEditDataDialog,
  } = useToggle()
  const {
    isVisible: isEditPasswordDialogVisible,
    handleHide: handleHideEditPasswordDialog,
    handleShow: handleShowEditPasswordDialog,
  } = useToggle()
  const {
    isVisible: isLanguageDialogVisible,
    handleHide: handleHideLanguageDialog,
    handleShow: handleShowLanguageDialog,
  } = useToggle()

  return (
    <>
      <SectionContainer title={i18n.settings.account} smallTitle container>
        <List disablePadding>
          <ListItemButton
            title={i18n.settings.editData}
            icon={<Person2Outlined />}
            onClick={handleShowEditDataDialog}
          />
          <Divider />
          <ListItemButton
            title={i18n.settings.editPassword}
            icon={<LockOutlined />}
            onClick={handleShowEditPasswordDialog}
          />
        </List>
        <DialogWrapper isVisible={isEditDataDialogVisible} onClose={handleHideEditDataDialog}>
          <EditDataForm successCallback={handleHideEditDataDialog} />
        </DialogWrapper>
        <DialogWrapper
          isVisible={isEditPasswordDialogVisible}
          onClose={handleHideEditPasswordDialog}
        >
          <EditPasswordForm successCallback={handleHideEditPasswordDialog} />
        </DialogWrapper>
      </SectionContainer>
      <SectionContainer title={i18n.settings.communication} smallTitle container>
        <List disablePadding>
          <ListItem disablePadding disableGutters>
            <ListItemText primary={i18n.settings.newsletter} secondary={i18n.settings.letUsWrite} />
            <Switch checked={isNewsletter} onClick={toggleNewsletter} />
          </ListItem>
        </List>
      </SectionContainer>
      <SectionContainer title={i18n.settings.misc} smallTitle container>
        <List disablePadding>
          <ListItemButton
            title={i18n.settings.language}
            icon={<TranslateOutlined />}
            onClick={handleShowLanguageDialog}
          />
        </List>
        <DialogWrapper isVisible={isLanguageDialogVisible} onClose={handleHideLanguageDialog}>
          <List disablePadding>
            {Object.entries(Languages).map(([name, symbol]) => (
              <ListItem
                key={name}
                disableGutters
                onClick={() => {
                  setLanguage(symbol)
                  handleHideLanguageDialog()
                }}
              >
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </DialogWrapper>
      </SectionContainer>
      <RemoveAccount />
    </>
  )
}
