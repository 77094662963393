import { setCardCredentials } from 'models/transaction/actions'
import { IAction, ICardCredentialsReducer } from 'models/transaction/types'
import { getType } from 'typesafe-actions'

const reducer = (
  state: ICardCredentialsReducer = null,
  action: IAction
): ICardCredentialsReducer => {
  switch (action.type) {
    case getType(setCardCredentials):
      return action.payload
    default:
      return state
  }
}

export default reducer
