import { ValidationRules } from 'components/_forms/_validators/types'
import { regExp } from 'constants/RegExp'

export const strongPasswordValidator = (
  requiredMsg: string,
  correctMsg: string,
  tooShort: string
): ValidationRules => ({
  required: {
    value: true,
    message: requiredMsg,
  },
  min: {
    value: 8,
    message: tooShort,
  },
  pattern: {
    value: regExp.password,
    message: correctMsg,
  },
})
