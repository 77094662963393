import { IBasket } from 'models/basket/types'
import { createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants'

export const basketBuyFormMounted = createStandardAction(CONSTS.BASKET_BUY_FORM_MOUNTED)()

export const updateBasketStateFromContext = createStandardAction(
  CONSTS.UPDATE_BASKET_STATE_FROM_CONTEXT
)<IBasket[]>()
