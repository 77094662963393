import { connect } from 'react-redux'

import _Store from '@Store'

import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import CustomCode from './CustomCode.component'
import { ICustomCodeFromState, ICustomCodeOwnProps } from './CustomCode.types'

const mapStateToProps = (state: _Store.IState): ICustomCodeFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
})

export default connect<ICustomCodeFromState, void, ICustomCodeOwnProps, _Store.IState>(
  mapStateToProps
)(CustomCode)
