import { Verified } from '@mui/icons-material'
import { Button, ButtonTypeMap, Typography, TypographyTypeMap, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const Styled = {
  Button: styled(Button)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: 12,
    paddingBottom: 12,
    textTransform: 'unset',
  })) as OverridableComponent<ButtonTypeMap>,

  Title: styled(Typography)({
    alignItems: 'flex-end',
    display: 'flex',
  }) as OverridableComponent<TypographyTypeMap>,

  Verified: styled(Verified)({
    marginLeft: 8,
    position: 'relative',
    bottom: 12,
  }),
}
