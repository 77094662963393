import * as React from 'react'

import { Chip } from '@mui/material'
import { useRecoilState } from 'recoil'

import { ICategoryFilterProps } from 'components/search/CategoryFilter/CategoryFilter.types'
import { HitFields } from 'constants/CloudSearch'
import { setCategoryFilter } from 'state/cloudSearch/selectors/setCategoryFilter'
import { ICombinedFacet } from 'types/CloudSearch'

export const CategoryFilter = ({
  categories,
  searchParams,
  categorySlug,
}: ICategoryFilterProps) => {
  const [categoryFilter, setCategoryFilterState] = useRecoilState(setCategoryFilter)
  const setCategory = (category: ICombinedFacet) => () =>
    setCategoryFilterState({
      id: category.id,
      name: category.name,
      slug: category.slug,
    })
  const clearCategory = () => setCategoryFilterState(null)

  React.useEffect(() => {
    if (!searchParams) return
    const name = searchParams.get(HitFields.category_name)
    if (name) setCategoryFilterState({ name })
  }, [searchParams])

  React.useEffect(() => {
    if (categorySlug) {
      const category = categories.find((cat) => cat.slug === categorySlug)
      if (category && !categoryFilter) setCategoryFilterState(category)
    }
  }, [categorySlug, categories])

  return (
    <>
      {categoryFilter && (
        <Chip
          label={categoryFilter.name}
          onClick={clearCategory}
          onDelete={clearCategory}
          size={'small'}
        />
      )}
      {!categoryFilter &&
        categories.map((category) => (
          <Chip
            key={'cat_' + category.id}
            label={category.name}
            onClick={setCategory(category)}
            size={'small'}
          />
        ))}
    </>
  )
}
