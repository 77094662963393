import { Route, routes } from 'router/routes'

import { ISectionsComponentBanner, ISectionsComponentLink } from 'models/pages/types/sections'

/**
 * Function to prepare url fot hot spots
 *
 * @param {ISectionsComponentLink} hotspot - to which slug refers
 *
 * @return {string} - link for hotspot
 */
const prepareHotSpotUri = (hotspot: ISectionsComponentLink | ISectionsComponentBanner) => {
  const eventUri = routes[Route.rundate].split(':')[0]

  return hotspot.link ?? eventUri + hotspot.slug
}

export default prepareHotSpotUri
