/*
 * Name convention: MODULE__LOCAL_NAME
 */
export const INTERNAL_ROUTER__LINK_TO_APP = 'INTERNAL_ROUTER__LINK_TO_APP'

export const STORAGE__PRIVACY_POLICY = 'STORAGE__PRIVACY_POLICY'
export const STORAGE__TERMS = 'STORAGE__TERMS'

export const HELMET__AUTHOR = 'HELMET__AUTHOR'
export const HELMET__PAGE_NAME = 'HELMET__PAGE_NAME'
export const HELMET__PAGE_TITLE = 'HELMET__PAGE_TITLE'
export const HELMET__PAGE_DESCRIPTION = 'HELMET__PAGE_DESCRIPTION'
export const HELMET__PAGE_KEYWORDS = 'HELMET__PAGE_KEYWORDS'
export const HELMET__PAGE_CANONICAL_URL = 'HELMET__PAGE_CANONICAL_URL'
