const areColorNumsEq = (colorOne: number, colorTwo: number, threshold: number) => {
  if (Math.abs(colorOne - colorTwo) <= threshold) {
    return true
  } else {
    return false
  }
}

const isColorGrayScale = (red: number, green: number, blue: number, threshold: number) => {
  return (
    areColorNumsEq(red, green, threshold) &&
    areColorNumsEq(red, blue, threshold) &&
    areColorNumsEq(green, red, threshold) &&
    areColorNumsEq(green, blue, threshold) &&
    areColorNumsEq(blue, red, threshold) &&
    areColorNumsEq(blue, green, threshold)
  )
}

export default isColorGrayScale
