import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import {
  IInsuranceProduct,
  IInsuranceSummaryData,
  IProduct,
  IProductSelect,
  IProductSelected,
  IProductUserData,
  IProductsRequest,
  IProductsSelected,
} from './../types'

export const mountProducts = createStandardAction(CONSTS.PRODUCTS_MOUNTED)()
export const productsMounted = createStandardAction(CONSTS.PRODUCT_MOUNTED)()
export const reloadInsurance = createStandardAction(CONSTS.RELOAD_INSURANCE)()

export const selectProducts = createStandardAction(CONSTS.SELECT_PRODUCTS)<IProductsSelected>()

export const selectProduct = createStandardAction(CONSTS.SELECT_PRODUCT)<IProductSelected>()

export const updateProductData = createStandardAction(
  CONSTS.UPDATE_PRODUCT_DATA
)<IProductUserData>()

export const updateSelectedProducts = createStandardAction(CONSTS._UPDATE_SELECTED_PRODUCTS)<
  IProductSelect[]
>()

export const getProducts = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<IProductsRequest, IProduct[], Error>()

export const getProductsPools = createAsyncAction(
  CONSTS._GET_PRODUCTS_POOLS_REQUEST,
  CONSTS._GET_PRODUCTS_POOLS_SUCCESS,
  CONSTS._GET_PRODUCTS_POOLS_FAILURE
)<undefined, IProduct[], Error>()

export const getSingleProduct = createAsyncAction(
  CONSTS._GET_SINGLE_PRODUCT_REQUEST,
  CONSTS._GET_SINGLE_PRODUCT_SUCCESS,
  CONSTS._GET_SINGLE_PRODUCT_FAILURE
)<undefined, IProduct, Error>()

export const setInsuranceSummaryData = createStandardAction(
  CONSTS.SET_INSURANCE_SUMMARY_DATA
)<IInsuranceSummaryData>()

export const getInsuranceProducts = createAsyncAction(
  CONSTS._GET_INSURANCE_PRODUCTS_REQUEST,
  CONSTS._GET_INSURANCE_PRODUCTS_SUCCESS,
  CONSTS._GET_INSURANCE_PRODUCTS_FAILURE
)<undefined, IInsuranceProduct, Error>()
