import { createSelector } from 'reselect'

import { IAvailabilities } from '../types'
import get from './get'
import getAvailabilities from './getAvailabilitiesSelector'
import getSelected from './getSelected'

const prepareResultsArray = (slots: IAvailabilities[]) => {
  const spacesSlots: { [key: string]: IAvailabilities[] } = {}
  slots[0].spaces.forEach((space) => {
    spacesSlots[space.spaceId] = []
  })

  for (const slot of slots) {
    for (const space of slot.spaces) {
      if (!space.reserved) {
        spacesSlots[space.spaceId].push(slot)
      }
    }
  }

  const filteredSlots = Object.keys(spacesSlots)
    .map((key) => {
      let firstEnable = -1
      for (let i = 0; i < spacesSlots[key].length; i++) {
        if (!spacesSlots[key][i].isDisabled) {
          firstEnable = i

          break
        }
      }
      return spacesSlots[key].slice(0, firstEnable + 1)[0]
    })
    .filter((value) => value)
    .map((slot) => ({ ...slot, isDisabled: false }))
    .filter((value, index, array) => array.findIndex((v) => v.start === value.start) === index)
    .sort((a, b) => a.start.localeCompare(b.start))

  return filteredSlots
}

const getTimeSlots = createSelector([getAvailabilities, get,getSelected], (availabilities, happening,selected) => {
  const selectedTotalCount = selected.selections.reduce((pV,cV)=>(cV.numberOfPlayers||0)+pV,0)

  if (!happening.data?.overbookedSlots) {
    return happening.data?.calculatePricePerPerson?availabilities.filter((slot)=>slot.capacityLeft>=selectedTotalCount):availabilities
  }
  let key = 'nil'
  const keys: string[] = []
  const result: IAvailabilities[][] = []


  availabilities.forEach((_slot) => {
    const hour = _slot.start.slice(0, 2)

    if (hour !== key) {
      result.push([])

      key = hour
      keys.push(hour)
    }
    result[result.length - 1].push(_slot)
  })

  let remove = true

  const filtered = result
    .map((_result, index) => ({
      start: `${keys[index]}:00`,
      spaces: prepareResultsArray(_result),
    }))
    .filter((_result) => {
      if (_result.spaces.length < 1) {
        return !remove
      } else {
        remove = false

        return true
      }
    })
  return availabilities.filter((obj1) => {
    return filtered.some((obj2) => {
      return obj2.spaces.some((item) => item.start === obj1.start)
    })
  })
})

export default getTimeSlots
