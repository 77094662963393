import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

/**
 * Stores current search results page.
 */
export const searchPageIndexState = atom({
  default: 0,
  key: RecoilKeys.searchPage,
})
