import React, { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import RcSlider from 'rc-slider'

import Description from 'components/buying/Pools/components/Description/Description.component'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './Slider.styles'
import { ISliderProps } from './Slider.types'

const DEFAULT_STEP = 1

const Slider = ({
  maxValue,
  minValue,
  step: _step,
  value: initialValue,
  onChange,
  priceType,
}: ISliderProps) => {
  const step = _step || DEFAULT_STEP
  const [value, setValue] = useState(initialValue || 0)
  const classes = useStyles()

  const { i18n } = useDictionary()
  const { isMobile } = useBreakpoints()

  const showDescription = !!priceType.description

  const changeValue = (val: number) => {
    setValue(val)
    onChange(val)
  }

  useEffect(() => {
    changeValue(initialValue || 0)
  }, [initialValue])

  const up = () => {
    if (value < maxValue) {
      changeValue(value + step)
    }
  }

  const down = () => {
    if (value > (minValue || 0)) {
      changeValue(value - step)
    }
  }

  return (
    <Grid
      container={true}
      className={classes.wrapper}
      alignItems={'center'}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Grid item={true} xs={'auto'} sm={5}>
        <Grid container={true} spacing={2} alignItems={'center'}>
          {!isMobile && (
            <Grid item={true} xs={'auto'} md={2}>
              <ConfirmationNumberOutlinedIcon />
            </Grid>
          )}
          <Grid item={true} xs={12} md={9}>
            <Grid
              container={true}
              flexDirection={'column'}
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <Grid item={true}>
                <Typography variant={'h6'}>
                  {priceType.title === 'default'
                    ? i18n.happening.priceTypes.defaultTicket
                    : priceType.title}
                </Typography>
              </Grid>
              <Grid item={true}>
                {showDescription && <Description text={priceType.description || ''} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} sm={7} className={classes.fullWidth}>
        <Grid
          container={true}
          spacing={3}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={'center'}
        >
          <Grid item={true} xs={12} sm={'auto'} className={classes.sliderWrapper}>
            <RcSlider
              className={classes.slider}
              onChange={changeValue}
              value={value}
              min={minValue || 0}
              max={maxValue}
              step={step}
            />
          </Grid>
          <Grid item={true}>
            <Grid container={true} spacing={3} alignItems={'center'}>
              <Grid item={true}>
                <Button
                  size={'small'}
                  color={'primary'}
                  variant={'contained'}
                  onClick={down}
                  className={classes.controlButton}
                >
                  <RemoveIcon fontSize={'medium'} />
                </Button>
              </Grid>
              <Grid item={true}>
                <Typography variant={'h4'}>{value}</Typography>
              </Grid>
              <Grid item={true}>
                <Button
                  size={'small'}
                  color={'primary'}
                  variant={'contained'}
                  onClick={up}
                  className={classes.controlButton}
                >
                  <AddIcon fontSize={'medium'} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Slider
