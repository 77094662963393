import React from 'react'

import { Grid } from '@mui/material'

import phone from 'misc/styles/images/going-footer-phone-image.png'

import useStyles from './PhoneMockup.styles'

const PhoneMockup = () => {
  const classes = useStyles()

  return (
    <Grid item={true} sm={4}>
      <img className={classes.image} src={phone} alt={''} />
    </Grid>
  )
}

export default PhoneMockup
