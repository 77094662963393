import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = makeSxStyles<{ isDesktop: boolean }>(({ isDesktop }) => ({
  root: {
    display: 'flex',
    justifyContent: isDesktop ? 'flex-start' : 'center',
    flexDirection: 'column',
    alignItems: isDesktop ? 'flex-start' : 'center',
  },
  button: (theme) => ({
    color: 'text.primary',
    display: 'flex',
    flex: 0,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
    textDecorationThickness: 2,
    textUnderlineOffset: 4,
    textTransform: 'lowercase',
    alignItems: 'center',
    p: 0,
  }),
  icon: {
    p: 0,
    mr: 1,
  },
}))
