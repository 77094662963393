import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { IReturnFormInput } from 'components/ticketManagement/TicketAction/Return/Return.types'
import { TicketActions, TicketManagementApi } from 'services/Api/TicketManagementApi'
import { URI } from 'services/URI'
import { TicketResponse } from 'types/TicketManagement'

export const useReturn = (
  code: string,
  email: string,
  ticketIndex: number,
  actionKey: TicketActions
) => {
  const { data: tickets, mutate } = useSWR<[TicketResponse]>(
    !!code && !!email && URI.getTicket(code, email),
    { revalidateOnMount: false }
  )
  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IReturnFormInput>()
  const ticketMainRoute = prepareRoute({
    route: Route.ticketManagementTicket,
    params: { code, email },
  })

  const onSubmit = handleSubmit(async (values) => {
    if (!!errors.root) setActiveStep(0)
    if (activeStep === 0) setActiveStep(1)
    else if (activeStep === 2) {
      setIsLoading(true)
      await mutate()
      navigate(ticketMainRoute)
    } else {
      setIsLoading(true)
      const response = await TicketManagementApi.returnTicket({
        ...values,
        token: tickets?.[ticketIndex].token!,
      })
      if (!response.status) {
        navigate(
          prepareRoute({
            route: Route.ticketManagementActionError,
            params: {
              code,
              email,
              ticketIndex: String(ticketIndex),
              errorCode: response.errorCode!,
              actionKey,
            },
          })
        )
      }
      setActiveStep(2)
      setIsLoading(false)
    }
  })

  return {
    activeStep,
    isLoading: false,
    handleBack: () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    handleSubmit: onSubmit,
    control,
  }
}
