import * as React from 'react'

import { Box } from '@mui/material'

import { Flex } from 'components/_layout/Flex'

import { ISwipeableCardDeckProps } from './SwipeableCardDeck.types'

export const SwipeableCardDeck = React.forwardRef<
  HTMLUListElement,
  React.PropsWithChildren<ISwipeableCardDeckProps>
>(({ children, bottomElement }, ref) => {
  return (
    <Flex gap={3} align={'center'}>
      <Box
        component={'ul'}
        ref={ref}
        sx={{
          display: 'flex',
          gap: 4,
          overflowX: 'scroll',
          width: '100%',
        }}
      >
        {children}
      </Box>
      {bottomElement}
    </Flex>
  )
})
