import { IEventFull } from 'models/event/types'
import { IProductResponse } from 'types/Products'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import {
  IDownloadFromTicketDataPayload,
  IDownloadFromTransactionDataPayload,
  ITicketReceiveData,
  ITicketReceptionTermsForm,
} from './../types'

// Public actions
export const resetTickets = createStandardAction(CONSTS.RESET)()

export const setShowSender = createStandardAction(CONSTS.SET_SHOW_SENDER)<boolean>()

export const receiveTicketMounted = createStandardAction(CONSTS.RECEIVE_TICKET_MOUNTED)()

export const receiveTicketSubmit = createStandardAction(
  CONSTS.RECEIVE_TICKET_SUBMIT
)<ITicketReceptionTermsForm>()

export const setInvalidSelection = createStandardAction(CONSTS._SET_INVALID_SELECTION)<string>()
export const setValidSelection = createStandardAction(CONSTS._SET_VALID_SELECTION)()

export const downloadTicketFromTicketData = createStandardAction(
  CONSTS.DOWNLOAD_TICKET_FROM_TICKET_DATA
)<IDownloadFromTicketDataPayload>()

export const downloadTicketFromTransactionData = createStandardAction(
  CONSTS.DOWNLOAD_TICKET_FROM_TRANSACTION_DATA
)<IDownloadFromTransactionDataPayload>()

// Private actions

export const getEventData = createAsyncAction(
  CONSTS._GET_EVENT_REQUEST,
  CONSTS._GET_EVENT_SUCCESS,
  CONSTS._GET_EVENT_FAILURE
)<string, IEventFull, Error>()

export const getTransferTicketFee = createAsyncAction(
  CONSTS._GET_TRANSFER_TICKET_FEE_REQUEST,
  CONSTS._GET_TRANSFER_TICKET_FEE_SUCCESS,
  CONSTS._GET_TRANSFER_TICKET_FEE_FAILURE
)<undefined, IProductResponse | null, Error>()

export const receiveTicket = createAsyncAction(
  CONSTS._GET_RECEIVE_REQUEST,
  CONSTS._GET_RECEIVE_SUCCESS,
  CONSTS._GET_RECEIVE_FAILURE
)<string, ITicketReceiveData, Error>()

export const downloadTicketFromTicketDataOnRequest = createAsyncAction(
  CONSTS._DOWNLOAD_TICKET_FROM_TICKET_DATA_REQUEST,
  CONSTS._DOWNLOAD_TICKET_FROM_TICKET_DATA_SUCCESS,
  CONSTS._DOWNLOAD_TICKET_FROM_TICKET_DATA_FAILURE
)<IDownloadFromTicketDataPayload, undefined, Error>()

export const downloadTicketFromTransactionDataOnRequest = createAsyncAction(
  CONSTS._DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_REQUEST,
  CONSTS._DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_SUCCESS,
  CONSTS._DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_FAILURE
)<IDownloadFromTransactionDataPayload, undefined, Error>()
