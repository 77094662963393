import React from 'react'

import { List, ListItem } from '@mui/material'

import styles from 'components/Happening/Happening.module.scss'
import Product from 'components/Products/components/Product/Product.component'
import config from 'config'
import isEmptyObject from 'misc/helpers/isEmptyObject'

import { IProductsProps } from './Products.types'

const Products = ({ products }: IProductsProps) => {
  const productsLimit = config.buy.maxProductsCount

  if (isEmptyObject(products)) {
    return null
  }

  return (
    <List sx={{ width: '100%', p: 0 }}>
      {products.map((product) => (
        <ListItem sx={{ p: 0 }} className={styles.product} key={product.id}>
          <Product product={product} productsLimit={productsLimit} selectedProducts={[]} />
        </ListItem>
      ))}
    </List>
  )
}

export default Products
