/**
 * Function that autocomplete buy form from previous data stored into local Storage
 *
 * @param {boolean} autoFill - checks if current URL contains data parameter used to autofill form
 * @param {ICustomTerm[]} customTerms - custom terms for current event or happening
 * @param {function} setFieldValue - formik function with the same name
 *
 * @return {void}
 */
import { getPreviousForm } from 'models/transaction/selectors'
import { ICustomTerm } from 'types/EventCommon'

const autocompleteForm = (
  autoFill: boolean,
  customTerms: ICustomTerm[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
) => {
  const data = getPreviousForm()

  if (data) {
    const areTermsValidWithCurrentEvent = () => {
      if (data.customTerms && customTerms) {
        return Object.keys(data.customTerms).every((term) =>
          customTerms.find((customTerm) => customTerm.id.toString(10) === term)
        )
      }

      return true
    }

    if (
      !areTermsValidWithCurrentEvent ||
      !autoFill ||
      (typeof window !== 'undefined' && window.ReactNativeWebView)
    ) {
      delete data.customTerms
    }

    if (!autoFill) {
      delete data.terms
    }

    Object.entries(data).map(([key, value]) => {
      if (key !== 'pickUpWay' && key !== 'discount') setFieldValue(key, value)
    })
  }

  return true
}

export default autocompleteForm
