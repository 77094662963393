import { ComponentType } from 'react'

import { MobileStepper, MobileStepperProps, styled } from '@mui/material'

export const Styled = {
  Dots: styled(MobileStepper)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(3),
    background: 'transparent',
    left: '50%',
    transform: 'translate(-50%, 0)',

    '& .MuiMobileStepper-dots': {
      gap: theme.spacing(5),

      '& .MuiMobileStepper-dot': {
        background: theme.palette.text.disabled,

        '&.MuiMobileStepper-dotActive': {
          background: theme.palette.info.contrastText,
        },
      },
    },
  })) as ComponentType<MobileStepperProps>,
}
