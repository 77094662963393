import React from 'react'

import { EventBusyOutlined } from '@mui/icons-material'
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { formatISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { CloudinaryPrefixes } from '../../../constants/Cloudinary'
import { Route } from '../../../router/routes'
import { prepareRoute } from '../../../router/utils/prepareRoute'
import { fetchCloudinaryList } from '../../../services/Api/Cloudinary'
import { DateTime } from '../../../services/DateTime'
import { FavoritesIcon } from '../../_svg/FavoritesIcon'
import { useEditFavorites } from '../FavoritesProvider/useEditFavorites'
import { ICalendarItemProps } from './CalendarItem.types'

export const CalendarItem = ({ item }: ICalendarItemProps) => {
  const { data: cloudinaryImages } = useSWR<string[]>(
    {
      slug: item.params.eventSlug,
      prefix: CloudinaryPrefixes.RUNDATE,
      additionalSlug: item.params.rundateSlug,
    },
    fetchCloudinaryList,
    {}
  )
  const navigate = useNavigate()
  const { removeFromFavorites } = useEditFavorites()

  const handleClick = () => {
    navigate(prepareRoute({ route: Route.rundate, params: item.params }))
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    removeFromFavorites({ type: item.type, externalId: item.externalId, itemName: item.name })
  }

  return (
    <ListItemButton onClick={handleClick} title={item.name} disableGutters>
      <ListItemAvatar>
        <Avatar src={cloudinaryImages?.[0]} children={<FavoritesIcon type={item.type} />} />
      </ListItemAvatar>
      <ListItemText
        primary={item.name}
        secondary={
          <>
            {DateTime.getFriendlyDate({
              isoDate: formatISO(item.time.toDate()),
            })}
            <br />
            {item.place}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton onClick={handleDelete}>
          <EventBusyOutlined />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}
