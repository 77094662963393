import { useEffect, useState } from 'react'

/**
 * Function computing background image height depending on the width of the window
 *
 * @param {string | null} imageSrc - background image file path
 * @param {boolean} isBrowser - boolean flag if DOM exists (SSR error protection)
 *
 * @return {number} computed background image height or default value of 50%
 */
const useBackgroundImageHeight = (
  imageSrc: string | null,
  isBrowser: boolean,
  useGradient?: boolean
) => {
  const [backgroundImageHeight, setImgHeight] = useState('50%')

  const computeAndSetHeight = (image: HTMLImageElement) => {
    const computedBgHeight = (document.body.offsetWidth * image.height) / image.width
    setImgHeight(`${computedBgHeight}px`)
  }

  useEffect(() => {
    if (isBrowser && imageSrc && useGradient) {
      const image = new Image()
      image.src = imageSrc

      image.addEventListener('load', () => {
        computeAndSetHeight(image)
      })

      window.addEventListener('resize', () => {
        computeAndSetHeight(image)
      })

      return function cleanup() {
        image.removeEventListener('load', () => {
          computeAndSetHeight(image)
        })

        window.removeEventListener('resize', () => {
          computeAndSetHeight(image)
        })
      }
    }
  })

  return backgroundImageHeight
}

export default useBackgroundImageHeight
