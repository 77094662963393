export enum PaymentMethods {
  PAYU = 'PAYU',
  BLIK = 'BLIK',
  MBANK = 'M',
  CARD = 'CARD',
  TWISTO = 'TWISTO',
  GOOGLE_PAY = 'GOOGLE_PAY',
  APPLE_PAY = 'APPLE_PAY',
  ECARD = 'ECARD',
}

export enum CardTypes {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}
