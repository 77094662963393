import { ITag } from './Common'

export enum ProfileType {
  place = 'miejsce',
}

export interface IProfileResponse {
  data: IProfile
}

export interface IProfile {
  id: number
  name: string
  recommendRundateIds: number[]
  stats: {
    followersCount: number
    eventCount: number
    archiveEventCount: number
    activityCount: number
    visitorsCount: number
  }
  links: {
    fbLink?: string
    instagramLink?: string
    spotifyLink?: string
    webLink?: string
    youtubeLink?: string
  }
  tags: ITag[]
}
