import React, { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import cn from 'classnames'

import { IExtendedSlot } from 'models/happening/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './MultipleSlotModal.styles'
import { IMultipleSlotModalProps } from './MultipleSlotModal.types'

const MultipleSlotModal = ({
  extendedSlots,
  setFormExtendedSlot,
  modalOpen,
  setModalOpen,
  isEmbed,
}: IMultipleSlotModalProps) => {
  const { i18n } = useDictionary()
  const [extendedSlot, setExtendedSlot] = useState<IExtendedSlot | null>(null)
  const [iframeModalPosition, setIframeModalPosition] = useState<number | null>(null)

  const classes = useStyles()

  const getMousePosition = (event: MouseEvent) => {
    if (!modalOpen) {
      setIframeModalPosition(event.clientY * 2)
    }
  }

  const selectSlot = (slot: IExtendedSlot) => {
    if (slot.duration === extendedSlot?.duration) {
      setExtendedSlot(null)
    } else {
      setExtendedSlot(slot)
    }
  }

  const onClose = () => {
    setModalOpen(false)
    setExtendedSlot(null)
  }

  const selectExtendedSlot = () => {
    setFormExtendedSlot(extendedSlot)
    onClose()
  }

  useEffect(() => {
    setFormExtendedSlot(null)
    if (isEmbed) {
      document.addEventListener('click', getMousePosition)
    }

    return () => {
      if (isEmbed) {
        document.removeEventListener('click', getMousePosition)
      }
    }
  }, [])

  return (
    <Dialog
      open={modalOpen}
      maxWidth={'sm'}
      fullWidth={true}
      onClose={onClose}
      scroll={'paper'}
      classes={{ paper: classes.modal }}
      style={{ height: iframeModalPosition || '100%' }}
      disableAutoFocus={true}
    >
      <DialogTitle>
        <Typography variant={'h5'}>{i18n.buy.multipleSlot.extendGame}</Typography>
        <IconButton className={classes.cancelIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant={'body1'}>{i18n.buy.multipleSlot.description}</Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
              {extendedSlots.map((value) => (
                <Grid key={value.duration} item={true} xs={12} sm={6} md={3}>
                  <Button
                    fullWidth={true}
                    variant={'outlined'}
                    size={'medium'}
                    color={'primary'}
                    onClick={() => selectSlot(value)}
                    className={cn(
                      classes.button,
                      extendedSlot?.duration === value.duration && classes.activeButton
                    )}
                  >
                    <Typography
                      variant={'button'}
                      className={cn(
                        (value.upsellPercentageValue || value.upsellValue) && classes.buttonLine
                      )}
                      component={'p'}
                    >
                      +{value.duration} {i18n.buy.multipleSlot.min}
                    </Typography>
                    {value.upsellPercentageValue && (
                      <Typography variant={'caption'}>
                        {value.upsellPercentageValue} % {i18n.buy.multipleSlot.cheaper}
                      </Typography>
                    )}
                    {value.upsellValue && (
                      <Typography variant={'caption'}>
                        {value.upsellValue} zł {i18n.buy.multipleSlot.cheaper}
                      </Typography>
                    )}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            {!!extendedSlot ? (
              <Button
                size={'large'}
                variant={'contained'}
                color={'primary'}
                fullWidth={true}
                onClick={selectExtendedSlot}
              >
                {i18n.buy.multipleSlot.extend}
              </Button>
            ) : (
              <Button
                size={'large'}
                variant={'contained'}
                color={'secondary'}
                fullWidth={true}
                onClick={onClose}
              >
                {i18n.buy.multipleSlot.decline}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default MultipleSlotModal
