import { EMPTY as EMPTY$ } from 'rxjs'
import {
  filter as filter$,
  map as map$,
  take as take$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { locationChange } from 'models/internalRouter/actions'
import { resetAllLoading } from 'models/state/actions'
import { isActionOf } from 'typesafe-actions'

import { appIsReady, setUserAgent } from './../actions'

const ssrIsEnabled = /true/i.test(process.env.REACT_APP_ENABLE_SSR_FOR_ALL_USERS || '')

export const determineMobileUserAgent: _Store.IEpic = (action$, state$, { deviceDetector }) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    map$(() => {
      return setUserAgent(deviceDetector.determineMobileUserAgent())
    })
  )
}

export const resetLoadingsAlwaysOnLocationChange: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    map$(() => resetAllLoading())
  )
}

export const informThatAppIsReady: _Store.IEpic = (action$) => {
  if (!ssrIsEnabled) {
    return EMPTY$
  }

  return action$.pipe(
    take$(1),
    map$(() => {
      return appIsReady()
    })
  )
}
