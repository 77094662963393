import React, { useEffect } from 'react'

import cn from 'classnames'
import { animated, config, useSpring } from 'react-spring'
import { Route, routes } from 'router/routes'

import styles from 'components/Landing/Landing.module.scss'
import { ILandingProps } from 'components/Landing/Landing.types'
import ArtistSlider from 'components/reusable/ArtistSlider'
import DualLink from 'components/reusable/DualLink'
import Faq from 'components/reusable/Faq'
import Gallery from 'components/reusable/Gallery'
import Timeline from 'components/reusable/Timeline'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import powered_black from 'misc/styles/images/powered_by.svg'
import powered_white from 'misc/styles/images/powered_by_fff.svg'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import EventCards from './components/EventCards'
import InfoSection from './components/InfoSection'
import Partners from './components/Partners'
import useLandingPageStyles from './useLandingPageStyles'

const SECTION_LINK_ID = '#tickets'
const BACK_TO_TOP_LINK_ID = '#header'

const Landing = ({ isLoading, landingPage, mounted }: ILandingProps) => {
  const { i18n } = useDictionary()
  const { isMobile } = useBreakpoints()
  const {
    backgroundGradient,
    bodyScheme,
    borderColorScheme,
    buttonScheme,
    cardTitleScheme,
    headerScheme,
    isDark,
    isHeaderFixed,
    onButtonOut,
    onButtonOver,
    sectionTitleScheme,
    timelineScheme,
  } = useLandingPageStyles(isMobile, landingPage)

  const logoAnimation = useSpring({
    config: config.wobbly,
    delay: 400,
    from: { transform: 'translate3d(0, -10rem, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
  })

  useEffect(() => mounted(), [])

  const footerImage = isDark ? powered_white : powered_black

  return (
    <>
      <div
        className={cn(styles.landingWrapper, isHeaderFixed && styles.fixed)}
        style={headerScheme}
      >
        <div className={styles.headerWrapper} id={BACK_TO_TOP_LINK_ID}>
          <animated.img
            src={landingPage?.header.logo}
            alt={landingPage?.title}
            style={logoAnimation}
          />
          <a
            href={SECTION_LINK_ID}
            className={styles.mainButton}
            type="button"
            style={buttonScheme}
            onMouseOver={onButtonOver}
            onMouseOut={onButtonOut}
          >
            {i18n.buttons.buyTicket}
          </a>
        </div>

        <div className={cn(styles.contentWrapper, isDark && styles.dark)} style={bodyScheme}>
          <div className={styles.mainColumn}>
            {landingPage?.artistSection && (
              <ArtistSlider artistSection={landingPage.artistSection} isDark={isDark} />
            )}

            <h1 className={styles.mainTitle} style={sectionTitleScheme}>
              {landingPage?.title}
            </h1>
            <p className={styles.description}>{landingPage?.description}</p>

            {landingPage?.timeline && (
              <Timeline
                isDark={isDark}
                timeline={landingPage?.timeline}
                timelineScheme={timelineScheme}
              />
            )}

            {landingPage?.infoSection && (
              <InfoSection
                infoSection={landingPage.infoSection}
                sectionTitleScheme={sectionTitleScheme}
              />
            )}

            {landingPage?.events && (
              <EventCards
                backgroundGradient={backgroundGradient}
                borderColorScheme={borderColorScheme}
                cardTitleScheme={cardTitleScheme}
                isDark={isDark}
                events={landingPage?.events}
                buttonScheme={buttonScheme}
                buttonHover={onButtonOver}
                buttonOut={onButtonOut}
                sectionTitleScheme={sectionTitleScheme}
              />
            )}

            {landingPage?.partners && (
              <Partners partners={landingPage?.partners} sectionTitleScheme={sectionTitleScheme} />
            )}

            {landingPage?.gallery && (
              <Gallery isLoading={isLoading} photos={landingPage?.gallery} vertical={true} />
            )}

            {landingPage?.faq && (
              <>
                <h2 className={styles.mainTitle} style={sectionTitleScheme}>
                  {i18n.landing.faqSection}
                </h2>
                <Faq faq={landingPage?.faq} isBgDark={isDark} />
              </>
            )}
          </div>
        </div>

        <div className={styles.footer} style={bodyScheme}>
          <DualLink to={routes[Route.index]}>
            <img src={footerImage} alt={landingPage?.title} />
          </DualLink>
        </div>
      </div>
    </>
  )
}

export default Landing
