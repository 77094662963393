import React, { FC } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import CitiesDropdown from 'components/reusable/CitiesDropdown'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import { useAppState } from 'state/app/hooks/useAppState'

import styles from './LeadSection.module.scss'
import useStyles from './LeadSection.styles'
import { ILeadSectionProps } from './LeadSection.types'

const SectionLead: FC<ILeadSectionProps> = ({
  background,
  color,
  description,
  id,
  medium,
  showDropdown,
  title,
}) => {
  const { isDarkTheme } = useAppState()
  const theme = useTheme()
  const bgColor = isDarkTheme && background ? invertIfGrayScaleColor(background) : background
  const isBgDark = isColorDark(background) || isColorDark(bgColor)

  const muiStyles = useStyles(color, isBgDark)(theme)

  if (description || title) {
    return (
      <div className={cn(styles.leadWrapper, medium && styles.midLead)} id={id}>
        <div className={styles.titleWrapper}>
          <h2
            className={cn(styles.title, showDropdown && styles.withDropdown, muiStyles.fontColor)}
          >
            {title}
          </h2>
          {showDropdown && <CitiesDropdown />}
        </div>
        {description && (
          <p className={cn(styles.description, muiStyles.fontColor)}>{description}</p>
        )}
      </div>
    )
  }

  return null
}

export default SectionLead
