import * as React from 'react'

import '@Styles/global.module.scss'
import { CssBaseline, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enGB, pl } from 'date-fns/locale'
import { mui } from 'theme'

import { Languages } from 'constants/Languages'
import { useThemeFromStore } from 'models/theme/hooks/useThemeFromStore'
import { useLang } from 'state/locale/hooks/useLang'

export const ThemeProvider: React.FC = ({ children }) => {
  const { code } = useLang()
  // TODO: move store logic here
  const theme = useThemeFromStore()

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={code === Languages.English ? enGB : pl}
    >
      <StyledEngineProvider injectFirst={true}>
        <MUIThemeProvider theme={mui(theme).theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}
