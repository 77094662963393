import { createHash } from 'crypto'
import normalizeUrl from 'normalize-url'
import queryString from 'query-string'
import { Route, routes } from 'router/routes'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  concatMap as concatMap$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { IRedirectForPayUData } from 'components/buying/components/RedirectForPayU/RedirectForPayU.types'
import * as CONST from 'components/buying/constants/constants'
import config from 'config'
import { Languages } from 'constants/Languages'
import { PaymentMethods } from 'constants/PaymentMethods'
import { StorageKeys } from 'constants/StorageKeys'
import TransactionError from 'misc/classes/TransactionError'
import replaceColonParamsWithBrackets from 'misc/helpers/replaceColonParamsWithBrackets'
import { addNewsletterSubscriptionToDataLayer } from 'models/analytics/actions'
import { getLocation, getLocation as getLocationFromState } from 'models/connectedRouter/selectors'
import { getEvent as getEventAction } from 'models/event/actions'
import { getEvent } from 'models/event/selectors'
import { IGetEventByIdSuccessPayload } from 'models/event/types'
import { locationChange, navigate, redirectParentTo } from 'models/internalRouter/actions'
import { getIframeParams, getModule, get as getRouter } from 'models/internalRouter/selectors'
import { getPools } from 'models/pools/actions'
import {
  getSeatsGroupedByPoolId,
  getSelectedTickets,
  isDataHydratedTransaction,
} from 'models/pools/selectors'
import getInsuranceProductIds from 'models/pools/selectors/getInsuranceProductIds'
import getPoolsData from 'models/pools/selectors/getPoolsData'
import { IPoolSelectedTicket } from 'models/pools/types'
import { getToken } from 'models/premium/selectors'
import { getProductUserData, getSelectedProducts } from 'models/products/selectors'
import { get as getShipments } from 'models/shipment/selectors'
import getTrafficParams from 'models/trafficParams/selectors/get'
import {
  getCardCredentials,
  getData,
  getTransactionIdFromLocation,
} from 'models/transaction/selectors'
import {
  ITransactionECardPaymentData,
  ITransactionOnSiteSalePaymentData,
  ITransactionPayUPaymentData,
  ITransactionResponse,
} from 'services/$transactions-api/types'
import { LocalStorage } from 'services/LocalStorage'
import { isActionOf } from 'typesafe-actions'

import {
  buyAndPayOnline,
  buyAndPayOnsite,
  failSummaryMounted,
  getPaymentRundateSlug,
  getTransactionDetails,
  getTransactionInfo,
  redirectAfterSale,
  sendTransaction,
  successSummaryMounted,
} from './../actions'
import {
  IGetTransactionDetailsSuccessPayload,
  IGetTransactionInfoSuccessPayload,
  ITransactionBody,
  ITransactionECardPayment,
  ITransactionOnlineUser,
  ITransactionProduct,
  ITransactionWithRedirectPayment,
  PaymentOperatorEnum,
} from './../types'

const URI_TRANSACTION_KEY = 'transactionId'

export const requestOnlineTransactionWhenBuyClicked: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(buyAndPayOnline)),
    withLatestFrom$(state$),
    map$(([action, state]) => {
      const selectedTickets = getSelectedTickets(state)
      const selectedProducts = getSelectedProducts(state)
      const seatsGroupedByPoolId = getSeatsGroupedByPoolId(state)
      const token = getToken(state)
      const dontCheckData = isDataHydratedTransaction(state)
      const trafficParams = getTrafficParams(state)
      const card = getCardCredentials(state)
      const gtmId = getEvent(state)?.gtmId
      const isECardPaymentType = action.payload.data.paymentMethod.type === PaymentMethods.ECARD
      const productUserData = getProductUserData(state)

      const paymentOperator = isECardPaymentType
        ? config.buy.eCardOperator
        : config.buy.defaultOperator

      const embedData = getIframeParams(state)
      const baseUrl = embedData?.currentUrl || config.app.baseUrl
      const language = action.payload.language || Languages.Polski
      const shipments = getShipments(state)
      const totalTicketsAmount = selectedTickets
        .map((ticket) => ticket.amount)
        .reduce((prev, next) => prev + next, 0)

      const checkIsOnShipmentsList = (id: string) =>
        shipments.some((shipment) => String(shipment.productId) === id)

      let paymentFailLink = replaceColonParamsWithBrackets(
        normalizeUrl(
          `${baseUrl}${
            routes[language === Languages.Polski ? Route.paymentFail : Route.enPaymentFail]
          }`
        )
      )

      let paymentSuccessLink = replaceColonParamsWithBrackets(
        normalizeUrl(
          `${baseUrl}${
            routes[language === Languages.Polski ? Route.paymentSuccess : Route.enPaymentSuccess]
          }`
        )
      )

      if (!config.buy.paymentSeparatedLinks) {
        let paymentRegularLink = replaceColonParamsWithBrackets(
          normalizeUrl(
            `${baseUrl}${routes[language === Languages.Polski ? Route.payment : Route.enPayment]}`
          )
        )

        if (!!embedData) {
          paymentRegularLink =
            baseUrl.indexOf('?') > -1
              ? `${baseUrl}&${URI_TRANSACTION_KEY}={${URI_TRANSACTION_KEY}}`
              : `${baseUrl}?${URI_TRANSACTION_KEY}={${URI_TRANSACTION_KEY}}`
        }
        paymentFailLink = paymentSuccessLink = paymentRegularLink
      }

      const onDone: () => null | void = !!embedData ? () => null : action.payload.onDone
      const basketItems = !!embedData ? [] : action.payload.basketItems

      const {
        customTerms,
        deliveryCity,
        deliveryFirstname,
        deliveryLastname,
        deliveryLocalStreet,
        deliveryNumberStreet,
        deliveryPhone,
        deliveryStreet,
        deliveryZip,
        deliveryCountry,
        dependencyCode,
        discount,
        email,
        emailAdditional,
        empikPremiumNumber,
        firstname,
        firstnameAdditional,
        giftWrapper,
        hasDependency,
        invoiceAddress,
        invoiceCheckbox,
        invoiceCity,
        invoiceName,
        invoiceNip,
        invoicePost,
        invoiceCountry,
        isInsurance,
        isAccidentInsurance,
        insuranceIsLivingInPoland,
        insuranceBirthDate,
        lastname,
        lastnameAdditional,
        newsletter,
        paymentMethod,
        pickUpWay,
        prepaidCard,
        prepaidCheckbox,
        terms,
      } = action.payload.data

      const acceptedTerms: number[] = []
      Object.keys(customTerms).forEach((term) => {
        if (customTerms[term]) {
          acceptedTerms.push(parseInt(term, 10))
        }
      })

      const basketCustomTerms: number[] = []

      basketItems.forEach((item) =>
        item.customTerms.forEach((term) => basketCustomTerms.push(term.id))
      )

      const basketAcceptedTerms = [...acceptedTerms].filter((item, pos, self) => {
        return self.indexOf(item) === pos
      })

      const isDefaultCurrency = ![...selectedTickets, ...basketItems].some(
        (item) => item.currency !== config.app.defaultCurrency
      )

      const invoice = !invoiceCheckbox
        ? null
        : {
            address: invoiceAddress,
            city: invoiceCity,
            name: invoiceName,
            nip: invoiceNip,
            post: invoicePost,
            country: !isDefaultCurrency ? invoiceCountry : undefined,
          }
      const discountDependency = hasDependency || dontCheckData ? dependencyCode : null
      const priceReduction = discount.length ? { code: discount.trim() } : undefined

      /* SEND MOBILE AGENT WHEN COMING FROM WEBVIEW WEAPP-2885 */
      const mobileAgent = 'mobile'
      const isWebview = getLocationFromState(state).query?.isWebview === 'true'

      /* SEATSIO HOLDTOKEN WEAPP-2853 */
      const poolsData = getPoolsData(state)
      const isSeatsIo = !!poolsData.seatsIoPublicKey
      const seatsIoObj = sessionStorage.seatsio ? JSON.parse(sessionStorage.seatsio) : {}
      const holdToken = seatsIoObj.holdToken ? seatsIoObj.holdToken : undefined
      const deliveryProduct = checkIsOnShipmentsList(pickUpWay)
        ? [
            {
              id: Number(pickUpWay),
              quantity: 1,
            },
          ]
        : []

      const insuranceProductIds =
        isInsurance && totalTicketsAmount <= config.buy.maxInsuredTicketsLimit
          ? getInsuranceProductIds(state)
          : []

      if (gtmId && !basketItems.length) {
        const paymentFailURL = new URL(paymentFailLink)
        const paymentSuccessURL = new URL(paymentSuccessLink)

        const paramsFail = new URLSearchParams(paymentFailURL.search)
        paramsFail.append('gtmId', gtmId)
        paymentFailURL.search = paramsFail.toString()

        const paramsSuccess = new URLSearchParams(paymentSuccessURL.search)
        paramsSuccess.append('gtmId', gtmId)
        paymentSuccessURL.search = paramsSuccess.toString()

        paymentFailLink = decodeURIComponent(paymentFailURL.toString())
        paymentSuccessLink = decodeURIComponent(paymentSuccessURL.toString())
      }

      const availableProductTypes = ['collectorTicket', 'priorityTicket']
      const poolProducts: { [key: string]: { [key: string]: number } } = {}

      const distributeProducts = () => {
        const productsToDistribute = selectedProducts.filter(
          (product) => product.type && availableProductTypes.includes(product.type)
        )

        productsToDistribute.forEach((product) => {
          let count = 0
          let productAmount = product.amount
          // emptyIndex helps us determine if all products have been matched according to the number of tickets
          let emptyIndex = 0
          while (productAmount > 0) {
            if (product.poolIds?.length) {
              // A function that checks whether it can add a product based on ticket.amount
              const checkChunk = (index: number) => {
                // we are looking for a ticket based on the poolId assigned to the product
                const ticket = selectedTickets.find(
                  (value) => value.poolId === product.poolIds?.[index]
                )

                // we check whether the product has an assigned poolId, whether the found ticket exists and whether the number of tickets is lower than the already assigned products
                if (
                  product.poolIds &&
                  ticket &&
                  poolProducts[product.poolIds[index]][product.id] < ticket.amount
                ) {
                  // if it meets the conditions, we increase the number of products of a given pool by one, subtract the number of general products by one and set emptyIndex to zero
                  poolProducts[product.poolIds[index]][product.id]++
                  productAmount--
                  emptyIndex = 0
                } else {
                  // if it does not meet the conditions, we increase emptyIndex by one
                  emptyIndex++
                }
              }

              if (count < product.poolIds.length) {
                // we check if poolId data exists in poolProducts, if not, we create an object with this name
                if (!poolProducts[product.poolIds[count]]) {
                  poolProducts[product.poolIds[count]] = {}
                }

                // we check if the given product already exists in the pool object
                if (poolProducts[product.poolIds[count]][product.id]) {
                  // if so, we call checkChunk
                  checkChunk(count)
                } else {
                  // if not we set the product value to one and subtract the quantity of general products
                  poolProducts[product.poolIds[count]][product.id] = 1
                  productAmount--
                }

                count++
              } else {
                count = 0
              }

              // The loop breaks and adds the rest of the products one by one if we have more products than tickets selected

              if (emptyIndex === selectedTickets.length) {
                while (productAmount > 0) {
                  if (count < product.poolIds.length) {
                    if (poolProducts[product.poolIds[count]][product.id]) {
                      poolProducts[product.poolIds[count]][product.id]++
                    }

                    count++
                    productAmount--
                  } else {
                    count = 0
                  }
                }
                break
              }
            }
          }
        })

        return poolProducts
      }

      const distributedProducts = distributeProducts()

      const body: ITransactionBody = {
        // TODO:
        agent: isWebview ? mobileAgent : !!embedData ? 'going-integration' : config.app.salesAgent,
        discountCode:
          discountDependency ||
          basketItems.find((item) => item.hasDependency)?.dependencyCode ||
          null,
        holdToken: isSeatsIo ? holdToken : undefined,
        invoice,
        insurance: isInsurance
          ? {
              birthDate: insuranceBirthDate,
              isLivingInPoland: insuranceIsLivingInPoland,
              isAccidentInsurance,
            }
          : undefined,
        language,
        linkFail: paymentFailLink,
        linkOk: paymentSuccessLink,
        paymentDetails: !isECardPaymentType
          ? {
              ...paymentMethod,
              ...(paymentMethod.type === PaymentMethods.CARD && { card }),
            }
          : undefined,
        paymentOperator,
        prepaidCard: prepaidCheckbox ? prepaidCard : '',
        priceReduction,
        products: [
          ...selectedProducts
            .filter((product) => product.type && !availableProductTypes.includes(product.type))
            .map((item) => ({
              id: item.id,
              quantity: item.amount,
            })),
          ...basketItems
            .filter((value) => value.isProduct)
            .map((item) => {
              return {
                id: item.id,
                quantity: item.amount,
              }
            }),
          ...deliveryProduct,
        ],
        salesChannelId: config.app.salesChannelId,
        tickets: [
          ...selectedTickets.map((item: IPoolSelectedTicket, idx: number) => {
            const insuranceProduct = insuranceProductIds.find((ip) => item.poolId === ip.poolId)

            const products = distributedProducts[item.poolId]
              ? Object.keys(distributedProducts[item.poolId]).map((productId) => ({
                  id: Number(productId),
                  quantity: distributedProducts[item.poolId][productId],
                }))
              : []

            if (isInsurance && insuranceProduct) {
              products.push({
                quantity: insuranceProduct.amount,
                id: insuranceProduct.id,
              })
            }

            const productsWithUserData: ITransactionProduct[] = []

            for (const product of products) {
              if (productUserData[`id_${product.id}`]) {
                ;[...Array(product.quantity)].forEach((_, index) => {
                  productsWithUserData.push({
                    ...product,
                    endUser: {
                      email,
                      firstName: productUserData?.[`id_${product.id}`]?.[index].firstName,
                      lastName: productUserData?.[`id_${product.id}`]?.[index].lastName,
                    },
                    quantity: 1,
                  })
                })
              } else {
                productsWithUserData.push(product)
              }
            }

            return {
              detailedUsers: item.additionalFields
                ? firstnameAdditional[`id_${item.poolId}`]?.map((name, index) => {
                    return {
                      email: emailAdditional[`id_${item.poolId}`][index],
                      firstname: name,
                      lastname: lastnameAdditional[`id_${item.poolId}`][index],
                    }
                  }) || []
                : [],
              poolId: item.poolId,
              products: productsWithUserData,
              seats: seatsGroupedByPoolId[item.poolId],
              submissions: item.submissions,
              ticketsNum: item.amount,
              /** TODO: remove when service fee test is over */
              serviceFee: item.serviceFee,
            }
          }),
          ...basketItems.map((item) => ({
            detailedUsers: item.additionalFields
              ? firstnameAdditional[`id_${item.id}`]?.map((name, index) => {
                  return {
                    email: emailAdditional[`id_${item.id}`][index],
                    firstname: name,
                    lastname: lastnameAdditional[`id_${item.id}`][index],
                  }
                }) || []
              : [],
            poolId: item.id,
            seats: item.seats,
            submissions: item.submissions,
            ticketsNum: item.amount,
          })),
        ],
        user: {
          acceptedTerms: basketAcceptedTerms,
          email,
          empikCardNumber: empikPremiumNumber || null,
          empikPremiumJWT: token,
          facebookId: null,
          firstName: firstname,
          lastName: lastname,
          newsletter,
          terms,
        },
        utmTags: trafficParams || undefined,
      }

      if (pickUpWay === CONST.PICK_UP_WAY_POST || checkIsOnShipmentsList(pickUpWay)) {
        const shipmentDetails = {
          address: `${deliveryStreet} ${deliveryNumberStreet} / ${deliveryLocalStreet}`,
          city: deliveryCity,
          contactPhone: deliveryPhone,
          giftWrapper,
          name: `${deliveryFirstname} ${deliveryLastname}`,
          zip: deliveryZip,
          country: deliveryCountry,
        }

        body.shipmentDetails = shipmentDetails
      }

      return sendTransaction.request({ body, onDone })
    })
  )
}

export const requestOnsiteTransactionWhenBuyClicked: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(buyAndPayOnsite)),
    withLatestFrom$(state$),
    map$(([action, state]) => {
      const selectedTickets = getSelectedTickets(state)
      const seatsGroupedByPoolId = getSeatsGroupedByPoolId(state)
      const event = getEvent(state)
      const shipments = getShipments(state)

      let linkCancel
      if (event) {
        linkCancel = `${config.app.baseUrl}/kup-bilety/${event.eventSlug}/${event.rundateSlug}`
      }

      const {
        authId,
        deliveryCity,
        deliveryFirstname,
        deliveryLastname,
        deliveryLocalStreet,
        deliveryNumberStreet,
        deliveryPhone,
        deliveryStreet,
        deliveryZip,
        emailAdditional,
        empikPremiumNumber,
        firstnameAdditional,
        giftWrapper,
        lastnameAdditional,
        pickUpWay,
      } = action.payload.data
      const { onDone, basketItems } = action.payload

      const checkIsOnShipmentsList = (id: string) =>
        shipments.some((shipment) => String(shipment.productId) === id)

      /* SEATSIO HOLDTOKEN WEAPP-2853 */
      const poolsData = getPoolsData(state)
      const isSeatsIo = !!poolsData.seatsIoPublicKey

      const seatsIoObj = sessionStorage.seatsio ? JSON.parse(sessionStorage.seatsio) : {}
      const holdToken = seatsIoObj.holdToken ? seatsIoObj.holdToken : undefined
      const deliveryProduct = checkIsOnShipmentsList(pickUpWay)
        ? [
            {
              id: Number(pickUpWay),
              quantity: 1,
            },
          ]
        : []

      const body: ITransactionBody = {
        agent: config.app.salesAgent,
        discountCode: null,
        holdToken: isSeatsIo ? holdToken : undefined,
        invoice: null,
        language: Languages.Polski,
        linkCancel,
        linkFail: `${config.app.baseUrl}${routes[Route.paymentFail]}`,
        linkOk: `${config.app.baseUrl}${routes[Route.paymentSuccess]}`,
        paymentOperator: PaymentOperatorEnum.virtual,
        products: deliveryProduct,
        salesChannelId: config.app.salesChannelId,
        tickets: [
          ...selectedTickets.map((item: IPoolSelectedTicket) => ({
            detailedUsers: item.additionalFields
              ? firstnameAdditional[`id_${item.poolId}`]?.map((name, index) => ({
                  email: emailAdditional[`id_${item.poolId}`][index],
                  firstname: name,
                  lastname: lastnameAdditional[`id_${item.poolId}`][index],
                })) || []
              : [],
            poolId: item.poolId,
            seats: seatsGroupedByPoolId[item.poolId],
            submissions: item.submissions,
            ticketsNum: item.amount,
          })),
          ...basketItems.map((item) => ({
            poolId: item.id,
            seats: seatsGroupedByPoolId[item.id],
            submissions: item.submissions,
            ticketsNum: item.amount,
          })),
        ],
        user: {
          authId,
          empikCardNumber: empikPremiumNumber,
        },
      }

      if (pickUpWay === CONST.PICK_UP_WAY_POST || checkIsOnShipmentsList(pickUpWay)) {
        const shipmentDetails = {
          address: `${deliveryStreet} ${deliveryNumberStreet} ${deliveryLocalStreet}`,
          city: deliveryCity,
          contactPhone: deliveryPhone,
          giftWrapper,
          name: `${deliveryFirstname} ${deliveryLastname}`,
          zip: deliveryZip,
        }

        body.shipmentDetails = shipmentDetails
      }

      return sendTransaction.request({ body, onDone })
    })
  )
}

export const sendTransactionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { transactionsApi, brazeApi }
) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.request)),
    mergeMap$((action) => {
      return from$(transactionsApi.sendTransaction(action.payload.body)).pipe(
        mergeMap$((data: ITransactionResponse) => {
          let paymentData:
            | ITransactionECardPayment
            | ITransactionWithRedirectPayment
            | ITransactionOnSiteSalePaymentData
            | null = null
          if (data.paymentOperator === PaymentOperatorEnum.eCard) {
            const payment = data.payment as ITransactionECardPaymentData
            paymentData = transactionsApi.normalizeECardPayment(payment)
          } else if (data.paymentOperator === PaymentOperatorEnum.payU) {
            const payment = data.payment as ITransactionPayUPaymentData
            paymentData = transactionsApi.normalizePayUPayment(payment)
          } else {
            paymentData = data.payment as ITransactionOnSiteSalePaymentData
          }

          const onlineTransactionPayloadUser = action.payload.body.user as ITransactionOnlineUser
          LocalStorage.set(StorageKeys.BasketItems, [])
          if (onlineTransactionPayloadUser.newsletter && config.theme.isGoing) {
            const sha256email = createHash('sha256')
              .update(onlineTransactionPayloadUser.email)
              .digest('hex')

            return from$(brazeApi.addUserToNewsletter(onlineTransactionPayloadUser.email)).pipe(
              mergeMap$(() => [
                addNewsletterSubscriptionToDataLayer(sha256email),
                sendTransaction.success({
                  data: paymentData,
                  paymentOperator: data.paymentOperator,
                  onDone: action.payload.onDone,
                }),
              ])
            )
          }

          return [
            sendTransaction.success({
              data: paymentData,
              paymentOperator: data.paymentOperator,
              onDone: action.payload.onDone,
            }),
          ]
        }),
        catchError$((error: TransactionError) => of$(sendTransaction.failure(error)))
      )
    })
  )
}

export const redirectPaymentOnEmbed: _Store.IEpic = (action$, state$, { iframeProvider }) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const embedData = getIframeParams(state)
      const redirectData = getData(state) as IRedirectForPayUData

      if (
        (action.payload.paymentOperator === PaymentOperatorEnum.payU ||
          action.payload.paymentOperator === PaymentOperatorEnum.free) &&
        !!embedData
      ) {
        iframeProvider.runRedirectParentMethod(redirectData.formUrl)
      }

      return EMPTY$
    })
  )
}

export const fetchTransactionInfoWhenRequested: _Store.IEpic = (action$, _, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(getTransactionInfo.request)),
    mergeMap$((action) => {
      return from$(analytics.getTransactionInfo(action.payload)).pipe(
        concatMap$((data: IGetTransactionInfoSuccessPayload) => {
          return [getTransactionInfo.success(data), getTransactionDetails.request(action.payload)]
        }),
        catchError$((error: Error) => of$(getTransactionInfo.failure(error)))
      )
    })
  )
}

export const fetchTransactionDetailsWhenRequested: _Store.IEpic = (action$, _, { analytics }) => {
  return action$.pipe(
    filter$(isActionOf(getTransactionDetails.request)),
    mergeMap$((action) => {
      return from$(analytics.getTransactionDetails(action.payload)).pipe(
        mergeMap$((data: IGetTransactionDetailsSuccessPayload) => {
          return of$(
            getTransactionDetails.success(data),
            redirectAfterSale(data.transactionItems[0].uuid)
          )
        }),
        catchError$((error: Error) => of$(getTransactionDetails.failure(error)))
      )
    })
  )
}

export const runRedirectAfterSaleWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(redirectAfterSale)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const transactionUuid = action.payload
      const redirectAfterSaleUrl = getIframeParams(state)?.redirectAfterSaleUrl
      if (redirectAfterSaleUrl && transactionUuid) {
        return of$(redirectParentTo(`${redirectAfterSaleUrl}?transactionHash=${transactionUuid}`))
      }

      return EMPTY$
    })
  )
}

export const removeHoldTokenWhenTransactionWasSent: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.success)),
    mergeMap$(() => {
      sessionStorage.removeItem('seatsio')

      return EMPTY$
    })
  )
}

export const removeBasketItemsWhenTransactionWasSent: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.success)),
    mergeMap$((action) => {
      action.payload.onDone()

      return EMPTY$
    })
  )
}

export const fetchTransactionInfoAndDetailsWhenSummaryMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([successSummaryMounted, failSummaryMounted])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const transactionId = getTransactionIdFromLocation(state)
      const isEmbed = !!getIframeParams(state)

      if (isEmbed) {
        const currentUrl = getRouter(state).embed.currentUrl || ''
        const search = queryString.parseUrl(currentUrl)

        if (search.query.transactionId) {
          return [getTransactionInfo.request(search.query.transactionId as string)]
        }
      }

      if (transactionId) {
        return [getTransactionInfo.request(transactionId)]
      }

      return EMPTY$
    })
  )
}

export const redirectPaymentWhenLocationChange: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] === routes[Route.buyLegacy].split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      return of$(getPaymentRundateSlug.request(Number(getLocation(state).pathname.split('/')[2])))
    })
  )
}

export const getPaymentRundateSlugWhenRequest: _Store.IEpic = (action$, state$, { eventsApi }) => {
  return action$.pipe(
    filter$(isActionOf(getPaymentRundateSlug.request)),
    mergeMap$((action) => {
      return from$(eventsApi.getEventById(action.payload)).pipe(
        map$((data: IGetEventByIdSuccessPayload) => {
          return getPaymentRundateSlug.success(data)
        }),
        catchError$((error: Error) =>
          of$(getPaymentRundateSlug.failure(error), navigate({ to: routes[Route.index] }))
        )
      )
    })
  )
}

export const redirectPaymentWhenRundateSlugSuccess: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getPaymentRundateSlug.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const params = {
        eventSlug: action.payload.event.slug,
        rundateSlug: action.payload.slug,
      }
      const module = getModule(state)

      return of$(
        locationChange({ module, params }),
        getPools.request(params),
        getEventAction.request()
      )
    })
  )
}
