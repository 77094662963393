import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { searchPageIndexState } from '../atoms/searchPageIndex'
import { sourceIdsFilterState } from '../atoms/sourceIdsFilter'

/**
 * Source ids filter setter selector. The "set" method resets search page to initial state,
 * each time new value is set.
 */
export const setSourceIdsFilter = selector({
  get: ({ get }) => get(sourceIdsFilterState),
  set: ({ get, set, reset }, newValue) => {
    set(sourceIdsFilterState, newValue)
    reset(searchPageIndexState)
  },
  key: RecoilKeys.setSourceIdsFilter,
})
