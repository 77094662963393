import { createSelector } from 'reselect'

import _Store from '@Store'

import { IInternalRouterReducer } from './../types'
import get from './get'

const getCartButtonEnabled = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  boolean | undefined
>([get], (embed) => embed.embed.cartButtonEnabled)

export default getCartButtonEnabled
