import React, { MouseEvent } from 'react'

import { Button, Typography, useTheme } from '@mui/material'
import cn from 'classnames'

import { MarkdownText } from 'components/reusable/MarkdownText'
import { DataLayerEvents } from 'constants/Analytics'
import isColorDark from 'misc/helpers/isColorDark'
import slugifyString from 'misc/helpers/slugifyString'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import Analytics from 'services/$analytics'

import useStyles from './TextBlock.styles'
import { ITextBlockProps } from './TextBlock.types'

const B2B_PAGE_CONTACT_TEXT_BLOCK_ID = 'text_block_with_image-3018'

const TextBlock = ({
  color,
  reversed,
  title,
  formattedDescription,
  cta,
  link,
  sectionBgColor,
  src,
  compositionIdentifier,
  brickId,
}: ITextBlockProps) => {
  const isBgDark = isColorDark(sectionBgColor)
  const theme = useTheme()
  const { isMobile } = useBreakpoints()
  const muiStyles = useStyles(reversed, isMobile, isBgDark, color)(theme)

  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    link
      ? (() => {
          Analytics.pushForHomepageSelectItem(e, link, cta, title)

          if (brickId === B2B_PAGE_CONTACT_TEXT_BLOCK_ID) {
            Analytics.pushForB2BPageAnalytics({
              event: DataLayerEvents.Interaction,
              eventInfo: {
                detail: 'navigate to b2b contact form',
                text_displayed: cta,
              },
            })
          }
        })()
      : undefined

  return (
    <div className={cn(muiStyles.root, muiStyles.fontColor)}>
      <div className={muiStyles.centerAlign}>
        <Typography
          variant={'h2'}
          children={title}
          className={isMobile ? muiStyles.h2mobile : undefined}
        />
        <MarkdownText text={formattedDescription} />
        {link && (
          <div className={muiStyles.buttonContainer}>
            <Button
              size={'large'}
              children={cta}
              href={link}
              className={cn(
                muiStyles.fontColor,
                muiStyles.button,
                `${compositionIdentifier}-${brickId}-${slugifyString(title)}`
              )}
              onClick={handleAddToDataLayerOnClick}
            />
            {!isMobile && <div />}
          </div>
        )}
      </div>
      {!isMobile && !src && <div />}
      {src && (
        <div className={muiStyles.centerAlign}>
          <img src={src} className={muiStyles.image} />
        </div>
      )}
    </div>
  )
}

export default TextBlock
