import React, { useEffect, useState } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Chip, Link, Tooltip, Typography } from '@mui/material'
import qs from 'qs'
import { Link as RouterLink } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { useStyles } from 'components/reusable/CloudSearchCard/CloudSearchCard.styles'
import { ICloudSearchCardProps } from 'components/reusable/CloudSearchCard/CloudSearchCard.types'
import ImageFixed from 'components/reusable/ImageFixed'
import { Tags } from 'components/reusable/Tags'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { Languages } from 'constants/Languages'
import { cloudSearchBoolean } from 'misc/helpers/cloudSearchBoolean'
import { DataMutations } from 'services/DataMutations'
import { DateTime } from 'services/DateTime'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'
import { IDateTranslate } from 'types/Locale'

import { useAppState } from '../../../state/app/hooks/useAppState'
import { getServiceFeeTest } from '../../../state/remoteConfig/selectors/getServiceFeeTest'

const formatSearchCardDates = (
  type: SearchTypes,
  dateTranslate: IDateTranslate,
  start_date?: string,
  end_date?: string,
  timezone?: string
) => {
  const time = type === SearchTypes.RUNDATE && start_date && DateTime.getFriendlyTime(start_date)
  const date = DateTime.formatRundateDates({
    startDate: start_date,
    endDate: end_date,
    dateTranslate,
    timeZone: timezone,
  })

  if (time && date) return `${date}, ${time}`
  if (date) return date
}

export const CloudSearchCard = ({
  item: {
    id,
    fields: {
      name_pl,
      type,
      thumbnail,
      name_en,
      caption,
      timezone,
      start_date,
      end_date,
      sale_enabled,
      slug,
      rundate_slug,
      id_source,
      price,
      currency,
      price_with_service_fee,
      price_description,
      price_service_fee,
      category_id,
      category_name,
      category_slug,
      tags_names,
      tags_ids,
      tags_slugs,
    },
  },
  sx,
}: ICloudSearchCardProps) => {
  const styles = useStyles({})
  const { i18n } = useDictionary()
  const splitCaption = type === SearchTypes.EVENT ? caption?.split(',') : undefined
  const _caption =
    type === SearchTypes.EVENT && splitCaption?.length
      ? splitCaption?.length < 5
        ? splitCaption?.join(', ')
        : splitCaption?.slice(0, 4).join(', ') + ' ' + i18n.event.andOthers
      : caption

  /** TODO: remove when service fee test is over */
  const { isWebview } = useAppState()
  const [feeData, setFeeData] = useState<{
    serviceFee: typeof price_service_fee
    priceWithServiceFee: typeof price_with_service_fee
  }>({ serviceFee: price_service_fee, priceWithServiceFee: price_with_service_fee })
  useEffect(() => {
    const feeTest = getServiceFeeTest({
      rundateID: type === SearchTypes.RUNDATE && id_source,
      price,
      serviceFee: price_service_fee,
      priceWithServiceFee: price_with_service_fee,
      isWebview,
    })
    if (feeTest.isCustomFee) {
      setFeeData({
        serviceFee: feeTest.serviceFee,
        priceWithServiceFee: feeTest.priceWithServiceFee,
      })
    }
  }, [type, id_source, price, price_service_fee, price_with_service_fee, isWebview])
  /** remove when service fee test is over */

  const to = {
    [SearchTypes.ACTIVITY]: prepareRoute({
      route: Route.activity,
      params: { eventSlug: slug },
    }),
    [SearchTypes.RUNDATE]: prepareRoute({
      route: Route.rundate,
      params: { eventSlug: slug, rundateSlug: rundate_slug ?? '' },
    }),
    [SearchTypes.EVENT]: prepareRoute({
      route: Route.event,
      params: { eventSlug: slug },
    }),
    [SearchTypes.ARTIST]: prepareRoute({
      route: Route.artist,
      params: { slug },
    }),
    [SearchTypes.PLACE]: prepareRoute({
      route: Route.place,
      params: { slug },
    }),
    [SearchTypes.TAG]:
      routes[Route.search] +
      qs.stringify(
        {
          [HitFields.tags_names]: name_pl,
          [HitFields.tags_ids]: id_source ?? id.split('_')[1],
          [HitFields.tags_slugs]: slug,
        },
        { addQueryPrefix: true }
      ),
  }

  const rootStyles = { ...styles.root, ...sx }

  return (
    <Box sx={rootStyles} component={'li'}>
      <Link sx={styles.link} component={RouterLink} to={to[type]}>
        <ImageFixed
          transformation={{ width: 405, height: 350 }}
          src={thumbnail}
          alt={i18n._lang === Languages.English ? name_pl : name_en}
        >
          {cloudSearchBoolean(sale_enabled) && (
            <Chip
              sx={styles.onSale}
              size={'small'}
              color={'primary'}
              label={i18n.searchCard.onSale}
            />
          )}
        </ImageFixed>
        <Box sx={styles.body}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Tags
              size={'small'}
              tags={DataMutations.combineCloudsearchTags({
                category_id,
                category_name,
                category_slug,
                tags_names,
                tags_ids,
                tags_slugs,
                i18n,
                isTour: type === SearchTypes.EVENT,
              })}
            />
            <Tooltip title={name_pl?.length > 42 && name_pl}>
              <Typography component={'h2'} sx={styles.title} variant={'h6'} children={name_pl} />
            </Tooltip>
            {type !== SearchTypes.RUNDATE && type !== SearchTypes.EVENT && (
              <Typography
                variant={'subtitle2'}
                color={'text.secondary'}
                children={i18n.searchCard[type]}
              />
            )}
          </Box>
          <Box sx={styles.footer}>
            <Typography
              variant={'subtitle2'}
              children={formatSearchCardDates(type, i18n.dates, start_date, end_date, timezone)}
            />
            <Typography variant={'subtitle2'} color={'secondary'} children={_caption} />
            {price && price_with_service_fee && currency && (
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', gap: 1 }}>
                <Typography
                  variant={'subtitle2'}
                  children={DataMutations.friendlyPrice({
                    price: feeData.priceWithServiceFee,
                    currency,
                    priceDescription: price_description,
                    prefix: i18n.price.from,
                  })}
                />
                {price_description !== 'TBA' && !!price_service_fee && (
                  <Tooltip
                    title={i18n.event.serviceFeeCaption(
                      DataMutations.formatServiceFeePrice(feeData.serviceFee, currency)
                    )}
                  >
                    <InfoOutlined color={'secondary'} fontSize={'small'} />
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Link>
    </Box>
  )
}
