import * as React from 'react'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { FieldValues, useController } from 'react-hook-form'

import { IRadioGroupInputProps } from 'components/_forms/RadioGroupInput/RadioGroupInput.types'

export const RadioGroupInput = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  label,
  rules,
  shouldUnregister,
  options,
}: IRadioGroupInputProps<T>) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
  })

  return (
    <FormControl component={'fieldset'} error={invalid}>
      {!!label && <FormLabel component={'legend'} children={label} />}
      <RadioGroup name={name} value={value} onChange={onChange} onBlur={onBlur}>
        {options.map((option) => (
          <FormControlLabel
            key={String(option.value)}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {invalid && <FormHelperText children={error?.message} error={invalid} />}
    </FormControl>
  )
}
