import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invert: {
      filter: 'invert(1)',
    },
  })
)

export default useStyles
