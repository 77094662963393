import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { validateForm } from 'models/analytics/actions'
import { updateBasketStateFromContext } from 'models/basket/actions'
import { getBasketData } from 'models/basket/selector'
import { buyFormMounted } from 'models/event/actions'
import { getCustomTerms, getEvent } from 'models/event/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import isDataHydratedTransaction from 'models/pools/selectors/isDataHydratedTransaction'
import { buyAndPayOnline } from 'models/transaction/actions'

import Online from './Online.component'
import { getInitialValues } from './Online.selectors'
import {
  IBuyingOnlineFromDispatch,
  IBuyingOnlineFromState,
  IBuyingOnlineOwnProps,
} from './Online.types'

const mapStateToProps = (state: _Store.IState): IBuyingOnlineFromState => ({
  currentBasketData: getBasketData(state),
  customTerms: getCustomTerms(state),
  eventSlug: getEvent(state)?.eventSlug,
  initialValues: (basketCustomTerms) => getInitialValues(state, basketCustomTerms),
  isDataHydratedTransaction: isDataHydratedTransaction(state),
  isEmbed: !!getIframeParams(state),
  queue: getEvent(state)?.queue,
  rundateSlug: getEvent(state)?.rundateSlug,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IBuyingOnlineFromDispatch => {
  return {
    buyAndPay: (data) => dispatch(buyAndPayOnline(data)),
    checkout: () => dispatch(validateForm()),
    mounted: () => dispatch(buyFormMounted()),
    updateBasketState: (basketState) => dispatch(updateBasketStateFromContext(basketState)),
  }
}

export default connect<
  IBuyingOnlineFromState,
  IBuyingOnlineFromDispatch,
  IBuyingOnlineOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Online)
