import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { happeningMounted } from 'models/happening/actions'
import {
  getData,
  getExtendedSlots,
  getIsSelectedSlotLastInCurrentDay,
  getSelected,
  getSelectedSlotHasUpsell,
  isAllSelected,
} from 'models/happening/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import { mountProducts } from 'models/products/actions'
import { getAreAnyProductsAvailable } from 'models/products/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import { getTheme } from 'models/theme/selectors'

import Happening from './Happening.component'
import { IHappeningFromDispatch, IHappeningFromState } from './Happening.types'

const mapStateToProps = (state: _Store.IState): IHappeningFromState => ({
  areAnyProductsAvailable: getAreAnyProductsAvailable(state),
  calculatePricePerPerson: getData(state)?.calculatePricePerPerson,
  canReserve: isAllSelected(state),
  happeningEndDate: getData(state)?.endDate,
  happeningSpaces: getData(state)?.spaces,
  happeningStartDate: getData(state)?.startDate,
  hasExtendedSlot: !!getExtendedSlots(state).length,
  isEmbed: !!getIframeParams(state),
  isActivityEventLoading: getLoading(CONST.EVENT)(state),
  isHappeningLoading: getLoading(CONST.HAPPENING)(state),
  isLoadingPrice: getLoading(CONST.HAPPENING_PRICE)(state),
  isSelectedSlotLastInCurrentDay: getIsSelectedSlotLastInCurrentDay(state),
  selectedSpace: getSelected(state).space,
  selectedTheme: getTheme(state),
  slotHasUpsell: getSelectedSlotHasUpsell(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IHappeningFromDispatch => ({
  mountProducts: () => dispatch(mountProducts()),
  mounted: () => dispatch(happeningMounted()),
})

export default connect<IHappeningFromState, IHappeningFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Happening)
