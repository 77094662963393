import React from 'react'

import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import styles from './Place.module.scss'
import { IPlaceProps } from './Place.types'

const Place = ({ customClassName, isLoading, placeSlug, place }: IPlaceProps) => {
  if (isLoading || !place) {
    return <div className={styles.fakePlace} />
  }

  return (
    <h4 className={cn(styles.value, customClassName)}>
      {placeSlug && (
        <Link
          to={prepareRoute({
            route: Route.place,
            params: { slug: placeSlug },
          })}
          children={place}
        />
      )}
      {!placeSlug && place}
    </h4>
  )
}

export default Place
