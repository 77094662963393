import { createSelector } from 'reselect'

import config from 'config'
import { getLocation } from 'models/connectedRouter/selectors'
import { getLandingPage } from 'models/landingPage/selectors'

import * as TEXTS from './../constants/texts'
import { IMeta, IMetaValue } from './../types'

const landingPageMeta = createSelector(
  [getLandingPage, getLocation],
  (landingPage, location): IMeta => {
    let pageName: IMetaValue = TEXTS.LOADING
    let description: IMetaValue = TEXTS.LOADING
    let ogDescription: IMetaValue = TEXTS.LOADING
    let ogImage: string | undefined

    if (landingPage) {
      pageName = [landingPage.title, TEXTS.LANDING_PAGE]
      description = [landingPage.description, TEXTS.LANDING_PAGE]
      ogDescription = [landingPage.description, TEXTS.LANDING_PAGE]
      ogImage = landingPage.header.ogImage ? landingPage.header.ogImage : landingPage.header.logo
    }

    return {
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      description,
      ogDescription,
      ogImage,
      pageName,
    }
  }
)

export default landingPageMeta
