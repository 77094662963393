import { TicketActionErrors } from 'services/Api/TicketManagementApi'
import { ITransferTicketResponse } from 'types/TicketManagement'

const ticketTransferErrorsDefinition: ITransferTicketResponse[] = [
  {
    errorCode: TicketActionErrors.INTERNAL_ERROR,
    errorMessage: 'Błąd wewnętrzny: ',
    status: false,
  },
  {
    errorCode: TicketActionErrors.INVALID_EMAIL,
    errorMessage: 'Adres email odbiorcy biletu jest niepoprawny. Spróbuj ponownie.',
    status: false,
  },
  {
    errorCode: TicketActionErrors.INVALID_TOKEN,
    errorMessage:
      'Sesja przedawniona. Aby rozpocząć ponownie, kliknij ikonkę biletu na pasku nawigacji i wybierz WRÓĆ.',
    status: false,
  },
]

export default ticketTransferErrorsDefinition
