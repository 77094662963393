import React from 'react'

import { Breadcrumbs } from 'components/Breadcrumbs'
import TicketReception from 'components/TicketReception'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/StaticPage'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const TicketReceptionPage = () => {
  const { i18n } = useDictionary()

  return (
    <PageWrapper>
      <Helmet pageName={i18n.ticketsManagement.receiveTitle} />
      <Breadcrumbs
        base={[
          {
            link: '',
            label: i18n.breadcrumbs.ticketsManagement,
          },
        ]}
        current={i18n.ticketsManagement.receiveTitle}
      />
      <TicketReception />
    </PageWrapper>
  )
}
