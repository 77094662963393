import * as React from 'react'

import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { PageWrapper } from 'components/_layout/PageWrapper'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './SendMailSuccess.styles'

export const SendMailSuccess = () => {
  const { i18n } = useDictionary()
  const muiStyles = useStyles()
  const navigate = useNavigate()

  const redirect = () => navigate(routes[Route.index])

  return (
    <PageWrapper>
      <Grid
        container={true}
        spacing={5}
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        className={muiStyles.container}
      >
        <Grid className={muiStyles.icon} item={true} xs={12}>
          <MailOutlineIcon fontSize={'large'} />
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant={'h3'} align={'center'} className={muiStyles.h3}>
            {i18n.sendMailSuccess.title}
          </Typography>
          <Typography variant={'body1'} align={'center'} className={muiStyles.body1}>
            {i18n.sendMailSuccess.description}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            className={muiStyles.button}
            variant={'contained'}
            size={'medium'}
            color={'primary'}
            onClick={redirect}
          >
            {i18n.sendMailSuccess.button}
          </Button>
        </Grid>
      </Grid>
    </PageWrapper>
  )
}
