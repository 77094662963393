import React, { FC, useEffect } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Button, Tooltip, Typography, useTheme } from '@mui/material'
import { useFormikContext } from 'formik'
import { useForm, useWatch } from 'react-hook-form'

import Buttons from 'components/Products/components/Buttons'
import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import Description from 'components/buying/Pools/components/Description'
import { CurrencyISO, currencySymbol } from 'constants/Currency'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import useUpdateEffect from 'misc/hooks/useUpdateEffect'
import { PackageIcon } from 'misc/styles/muiIcons'
import { IProductUserData } from 'models/products/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles, useSxStyles } from './Product.styles'
import { IProductProps } from './Product.types'

const Product: FC<IProductProps> = ({
  product,
  productsLimit,
  selectedProducts,
  userData,
  updateProductData,
  isInvalid,
}) => {
  const theme = useTheme()
  const { i18n } = useDictionary()
  const sxStyles = useSxStyles(theme)({})
  const styles = useStyles()
  const { isMobile } = useBreakpoints()
  const formikContext = useFormikContext<IBuyingOnlineFormValues>()
  const {
    control,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<{ productUserData: IProductUserData }>({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues: {
      productUserData: {
        [`id_${product.id}`]: [
          {
            firstName: '',
            lastName: '',
          },
        ],
      },
    },
  })
  const values = useWatch({ control })
  const showDescription = product.description && product.description.length > 0

  const selectedProduct = selectedProducts.find((element) => element.id === product?.id)
  let ticketNumber = 1

  const fillWithBuyerData = () => {
    setValue(`productUserData.id_${product.id}.0.firstName`, userData?.firstName || '')
    setValue(`productUserData.id_${product.id}.0.lastName`, userData?.lastName || '')
  }

  useEffect(() => {
    if (updateProductData && values.productUserData) {
      updateProductData(values.productUserData)
    }
  }, [values])

  useUpdateEffect(() => {
    trigger()
  }, [isInvalid])

  useEffect(() => {
    formikContext?.setStatus({ isValid })
  }, [isValid])

  return (
    <Flex gap={3} fullWidth>
      <Flex
        justify={'space-between'}
        align={'center'}
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 4 : 0}
        sx={sxStyles.container}
        fullWidth
      >
        <Flex>
          <Flex gap={2} align={'center'} direction={isMobile ? 'column' : 'row'}>
            <Flex>
              {product.imageUrl ? (
                <img className={styles.productImage} src={product.imageUrl} />
              ) : (
                <PackageIcon />
              )}
            </Flex>
            <Flex>
              <Flex direction={'column'}>
                <Flex>
                  <Flex align={'center'}>
                    <Flex>
                      <Typography variant={'h6'}>{product.name}</Typography>
                    </Flex>
                    {product.type === 'collectorTicket' && (
                      <Flex>
                        <Tooltip
                          title={i18n.buy.products.collectorTicket}
                          enterTouchDelay={100}
                          leaveTouchDelay={6000}
                        >
                          <InfoIcon sx={sxStyles.tooltip} />
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                {showDescription && <Description text={product.description || ''} />}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <Typography variant={'h5'}>
            {getPriceFormatted(product.price)} {currencySymbol[CurrencyISO.PLN]}
          </Typography>
        </Flex>
        <Flex>
          <Buttons product={product} howManyProducts={productsLimit} />
        </Flex>
      </Flex>
      {selectedProduct?.type === 'collectorTicket' && (
        <SectionContainer container fullWidth sx={{ padding: 5 }}>
          <Flex gap={3}>
            <Typography variant={'body2'}>{i18n.buy.products.collectorTicketData}</Typography>

            <Form.Wrapper>
              {selectedProducts
                .filter((product) => product.type === 'collectorTicket')
                .map((product) => {
                  return [...Array(product.amount)].map((_, index) => (
                    <>
                      <Typography variant={'body1'}>
                        {i18n.buy.products.ticket} {ticketNumber++}
                      </Typography>
                      {index === 0 && (
                        <Button
                          sx={{ borderColor: 'primary.main' }}
                          color={'primary'}
                          size={'medium'}
                          variant={'outlined'}
                          onClick={fillWithBuyerData}
                        >
                          {i18n.buy.agreements.fillInData}
                        </Button>
                      )}
                      <TextInput
                        autoCapitalize={'words'}
                        label={i18n.forms.labels[FormFields.FIRSTNAME]}
                        control={control}
                        rules={validators.requiredString(
                          i18n.forms.validation[FormFields.FIRSTNAME].required
                        )}
                        name={`productUserData.id_${product.id}.${index}.firstName`}
                      />
                      <TextInput
                        autoCapitalize={'words'}
                        label={i18n.forms.labels[FormFields.LASTNAME]}
                        control={control}
                        rules={validators.requiredString(
                          i18n.forms.validation[FormFields.LASTNAME].required
                        )}
                        name={`productUserData.id_${product.id}.${index}.lastName`}
                      />
                    </>
                  ))
                })}
            </Form.Wrapper>
          </Flex>
        </SectionContainer>
      )}
    </Flex>
  )
}

export default Product
