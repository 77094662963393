import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { locale } from 'state/locale/atoms/locale'

export const setLang = selector({
  get: ({ get }) => get(locale),
  set: ({ set }, newValue) => {
    set(locale, newValue)
  },
  key: RecoilKeys.setLang,
})
