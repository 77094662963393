import React, { createRef, useEffect } from 'react'

import styles from './RedirectForECard.module.scss'
import { IRedirectForECardProps } from './RedirectForECard.types'

const formFields = [
  'amount',
  'autoDeposit',
  'charset',
  'country',
  'currency',
  'hash',
  'hashAlgorithm',
  'language',
  'linkFail',
  'linkOk',
  'merchantId',
  'name',
  'orderDescription',
  'orderNumber',
  'paymentType',
  'surname',
  'transparentPages',
]

const RedirectForECard = ({ data, isPaymentAvailable }: IRedirectForECardProps) => {
  const formRef = createRef<HTMLFormElement>()

  if (!isPaymentAvailable) {
    return null
  }

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  })

  return (
    <form
      action="https://pay.ecard.pl/payment/PS"
      className={styles.form}
      method="post"
      id="payment_form"
      target="_parent"
      ref={formRef}
    >
      {formFields.map((key) => (
        <input type="hidden" key={key} name={key.toUpperCase()} value={data[key]} readOnly={true} />
      ))}
    </form>
  )
}

export default RedirectForECard
