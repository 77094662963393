import { createSelector } from 'reselect'

import _Store from '@Store'

import { ITicketsListData, ITicketsReducer } from './../types'
import get from './get'

const getTicketsData = createSelector<_Store.IState, ITicketsReducer, ITicketsListData | null>(
  [get],
  (tickets) => tickets.ticketsData
)

export default getTicketsData
