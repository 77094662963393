import catchHttpError from 'misc/helpers/api/catchHttpError'
import Logger from 'services/Logger'

export class Fetcher {
  public static get = async <T = any>(
    url: string,
    headers?: HeadersInit,
    signal?: AbortSignal
  ): Promise<T> => {
    try {
      const response = await fetch(url, {
        method: 'get',
        headers: new Headers(headers),
        signal,
      })

      return await response.json()
    } catch (error: any) {
      Logger.error('Fetcher', error)
      return catchHttpError(error)
    }
  }

  public static post = async <T = any>(
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<T> => {
    try {
      const data = await fetch(url, {
        body,
        method: 'post',
        headers: new Headers(headers),
      })

      return await data.json()
    } catch (error: any) {
      Logger.error('Fetcher', error)
      return catchHttpError(error)
    }
  }

  public static delete = async <T = any>(
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<T> => {
    try {
      const data = await fetch(url, {
        body,
        method: 'delete',
        headers: new Headers(headers),
      })

      return await data.json()
    } catch (error: any) {
      Logger.error('Fetcher', error)
      return catchHttpError(error)
    }
  }

  /**
   * Usage:
   *
   * useEffect(() => {
   *   const fetcher = async () => Fetcher.getWithAbort(url)
   *
   *   fetcher().then().catch().finally()
   *
   *   return () => {
   *    fetcher.abort()
   *   }
   * }, [])
   *
   * @param {string} url
   * @param {HeadersInit} headers
   * @returns {{fetch: Promise<T>, cancel: () => void}}
   */
  public static getWithAbort = <T = any>(
    url: string,
    headers?: HeadersInit
  ): { fetch: Promise<T>; cancel: () => void } => {
    const controller = new AbortController()
    const { signal } = controller
    const fetchPromise = Fetcher.get<T>(url, headers, signal)
    const cancel = () => controller.abort()

    return { fetch: fetchPromise, cancel }
  }

  public static retryWithExponentialBackoff = async <T = any>(
    fn: () => Promise<T>,
    retries: number,
    delay: number
  ): Promise<T> => {
    try {
      return await fn()
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay))

        return this.retryWithExponentialBackoff(fn, retries - 1, delay * 2)
      } else {
        throw error
      }
    }
  }

  /**
   * Method used to mock a Promise.
   *
   * @param timeout
   * @param response
   */
  public static fakeFetch = async <T = any>(
    timeout: number,
    response?: T | undefined
  ): Promise<T | undefined> => {
    await new Promise((resolve) => setTimeout(resolve, timeout))

    return response
  }
}
