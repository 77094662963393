import { Key } from 'swr'

import { StorageKeys } from 'constants/StorageKeys'

type StorageKey = string | number | Key | StorageKeys

export class LocalStorage {
  public static get = <K = undefined>(key: StorageKey): K | undefined => {
    if (!key || this.ssr) return

    const storageKey = typeof key === 'string' ? key : JSON.stringify(key)
    const storageItem = window.localStorage.getItem(storageKey)

    if (!storageItem) return

    const data = JSON.parse(storageItem)

    if (data.expiration) {
      const now = new Date()

      if (now.getTime() > data.expiration) {
        window.localStorage.removeItem(storageKey)

        return
      }
    }

    return data.value ? data.value : undefined
  }

  public static set = (key: StorageKey, value: any, ttl?: number): void => {
    if (!key || !value || this.ssr) return

    const now = new Date()
    const expiration = ttl ? now.getTime() + ttl : undefined

    window.localStorage.setItem(
      typeof key === 'string' ? key : JSON.stringify(key),
      JSON.stringify({ value, expiration })
    )
  }

  public static delete = (key: StorageKey): void => {
    if (!key || this.ssr) return

    const storageKey = typeof key === 'string' ? key : JSON.stringify(key)

    window.localStorage.removeItem(storageKey)
  }

  public static purge = (): void => {
    if (this.ssr) return

    window.localStorage.clear()
  }

  public static getAllKeys = (): StorageKey[] => {
    const keys: StorageKey[] = []

    if (this.ssr) return keys

    for (let i = 0; i < window.localStorage.length; i++) {
      keys.push(window.localStorage.key(i))
    }

    return keys
  }

  private static ssr = typeof window === 'undefined'
}
