import { useEffect, useRef, useState } from 'react'

import { useInView } from 'react-intersection-observer'

import { useIsLegacySafari } from './useIsLegacySafari'

const isBrowser = typeof window !== 'undefined'

const useArrowsSlider = (
  arrLength: number,
  isDesktop: boolean,
  arrowWidth: number = 28,
  elementsMargin: number = 16,
  usePadding: boolean = false
) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [containerRef, loadContainer] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const listRef = useRef<HTMLUListElement | null>(null)
  const [firstRendering, disableCheck] = useState(true)
  const [cardWidth, setCardWidth] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerContentWidth, setContainerContentWidth] = useState(0)
  const [scrollPos, updatePos] = useState(0)
  const [showPrevious, togglePrevious] = useState(false)
  const [showNext, toggleNext] = useState(true)
  const [useNativeScroll, toggleScroll] = useState(false)

  const fullScrollValue = !isDesktop ? containerWidth : containerWidth - cardWidth + elementsMargin
  const lastScrollPos = containerContentWidth - containerWidth
  const isSliderShort = cardWidth * arrLength - elementsMargin <= containerWidth

  const { isLegacySafari } = useIsLegacySafari()

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Trigger only on first rendering after the list is loaded: set scroll width and single element width
  useEffect(() => {
    if (
      loadContainer &&
      firstRendering &&
      listRef.current &&
      listRef.current?.childElementCount > 0
    ) {
      const firstChildElement = listRef.current.childNodes.item(0) as HTMLElement

      setContainerContentWidth(listRef.current.scrollWidth)
      setCardWidth(firstChildElement.scrollWidth + (usePadding ? 0 : elementsMargin))
      disableCheck(false)
    }
  }, [listRef, loadContainer])

  // Trigger only on window resize
  useEffect(() => {
    if (listRef.current) {
      setContainerWidth(listRef.current.clientWidth)
    }
  }, [windowWidth])

  useEffect(() => {
    checkWindowWidth()
  }, [isDesktop])

  // Check if slider is shorter than container and toggle next arrow
  useEffect(() => {
    if (isSliderShort) {
      toggleNext(false)
    } else {
      toggleNext(true)
    }
  }, [windowWidth, listRef.current?.childElementCount])

  // Trigger only when scroll pos have changed: scroll handling
  useEffect(() => {
    if (listRef.current) {
      handleScroll(scrollPos)
    }
  }, [scrollPos])

  const checkWindowWidth = () => {
    if (windowWidth < containerWidth + arrowWidth * 2 || !isDesktop) {
      toggleScroll(true)
    } else {
      toggleScroll(false)
    }
  }

  const previous = () => {
    if (scrollPos > fullScrollValue) {
      updatePos(scrollPos - fullScrollValue)
    } else {
      updatePos(0)
    }
  }

  const next = () => {
    if (scrollPos + fullScrollValue >= lastScrollPos) {
      updatePos(lastScrollPos)
    } else {
      updatePos(scrollPos + fullScrollValue)
    }
  }

  const handleScroll = (value: number) => {
    if (isBrowser && listRef.current) {
      try {
        listRef.current.scrollTo({
          behavior: !isLegacySafari ? 'smooth' : undefined,
          left: value,
        })
      } catch (ieGoBad) {}
      handleArrows()
    }
  }

  const handleArrows = () => {
    if (scrollPos === 0) {
      togglePrevious(false)
      if (isSliderShort) {
        toggleNext(false)
      } else {
        toggleNext(true)
      }
    } else if (scrollPos > 0) {
      togglePrevious(true)
      if (scrollPos >= lastScrollPos) {
        toggleNext(false)
      } else if (scrollPos < lastScrollPos) {
        toggleNext(true)
      }
    }
  }

  return {
    containerRef,
    listRef,
    load: loadContainer,
    next,
    previous,
    showNext: loadContainer && showNext,
    showPrevious: loadContainer && showPrevious,
    useNativeScroll,
  }
}

export default useArrowsSlider
