import * as React from 'react'

import { Close } from '@mui/icons-material'
import { Button, Dialog, IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Styled } from 'components/FollowingDialog/FollowingDialog.styles'
import {
  IFollowingDialogFormInput,
  IFollowingDialogProps,
} from 'components/FollowingDialog/FollowingDialog.types'
import { FavoriteListItem } from 'components/FollowingDialog/components/FavoriteListItem'
import { Checkbox } from 'components/_forms/Checkbox'
import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { defaultValues } from 'components/_forms/_constants/defaultValues'
import { validators } from 'components/_forms/_validators'
import { useNotify } from 'components/_functional/NotificationProvider'
import { Flex } from 'components/_layout/Flex'
import ImageFixed from 'components/reusable/ImageFixed'
import { CloudinaryAssetIds } from 'constants/CloudinaryAssetIds'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { Fetcher } from 'services/Api/Fetcher'
import { DataMutations } from 'services/DataMutations'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'
import { getReviewAfterPurchase } from 'state/remoteConfig/selectors/getReviewAfterPurchase'
import { IAddToFavoritesPayload, IAddToFavoritesResponse } from 'types/Favorites'

import { FavoritesTypes } from '../../constants/Favorites'

export const FollowingDialog = ({
  eventData,
  userEmail,
  handleHide,
  isVisible,
}: IFollowingDialogProps) => {
  const { isDesktop } = useBreakpoints()
  const [step, setStep] = React.useState(0)
  const { i18n } = useDictionary()
  const { code } = useLang()
  const { notify } = useNotify()
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IFollowingDialogFormInput>({
    defaultValues: {
      [FormFields.NEWSLETTER]: defaultValues.unchecked,
      [FormFields.FAVORITES]: [],
      [FormFields.EMAIL]: defaultValues.empty,
    },
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const isReview = getReviewAfterPurchase()

  React.useEffect(() => {
    if (userEmail) setValue(FormFields.EMAIL, userEmail)
  }, [userEmail])

  const validationRules = {
    [FormFields.NEWSLETTER]: validators.term(i18n.forms.validation.termField.required),
    [FormFields.EMAIL]: validators.email(
      i18n.forms.validation[FormFields.EMAIL].required,
      i18n.forms.validation[FormFields.EMAIL].correct
    ),
  }

  const data = {
    [FavoritesTypes.Artists]: DataMutations.artistsToFavorites(
      'event' in eventData ? eventData.event.artists : []
    ),
    [FavoritesTypes.Places]: DataMutations.placeToFavorite(eventData.place),
    [FavoritesTypes.Tags]: DataMutations.tagsToFavorites(
      'event' in eventData ? eventData.event.tags : eventData.tags
    ),
  }

  const onFavoritesSubmit = handleSubmit(async ({ favorites, newsletter, email }) => {
    if (favorites.length === 0) {
      notify({ text: i18n.favorites.noFavorites, alertColor: 'error' })
      return
    }

    setIsLoading(true)
    try {
      const body: IAddToFavoritesPayload = {
        favorites,
        newsletter,
        email: userEmail || email,
      }
      const res = await Fetcher.post<IAddToFavoritesResponse>(
        URI.AddToFavorites(code),
        JSON.stringify(body)
      )
      if (res.success) {
        notify({ text: res.message, alertColor: 'success' })
      } else notify({ text: res.message, alertColor: 'error' })

      if (isReview) setStep(1)
    } catch (e: any) {
      notify({ text: e.message, alertColor: 'error' })
      Logger.error('useFollowing.onSubmit', e)

      if (isReview) setStep(1)
    } finally {
      setIsLoading(false)
    }
  })

  const followContent = (
    <>
      <ListItem component={'div'} disableGutters disablePadding sx={{ mb: 4 }}>
        <ListItemText
          sx={{ flex: 1 }}
          children={<Typography variant={'h4'}>{i18n.favorites.stayFresh}</Typography>}
        />
        <IconButton onClick={handleHide} children={<Close />} />
      </ListItem>
      <Form.Wrapper sx={{ gap: 4, position: 'relative' }}>
        {!userEmail && !!errors[FormFields.EMAIL] && (
          <TextInput
            inputMode={'email'}
            control={control}
            name={FormFields.EMAIL}
            label={i18n.following.yourEmail}
            rules={validationRules[FormFields.EMAIL]}
          />
        )}
        <Styled.CheckboxWrapper>
          <Typography variant={'body1'} children={i18n.favorites.termHeader} />
          <Checkbox
            label={i18n.following.term}
            name={FormFields.NEWSLETTER}
            rules={validationRules[FormFields.NEWSLETTER]}
            control={control}
          />
        </Styled.CheckboxWrapper>
        {!!data[FavoritesTypes.Artists].length && (
          <Flex gap={3}>
            <Typography variant={'h5'} children={i18n.favorites[FavoritesTypes.Artists]} />
            <Styled.ListContainer>
              {data[FavoritesTypes.Artists].map((favorite, index, array) => (
                <React.Fragment key={String(favorite.externalId)}>
                  <FavoriteListItem
                    control={control}
                    favorite={favorite}
                    key={String(favorite.externalId)}
                    getValues={getValues}
                    setValue={setValue}
                  />
                  {index !== array.length - 1 && <Styled.Divider />}
                </React.Fragment>
              ))}
            </Styled.ListContainer>
          </Flex>
        )}
        {!!data[FavoritesTypes.Places] && (
          <Flex gap={3}>
            <Typography variant={'h5'} children={i18n.favorites[FavoritesTypes.Places]} />
            <Styled.ListContainer>
              {[data[FavoritesTypes.Places]].map((favorite, index, array) => (
                <React.Fragment key={String(favorite.externalId)}>
                  <FavoriteListItem
                    control={control}
                    favorite={favorite}
                    key={String(favorite.externalId)}
                    getValues={getValues}
                    setValue={setValue}
                  />
                  {index !== array.length - 1 && <Styled.Divider />}
                </React.Fragment>
              ))}
            </Styled.ListContainer>
          </Flex>
        )}
        {!!data[FavoritesTypes.Tags].length && (
          <Flex gap={3}>
            <Typography variant={'h5'} children={i18n.favorites[FavoritesTypes.Tags]} />
            <Styled.ListContainer>
              {data[FavoritesTypes.Tags].map((favorite, index, array) => (
                <React.Fragment key={String(favorite.externalId)}>
                  <FavoriteListItem
                    control={control}
                    favorite={favorite}
                    key={String(favorite.externalId)}
                    getValues={getValues}
                    setValue={setValue}
                  />
                  {index !== array.length - 1 && <Styled.Divider />}
                </React.Fragment>
              ))}
            </Styled.ListContainer>
          </Flex>
        )}
      </Form.Wrapper>
      <Styled.Footer isDesktop={isDesktop}>
        <Button
          color={'primary'}
          onClick={onFavoritesSubmit}
          variant={'contained'}
          children={i18n.buttons.save}
          fullWidth={!isDesktop}
          disabled={isLoading}
        />
        <Button
          color={'primary'}
          onClick={isReview ? () => setStep(1) : handleHide}
          children={i18n.buttons.cancel}
          fullWidth={!isDesktop}
        />
      </Styled.Footer>
    </>
  )

  const googleLogoImg = (
    <ImageFixed
      src={CloudinaryAssetIds.GoogleLogo}
      transformation={{ height: 160, width: 160 }}
      aspectRatio={[1, 1]}
    />
  )

  const reviewContent = (
    <>
      <ListItem component={'div'} disableGutters disablePadding sx={{ mb: 4 }}>
        <ListItemText
          sx={{ flex: 1 }}
          children={<Typography variant={'h4'}>{i18n.payment.paymentSuccess.reviewUs}</Typography>}
        />
        <IconButton onClick={handleHide} children={<Close />} />
      </ListItem>
      {isDesktop ? (
        <Flex align="center" gap={6} direction="row-reverse" sx={{ height: 320 }}>
          <Flex
            sx={{
              height: 160,
              width: 160,
              flex: 1,
            }}
          >
            {googleLogoImg}
          </Flex>
          <Flex sx={{ flex: 4, p: 2 }} gap={3}>
            <Typography variant={'h3'} children={i18n.payment.paymentSuccess.reviewUsInGoogle} />
            <Typography variant={'body1'} children={i18n.payment.paymentSuccess.doYouLikeGoing} />
          </Flex>
        </Flex>
      ) : (
        <Flex gap={4} align={'center'} sx={{ p: 3 }}>
          <Flex
            sx={{
              height: 60,
              width: 60,
              flex: 1,
            }}
          >
            {googleLogoImg}
          </Flex>
          <Flex sx={{ width: 240, height: 35 }}>
            <ImageFixed
              src={CloudinaryAssetIds.StarBar}
              transformation={{ height: 35, width: 240 }}
              aspectRatio={[240, 35]}
            />
          </Flex>
          <Typography variant={'body1'} children={i18n.payment.paymentSuccess.doYouLikeGoing} />
        </Flex>
      )}
      <Styled.Footer isDesktop={isDesktop}>
        <Link to={URI.googleReview} target={'_blank'}>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={handleHide}
            children={i18n.payment.paymentSuccess.iWantToReviewNow}
            fullWidth={!isDesktop}
            disabled={isLoading}
          />
        </Link>
        <Button
          color={'primary'}
          onClick={handleHide}
          children={i18n.payment.paymentSuccess.noMaybeLater}
          fullWidth={!isDesktop}
        />
      </Styled.Footer>
    </>
  )

  return (
    <Dialog open={isVisible} fullWidth={true} onClose={handleHide} maxWidth={'md'}>
      {step === 0 ? followContent : reviewContent}
    </Dialog>
  )
}
