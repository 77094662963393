export enum FavoritesTypes {
  Artists = 'artist',
  Places = 'place',
  Rundates = 'rundate',
  Selection = 'selection',
  Tags = 'tag',
}

export enum CalendarTypes {
  Future = 'future',
  Past = 'past',
}

/**
 * Firestore Collection IDs for each FavoritesType.
 */
export const FavoriteCollectionIds = {
  [FavoritesTypes.Artists]: 'favArtists',
  [FavoritesTypes.Places]: 'favPlaces',
  [FavoritesTypes.Rundates]: 'favRundates',
  [FavoritesTypes.Tags]: 'favTags',
  [FavoritesTypes.Selection]: 'favSelections',
}

export enum FavoritesSubCollectionIds {
  Followers = 'followers',
}
