// tslint:disable: no-bitwise

const getCommonObjectFromArrays = (arrays: any[][]) => {
  const objects = {} as any
  const counter = {} as any
  arrays.map((ary, n) => {
    ary.map((obj) => {
      const key = JSON.stringify(obj)
      objects[key] = obj
      counter[key] = (counter[key] || 0) | (1 << n)
    })
  })

  const intersection: any[] = []
  Object.keys(counter).map((key) => {
    if (counter[key] === (1 << arrays.length) - 1) {
      intersection.push(objects[key])
    }
  })

  return intersection
}

export default getCommonObjectFromArrays
