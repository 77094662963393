import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import Summary from 'components/eventBoxes/Summary/Summary.component'
import {
  ISummaryFromDispatch,
  ISummaryFromState,
} from 'components/eventBoxes/Summary/Summary.types'
import {
  getHappeningEvent,
  getSelectedExtendedSlot,
  getSelected as getSelections,
  getSlotsSummary,
} from 'models/happening/selectors'
import { getIframeParams, getModule } from 'models/internalRouter/selectors'
import { setFinalPrice } from 'models/pools/actions'
import { getSelectedTickets } from 'models/pools/selectors'
import { setInsuranceSummaryData } from 'models/products/actions'
import { getProductsSummary, getSelected, getSelectedProducts } from 'models/products/selectors'
import { EVENT } from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import { getTheme } from 'models/theme/selectors'

const mapStateToProps = (state: _Store.IState): ISummaryFromState => ({
  defaultSelections: getSelections(state).selections,
  event: getHappeningEvent(state),
  isEmbed: !!getIframeParams(state),
  isLoading: getLoading(EVENT)(state),
  module: getModule(state),
  products: getSelected(state),
  selectedDay: getSelections(state).day,
  selectedDayFromSlot: getSelections(state).dayFromSlot,
  selectedProducts: getSelectedProducts(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
  selections: getSelections(state).selectionsPerPriceType,
  shipments: [],
  slots: getSlotsSummary(state) || undefined,
  summary: null,
  summaryProducts: getProductsSummary(state),
  selectedExtendedSlot: getSelectedExtendedSlot(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ISummaryFromDispatch => {
  return {
    setFinalPriceAction: (value) => dispatch(setFinalPrice(value)),
    setInsuranceSummaryData: (value) => dispatch(setInsuranceSummaryData(value)),
  }
}

export default connect<ISummaryFromState, ISummaryFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Summary)
