export const BUY_FORM_MOUNTED = 'Empik/event/BUY_FORM_MOUNTED'
export const EVENT_PAGE_MOUNTED = 'Empik/event/EVENT_PAGE_MOUNTED'
export const SET_CURRENT_KEY = 'Empik/event/SET_CURRENT_KEY'

// Private
export const _GET_REQUEST = 'Empik/event/_GET_REQUEST'
export const _GET_SUCCESS = 'Empik/event/_GET_SUCCESS'
export const _GET_FAILURE = 'Empik/event/_GET_FAILURE'

export const _GET_RUNDATE_SLUG_REQUEST = 'Empik/event/_GET_RUNDATE_SLUG_REQUEST'
export const _GET_RUNDATE_SLUG_SUCCESS = 'Empik/event/_GET_RUNDATE_SLUG_SUCCESS'
export const _GET_RUNDATE_SLUG_FAILURE = 'Empik/event/_GET_RUNDATE_SLUG_FAILURE'
