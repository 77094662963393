import * as React from 'react'

import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material'

import { Form } from 'components/_forms/Form'
import { RadioGroupInput } from 'components/_forms/RadioGroupInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { ActionLoading } from 'components/ticketManagement/TicketAction/ActionLoading'
import { Styled } from 'components/ticketManagement/TicketAction/TicketAction.styles'
import { ITicketActionsProps } from 'components/ticketManagement/TicketAction/TicketAction.types'
import { useTransferCancel } from 'components/ticketManagement/TicketAction/TransferCancel/useTransferCancel'
import { formatPluralNouns } from 'misc/helpers/formatPluralNouns'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import { ITransferCancelFormInput } from './TransferCancel.types'

export const TransferCancel = ({ actionKey, ticketIndex, code, email }: ITicketActionsProps) => {
  const { isMobile } = useBreakpoints()
  const { i18n } = useDictionary()
  const { code: lang } = useLang()
  const { activeStep, control, getValues, handleBack, handleSubmit, isLoading, transfers } =
    useTransferCancel(code, email, parseInt(ticketIndex), actionKey)

  const submitLabel = () => {
    switch (activeStep) {
      case 1:
        return i18n.buttons.confirm
      case 2:
        return i18n.buttons.done
      default:
        return i18n.buttons.next
    }
  }

  return (
    <Styled.Root>
      <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
        {i18n.ticketManagement.myTicket.steps.map((label, index) => (
          <Step key={label} completed={activeStep > index || activeStep === 2}>
            <StepLabel children={label} />
          </Step>
        ))}
      </Stepper>
      <Styled.Body>
        {isLoading ? (
          <ActionLoading actionKey={actionKey} hideText={activeStep !== 1} />
        ) : /** STEP ① : START */ activeStep === 0 ? (
          <Form.Wrapper sx={{ flex: 'inherit' }}>
            <Typography variant={'h5'} children={i18n.ticketManagement.cancelTransfer.formTitle} />
            <RadioGroupInput<ITransferCancelFormInput>
              name={FormFields.INDEX}
              control={control}
              defaultValue={0}
              options={transfers.map((transfer, transferIndex) => ({
                label: `${transfer.email} : ${transfer.quantity} ${formatPluralNouns(
                  transfer.quantity,
                  lang,
                  i18n.pluralRules.ticket
                )}`,
                value: transferIndex,
              }))}
            />
          </Form.Wrapper>
        ) : /** STEP ② : CONFIRM */ activeStep === 1 ? (
          <Styled.Content>
            <Typography
              variant={'h5'}
              mb={4}
              children={i18n.ticketManagement.myTicket.actions[actionKey].prompt}
            />
          </Styled.Content>
        ) : (
          /** STEP ③ : SUCCESS */ <Styled.Content>
            <Typography variant={'h5'} mb={4} children={i18n.ticketManagement.myTicket.thanks} />
            <Typography
              variant={'body2'}
              color={'text.secondary'}
              children={i18n.ticketManagement.myTicket.actions[actionKey].success}
            />
          </Styled.Content>
        )}
        <Styled.Footer>
          <Button
            variant={'outlined'}
            children={i18n.buttons.back}
            onClick={handleBack}
            disabled={activeStep !== 1 || isLoading}
          />
          <Button
            disabled={isLoading}
            variant={'contained'}
            type={'submit'}
            children={submitLabel()}
            onClick={handleSubmit}
          />
        </Styled.Footer>
      </Styled.Body>
    </Styled.Root>
  )
}
