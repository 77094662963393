import { getType } from 'typesafe-actions'

import { getPools, resetState } from './../../actions'
import { IAction, IPoolsAllReducer } from './../../types'

export const initialState: IPoolsAllReducer = {
  poolsData: null,
}

const reducer = (state: IPoolsAllReducer = initialState, action: IAction): IPoolsAllReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getPools.success):
      return {
        ...state,
        poolsData: action.payload.poolsData,
      }

    case getType(resetState):
      return initialState

    default:
      return state
  }
}

export default reducer
