import React from 'react'

import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './BasketEmpty.module.scss'
import useStyles from './BasketEmpty.styles'
import { IBasketEmptyProps } from './BasketEmpty.types'

const BasketEmpty = ({ basketRedirect, redirectParentTo }: IBasketEmptyProps) => {
  const classes = useStyles()
  const { i18n } = useDictionary()

  const redirect = () => {
    if (basketRedirect) {
      redirectParentTo(basketRedirect)
    }
  }

  return (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <p>{i18n.basket.basketEmpty.empty}</p>
        <p>{i18n.basket.basketEmpty.find}</p>
      </div>
      {basketRedirect ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="button"
          className={classes.buyButton}
          onClick={redirect}
        >
          {i18n.basket.basketEmpty.continue}
        </Button>
      ) : (
        <Link to={routes[Route.search]} className={styles.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="button"
            className={classes.buyButton}
          >
            {i18n.basket.basketEmpty.continue}
          </Button>
        </Link>
      )}
    </div>
  )
}

export default BasketEmpty
