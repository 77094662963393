import React from 'react'

import { Button, Grid, MenuItem, Select, TextField } from '@mui/material'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import Paper from '../Paper'
import useStyles from './ParentFormLayout.styles'
import { IParentFormLayoutProps } from './ParentFormLayout.types'
import prefixes from './assets'
import getButtonActiveHelper from './getButtonActiveHelper'

const ParentFormLayout = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  handleSubmit,
  handlePrevStep,
}: IParentFormLayoutProps) => {
  const classes = useStyles()
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  return (
    <Paper title={i18n.entryList.parentForm.title} helperText={i18n.entryList.parentForm.subtitle}>
      <form onSubmit={handleSubmit}>
        <Grid container={true} justifyContent={'center'} className={classes.root}>
          <Grid item={true} xs={12}>
            <TextField
              name={'firstName'}
              helperText={!!(touched.firstName && errors.firstName) && errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={i18n.entryList.parentForm.firstName}
              variant={'outlined'}
              fullWidth={true}
              error={!!(touched.firstName && errors.firstName)}
              autoComplete={'off'}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name={'lastName'}
              helperText={!!(touched.lastName && errors.lastName) && errors.lastName}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={i18n.entryList.parentForm.lastName}
              variant={'outlined'}
              fullWidth={true}
              error={!!(touched.lastName && errors.lastName)}
              autoComplete={'off'}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container={true}>
              <Grid item={true} xs={2}>
                <Select
                  value={values.prefix}
                  name={'prefix'}
                  onChange={handleChange}
                  MenuProps={{
                    MenuListProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  {prefixes.map((prefix) => (
                    <MenuItem key={prefix.value} value={prefix.value}>
                      {prefix.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item={true} xs={10} sx={{ paddingLeft: { xs: '40px', sm: '20px' } }}>
                <TextField
                  name={'phoneNumber'}
                  helperText={!!(touched.phoneNumber && errors.phoneNumber) && errors.phoneNumber}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={i18n.entryList.parentForm.phoneNumber}
                  variant={'outlined'}
                  fullWidth={true}
                  error={!!(touched.phoneNumber && errors.phoneNumber)}
                  inputProps={{ inputMode: 'numeric' }}
                  autoComplete={'off'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} className={classes.buttons}>
            <Grid
              container={true}
              justifyContent={'center'}
              alignItems={'center'}
              className={globals.column}
              spacing={1}
            >
              <Grid item={true}>
                <Button
                  className={globals.button}
                  type={'button'}
                  variant={'text'}
                  color={'primary'}
                  onClick={handlePrevStep}
                >
                  {i18n.entryList.parentForm.goBack}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  className={globals.button}
                  variant={'contained'}
                  size={'medium'}
                  color={'primary'}
                  type={'submit'}
                  disabled={getButtonActiveHelper(values)}
                >
                  {i18n.entryList.parentForm.goNext}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default ParentFormLayout
