import { ComponentType } from 'react'

import { Button, ButtonProps, styled } from '@mui/material'

export const Styled = {
  Button: styled(Button)(({ theme }) => ({
    borderRadius: 32,
    transition: '200ms',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: 'max-content',

    [theme.breakpoints.up('md')]: {
      minHeight: 0,
    },

    '&:hover': {
      background: theme.palette.text.primary,
      color: theme.palette.secondary.contrastText,
    },
  })) as ComponentType<ButtonProps>,
}
