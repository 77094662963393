import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { appState } from 'state/app/atoms/app'

export const setApp = selector({
  get: ({ get }) => get(appState),
  set: ({ set }, newValue) => {
    set(appState, newValue)
  },
  key: RecoilKeys.setApp,
})
