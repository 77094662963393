import { useEffect, useState } from 'react'

import Logger from 'services/Logger'

export const useIsLegacySafari = () => {
  const [isLegacySafari, setIsLegacySafari] = useState(false)

  useEffect(() => {
    try {
      const _isLegacyBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      setIsLegacySafari(_isLegacyBrowser)
    } catch (error) {
      Logger.error('useIsLegacySafari', error)
    }
  }, [])

  return {
    isLegacySafari,
  }
}
