import { IAction, IPaymentMethodsReducer } from 'models/pools/types'
import { getType } from 'typesafe-actions'

import { setPaymentMethods } from './../../actions'

const reducer = (
  state: IPaymentMethodsReducer = {
    paymentMethods: [],
  },
  action: IAction
): IPaymentMethodsReducer => {
  switch (action.type) {
    case getType(setPaymentMethods):
      return {
        paymentMethods: action.payload,
      }

    default:
      return state
  }
}

export default reducer
