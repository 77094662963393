export enum FormFields {
  CODE = 'code',
  EMAIL = 'email',
  FIRSTNAME = 'firstName',
  INDEX = 'index',
  LASTNAME = 'lastName',
  NEWSLETTER = 'newsletter',
  PASSWORD = 'password',
  PHONE = 'phone',
  REPEATPASSWORD = 'repeatPassword',
  REFUNDTYPE = 'refundType',
  QUANTITY = 'quantity',
  OLDPASSWORD = 'oldPassword',
  TERMS = 'terms',
  FAVORITES = 'favorites',
  BANKACCOUNTNUMBER = 'bankAccountNumber',
}
