import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolNormalized, IPoolsSelectedTicketsReducer } from './../types'
import getPools from './getPools'
import getSelectedTickets from './getSelectedTickets'

const isCourierRequired = createSelector<
  _Store.IState,
  IPoolNormalized[],
  IPoolsSelectedTicketsReducer,
  boolean
>(
  [getPools, getSelectedTickets],
  (pools: IPoolNormalized[], selectedTickets: IPoolsSelectedTicketsReducer) => {
    let required = false

    selectedTickets.map((ticket) => {
      if (pools.find((pool) => pool.id === ticket.poolId)?.courierRequired) {
        required = true
      }
    })

    return required
  }
)

export default isCourierRequired
