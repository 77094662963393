export enum AnchorLinks {
  EVENT_TICKETS = 'terminy-i-bilety',
  PLACE_EVENTS = 'aktualne-wydarzenia',
  UPCOMING_EVENTS = 'nadchodzace-wydarzenia',
  UPCOMING_RUNDATES = 'nadchodzace-terminy',
  RECOMMENDED_EVENTS = 'polecane-wydarzenia',
  NEWS = 'nowosci',
  GALLERY = 'galeria',
  ABOUT_PLACE = 'o-miejscu',
  LOCATION = 'lokalizacja',
}
