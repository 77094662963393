import {
  ISlidableCardsOwnProps,
  ISlidableExtraData,
} from 'components/Bricks/components/SlidableCards/SlidableCards.types'
import { IBasicLocation } from 'types/Locations'

import { IComponentExtraProps, ISectionsComponentDataMutable } from './../types/sections'

export const slidable_hotspots = (
  data: ISectionsComponentDataMutable,
  extra?: ISlidableExtraData,
  background?: string,
  id?: string,
  userLocation?: IBasicLocation
): ISlidableCardsOwnProps & ISlidableExtraData => {
  const extraFlags: IComponentExtraProps = {
    disableDate: extra?.disableDate,
    disableDescription: extra?.disableDescription,
    disablePlace: extra?.disablePlace,
    disableTitle: extra?.disableTitle,
  }

  return {
    background,
    data,
    color: extra?.color,
    hotDeals: extra?.hotDeals,
    moreButton: extra?.moreButton,
    moreButtonText: extra?.moreButtonText,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
    verticalImage: extra?.verticalImage,
    extraFlags,
    userLocation,
  }
}
