import * as React from 'react'

import qs from 'qs'
import { useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Place } from 'components/Place'
import { useMaintenance } from 'components/_functional/MaintenanceModeProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import Helmet from 'components/helmet/Helmet'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { PlaceApi } from 'services/Api/PlaceApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const PlacePage = () => {
  const { isMaintenance } = useMaintenance()
  const { slug } = useParams<RouteParams<Route.place>>()
  const { data, isLoading } = useSWR(
    isMaintenance !== null && !isMaintenance && slug,
    PlaceApi.getFullPlace
  )
  const { i18n } = useDictionary()

  return (
    <PageWrapper>
      {!!data?.placeData && (
        <Helmet
          pageName={[data.placeData.name, data.placeData.city.name, i18n.meta.events]}
          description={[
            data.placeData.name,
            i18n.meta.place,
            data.placeData.address,
            data.placeData.city.name,
          ]}
          ogDescription={[
            data.placeData.name,
            i18n.meta.place,
            data.placeData.address,
            data.placeData.city.name,
          ]}
          currentUrl={window?.location.href}
        />
      )}
      <Breadcrumbs
        base={[
          {
            link:
              routes[Route.search] +
              qs.stringify(
                {
                  [HitFields.type]: SearchTypes.PLACE,
                },
                { addQueryPrefix: true }
              ),
            label: i18n.breadcrumbs.places,
          },
        ]}
        current={data?.placeData.name}
      />
      {isLoading ? (
        <TwoColumnsSkeleton />
      ) : (
        !!data && (
          <Place
            cloudinaryImages={data.cloudinaryImages}
            data={data.placeData}
            placeEvents={data.placeEvents}
          />
        )
      )}
    </PageWrapper>
  )
}
