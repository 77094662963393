import * as React from 'react'

import { Alert, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'
import { useSWRConfig } from 'swr'

import { SectionContainer } from 'components/_layout/SectionContainer'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ActionError = () => {
  const { errorCode, code, email, actionKey } =
    useParams<RouteParams<Route.ticketManagementActionError>>()
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { mutate } = useSWRConfig()

  if (!errorCode || !code || !email || !actionKey) return null

  const handleGoBack = async () => {
    await mutate(URI.getTicket(code, email))
    navigate(
      prepareRoute({
        route: Route.ticketManagementTicket,
        params: { code, email },
      })
    )
  }

  return (
    <SectionContainer
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      title={i18n.ticketManagement.myTicket.actionErrorTitle}
      container
    >
      <Alert
        severity={'error'}
        title={i18n.ticketManagement.myTicket.actions[actionKey].name}
        children={i18n.ticketManagement.myTicket.actionErrors[errorCode]}
      />
      <Button
        size={'large'}
        variant={'contained'}
        children={i18n.buttons.goBack}
        onClick={handleGoBack}
      />
    </SectionContainer>
  )
}
