import React, { useEffect, useState } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Icon, useTheme } from '@mui/material'
import cn from 'classnames'

import Buttons from 'components/buying/Pools/components/Buttons'
import Description from 'components/buying/Pools/components/Description'
import classes from 'components/buying/Pools/components/Pool/Pool.classes'
import styles from 'components/buying/Pools/components/Pool/Pool.module.scss'
import useStyles from 'components/buying/Pools/components/Pool/Pool.styles'
import { IPoolProps } from 'components/buying/Pools/components/Pool/Pool.types'
import ToolTip from 'components/reusable/ToolTip'
import config from 'config'
import icons from 'misc/helpers/icons'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { DataMutations } from 'services/DataMutations'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { getServiceFeeTest } from 'state/remoteConfig/selectors/getServiceFeeTest'

const ticketIcon = config.theme.isEmpik
  ? cn(styles.icon, icons.ticket, classes.ticketIcon)
  : cn(styles.ticketIcon, classes.ticketIcon)

const Pool = ({ pool, rundateID }: IPoolProps) => {
  const { isDesktop } = useBreakpoints()
  const { i18n } = useDictionary()
  const showDescription = pool.description.length > 0
  const isDisabled = !pool.active || (!config.app.onlineSale && pool.currency !== 'PLN')
  const theme = useTheme()
  const muiStyles = useStyles(theme)

  /** TODO: remove when service fee test is over */
  const { isWebview } = useAppState()
  const [feeData, setFeeData] = useState<{
    serviceFee: typeof pool.serviceFee
    priceWithServiceFee: typeof pool.priceWithServiceFee
  }>({ serviceFee: pool.serviceFee, priceWithServiceFee: pool.priceWithServiceFee })
  useEffect(() => {
    if (rundateID) {
      const feeTest = getServiceFeeTest({
        rundateID,
        price: pool.price,
        serviceFee: pool.serviceFee,
        priceWithServiceFee: pool.priceWithServiceFee,
        isWebview,
      })
      setFeeData({
        serviceFee: feeTest.serviceFee,
        priceWithServiceFee: feeTest.priceWithServiceFee,
      })
    }
  }, [rundateID, pool.price, pool.serviceFee, pool.priceWithServiceFee, isWebview])
  /** remove when service fee test is over */

  return (
    <div
      className={cn(
        muiStyles.root,
        styles.pool,
        classes.pool,
        isDisabled && cn(styles.disabled, classes.disabled),
        `${classes.pool}-${pool.id}`
      )}
      data-test-id={`pool-${pool.id}`}
      {...(pool.active && { 'data-pool-id': pool.id })}
    >
      <div className={cn(styles.column, styles.columnLeft, classes.column, classes.columnLeft)}>
        {isDesktop && <span className={ticketIcon} />}
        <div className={cn(styles.ticketInfo, classes.ticketInfo)}>
          <div
            className={cn(
              styles.title,
              classes.title,
              showDescription && styles.titleWithMarginBottom
            )}
          >
            <h4>{pool.title}</h4>
          </div>
          {showDescription && <Description text={pool.description} />}
        </div>
      </div>
      <div className={cn(styles.column, classes.column)}>
        <span className={cn(styles.price, classes.price)}>
          {DataMutations.friendlyPrice({
            price: feeData.priceWithServiceFee,
            currency: pool.currency,
          })}
        </span>
        {!!pool.serviceFee && (
          <ToolTip
            title={i18n.event.serviceFeeCaption(
              DataMutations.formatServiceFeePrice(feeData.serviceFee, pool.currency)
            )}
            className={muiStyles.tooltip}
          >
            <Icon component={InfoIcon} />
          </ToolTip>
        )}
      </div>

      <div className={cn(styles.column, classes.column)}>
        <Buttons
          poolId={pool.id}
          howManyTickets={pool.ticketsLimit}
          poolName={pool.title}
          price={pool.price}
          serviceFee={feeData.serviceFee}
          additionalFields={pool.isDetailedPurchaseRequired}
          forms={pool.forms}
          isActive={pool.active}
          paymentMethods={pool.payments}
          currency={pool.currency}
        />
      </div>
    </div>
  )
}

export default Pool
