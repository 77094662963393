import * as React from 'react'

import { Route, routes } from 'router/routes'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { PassDataForm } from 'components/passManagement/PassDataForm'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const MyPassPage = () => {
  const { i18n } = useDictionary()

  return (
    <PageWrapper>
      <Breadcrumbs base={[{ link: routes[Route.myPass], label: i18n.breadcrumbs.yourPass }]} />
      <PassDataForm />
    </PageWrapper>
  )
}
