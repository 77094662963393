import { createSelector } from 'reselect'

import getData from './getData'
import getSelected from './getSelected'
import getSelectedSlot from './getSelectedSlot'

export const NO_PRICE = 0

const calculatePrice = createSelector(
  [getData, getSelectedSlot, getSelected],
  (happening, slot, selected) => {
    const summedPrice = selected.selectionsPerPriceType
      .map((item) =>item.priceType?.priceOverride ? item.priceType.priceOverride/100 : (item.numberOfPlayers && item.calculatedPrice) || NO_PRICE)
      .reduce((prev, curr) => prev + curr, NO_PRICE)

    if (happening?.calculatePricePerPerson) {
      return summedPrice
    }

    return summedPrice
  }
)

export default calculatePrice
