import { connect } from 'react-redux'

import _Store from '@Store'

import Products from 'components/Happening/components/Products/Products.component'
import { IProductsFromState } from 'components/Happening/components/Products/Products.types'
import { getProducts } from 'models/products/selectors'

const mapStateToProps = (state: _Store.IState): IProductsFromState => ({
  products: getProducts(state),
})

export default connect<IProductsFromState, void, {}, _Store.IState>(mapStateToProps)(Products)
