import * as React from 'react'

import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { IActivityProps } from 'components/Activity/Activity.types'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { CardDeck } from 'components/reusable/CardDeck'
import CarouselFixed from 'components/reusable/CarouselFixed'
import { ICarouselFixedImage } from 'components/reusable/CarouselFixed/CarouselFixed.types'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import { DateTimePlace } from 'components/reusable/DateTimePlace'
import Description from 'components/reusable/Description'
import { PlaceCard } from 'components/reusable/PlaceCard'
import config from 'config'
import isHTML from 'misc/helpers/isHTML'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import mobile_banner from 'misc/styles/images/event_baner_page_breaker_810.png'
import desktop_banner from 'misc/styles/images/event_baner_page_breaker_1280x264.png'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const Activity = ({ activityData, placeActivities, cloudinaryImages }: IActivityProps) => {
  const { i18n, getExternalKey } = useDictionary()
  const { isMobile, isDesktop } = useBreakpoints()

  const banner: ICarouselFixedImage = {
    alt: i18n.promotionBanner.altText,
    link: routes[Route.about],
    wideImage: isMobile ? mobile_banner : desktop_banner,
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      <TwoColumnsLayout
        boxComponent={
          <>
            <DateTimePlace
              place={{
                name: `${activityData.place.name}, ${activityData.place.city.name}`,
                slug: activityData.place.slug,
              }}
            />
            {isDesktop && (
              <Button
                component={Link}
                variant={'contained'}
                size={'large'}
                children={getExternalKey('buyButton') || i18n.event.buyTicket}
                to={prepareRoute({
                  route: Route.happening,
                  params: { slug: activityData.slug },
                })}
                fullWidth
              />
            )}
          </>
        }
        pageTitle={activityData.title}
        tags={activityData.tags}
        image={cloudinaryImages[0] ?? activityData.images[0]?.large}
      >
        <Description
          hideApostrophe={!config.theme.isEmpik}
          isVideo={!!activityData.teaser}
          text={activityData.partnerDescription ?? activityData.descriptionPL}
          html={
            isHTML(activityData.formattedPartnerDescription ?? activityData.formattedDescription)
              ? activityData.formattedPartnerDescription ?? activityData.formattedDescription
              : undefined
          }
        />
        <SectionContainer title={i18n.event.place} disabled={!activityData.place}>
          <PlaceCard data={activityData.place} />
        </SectionContainer>
      </TwoColumnsLayout>
      <CarouselFixed images={[banner]} />
      <SectionContainer
        title={i18n.activity.samePlaceActivitiesTitle}
        disabled={!placeActivities.length}
      >
        <CardDeck>
          {placeActivities.map((activity) => (
            <CloudSearchCard key={activity.id} item={activity} />
          ))}
        </CardDeck>
      </SectionContainer>
    </Box>
  )
}
