import * as React from 'react'

import ReactHelmet from 'react-helmet'

import { assembleTradeDoublerScript } from 'misc/helpers/analytics/assembleTradeDoublerScript'
import { useRundateID } from 'models/event/hooks/useRundateID'
import { useTransactionDetailsFromStore } from 'models/transaction/hooks/useTransactionDetailsFromStore'
import { useTransactionInfoFromStore } from 'models/transaction/hooks/useTransactionInfoFromStore'

export const TradeDoubler = () => {
  const rundateId = useRundateID()
  const transactionInfo = useTransactionInfoFromStore()
  const transactionDetails = useTransactionDetailsFromStore()
  const orderValue = transactionDetails?.revenue
  const orderNumber = Number(transactionInfo?.id)
  const transactionItems = transactionDetails?.transactionItems
  const userEmail = transactionDetails?.user?.userEmail

  return document.cookie.includes('TRADEDOUBLER') &&
    orderNumber &&
    orderValue &&
    orderValue > 0 &&
    !!transactionItems?.length &&
    transactionItems.length > 0 &&
    userEmail ? (
    <ReactHelmet>
      <script type="text/javascript">
        {assembleTradeDoublerScript({
          orderNumber,
          orderValue,
          rundateId: rundateId || '',
          transactionItems,
          userEmail,
          voucher: transactionInfo?.coupon || '',
        })}
      </script>
    </ReactHelmet>
  ) : null
}
