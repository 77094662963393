/**
 * Function converting all relative links in html code to absolute
 *
 * @param {string} baseUrl - base origin url
 * @param {string} html - html code string
 *
 * @return {string} html code string with absolute links
 */
export const convertRelToAbs = (baseUrl: string, html: string) => {
  const att = '[^-a-z0-9:._]';
  const entityEnd = '(?:;|(?!\\d))';
  const ents: { [key: string]: string } = {
    ' ': '(?:\\s|&nbsp;?|&#0*32' + entityEnd + '|&#x0*20' + entityEnd + ')',
    '(': '(?:\\(|&#0*40' + entityEnd + '|&#x0*28' + entityEnd + ')',
    ')': '(?:\\)|&#0*41' + entityEnd + '|&#x0*29' + entityEnd + ')',
    '.': '(?:\\.|&#0*46' + entityEnd + '|&#x0*2e' + entityEnd + ')',
  };
  const charMap: { [key: string]: string } = {};
  const s = ents[' '] + '*';
  const any = '(?:[^>"\']*(?:"[^"]*"|\'[^\']*\'))*?[^>]*';

  /**
   * Converts a given string in a sequence of the original input and the HTML entity
   *
   * @param {string} string to convert
   *
   * @return RegExp-pattern to deal with HTML entities
   */
  function anyEntity(string: string) {
    const all_chars_lowercase = string.toLowerCase();
    if (ents.hasOwnProperty(string)) {
      return ents[string];
    }
    const all_chars_uppercase = string.toUpperCase();
    let RE_res = '';
    for (let i = 0; i < string.length; i++) {
      const char_lowercase = all_chars_lowercase.charAt(i);
      if (charMap.hasOwnProperty(char_lowercase)) {
        RE_res += charMap[char_lowercase];
        continue;
      }
      const char_uppercase = all_chars_uppercase.charAt(i);
      let RE_sub: string | string[] = [char_lowercase];
      RE_sub.push('&#0*' + char_lowercase.charCodeAt(0) + entityEnd);
      RE_sub.push(
        '&#x0*' + char_lowercase.charCodeAt(0).toString(16) + entityEnd
      );
      if (char_lowercase !== char_uppercase) {
        RE_sub.push('&#0*' + char_uppercase.charCodeAt(0) + entityEnd);
        RE_sub.push(
          '&#x0*' + char_uppercase.charCodeAt(0).toString(16) + entityEnd
        );
      }

      RE_sub = '(?:' + RE_sub.join('|') + ')';
      RE_res += charMap[char_lowercase] = RE_sub;
    }

    return (ents[string] = RE_res);
  }

  /**
   * 2nd argument for replace(). Note that this function can also be used to remove links:
   * return group1 + "javascript://" + group3;
   */
  function replaceBy(match: any, group1: string, group2: string, group3: string) {
    return group1 + parseLink(baseUrl, group2) + group3;
  }

  const slashRE = new RegExp(anyEntity('/'), 'g');
  const dotRE = new RegExp(anyEntity('.'), 'g');

  /**
   * 2nd argument for replace(). Parses relevant HTML entities. Note that this function can also be used to remove links:
   * return group1 + "javascript://" + group3;
   */
  function replaceBy2(match: any, group1: string, group2: string, group3: string) {
    group2 = group2.replace(slashRE, '/').replace(dotRE, '.');

    return group1 + parseLink(baseUrl, group2) + group3;
  }

  /**
   * Selects an HTML element and performs a search-and-replace on attributes
   *
   * @param {string} selector  HTML substring to match
   * @param {string} attribute RegExp-escaped; HTML element attribute to match
   * @param {string} marker    Optional RegExp-escaped; marks the prefix
   * @param {string} delimiter Optional RegExp escaped; non-quote delimiters
   * @param {string} end       Optional RegExp-escaped; forces the match to end before an occurrence of <end>
   */
  function createReplace(
    selector: string | RegExp,
    attribute: string,
    marker?: string,
    delimiter?: string,
    end?: string
  ) {
    if (typeof selector === 'string') {
      selector = new RegExp(selector, 'gi');
    }
    attribute = att + attribute;
    marker = typeof marker === 'string' ? marker : '\\s*=\\s*';
    delimiter = typeof delimiter === 'string' ? delimiter : '';
    end = typeof end === 'string' ? '?)(' + end : ')(';
    const re1 = new RegExp('(' + attribute + marker + '")([^"' + delimiter + ']+' + end + ')', 'gi');
    const re2 = new RegExp('(' + attribute + marker + "')([^'" + delimiter + ']+' + end + ')', 'gi');
    const re3 = new RegExp('(' + attribute + marker + ')([^"\'][^\\s>' + delimiter + ']*' + end + ')', 'gi');
    html = html.replace(selector, (match: string) => {
      return match.replace(re1, replaceBy).replace(re2, replaceBy).replace(re3, replaceBy);
    });
  }

  /**
   * Selects an attribute of an HTML element, and performs a search-and-replace on certain values
   *
   * @param {string} selector  HTML element to match
   * @param {string} attribute RegExp-escaped; HTML element attribute to match
   * @param {string} front     RegExp-escaped; attribute value, prefix to match
   * @param {string} flags     Optional RegExp flags, default "gi"
   * @param {string} delimiter Optional RegExp-escaped; non-quote delimiters
   * @param {string} end       Optional RegExp-escaped; forces the match to end before an occurrence of <end>
   */
  function createReplaceInline(
    selector: string | RegExp,
    attribute: string,
    front: string,
    flags?: string | number,
    delimiter?: string,
    end?: string
  ) {
    if (typeof selector === 'string') {
      selector = new RegExp(selector, 'gi');
    }
    attribute = att + attribute;
    flags = typeof flags === 'string' ? flags : 'gi';
    const re1 = new RegExp('(' + attribute + '\\s*=\\s*")([^"]*)', 'gi');
    const re2 = new RegExp('(' + attribute + "\\s*=\\s*')([^']+)", 'gi');
    const at1 = new RegExp('(' + front + ')([^"]+)(")', flags);
    const at2 = new RegExp('(' + front + ")([^']+)(')", flags);
    let handleAttr: any;

    if (typeof delimiter === 'string') {
      end = typeof end === 'string' ? end : '';
      const at3 = new RegExp('(' + front + ')([^"\'][^' + delimiter + ']*' + (end ? '?)(' + end + ')' : ')()'), flags);
      handleAttr = (match: any, g1: any, g2: string) =>
        g1 + g2.replace(at1, replaceBy2).replace(at2, replaceBy2).replace(at3, replaceBy2);
    } else {
      handleAttr = (match: any, g1: any, g2: string) => g1 + g2.replace(at1, replaceBy2).replace(at2, replaceBy2);
    }
    html = html.replace(selector, (match) => {
      return match.replace(re1, handleAttr).replace(re2, handleAttr);
    });
  }

  createReplaceInline('<meta' + any + att + 'http-equiv\\s*=\\s*(?:"' + anyEntity('refresh') + '"' + any + ">|'" + anyEntity('refresh') + "'" + any + '>|' + anyEntity('refresh') + '(?:' + anyEntity(' ') + any + '>|>))', 'content', anyEntity('url') + s + anyEntity('=') + s, 'i');
  createReplace('<' + any + att + 'href\\s*=' + any + '>', 'href');
  createReplace('<' + any + att + 'src\\s*=' + any + '>', 'src');
  createReplace('<object' + any + att + 'data\\s*=' + any + '>', 'data');
  createReplace('<applet' + any + att + 'codebase\\s*=' + any + '>', 'codebase');
  createReplace('<param' + any + att + 'name\\s*=\\s*(?:"' + anyEntity('movie') + '"' + any + ">|'" + anyEntity('movie') + "'" + any + '>|' + anyEntity('movie') + '(?:' + anyEntity(' ') + any + '>|>))', 'value');
  createReplace(/<style[^>]*>(?:[^"']*(?:"[^"]*"|'[^']*'))*?[^'"]*(?:<\/style|$)/gi, 'url', '\\s*\\(\\s*', '', '\\s*\\)');
  createReplaceInline('<' + any + att + 'style\\s*=' + any + '>', 'style', anyEntity('url') + s + anyEntity('(') + s, 0, s + anyEntity(')'), anyEntity(')'));

  return html;
};

/**
 * Parses relative link/src path to absolute. Ignores links tha are already absolute and anchor #links
 *
 * @param {string} baseUrl origin of the link
 * @param {string} link relative link
 */
const parseLink = (baseUrl: string, link: string) => {
  const regExpAbsolute = /^(https?|file|ftps?|mailto|javascript|data:image\/[^;]{2,9};):/i;
  const regExpAnchor = /(#[a-zA-Z]+\b)(?!;)/gm;

  if (regExpAbsolute.test(link) || regExpAnchor.test(link)) {
    return link;
  }

  if (link.substring(0, 5) === './../') {
    link = 'https://' + baseUrl.split('/', 3)[2] + link.replace('./..', '');
  } else {
    link = baseUrl + link;
  }

  link = link
    .replace(/\.$/, '')
    .replace(/\/\./g, '')
    .replace(/"/g, '%22')
    .replace(/'/g, '%27')
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E');

  return link;
}
