import {
  IArtistSliderBrickProps,
  IArtistSliderExtraData,
} from 'components/Bricks/components/ArtistSliderBrick/ArtistSliderBrick.types'
import {
  ISectionsComponentArtistSlider,
  ISectionsComponentDataMutable,
} from 'models/pages/types/sections'

export const artistSlider = (
  data: ISectionsComponentDataMutable,
  extra?: IArtistSliderExtraData | null,
  bgColor?: string
): IArtistSliderBrickProps => {
  const artists = data as ISectionsComponentArtistSlider[]

  return {
    artists,
    bgColor,
    color: extra?.color,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  }
}
