import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = makeSxStyles(() => ({
  formWrapper: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    borderRadius: theme.border.radius,
    p: 5,
    boxShadow: 'none',
  }),
  button: {
    minWidth: 250,
    mt: 6,
  },
}))
