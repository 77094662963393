import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import WideArticle from './WideArticle.component'
import { IWideArticleFromState, IWideArticleOwnProps } from './WideArticle.types'

const mapStateToProps = (state: _Store.IState): IWideArticleFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
  compositionIdentifier: getCompositionIdentifier(state),
})

export default connect<IWideArticleFromState, null, IWideArticleOwnProps, _Store.IState>(
  mapStateToProps
)(WideArticle)
