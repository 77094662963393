import { connect } from 'react-redux'

import _Store from '@Store'

import { getEvent } from 'models/event/selectors'
import { getHappeningEvent } from 'models/happening/selectors'
import { EVENT } from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import Details from './Details.component'
import { IDetailsFromState } from './Details.types'

const mapStateToProps = (state: _Store.IState): IDetailsFromState => ({
  event: getEvent(state) || getHappeningEvent(state),
  isLoading: getLoading(EVENT)(state),
})

export default connect<IDetailsFromState, void, {}, _Store.IState>(mapStateToProps)(Details)
