import * as React from 'react'

import { addYears } from 'date-fns'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'

import { IClientLastData } from 'components/buying/components/ClientData/ClientData.types'
import { Languages } from 'constants/Languages'
import { StorageKeys } from 'constants/StorageKeys'
import loadDOMStyle from 'misc/helpers/loadDOMStyle'
import { useLayoutEffect } from 'misc/hooks/useIsomorphicLayoutEffect'
import { captureIframeEvent, initEmbed, locationChange } from 'models/internalRouter/actions'
import { useExternalStylesFromStore } from 'models/internalRouter/hooks/useExternalStylesFromStore'
import { useIframeParamsFromStore } from 'models/internalRouter/hooks/useIframeParamsFromStore'
import { useNavigateFromStore } from 'models/internalRouter/hooks/useNavigateFromStore'
import { IInternalRouterEvent } from 'models/internalRouter/types'
import Analytics from 'services/$analytics'
import { LocalStorage } from 'services/LocalStorage'
import { useSetAppState } from 'state/app/hooks/useSetAppState'
import { useSetLang } from 'state/locale/hooks/useSetLang'

/**
 * Component adds logic of internal router. Dispatches locationChange
 * action each time route changes and binds Page Component to module using
 * Routes enum.
 *
 * @param PageComponent
 * @param module
 * @param isEmbed
 * @param language
 * @constructor
 */
export const Page = ({
  PageComponent,
  module,
  isEmbed = false,
  language,
}: {
  PageComponent: React.ComponentType
  module: Route
  isEmbed?: boolean
  language?: Languages
}) => {
  const location = useLocation()
  const [history, setHistory] = React.useState<string[]>([])
  const navigate = useNavigate()
  const params = useParams<RouteParams<any>>()
  const dispatch = useDispatch()
  const externalStyles = useExternalStylesFromStore()
  const iframeParams = useIframeParamsFromStore()
  const navigateTo = useNavigateFromStore()
  const { setLanguage } = useSetLang()
  const [searchParams] = useSearchParams()
  const langParam = searchParams.get('language') as Languages
  useSetAppState(searchParams)

  useLayoutEffect(() => {
    if (langParam && Object.values(Languages).includes(langParam)) setLanguage(langParam)
  }, [langParam])

  useLayoutEffect(() => {
    dispatch(
      locationChange({
        params: Object.keys(params).length > 0 ? params : null,
        module,
        location,
      })
    )
    setHistory((prevHistory) => [...prevHistory, location.pathname])

    return () => {
      dispatch(
        locationChange({
          params: null,
          module: null,
          location: null,
        })
      )
    }
  }, [location])

  useLayoutEffect(() => {
    if (isEmbed) {
      dispatch(initEmbed((event: IInternalRouterEvent) => dispatch(captureIframeEvent(event))))
    }
  }, [isEmbed])

  useLayoutEffect(() => {
    if (externalStyles) loadDOMStyle(externalStyles)
  }, [externalStyles])

  useLayoutEffect(() => {
    if (language) setLanguage(language)
  }, [language])

  React.useEffect(() => {
    if (navigateTo) {
      if ('delta' in navigateTo) navigate(navigateTo.delta)
      else navigate(navigateTo.to, navigateTo.navigateOptions)
    }
  }, [navigateTo])

  React.useEffect(() => {
    Analytics.pushPageView({
      pageName: module,
      isEmbed,
      previousUrl: history[history.length - 1],
      embedHostname: iframeParams?.currentUrl,
      userEmail: LocalStorage.get<IClientLastData>(StorageKeys.BuyForm)?.email,
    })
  }, [location])

  /**
   * TRADE DOUBLER
   */
  React.useEffect(() => {
    if (searchParams.has('tduid')) {
      const tduid = searchParams.get('tduid')
      document.cookie = `TRADEDOUBLER=${tduid};expires=${addYears(
        new Date(),
        1
      ).toISOString()};path=/`
    }
  }, [searchParams])

  if (!isEmbed) return <PageComponent />

  if (iframeParams?.loaded) return <PageComponent />

  return null
}
