import { IRouterRedirectReducer } from 'models/connectedRouter/types'
import { IRedirectData } from 'services/$redirection-api/types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'

export const manageRedirect = createStandardAction(CONSTS.MANAGE_REDIRECT)<IRedirectData>()

export const getRedirection = createAsyncAction(
  CONSTS._GET_REDIRECT_REQUEST,
  CONSTS._GET_REDIRECT_SUCCESS,
  CONSTS._GET_REDIRECT_FAILURE
)<string, IRouterRedirectReducer, Error>()
