import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import HotDeals from './HotDeals.component'
import { IHotDealsFromState, IHotDealsOwnProps } from './HotDeals.types'

const mapStateToProps = (state: _Store.IState): IHotDealsFromState => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  }
}

export default connect<IHotDealsFromState, null, IHotDealsOwnProps, _Store.IState>(mapStateToProps)(
  HotDeals
)
