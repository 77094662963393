import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    column: {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    gapTop: {
      marginTop: '1rem',
    },
  })
)

export default useGlobalStyles
