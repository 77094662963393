import React from 'react'

import cn from 'classnames'

import styles from './InfoSection.module.scss'
import { IInfoSectionProps } from './InfoSection.types'

const InfoSection = ({ infoSection, sectionTitleScheme }: IInfoSectionProps) => {
  return (
    <>
      {infoSection.title && (
        <h2
          className={cn(styles.sectionTitle, infoSection?.centerText && styles.center)}
          style={sectionTitleScheme}
        >
          {infoSection.title}
        </h2>
      )}
      {infoSection.description && (
        <p className={styles.infoSectionText}>{infoSection.description}</p>
      )}
    </>
  )
}

export default InfoSection
