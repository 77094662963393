import { atom } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { ITagsFilterState } from 'types/Search'

export const initialState: ITagsFilterState = []

export const tagsFilterState = atom<ITagsFilterState>({
  default: initialState,
  key: RecoilKeys.tagsFilter,
})
