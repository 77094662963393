import * as React from 'react'

import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import { FavoriteAvatar } from 'components/FollowingDialog/components/FavoriteAvatar'
import { IFavoriteListItemProps } from 'components/FollowingDialog/components/FavoriteListItem/FavoriteListItem.types'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { Flex } from 'components/_layout/Flex'

export const FavoriteListItem = ({
  favorite,
  control,
  setValue,
  getValues,
}: IFavoriteListItemProps) => (
  <Controller
    control={control}
    render={({ field }) => (
      <FormControlLabel
        sx={{ width: '100%', margin: 0, justifyContent: 'space-between', p: 3 }}
        labelPlacement={'start'}
        control={
          <Checkbox
            checkedIcon={<Favorite />}
            icon={<FavoriteBorder />}
            color={'error'}
            checked={getValues(FormFields.FAVORITES).some(
              (fav) => fav.externalId === favorite.externalId && fav.type === favorite.type
            )}
            onChange={(e) => {
              if (e.target.checked) {
                setValue(FormFields.FAVORITES, [
                  ...getValues(FormFields.FAVORITES),
                  {
                    externalId: favorite.externalId,
                    type: favorite.type,
                  },
                ])
              } else {
                setValue(
                  FormFields.FAVORITES,
                  getValues(FormFields.FAVORITES).filter(
                    (fav) => fav.type === favorite.type && fav.externalId !== favorite.externalId
                  )
                )
              }
            }}
          />
        }
        label={
          <Flex direction={'row'} wrap={'nowrap'} align={'center'} gap={3}>
            <FavoriteAvatar slug={favorite.slug} type={favorite.type} />
            <Typography children={favorite.name} />
          </Flex>
        }
      />
    )}
    name={FormFields.FAVORITES}
  />
)
