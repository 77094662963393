import * as React from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import Bricks from 'components/Bricks'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/CompositionPage'
import config from 'config'
import { ICompositionData } from 'models/pages/types'
import { URI } from 'services/URI'
import { useAppState } from 'state/app/hooks/useAppState'

export const HomePage = () => {
  const params = useParams<RouteParams<Route.mainPage>>()
  const { userLocation, locations, setLocation } = useLocations()
  const key = params.slug ?? userLocation?.slug
  const { data } = useSWR<ICompositionData>(URI.getMainPage(key))
  const navigate = useNavigate()
  const { isBrowser } = useAppState()

  React.useEffect(() => {
    if (!config.app.onlineSale) navigate(routes[Route.search])
  }, [])

  React.useEffect(() => {
    if (params.slug) {
      const routeLocation = locations.find((loc) => loc.slug === params.slug)
      if (!!routeLocation) setLocation(routeLocation)
    }
  }, [])

  React.useEffect(() => {
    if (isBrowser) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [key])

  return (
    <PageWrapper fullWidth={true}>
      <Helmet />
      <Bricks key={key} userLocation={userLocation} structure={data} />
    </PageWrapper>
  )
}
