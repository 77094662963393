import React from 'react'

import { Divider, Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useTransactionDetailsFromStore } from 'models/transaction/hooks/useTransactionDetailsFromStore'
import { useTransactionInfoFromStore } from 'models/transaction/hooks/useTransactionInfoFromStore'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import classes from '../../Success.classes'
import useStyles from './SummaryAfterSellSegment.styles'
import SingleSegment from './components/SingleSegment'

export const SummaryAfterSellSegment = () => {
  const { i18n } = useDictionary()
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const { isMobile } = useBreakpoints()
  const transactionInfo = useTransactionInfoFromStore()
  const transactionDetails = useTransactionDetailsFromStore()
  const transactionItems = transactionDetails?.transactionItems

  return (
    <Grid item={true}>
      <Grid container={true} direction="row">
        <Grid item={true} xs={12} className={cn(classes.summaryAfterSellSegmentHeader)}>
          <Typography variant={isMobile ? 'h5' : 'h3'} color="textPrimary">
            {i18n.payment.paymentSuccess.yourOrder}
          </Typography>
        </Grid>

        <Grid item={true} xs={12} className={cn(muiStyles.container, classes.detailsContainer)}>
          <Grid container={true} className={muiStyles.detailsContainer}>
            {transactionItems?.map((item, index, arr) => (
              <Grid item={true} xs={12} className={cn(classes.singleSegment)} key={item.uuid}>
                <SingleSegment transactionItems={item} />
                {arr.length - 1 === index ? null : <Divider className={muiStyles.detailsDivider} />}
              </Grid>
            ))}
          </Grid>

          <Divider className={muiStyles.divider} />

          <Grid
            container={true}
            className={cn(muiStyles.bottomSummary, classes.bottomSummary)}
            alignItems={'flex-end'}
          >
            <Grid item={true} xs={6} className={muiStyles.summaryText}>
              <Typography variant="body1" color="textPrimary">
                {i18n.payment.paymentSuccess.summary}
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography variant="h5" align="right" color="textPrimary">
                {transactionInfo?.revenue.toFixed(2)} {transactionInfo?.currency}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
