import { locationChange } from 'models/internalRouter/actions'
import { IAction as IInternalRouterAction } from 'models/internalRouter/types'
import { getType } from 'typesafe-actions'

import { checkoutStep, firstValidation } from './../actions'
import { IAction, IAnalyticsReducer } from './../types'

const initialState: IAnalyticsReducer = {
  checkoutValidation: false,
  selectTickets: false,
}

const reducer = (
  state: IAnalyticsReducer = initialState,
  action: IAction | IInternalRouterAction
): IAnalyticsReducer => {
  switch (action.type) {
    case getType(firstValidation):
      return {
        ...state,
        checkoutValidation: true,
      }

    case getType(checkoutStep):
      return {
        ...state,
        selectTickets: true,
      }

    case getType(locationChange):
      return initialState

    default:
      return state
  }
}

export default reducer
