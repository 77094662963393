import * as React from 'react'

import { getDatabase, onValue, ref } from 'firebase/database'

import { IMaintenanceModeContext } from './MaintenanceModeProvider.types'

const MaintenanceContext = React.createContext<IMaintenanceModeContext>({
  isMaintenance: null,
})

export const useMaintenance = () => React.useContext(MaintenanceContext)

export const MaintenanceModeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isMaintenance, setMaintenance] = React.useState(null)

  React.useEffect(() => {
    const db = getDatabase()
    const isMaintenanceRef = ref(db, '/isWebMaintenance')

    onValue(isMaintenanceRef, (snapshot) => {
      const isWebMaintenance = snapshot.val()
      setMaintenance(isWebMaintenance)
    })
  }, [])

  return <MaintenanceContext.Provider value={{ isMaintenance }} children={children} />
}
