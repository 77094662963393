import { ICustomCodeOwnProps } from 'components/Bricks/components/CustomCode/CustomCode.types'
import {
  ISectionsComponentCustomCode,
  ISectionsComponentDataMutable,
} from 'models/pages/types/sections'

export const custom_code = (data: ISectionsComponentDataMutable): ICustomCodeOwnProps => {
  const customCodeData = data as ISectionsComponentCustomCode[]
  const customCodeElement = customCodeData[0]

  return {
    cssSrc: customCodeElement.cssSrc,
    htmlSrc: customCodeElement.htmlSrc,
  }
}
