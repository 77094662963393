import { IframeProvider } from 'services/$iframe-provider'
import { Fetcher } from 'services/Api/Fetcher'
import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { IFinalizeTransactionResponse, ITransactionResponse } from 'types/Transactions'

export class TransactionsApi {
  public static getExtendedPayment = async ([id]: [id: string]) => {
    try {
      const transaction = await Fetcher.get<ITransactionResponse>(
        URI.GetExtendedPaymentTransaction(id)
      )
      return {
        transaction,
      }
    } catch (e) {
      Logger.error('TransactionsApi.getExtendedPayment', e)
    }
  }

  public static finalizeTransaction = async ([id]: [id: string]) => {
    try {
      const response = await Fetcher.get<IFinalizeTransactionResponse>(URI.FinalizeTransaction(id))
      if (response.url) {
        new IframeProvider().runRedirectParentMethod(response.url)
      }
    } catch (e) {
      Logger.error('TransactionsApi.finalizeTransaction', e)
    }
  }
}
