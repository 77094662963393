import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPaymentMethods, ITransactionReducer } from 'models/transaction/types'

import get from './get'

const getPaymentMethods = createSelector<
  _Store.IState,
  ITransactionReducer,
  IPaymentMethods | undefined
>([get], (transaction) => transaction.paymentMethods || undefined)

export default getPaymentMethods
