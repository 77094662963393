import React from 'react'

import { Button } from '@mui/material'
import { updateProfile } from 'firebase/auth'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { useNotify } from 'components/_functional/NotificationProvider'
import { useUser } from 'components/_functional/UserContextProvider'
import { UserApi } from 'services/UserApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IEditDataFormInput, IEditDataFormProps } from './EditDataForm.types'

export const EditDataForm = ({ successCallback }: IEditDataFormProps) => {
  const { i18n } = useDictionary()
  const { goingMetadata, loading, firebaseUser } = useUser()
  const { notify } = useNotify()
  const { control, handleSubmit } = useForm<IEditDataFormInput>({
    defaultValues: {
      [FormFields.FIRSTNAME]: goingMetadata?.firstname || '',
      [FormFields.LASTNAME]: goingMetadata?.lastname || '',
    },
  })

  const onSubmit: SubmitHandler<IEditDataFormInput> = async ({ firstName, lastName }) => {
    loading.start()
    if (firebaseUser) {
      const res = await UserApi.updateApiUser({ firstName, lastName }, firebaseUser)
      await updateProfile(firebaseUser, { displayName: `${firstName} ${lastName}` })
      if (res.isSuccess) notify({ text: i18n.auth.userUpdated, alertColor: 'success' })
      else notify({ text: i18n.notifications.genericError, alertColor: 'error' })
      await firebaseUser.reload()
      if (successCallback) successCallback()
    } else {
      notify({ text: i18n.notifications.mustLogin, alertColor: 'error' })
    }
    loading.done()
  }

  return (
    <Form.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        autoCapitalize={'words'}
        name={FormFields.FIRSTNAME}
        label={i18n.forms.labels[FormFields.FIRSTNAME]}
        control={control}
        rules={validators.requiredString(i18n.forms.validation[FormFields.FIRSTNAME].required)}
        autoFocus={true}
      />
      <TextInput
        autoCapitalize={'words'}
        name={FormFields.LASTNAME}
        label={i18n.forms.labels[FormFields.LASTNAME]}
        control={control}
        rules={validators.requiredString(i18n.forms.validation[FormFields.LASTNAME].required)}
      />
      <Button
        variant={'contained'}
        children={i18n.buttons.save}
        type={'submit'}
        disabled={loading.isLoading}
        fullWidth
      />
    </Form.Wrapper>
  )
}
