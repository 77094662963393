import React from 'react'

import { Button, Grid, TextField } from '@mui/material'
import { Formik } from 'formik'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import Paper from '../Paper'
import { makeValidationSchema } from './CheckSubmission.selector'
import { ICheckSubmissionProps } from './CheckSubmission.types'

const CheckSubmission = ({ handlePrevStep, checkSubmissionsExists }: ICheckSubmissionProps) => {
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  const handleFormSubmit = (values: { email: string }) => {
    checkSubmissionsExists(values.email)
  }

  return (
    <Paper
      title={i18n.entryList.checkSubmission.title}
      helperText={i18n.entryList.checkSubmission.helper}
    >
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleFormSubmit}
        validationSchema={makeValidationSchema(i18n)}
      >
        {({ values, handleSubmit, handleBlur, handleChange, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <TextField
                  name="email"
                  helperText={!!(touched.email && errors.email) && errors.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={i18n.entryList.checkSubmission.placeholder}
                  variant="outlined"
                  fullWidth={true}
                  error={!!(touched.email && errors.email)}
                  inputProps={{ inputMode: 'email' }}
                  autoCapitalize="false"
                  autoCorrect="false"
                  autoComplete="off"
                />
              </Grid>

              <Grid item={true} xs={12} className={globals.gapTop}>
                <Grid
                  container={true}
                  justifyContent="center"
                  alignItems="center"
                  className={globals.column}
                  spacing={1}
                >
                  <Grid item={true}>
                    <Button
                      className={globals.button}
                      type="button"
                      variant="text"
                      color="primary"
                      onClick={handlePrevStep}
                    >
                      {i18n.entryList.checkSubmission.goBack}
                    </Button>
                  </Grid>
                  <Grid item={true}>
                    <Button
                      className={globals.button}
                      variant="contained"
                      size="medium"
                      color="primary"
                      type={'submit'}
                    >
                      {i18n.entryList.checkSubmission.goNext}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Paper>
  )
}

export default CheckSubmission
