import { EventCommonResponse } from 'types/EventCommon'
import { IPlaceResponse } from 'types/Place'

type DataType = EventCommonResponse | IPlaceResponse | undefined

export interface IActionBarProps {
  data: DataType
  type: ActionBarType
}

export enum ActionBarType {
  EVENT = 'EVENT',
  ACTIVITY = 'ACTIVITY',
  PLACE = 'PLACE',
}
