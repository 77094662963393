import React, { useEffect } from 'react'

import { Button, Grid } from '@mui/material'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import stepNames from '../../steps'
import Paper from '../Paper'
import { IGreetingProps } from './Greeting.types'

const Greeting = ({ resetForm, skipToSelectedStep }: IGreetingProps) => {
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  const handleResetForm = () => {
    resetForm()
    skipToSelectedStep(stepNames.welcome)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetForm()
      skipToSelectedStep(stepNames.welcome)
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Paper title={i18n.entryList.greeting.title} helperText={i18n.entryList.greeting.subtitle}>
      <Grid container={true} justifyContent="center">
        <Grid item={true}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleResetForm}
            className={globals.button}
          >
            {i18n.entryList.greeting.button}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Greeting
