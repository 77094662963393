import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolsAllReducer, IPoolsNormalized } from './../types'
import getAll from './getAll'

const getPoolsData = createSelector<_Store.IState, IPoolsAllReducer, IPoolsNormalized>(
  [getAll],
  (all) => (all.poolsData || {}) as IPoolsNormalized
)

export default getPoolsData
