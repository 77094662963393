import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import MultilineCards from './MultilineCards.component'
import { IMultilineCardsFromState, IMultilineCardsOwnProps } from './MultilineCards.types'

const mapStateToProps = (state: _Store.IState): IMultilineCardsFromState => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  }
}

export default connect<IMultilineCardsFromState, null, IMultilineCardsOwnProps, _Store.IState>(
  mapStateToProps
)(MultilineCards)
