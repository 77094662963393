import { ValidationRules } from 'components/_forms/_validators/types'
import { regExp } from 'constants/RegExp'

export const emailValidator = (requiredMsg: string, correctMsg: string): ValidationRules => ({
  required: {
    value: true,
    message: requiredMsg,
  },
  pattern: {
    value: regExp.email,
    message: correctMsg,
  },
})
