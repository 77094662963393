import { filter as filter$, map as map$ } from 'rxjs/operators'

import _Store from '@Store'

import { receiveTicket } from 'models/tickets/actions'
import { isActionOf } from 'typesafe-actions'

import { resetLoading, setLoading } from './../actions'
import * as CONSTS from './../constants/constants'

export const setLoaderWhenTicketsSendRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([receiveTicket.request])),
    map$(() => setLoading(CONSTS.MYTICKET))
  )
}

export const setLoaderWhenTicketsSendFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([receiveTicket.success, receiveTicket.failure])),
    map$(() => resetLoading(CONSTS.MYTICKET))
  )
}
