// Shared services
import { services as sharedServices } from 'goingapp-shared'

import config from 'config'
import ActivityApi from 'services/$activity-api'
import Analytics from 'services/$analytics'
import ArticleApi from 'services/$article-api'
import AuthApi from 'services/$auth-api'
import BrazeApi from 'services/$braze-api'
import DeviceDetector from 'services/$device-detector'
import EventsApi from 'services/$events-api'
import FacebookApi from 'services/$facebook-api'
import FormIoApi from 'services/$formio-api'
import GiftCardApi from 'services/$giftCard-api'
import HtmlApi from 'services/$html-api'
import IFrameProvider from 'services/$iframe-provider'
import LandingPageApi from 'services/$landing-api'
import MailApi from 'services/$mail-api'
import ModalDictionary from 'services/$modal-dictionary'
import PoolsApi from 'services/$pools-api'
import PremiumApi from 'services/$premium-api'
import PriceTypeApi from 'services/$price-type-api'
import ProductApi from 'services/$product-api'
import RedirectionApi from 'services/$redirection-api'
import SectionsApi from 'services/$sections-api'
import SendgridApi from 'services/$sendgrid-api'
import ShipmentApi from 'services/$shipment-api'
import TicketsApi from 'services/$tickets-api'
import TransactionsApi from 'services/$transactions-api'

const availabilitiesApi = new sharedServices.AvailabilitiesApi(config.api.zagrywkiApiV2 || '')
const happeningApi = new sharedServices.HappeningsApi(config.api.zagrywkiApi || '')
const priceApi = new sharedServices.PriceApi(config.api.zagrywkiApiV2 || '')
const productsApi = new sharedServices.ProductsApi(config.api.zagrywkiApi || '')
const termsApi = new sharedServices.TermsApi(config.api.baseUrl || '')
const cloudinaryApi = new sharedServices.CloudinaryApi(config.images.cloudName)

export interface IServices {
  analytics: typeof Analytics
  articleApi: typeof ArticleApi
  authApi: typeof AuthApi
  availabilitiesApi: typeof availabilitiesApi
  brazeApi: typeof BrazeApi
  cloudinaryApi: typeof cloudinaryApi
  deviceDetector: typeof DeviceDetector
  eventsApi: typeof EventsApi
  facebookApi: typeof FacebookApi
  giftCardApi: typeof GiftCardApi
  happeningApi: typeof happeningApi
  htmlApi: typeof HtmlApi
  iframeProvider: typeof IFrameProvider
  landingPageApi: typeof LandingPageApi
  mailApi: typeof MailApi
  modalDictionary: typeof ModalDictionary
  poolsApi: typeof PoolsApi
  premiumApi: typeof PremiumApi
  priceApi: typeof priceApi
  priceTypeApi: typeof PriceTypeApi
  productApi: typeof ProductApi
  productsApi: typeof productsApi
  redirectionApi: typeof RedirectionApi
  sectionsApi: typeof SectionsApi
  sendgridApi: typeof SendgridApi
  shipmentApi: typeof ShipmentApi
  termsApi: typeof termsApi
  ticketsApi: typeof TicketsApi
  transactionsApi: typeof TransactionsApi
  formioApi: typeof FormIoApi
  activityApi: typeof ActivityApi
}

const services: IServices = {
  activityApi: ActivityApi,
  analytics: Analytics,
  articleApi: ArticleApi,
  authApi: AuthApi,
  availabilitiesApi,
  brazeApi: BrazeApi,
  cloudinaryApi,
  deviceDetector: DeviceDetector,
  eventsApi: EventsApi,
  facebookApi: FacebookApi,
  giftCardApi: GiftCardApi,
  happeningApi,
  htmlApi: HtmlApi,
  iframeProvider: IFrameProvider,
  landingPageApi: LandingPageApi,
  mailApi: MailApi,
  modalDictionary: ModalDictionary,
  poolsApi: PoolsApi,
  premiumApi: PremiumApi,
  priceApi,
  priceTypeApi: PriceTypeApi,
  productApi: ProductApi,
  productsApi,
  redirectionApi: RedirectionApi,
  sectionsApi: SectionsApi,
  sendgridApi: SendgridApi,
  shipmentApi: ShipmentApi,
  termsApi,
  ticketsApi: TicketsApi,
  transactionsApi: TransactionsApi,
  formioApi: FormIoApi,
}

export default services
