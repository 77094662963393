import * as LOCALS from './constants'
import { ILocals } from './types'

const goingLocals: ILocals = {
  [LOCALS.INTERNAL_ROUTER__LINK_TO_APP]: 'https://goingapp.pl',

  [LOCALS.STORAGE__PRIVACY_POLICY]:
    'https://goingapp.pl/themes/going/assets/docs/privacy_policy.pdf',
  [LOCALS.STORAGE__TERMS]:
    'https://web.goingapp.pl/storage/app/media/Regulamin%20Aplikacji%20Going./regulamin_aplikacji_going.pdf',

  [LOCALS.HELMET__AUTHOR]: 'Going.',
  [LOCALS.HELMET__PAGE_NAME]: 'goingapp.pl',
  [LOCALS.HELMET__PAGE_TITLE]:
    'Going - Koncerty, imprezy, bilety do teatru w Twoim smartfonie w całej Polsce!',
  [LOCALS.HELMET__PAGE_DESCRIPTION]:
    'Dowiedz się, co dzieje się w całej Polsce. Pobierz Going. i bądź na bieżąco z najciekawszymi wydarzeniami!',
  [LOCALS.HELMET__PAGE_KEYWORDS]: 'going, bilety, wydarzenia, koncerty, imprezy, teatr, festiwale',
  [LOCALS.HELMET__PAGE_CANONICAL_URL]: 'https://goingapp.pl/',
}

export default goingLocals
