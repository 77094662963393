import React from 'react'

import { PageWrapper } from '../../_layout/PageWrapper'
import { Calendar } from '../../favorites/Calendar'
import { useFavoritesContext } from '../../favorites/FavoritesProvider'

export const CalendarPage = () => {
  const {
    favorites: { rundate },
  } = useFavoritesContext()

  return (
    <PageWrapper>
      <Calendar items={rundate} />
    </PageWrapper>
  )
}
