import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  delay as delay$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import config from 'config'
import { StorageKeys } from 'constants/StorageKeys'
import HttpError from 'misc/classes/HttpError'
import { locationChange } from 'models/internalRouter/actions'
import { getLocation, getParams } from 'models/internalRouter/selectors'
import { getPagesAuth } from 'models/pages/selectors'
import { LocalStorage } from 'services/LocalStorage'
import { IBasicLocation } from 'types/Locations'
import { isActionOf } from 'typesafe-actions'

import {
  authorizePage,
  fetchUrlStructure,
  getCustomPage,
  getSections,
  openPageAuth,
  resetAuthError,
  setAuthError,
} from './../actions'
import { PagesEnum } from './../constants/pages'
import { ICompositionData } from './../types'

const ONE_HOUR_MILLISECONDS = 3600000

export const getCustomSections: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(getCustomPage)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { slug, extraSlug } = getParams(state) as RouteParams<Route.recommend>

      return [
        getSections.request({
          groupSlug: extraSlug ? slug : undefined,
          site: PagesEnum.recommend,
          slug: extraSlug ?? slug,
        }),
      ]
    })
  )

export const fetchUrlSections: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(fetchUrlStructure)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const url = getLocation(state)?.pathname.replace('/', '')

      return of$(getSections.request({ site: PagesEnum.link, slug: url }))
    })
  )
}

export const fetchSections: _Store.IEpic = (action$, state$, { sectionsApi }) =>
  action$.pipe(
    filter$(isActionOf(getSections.request)),
    delay$(config.api.cancellationFixDelay),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const savedLocation = LocalStorage.get<IBasicLocation>(StorageKeys.UserLocation)
      const slug = action.payload.slug ? action.payload.slug : savedLocation?.slug ?? 'warszawa'

      const groupSlug = action.payload.groupSlug
      const authCredentials = getPagesAuth(state)
      const savedCredentials = LocalStorage.get<{
        email: string
        token: string
      }>(`${action.payload.slug}-auth`)
      const version = new URLSearchParams(getLocation(state)?.search).get('version') || undefined

      return from$(
        sectionsApi.getSections(
          action.payload.site,
          slug,
          groupSlug,
          authCredentials.email || savedCredentials?.email,
          authCredentials.entryToken || savedCredentials?.token,
          version
        )
      ).pipe(
        mergeMap$((data: ICompositionData) => {
          const pages = {
            pages: data,
            site: action.payload.site,
          }

          if (data) {
            if (action.payload.email && action.payload.entryToken) {
              LocalStorage.set(
                `${action.payload.slug}-auth`,
                {
                  email: action.payload.email,
                  token: action.payload.entryToken,
                },
                ONE_HOUR_MILLISECONDS
              )
            }

            return of$(getSections.success(pages))
          }

          return of$(getSections.failure(new Error()))
        }),
        takeUntil$(
          action$.pipe(
            filter$(isActionOf(locationChange)),
            tap$(() => sectionsApi.cancelSections())
          )
        ),
        catchError$((error: HttpError) => {
          if (error.status === 401) {
            if (action.payload.email && action.payload.entryToken) {
              return of$(openPageAuth(action.payload), setAuthError())
            }

            return of$(openPageAuth(action.payload))
          }

          return of$(getSections.failure(error))
        })
      )
    }),
    catchError$((error: Error) => of$(getSections.failure(error)))
  )

export const authenticateSections: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(authorizePage)),
    mergeMap$((action) => {
      return [resetAuthError(), getSections.request(action.payload)]
    })
  )
