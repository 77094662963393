import { filter as filter$, map as map$ } from 'rxjs/operators'

import _Store from '@Store'

import { getArticle } from 'models/article/actions'
import { isActionOf } from 'typesafe-actions'

import { resetLoading, setLoading } from './../actions'
import * as CONSTS from './../constants/constants'

export const setLoaderWhenArticleFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getArticle.request)),
    map$(() => setLoading(CONSTS.STATIC_ARTICLE))
  )
}

export const setLoaderWhenArticleFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getArticle.success, getArticle.failure], action)
    ),
    map$(() => resetLoading(CONSTS.STATIC_ARTICLE))
  )
}
