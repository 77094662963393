import { createSelector } from 'reselect'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import { prepareRoute } from 'router/utils/prepareRoute'

import _Store from '@Store'

import config from 'config'
import isEmptyObject from 'misc/helpers/isEmptyObject'

import { get as getInternalRouter } from './../selectors'
import { IInternalRouterReducer } from './../types'

const prepareBackUrl = createSelector<_Store.IState, IInternalRouterReducer, string | null>(
  [getInternalRouter],
  ({ internalRouter: { module, params }, embed }) => {
    if (config.theme.isGoing) {
      return null
    }

    let backUrl = routes[Route.index]

    if ((module === Route.buy || module === Route.pinBuy) && params) {
      const { eventSlug, rundateSlug } = params as RouteParams<Route.buy>

      backUrl = prepareRoute({
        route: isEmptyObject(embed) ? Route.rundate : Route.rundate,
        params: { eventSlug, rundateSlug },
      })
    }

    return backUrl
  }
)

export default prepareBackUrl
