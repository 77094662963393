import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { searchPageIndexState } from '../atoms/searchPageIndex'
import { typeFilterState } from '../atoms/typeFilter'

/**
 * Type filter setter selector. The "set" method resets search page to initial state,
 * each time new value is set.
 */
export const setTypeFilter = selector({
  get: ({ get }) => get(typeFilterState),
  set: ({ get, set, reset }, newValue) => {
    set(typeFilterState, newValue)
    reset(searchPageIndexState)
  },
  key: RecoilKeys.setTypeFilter,
})
