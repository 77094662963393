import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondTypography: {
      theme: theme.palette.secondary.main,
    },
    icon: {
      color: theme.palette.text.secondary,
    },
    iconWrapper: {
      width: '2.5rem',
      top: theme.spacing(2),
      paddingTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(0),
      },
    },
    headerContainer: {
      paddingBottom: theme.spacing(2),
    },
  })
)

export default useStyles
