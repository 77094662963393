import { useCallback, useState } from 'react'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'

import { useDebounce } from 'misc/hooks/useDebounce'
import { CloudSearchApi } from 'services/Api/CloudSearchApi'
import { searchPageIndexState } from 'state/cloudSearch/atoms/searchPageIndex'
import { getSearchUrl } from 'state/cloudSearch/selectors/getSearchUrl'
import { ICloudSearchHit } from 'types/CloudSearch'

import { useCloudSearchAnalytics } from './useCloudSearchAnalytics'

export const useCloudSearchPage = () => {
  const setPage = useSetRecoilState(searchPageIndexState)
  const url = useRecoilValue(getSearchUrl)
  const debouncedUrl = useDebounce(url, 300)
  const [events, setEvents] = useState<ICloudSearchHit[]>([])
  const { sendAnalytics, sendItemsAnalytics } = useCloudSearchAnalytics()
  const { data, isValidating } = useSWR(debouncedUrl, CloudSearchApi.get, {
    onSuccess: (data, key) => {
      if (!!data) {
        sendAnalytics(data.found)
        sendItemsAnalytics(data.data, data.start, data.rid)
        setEvents((state) => (data.start === 0 ? data.data : [...state, ...data.data]))
      }
    },
  })

  const onScrollEnd = useCallback(() => {
    if (!isValidating && data?.hasMore) setPage((prev) => prev + 1)
  }, [isValidating, data?.hasMore])

  return {
    data: events,
    tags: data?.tags ?? [],
    categories: data?.categories ?? [],
    found: data?.found ?? 0,
    onScrollEnd,
    isValidating,
  }
}
