import * as React from 'react'

import Landing from 'components/Landing'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/CompositionPage'

export const LandingPage = () => {
  return (
    <PageWrapper fullWidth>
      <Helmet />
      <Landing />
    </PageWrapper>
  )
}
