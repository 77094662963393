import { createSelector } from 'reselect'

import _Store from '@Store'

import { ITicketsReducer } from 'models/tickets/types'
import { ICustomTerm } from 'types/EventCommon'

import get from './get'

const getReceivedCustomTerms = createSelector<_Store.IState, ITicketsReducer | null, ICustomTerm[]>(
  [get],
  (ticket) => ticket?.eventData?.customTerms || []
)

export default getReceivedCustomTerms
