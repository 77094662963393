import { createSelector } from 'reselect'

import config from 'config'
import _L from 'misc/helpers/local'
import { getLocation } from 'models/connectedRouter/selectors'
import { IMeta } from 'models/meta/types'
import { PagesEnum } from 'models/pages/constants/pages'
import { get } from 'models/pages/selectors'
import * as LOCALS from 'vars/constants'

const DEFAULT_DESCRIPTION_TEXT = _L(LOCALS.HELMET__PAGE_DESCRIPTION)
const DEFAULT_PAGE_NAME_TEXT = _L(LOCALS.HELMET__PAGE_TITLE)
const DEFAULT_KEYWORDS_TEXT = _L(LOCALS.HELMET__PAGE_KEYWORDS)

const baseUrl = config.app.baseUrl

const compositionPageMeta = createSelector([get], (page): IMeta => {
  const compositionType = PagesEnum.recommend

  return {
    currentUrl: window?.location.href,
    customGtmId: page.compositions[compositionType]?.metadata?.partnerGtm || undefined,
    description:
      page.compositions[compositionType]?.metadata?.description || DEFAULT_DESCRIPTION_TEXT,
    keywords:
      page.compositions[compositionType]?.metadata?.keywords?.toString() || DEFAULT_KEYWORDS_TEXT,
    noIndex: page.compositions[compositionType]?.metadata?.noIndex || false,
    ogDescription:
      page.compositions[compositionType]?.metadata?.description || DEFAULT_DESCRIPTION_TEXT,
    ogImage:
      page.compositions[compositionType]?.metadata?.ogImage ||
      `${baseUrl}/static_legacy/${config.theme.name}/og_image.jpg`,
    pageName:
      (page.compositions[compositionType]?.metadata?.title &&
        page.compositions[compositionType]?.metadata?.title) ||
      DEFAULT_PAGE_NAME_TEXT,
  }
})

export default compositionPageMeta
