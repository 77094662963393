import { createSelector } from 'reselect'

import _Store from '@Store'

import { IErrorsReducer } from './../types'
import get from './get'

const isError = createSelector<_Store.IState, IErrorsReducer, boolean>(
  [get],
  (error) => error.isError
)

export default isError
