import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolNormalized, IPoolsAllReducer } from './../types'
import getAll from './getAll'

const getPools = createSelector<_Store.IState, IPoolsAllReducer, IPoolNormalized[]>(
  [getAll],
  (all) => (all.poolsData ? all.poolsData.pools : [])
)

export default getPools
