import * as React from 'react'

import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import { useSearchParams } from 'react-router-dom'

import { ISeatSelectionPayload, ISeatsChart } from 'components/SeatsIO/seatsio-react.types'
import { useSeatsIO } from 'components/SeatsIO/useSeatsIO'
import { CurrencyISO, currencySymbol } from 'constants/Currency'
import priceFormatter from 'misc/helpers/priceFormatter'
import { useLang } from 'state/locale/hooks/useLang'

import { ISeatsIOProps } from './SeatsIO.types'

export const SeatsIO = ({ rundateID }: ISeatsIOProps) => {
  const { code: language } = useLang()
  const [allCategories, setAllCategories] = React.useState<number[]>([])
  const [searchParams] = useSearchParams()
  const {
    pricing,
    selectSeat,
    onSelectionValid,
    deselectSeat,
    onSelectionInvalid,
    eventAddToCart,
    eventRemoveFromCart,
    poolsData,
  } = useSeatsIO()
  const selectedPool = searchParams.get('selectedPool')

  if (!poolsData || !rundateID) return null

  const getSelectedPoolId = (seat: ISeatSelectionPayload) =>
    Number(seat.selectedTicketType) ||
    poolsData.pools.find((pool) =>
      pool.seatsIoCategories.find(
        (category) =>
          category.categoryKey === seat.category?.key && seat.category.label === pool.title
      )
    )?.id

  const getPrice = (seat: ISeatSelectionPayload) => {
    if (seat.pricing && 'ticketTypes' in seat.pricing) {
      const type = seat.pricing.ticketTypes.find(
        (type) => type.ticketType === seat.selectedTicketType
      )

      return type?.price ?? 0
    }

    return 0
  }

  const handleSelectSeats = (seat: ISeatSelectionPayload) => {
    selectSeat({
      amount: seat.amount,
      category: {
        amount: seat.category.amount,
        label: seat.category.label,
        key: seat.category.key,
      },
      id: seat.id,
      label: seat.label,
      selectedTicketType: seat.selectedTicketType,
      pricing: seat.pricing,
    })
    eventAddToCart({
      price: getPrice(seat),
      currency: CurrencyISO.PLN,
      poolId: getSelectedPoolId(seat),
    })
  }

  const handleDeselectSeats = (seat: ISeatSelectionPayload) => {
    deselectSeat({
      amount: seat.amount,
      category: seat.category,
      id: seat.id,
      label: seat.label,
      selectedTicketType: seat.selectedTicketType,
      pricing: seat.pricing,
    })
    eventRemoveFromCart({
      price: getPrice(seat),
      currency: CurrencyISO.PLN,
      poolId: getSelectedPoolId(seat),
    })
  }

  const selectedPoolCategory = poolsData?.pools.find((pool) => pool.id === Number(selectedPool))

  const category = selectedPoolCategory?.seatsIoCategories[0].categoryKey

  const availableCategories = () => {
    const categories: number[] = []

    const activePools = poolsData?.pools.filter((pool) => pool.active)

    activePools.forEach((pool) =>
      pool.seatsIoCategories.forEach((category) => categories.push(category.categoryKey))
    )

    return categories
  }

  const unavailableCategories = availableCategories().length ? [] : allCategories

  const onChartRendered = (chart: ISeatsChart) => {
    setAllCategories(chart.config.pricing.map((element) => element.category))

    if (!!selectedPool) {
      const isPrice = chart.config.pricing.some(
        (element) => element.category === category && 'price' in element
      )

      if (isPrice) chart.selectBestAvailable({ number: 1, category })
      else chart.selectBestAvailable({ ticketTypes: { [selectedPool]: 1 } })
    }
  }

  return (
    <SeatsioSeatingChart
      workspaceKey={poolsData.seatsIoPublicKey}
      event={String(rundateID)}
      region={'eu'}
      pricing={pricing}
      language={language}
      holdOnSelectForGAs
      maxSelectedObjects={poolsData.ticketsNumLimit}
      onChartRendered={onChartRendered}
      onObjectDeselected={handleDeselectSeats}
      onObjectSelected={handleSelectSeats}
      onSelectionInvalid={onSelectionInvalid}
      onSelectionValid={onSelectionValid}
      priceFormatter={(price: number) =>
        `${priceFormatter(price)} ${currencySymbol[CurrencyISO.PLN]}`
      }
      selectionValidators={[{ type: 'noOrphanSeats' }]}
      session={'start'}
      availableCategories={availableCategories()}
      unavailableCategories={unavailableCategories}
    />
  )
}
