import { createSelector } from 'reselect'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'

import _Store from '@Store'

import config from 'config'
import { getEvent } from 'models/event/selectors'
import { IEventFull } from 'models/event/types'
import { getParams } from 'models/internalRouter/selectors'

import { IImagesReducer } from '../types'
import get from './get'

const getPlaceImages = createSelector<
  _Store.IState,
  RouteParams<Route.place> | null,
  IImagesReducer,
  IEventFull | null,
  string[] | null
>([getParams, get, getEvent], (_params, images, event) => {
  const params = _params
  const key = `${config.images.placePrefix}${params?.slug ? params?.slug : event?.placeSlug}`
  if (key && key in images) {
    return images[key]
  }

  return null
})

export default getPlaceImages
