import { createSelector } from 'reselect'

import getBasketTickets from 'models/basket/selector/getBasketTickets'
import getSelectedTickets from 'models/pools/selectors/getSelectedTickets'
import { getInsuranceProducts } from 'models/products/selectors'

const getInsurancePrice = createSelector(
  [getSelectedTickets, getBasketTickets, getInsuranceProducts],
  (selectedTickets, basketTickets, insuranceProducts) => {
    if (!selectedTickets.length && !basketTickets.length) return 0

    const getInsurancePricePerPool = (poolPrice: number) => {
      const prices = insuranceProducts.map((product) => product.price)

      if (5 <= poolPrice && poolPrice <= 50) return prices[0]
      if (50.01 <= poolPrice && poolPrice <= 100) return prices[1]
      if (100.01 <= poolPrice && poolPrice <= 150) return prices[2]
      if (150.01 <= poolPrice && poolPrice <= 200) return prices[3]
      if (200.01 <= poolPrice && poolPrice <= 300) return prices[4]
      if (300.01 <= poolPrice && poolPrice <= 400) return prices[5]
      if (400.01 <= poolPrice && poolPrice <= 500) return prices[6]
      if (500.01 <= poolPrice && poolPrice <= 1000) return prices[7]
      if (1000.01 <= poolPrice && poolPrice <= 2500) return prices[8]

      return 0
    }

    const insurancePricesPerSelectedTicket = [
      ...selectedTickets,
      /*...basketTickets,*/
    ].map((pool) => {
      return pool.amount * getInsurancePricePerPool(pool.price || 0)
    })

    return insurancePricesPerSelectedTicket.reduce((previous, current) => previous + current, 0)
  }
)

export default getInsurancePrice
