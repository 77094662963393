import { formTranslations } from 'lang/formio'

const formIo = {
  autoRedirectTime: 5000,
  doneSlug: '-done',
  elementClass: 'formio-form',
  fontAwesomeUrl: 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
  langEN: formTranslations.en,
  langPL: formTranslations.pl,
  location: 'https://jafbioobkhmpnfo.form.io/',
  // TODO: fix translation issue (downgrade)
  scriptUrl: 'https://unpkg.com/formiojs@latest/dist/formio.full.min.js',
  styleUrl: 'https://unpkg.com/formiojs@latest/dist/formio.full.min.css',
  translatedForms: formTranslations.translatedForms,
}

export default formIo
