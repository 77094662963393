import React from 'react'

import { ConfirmationNumberOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, Button, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { CardDeck } from 'components/reusable/CardDeck'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import { DateTimePlace } from 'components/reusable/DateTimePlace'
import DeepLinkModal from 'components/reusable/DeepLinkModal'
import Description from 'components/reusable/Description'
import config from 'config'
import { AnchorLinks } from 'constants/AnchorLinks'
import isHTML from 'misc/helpers/isHTML'
import scrollTo from 'misc/helpers/scrollTo'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { DataMutations } from 'services/DataMutations'
import { DateTime } from 'services/DateTime'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { Styled } from './Event.styles'
import { IEventProps } from './Event.types'
import { Rundates } from './components/Rundates'

export const Event = ({ event, similarEvents }: IEventProps) => {
  const { externalImages } = event
  const { isMobile, isTablet } = useBreakpoints()
  const { i18n } = useDictionary()
  const lowestRundatePrice = event.rundates.reduce((prev, curr) =>
    prev.price < curr.price ? prev : curr
  )
  const lowestRundate = event.rundates.reduce((prev, curr) =>
    DateTime.compareTwoDates(prev.startDate, curr.startDate) ? prev : curr
  )
  const highestRundate = event.rundates.reduce((prev, curr) =>
    DateTime.compareTwoDates(prev.startDate, curr.startDate) ? curr : prev
  )
  const rundatesPlaces = event.rundates
    .map((rundate) => rundate.place.city.name)
    .filter((place, index, array) => array.indexOf(place) === index)
  const places = !!rundatesPlaces.length
    ? rundatesPlaces.length > 4
      ? `${rundatesPlaces.slice(0, 3).join(', ')}  ${i18n.event.andOthers}`
      : rundatesPlaces.join(', ')
    : undefined

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TwoColumnsLayout
        boxComponent={
          <>
            <DateTimePlace
              startDate={lowestRundate.startDate}
              endDate={highestRundate.startDate}
              placeDescription={places}
              isTimeDisabled
            />
            <Box sx={{ mt: 4 }} />
            <ListItem disableGutters disablePadding>
              <ListItemIcon color={'text.primary'} children={<ConfirmationNumberOutlined />} />
              <ListItemText
                primaryTypographyProps={{ variant: 'h5' }}
                children={
                  <>
                    {DataMutations.friendlyPrice({
                      price: lowestRundatePrice.priceWithServiceFee,
                      currency: lowestRundatePrice.currency,
                      priceDescription: lowestRundatePrice.priceDescriptionPL,
                      prefix: i18n.price.from,
                    })}
                    {!!lowestRundatePrice.priceWithServiceFee && !!lowestRundatePrice.price && (
                      <Styled.ToolTip
                        title={i18n.event.serviceFeeCaption(
                          DataMutations.formatServiceFeePrice(
                            // TODO: this value should be in the API response
                            lowestRundatePrice.priceWithServiceFee - lowestRundatePrice.price,
                            lowestRundatePrice.currency
                          )
                        )}
                      >
                        <InfoOutlined fontSize={'small'} />
                      </Styled.ToolTip>
                    )}
                  </>
                }
                style={{ flex: 'unset' }}
              />
            </ListItem>
            <Flex gap={3} direction={'column'} sx={{ mt: 2 }}>
              <Button
                fullWidth={true}
                variant={'contained'}
                size={'large'}
                onClick={(e) => scrollTo(e, AnchorLinks.UPCOMING_RUNDATES)}
                children={i18n.event.buyTicket}
              />
            </Flex>
          </>
        }
        pageTitle={event.titlePL}
        tags={[event.category, ...event.tags]}
        image={externalImages?.[0] || event.externalImages?.[0] || event.images[0]?.large}
      >
        {!!event.rundates.length && (
          <SectionContainer id={AnchorLinks.UPCOMING_RUNDATES} title={i18n.event.rundates}>
            <Rundates rundates={event.rundates} eventSlug={event.slug} />
          </SectionContainer>
        )}
        {event.formattedPartnerDescription && !config.theme.isEmpik && (
          <SectionContainer title={i18n.event.additionalDescriptionTitle}>
            <Description
              text={event.formattedPartnerDescription}
              html={
                isHTML(event.formattedPartnerDescription)
                  ? event.formattedPartnerDescription
                  : undefined
              }
            />
          </SectionContainer>
        )}
      </TwoColumnsLayout>
      <SectionContainer
        title={i18n.event.similarEventsTitle}
        disabled={!similarEvents || !similarEvents.length}
      >
        <CardDeck>
          {similarEvents.map((item) => (
            <CloudSearchCard key={item.id} item={item} />
          ))}
        </CardDeck>
      </SectionContainer>
      {(isMobile || isTablet) && config.theme.isGoing && (
        <DeepLinkModal
          url={prepareRoute({
            route: Route.event,
            params: { eventSlug: event.slug },
          })}
        />
      )}
    </Box>
  )
}
