import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { handlePrevStep, requestToCheckSubmissionsExists } from 'models/entryList/actions'

import CheckSubmission from './CheckSubmission.component'
import { ICheckSubmissionFromDispatch } from './CheckSubmission.types'

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ICheckSubmissionFromDispatch => ({
  handlePrevStep: () => dispatch(handlePrevStep()),
  checkSubmissionsExists: (email) => dispatch(requestToCheckSubmissionsExists(email)),
})

export default connect<{}, ICheckSubmissionFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(CheckSubmission)
