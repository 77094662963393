import { useSelector } from 'react-redux'

import _Store from '@Store'

import isEmptyObject from 'misc/helpers/isEmptyObject'
import { IInternalRouterEmbed } from 'models/internalRouter/types'

export const useIframeParamsFromStore = () =>
  useSelector<_Store.IState, IInternalRouterEmbed | null>(({ internalRouter }) =>
    isEmptyObject(internalRouter.embed) ? null : internalRouter.embed
  )
