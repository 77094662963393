import { phoneNumberValidator } from 'components/_forms/_validators/phoneNumber'
import { repeatPasswordValidator } from 'components/_forms/_validators/repeatPassword'

import { emailValidator } from './email'
import { minNumberValidator } from './minNumber'
import { requiredStringValidator } from './requiredString'
import { strongPasswordValidator } from './strongPassword'
import { termValidator } from './term'

export const validators = {
  email: emailValidator,
  minimumNumber: minNumberValidator,
  phoneNumber: phoneNumberValidator,
  requiredString: requiredStringValidator,
  repeatPassword: repeatPasswordValidator,
  strongPassword: strongPasswordValidator,
  term: termValidator,
}
