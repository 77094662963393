import * as React from 'react'

import { Box, Button, Chip, Paper, Skeleton, Typography } from '@mui/material'

import { SectionContainer } from 'components/_layout/SectionContainer'
import { Styled } from 'components/_layout/TwoColumnsLayout/TwoColumnsLayout.styles'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

export const TwoColumnsSkeleton = () => {
  const { isDesktop } = useBreakpoints()

  const boxSkeleton = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton sx={{ mb: 8 }} />
      <Skeleton variant={'rectangular'} width={'100%'}>
        <Button size={'large'} />
      </Skeleton>
    </>
  )

  return (
    <Styled.Root>
      <Typography variant={'h2'} children={<Skeleton width={'50%'} />} />
      <Box component={'ul'} sx={{ display: 'flex', flexFlow: 'row wrap', gap: 2 }}>
        <Skeleton variant={'rectangular'} width={74} children={<Chip />} />
        <Skeleton variant={'rectangular'} width={64} children={<Chip />} />
        <Skeleton variant={'rectangular'} width={84} children={<Chip />} />
      </Box>
      <Styled.ColumnLayout isDesktop={isDesktop}>
        <Styled.LeftColumn isDesktop={isDesktop}>
          <Styled.ImageBox>
            <Skeleton
              variant={'rectangular'}
              width={'100%'}
              height={isDesktop ? 700 : 350}
              sx={{ display: 'flex' }}
            />
            {!isDesktop && <Paper sx={{ p: 4 }}>{boxSkeleton}</Paper>}
          </Styled.ImageBox>
          <SectionContainer container={true}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant={'h4'} children={<Skeleton sx={{ mb: 4 }} />} />
              <Typography variant={'body2'} children={<Skeleton />} />
              <Typography variant={'body1'} children={<Skeleton />} />
              <Typography variant={'body1'} children={<Skeleton />} />
              <Typography variant={'body1'} children={<Skeleton />} />
              <Typography variant={'body1'} children={<Skeleton />} />
              <Typography variant={'body1'} children={<Skeleton />} />
              <Typography variant={'body1'} children={<Skeleton />} />
            </Box>
          </SectionContainer>
        </Styled.LeftColumn>
        {isDesktop && (
          <Styled.FloatingBox isDesktop={isDesktop}>
            <Styled.TitleSkeleton variant={'h3'} children={<Skeleton sx={{ mb: 4 }} />} />
            {boxSkeleton}
          </Styled.FloatingBox>
        )}
      </Styled.ColumnLayout>
    </Styled.Root>
  )
}
