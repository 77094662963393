import { createSelector } from 'reselect'

import config from 'config'
import { getLocation } from 'models/connectedRouter/selectors'

const contactPageMeta = createSelector(getLocation, (location) => ({
  currentUrl: `${config.app.baseUrl}/kontakt/formularz-kontaktowy`,
  schema:
    config.theme.isGoing && location.pathname.includes('formularz-kontaktowy')
      ? [
          {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'http://www.goingapp.pl',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+48-501-306-607',
                contactType: 'customer service',
              },
            ],
          },
        ]
      : null,
}))

export default contactPageMeta
