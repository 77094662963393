import { createSelector } from 'reselect'

import _Store from '@Store'

import * as CONSTS from './../constants/constants'
import { ISetStateModule, IStateReducer, ISubStateKeys } from './../types'
import get from './get'

const getStateByKey = (property: ISubStateKeys) => (module: ISetStateModule) =>
  createSelector<_Store.IState, IStateReducer, boolean>([get], (state) => {
    switch (property) {
      /*
       * This selector should be used only for boolean properties.
       */
      case CONSTS._LOADING:
      case CONSTS._REQUESTED:
      case CONSTS._SKIP_NEXT_REQUEST:
        return state[module][property]

      default:
        return false
    }
  })

export default getStateByKey
