const colors = {
  actions: {
    background: 'rgba(0, 0, 0, 0.08);',
    disabled: 'rgba(0,0,0,0.12);',
    disabledBackground: 'linear-gradient(0deg, rgba(255,255,255,1), rgba(0, 0, 0, 0.12))',
    disabledText: '#DDD',
    main: '#E0E0E0',
  },
  other: {
    activeRating: '#FFB400',
    backdrop: 'rgba(0, 0, 0, 0.5);',
    divider: '#E0E0E0',
    snackbar: '#323232',
    stroke: 'rgba(255,255,255,0.23)',
    white: '#ffffff',
    gray: 'rgba(0,0,0,0.2)',
  },
  primary: {
    background: 'rgba(255, 255, 255, 0.08)',
    border: 'rgba(255, 255, 255, 1)',
    contrastText: '#000000',
    dark: '#FFFFFF',
    light: '#FFFFFF',
    main: '#FFFFFF',
  },
  secondary: {
    background: 'rgba(52, 73, 94, 0.08)',
    border: '#FFFFFF',
    contrastText: '#FFFFFF',
    dark: '#6B6B6B',
    light: '#B4C2C8',
    main: '#999999',
  },
  text: {
    disabled: 'rgba(255, 255, 255, 0.30);',
    hint: '#666666',
    primary: '#FFFFFF',
    secondary: '#849297',
  },
}

export default colors
