import React, { FC } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import linkToFileInStorage from 'misc/helpers/linkToFileInStorage'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import classes from './CustomTerm.classes'
import styles from './CustomTerm.module.scss'
import useStyles from './CustomTerm.styles'
import { ICustomTermProps } from './CustomTerm.types'

const CustomTerm: FC<ICustomTermProps> = ({ term }) => {
  const { i18n } = useDictionary()
  const theme = useTheme()
  const muiStyles = useStyles(theme)

  return (
    <div>
      <div>
        {term.name || i18n.buy.term.iAcceptTerms}{' '}
        {term.link && (
          <a
            href={linkToFileInStorage(term.link, true)}
            target="_blank"
            className={muiStyles.secondary}
          >
            {i18n.buy.term.read}
          </a>
        )}
        {term.required && '*'}
      </div>

      {term.text && (
        <div className={cn(styles.additionalText, classes.additionalText, muiStyles.secondary)}>
          {term.text}
        </div>
      )}
    </div>
  )
}

export default CustomTerm
