import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'
import { LuceneQueryBuilder } from 'services/Api/LuceneQueryBuilder'

import { searchPageIndexState } from '../atoms/searchPageIndex'
import { searchPhraseState } from '../atoms/searchPhrase'
import { getFilters } from './getFilters'
import { getSorting } from './getSorting'

/**
 * Selector returns CloudSearch url, depending on all the filters.
 */
export const getSearchUrl = selector({
  get: ({ get }) => {
    const {
      category: categoryFilter,
      tags: tagsFilter,
      date: datesFilter,
      various: variousFilters,
      location: locationFilter,
      type: typeFilter,
      sourceIds,
    } = get(getFilters)
    const sorting = get(getSorting)
    const phrase = get(searchPhraseState)
    const page = get(searchPageIndexState)

    return LuceneQueryBuilder.buildCloudSearchUrl({
      categoryFilter,
      datesFilter,
      locationFilter,
      typeFilter,
      page,
      phrase,
      tagsFilter,
      variousFilters,
      sorting,
      sourceIds,
    })
  },
  key: RecoilKeys.getSearchUrl,
})
