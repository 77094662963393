import { useRecoilValue } from 'recoil'

import Analytics from 'services/$analytics'
import { searchPhraseState } from 'state/cloudSearch/atoms/searchPhrase'
import { getFilters } from 'state/cloudSearch/selectors/getFilters'
import { ICloudSearchHit } from 'types/CloudSearch'

export const useCloudSearchAnalytics = () => {
  const phrase = useRecoilValue(searchPhraseState)
  const { date, location, category, tags, various } = useRecoilValue(getFilters)

  return {
    sendAnalytics: (totalResults: number) =>
      Analytics.pushForSearch(
        phrase,
        totalResults,
        date,
        various,
        tags,
        location?.name,
        category?.name
      ),
    sendItemsAnalytics: (items: ICloudSearchHit[], offset: number, rid: string) => {
      Analytics.pushForEventsList(items, offset, phrase, rid)
    },
  }
}
