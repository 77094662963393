import React from 'react'

import { ChevronLeft, Close } from '@mui/icons-material'
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'

import { IDialogAppBarProps } from 'components/reusable/DialogAppBar/DialogAppBar.types'

export const DialogAppBar = ({ title, onClose, onBack }: IDialogAppBarProps) => (
  <AppBar position={'static'} color={'transparent'}>
    <Toolbar sx={{ padding: 0 }}>
      {!!onBack ? (
        <IconButton
          edge={'start'}
          color={'inherit'}
          onClick={onBack}
          children={<ChevronLeft fontSize={'large'} />}
          aria-label={'back'}
        />
      ) : (
        <Box width={51} marginLeft={'-12px'} />
      )}
      <Typography variant={'h6'} margin={'auto'} textAlign={'center'} children={title} />
      {!!onClose ? (
        <IconButton
          edge={'end'}
          color={'inherit'}
          onClick={onClose}
          children={<Close fontSize={'large'} />}
          aria-label={'close'}
        />
      ) : (
        <Box width={51} marginRight={'-12px'} />
      )}
    </Toolbar>
  </AppBar>
)
