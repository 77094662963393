import * as React from 'react'

import { Page } from 'components/_functional/InternalRouter'
import { ActivityPage } from 'components/_pages/Activity'
import { BasketPage } from 'components/_pages/Basket'
import { BasketBuyPage } from 'components/_pages/BasketBuy'
import { BuyPage } from 'components/_pages/Buy'
import { BuyProductsPage } from 'components/_pages/BuyProducts'
import { EntryListPage } from 'components/_pages/EntryList'
import { EventPage } from 'components/_pages/Event'
import { EventModalEmbed } from 'components/_pages/EventModalEmbed'
import { ExtendedPaymentPage } from 'components/_pages/ExtendedPayment'
import { FormioPage } from 'components/_pages/Formio'
import { HappeningPage } from 'components/_pages/Happening'
import { HomePage } from 'components/_pages/Home'
import { PaymentPage } from 'components/_pages/Payment'
import { RecommendPage } from 'components/_pages/Recommend'
import { RundatePage } from 'components/_pages/Rundate'
import { SearchPage } from 'components/_pages/Search'
import { SelectionPage } from 'components/_pages/Selection'
import { TicketReceptionPage } from 'components/_pages/TicketReception'
import PaymentFail from 'components/payment/Fail'
import PaymentSuccess from 'components/payment/Success'
import { Languages } from 'constants/Languages'

import { Route, routes } from './routes'

export const baseRoutes = [
  {
    index: true,
    element: <Page PageComponent={HomePage} module={Route.index} />,
  },
  {
    path: routes[Route.mainPage],
    element: <Page PageComponent={HomePage} module={Route.mainPage} />,
  },
  {
    path: routes[Route.search],
    element: <Page PageComponent={SearchPage} module={Route.search} />,
  },
  {
    path: routes[Route.searchWithParams],
    element: <Page PageComponent={SearchPage} module={Route.searchWithParams} />,
  },
  {
    path: routes[Route.rundate],
    element: <Page PageComponent={RundatePage} module={Route.rundate} />,
  },
  {
    path: routes[Route.enRundate], // 🇬🇧
    element: (
      <Page PageComponent={RundatePage} module={Route.rundate} language={Languages.English} />
    ),
  },
  {
    path: routes[Route.event],
    element: <Page PageComponent={EventPage} module={Route.event} />,
  },
  {
    path: routes[Route.activity],
    element: <Page PageComponent={ActivityPage} module={Route.activity} />,
  },
  {
    path: routes[Route.buy],
    element: <Page PageComponent={BuyPage} module={Route.buy} />,
  },
  {
    path: routes[Route.enBuy], // 🇬🇧
    element: <Page PageComponent={BuyPage} module={Route.buy} language={Languages.English} />,
  },
  {
    path: routes[Route.happening],
    element: <Page PageComponent={HappeningPage} module={Route.happening} />,
  },
  {
    path: routes[Route.enHappening], // 🇬🇧
    element: (
      <Page PageComponent={HappeningPage} module={Route.happening} language={Languages.English} />
    ),
  },
  {
    path: routes[Route.buyProducts],
    element: <Page PageComponent={BuyProductsPage} module={Route.buyProducts} />,
  },
  {
    path: routes[Route.payment],
    element: <Page PageComponent={PaymentPage} module={Route.payment} />,
    children: [
      {
        path: routes[Route.paymentSuccess],
        element: <Page PageComponent={PaymentSuccess} module={Route.paymentSuccess} />,
      },
      {
        path: routes[Route.paymentFail],
        element: <Page PageComponent={PaymentFail} module={Route.paymentFail} />,
      },
    ],
  },
  {
    path: routes[Route.selection],
    element: <Page PageComponent={SelectionPage} module={Route.selection} />,
  },
  {
    path: routes[Route.recommend],
    element: <Page PageComponent={RecommendPage} module={Route.recommend} />,
  },
  {
    path: routes[Route.enPayment], // 🇬🇧
    element: (
      <Page PageComponent={PaymentPage} module={Route.payment} language={Languages.English} />
    ),
    children: [
      {
        path: routes[Route.enPaymentSuccess], // 🇬🇧
        element: (
          <Page
            PageComponent={PaymentSuccess}
            module={Route.paymentSuccess}
            language={Languages.English}
          />
        ),
      },
      {
        path: routes[Route.enPaymentFail], // 🇬🇧
        element: (
          <Page
            PageComponent={PaymentFail}
            module={Route.paymentFail}
            language={Languages.English}
          />
        ),
      },
    ],
  },
  {
    path: routes[Route.pinEntryList],
    element: <Page PageComponent={EntryListPage} module={Route.entryList} isEmbed />,
  },
  {
    path: routes[Route.pinActivity],
    element: <Page PageComponent={ActivityPage} module={Route.activity} isEmbed />,
  },
  {
    path: routes[Route.pinCartBuy],
    element: <Page PageComponent={BasketBuyPage} module={Route.cartBuy} isEmbed />,
  },
  {
    path: routes[Route.pinBuyLegacy],
    element: <Page PageComponent={BuyPage} module={Route.pinBuyLegacy} isEmbed />,
  },
  {
    path: routes[Route.pinBuyProducts],
    element: <Page PageComponent={BuyProductsPage} module={Route.buyProducts} isEmbed />,
  },
  {
    path: routes[Route.pinBuy],
    element: <Page PageComponent={BuyPage} module={Route.buy} isEmbed />,
  },
  {
    path: routes[Route.pinCart],
    element: <Page PageComponent={BasketPage} module={Route.cart} isEmbed />,
  },
  {
    path: routes[Route.pinEntryList],
    element: <Page PageComponent={EntryListPage} module={Route.entryList} isEmbed />,
  },
  {
    path: routes[Route.pinEventLegacy],
    element: <Page PageComponent={RundatePage} module={Route.eventLegacy} isEmbed />,
  },
  {
    path: routes[Route.pinRundate],
    element: <Page PageComponent={RundatePage} module={Route.rundate} isEmbed />,
  },
  {
    path: routes[Route.pinEvents],
    element: <Page PageComponent={SearchPage} module={Route.events} isEmbed />,
  },
  {
    path: routes[Route.pinForm],
    element: <Page PageComponent={FormioPage} module={Route.form} isEmbed />,
  },
  {
    path: routes[Route.pinHappening],
    element: <Page PageComponent={HappeningPage} module={Route.happening} isEmbed />,
  },
  {
    path: routes[Route.pinTicketReception],
    element: <Page PageComponent={TicketReceptionPage} module={Route.ticketReception} isEmbed />,
  },
  {
    path: routes[Route.pinExtendedPayment],
    element: <Page PageComponent={ExtendedPaymentPage} module={Route.pinExtendedPayment} isEmbed />,
  },
  {
    path: routes[Route.pinEventModal],
    element: <Page PageComponent={EventModalEmbed} module={Route.pinEventModal} isEmbed />,
  },
  {
    path: routes[Route.cart],
    element: <Page PageComponent={BasketPage} module={Route.cart} />,
  },
  {
    path: routes[Route.cartBuy],
    element: <Page PageComponent={BasketBuyPage} module={Route.cartBuy} />,
  },
]
