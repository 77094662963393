import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((color: string) =>
  createStyles({
    fontColor: {
      color,
    },
  })
)

export default useStyles
