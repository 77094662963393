import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import ArtistTile from 'components/reusable/ArtistSlider/components/ArtistTile/ArtistTile.component'
import {
  IArtistTileFromDispatch,
  IArtistTileFromState,
} from 'components/reusable/ArtistSlider/components/ArtistTile/ArtistTile.types'
import { getImagesByCloudinaryPrefixAndSlug } from 'models/images/actions'
import { get } from 'models/images/selectors'
import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

const mapStateToProps = (state: _Store.IState): IArtistTileFromState => ({
  images: get(state),
  isLoading: getLoading(CONST.SECTIONS)(state),
  compositionIdentifier: getCompositionIdentifier(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IArtistTileFromDispatch => ({
  mounted: (payload) => dispatch(getImagesByCloudinaryPrefixAndSlug(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistTile)
