import * as React from 'react'

import { Box, Container } from '@mui/material'

import { IPageWrapperProps } from 'components/_layout/PageWrapper/PageWrapper.types'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useAppState } from 'state/app/hooks/useAppState'

/**
 * This component should be used only once in component flow - to wrap components
 * inside the page component for particular route.
 *
 * @param children
 * @param fullWidth - it true page will be rendered using full width of the window and no paddings.
 * @param gap - CSS gap property (uses MUI spacing multiplier)
 */
export const PageWrapper: React.FC<React.PropsWithChildren<IPageWrapperProps>> = ({
  children,
  fullWidth,
  gap = 2,
}) => {
  const { isDesktop } = useBreakpoints()
  const { isWebview } = useAppState()
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  if (fullWidth)
    return (
      <Box
        ref={wrapperRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minHeight: '80vh',
        }}
        component={'main'}
        children={children}
      />
    )

  return (
    <Container
      ref={wrapperRef}
      component={'main'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        pt: 4,
        minHeight: '80vh',
        gap,
        pb: isWebview ? 4 : isDesktop ? 8 : 4,
      }}
      children={children}
    />
  )
}
