import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useReturnAccountBank } from './useReturnAccountBank'

export const ReturnAccountBank = () => {
  const { i18n } = useDictionary()
  const { control, validationRules, isLoading, handleSubmit, isVisible, redirectOnSuccess } =
    useReturnAccountBank()

  return (
    <>
      <SectionContainer title={i18n.returnAccountBank.title} container={true}>
        <Flex gap={3} align={'flex-end'}>
          <Form.Wrapper sx={{ flex: 'inherit' }}>
            <TextInput
              label={i18n.forms.labels[FormFields.BANKACCOUNTNUMBER]}
              name={FormFields.BANKACCOUNTNUMBER}
              control={control}
              rules={validationRules[FormFields.BANKACCOUNTNUMBER]}
            />
          </Form.Wrapper>
          <Button
            disabled={isLoading}
            variant={'contained'}
            type={'submit'}
            children={i18n.buttons.save}
            onClick={handleSubmit}
          />
        </Flex>
      </SectionContainer>

      <Dialog maxWidth={'sm'} fullWidth open={isVisible} onClose={redirectOnSuccess}>
        <DialogTitle>{i18n.returnAccountBank.success.title}</DialogTitle>
        <DialogContent>{i18n.returnAccountBank.success.description}</DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            type={'submit'}
            children={i18n.buttons.ok}
            onClick={redirectOnSuccess}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
