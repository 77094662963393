import * as React from 'react'

import { Search } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import debounce from 'lodash.debounce'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { Route, routes } from 'router/routes'

import { StyledTextField } from 'components/search/Searchbar/Searchbar.styles'
import { setSearchPhrase } from 'state/cloudSearch/selectors/setSearchPhrase'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const Searchbar = () => {
  const { i18n } = useDictionary()
  const [value, setValue] = React.useState('')
  const setPhrase = useSetRecoilState(setSearchPhrase)
  const navigate = useNavigate()
  const debouncedSetPhrase = React.useCallback(
    debounce((value) => {
      setPhrase(value)
      navigate(routes[Route.search])
    }, 500),
    []
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setValue(inputValue)
    debouncedSetPhrase(inputValue)
  }

  return (
    <StyledTextField
      sx={{ my: 2 }}
      placeholder={i18n.searchbar.placeholder}
      color={'primary'}
      value={value}
      onChange={handleChange}
      size={'medium'}
      type={'search'}
      fullWidth={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'}>
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )
}
