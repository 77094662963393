import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolNormalized, IPoolSelectedSeats, IPoolsGroupedSelectedSeats } from './../types'
import getPools from './getPools'
import getSeatsGroupedByCategoryKeysAndPools from './getSeatsGroupedByCategoryKeysAndPools'

const getSeatsGroupedByPoolId = createSelector<
  _Store.IState,
  IPoolsGroupedSelectedSeats,
  IPoolNormalized[],
  IPoolSelectedSeats
>([getSeatsGroupedByCategoryKeysAndPools, getPools], (seatsGroupedByPoolNameOrKey, pools) => {
  const seatsGroupedByPoolId: IPoolSelectedSeats = {}

  pools.map((current) => {
    const { id, seatsIoCategories } = current
    const stringId = id.toString()
    seatsIoCategories.forEach((seatsCategory) => {
      if (seatsCategory.categoryKey) {
        seatsGroupedByPoolId[id] =
          (seatsGroupedByPoolNameOrKey[seatsCategory.categoryKey] &&
            seatsGroupedByPoolNameOrKey[seatsCategory.categoryKey]?.find((pool) => {
              return pool.poolName === stringId
            })?.ids) ||
          seatsGroupedByPoolId[id] ||
          []
      }
    })
  })

  return seatsGroupedByPoolId
})

export default getSeatsGroupedByPoolId
