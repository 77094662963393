import React, { useEffect, useState } from 'react'

import { Grid, useTheme } from '@mui/material'

import { CurrencyISO } from 'constants/Currency'
import { getSelectedPriceType } from 'misc/helpers/happening/getSelectedPriceType'
import usePreviousState from 'misc/hooks/usePreviousState'

import useStyles from './PriceTypes.styles'
import { IHappeningPriceType, IPriceTypesProps } from './PriceTypes.types'
import PriceType from './components/PriceType'

const PriceTypes = ({
  availabilities,
  calculatePricePerPerson,
  happening,
  happeningSpaces,
  selectedSpace,
  selectedSlot,
  setOnlyOnePrice,
  setPricesInState,
  eventRemoveFromCartHappening,
  eventAddToCartHappening,
}: IPriceTypesProps) => {
  const prevSpace = usePreviousState(selectedSpace)
  const [allHappeningPriceTypes, setAllHappeningPriceTypes] = useState<IHappeningPriceType[]>([])
  const availabilitiesSelectedSlot = availabilities.find((slot) => selectedSlot === slot.start)
  const availabilitiesSelectedSlotSpace = availabilitiesSelectedSlot?.spaces.find(
    (space) => selectedSpace === space.spaceId
  )
  const isNotCalculatedPerPersonAndHasOnePriceType =
    !calculatePricePerPerson && allHappeningPriceTypes.length === 1

  const selectedSlotPriceTypes = () => {
    const getAdditionalDataByPriceType = (priceType: string) => {
      const foundedPriceType = allHappeningPriceTypes.find((price) => price.type === priceType)
      if (foundedPriceType) {
        return {
          title: foundedPriceType.title,
          titleEN: foundedPriceType.titleEN,
        }
      }

      return { title: '', titleEN: '' }
    }

    return availabilitiesSelectedSlotSpace?.prices.map((slotPrice) => ({
      ...slotPrice,
      title: getAdditionalDataByPriceType(slotPrice.type).title,
      titleEN: getAdditionalDataByPriceType(slotPrice.type).titleEN,
    }))
  }

  const theme = useTheme()
  const muiStyles = useStyles(theme)

  useEffect(() => {
    if (isNotCalculatedPerPersonAndHasOnePriceType && selectedSpace !== -1) {
      setOnlyOnePrice({
        numberOfPlayers: 1,
        priceType: getSelectedPriceType(allHappeningPriceTypes[0].type, selectedSlotPriceTypes()),
      })
    }
    if (
      isNotCalculatedPerPersonAndHasOnePriceType &&
      prevSpace !== selectedSpace &&
      prevSpace !== -1 &&
      !!allHappeningPriceTypes.length
    ) {
      eventRemoveFromCartHappening({
        price: getSelectedPriceType(allHappeningPriceTypes[0].type, selectedSlotPriceTypes()).value,
        currency: CurrencyISO.PLN,
      })
    }

    if (
      isNotCalculatedPerPersonAndHasOnePriceType &&
      selectedSpace !== -1 &&
      !!allHappeningPriceTypes.length
    ) {
      eventAddToCartHappening({
        price: getSelectedPriceType(allHappeningPriceTypes[0].type, selectedSlotPriceTypes()).value,
        currency: CurrencyISO.PLN,
      })
    }
  }, [happening, selectedSpace])

  useEffect(() => {
    const priceTypesArray: IHappeningPriceType[] = []
    happeningSpaces?.forEach((space) =>
      space.rulePrice.forEach((priceRule) =>
        priceRule.prices.forEach((price) => {
          if (
            price.title &&
            allHappeningPriceTypes.findIndex((priceType) => priceType.type === price.type) === -1
          ) {
            priceTypesArray.push({
              counter: price.counter || 1,
              description: price.description,
              descriptionEN: price.descriptionEN,
              title: price.title,
              titleEN: price.titleEN,
              type: price.type,
              defaultCount: price.defaultCount,
              counterAsMin: price.counterAsMin || false,
              priceOverride: price.priceOverride
            })
          }
        })
      )
    ) // all Happening priceType type names are collected and pushed into a separate array for display.
    const filteredPriceTypesArray = priceTypesArray.filter(
      (v, i, a) => a.findIndex((t) => t.type === v.type) === i
    )
    setAllHappeningPriceTypes(filteredPriceTypesArray)
    setPricesInState(filteredPriceTypesArray)
  }, [])

  if (isNotCalculatedPerPersonAndHasOnePriceType) return null

  return (
    <Grid container={true} direction={'column'}>
      {allHappeningPriceTypes?.map((priceType) => (
        <PriceType
          priceType={priceType}
          selectedSlotPriceTypes={selectedSlotPriceTypes()}
          key={priceType.type}
        />
      ))}
    </Grid>
  )
}

export default PriceTypes
