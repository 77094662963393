import React from 'react'

import { Link, Typography } from '@mui/material'

import { FormFields } from 'components/_forms/_constants/FieldNames'
import linkToFileInStorage from 'misc/helpers/linkToFileInStorage'
import _L from 'misc/helpers/local'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import * as LOCALS from 'vars/constants'

export const LabelTerms = () => {
  const { i18n } = useDictionary()

  return (
    <Typography>
      {i18n.forms.labels[FormFields.TERMS].iAccept}
      <Link
        href={linkToFileInStorage(_L(LOCALS.STORAGE__TERMS), true)}
        children={i18n.forms.labels[FormFields.TERMS].termsLink}
        target={'_blank'}
        rel={'noopener noreferrer nofollow'}
      />
      {i18n.forms.labels[FormFields.TERMS].and}
      <Link
        href={linkToFileInStorage(_L(LOCALS.STORAGE__PRIVACY_POLICY), true)}
        children={i18n.forms.labels[FormFields.TERMS].privacyPolicyLink}
        target={'_blank'}
        rel={'noopener noreferrer nofollow'}
      />
      {'. *'}
    </Typography>
  )
}
