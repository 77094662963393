import * as React from 'react'

import { Chip } from '@mui/material'
import { useRecoilState } from 'recoil'

import { ISourceIdsFilterProps } from 'components/search/SourceIdsFilter/SourceIdsFilter.types'
import { HitFields } from 'constants/CloudSearch'
import { setSourceIdsFilter } from 'state/cloudSearch/selectors/setSourceIdsFilter'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const SourceIdsFilter = ({ searchParams }: ISourceIdsFilterProps) => {
  const { i18n } = useDictionary()
  const [sourceIdsFilter, setSourceIdsFilterState] = useRecoilState(setSourceIdsFilter)

  const clearSourceIds = () => setSourceIdsFilterState([])

  React.useEffect(() => {
    if (!!searchParams && !searchParams.entries().next().done) {
      const ids: string[] = []
      searchParams.forEach((value, key) => {
        if (key === HitFields.id_source) {
          ids.push(value)
        }
      })

      if (ids) setSourceIdsFilterState(ids)
    }
  }, [searchParams])

  if (!sourceIdsFilter.length) return null

  return (
    <Chip
      label={i18n.profile.recommended}
      onDelete={clearSourceIds}
      onClick={clearSourceIds}
      size={'small'}
    />
  )
}
