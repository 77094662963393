import * as React from 'react'

import { TextField } from '@mui/material'
import { FieldValues, useController } from 'react-hook-form'

import { ITextInputProps } from './TextInput.types'

export const TextInput = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  label,
  rules,
  shouldUnregister,
  flex = 1,
  trimValue,
  ...rest
}: ITextInputProps<T>) => {
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  return (
    <TextField
      sx={{ flex }}
      {...inputProps}
      onChange={(event) => {
        if (trimValue) {
          event.target.value = event.target.value.trim()
        }
        onChange(event)
      }}
      label={error?.message ?? label}
      inputRef={ref}
      error={invalid}
      {...rest}
    />
  )
}
