import { selectors } from 'goingapp-shared'
import { createSelector } from 'reselect'

import { IAvailableSpaceWithDescription } from 'components/Happening/components/Spaces/Spaces.types'
import { Languages } from 'constants/Languages'

import getData from './getData'
import getSelected from './getSelected'
import getSelectedSlot from './getSelectedSlot'

const DEFAULT_LANGUAGE = 'pl'

const getSpaces = createSelector(
  [getSelectedSlot, getData, getSelected],
  (slot, data, selected) => {
    const spaces = selectors.getSpaces(slot, data, Languages.Polski, selected)
    if (spaces.length) {
      return spaces
    }

    return (
      data?.spaces.map((space) => {
        const localizedDescription = space?.description.find(
          (desc) => desc.language === Languages.Polski
        )

        return {
          ...space,
          available: false,
          break: null,
          capacity: 0,
          capacityLeft: 0,
          description: localizedDescription?.description,
          duration: 0,
          prices: [],
          reserved: true,
          ruleCapacityId: 0,
          rulePriceId: 0,
          ruleTimeId: 0,
          spaceId: 0,
          start: 0,
          title: localizedDescription?.title,
          upsell: null,
          upsellExtended: null,
        } as IAvailableSpaceWithDescription
      }) || []
    )
  }
)

export default getSpaces
