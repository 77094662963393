import { createSelector } from 'reselect'

import _Store from '@Store'

import { IEntryListReducer } from './../types'
import get from './get'

const getIsViewOnly = createSelector<_Store.IState, IEntryListReducer, boolean>(
  [get],
  (state) => state.viewOnly
)

export default getIsViewOnly
