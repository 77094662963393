import { isJsonString } from 'misc/helpers/isJsonString'
import { IBasket } from 'models/basket/types'
import { IInternalRouterEvent } from 'models/internalRouter/types'

import { IApiAction, IAvailableFunctions, IFrameAction } from './types'

const TYPE_GOING = 'GOING'

export class IframeProvider {
  public addEventListenerMethod = (
    messageEvent: MessageEvent,
    callBack: (event: IInternalRouterEvent) => void
  ) => {
    if (messageEvent.data && isJsonString(messageEvent.data)) {
      const event = JSON.parse(messageEvent.data)
      if (event.type === TYPE_GOING) {
        callBack(event.action)
      }
    }
  }

  public runRedirectParentMethod = (url: string) => {
    const action: IApiAction = {
      payload: url,
      type: IAvailableFunctions.REDIRECT,
    }

    this.sendMessage(action)
  }

  public runResizeMethod = (size: string) => {
    const action: IApiAction = {
      payload: size,
      type: IAvailableFunctions.RESIZE,
    }

    this.sendMessage(action)
  }

  public runScrollTopMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.SCROLL_TO_TOP,
    }
    setTimeout(() => this.sendMessage(action), 1000)
    setTimeout(() => this.sendMessage(action), 2000)

    this.sendMessage(action)
  }

  public runSetLoaderPositionTopMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.LOADER_POSITION_TOP,
    }

    this.sendMessage(action)
  }

  public pingMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.PING,
    }

    this.sendMessage(action)
  }

  public redirectEventIframe(eventSlug: string, rundateSlug: string) {
    const action: IApiAction = {
      payload: JSON.stringify({ eventSlug, rundateSlug }),
      type: IAvailableFunctions.REDIRECT_EVENT_IFRAME,
    }

    this.sendMessage(action)
  }

  public getIframeRelativeScrollPosition(callback: (payload: number) => void) {
    const action: IApiAction = {
      type: IAvailableFunctions.GET_IFRAME_RELATIVE_SCROLL_POSITION,
    }
    this.sendMessage(action)

    window.addEventListener('message', (event) => {
      const data: IFrameAction = typeof event.data === 'string' ? JSON.parse(event.data) : undefined
      if (
        data?.type &&
        data.type === TYPE_GOING &&
        data?.action?.type === IAvailableFunctions.SEND_IFRAME_RELATIVE_SCROLL_POSITION &&
        typeof data.action.payload === 'number'
      ) {
        callback(data.action.payload)
      }
    })
  }

  public setGlobalBasket = (payload: IBasket[]) => {
    const action: IApiAction = {
      payload: JSON.stringify(payload),
      type: IAvailableFunctions.SET_BASKET_DATA,
    }
    this.sendMessage(action)
  }

  private sendMessage = (action: IApiAction) => {
    const targetOrigin = '*'
    const iframeAction: IFrameAction = {
      action,
      type: 'GOING',
    }

    window.parent.postMessage(JSON.stringify(iframeAction), targetOrigin)
  }
}

export default new IframeProvider()
