import { ILeadSectionProps } from 'components/Bricks/components/LeadSection/LeadSection.types'

import {
  ISearchExtraData,
  ISectionsComponentDataMutable,
  ISectionsComponentLead,
} from './../types/sections'

export const section_lead = (
  data: ISectionsComponentDataMutable,
  extra?: ISearchExtraData | null,
  background?: string
): ILeadSectionProps => {
  if (!data) {
    return {} as ILeadSectionProps
  }

  const { description, title } = data[0] as ISectionsComponentLead

  return {
    background,
    color: extra?.color,
    description,
    showDropdown: extra?.showDropdown || false,
    title,
  }
}
