import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import {
  IHappeningFormLayoutFromDispatch,
  IHappeningFormLayoutFromState,
} from 'components/Happening/components/HappeningFormLayout/HappeningFormLayout.types'
import { getBasketData } from 'models/basket/selector'
import { isError } from 'models/errors/selectors'
import {
  clearSelectedHappening,
  setCalculatedPrices,
  setDay,
  setDiscountState,
} from 'models/happening/actions'
import { getData, getHappeningTerms, getSelected, isAllSelected } from 'models/happening/selectors'
import { redirectParentTo } from 'models/internalRouter/actions'
import { getIframeParams } from 'models/internalRouter/selectors'
import { TRANSACTION } from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import { getTheme } from 'models/theme/selectors'

import HappeningFormLayout from './HappeningFormLayout.component'

const mapStateToProps = (state: _Store.IState): IHappeningFormLayoutFromState => ({
  canReserve: isAllSelected(state),
  customTerms: getHappeningTerms(state),
  isError: isError(state),
  selectedTheme: getTheme(state),
  upsellFromState: getSelected(state).upsell?.isSelected,
  isTransactionLoading: getLoading(TRANSACTION)(state),
  currentBasketData: getBasketData(state),
  addToBasketRedirect: getIframeParams(state)?.addToBasketRedirect,
  happening: getData(state),
  isEmbed: !!getIframeParams(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IHappeningFormLayoutFromDispatch => ({
  calculatePrice: (value) => dispatch(setCalculatedPrices(value)),
  setDiscountState: (discountCode) => dispatch(setDiscountState(discountCode)),
  redirectParentTo: (url) => dispatch(redirectParentTo(url)),
  setDay: (day) => dispatch(setDay(day)),
  clearSelectedHappening: () => dispatch(clearSelectedHappening()),
})

export default connect<
  IHappeningFormLayoutFromState,
  IHappeningFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(HappeningFormLayout)
