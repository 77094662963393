import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import SectionLead from 'components/Bricks/components/LeadSection'
import useStyles from 'components/Bricks/components/OneBigAndVerticalList/OneBigAndVerticalList.styles'
import DualLink from 'components/reusable/DualLink'
import ImageFixed from 'components/reusable/ImageFixed'
import Title from 'components/reusable/Title'
import getUrlWithTransformation from 'misc/helpers/cloudinary/getUrlWithTransformation'
import fillDataWithEmptyElement from 'misc/helpers/fillDataWithEmptyElement'
import slugifyString from 'misc/helpers/slugifyString'
import Analytics from 'services/$analytics'

import styles from './OneBigAndVerticalList.module.scss'
import {
  IOneBigAndVerticalListData,
  IOneBigAndVerticalListProps,
} from './OneBigAndVerticalList.types'

const DEFAULT_ELEMENTS_NUMBER = 3

const OneBigAndVerticalList: FC<IOneBigAndVerticalListProps> = ({
  articlesListData,
  bigArticleData,
  color,
  id,
  isLoading,
  sectionDescription,
  sectionTitle,
  compositionIdentifier,
}) => {
  const GAClassName = (title: string) => `${compositionIdentifier}-${id}-${slugifyString(title)}`
  const [bigImageSrc, setBigImageSrc] = useState<string>()
  const [bigImgWidth, setBigImgWidth] = useState<number>()
  const [smallImgWidth, setSmallImgWidth] = useState<number>()
  const bigImgRef = useRef<HTMLDivElement>(null)
  const smallImgRef = useRef<HTMLDivElement>(null)

  if (isLoading && articlesListData.length === 0) {
    articlesListData = fillDataWithEmptyElement<IOneBigAndVerticalListData>(DEFAULT_ELEMENTS_NUMBER)
  }

  const theme = useTheme()
  const muiStyles = useStyles(true, color)(theme)

  useEffect(() => {
    if (bigImgRef.current?.offsetWidth) {
      setBigImgWidth(bigImgRef.current?.offsetWidth)
    }
    if (bigImgWidth) {
      const dimensions = [bigImgWidth, Math.ceil(bigImgWidth / 1.15)]
      setBigImageSrc(getUrlWithTransformation(dimensions, bigArticleData.imageUrl))
    }
  }, [bigImgRef.current?.offsetWidth])

  useEffect(() => {
    if (smallImgRef.current?.offsetWidth) {
      setSmallImgWidth(smallImgRef.current?.offsetWidth + 200) // 200 is added just to make quality a bit better
    }
  }, [smallImgRef.current?.offsetWidth])

  const handleAddToDataLayerOnClick =
    (link: string, title: string, componentTitle?: string) => (e: MouseEvent<HTMLElement>) =>
      Analytics.pushForHomepageSelectItem(e, link, title, componentTitle)

  return (
    <>
      <SectionLead
        color={color}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <div className={styles.wrapper} id={id}>
        <div className={styles.bigArticle} ref={bigImgRef}>
          <ImageFixed
            src={bigImageSrc}
            to={bigArticleData.url}
            alt={bigArticleData.title}
            className={GAClassName(bigArticleData.title || '')}
          />
          <div className={styles.content}>
            {bigArticleData.title && (
              <Title
                title={bigArticleData.title}
                isLoading={isLoading}
                link={bigArticleData.url}
                customClassName={cn(
                  styles.title,
                  muiStyles.fontColor,
                  GAClassName(bigArticleData.title || '')
                )}
                onClick={handleAddToDataLayerOnClick(
                  bigArticleData.url,
                  bigArticleData.title || '',
                  sectionTitle!
                )}
              />
            )}
          </div>
        </div>
        <div className={styles.articlesList}>
          {articlesListData.map((article, key) => {
            return (
              <DualLink
                to={article.url}
                key={key}
                className={cn(
                  styles.smallArticle,
                  muiStyles.root,
                  muiStyles.boxShadow,
                  muiStyles.fontColor,
                  GAClassName(article.title || '')
                )}
                onClick={handleAddToDataLayerOnClick(
                  article.url,
                  article.title || '',
                  sectionTitle!
                )}
              >
                <div className={cn(styles.image, muiStyles.disablePointerEvents)} ref={smallImgRef}>
                  {smallImgWidth && (
                    <ImageFixed
                      src={getUrlWithTransformation(
                        [smallImgWidth, Math.ceil(smallImgWidth / 1.15)],
                        article.imageUrl
                      )}
                    />
                  )}
                </div>
                <div className={styles.content}>
                  {article.title && (
                    <Title
                      title={article.title}
                      isLoading={isLoading}
                      customClassName={cn(
                        styles.title,
                        muiStyles.fontColor,
                        muiStyles.disablePointerEvents,
                        GAClassName(article.title || '')
                      )}
                    />
                  )}
                </div>
                <div className={styles.arrow} />
              </DualLink>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default OneBigAndVerticalList
