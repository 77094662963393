import { createSelector } from 'reselect'

import _Store from '@Store'

import { ILandingPageReducer, ILandingPageReducerCurrentKey } from './../types'
import get from './get'

const getCurrentKey = createSelector<
  _Store.IState,
  ILandingPageReducer,
  ILandingPageReducerCurrentKey
>([get], (landingPage) => landingPage.currentKey)

export default getCurrentKey
