import { getBoolean, getRemoteConfig } from 'firebase/remote-config'

import { RemoteConfigKeys } from 'constants/RemoteConfig'

export const getReviewAfterPurchase = () => {
  const webAppDefinition = getBoolean(getRemoteConfig(), RemoteConfigKeys.isReviewAfterPurchase)

  if (webAppDefinition) return webAppDefinition

  return false
}
