import * as React from 'react'

import { Check, KeyboardArrowDown } from '@mui/icons-material'
import { Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useRecoilState } from 'recoil'

import { useLocations } from 'components/_functional/LocationContextProvider'
import { ILocationFilterProps } from 'components/search/LocationFilter/LocationFilter.types'
import { HitFields } from 'constants/CloudSearch'
import { initialState } from 'state/cloudSearch/atoms/locationFilter'
import { setLocationFilter } from 'state/cloudSearch/selectors/setLocationFilter'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { ILocation } from 'types/Locations'

export const LocationFilter = ({ searchParams, locationSlug }: ILocationFilterProps) => {
  const { i18n } = useDictionary()
  const [filter, setFilter] = useRecoilState(setLocationFilter)
  const { locations } = useLocations()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const handleShow = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)
  const handleClear = () => setFilter(initialState)
  const handleSetFilter = (location: ILocation) => () => {
    // TODO: await Analytics.logEvent(FirebaseEvents.FILTER_LOCATION, { selectedLocation })
    setFilter(location)
    handleClose()
  }

  React.useEffect(() => {
    if (
      !!searchParams &&
      searchParams.has(HitFields.locations_ids || searchParams.has(HitFields.locations_names)) &&
      !!locations
    ) {
      const location =
        locations.find((loc) => loc.id.toString() === searchParams.get(HitFields.locations_ids)) ||
        locations.find((loc) => loc.name === searchParams.get(HitFields.locations_names))

      if (location) setFilter({ name: location.name, id: location.id, slug: location.slug })
    }
  }, [searchParams, locations])

  React.useEffect(() => {
    if (locationSlug && locations.length) {
      const location = locations.find((loc) => loc.slug === locationSlug)
      if (location) setFilter(location)
    }
  }, [locationSlug, locations])

  return (
    <>
      <Chip
        id={'location-filter-button'}
        aria-controls={isOpen ? 'location-filter-menu' : undefined}
        aria-haspopup={'true'}
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleShow}
        icon={<KeyboardArrowDown />}
        label={!!filter ? filter.name : i18n.locationFilter.filterTitle}
        onDelete={filter ? handleClear : undefined}
        size={'small'}
      />
      <Menu
        id={'location-filter-menu'}
        title={i18n.locationFilter.filterTitle}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'location-filter-button' }}
      >
        {locations.map((location) => (
          <MenuItem key={location.id} onClick={handleSetFilter(location)}>
            {location.id === filter?.id && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText inset={!!filter && location.id !== filter?.id} children={location.name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
