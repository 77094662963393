import * as React from 'react'

import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Bricks from 'components/Bricks'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/CompositionPage'
import AuthGate from 'components/modals/AuthGate'
import EighteenGate from 'components/reusable/EighteenGate'
import { authorizePage, getCustomPage } from 'models/pages/actions'
import { BricksEnum } from 'models/pages/constants/bricks'
import { usePagesAuthFromStore } from 'models/pages/hooks/usePagesAuthFromStore'
import { useRecommendFromStore } from 'models/pages/hooks/useRecommendFromStore'
import { ICompositionData } from 'models/pages/types'
import { ISectionsComponentAgeGate } from 'models/pages/types/sections'

const getEighteenGate = (structure?: ICompositionData | null) => {
  if (!structure) return null

  const sectionWithGate = structure.sections.find(
    (section) =>
      !!section.components.find((component) => component.type === BricksEnum[BricksEnum.age_gate])
  )

  const ageGate = sectionWithGate?.components.find(
    (component) => component.type === BricksEnum[BricksEnum.age_gate]
  )

  const data = ageGate?.data?.length && ageGate.data[0]

  return data as ISectionsComponentAgeGate | undefined
}

export const RecommendPage = () => {
  const location = useLocation()
  const structure = useRecommendFromStore()
  const pagesAuth = usePagesAuthFromStore()
  const dispatch = useDispatch()
  const { userLocation } = useLocations()
  const eighteenGate = getEighteenGate(structure)

  React.useEffect(() => {
    dispatch(getCustomPage())
  }, [location])

  return (
    <PageWrapper fullWidth>
      <Helmet />
      <Bricks structure={structure} userLocation={userLocation} />
      <AuthGate
        action={(payload) => dispatch(authorizePage(payload))}
        error={pagesAuth.error}
        show={pagesAuth.showGate}
        payload={pagesAuth.originalPayload}
      />
      {!!eighteenGate && structure?.slug && (
        <EighteenGate
          slug={structure.slug}
          title={eighteenGate.title}
          description={eighteenGate.description}
          yesLabel={eighteenGate.yesLabel}
          noLabel={eighteenGate.noLabel}
        />
      )}
    </PageWrapper>
  )
}
