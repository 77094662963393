import React from 'react'

import { Button, Dialog } from '@mui/material'

import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { AppleLogin } from 'components/auth/AppleLogin'
import { IAuthDialogProps } from 'components/auth/AuthDialog/AuthDialog.types'
import { useAuthFormSelector } from 'components/auth/AuthDialog/useAuthFormSelector'
import { FacebookLogin } from 'components/auth/FacebookLogin'
import { GoogleLogin } from 'components/auth/GoogleLogin'
import SimpleLoading from 'components/reusable/SimpleLoading'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const AuthDialog = ({ isVisible, onClose }: IAuthDialogProps) => {
  const { i18n } = useDictionary()
  const { Form, isLoading } = useAuthFormSelector()
  const { isLoggedIn } = useUser()

  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth={'xs'}>
      {isLoading && <SimpleLoading />}
      <Form />
      <Flex direction={'column'} gap={2} sx={{ marginTop: 6 }}>
        {!isLoggedIn && (
          <>
            <FacebookLogin />
            <GoogleLogin />
            <AppleLogin />
          </>
        )}
        <Button
          onClick={onClose}
          children={isLoggedIn ? i18n.buttons.close : i18n.buttons.cancel}
          fullWidth
        />
      </Flex>
    </Dialog>
  )
}
