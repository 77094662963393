import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import config from 'config'
import { getLocation } from 'models/connectedRouter/selectors'
import { getEvent } from 'models/event/actions'
import { getEvent as getEventSelector } from 'models/event/selectors'
import { isActionOf } from 'typesafe-actions'

import {
  checkUserCardStatus,
  getPremiumUserByToken,
  getUserCardStatus,
  redirect,
  setToken,
} from './../actions'
import { get, getIsUserPremium } from './../selectors'
import { UserCardStatusEnum } from './../types'

export const fetchPremiumWhenEventSuccess: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getEvent.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const event = getEventSelector(state)
      const isUserPremium = getIsUserPremium(state)

      if (isUserPremium) {
        return EMPTY$
      }

      const checkUserToken = event?.isPremiumEvent && event?.premiumAuthUrl

      if (checkUserToken) {
        return [getPremiumUserByToken.request()]
      }

      return EMPTY$
    })
  )
}

export const checkIfNeedFetchCardData: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(checkUserCardStatus)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { userCardData } = get(state)
      const isEmailNotChange = action.payload.email === userCardData?.email
      const isCardNumberNotChange = action.payload.cardNumber === userCardData?.cardNumber
      if (isEmailNotChange && isCardNumberNotChange) {
        return EMPTY$
      }

      return [getUserCardStatus.request(action.payload)]
    })
  )
}

export const fetchUserCardData: _Store.IEpic = (action$, state$, { premiumApi }) => {
  return action$.pipe(
    filter$(isActionOf(getUserCardStatus.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, _]) => {
      return from$(
        premiumApi.checkIfUserIsPremiumByCardNumber(action.payload.cardNumber, action.payload.email)
      ).pipe(
        mergeMap$((data) => {
          let status = UserCardStatusEnum.NODATA

          if (data.verified) {
            if (data.isPremium) {
              status = UserCardStatusEnum.OK
            } else {
              status = UserCardStatusEnum.INACTIVE
            }
          } else {
            switch (data.errorCode) {
              case 1:
              case 2:
                status = UserCardStatusEnum.ERROR
                break
              default:
                status = UserCardStatusEnum.NOMATCH
            }
          }

          return [getUserCardStatus.success(status)]
        }),
        catchError$((error: Error) => of$(getUserCardStatus.failure(error)))
      )
    })
  )
}

export const fetchPremiumUserByToken: _Store.IEpic = (action$, state$, { premiumApi }) => {
  return action$.pipe(
    filter$(isActionOf(getPremiumUserByToken.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const token = premiumApi.getToken(getLocation(state).search)

      if (token) {
        return from$(premiumApi.checkIfUserIsPremiumByToken(token)).pipe(
          mergeMap$((data) => {
            if (data.isTokenValid) {
              const user = premiumApi.normalize(data)

              return [getPremiumUserByToken.success(user), setToken(token)]
            }

            return [redirect()]
          }),
          catchError$(() => of$(redirect()))
        )
      }

      return [redirect()]
    })
  )
}

export const doRedirectWhenPremiumTokenIsInvalid: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(redirect)),
    withLatestFrom$(state$),
    tap$(([_, state]) => {
      const event = getEventSelector(state)
      const url = event?.premiumAuthUrl ?? config.premium.url

      window.location.href = url
    }),
    mergeMap$(() => {
      return EMPTY$
    })
  )
}
