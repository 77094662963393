import React, { MouseEvent } from 'react'

import cn from 'classnames'

import Link from 'components/reusable/DualLink'
import ReadMoreLoading from 'components/reusable/loadings/ReadMoreLoading'
import slugifyString from 'misc/helpers/slugifyString'
import Analytics from 'services/$analytics'

import styles from './ReadMore.module.scss'
import useStyles from './ReadMore.styles'
import { IReadMoreProps } from './ReadMore.types'

const READ_MORE = 'Zobacz więcej'

const ReadMore = ({
  color,
  id,
  link,
  isLoading,
  text,
  isBgDark,
  className,
  compositionIdentifier,
}: IReadMoreProps) => {
  const muiStyles = useStyles(color, isBgDark)()
  const GAClassName = (title: string) => `${compositionIdentifier}-${id}-${slugifyString(title)}`
  const linkText = text ?? READ_MORE

  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    link ? Analytics.pushForHomepageSelectItem(e, link, text || '') : undefined

  if (isLoading) {
    return <ReadMoreLoading />
  }

  return (
    <div className={styles.readMoreContainer} id={id}>
      <Link
        className={cn(styles.readMoreLink, muiStyles.fontColor, className, GAClassName(linkText))}
        to={link}
        onClick={handleAddToDataLayerOnClick}
      >
        {linkText}
      </Link>
    </div>
  )
}

export default ReadMore
