// State properties
export const _LOADING = 'loading'
export const _REQUESTED = 'requested'
export const _RESOURCE_URL = 'resourceUrl'
export const _SKIP_NEXT_REQUEST = 'skipNextRequest'

// Modules
export const _ANY = '_any'
export const AVAILABILITIES = 'availabilities'
export const ARTIST = 'artist'
export const EVENT = 'event'
export const EVENTS = 'events'
export const FILTERS = 'filters'
export const HAPPENING = 'happening'
export const HAPPENING_PRICE = 'happeningPrice'
export const MYTICKET = 'myTicket'
export const LANDING_PAGE = 'landingPage'
export const PASSWORDRESET = 'passwordReset'
export const PLACE = 'place'
export const PLACES = 'places'
export const POOLS = 'pools'
export const PREMIUM = 'premium'
export const SECTIONS = 'sections'
export const SELECTION = 'selection'
export const STATIC_ARTICLE = 'staticArticle'
export const TRANSACTION = 'transaction'
export const TRANSLATIONS = 'translations'
