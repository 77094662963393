import React from 'react'

import { Divider, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import ImageFixed from 'components/reusable/ImageFixed'
import getNonEmptyObject from 'misc/helpers/getNonEmptyObject'
import translatableDate from 'misc/helpers/translatableDate'
import { IEventFull } from 'models/event/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { SellerInfoButton } from '../../reusable/SellerInfoButton'
import styles from './Details.module.scss'
import useStyles from './Details.styles'
import { IDetailsProps } from './Details.types'
import Loading from './components/Loading'

const Details = ({
  event: potentialEvent,
  isBasketPay,
  isProductsPay,
  isLoading,
}: IDetailsProps) => {
  const { i18n } = useDictionary()
  const event = getNonEmptyObject(potentialEvent) as IEventFull
  const theme = useTheme()
  const muiStyles = useStyles(theme)

  if (isBasketPay || isProductsPay) return null

  return (
    <div className={cn(styles.eventDetails, muiStyles.root, muiStyles.radius)}>
      <ImageFixed
        src={event.externalImages?.[0] || event.imagesUrl}
        alt={event.title}
        transformation={{
          height: 700,
          width: 810,
        }}
      />
      {isLoading && <Loading />}

      {!isLoading && (
        <div className={styles.content}>
          <h1 className={styles.title}>{event.title}</h1>
          <div className={styles.infoBox}>
            <h2 className={styles.info}>
              {translatableDate(event.startDate, event.rundateDescription, i18n.dates)}
            </h2>
            {event.hourDescription ? (
              <h2 className={styles.info}>{event.hourDescription}</h2>
            ) : (
              event.friendlyHour && <h2 className={styles.info}>{event.friendlyHour}</h2>
            )}
            {event.placeName && (
              <h3 className={styles.info}>
                {event.placeSlug && (
                  <Link
                    to={prepareRoute({
                      route: Route.place,
                      params: { slug: event.placeSlug },
                    })}
                    className={muiStyles.fontColor}
                  >
                    {event.placeName}, {event.placeCityName}
                  </Link>
                )}
                {!event.placeSlug && `${event.placeName}, ${event.placeCityName}`}
              </h3>
            )}
            <Divider flexItem sx={{ display: { md: 'none' }, mt: 5, mb: 3 }} />

            {event.partnerInfo && !event.partnerInfo.vatPayer ? (
              <SellerInfoButton partnerInfo={event.partnerInfo} />
            ) : (
              <Typography variant={'subtitle2'}>{i18n.event.goingSeller}</Typography>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Details
