import * as React from 'react'

import { Chip } from '@mui/material'
import { Filter } from 'lucene-query-builder'
import { useRecoilState } from 'recoil'

import { IVariousFiltersProps } from 'components/search/VariousFilters/VariousFilters.types'
import { HitFields } from 'constants/CloudSearch'
import { capitalizeFirstLetter } from 'misc/helpers/capitalizeFirstLetter'
import { setVariousFilters } from 'state/cloudSearch/selectors/setVariousFilters'

enum LegacySearchFields {
  public_tags_names = 'public_tags_names',
  event_category_name = 'event_category_name',
  title_pl = 'title_pl',
}

export const VariousFilters = ({ searchParams }: IVariousFiltersProps) => {
  const [filters, setFilters] = useRecoilState(setVariousFilters)
  const removeFilter = (filter: Filter) => {
    const key = Object.keys(filter)[0]

    setFilters((state) => state.filter((x) => x[key] !== filter[key]))
  }

  /**
   * TODO: remove legacy filters after transition period.
   */
  const handleLegacyFilters = (params: URLSearchParams) => {
    const tagsNames = params.get(LegacySearchFields.public_tags_names)

    if (tagsNames) {
      setFilters((prev) => [...prev, { [HitFields.tags_names]: tagsNames }])
    }

    const categoryName = params.get(LegacySearchFields.event_category_name)

    if (categoryName) {
      setFilters((prev) => [...prev, { [HitFields.category_name]: categoryName }])
    }

    const titlePL = params.get(LegacySearchFields.title_pl)

    if (titlePL) {
      setFilters((prev) => [...prev, { [HitFields.name_pl]: titlePL }])
    }
  }

  React.useEffect(() => {
    if (!!searchParams && !searchParams.entries().next().done) {
      const filters: Filter[] = []
      const exclude = [
        HitFields.category_id,
        HitFields.category_name,
        HitFields.locations_names,
        HitFields.locations_ids,
        HitFields.tags_names,
        HitFields.tags_ids,
        HitFields.tags_slugs,
        HitFields.type,
        HitFields.id_source,
      ]

      searchParams.forEach((value, key) => {
        if (!exclude.includes(key as HitFields)) {
          const isValidFilter = Object.keys(HitFields).includes(key)

          if (isValidFilter) filters.push({ [key]: value })
        }
      })

      setFilters(filters)
      handleLegacyFilters(searchParams)
    }
  }, [searchParams])

  if (!filters.length) return null

  return (
    <>
      {filters.map((filter) => {
        const clearAction = () => removeFilter(filter)
        const [key, value] = Object.entries(filter)[0]

        return (
          <Chip
            key={`${key}-${value}`}
            onDelete={clearAction}
            onClick={clearAction}
            label={capitalizeFirstLetter(String(value))}
            size={'small'}
          />
        )
      })}
    </>
  )
}
