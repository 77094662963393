import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { validateForm } from 'models/analytics/actions'
import { isDataHydratedTransaction } from 'models/pools/selectors'
import { buyAndPayOnline } from 'models/transaction/actions'

import { getInitialValues } from './../Online/Online.selectors'
import Products from './Products.component'
import { IBuyingOnsiteFromDispatch, IBuyingOnsiteFromState } from './Products.types'

const mapStateToProps = (state: _Store.IState): IBuyingOnsiteFromState => ({
  initialValues: (basketCustomTerms) => getInitialValues(state, basketCustomTerms),
  isDataHydratedTransaction: isDataHydratedTransaction(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IBuyingOnsiteFromDispatch => ({
  buyAndPay: (values) => dispatch(buyAndPayOnline(values)),
  checkout: () => dispatch(validateForm()),
})

export default connect<void, IBuyingOnsiteFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Products)
