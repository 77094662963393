import { useEffect, useState } from 'react'

import {
  CollectionReference,
  DocumentChange,
  DocumentData,
  Query,
  collection,
  collectionGroup,
  documentId,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'

import {
  FavoriteCollectionIds,
  FavoritesSubCollectionIds,
  FavoritesTypes,
} from '../../../constants/Favorites'
import { splitArrayIntoChunks } from '../../../misc/helpers/splitArrayToChunks'
import Logger from '../../../services/Logger'
import { IFavoriteResponse, IFollowersResponse } from '../../../types/Favorites'
import { useUser } from '../../_functional/UserContextProvider'

export const useUserFavorites = (type: FavoritesTypes) => {
  const [favorites, setFavorites] = useState<IFavoriteResponse[]>([])
  const [isFirstReqDone, setFirstReqDone] = useState(false)
  const { firebaseUser } = useUser()
  const db = getFirestore()

  useEffect(() => {
    if (!firebaseUser?.uid) {
      setFavorites([])

      return () => {}
    }

    const colGroup = collectionGroup(
      db,
      FavoritesSubCollectionIds.Followers
    ) as Query<IFollowersResponse> // TODO: no assertion
    const q = query<IFollowersResponse, DocumentData>(
      colGroup,
      where('uid', '==', firebaseUser.uid)
    )

    const subscriber = onSnapshot(
      q,
      async (followersCol) => {
        const changesPerSelectedType: Array<DocumentChange<IFollowersResponse>> = []
        const addsPerSelectedType: string[] = []
        const removesPerSelectedType: string[] = []
        let index = 0

        followersCol.docChanges().map((change) => {
          const collectionName = change.doc.ref.parent.parent?.path.split('/')[0]

          if (collectionName === FavoriteCollectionIds[type]) changesPerSelectedType.push(change)
        })

        changesPerSelectedType.forEach((ch) => {
          const changedFavPath = ch.doc.ref.parent.parent?.path
          const changedFavId = changedFavPath?.split('/')?.[1]

          if (ch.type === 'added' && changedFavId) addsPerSelectedType.push(changedFavId)

          if (ch.type === 'removed' && changedFavId) removesPerSelectedType.push(changedFavId)
        })

        const chunkedAdds = splitArrayIntoChunks(addsPerSelectedType, 10)
        const chunkedRemoves = splitArrayIntoChunks(removesPerSelectedType, 10)

        if (!!chunkedAdds.length) {
          for (const addsChunk of chunkedAdds) {
            const col = collection(
              db,
              FavoriteCollectionIds[type]
            ) as CollectionReference<IFavoriteResponse> // TODO: no assertion
            const q = query<IFavoriteResponse, DocumentData>(
              col,
              where(documentId(), 'in', addsChunk)
            )
            const favsBatch = await getDocs(q)
            const addedDocs = favsBatch.docs.map((doc) => doc.data())

            setFavorites((oldFavs) => [...oldFavs, ...addedDocs])

            if (!isFirstReqDone) setFirstReqDone(true)
            index++
          }
        }

        if (!!chunkedRemoves.length) {
          for (const removesChunk of chunkedRemoves) {
            setFavorites((oldFavs) =>
              oldFavs.filter((item) =>
                removesChunk.find((docName) => item.item.externalId.toString(10) !== docName)
              )
            )
          }
        }

        if (!isFirstReqDone && !changesPerSelectedType.length) setFirstReqDone(true)
      },
      (error) => {
        Logger.error('useUserFavorites', error)
      }
    )

    return () => subscriber()
  }, [firebaseUser?.uid])

  return {
    favorites,
    count: favorites.length,
    isFirstReqDone,
  }
}
