import * as React from 'react'

import { Box } from '@mui/material'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'

export const CardDeck = React.forwardRef<HTMLUListElement, React.PropsWithChildren<{}>>(
  ({ children }, ref) => {
    const { isDesktop, isTablet } = useBreakpoints()

    return (
      <Box
        component={'ul'}
        ref={ref}
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${isDesktop ? 4 : isTablet ? 3 : 1}, 1fr)`,
          gap: 4,
          gridAutoRows: '-webkit-min-content',
        }}
      >
        {children}
      </Box>
    )
  }
)
