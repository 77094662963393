import React from 'react'

import {
  ContentCopy,
  EmailOutlined,
  FacebookOutlined,
  Share,
  Telegram,
  Twitter,
  WhatsApp,
} from '@mui/icons-material'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material'

import { ISocialShareProps } from 'components/SocialShare/SocialShare.types'
import { useNotify } from 'components/_functional/NotificationProvider'
import { VKIcon } from 'components/_svg/VKIcon'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useToggle } from 'misc/hooks/useToggle'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const SocialShare = ({ url, title, withLabel }: ISocialShareProps) => {
  const { isVisible, handleShow, handleHide, handleToggle } = useToggle()
  const { i18n } = useDictionary()
  const { notify } = useNotify()
  const { isDesktop } = useBreakpoints()

  return (
    <>
      {withLabel ? (
        <Button
          variant={'outlined'}
          color={'secondary'}
          size={'medium'}
          onClick={handleToggle}
          fullWidth={true}
          startIcon={<Share />}
        >
          {i18n.buttons.share}
        </Button>
      ) : (
        <IconButton sx={{ p: 4 }} onClick={handleToggle} children={<Share />} />
      )}
      <SwipeableDrawer anchor={'bottom'} open={isVisible} onClose={handleHide} onOpen={handleShow}>
        <List
          sx={{ width: 'auto' }}
          role={'presentation'}
          onClick={handleHide}
          onKeyDown={handleHide}
        >
          <ListItem>
            <ListItemIcon children={<FacebookOutlined />} />
            <ListItemText children={i18n.share.facebook} />
            <ListItemSecondaryAction>
              <ListItemButton
                component={'a'}
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                rel={'nofollow noopener noreferrer'}
                aria-label={i18n.share.facebook}
                children={i18n.buttons.share}
                target={'_blank'}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon children={<Twitter />} />
            <ListItemText children={i18n.share.twitter} />
            <ListItemSecondaryAction>
              <ListItemButton
                component={'a'}
                href={`https://twitter.com/intent/tweet/?text=Udost%C4%99pnij&amp;url=${url}`}
                rel={'nofollow noopener noreferrer'}
                aria-label={i18n.share.twitter}
                children={i18n.buttons.share}
                target={'_blank'}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon children={<WhatsApp />} />
            <ListItemText children={i18n.share.whatsapp} />
            <ListItemSecondaryAction>
              <ListItemButton
                component={'a'}
                href={
                  isDesktop
                    ? `https://api.whatsapp.com/send?text=${url}`
                    : `whatsapp://send?text=${url}`
                }
                rel={'nofollow noopener noreferrer'}
                aria-label={i18n.share.whatsapp}
                children={i18n.buttons.share}
                target={'_blank'}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon children={<Telegram />} />
            <ListItemText children={i18n.share.telegram} />
            <ListItemSecondaryAction>
              <ListItemButton
                component={'a'}
                href={`https://telegram.me/share/url?url=${url}`}
                rel={'nofollow noopener noreferrer'}
                aria-label={i18n.share.telegram}
                children={i18n.buttons.share}
                target={'_blank'}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon children={<VKIcon />} />
            <ListItemText children={i18n.share.vk} />
            <ListItemSecondaryAction>
              <ListItemButton
                component={'a'}
                href={`https://vk.com/share.php?url=${url}`}
                rel={'nofollow noopener noreferrer'}
                aria-label={i18n.share.vk}
                children={i18n.buttons.share}
                target={'_blank'}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon children={<EmailOutlined />} />
            <ListItemText children={i18n.share.email} />
            <ListItemSecondaryAction>
              <ListItemButton
                component={'a'}
                href={`mailto:?subject=${title}&body=${url}`}
                rel={'nofollow noopener noreferrer'}
                aria-label={i18n.share.email}
                children={i18n.buttons.share}
                target={'_self'}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon children={<ContentCopy />} />
            <ListItemText children={i18n.share.copyLink} />
            <ListItemSecondaryAction>
              <ListItemButton
                onClick={async () => {
                  await navigator.clipboard.writeText(url)
                  notify({ text: i18n.notifications.linkCopied })
                }}
                children={i18n.buttons.copy}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </>
  )
}
