import { useSelector } from 'react-redux'

import _Store from '@Store'

import { NavigateParams } from 'models/internalRouter/types'

export const useNavigateFromStore = () =>
  useSelector<_Store.IState, NavigateParams | undefined>(
    ({ internalRouter: { internalRouter } }) => internalRouter.navigate
  )
