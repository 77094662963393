import React, { useEffect, useState } from 'react'

import { ConfirmationNumberOutlined } from '@mui/icons-material'
import { Button, Tooltip, Typography } from '@mui/material'

import { Styled } from 'components/Rundate/components/Pools/Pool/Pool.styles'
import { ShowMore } from 'components/reusable/ShowMore'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { DataMutations } from 'services/DataMutations'
import { useAppState } from 'state/app/hooks/useAppState'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { getServiceFeeTest } from 'state/remoteConfig/selectors/getServiceFeeTest'

import { IPoolProps } from './Pool.types'

const Pool: React.FC<IPoolProps> = ({
  eventSlug,
  pool,
  rundateSlug,
  rundateID,
  selectPool,
  selectTicket,
  eventProceed,
}) => {
  const { i18n } = useDictionary()
  const { isDesktop } = useBreakpoints()

  /** TODO: remove when service fee test is over */
  const { isWebview } = useAppState()
  const [feeData, setFeeData] = useState<{
    serviceFee: typeof pool.serviceFee
    priceWithServiceFee: typeof pool.priceWithServiceFee
  }>({ serviceFee: pool.serviceFee, priceWithServiceFee: pool.priceWithServiceFee })
  useEffect(() => {
    const feeTest = getServiceFeeTest({
      rundateID,
      price: pool.price,
      serviceFee: pool.serviceFee,
      priceWithServiceFee: pool.priceWithServiceFee,
      isWebview,
    })
    setFeeData({ serviceFee: feeTest.serviceFee, priceWithServiceFee: feeTest.priceWithServiceFee })
  }, [rundateID, pool.price, pool.serviceFee, pool.priceWithServiceFee, isWebview])
  /** remove when service fee test is over */

  const choosePool = () => {
    selectPool(eventSlug, rundateSlug)
    selectTicket(
      pool.id,
      1,
      pool.poolDescription?.title ?? '',
      pool.currency,
      pool.payments,
      pool.price,
      feeData.serviceFee,
      pool.isDetailedPurchaseRequired,
      pool.forms
    )
    eventProceed(i18n.event.choose)
  }

  return (
    <Styled.Root isDesktop={isDesktop}>
      {isDesktop && <ConfirmationNumberOutlined />}
      <Styled.Description>
        <Typography
          align={isDesktop ? 'left' : 'center'}
          variant={'h5'}
          children={pool.poolDescription?.title}
        />
        <ShowMore text={pool.poolDescription?.description} />
      </Styled.Description>
      <Styled.Price>
        {DataMutations.friendlyPrice({
          price: feeData.priceWithServiceFee,
          currency: pool.currency,
        })}
        <Tooltip
          title={i18n.event.serviceFeeCaption(
            DataMutations.formatServiceFeePrice(feeData.serviceFee, pool.currency)
          )}
        >
          <Styled.InfoIcon fontSize={'small'} />
        </Tooltip>
      </Styled.Price>
      <Button
        size={'large'}
        variant={'contained'}
        onClick={choosePool}
        disabled={!pool.active}
        children={i18n.event.choose}
      />
    </Styled.Root>
  )
}

export default Pool
