const ALARM_ICON = 'app-icon-alarm'
const ANDROID_ICON = 'app-icon-android'
const APPLE_ICON = 'app-icon-appleinc'
const ASCENDING_ICON = 'app-icon-sort-amount-asc'
const BARCODE_ICON = 'app-icon-barcode'
const BAR_ICON = 'app-icon-bar'
const BASKET = 'app-icon-basket'
const BROWSER = 'app-icon-browser'
const CALENDAR_EMPTY_ICON = 'app-icon-going-calendar-empty-frame'
const CALENDAR_GENERIC_ICON = 'app-icon-calendar'
const CALENDAR_ICON = 'app-icon-calendar1'
const CAPACITY_ICON = 'app-icon-going-capacity-frame'
const CART_3 = 'app-icon-cart-3'
const CART_ICON = 'app-icon-cart'
const CINEMA_ICON = 'app-icon-cinema'
const CLOCK_FRAME_ICON = 'app-icon-clock-frame'
const CLOCK_ICON = 'app-icon-clock1'
const CLOCK_SOLID_ICON = 'app-icon-clock-solid'
const CREDIT_CARD_ICON = 'app-icon-credit-card'
const CROWD = 'app-icon-crowd'
const CROWD_ICON = 'app-icon-going-crowd-frame'
const DESCENDING_ICON = 'app-icon-sort-amount-desc'
const DESKTOP_ICON = 'app-icon-display'
const EMPIK_CART = 'app-icon-empik-cart'
const EMPIK_DOOR = 'app-icon-empik-door'
const FACEBOOK_FRAME_ICON = 'app-icon-going-social-facebook-frame'
const FACEBOOK_SOLID_ICON = 'app-icon-facebook-solid'
const FACEBOOK_SQUARE_ICON = 'app-icon-facebook-square'
const FILTER_ICON = 'app-icon-filter'
const GAMES_ICON = 'app-icon-games'
const GIFT_ICON = 'app-icon-gift'
const GOOGLE_FRAME_ICON = 'app-icon-going-social-google-frame'
const GOOGLE_SOLID_ICON = 'app-icon-google'
const GOOGLE_SQUARE_ICON = 'app-icon-google-square'
const GROUP_ICON = 'app-icon-going-group-frame'
const HEART_EMPTY_ICON = 'app-icon-going-heart-frame'
const HEART_SOLID_ICON = 'app-icon-going-heart-solid'
const HELP_ICON = 'app-icon-help'
const HOME_EMPIK_ICON = 'app-icon-empik-home'
const HOME_GEN1_ICON = 'app-icon-home'
const HOME_GEN2_ICON = 'app-icon-home3'
const HOME_GOING_ICON = 'app-icon-going-home-ico'
const HORN_ICON = 'app-icon-bullhorn'
const INFO = 'app-icon-info'
const INFO_GOING_ICON = 'app-icon-going-info-frame'
const INSTAGRAM_SOLID_ICON = 'app-icon-going-social-instagram-solid'
const LIFEBUOY_ICON = 'app-icon-lifebuoy'
const LOCATION_GENERIC1_ICON = 'app-icon-location'
const LOCATION_GENERIC2_ICON = 'app-icon-location-invert'
const LOCATION_GOING_ICON = 'app-icon-going-location-frame'
const LOCATION_ICON = 'app-icon-location1'
const LORRY_ICON = 'app-icon-lorry'
const MAIL_GOING_ICON = 'app-icon-going-mail-frame'
const MAIL_ICON = 'app-icon-mail'
const MAP_FRAME_ICON = 'app-icon-map-frame'
const MAP_ICON = 'app-icon-going-map-frame'
const MAP_SOLID_ICON = 'app-icon-map-solid'
const MOBILE_ICON = 'app-icon-mobile'
const MUSEUM_ICON = 'app-icon-museum'
const MY_ACCOUNT_ICON = 'app-icon-my-account-icon'
const MY_TICKET = 'app-icon-my-ticket'
const MY_TICKET_GOING = 'app-icon-my-ticket-going'
const NETWORKING_ICON = 'app-icon-going-networking-frame'
const PIN_ICON = 'app-icon-pin'
const PREMIUM_INFO = 'app-icon-premium-info'
const PREMIUM_STAR = 'app-icon-premium-star'
const QR_ICON = 'app-icon-qrcode'
const RELOAD = 'app-icon-reload'
const RESTAURANT_ICON = 'app-icon-restaurant'
const SAD_FACE = 'app-icon-sad-face'
const SAD_FACE_ICON = 'app-icon-going-sad-frame'
const SEARCH_ICON = 'app-icon-search-icon'
const SPINNER = 'app-icon-spinner'
const STAR_FRAME_ICON = 'app-icon-star-empty'
const STAR_SOLID_ICON = 'app-icon-star-solid'
const SUMMARY_ACCOUNT_ICON = 'app-icon-summary-account'
const SUMMARY_FRIENDS_ICON = 'app-icon-summary-friends'
const SUMMARY_INFO_ICON = 'app-icon-summary-info'
const SUMMARY_MAIL_ICON = 'app-icon-summary-mail'
const SUMMARY_OK_ICON = 'app-icon-summary-ok'
const SUMMARY_TICKET_ICON = 'app-icon-summary-ticket'
const TENT_ICON = 'app-icon-going-tent-frame'
const TICKETMAIL_ICON = 'app-icon-going-ticketmail-frame'
const TICKET_ANGLE_ICON = 'app-icon-ticket-angle'
const TICKET_GOING_FRAME_ICON = 'app-icon-going-ticket-frame'
const TICKET_ICON = 'app-icon-empik-ticket'
const TWITTER_SOLID_ICON = 'app-icon-going-social-twitter-solid'
const VIEW_ICONS_ICON = 'app-icon-view-switch-icons'
const VIEW_LIST_ICON = 'app-icon-view-switch-list'
const WALLET_ICON = 'app-icon-going-wallet-frame'
const WINDOWS_ICON = 'app-icon-windows8'
const YOUTUBE_SOLID_ICON = 'app-icon-going-social-youtube-solid'
const ZOOM_GOING = 'app-icon-zoom-going'
const ZOOM_IN_ICON = 'app-icon-zoom-in'
const ZOOM_OUT_ICON = 'app-icon-zoom-out'

interface IIcons {
  [key: string]: string
}

export default {
  /* EMPIK BASE */
  account: MY_ACCOUNT_ICON,
  calendar: CALENDAR_ICON,
  clock: CLOCK_ICON,
  crowdEmpik: CROWD,
  empikCart: EMPIK_CART,
  empikDoor: EMPIK_DOOR,
  facebookFrame: FACEBOOK_FRAME_ICON,
  filter: FILTER_ICON,
  help: HELP_ICON,
  homeEmpik: HOME_EMPIK_ICON,
  location: LOCATION_ICON,
  lorry: LORRY_ICON,
  mail: MAIL_ICON,
  premiumInfo: PREMIUM_INFO,
  premiumStar: PREMIUM_STAR,
  search: SEARCH_ICON,
  summaryAccount: SUMMARY_ACCOUNT_ICON,
  summaryFriends: SUMMARY_FRIENDS_ICON,
  summaryInfo: SUMMARY_INFO_ICON,
  summaryMail: SUMMARY_MAIL_ICON,
  summaryOk: SUMMARY_OK_ICON,
  summaryTicket: SUMMARY_TICKET_ICON,
  ticket: TICKET_ICON,
  viewIcons: VIEW_ICONS_ICON,
  viewList: VIEW_LIST_ICON,

  /* GOING BASE */
  basketGoing: BASKET,
  calendarGoing: CALENDAR_EMPTY_ICON,
  capacity: CAPACITY_ICON,
  crowd: CROWD_ICON,
  googleFrame: GOOGLE_FRAME_ICON,
  group: GROUP_ICON,
  heartEmpty: HEART_EMPTY_ICON,
  heartSolid: HEART_SOLID_ICON,
  homeGoing: HOME_GOING_ICON,
  infoIcon: INFO_GOING_ICON,
  instagramSolid: INSTAGRAM_SOLID_ICON,
  locationGoing: LOCATION_GOING_ICON,
  mailGoing: MAIL_GOING_ICON,
  map: MAP_ICON,
  myTicket: MY_TICKET,
  myTicketGoing: MY_TICKET_GOING,
  networking: NETWORKING_ICON,
  sad: SAD_FACE_ICON,
  sadFaceGoing: SAD_FACE,
  searchGoing: ZOOM_GOING,
  tent: TENT_ICON,
  ticketGoing: TICKET_GOING_FRAME_ICON,
  ticketMail: TICKETMAIL_ICON,
  twitterSolid: TWITTER_SOLID_ICON,
  wallet: WALLET_ICON,
  youtubeSolid: YOUTUBE_SOLID_ICON,

  alarm: ALARM_ICON,
  ascending: ASCENDING_ICON,
  bar: BAR_ICON,
  barcode: BARCODE_ICON,
  brandAndroid: ANDROID_ICON,
  brandApple: APPLE_ICON,
  brandWindows: WINDOWS_ICON,
  browser: BROWSER,
  calendar1: CALENDAR_GENERIC_ICON,
  cart: CART_ICON,
  cart3: CART_3,
  cinema: CINEMA_ICON,
  clockFrame: CLOCK_FRAME_ICON,
  clockSolid: CLOCK_SOLID_ICON,
  creditCard: CREDIT_CARD_ICON,
  descending: DESCENDING_ICON,
  desktop: DESKTOP_ICON,
  facebookSolid: FACEBOOK_SOLID_ICON,
  facebookSquare: FACEBOOK_SQUARE_ICON,
  games: GAMES_ICON,
  gift: GIFT_ICON,
  googleSolid: GOOGLE_SOLID_ICON,
  googleSquare: GOOGLE_SQUARE_ICON,
  home1: HOME_GEN1_ICON,
  home2: HOME_GEN2_ICON,
  horn: HORN_ICON,
  info: INFO,
  lifeBuoy: LIFEBUOY_ICON,
  location1: LOCATION_GENERIC1_ICON,
  location2: LOCATION_GENERIC2_ICON,
  mapFrame: MAP_FRAME_ICON,
  mapSolid: MAP_SOLID_ICON,
  mobile: MOBILE_ICON,
  museum: MUSEUM_ICON,
  pin: PIN_ICON,
  qr: QR_ICON,
  reload: RELOAD,
  restaurant: RESTAURANT_ICON,
  spinner: SPINNER,
  starFrame: STAR_FRAME_ICON,
  starSolid: STAR_SOLID_ICON,
  ticketAngle: TICKET_ANGLE_ICON,
  zoomIn: ZOOM_IN_ICON,
  zoomOut: ZOOM_OUT_ICON,
} as IIcons
