import React from 'react'

import { Typography } from '@mui/material'

import { Flex } from 'components/_layout/Flex'
import { Avatars } from 'components/reusable/Avatars'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IFollowersProps } from './Followers.types'

export const Followers = ({ numberOfFollowers }: IFollowersProps) => {
  const { i18n } = useDictionary()

  return (
    <Flex sx={{ width: '100%' }} direction={'row'} justify={'space-between'} align={'center'}>
      <Typography variant={'h5'}>
        {numberOfFollowers}{' '}
        <Typography component={'span'} variant={'subtitle2'}>
          {i18n.followers.follows}
        </Typography>
      </Typography>

      <Avatars length={numberOfFollowers} />
    </Flex>
  )
}
