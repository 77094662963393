import React from 'react'

import { Badge, Typography, useTheme } from '@mui/material'

import { FavoritesTypes } from '../../../constants/Favorites'
import { Route, routes } from '../../../router/routes'
import { prepareRoute } from '../../../router/utils/prepareRoute'
import { useDictionary } from '../../../state/locale/hooks/useDictionary'
import { Flex } from '../../_layout/Flex'
import { FavoritesIcon } from '../../_svg/FavoritesIcon'
import { useFavoritesContext } from '../FavoritesProvider'
import { Styled } from './FavoriteCategory.styles'
import { IFavoriteCategoryProps } from './FavoriteCategory.types'

export const FavoriteCategory = ({ type }: IFavoriteCategoryProps) => {
  const { i18n } = useDictionary()
  const { count, favorites } = useFavoritesContext()
  const theme = useTheme()

  return (
    <Styled.Root
      to={
        type === FavoritesTypes.Rundates
          ? routes[Route.calendar]
          : prepareRoute({ route: Route.favorites, params: { type } })
      }
      aria-disabled={count[type] < 1}
    >
      <Styled.Avatar>
        <FavoritesIcon size={96} stroke={theme.palette.text.primary} type={type} />
      </Styled.Avatar>
      <Flex direction={'row'} wrap={'nowrap'} align={'center'}>
        <Badge
          badgeContent={count[type]}
          color={'primary'}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          children={<Typography variant={'body2'} children={i18n.favorites.labels[type]} />}
        />
      </Flex>
    </Styled.Root>
  )
}
