import React from 'react'

import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { Breadcrumbs } from 'components/Breadcrumbs'
import Loading from 'components/Bricks/components/Loading'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { MyPassDetails } from 'components/passManagement/MyPassDetails'
import { IPassDetails } from 'components/passManagement/MyPassDetails/MyPassDetails.types'
import { BasicModal } from 'components/reusable/Modal'
import { URI } from 'services/URI'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const MyPassDetailsPage = () => {
  const { identity, email } = useParams<RouteParams<Route.myPassDetails>>()
  const { data, isLoading } = useSWR<IPassDetails>(
    !!identity && !!email && URI.getPass(identity, email)
  )
  const { i18n } = useDictionary()
  const navigate = useNavigate()

  const handleBackToForm = () => navigate(routes[Route.myPass])

  return (
    <PageWrapper>
      <Breadcrumbs base={[{ link: routes[Route.myPass], label: i18n.breadcrumbs.yourPass }]} />
      {isLoading ? (
        <Loading />
      ) : !data?.identity ? (
        <BasicModal
          title={i18n.passManagement.passError.title}
          content={i18n.passManagement.passError.content}
          button={
            <Button variant={'contained'} onClick={handleBackToForm}>
              {i18n.passManagement.passError.button}
            </Button>
          }
        />
      ) : (
        <MyPassDetails details={data} />
      )}
    </PageWrapper>
  )
}
