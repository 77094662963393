import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import TextBlockBrick from './TextBlock.component'
import { ITextBlockFromState, ITextBlockOwnProps } from './TextBlock.types'

const mapStateToProps = (state: _Store.IState): ITextBlockFromState => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  }
}

export default connect<ITextBlockFromState, null, ITextBlockOwnProps, _Store.IState>(
  mapStateToProps
)(TextBlockBrick)
