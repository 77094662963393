import { selector } from 'recoil'

import { Languages } from 'constants/Languages'
import { RecoilKeys } from 'constants/RecoilKeys'

import { locale } from '../atoms/locale'

/**
 * Retrieves language setting state. Language code (code, e.g. 'en')
 * and language name (language, e.g. English).
 *
 * @type {RecoilValueReadOnly<{code: Languages, language: string}>}
 */
export const getLang = selector({
  get: ({ get }) => {
    const lang = get(locale)
    const index = Object.values(Languages).indexOf(lang)

    return {
      code: lang,
      language: Object.keys(Languages)[index],
    }
  },
  key: RecoilKeys.getLang,
})
