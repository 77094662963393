import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import Buttons from 'components/buying/Pools/components/Buttons/Buttons.component'
import {
  IButtonsFromDispatch,
  IButtonsFromState,
} from 'components/buying/Pools/components/Buttons/Buttons.types'
import { eventAddToCart, eventRemoveFromCart } from 'models/analytics/actions'
import { get as getBasketItems } from 'models/basket/selector'
import { selectTicket } from 'models/pools/actions'
import { getSelectedTickets } from 'models/pools/selectors'

const mapStateToProps = (state: _Store.IState): IButtonsFromState => ({
  basket: getBasketItems(state),
  selectedTickets: getSelectedTickets(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IButtonsFromDispatch => ({
  selectTicket: (
    poolId,
    amount,
    poolName,
    currency,
    price,
    serviceFee,
    additionalFields,
    forms,
    submissions,
    paymentMethods
  ) =>
    dispatch(
      selectTicket({
        additionalFields,
        amount,
        currency,
        forms,
        poolId,
        poolName,
        price,
        serviceFee,
        paymentMethods,
      })
    ),
  eventAddToCart: (params) => dispatch(eventAddToCart(params)),
  eventRemoveFromCart: (params) => dispatch(eventRemoveFromCart(params)),
})

export default connect<IButtonsFromState, IButtonsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Buttons)
