import { getType } from 'typesafe-actions'

import { getImages } from './../actions'
import { IAction, IImagesReducer } from './../types'

const initialState: IImagesReducer = {}

const reducer = (state: IImagesReducer = initialState, action: IAction): IImagesReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getImages.success):
      const { images, key } = action.payload

      return {
        ...state,
        [key]: images,
      }

    default:
      return state
  }
}

export default reducer
