import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'

import ImageFixed from '../ImageFixed'
import { Styled } from './SwipeableGallery.styles'
import { ISwipeableGalleryProps } from './SwipeableGallery.types'

export const SwipeableGallery = ({ images }: ISwipeableGalleryProps) => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = images.length

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <ImageFixed
            key={`swipeable-gallery-${index}`}
            src={step}
            transformation={{ width: 810, height: 700 }}
          />
        ))}
      </SwipeableViews>
      <Styled.Dots
        steps={maxSteps}
        position={'static'}
        activeStep={activeStep}
        backButton={<></>}
        nextButton={<></>}
      />
    </Box>
  )
}
