import * as React from 'react'

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { failSummaryMounted, unmount } from 'models/transaction/actions'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import classes from './Fail.classes'
import useStyles from './Fail.styles'

export const PaymentFail = () => {
  const { i18n } = useDictionary()
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const { isMobile } = useBreakpoints()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(failSummaryMounted())

    return () => {
      dispatch(unmount())
    }
  }, [])

  return (
    <div className={cn(muiStyles.wrapper, classes.wrapper)}>
      <Grid container={true}>
        <Grid item={true} xs={12} className={cn(muiStyles.iconWrapper, classes.errorIcon)}>
          <Grid item={true} className={muiStyles.iconContainer}>
            <HighlightOffOutlinedIcon fontSize="large" />
          </Grid>
        </Grid>

        <Grid item={true} xs={12} className={cn(muiStyles.headerWrapper, classes.header)}>
          <Typography variant={isMobile ? 'h3' : 'h2'} color="textPrimary" align="center">
            {i18n.payment.paymentFail.header}
          </Typography>
        </Grid>

        <Grid item={true} xs={12} className={cn(muiStyles.textWrapper, classes.text)}>
          <Typography variant={isMobile ? 'body1' : 'h5'} color="textPrimary">
            {i18n.payment.paymentFail.contactWithUs}
          </Typography>
        </Grid>

        <Grid item={true} xs={12} className={classes.reportButton}>
          <Button
            component={Link}
            variant="outlined"
            size="large"
            color="primary"
            fullWidth={true}
            to={routes[Route.contact]}
          >
            <Typography>{i18n.payment.paymentFail.report}</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default PaymentFail
