import React, { useContext } from 'react'

import {
  CardGiftcardOutlined,
  ConfirmationNumberOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material'
import { Badge, Box, IconButton } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import { useMaintenance } from 'components/_functional/MaintenanceModeProvider'
import MobileMenu from 'components/_layout/Navbar/components/MobileMenu'
import { AccountLinkButton } from 'components/auth/AccountLinkButton'
import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './IconBar.styles'

const COLOR = 'inherit'

const IconBar = () => {
  const { i18n } = useDictionary()
  const classes = useStyles()
  const { isMaintenance } = useMaintenance()
  const basketState = useContext(clearContext)
  const itemsLength = basketState?.basketItems.length
  const { isDesktop } = useBreakpoints()

  return (
    <Box className={classes.root}>
      <IconButton component={NavLink} to={routes[Route.search]} color={COLOR}>
        <SearchOutlined />
      </IconButton>
      {config.app.onlineSale && (
        <>
          <IconButton component={NavLink} to={routes[Route.ticketManagement]} color={COLOR}>
            <ConfirmationNumberOutlined />
          </IconButton>
          {config.features.userLogin && !isMaintenance && <AccountLinkButton />}
          {!!basketState?.basketItems.length && (
            <IconButton component={NavLink} to={routes[Route.cart]} color={COLOR}>
              <Badge badgeContent={itemsLength} color={'primary'}>
                <ShoppingCartOutlined />
              </Badge>
            </IconButton>
          )}
          {!isDesktop && <MobileMenu />}
        </>
      )}
      {!config.app.onlineSale && (
        <IconButton component={NavLink} to={routes[Route.giftCardActivation]} color={COLOR}>
          <CardGiftcardOutlined />
        </IconButton>
      )}
    </Box>
  )
}

export default IconBar
