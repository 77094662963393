import React from 'react'

import Faq from 'components/reusable/Faq'
import invertIfGrayScaleColor from 'misc/helpers/invertIfGrayScaleColor'
import isColorDark from 'misc/helpers/isColorDark'
import { useAppState } from 'state/app/hooks/useAppState'

import styles from './FaqBrick.module.scss'
import { IFaqBrickProps } from './FaqBrick.types'

const FaqBrick = ({ faq, id, color, background }: IFaqBrickProps) => {
  const { isDarkTheme } = useAppState()
  const isBgDark =
    isColorDark(background) ||
    isColorDark(isDarkTheme && background ? invertIfGrayScaleColor(background) : background)

  return (
    <div className={styles.faq} id={id}>
      <Faq isBgDark={isBgDark} color={color} faq={faq} />
    </div>
  )
}

export default FaqBrick
