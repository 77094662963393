import { connect } from 'react-redux'

import _Store from '@Store'

import { getCompositionIdentifier } from 'models/pages/selectors'
import * as CONST from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import OneBigAndVerticalList from './OneBigAndVerticalList.component'
import {
  IOneBigAndVerticalListFromState,
  IOneBigAndVerticalListOwnProps,
} from './OneBigAndVerticalList.types'

const mapStateToProps = (state: _Store.IState): IOneBigAndVerticalListFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
  compositionIdentifier: getCompositionIdentifier(state),
})

export default connect<
  IOneBigAndVerticalListFromState,
  null,
  IOneBigAndVerticalListOwnProps,
  _Store.IState
>(mapStateToProps)(OneBigAndVerticalList)
