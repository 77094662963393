import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import cn from 'classnames'

import styles from 'components/buying/Pools/Pools.module.scss'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import productsStyles from './Products.module.scss'
import useStyles from './Products.styles'
import { IProductsProps } from './Products.types'
import Product from './components/Product'

const Products = ({
  isProductPay,
  isTransactionLoading,
  number,
  products,
  productsMounted,
  isDisabled,
  userData,
  isInvalid,
}: IProductsProps) => {
  const muiStyles = useStyles()
  const { i18n } = useDictionary()
  const shouldShowProducts = !!products.length

  useEffect(() => {
    if (isProductPay) {
      productsMounted()
    }
  }, [])

  return (
    <div
      className={cn(
        styles.pools,
        isTransactionLoading && styles.disabled,
        isDisabled && muiStyles.disabled,
        !isProductPay && muiStyles.container,
        productsStyles.title
      )}
    >
      <Typography className={muiStyles.header} variant={'h3'} data-number={number}>
        {i18n.buy.products.title}
      </Typography>

      {shouldShowProducts && (
        <ul>
          {products.map((product) => (
            <li key={product.id}>
              <Product product={product} userData={userData} isInvalid={isInvalid} />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Products
