import { pathToRegexp } from 'path-to-regexp'
import { Route, routes } from 'router/routes'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import { catchError as catchError$ } from 'rxjs/internal/operators/catchError'
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { convertRelToAbs } from 'misc/helpers/convertRelToAbs'
import evalPageScripts from 'misc/helpers/evalPageScripts'
import { locationChange } from 'models/internalRouter/actions'
import { IRedirectionResponse } from 'services/$redirection-api/types'
import { isActionOf } from 'typesafe-actions'

import { getRedirection, manageRedirect } from './../actions'
import { getLocation } from './../selectors'

export const checkRouteExists: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const routesRX = pathToRegexp(Object.values(routes))
      const pathname = getLocation(state).pathname

      if (!routesRX.exec(pathname)) {
        return of$(getRedirection.request(pathname))
      }

      return EMPTY$
    })
  )
}

export const checkRedirectExists: _Store.IEpic = (action$, state$, { redirectionApi }) => {
  return action$.pipe(
    filter$(isActionOf(getRedirection.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(redirectionApi.check(action.payload)).pipe(
        mergeMap$((data: IRedirectionResponse) => {
          if (data.status === 'found') {
            return [
              getRedirection.success({ redirectUrl: data.data.address }),
              manageRedirect(data.data),
            ]
          }

          return EMPTY$
        }),
        catchError$((error: Error) => of$(getRedirection.failure(error)))
      )
    })
  )
}

export const redirectIfExists: _Store.IEpic = (action$, state$, { htmlApi }) => {
  return action$.pipe(
    filter$(isActionOf(manageRedirect)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const path = action.payload.address
      const html = document.querySelector('html')

      if (action.payload.type === 'redirect') {
        window.location.replace(path)

        return EMPTY$
      }

      htmlApi
        .getHtml(path)
        .then((response) => {
          const htmlDocument = response.documentElement
          const canonicalLink = htmlDocument.querySelector("link[rel='canonical']")
          const ogUrl = htmlDocument.querySelector("meta[property='og:url']")
          const locationUrl = location.protocol + '//' + location.host + location.pathname

          if (canonicalLink) {
            canonicalLink.setAttribute('href', locationUrl)
          }

          if (ogUrl) {
            ogUrl.setAttribute('content', locationUrl)
          }

          return htmlDocument.outerHTML
        })
        .then((htmlString) => {
          return convertRelToAbs(path.replace('index.html', ''), htmlString)
        })
        .then((parsedHtml) => {
          if (html) {
            html.innerHTML = parsedHtml
          }
        })
        .then(() => {
          evalPageScripts()
        })
        .catch((error) => {
          window.location.replace(routes[Route.index])
        })

      return EMPTY$
    })
  )
}
