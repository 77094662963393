import React from 'react'

import styles from './ReadMoreLoading.module.scss'

const ReadMoreLoading = () => (
  <div className={styles.fakeLinkContainer}>
    <div className={styles.fakeLink} />
  </div>
)

export default ReadMoreLoading
