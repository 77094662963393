import { createSelector } from 'reselect'

import _Store from '@Store'

import { IEntryListReducer, ISubmissionsToAttachProps } from './../types'
import get from './get'

const getSubmissions = createSelector<
  _Store.IState,
  IEntryListReducer,
  ISubmissionsToAttachProps[]
>([get], (state) => state.submissions)

export default getSubmissions
