import React, { useEffect } from 'react'

import { Box, Step, Stepper } from '@mui/material'

import useStyles from './EntryList.styles'
import { IEntryListProps } from './EntryList.types'
import { steps } from './steps'

const EntryListStepper = ({ activeStep, clearEmbedInitTime }: IEntryListProps) => {
  const classes = useStyles()

  useEffect(() => {
    clearEmbedInitTime()
  }, [])

  return (
    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Stepper className={classes.root}>
        {steps.map((step) => {
          return activeStep === step.name && <Step key={step.name}>{step.component()}</Step>
        })}
      </Stepper>
    </Box>
  )
}
export default EntryListStepper
