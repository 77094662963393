import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import { IAction } from 'models/transaction/types'
import { getType } from 'typesafe-actions'

import { buyAndPayOnline } from './../../actions'

const reducer = (
  state: IBuyingOnlineFormValues | null = null,
  action: IAction
): IBuyingOnlineFormValues | null => {
  switch (action.type) {
    // _POST_SUCCESS
    case getType(buyAndPayOnline):
      return action.payload.data

    default:
      return state
  }
}

export default reducer
