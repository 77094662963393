import { getType } from 'typesafe-actions'

import {
  authorizePage,
  getSections,
  openPageAuth,
  resetAuthError,
  setAuthError,
} from './../actions'
import { IAction as IActionSections, IPagesReducer } from './../types'

export const initialState: IPagesReducer = {
  auth: {
    email: null,
    entryToken: null,
    error: false,
    originalPayload: null,
    showGate: false,
  },
  compositions: {},
}

const reducer = (state: IPagesReducer = initialState, action: IActionSections): IPagesReducer => {
  switch (action.type) {
    case getType(getSections.success):
      return {
        ...state,
        auth: {
          ...state.auth,
          showGate: false,
        },
        compositions: {
          ...state.compositions,
          [action.payload.site]: action.payload.pages,
        },
      }

    case getType(openPageAuth):
      return {
        ...state,
        auth: {
          ...state.auth,
          originalPayload: action.payload,
          showGate: true,
        },
      }

    case getType(authorizePage):
      return {
        ...state,
        auth: {
          ...state.auth,
          email: action.payload.email,
          entryToken: action.payload.entryToken,
          showGate: false,
        },
      }

    case getType(setAuthError):
      return {
        ...state,
        auth: {
          ...state.auth,
          error: true,
        },
      }

    case getType(resetAuthError):
      return {
        ...state,
        auth: {
          ...state.auth,
          error: false,
        },
      }

    default:
      return state
  }
}

export default reducer
