import { connect } from 'react-redux'

import _Store from '@Store'

import HorizontalList from 'components/Bricks/components/HorizontalList/HorizontalList.component'
import { IHorizontalListFromState } from 'components/Bricks/components/HorizontalList/HorizontalList.types'
import { getCompositionIdentifier } from 'models/pages/selectors'

export default connect(
  (state: _Store.IState): IHorizontalListFromState => ({
    compositionIdentifier: getCompositionIdentifier(state),
  })
)(HorizontalList)
