import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { getActiveStep } from 'models/entryList/selectors'
import { clearEmbedInitTime } from 'models/internalRouter/actions'

import EntryListStepper from './EntryList.component'
import { IEntryListFromDispatch, IEntryListFromState } from './EntryList.types'

const mapStateToProps = (state: _Store.IState): IEntryListFromState => ({
  activeStep: getActiveStep(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IEntryListFromDispatch => ({
  clearEmbedInitTime: () => dispatch(clearEmbedInitTime()),
})

export default connect<IEntryListFromState, IEntryListFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(EntryListStepper)
