import * as React from 'react'

import { Paper } from '@mui/material'

import ImageFixed from 'components/reusable/ImageFixed'
import { SwipeableGallery } from 'components/reusable/SwipeableGallery'
import { Tags } from 'components/reusable/Tags'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import { Styled } from './TwoColumnsLayout.styles'
import { ITwoColumnsLayoutProps } from './TwoColumnsLayout.types'

export const TwoColumnsLayout = ({
  children,
  boxComponent,
  tags,
  pageTitle,
  image,
  onImageClick,
  isBoxTitleDisabled,
}: React.PropsWithChildren<ITwoColumnsLayoutProps>) => {
  const { isDesktop, isMobile } = useBreakpoints()

  return (
    <Styled.Root>
      {pageTitle && <Styled.Title variant={'h2'} component={'h1'} children={pageTitle} />}
      <Tags tags={tags} />
      <Styled.ColumnLayout isDesktop={isDesktop}>
        <Styled.LeftColumn isDesktop={isDesktop}>
          <Styled.ImageBox>
            {Array.isArray(image) ? (
              <SwipeableGallery images={image} />
            ) : (
              <ImageFixed
                alt={pageTitle}
                src={image}
                transformation={{ width: 810, height: 700 }}
                onClick={onImageClick}
              />
            )}
            {!isDesktop && !!boxComponent && (
              <Paper sx={{ p: isMobile ? 3 : 4 }} children={boxComponent} />
            )}
          </Styled.ImageBox>
          {children}
        </Styled.LeftColumn>
        {isDesktop && (
          <Styled.FloatingBox isDesktop={isDesktop}>
            {!isBoxTitleDisabled && <Styled.Title variant={'h3'} mb={2} children={pageTitle} />}
            {boxComponent}
          </Styled.FloatingBox>
        )}
      </Styled.ColumnLayout>
    </Styled.Root>
  )
}
