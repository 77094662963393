import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blackFontColor: {
      color: '#111',
    },
  })
)

export default useStyles
