import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import cn from 'classnames'

import { IFormioRedirectProps } from 'components/Formio/Formio.types'
import config from 'config'
import { IPoolSelectedTicket } from 'models/pools/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import useStyles from './Forms.styles'
import { IFormioSubmitResponse, IFormsProps, IFormsRef } from './Forms.types'

let formsCounter = 0
let submitCounter = 0

const Forms = forwardRef<IFormsRef, IFormsProps>((props, ref) => {
  const { selectedTickets, number, selectTicket, firstName, lastName, handleSubmit } = props
  const { i18n } = useDictionary()
  const muiStyles = useStyles()
  const formRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const [count, setCount] = useState<number[]>([])
  const [formio, setFormio] = useState<IFormioRedirectProps[]>([])

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      formio.forEach((element) => {
        element.emit('submitButton')
      })
    },
  }))

  useEffect(() => {
    formsCounter = 0
    selectedTickets.forEach((ticket) => {
      if (ticket.forms?.length) {
        ticket.forms.forEach((el) => {
          formsCounter++
        })
      }
    })

    setCount(selectedTickets.map((ticket) => ticket.amount))
  }, [selectedTickets])

  const onSubmitDone = (ticket: IPoolSelectedTicket, data: IFormioSubmitResponse) => {
    if (ticket) {
      selectTicket(
        ticket.poolId,
        ticket.amount,
        ticket.poolName,
        ticket.currency,
        ticket.paymentMethods,
        ticket.price,
        ticket.serviceFee,
        ticket.additionalFields,
        ticket.forms,
        [{ formId: data.form, submissionId: data._id }]
      )
      if (formsCounter === submitCounter) {
        handleSubmit()
      }
    }
  }

  useEffect(() => {
    if (formRefs.current) {
      setFormio([])
      selectedTickets.forEach((ticket, ticketIndex) =>
        ticket.forms?.forEach((form) => {
          form.parameters
            .filter((param) => param.type === 'url')
            .forEach((param, formIndex) => {
              try {
                window.Formio.createForm(
                  formRefs.current?.[`${form.formId}-${ticketIndex}-${formIndex}`],
                  param.value,
                  {
                    i18n: {
                      pl: config.formIo.langPL.default,
                      en: config.formIo.langEN.goingSupport, // tslint:disable-line:object-literal-sort-keys
                    },
                  }
                ).then((formIo: IFormioRedirectProps) => {
                  setFormio((prevState) => [...prevState, formIo])
                  formIo.on('submitDone', (data) => {
                    submitCounter++
                    onSubmitDone(ticket, data)
                  })
                })
              } catch (noSSR) {}
            })
        })
      )
    }
  }, [formRefs, JSON.stringify(count)])

  useEffect(() => {
    formio.forEach((element) => {
      element.submission = {
        ...element.submission,
        data: { ...element.submission?.data, firstName, lastName },
      }
    })
  }, [firstName, lastName, formio])

  return (
    <div className={muiStyles.container}>
      <h3 className={muiStyles.header} data-number={number}>
        {i18n.buy.pools.additionalData}
      </h3>
      {selectedTickets.map((ticket, ticketIndex) => (
        <fieldset key={ticket.poolId} className={cn(muiStyles.formPanel, muiStyles.root)}>
          {ticket.forms &&
            formRefs.current &&
            ticket.forms.map((form) => (
              <>
                {form.parameters
                  .filter((param) => param.type === 'url')
                  .map((param, formIndex) => (
                    <div
                      key={ticketIndex}
                      ref={(el) =>
                        (formRefs.current[`${form.formId}-${ticketIndex}-${formIndex}`] = el)
                      }
                      className={muiStyles.formContainer}
                    />
                  ))}
              </>
            ))}
        </fieldset>
      ))}
    </div>
  )
})

export default Forms
