import React from 'react'

import { Check } from '@mui/icons-material'
import { Button, Dialog, List, ListItemButton, ListItemText } from '@mui/material'

import { ILocationsDialogProps } from 'components/_functional/LocationContextProvider/LocationsDialog/LocationsDialog.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const LocationsDialog = ({
  currentLocationID,
  isVisible,
  onClose,
  locations,
  setLocation,
}: ILocationsDialogProps) => {
  const { i18n } = useDictionary()

  if (!locations) return null

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      fullWidth
      maxWidth={'xs'}
      PaperProps={{
        sx: { backgroundColor: 'primary.main' },
      }}
    >
      <List>
        {locations.map((location) => (
          <ListItemButton
            key={location.id}
            onClick={() => {
              setLocation({ slug: location.slug, name: location.name, id: location.id })
            }}
          >
            <ListItemText primary={location.name} />
            {location.id === currentLocationID && <Check />}
          </ListItemButton>
        ))}
      </List>
      <Button children={i18n.buttons.cancel} onClick={onClose} />
    </Dialog>
  )
}
