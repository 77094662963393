import isFloat from 'misc/helpers/isFloat'

const FRACTION_DIGITS = 2

const getPriceFormatted = (price: number | null) => {
  const numberPrice = parseFloat(price != null ? price.toString() : '')

  if (isNaN(numberPrice)) {
    return null
  }

  const finalPrice = numberPrice.toFixed(isFloat(numberPrice) ? FRACTION_DIGITS : 0)

  if (finalPrice.charAt(0) === '-') {
    return '0'
  }

  return finalPrice
}

export default getPriceFormatted
