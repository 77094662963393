import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { handlePrevStep, resetForm, skipToSelectedStep } from 'models/entryList/actions'
import { getSubmissionsComplete } from 'models/entryList/selectors'

import AgreementExist from './AgreementExist.component'
import { IAgreementExistFromState, IAgreementFromDispatch } from './AgreementExist.types'

const mapStateToProps = (state: _Store.IState): IAgreementExistFromState => ({
  submissionsComplete: getSubmissionsComplete(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IAgreementFromDispatch => ({
  resetForm: () => dispatch(resetForm()),
  handlePrevStep: () => dispatch(handlePrevStep()),
  skipToSelectedStep: (step) => dispatch(skipToSelectedStep(step)),
})

export default connect<IAgreementExistFromState, IAgreementFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(AgreementExist)
