import React, { FC, useRef } from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import useStyles from 'components/reusable/Card/Card.styles'
import Date from 'components/reusable/Date'
import Link from 'components/reusable/DualLink/DualLink.component'
import ImageFixed from 'components/reusable/ImageFixed'
import Place from 'components/reusable/Place'
import Title from 'components/reusable/Title'
import config from 'config'
import isColorDark from 'misc/helpers/isColorDark'
import isHTML from 'misc/helpers/isHTML'
import removeHTMLTags from 'misc/helpers/removeHTMLTags'
import slugifyString from 'misc/helpers/slugifyString'

import internalAbsoluteLinksToRoutes from '../../../misc/helpers/internalAbsoluteLinksToRoutes'
import styles from './Card.module.scss'
import { ICardExtended } from './Card.types'

const Card: FC<ICardExtended> = ({
  badge,
  color,
  children,
  ctaButton,
  secondaryCtaButton,
  date,
  description,
  fixedWidthCards,
  highlightTitle,
  hotDeals,
  isHorizontalMultiline,
  isLoading,
  isSlider,
  link,
  maxInRow = 4,
  place,
  placeSlug,
  sectionBackground,
  subtitle,
  thumb,
  title,
  verticalImage,
  compositionIdentifier,
  brickId,
  onClick,
}) => {
  const darkBadge = badge?.type && isColorDark(badge?.type)
  const isEmpik = config.theme.isEmpik
  const ref = useRef<HTMLLIElement>(null)
  const verticalImgDimensions = [550, 950]
  const squareImgDimensions = maxInRow && maxInRow > 2 ? [405, 350] : [810, 700]
  const isSectionBgDark = isColorDark(sectionBackground)
  const isCtaButtonSet = ctaButton?.label && ctaButton.label.length > 0
  const isSecondaryCtaButtonSet = secondaryCtaButton?.label && secondaryCtaButton.label.length > 0
  const isAnyCtaButtonSet = isCtaButtonSet || isSecondaryCtaButtonSet
  const GAClassName = `${compositionIdentifier}${brickId ? `-${brickId}-` : '-'}${slugifyString(
    title
  )}`

  const theme = useTheme()
  const muiStyles = useStyles(isEmpik, color, isSectionBgDark, ctaButton, secondaryCtaButton)(theme)

  return (
    <li
      className={cn(
        styles.card,
        styles[`cardWidthFor${maxInRow}`],
        (fixedWidthCards || isSlider) && styles[`fixedWidthFor${maxInRow}`],
        isSlider && styles.sliderCard,
        isHorizontalMultiline && muiStyles.horizontalMultiline
      )}
      ref={ref}
      onClick={onClick}
    >
      <div className={cn(styles.cardHeader, hotDeals && styles.hotDeals)}>
        {badge && (
          <div
            className={cn(styles.badge, darkBadge && styles.dark, styles[badge.type])}
            style={{ backgroundColor: badge.type }}
          >
            {badge.name}
          </div>
        )}
        <ImageFixed
          src={thumb}
          to={!isAnyCtaButtonSet ? internalAbsoluteLinksToRoutes(link) : undefined}
          alt={title}
          className={GAClassName}
          transformation={
            verticalImage
              ? { width: verticalImgDimensions[0], height: verticalImgDimensions[1] }
              : { width: squareImgDimensions[0], height: squareImgDimensions[1] }
          }
        />
      </div>
      <div className={cn(styles.cardBody, muiStyles.root)}>
        {title && !isAnyCtaButtonSet && (
          <Title
            title={title}
            isLoading={isLoading}
            link={!children ? link : undefined}
            customClassName={cn(
              styles.cardTitle,
              highlightTitle && styles.highlightTitle,
              highlightTitle ? muiStyles.contrastColor : muiStyles.fontColor,
              GAClassName
            )}
          />
        )}
        {ctaButton && ctaButton.label && (
          <Title
            title={ctaButton.label}
            isLoading={isLoading}
            link={!ctaButton.disabled ? ctaButton.link : undefined}
            customClassName={cn(styles.cardTitle, ctaButton && muiStyles.ctaButton, GAClassName)}
          />
        )}
        {secondaryCtaButton && secondaryCtaButton.label && (
          <Title
            title={secondaryCtaButton.label}
            isLoading={isLoading}
            link={!secondaryCtaButton.disabled ? secondaryCtaButton.link : undefined}
            customClassName={cn(
              styles.cardTitle,
              ctaButton && muiStyles.secondaryCtaButton,
              GAClassName
            )}
          />
        )}
        {!verticalImage && (subtitle || date || place || description) && (
          <div className={cn(styles.cardData, muiStyles.secondary)}>
            {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
            {date && (
              <Date
                date={date}
                isLoading={isLoading}
                customClassName={cn(styles.date, muiStyles.fontColor)}
              />
            )}
            {place && (
              <Place
                placeSlug={placeSlug}
                place={place}
                customClassName={cn(styles.place, muiStyles.placeFontColor, GAClassName)}
                isLoading={isLoading}
              />
            )}
            {description &&
              (isHTML(description) ? (
                <p className={styles.description}>{removeHTMLTags(description)}</p>
              ) : (
                <p className={styles.description}>{description}</p>
              ))}
          </div>
        )}
      </div>
      {children && (
        <div className={cn(styles.cardFooter, muiStyles.root)}>
          {link && (
            <Link className={cn(muiStyles.fontColor, GAClassName)} to={link}>
              {children}
            </Link>
          )}
        </div>
      )}
    </li>
  )
}

export default Card
