import React from 'react'

import cn from 'classnames'
import { Form, Formik, FormikValues } from 'formik'
import { useNavigate } from 'react-router-dom'

import FormField from 'components/formik/FormField'
import Portal from 'components/modals/Portal'
import config from 'config'
import cancelClick from 'misc/helpers/cancelClick'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'

import styles from './AuthGate.module.scss'
import { IAuthGateProps } from './AuthGate.types'

const appClass = config.theme.isEmpik ? styles.empik : styles.going

const MESSAGE_TEXT = 'Wpisz dane biletu, aby wyświetlić zawartość.'
const CLOSE_TEXT = 'Zamknij'
const SUBMIT_TEXT = 'Dalej'
const CANCEL_TEXT = 'Anuluj'
const ERROR_MESSAGE_TEXT = 'dane są nieprawidłowe lub nie uprawniają do wyświetlenia treści'
const EMAIL_PLACEHOLDER_TEXT = 'e-mail'
const TOKEN_PLACEHOLDER_TEXT = 'kod biletu'

const AuthGate = ({ action, error, payload, show, client }: IAuthGateProps) => {
  const { isDesktop } = useBreakpoints()
  const navigate = useNavigate()

  const cancel = () => {
    navigate(-1)
  }

  const initialValues = {
    email: '',
    entryToken: '',
  }

  const submitAction = (formValues: FormikValues) => {
    const submitPayload = {
      ...payload,
      ...formValues,
    }

    action(submitPayload)
  }

  return show ? (
    <Portal>
      <div
        className={cn(styles.authGate, isDesktop && styles.desktop, {
          [client]: client,
        })}
        onClick={cancel}
        role="button"
      >
        {isDesktop && (
          <button className={styles.close} onClick={cancel}>
            {CLOSE_TEXT}
          </button>
        )}
        <div className={cn(styles.panel, appClass)} onClick={cancelClick}>
          <h2 className={styles.message}>{MESSAGE_TEXT}</h2>
          <Formik initialValues={initialValues} onSubmit={(values) => submitAction(values)}>
            <Form>
              <label htmlFor={'email'}>
                <FormField
                  id="email"
                  name="email"
                  placeholder={EMAIL_PLACEHOLDER_TEXT}
                  type="email"
                />
              </label>
              <label htmlFor={'entryToken'}>
                <FormField
                  id="entryToken"
                  name="entryToken"
                  placeholder={TOKEN_PLACEHOLDER_TEXT}
                  type="text"
                />
              </label>
              {error && <p className={styles.error}>{ERROR_MESSAGE_TEXT}</p>}
              <div className={styles.buttonWrapper}>
                <button className={styles.button} type={'submit'}>
                  {SUBMIT_TEXT}
                </button>
                <button
                  className={cn(styles.button, styles.cancel)}
                  onClick={cancel}
                  type={'button'}
                >
                  {CANCEL_TEXT}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </Portal>
  ) : null
}

export default AuthGate
