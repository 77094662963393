export const INFORM_ABOUT_ADD_TO_CART = 'Empik/analytics/INFORM_ABOUT_ADD_TO_CART'

export const INFORM_ABOUT_ADD_TO_CART_HAPPENING =
  'Empik/analytics/INFORM_ABOUT_ADD_TO_CART_HAPPENING'

export const INFORM_ABOUT_ADD_PAYMENT_INFO = 'Empik/analytics/INFORM_ABOUT_ADD_PAYMENT_INFO'

export const INFORM_ABOUT_AUTO_FILL_DATA = 'Empik/analytics/INFORM_ABOUT_AUTO_FILL_DATA'

export const INFORM_ABOUT_BANNER_CLICK = 'Empik/analytics/INFORM_ABOUT_BANNER_CLICK'

export const INFORM_ABOUT_BUYING_PROCESS = 'Empik/analytics/INFORM_ABOUT_BUYING_PROCESS'

export const INFORM_ABOUT_DEEPLINK_WEB = 'Empik/analytics/INFORM_ABOUT_DEEPLINK_WEB'

export const INFORM_ABOUT_EVENT_LIST_BUY_BUTTON =
  'Empik/analytics/INFORM_ABOUT_EVENT_LIST_BUY_BUTTON'

export const INFORM_ABOUT_EVENT_BUY_BUTTON = 'Empik/analytics/INFORM_ABOUT_EVENT_BUY_BUTTON'

export const INFORM_ABOUT_PROCEED = 'Empik/analytics/INFORM_ABOUT_PROCEED'

export const INFORM_ABOUT_CHECKOUT = 'Empik/analytics/INFORM_ABOUT_CHECKOUT'

export const INFORM_ABOUT_REMOVE_FROM_CART = 'Empik/analytics/INFORM_ABOUT_REMOVE_FROM_CART'

export const INFORM_ABOUT_REMOVE_FROM_CART_HAPPENING =
  'Empik/analytics/INFORM_ABOUT_REMOVE_FROM_CART_HAPPENING'

export const INFORM_ABOUT_VALIDATE = 'Empik/analytics/INFORM_ABOUT_VALIDATE'

export const INFORM_ABOUT_NEWSLETTER_SUBSCRIPTION =
  'Empik/analytics/INFORM_ABOUT_NEWSLETTER_SUBSCRIPTION'

export const FIRST_VALIDATION = 'Empik/analytics/FIRST_VALIDATION'
