import React from 'react'

import { Box, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import ImageFixed from 'components/reusable/ImageFixed'
import { IPlaceCardProps } from 'components/reusable/PlaceCard/PlaceCard.types'
import { Tags } from 'components/reusable/Tags'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { Styled } from './PlaceCard.styles'

export const PlaceCard = ({ data }: IPlaceCardProps) => {
  const { i18n } = useDictionary()
  const { isMobile } = useBreakpoints()

  if (!data) return null

  const { name, description, category, thumb, address, slug } = data

  const route = prepareRoute({ route: Route.place, params: { slug } })

  return (
    <Styled.Root isMobile={isMobile}>
      <Styled.ImageContainer isMobile={isMobile}>
        <ImageFixed src={thumb} alt={name} transformation={{ width: 810, height: 700 }} />
      </Styled.ImageContainer>
      <Styled.Body isMobile={isMobile}>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          {category && isMobile && <Tags tags={[category]} />}
          <Typography
            color={'text.primary'}
            component={RouterLink}
            variant={'h4'}
            to={route}
            children={name}
          />
          <Typography variant={'body1'} children={address} />
          {!!description && (
            <Styled.StyledDescription
              variant={'body2'}
              color={'text.secondary'}
              children={description}
            />
          )}
        </Box>
        <Link component={RouterLink} to={route} children={i18n.event.placeLink} />
      </Styled.Body>
    </Styled.Root>
  )
}
