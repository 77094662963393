import React, { useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Link, Menu, MenuItem, Tooltip, useTheme } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { mui } from 'theme'

import { useStyles } from 'components/_layout/Navbar/components/MobileMenu/MobileMenu.styles'
import config from 'config'
import { DataLayerEvents } from 'constants/Analytics'
import Analytics from 'services/$analytics'
import menu from 'services/$menu-api'
import { useDictionary } from 'state/locale/hooks/useDictionary'

const menuSelector = config.theme.isEmpik ? menu.menuEmpik : menu.menuGoing

const MobileMenu = () => {
  const { i18n } = useDictionary()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const theme = useTheme()
  const styles = useStyles(theme)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleB2BAnalytics = (buttonLabel: string) => () => {
    Analytics.pushForB2BPageAnalytics({
      eventInfo: {
        detail: 'navigate to b2b landing page',
        text_displayed: buttonLabel,
      },
      event: DataLayerEvents.Interaction,
    })
  }

  return (
    <>
      <IconButton
        edge={'end'}
        color={'inherit'}
        aria-label={mui().mobileMenuId}
        aria-controls={mui().mobileMenuId}
        aria-haspopup={'true'}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id={mui().mobileMenuId}
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuSelector.map((item) => (
          <MenuItem key={item.title}>
            {item.link && item.link.startsWith('https://') ? (
              <Link
                href={item.link}
                color={'inherit'}
                underline={'none'}
                target={item.isSalesButton ? '_self' : '_blank'}
                style={item.isSalesButton ? styles.salesButton : undefined}
              >
                {item.title}
              </Link>
            ) : item.link ? (
              <Link
                component={NavLink}
                to={item.link}
                color={'inherit'}
                underline={'none'}
                style={item.isSalesButton ? styles.salesButton : undefined}
                onClick={item.isSalesButton ? handleB2BAnalytics(item.title) : undefined}
              >
                {item.title}
              </Link>
            ) : (
              <Tooltip title={i18n.menu.comingSoon}>
                <Link color={'inherit'} underline={'none'}>
                  {item.title}
                </Link>
              </Tooltip>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MobileMenu
