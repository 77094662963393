import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { generateIdempotencyKey, setSpace } from 'models/happening/actions'
import { getData, getSelected, getSlotsSummary, getSpaces } from 'models/happening/selectors'
import { getTheme } from 'models/theme/selectors'

import Space from './Space.component'
import { ISpaceFromDispatch, ISpaceFromState } from './Space.types'

const mapStateToProps = (state: _Store.IState): ISpaceFromState => {
  return {
    isPriceCalculatedPerPerson: getData(state)?.calculatePricePerPerson,
    selectedDay: getSelected(state).day,
    selectedPriceTypes: getSelected(state).selections,
    selectedSlot: getSelected(state).slot,
    selectedSpace: getSelected(state).space,
    selectedTheme: getTheme(state),
    spacesLen: getSpaces(state).length,
    summedPlayersNum: getSlotsSummary(state)?.numberOfPlayers,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ISpaceFromDispatch => {
  return {
    generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
    selectSpace: (space: number) => dispatch(setSpace(space)),
  }
}

export default connect<ISpaceFromState, ISpaceFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Space)
