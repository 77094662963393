import React, { useEffect, useRef, useState } from 'react'

import { Styled } from './Slider.styles'
import { ISliderProps } from './Slider.types'

export const Slider = <T extends object>({
  children,
  items,
  disableScrollbars,
  edgeOffset,
  useFixedWidth,
}: ISliderProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const itemWidth = (width / 3) * 2
  const widthProps = {
    minWidth: itemWidth,
    maxWidth: itemWidth,
    width: itemWidth,
  }

  useEffect(() => {
    if (ref.current?.offsetWidth) setWidth(ref.current.offsetWidth)
  }, [ref.current?.offsetWidth])

  return (
    <Styled.Root
      component={'ul'}
      disableScrollbars={disableScrollbars}
      edgeOffset={edgeOffset}
      ref={ref}
    >
      {items.map((item, index) => (
        <Styled.Item
          {...(useFixedWidth ? widthProps : {})}
          component={'li'}
          key={'id' in item ? String(item.id) : String(index)}
        >
          {children({ item, index })}
        </Styled.Item>
      ))}
    </Styled.Root>
  )
}
