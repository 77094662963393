const classes = {
  active: 'pool-active',
  column: 'pool-column',
  columnLeft: 'pool-column-left',
  disabled: 'pool-disabled',
  pool: 'pool',
  price: 'pool-price',
  priceValue: 'pool-price-value',
  ticketIcon: 'pool-ticket-icon',
  ticketInfo: 'pool-ticket-info',
  title: 'pool-title',
}

export default classes
