import React from 'react'

import Basket from 'components/Basket'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/StaticPage'

const BASKET_TEXT = 'Koszyk'

export const BasketPage = () => {
  return (
    <PageWrapper>
      <Helmet pageName={BASKET_TEXT} />
      <Basket />
    </PageWrapper>
  )
}
