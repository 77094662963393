import * as React from 'react'

import qs from 'qs'
import { useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { Artist } from 'components/Artist'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { useMaintenance } from 'components/_functional/MaintenanceModeProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import Helmet from 'components/helmet/Helmet'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { ArtistApi } from 'services/Api/ArtistApi'
import { GoingMoreApi } from 'services/Api/GoingMoreApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ArtistPage = () => {
  const { isMaintenance } = useMaintenance()
  const { slug } = useParams<RouteParams<Route.artist>>()
  const { data, isLoading } = useSWR(
    isMaintenance !== null && !isMaintenance && !!slug && slug,
    ArtistApi.getFullArtist
  )
  const { data: relatedArticlesData, isLoading: isArticlesLoading } = useSWR(
    !!data && `artist-${data?.artistData.slug}`,
    GoingMoreApi.GetPostsByTagSlug
  )
  const { i18n } = useDictionary()

  return (
    <PageWrapper>
      {!!data?.artistData && (
        <Helmet
          pageName={[data.artistData.name, i18n.meta.concertTickets]}
          description={data.artistData.bio}
          ogDescription={data.artistData.bio}
          currentUrl={window?.location.href}
        />
      )}
      <Breadcrumbs
        base={[
          {
            link:
              routes[Route.search] +
              qs.stringify({ [HitFields.type]: SearchTypes.ARTIST }, { addQueryPrefix: true }),
            label: i18n.breadcrumbs.artists,
          },
        ]}
        current={data?.artistData.name}
      />
      {isLoading ? (
        <TwoColumnsSkeleton />
      ) : (
        !!data && (
          <Artist
            data={data.artistData}
            cloudinaryImages={data.cloudinaryImages}
            artistEvents={data.artistEvents}
            lowestPrice={data.lowestPrice}
            relatedArticlesData={relatedArticlesData}
            isArticlesLoading={isArticlesLoading}
          />
        )
      )}
    </PageWrapper>
  )
}
