import { locationChange } from 'models/internalRouter/actions'
import { getType } from 'typesafe-actions'

import {
  getInsuranceProducts,
  getProducts,
  getProductsPools,
  getSingleProduct,
  selectProducts,
  setInsuranceSummaryData,
  updateProductData,
  updateSelectedProducts,
} from './../actions'
import { IAction, IProductsReducer } from './../types'

const initialState: IProductsReducer = {
  products: [],
  selected: {},
  selectedProducts: [],
  insurance: [],
  insuranceSummaryData: { isSelected: false },
  productUserData: {},
}

const reducer = (state: IProductsReducer = initialState, action: IAction): IProductsReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getProducts.success):
      return {
        ...initialState,
        products: action.payload,
      }
    case getType(getProductsPools.success):
      return {
        ...initialState,
        products: action.payload,
        insurance: [...state.insurance],
      }

    case getType(getSingleProduct.success):
      return {
        ...initialState,
        products: [action.payload],
      }

    case getType(getInsuranceProducts.request):
      return {
        ...state,
        insurance: initialState.insurance,
      }

    case getType(getInsuranceProducts.failure):
      return {
        ...state,
        insurance: initialState.insurance,
      }

    case getType(getInsuranceProducts.success):
      return {
        ...state,
        insurance: [...state.insurance, action.payload],
      }

    case getType(selectProducts):
      return {
        ...state,
        selected: action.payload,
      }

    case getType(updateSelectedProducts):
      return {
        ...state,
        selectedProducts: action.payload,
      }

    case getType(setInsuranceSummaryData):
      return {
        ...state,
        insuranceSummaryData: action.payload,
      }

    case getType(updateProductData):
      return {
        ...state,
        productUserData: action.payload,
      }

    // _RESET_STATE
    case getType(locationChange):
      return {
        ...initialState,
        // TODO: random bug: sometimes resets products in pin page
        products: state.products,
      }

    default:
      return state
  }
}

export default reducer
