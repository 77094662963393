import { createSelector } from 'reselect'

import _Store from '@Store'

import { getPools } from '.'
import { IPoolNormalized, IPoolsGroupedSelectedSeats, IPoolsSelectedSeatsReducer } from './../types'
import getSelectedSeats from './getSelectedSeats'

const getSeatsGroupedByCategoryKeysAndPools = createSelector<
  _Store.IState,
  IPoolsSelectedSeatsReducer,
  IPoolNormalized[],
  IPoolsGroupedSelectedSeats
>([getSelectedSeats, getPools], (selectedSeats, pools) => {
  const seatsGroupedByCategoryKeysAndPools: IPoolsGroupedSelectedSeats = {}

  selectedSeats.map((current) => {
    const { category, id, selectedTicketType } = current
    const key = category?.key

    const stringKey = key?.toString()

    if (stringKey) {
      if (selectedTicketType) {
        const poolWithCurrentTicketType = seatsGroupedByCategoryKeysAndPools[stringKey]?.find(
          (pool) => pool.poolName === selectedTicketType
        )

        if (poolWithCurrentTicketType) {
          poolWithCurrentTicketType.amount += 1
          poolWithCurrentTicketType.ids.push(id)
        } else {
          if (!seatsGroupedByCategoryKeysAndPools[stringKey]) {
            seatsGroupedByCategoryKeysAndPools[stringKey] = []
          }

          seatsGroupedByCategoryKeysAndPools[stringKey]?.push({
            amount: 1,
            ids: [id],
            poolName: selectedTicketType,
          })
        }
      } else {
        const poolId =
          pools
            .find(
              (pool) =>
                pool.seatsIoCategories?.length > 0 &&
                pool.seatsIoCategories.some((seatsCategory) => seatsCategory.categoryKey === key)
            )
            ?.id.toString() || ''

        const poolWithCurrentTicketType = seatsGroupedByCategoryKeysAndPools[stringKey]?.find(
          (pool) => pool.poolName === poolId
        )

        if (poolWithCurrentTicketType) {
          poolWithCurrentTicketType.amount += 1
          poolWithCurrentTicketType.ids.push(id)
        } else {
          if (!seatsGroupedByCategoryKeysAndPools[stringKey]) {
            seatsGroupedByCategoryKeysAndPools[stringKey] = []
          }

          seatsGroupedByCategoryKeysAndPools[stringKey]?.push({
            amount: 1,
            ids: [id],
            poolName: poolId,
          })
        }
      }
    }
  })

  return seatsGroupedByCategoryKeysAndPools
})

export default getSeatsGroupedByCategoryKeysAndPools
