import { combineReducers } from 'redux'

import cardCredentialsReducer from './cardCredentials'
import dataReducer from './data'
import paymentOperatorReducer from './paymentOperator'
import transactionDetailsReducer from './transactionDetails'
import transactionInfoReducer from './transactionInfo'
import userDataReducer from './userData'

const reducer = combineReducers({
  cardCredentials: cardCredentialsReducer,
  data: dataReducer,
  paymentOperator: paymentOperatorReducer,
  transactionDetails: transactionDetailsReducer,
  transactionInfo: transactionInfoReducer,
  userData: userDataReducer,
})

export default reducer
