import { Theme } from '@mui/material'
import { makeSxStyles } from 'theme/makeSxStyles'

export const useStyles = (theme: Theme) =>
  makeSxStyles(({ isDisabled }: { isDisabled: boolean }) => ({
    button: {
      alignSelf: 'center',
      borderColor: theme.border.color,
      borderRadius: theme.border.radius,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
      '&:hover': {
        background: theme.palette.primary.main,
        borderColor: theme.border.color,
        borderRadius: theme.border.radius,
        borderStyle: theme.border.style,
        borderWidth: theme.border.width,
      },
    },
    container: {
      background: theme.palette.background.paper,
      borderColor: theme.border.color,
      borderRadius: theme.border.radius,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
      p: 4,
      backgroundColor: 'background.paper',
      ...(isDisabled
        ? {
            opacity: 0.6,
            pointerEvents: 'none',
          }
        : {}),
    },
  }))
