import { selector } from 'recoil'

import { RecoilKeys } from 'constants/RecoilKeys'

import { searchPageIndexState } from '../atoms/searchPageIndex'
import { tagsFilterState } from '../atoms/tagsFilter'

/**
 * Tags filter setter selector. The "set" method resets search page to initial state,
 * each time new value is set.
 */
export const setTagsFilter = selector({
  get: ({ get }) => get(tagsFilterState),
  set: ({ get, set, reset }, newValue) => {
    set(tagsFilterState, newValue)
    reset(searchPageIndexState)
  },
  key: RecoilKeys.setTagsFilter,
})
