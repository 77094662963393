import React, { useEffect, useState } from 'react'

import config from 'config'
import { StorageKeys } from 'constants/StorageKeys'
import linkToFileInStorage from 'misc/helpers/linkToFileInStorage'
import _L from 'misc/helpers/local'
import { LocalStorage } from 'services/LocalStorage'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import * as LOCALS from 'vars/constants'

import styles from './CookieBar.module.scss'
import { ICookieBarProps } from './CookieBar.types'

const ACCEPTED_VALUE = 'true'

const CookieBar = ({ isBrowser, isEmbed }: ICookieBarProps) => {
  const { i18n } = useDictionary()
  const [show, setShow] = useState(false)

  useEffect(() => {
    try {
      if (
        isBrowser &&
        !isEmbed &&
        LocalStorage.get(StorageKeys.CookiesConsent) !== ACCEPTED_VALUE
      ) {
        setShow(true)
      }
    } catch (noSSR) {}
  }, [])

  const acceptCookie = () => {
    try {
      LocalStorage.set(StorageKeys.CookiesConsent, ACCEPTED_VALUE)
    } catch (noSSR) {}

    setShow(false)
  }

  return !show ? null : (
    <div className={styles.cookieBar}>
      <div className={styles.info}>
        {i18n.cookieBar.title}{' '}
        <a
          href={linkToFileInStorage(
            _L(LOCALS.STORAGE__PRIVACY_POLICY),
            true,
            config.storage.baseUrl
          )}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {i18n.cookieBar.readMore}
        </a>
      </div>
      <div className={styles.accept}>
        <button onClick={acceptCookie}>{i18n.buttons.ok}</button>
      </div>
    </div>
  )
}

export default CookieBar
