import React, { FC, PropsWithChildren, createContext, useContext } from 'react'

import { FavoritesTypes } from '../../../constants/Favorites'
import { IFavoritesProvider } from './FavoritesProvider.types'
import { useUserFavorites } from './useUserFavorites'

const initialState: IFavoritesProvider = {
  count: {
    [FavoritesTypes.Artists]: 0,
    [FavoritesTypes.Places]: 0,
    [FavoritesTypes.Selection]: 0,
    [FavoritesTypes.Tags]: 0,
    [FavoritesTypes.Rundates]: 0,
  },
  favorites: {
    [FavoritesTypes.Artists]: [],
    [FavoritesTypes.Places]: [],
    [FavoritesTypes.Selection]: [],
    [FavoritesTypes.Tags]: [],
    [FavoritesTypes.Rundates]: [],
  },
  isFirstArtistRequestDone: false,
  isFirstPlacesRequestDone: false,
  isFirstTagsRequestDone: false,
}

export const FavoritesContext = createContext(initialState)

export const useFavoritesContext = () => {
  const favoritesContext = useContext(FavoritesContext)

  const isItemInFavs = (externalId: number, type: FavoritesTypes) =>
    !!favoritesContext.favorites[type].find((fav) => fav.item.externalId === externalId)

  const getCurrentFav = (externalId: number, type: FavoritesTypes) =>
    favoritesContext.favorites[type].find((fav) => fav.item.externalId === externalId)

  return {
    ...favoritesContext,
    isItemInFavs,
    getCurrentFav,
  }
}

export const FavoritesProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const {
    favorites: artistsFavs,
    count: artistsCount,
    isFirstReqDone: isFirstArtistRequestDone,
  } = useUserFavorites(FavoritesTypes.Artists)
  const {
    favorites: placesFavs,
    count: placesCount,
    isFirstReqDone: isFirstPlacesRequestDone,
  } = useUserFavorites(FavoritesTypes.Places)
  const { favorites: selectionsFavs, count: selectionsCount } = useUserFavorites(
    FavoritesTypes.Selection
  )
  const {
    favorites: tagsFavs,
    count: tagsCount,
    isFirstReqDone: isFirstTagsRequestDone,
  } = useUserFavorites(FavoritesTypes.Tags)
  const { favorites: rundatesFavs, count: rundatesCount } = useUserFavorites(
    FavoritesTypes.Rundates
  )

  return (
    <FavoritesContext.Provider
      value={{
        count: {
          [FavoritesTypes.Artists]: artistsCount,
          [FavoritesTypes.Places]: placesCount,
          [FavoritesTypes.Selection]: selectionsCount,
          [FavoritesTypes.Tags]: tagsCount,
          [FavoritesTypes.Rundates]: rundatesCount,
        },
        favorites: {
          [FavoritesTypes.Artists]: artistsFavs,
          [FavoritesTypes.Places]: placesFavs,
          [FavoritesTypes.Selection]: selectionsFavs,
          [FavoritesTypes.Tags]: tagsFavs,
          [FavoritesTypes.Rundates]: rundatesFavs,
        },
        isFirstArtistRequestDone,
        isFirstPlacesRequestDone,
        isFirstTagsRequestDone,
      }}
      children={children}
    />
  )
}
