import * as React from 'react'

import { Button, Grid } from '@mui/material'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import useGlobalStyles from '../../global.styles'
import stepNames from '../../steps'
import Paper from '../Paper'
import { IStarterProps } from './Starter.types'

const Starter = ({ handleNextStep, skipToSelectedStep }: IStarterProps) => {
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()

  return (
    <Paper title={i18n.entryList.starter.title} helperText={i18n.entryList.starter.subtitle}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            className={globals.column}
            spacing={1}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                type="button"
                variant="text"
                color="primary"
                onClick={() => handleNextStep(stepNames.checkSubmission)}
              >
                {i18n.entryList.starter.no}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => skipToSelectedStep(stepNames.ticketCode)}
              >
                {i18n.entryList.starter.yes}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Starter
