import React from 'react'

import { ArrowForward, InfoOutlined } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { CurrencyISO, currencySymbol } from 'constants/Currency'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { DataMutations } from 'services/DataMutations'
import { DateTime } from 'services/DateTime'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { Styled } from './Rundate.styles'
import { IRundateProps } from './Rundate.types'

export const Rundate = ({ rundate, eventSlug }: IRundateProps) => {
  const { startDate, endDate, rundateDescription, slug } = rundate
  const { i18n } = useDictionary()
  const { isDesktop } = useBreakpoints()

  return (
    <Styled.Root isDesktop={isDesktop}>
      <Styled.Description>
        <Grid container={true} spacing={{ xs: 3, md: 0 }}>
          <Grid item={true} xs={12}>
            <Typography variant={isDesktop ? 'h5' : 'h6'} children={rundate.place.city.name} />
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container={true} justifyContent={'space-between'}>
              <Grid item={true}>
                <Typography
                  variant={'subtitle2'}
                  color={'text.secondary'}
                  children={
                    rundateDescription ||
                    `${DateTime.formatRundateDates({
                      startDate,
                      endDate,
                      dateTranslate: i18n.dates,
                    })}, ${DateTime.getFriendlyTime(startDate)}`
                  }
                />
                <Typography
                  variant={'subtitle2'}
                  color={'text.secondary'}
                  children={rundate.place.name}
                />
              </Grid>

              <Grid item={true}>
                {!isDesktop && (
                  <Button
                    component={Link}
                    to={prepareRoute({
                      route: Route.rundate,
                      params: { eventSlug, rundateSlug: slug },
                    })}
                    size={'medium'}
                    variant={'contained'}
                    endIcon={<ArrowForward />}
                    sx={{ height: 48, minWidth: 48, '& .MuiButton-endIcon': { marginLeft: 0 } }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Styled.Description>
      <Typography
        variant={isDesktop ? 'h5' : 'h6'}
        children={
          <>
            {DataMutations.friendlyPrice({
              price: rundate.priceWithServiceFee,
              currency: rundate.currency,
              priceDescription: rundate.priceDescriptionPL,
              prefix: i18n.price.from,
            })}
            {!!rundate.priceWithServiceFee && !!rundate.price && (
              <Styled.ToolTip
                title={i18n.event.serviceFeeCaption(
                  // TODO: this value should be in the API response
                  DataMutations.formatServiceFeePrice(
                    rundate.priceWithServiceFee - rundate.price,
                    rundate.currency
                  )
                )}
              >
                <InfoOutlined color={'secondary'} fontSize={'small'} />
              </Styled.ToolTip>
            )}
          </>
        }
      />
      {isDesktop && (
        <Button
          component={Link}
          to={prepareRoute({
            route: Route.rundate,
            params: { eventSlug, rundateSlug: slug },
          })}
          size={'large'}
          variant={'contained'}
          children={i18n.event.choose}
        />
      )}
    </Styled.Root>
  )
}
