import * as React from 'react'

import { useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import Bricks from 'components/Bricks'
import { useLocations } from 'components/_functional/LocationContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/CompositionPage'
import { ICompositionData } from 'models/pages/types'
import { URI } from 'services/URI'

export const InspirationsPage = () => {
  const { slug } = useParams<RouteParams<Route.inspirations>>()
  const { userLocation } = useLocations()
  // TODO: check the slugs used with inspirations routes
  const { data } = useSWR<ICompositionData>(URI.getInspirations(slug))

  return (
    <PageWrapper fullWidth>
      <Helmet />
      <Bricks structure={data} userLocation={userLocation} />
    </PageWrapper>
  )
}
