import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { setExtendedSlot, setExtendedSlotModalVisible } from 'models/happening/actions'
import { getExtendedSlotModalVisible, getExtendedSlots } from 'models/happening/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'

import MultipleSlotModal from './MultipleSlotModal.component'
import {
  IMultipleSlotModalFromDispatch,
  IMultipleSlotModalFromState,
} from './MultipleSlotModal.types'

const mapStateToProps = (state: _Store.IState): IMultipleSlotModalFromState => ({
  extendedSlots: getExtendedSlots(state),
  isEmbed: !!getIframeParams(state),
  modalOpen: getExtendedSlotModalVisible(state),
})
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IMultipleSlotModalFromDispatch => ({
  setFormExtendedSlot: (slot) => dispatch(setExtendedSlot(slot)),
  setModalOpen: (open) => dispatch(setExtendedSlotModalVisible(open)),
})

export default connect<
  IMultipleSlotModalFromState,
  IMultipleSlotModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(MultipleSlotModal)
