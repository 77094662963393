import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginForLastComponent: {
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(4),
    },
    containerMargin: {
      marginTop: theme.spacing(4),
    },
    wrapper: {
      maxWidth: '90rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),

      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
  })
)

export default useStyles
