import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolNormalized } from './../types'
import getPools from './getPools'

const getPoolsIds = createSelector<_Store.IState, IPoolNormalized[], number[]>(
  [getPools],
  (pools) => {
    return pools.map((pool) => pool.id)
  }
)

export default getPoolsIds
