import React from 'react'

import DoneIcon from '@mui/icons-material/Done'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'

import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import classes from '../../Success.classes'
import useStyles from './SellSuccessHeader.styles'

const SellSuccessHeader = () => {
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const { i18n } = useDictionary()

  const { isMobile } = useBreakpoints()

  return (
    <Grid container={true} direction="column">
      <Grid item={true} xs={true}>
        <Grid container={true} wrap="nowrap" className={muiStyles.headerAfterSellContainer}>
          <Grid className={cn(muiStyles.bigIconContainer, classes.doneIcon)}>
            <DoneIcon fontSize="large" />
          </Grid>

          <Grid item={true} className={cn(muiStyles.mainText, classes.header)}>
            <Typography variant={isMobile ? 'h5' : 'h2'}>
              {i18n.payment.paymentSuccess.header}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item={true}
        xs={true}
        className={cn(muiStyles.baseAlertContainer, classes.baseAlertContainer)}
      >
        <Grid container={true}>
          <Grid item={true} className={muiStyles.baseAlertIcon}>
            <TimerOutlinedIcon />
          </Grid>

          <Grid item={true} className={muiStyles.baseAlertText}>
            <Typography variant="body2">{i18n.payment.paymentSuccess.waiting}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SellSuccessHeader
