import React, { useState } from 'react'

import { Button, Grid, TextField } from '@mui/material'

import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import useGlobalStyles from '../../global.styles'
import stepNames from '../../steps'
import Paper from '../Paper'
import useStyles from './SmsCode.styles'
import { ISmsCodeProps } from './SmsCode.types'

const SmsCode = ({
  handleNextStep,
  handlePrevStep,
  smsCode,
  saveSubmission,
  generateAndSendSmsCode,
}: ISmsCodeProps) => {
  const classes = useStyles()
  const globals = useGlobalStyles()
  const { i18n } = useDictionary()
  const { code: locale } = useLang()
  const [code, setCode] = useState('')
  const [error, setError] = useState<string>()

  const validateCode = () => {
    if (smsCode !== code) {
      setError(i18n.entryList.smsCode.codeError)
    } else {
      saveSubmission()
      setError(undefined)
      handleNextStep(stepNames.greeting)
    }
  }

  const resendCode = () => {
    const generatedCode = Math.floor(1000 + Math.random() * 9000).toString()
    generateAndSendSmsCode({ code: generatedCode, lang: locale })
  }

  return (
    <Paper title={i18n.entryList.smsCode.title} helperText={i18n.entryList.smsCode.subtitle}>
      <Grid container={true} justifyContent="center" className={classes.root}>
        <Grid item={true} xs={12}>
          <TextField
            error={!!error}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            label={i18n.entryList.smsCode.code}
            variant="outlined"
            helperText={!!error && error}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button variant="text" size="medium" onClick={resendCode}>
            {i18n.entryList.smsCode.sendAgain}
          </Button>
        </Grid>
        <Grid item={true} xs={12} className={classes.buttons}>
          <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            className={globals.column}
            spacing={1}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                type="button"
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {i18n.entryList.smsCode.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                type="submit"
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                disabled={!code.length}
                onClick={validateCode}
              >
                {i18n.entryList.smsCode.goNext}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SmsCode
