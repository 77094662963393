import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { Languages } from 'constants/Languages'
import { getLocation } from 'models/connectedRouter/selectors'
import { locationChange } from 'models/internalRouter/actions'
import { getModule, getParams } from 'models/internalRouter/selectors'
import { getProductsPools } from 'models/products/actions'
import { resetSkipNextRequest } from 'models/state/actions'
import * as CONSTS from 'models/state/constants/constants'
import { getSkipNextRequest } from 'models/state/selectors'
import { IPaymentMethod, IPoolsResponse } from 'types/Pools'
import { isActionOf } from 'typesafe-actions'
import { EmptyAction } from 'typesafe-actions/dist/type-helpers'

import {
  getPools,
  poolsMounted,
  resetState,
  resetTicketsState,
  setPaymentMethods,
} from './../actions'

/*
 * When we visit buy page we want to load event only when it is necessary.
 * Every time we load pools except it is loaded by server.
 * We always should display the newest data.
 */
export const getPoolsWhenPoolsComponentMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(poolsMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const mergedActions: Array<EmptyAction<any>> = []
      const shouldSkipNextRequestForPools = getSkipNextRequest(CONSTS.POOLS)(state)
      const params = getParams(state) as RouteParams<Route.buy>

      const disableActivityPoolRequest =
        !params.rundateSlug || params.eventSlug === params.rundateSlug

      if (shouldSkipNextRequestForPools || disableActivityPoolRequest) {
        mergedActions.push(resetSkipNextRequest(CONSTS.POOLS))
      } else if (params.eventSlug && params.rundateSlug) {
        mergedActions.push(getPools.request(params))
      }

      return mergedActions
    })
  )
}

/*
 * When we left buy page we want to reset data for transaction.
 */
export const resetStateWhenBuyRouteLeft: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) => getModule(state) !== Route.buy && !getSkipNextRequest(CONSTS.POOLS)(state)
    ),
    mergeMap$(() => {
      return of$(resetState(), resetTicketsState())
    })
  )
}

/*
 * This epic loads data from API for pools.
 */
export const fetchPoolsWhenRequested: _Store.IEpic = (action$, state$, { poolsApi }) => {
  return action$.pipe(
    filter$(isActionOf(getPools.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload) {
        const language = Languages.Polski // TODO: save and get from local storage / params
        const isPreview =
          getLocation(state).query?.preview === 'true' ||
          getLocation(state).query?.preview === '1' ||
          getLocation(state).query?.preview === 'on' ||
          getLocation(state).query?.preview === 'yes'

        return from$(
          poolsApi.getPools({
            eventSlug: action.payload.eventSlug,
            isPreview,
            language,
            rundateSlug: action.payload.rundateSlug,
          })
        ).pipe(
          mergeMap$((data: IPoolsResponse) => {
            const payments = data.pools.map((pool) => pool.payments)
            const concatValues: IPaymentMethod[] = ([] as IPaymentMethod[]).concat.apply(
              [],
              payments
            )

            const uniquePayments = concatValues.reduce(
              (acc, payment) =>
                acc.concat(acc.find((array) => array.type === payment.type) ? [] : [payment]),
              [] as IPaymentMethod[]
            )

            return of$(
              getPools.success({
                poolsData: poolsApi.normalize(data),
              }),
              getProductsPools.request(),
              setPaymentMethods(uniquePayments)
            )
          }),
          takeUntil$(
            action$.pipe(
              filter$(isActionOf(locationChange)),
              tap$(() => poolsApi.cancelPools())
            )
          ),
          catchError$((error: Error) => {
            const module = getModule(state)
            if (module === Route.rundate || module === Route.pinRundate) {
              return EMPTY$
            }

            return of$(getPools.failure(error))
          })
        )
      }

      return EMPTY$
    })
  )
}
