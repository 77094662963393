import React, { FC } from 'react'

import { includes } from 'lodash'

import ExternalLink from 'components/reusable/ExternalLink'
import internalAbsoluteLinksToRoutes from 'misc/helpers/internalAbsoluteLinksToRoutes'

import { IDualLinkProps } from './DualLink.types'

const regExp = new RegExp('^https?://')
const mailExp = 'mailto:'
const anchorExp = '#'

const DualLink: FC<IDualLinkProps> = ({ to, ...rest }) => {
  const processedLink = internalAbsoluteLinksToRoutes(to)
  const isExternal = regExp.test(processedLink)
  const isMail = includes(processedLink, mailExp)
  const isAnchor = includes(processedLink, anchorExp)

  if (isExternal) {
    return (
      <ExternalLink
        to={{
          external: true,
          pathname: processedLink,
        }}
        {...rest}
        newWindow={true}
      />
    )
  }

  if (isAnchor || isMail) {
    return <a href={processedLink} {...rest} />
  }

  return <ExternalLink to={processedLink} {...rest} />
}

export default DualLink
