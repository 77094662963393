import * as React from 'react'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { RecoilRoot } from 'recoil'
import configureStore, { history } from 'store'
import preloadedState from 'store/preloadedState'

import { App } from 'components/App'
import ErrorBoundary from 'components/_functional/ErrorBoundary'
import { SWRConfigProvider } from 'components/_functional/SWRConfigProvider'

export const store = configureStore(preloadedState)

render(
  <Provider store={store}>
    <ErrorBoundary>
      <RecoilRoot>
        <SWRConfigProvider>
          <App />
        </SWRConfigProvider>
      </RecoilRoot>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
)

////////////////////////////////////////////////////////////////////////////////
//                           DEVELOPMENT MODE ONLY                            //
////////////////////////////////////////////////////////////////////////////////
if (process.env.NODE_ENV === 'development') {
  if (module.hot && localStorage) {
    const callback = () => {
      const state = store.getState()

      localStorage.setItem('@@hmr', JSON.stringify(state))

      document.location.reload()
    }

    module.hot.accept('./../components/App', callback)
    module.hot.accept('./../store/index.ts', callback)
  }
}
////////////////////////////////////////////////////////////////////////////////
