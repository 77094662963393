const prefixes = [
  { value: '+30', label: 'GR +30' },
  { value: '+31', label: 'NL +31' },
  { value: '+32', label: 'BE +32' },
  { value: '+33', label: 'FR +33' },
  { value: '+34', label: 'ES +34' },
  { value: '+36', label: 'HU +36' },
  { value: '+39', label: 'IT +39' },
  { value: '+40', label: 'RO +40' },
  { value: '+41', label: 'CH +41' },
  { value: '+43', label: 'AT +43' },
  { value: '+44', label: 'GB +44' },
  { value: '+45', label: 'DK +45' },
  { value: '+46', label: 'SE +46' },
  { value: '+47', label: 'NO +47' },
  { value: '+48', label: 'PL +48' },
  { value: '+49', label: 'DE +49' },
  { value: '+350', label: 'GI +350' },
  { value: '+351', label: 'PT +351' },
  { value: '+352', label: 'LU +352' },
  { value: '+353', label: 'IE +353' },
  { value: '+354', label: 'IS +354' },
  { value: '+355', label: 'AL +355' },
  { value: '+356', label: 'MT +356' },
  { value: '+357', label: 'CY +357' },
  { value: '+358', label: 'FI +358' },
  { value: '+359', label: 'BG +359' },
  { value: '+370', label: 'LT +370' },
  { value: '+371', label: 'LV +371' },
  { value: '+372', label: 'EE +372' },
  { value: '+373', label: 'MD +373' },
  { value: '+374', label: 'AM +374' },
  { value: '+375', label: 'BY +375' },
  { value: '+376', label: 'AD +376' },
  { value: '+377', label: 'MC +377' },
  { value: '+378', label: 'SM +378' },
  { value: '+379', label: 'VA +379' },
  { value: '+380', label: 'UA +380' },
  { value: '+381', label: 'RS +381' },
  { value: '+382', label: 'ME +382' },
  { value: '+385', label: 'HR +385' },
  { value: '+386', label: 'SI +386' },
  { value: '+387', label: 'BA +387' },
  { value: '+389', label: 'MK +389' },
  { value: '+420', label: 'CZ +420' },
  { value: '+421', label: 'SK +421' },
  { value: '+423', label: 'LI +423' },
]

export default prefixes
