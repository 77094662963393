import * as React from 'react'

import { useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { ActionBar } from 'components/ActionBar'
import { ActionBarType } from 'components/ActionBar/ActionBar.types'
import { Profile } from 'components/Profile'
import { useMaintenance } from 'components/_functional/MaintenanceModeProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { ProfileApi } from 'services/Api/ProfileApi'

export const ProfilePage = () => {
  const { isMaintenance } = useMaintenance()
  const { isDesktop } = useBreakpoints()
  const { slug, type } = useParams<RouteParams<Route.profile>>()
  const { data, isLoading, mutate } = useSWR(
    isMaintenance !== null && !isMaintenance && [type, slug],
    ProfileApi.getFullResource,
    {}
  )

  return (
    <PageWrapper>
      {isLoading ? (
        <TwoColumnsSkeleton />
      ) : (
        <>
          <Profile
            profile={data?.profile}
            place={data?.placeData}
            recommendRundates={data?.recommendRundates}
            refresh={mutate}
          />
          {!isDesktop && <ActionBar data={data?.placeData} type={ActionBarType.PLACE} />}
        </>
      )}
    </PageWrapper>
  )
}
