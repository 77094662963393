import { IPriceError } from 'goingapp-shared/dist/services/price.types'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'

import { IHappeningFormValues } from 'components/Happening/components/HappeningBuyForm/HappeningBuyForm.types'
import { IHappeningFormOnsiteValues } from 'components/Happening/components/HappeningBuyFormOnsite/HappeningBuyFormOnsite.types'
import { IHappeningPriceType } from 'components/Happening/components/PriceTypes/PriceTypes.types'
import { ICustomTerm } from 'types/EventCommon'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import {
  IActivitiesData,
  IAvailabilitiesData,
  IExtendedSlot,
  IHappeningData,
  IPriceRequestPayload,
  IPriceToCalculate,
  ISelectedUpsell,
  ISelectionPerPriceType,
  ISlotPrice,
} from './../types'

export const getHappening = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<string, IHappeningData, Error>()

export const getAvailabilities = createAsyncAction(
  CONSTS._GET_AVAILABILITIES_REQUEST,
  CONSTS._GET_AVAILABILITIES_SUCCESS,
  CONSTS._GET_AVAILABILITIES_FAILURE
)<Date, IAvailabilitiesData, Error>()

export const calculatePrice = createAsyncAction(
  CONSTS.CALCULATE_PRICE_REQUEST,
  CONSTS.CALCULATE_PRICE_SUCCESS,
  CONSTS.CALCULATE_PRICE_FAILURE
)<IPriceRequestPayload, ISelectionPerPriceType[], IPriceError>()

export const saveTerms = createAsyncAction(
  CONSTS.SAVE_TERMS_REQUEST,
  CONSTS.SAVE_TERMS_SUCCESS,
  CONSTS.SAVE_TERMS_FAILURE
)<RouteParams<Route.rundate>, ICustomTerm[], Error>()

export const getPartnerActivities = createAsyncAction(
  CONSTS._GET_PARTNER_ACTIVITIES_REQUEST,
  CONSTS._GET_PARTNER_ACTIVITIES_SUCCESS,
  CONSTS._GET_PARTNER_ACTIVITIES_FAILURE
)<number, IActivitiesData, Error>()

export const requestPartnerActivities = createStandardAction(
  CONSTS.REQUEST_PARTNER_ACTIVITIES
)<number>()

export const notifyWhenUpsellUnavailable = createStandardAction(
  CONSTS.NOTIFY_WHEN_UPSELL_UNAVAILABLE
)<string | null>()

export const resetState = createStandardAction(CONSTS._RESET_STATE)()
export const redirectToHappening = createStandardAction(CONSTS.REDIRECT_TO_HAPPENING)<string>()
export const handleHappening = createStandardAction(CONSTS.HANDLE_HAPPENING)<string>()
export const scrollToSecondStep = createStandardAction(CONSTS.SCROLL_TO_SECOND_STEP)()

export const happeningMounted = createStandardAction(CONSTS.HAPPENING_MOUNTED)()

export const setDay = createStandardAction(CONSTS.SET_DAY)<Date>()
export const setDayFromSlot = createStandardAction(CONSTS.SET_DAY_FROM_SLOT)<Date>()
export const setNumberOfPlayers = createStandardAction(
  CONSTS.SET_NUMBER_OF_PLAYERS
)<ISelectionPerPriceType>()
export const setOnlyOnePriceType = createStandardAction(
  CONSTS.SET_ONLY_ONE_PRICE_TYPE
)<ISelectionPerPriceType>()
export const setSlot = createStandardAction(CONSTS.SET_SLOT)<string>()
export const setSpace = createStandardAction(CONSTS.SET_SPACE)<number>()
export const setPriceType = createStandardAction(CONSTS.SET_PRICE_TYPE)<ISlotPrice>()

export const reserveSlots = createStandardAction(CONSTS.RESERVE_SLOTS)<IHappeningFormValues>()

export const reserveSlotsOnsite = createStandardAction(
  CONSTS.RESERVE_SLOTS_ONSITE
)<IHappeningFormOnsiteValues>()

export const setCalculatedPrices = createStandardAction(
  CONSTS.SET_CALCULATED_PRICES
)<IPriceToCalculate>()

export const generateIdempotencyKey = createStandardAction(CONSTS.GENERATE_IDEMPOTENCY_KEY)()

export const upsellMounted = createStandardAction(CONSTS.UPSELL_MOUNTED)()

export const setSelectedUpsell = createStandardAction(CONSTS.SET_SELECTED_UPSELL)<ISelectedUpsell>()

export const toggleSelectedUpsell = createStandardAction(CONSTS.TOGGLE_UPSELL)<boolean>()

export const setUpsellState = createStandardAction(CONSTS.SET_UPSELL_STATE)<boolean>()

export const setTotalPrice = createStandardAction(CONSTS.SET_TOTAL_PRICE)()
export const setDiscountState = createStandardAction(CONSTS.SET_DISCOUNT_STATE)<string | null>()
export const setPrepaidState = createStandardAction(CONSTS.SET_PREPAID_STATE)<string | null>()

export const setTotalPriceInState = createStandardAction(CONSTS.SET_TOTAL_PRICE_IN_STATE)<number>()

export const setExtendedSlot = createStandardAction(
  CONSTS.SET_EXTENDED_SLOT
)<IExtendedSlot | null>()

export const setExtendedSlotModalVisible = createStandardAction(
  CONSTS.SET_EXTENDED_SLOT_MODAL_VISIBLE
)<boolean>()

export const setPricesInState = createStandardAction(CONSTS.SET_PRICES_IN_STATE)<
  IHappeningPriceType[]
>()

export const clearSelectedHappening = createStandardAction(CONSTS.CLEAR_SELECTED_HAPPENING)()
export const resetSlot = createStandardAction(CONSTS.RESET_SLOT)()
