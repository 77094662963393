import React, { FC, useRef } from 'react'

import cn from 'classnames'
import { Overlay, Map as PigeonMap } from 'pigeon-maps'

import { IMapProps } from 'components/reusable/Map/Map.types'
import config from 'config'
import theme from 'config/theme'
import useCenterImage from 'misc/hooks/useCenterImage'
import useResize from 'misc/hooks/useResizer'
import GoingMarker from 'misc/styles/images/going-marker.svg'
import Marker from 'misc/styles/images/marker.svg'
import { useAppState } from 'state/app/hooks/useAppState'

import mapProviders from './Map.providers'
import useStyles from './Map.styles'

const MAP_MAX_HEIGHT = 500
const MARKER_HEIGHT = 40
const MARKER_WIDTH = 40
const MAP_ZOOM = 14
const locationMarker = theme.isEmpik ? Marker : GoingMarker

export const Map: FC<IMapProps> = ({ lon, lat }) => {
  const { isDarkTheme } = useAppState()
  const mapRef = useRef<HTMLDivElement>(null)
  const { width } = useResize(mapRef)
  const muiStyles = useStyles()
  const { provider, Attribution } = config.theme.isEmpik
    ? mapProviders.stamenGrey
    : mapProviders.stamenBlackAndWhite

  return (
    <div ref={mapRef}>
      <PigeonMap
        center={[lat, lon]}
        zoom={MAP_ZOOM}
        height={width <= MAP_MAX_HEIGHT ? width : MAP_MAX_HEIGHT}
        provider={provider}
        attribution={<Attribution />}
        metaWheelZoom={true}
        boxClassname={cn(isDarkTheme && muiStyles.invert)}
      >
        <Overlay anchor={[lat, lon]} offset={useCenterImage(MARKER_WIDTH, MARKER_HEIGHT)}>
          <img
            src={locationMarker}
            width={MARKER_WIDTH}
            height={MARKER_HEIGHT}
            className={cn(isDarkTheme && muiStyles.invert)}
            alt={''}
          />
        </Overlay>
      </PigeonMap>
    </div>
  )
}
