import React, { FC, useContext } from 'react'

import { Close } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import cn from 'classnames'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import config from 'config'
import getCurrencyFromCode from 'misc/helpers/getCurrencyFromCode'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './Pools.module.scss'
import useStyles from './Pools.styles'
import { IPoolsProps } from './Pools.types'

const COURIER_COST = config.buy.courierDeliveryPrice
const GIFT_WRAP_COST = config.buy.giftWrapperPrice

const Pools: FC<IPoolsProps> = ({
  pool,
  ticketTransferFee,
  cancelTicket,
  giftWrap,
  isRundateWithSeatsIo,
  isTransferTicket,
  priceType,
  selectProduct,
  selectTicket,
  withCourier,
  product,
  selectedTheme,
  isInsurance,
  setDay,
}) => {
  const { i18n } = useDictionary()
  const state = useContext(clearContext)
  const summedPrice = pool
    ? (pool.totalPoolPrice || 0) + (pool.serviceFee || 0) + (ticketTransferFee || 0)
    : 0

  const close = () => {
    if (pool) {
      if (cancelTicket) {
        if (!product) {
          selectTicket(pool.id, 0, pool.poolName, pool.currency, pool.payments)
        } else {
          selectProduct(product, 0)
        }
      } else {
        setDay(new Date())
        state?.removeFromBag(pool.id)
      }
    }
  }
  const muiStyles = useStyles()

  const isDefaultTheme = selectedTheme === 'default'

  const deleteUpsell = () => {
    if (pool) {
      const founded = state?.basketItems.find((item) => item.id === pool.id)
      const reduced = {
        ...founded,
        upsell: undefined,
        extendedUpsell: undefined,
        upsellDuration: undefined,
        price: founded?.amount && founded.poolPrice ? founded?.amount * founded?.poolPrice : 0,
      }
      // @ts-ignore
      state?.updateItem(reduced)
    }
  }

  return (
    <section
      className={cn(
        styles.basketPools,
        isInsurance && styles.insurance,
        !isDefaultTheme && muiStyles.basketPools
      )}
    >
      {pool && (
        <Grid container={true} direction={'column'} spacing={3}>
          <Grid
            container={true}
            item={true}
            direction={'row'}
            justifyContent={'space-between'}
            wrap={'nowrap'}
          >
            <Typography variant={'body1'} className={muiStyles.fontMedium}>
              {pool.poolName}
            </Typography>
            {!isRundateWithSeatsIo && !isTransferTicket && !priceType && (
              <IconButton className={styles.close} onClick={close} color={'secondary'}>
                <Close />
              </IconButton>
            )}
          </Grid>
          <Grid container={true} item={true} direction={'column'} spacing={0}>
            <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
              <Typography variant={isDefaultTheme ? 'caption' : 'body2'} color={'textSecondary'}>
                {i18n.buy.summary.pricePerItem}
              </Typography>
              <Typography variant={isDefaultTheme ? 'caption' : 'body2'} color={'textSecondary'}>
                {getPriceFormatted(pool.poolPrice || 0)} {getCurrencyFromCode(pool.currency)}
              </Typography>
            </Grid>
            {!!pool.serviceFee && (
              <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
                <Typography
                  className={muiStyles.typographyWithTooltip}
                  variant={isDefaultTheme ? 'caption' : 'body2'}
                  color={'textSecondary'}
                >
                  {i18n.buy.summary.serviceFee}
                  {config.app.showUnfinishedFeatures && (
                    <Tooltip
                      title={i18n.buy.summary.serviceFeeInformation}
                      enterTouchDelay={100}
                      leaveTouchDelay={6000}
                    >
                      <InfoIcon className={muiStyles.tooltip} />
                    </Tooltip>
                  )}
                </Typography>
                <Typography variant={isDefaultTheme ? 'caption' : 'body2'} color={'textSecondary'}>
                  {getPriceFormatted(pool.serviceFee)} {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            {withCourier && config.features.courierSummary && (
              <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'caption'} color={'secondary'}>
                  {i18n.buy.summary.courierDelivery}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {COURIER_COST} {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            {giftWrap && (
              <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'caption'} color={'secondary'}>
                  {i18n.buy.summary.serviceFee}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {GIFT_WRAP_COST} {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            {isTransferTicket && (
              <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'caption'} color={'secondary'}>
                  {i18n.buy.summary.ticketTransferFee}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {getPriceFormatted(ticketTransferFee || 0)} {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
              <Typography variant={isDefaultTheme ? 'caption' : 'body2'} color={'textSecondary'}>
                {i18n.buy.summary.amount}
              </Typography>
              <Typography variant={isDefaultTheme ? 'caption' : 'body2'} color={'textSecondary'}>
                {pool.amount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'} className={muiStyles.fontMedium}>
              {i18n.buy.summary.totalPrice}
            </Typography>
            <Typography variant={'body1'} className={muiStyles.fontMedium}>
              {summedPrice ? summedPrice.toFixed(2) : 0} {getCurrencyFromCode(pool.currency)}
            </Typography>
          </Grid>

          {/* UPSELL */}
          {((pool.upsell && pool.upsell.isSelected) || pool.extendedUpsell) && (
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Grid container={true} justifyContent={'space-between'}>
                    <Grid item={true}>
                      <Typography
                        variant="body1"
                        color={'textPrimary'}
                        className={muiStyles.fontMedium}
                      >
                        {i18n.buy.summary.upsell}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <IconButton
                        className={styles.close}
                        onClick={deleteUpsell}
                        color={'secondary'}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <Grid container={true} justifyContent={'space-between'}>
                    <Grid item={true}>
                      <Typography
                        variant={'body2'}
                        color={'textSecondary'}
                        className={muiStyles.fontMedium}
                      >
                        {i18n.buy.summary.time}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography
                        variant={'body2'}
                        color={'textSecondary'}
                        className={muiStyles.fontMedium}
                      >
                        {pool.upsellDuration ||
                          (pool.extendedUpsell && pool?.extendedUpsell.duration)}{' '}
                        min
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <Grid container={true} justifyContent={'space-between'}>
                    <Grid item={true}>
                      <Typography variant={'body2'} className={muiStyles.fontMedium}>
                        {i18n.buy.summary.totalPrice}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body2'} className={muiStyles.fontMedium}>
                        {getPriceFormatted(pool.upsell?.price || 0)} {pool.currency}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {!pool && (
        <Grid container={true} item={true} direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'caption'}>{i18n.buy.summary.noData}</Typography>
        </Grid>
      )}
    </section>
  )
}

export default Pools
