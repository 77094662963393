import React from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import linkToFileInStorage from 'misc/helpers/linkToFileInStorage'
import _L from 'misc/helpers/local'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import * as LOCALS from 'vars/constants'

import config from '../../../../config'
import styles from './Term.module.scss'
import useStyles from './Term.styles'

const Terms = () => {
  const { i18n } = useDictionary()
  const theme = useTheme()
  const muiStyles = useStyles(theme)

  return (
    <span>
      {i18n.buy.term.iAccept}{' '}
      <a
        className={cn(styles.termLink, muiStyles.root)}
        href={linkToFileInStorage(_L(LOCALS.STORAGE__TERMS), true)}
        target="_blank"
      >
        {i18n.buy.term.regulations(config.theme.isEmpik ? 'Empik Bilety' : 'Going.')}
      </a>{' '}
      {i18n.buy.term.and}{' '}
      <a
        className={cn(styles.termLink, muiStyles.root)}
        href={linkToFileInStorage(_L(LOCALS.STORAGE__PRIVACY_POLICY), true)}
        target="_blank"
      >
        {i18n.buy.term.privacyPolicy(config.theme.isEmpik ? 'Empik Bilety' : 'Going.')}
      </a>
      *
    </span>
  )
}

export default Terms
