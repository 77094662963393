import * as React from 'react'

import { FormControlLabel, FormHelperText, Checkbox as MaterialCheckbox } from '@mui/material'
import { FieldValues, useController } from 'react-hook-form'

import { ICheckboxProps } from 'components/_forms/Checkbox/Checkbox.types'

export const Checkbox = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  label,
  ...rest
}: ICheckboxProps<T>) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  return (
    <>
      <FormControlLabel
        slotProps={{ typography: { variant: 'caption', color: invalid ? 'error' : undefined } }}
        control={<MaterialCheckbox inputRef={ref} {...inputProps} {...rest} />}
        label={label}
      />
      {invalid && <FormHelperText children={error?.message} error={invalid} />}
    </>
  )
}
