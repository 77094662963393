import { useSelector } from 'react-redux'

import _Store from '@Store'

import { PagesEnum } from 'models/pages/constants/pages'
import { ICompositionData } from 'models/pages/types'

export const useRecommendFromStore = () =>
  useSelector<_Store.IState, ICompositionData | null>(({ pages }) =>
    pages.compositions[PagesEnum.recommend]?.sections.length
      ? pages.compositions[PagesEnum.recommend] || null
      : null
  )
