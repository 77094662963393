import axios, { CancelTokenSource } from 'axios'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import { IActivitiesData } from 'models/happening/types'

class ActivityApi {
  private static getActivitiesUrl() {
    return `${config.api.zagrywkiApi}happenings`
  }
  private cancelToken?: CancelTokenSource

  public getPartnerActivities(partnerId: number): Promise<IActivitiesData> {
    return new Promise<IActivitiesData>((resolve, reject) => {
      this.cancelToken = axios.CancelToken.source()

      axios
        .get(
          ActivityApi.getActivitiesUrl(),
          withCacheHeader({
            cancelToken: this.cancelToken.token,
            params: {
              'partnerIds[]': partnerId,
            },
          })
        )
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelEvent() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }
}

export default new ActivityApi()
