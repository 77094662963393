import React from 'react'

import cn from 'classnames'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import DualLink from 'components/reusable/DualLink'
import icons from 'misc/helpers/icons'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './EventCards.module.scss'
import { IEventCardsProps } from './EventCards.types'

const SECTION_ID = 'tickets'
const SEPARATOR_TEXT = '❖'

const EventCards = ({
  backgroundGradient,
  borderColorScheme,
  buttonHover,
  buttonOut,
  buttonScheme,
  cardTitleScheme,
  events,
  isDark,
  sectionTitleScheme,
}: IEventCardsProps) => {
  const { i18n } = useDictionary()
  return (
    <>
      <h2 className={styles.sectionTitle} id={SECTION_ID} style={sectionTitleScheme}>
        {i18n.landing.tickets}
      </h2>
      <ul className={styles.cardDeck}>
        {events.map((event, id) => {
          const link = prepareRoute({
            route: event.isBuyLink ? Route.buy : Route.rundate,
            params: {
              eventSlug: event.eventSlug,
              rundateSlug: event.rundateSlug!,
            },
          })
          const buyButtonActive = event.eventSlug && !event.disableBuyButton
          const mainTitle = event.city ? event.city : event.title
          const buyButtonText = event.buttonText || i18n.buttons.buyTicket
          const disabledButtonText = event.buttonText || i18n.buttons.disabled

          return (
            <li
              className={cn(styles.card, isDark && styles.dark)}
              key={id}
              style={borderColorScheme}
            >
              <div
                className={styles.cardHeader}
                style={{
                  ...borderColorScheme,
                  ...backgroundGradient,
                }}
              >
                {SEPARATOR_TEXT}
              </div>
              <div className={styles.cardBody}>
                {event.date && (
                  <h2 className={styles.date}>
                    <b>{event.date}</b> {event.day}
                  </h2>
                )}
                <h3 className={styles.title} style={cardTitleScheme}>
                  {mainTitle}
                </h3>
                {event.placeName && <h3 className={styles.place}>{event.placeName}</h3>}
              </div>
              <div className={styles.cardFooter}>
                {event.social && (
                  <DualLink to={event.social} newwindow={true}>
                    <button
                      className={styles.cardButton}
                      style={buttonScheme}
                      onMouseOver={buttonHover}
                      onMouseOut={buttonOut}
                    >
                      <span className={cn(styles.socialIcon, icons.facebookSquare)} />
                      {i18n.buttons.join}
                    </button>
                  </DualLink>
                )}

                {!buyButtonActive && (
                  <button
                    className={cn(styles.cardButton, styles.disabled)}
                    type="button"
                    style={buttonScheme}
                    onMouseOver={buttonHover}
                    onMouseOut={buttonOut}
                  >
                    {disabledButtonText}
                  </button>
                )}
                {buyButtonActive && (
                  <DualLink to={link} newwindow={true}>
                    <button
                      className={styles.cardButton}
                      style={buttonScheme}
                      onMouseOver={buttonHover}
                      onMouseOut={buttonOut}
                    >
                      {buyButtonText}
                    </button>
                  </DualLink>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default EventCards
