import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      marginLeft: theme.spacing(1),
      width: '0.75rem',
    },

    typographyWithTooltip: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export default useStyles
