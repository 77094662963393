import React, { FC } from 'react'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
} from '@mui/material'
import cn from 'classnames'
import { ErrorMessage } from 'formik'

import classes from './Checkbox.classes'
import styles from './Checkbox.module.scss'
import { ICheckboxProps } from './Checkbox.types'

const Checkbox: FC<ICheckboxProps> = ({
  field: { name, value, onChange, onBlur },
  disabled,
  id,
  isRequiredTerm,
  children,
  style,
  theme,
  error,
  errorMessage,
}) => {
  if (theme !== 'default') {
    return (
      <FormControl error={error}>
        <FormControlLabel
          control={
            <MuiCheckbox
              name={name}
              id={id}
              value={value}
              checked={!!value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
          }
          label={children}
        />
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    )
  }

  return (
    <div className={cn(styles.checkbox, classes.checkbox, disabled && styles.disabled)}>
      <label htmlFor={id} className={cn(style, classes.label)}>
        <input
          name={name}
          id={id}
          type={'checkbox'}
          value={value}
          checked={!!value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          className={cn(classes.checkbox)}
          terms-required={isRequiredTerm ? 'term-required' : undefined}
        />

        <span
          className={cn(styles.selector, classes.selector)}
          data-checkbox-id={isRequiredTerm && 'checkbox active'}
        />

        {children}
      </label>
      <ErrorMessage name={name} className={cn(styles.error, classes.error)} component="span" />
    </div>
  )
}

export default Checkbox
