import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: 0, paddingBottom: '1rem' },
    spaceAround: {
      padding: '0rem 1rem',
    },
    fontMedium: {
      fontWeight: 500,
    },
    priceContainer: {
      padding: '0rem 0.5rem',
      marginTop: '1.25rem',
      [theme.breakpoints.up('md')]: {
        marginTop: '2.25rem',
        padding: '2rem',
      },
    },
    basketButton: {
      padding: '1.25rem 0rem',
    },
  })
)

export default useStyles
