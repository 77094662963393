import React from 'react'

import { Button, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/pl'

import { Flex } from 'components/_layout/Flex'
import { CurrencyISO, currencySymbol } from 'constants/Currency'
import { Languages } from 'constants/Languages'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { TransactionsApi } from 'services/Api/TransactionsApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import { IExtendedPaymentProps } from './ExtendedPayment.types'

export const ExtendedPayment = ({ transaction }: IExtendedPaymentProps) => {
  const { user, revenue, transactionItems, transactionUuid } = transaction
  const theme = useTheme()
  const { i18n } = useDictionary()
  const { isMobile } = useBreakpoints()
  const { code } = useLang()

  moment.locale(code === Languages.Polski ? 'pl' : 'en')

  const finalizeTransaction = () => {
    TransactionsApi.finalizeTransaction([transactionUuid])
  }

  return (
    <Flex direction={'column'} gap={5} fullWidth sx={{ padding: '1rem' }}>
      <Flex
        direction={'column'}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.border.radius,
        }}
        fullWidth
      >
        <Flex
          gap={1}
          sx={{
            padding: '2rem',
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
          }}
          fullWidth
        >
          <Typography variant={'h4'}>{i18n.extendedPayment.title}</Typography>
          <Typography variant={'body1'}>{i18n.extendedPayment.subtitle}</Typography>
        </Flex>
        <Flex gap={1} sx={{ padding: '2rem' }}>
          <Typography variant={'h5'}>{i18n.extendedPayment.clientData}</Typography>
          <Flex direction={'row'} gap={1} wrap={'wrap'}>
            <Typography variant={'body1'} color={'secondary'}>
              {i18n.extendedPayment.firstName}
            </Typography>
            <Typography variant={'body1'}>{user.userFirstName}</Typography>
          </Flex>
          <Flex direction={'row'} gap={1} wrap={'wrap'}>
            <Typography variant={'body1'} color={'secondary'}>
              {i18n.extendedPayment.lastName}
            </Typography>
            <Typography variant={'body1'}>{user.userLastName}</Typography>
          </Flex>
          <Flex direction={'row'} gap={1} wrap={'wrap'}>
            <Typography variant={'body1'} color={'secondary'}>
              {i18n.extendedPayment.emailAddress}
            </Typography>
            <Typography variant={'body1'}>{user.userEmail}</Typography>
          </Flex>
        </Flex>
        <Flex gap={1} sx={{ padding: '2rem' }} fullWidth>
          <Typography variant={'h5'}>{i18n.extendedPayment.summary}</Typography>
          {transactionItems.map((item) => (
            <Flex
              key={item.uuid}
              gap={1}
              fullWidth
              sx={{ borderBottom: `2px solid ${theme.palette.secondary.light}` }}
            >
              <Typography variant={'body1'}>{item.productName}</Typography>
              <Flex
                fullWidth
                direction={'row'}
                justify={'space-between'}
                align="flex-end"
                wrap={'wrap'}
              >
                <Typography variant={'body1'} color={'secondary'}>
                  {`${moment(item.startDate).format('dddd')}, ${moment(item.startDate).format(
                    'DD MMMM YYYY, HH:mm'
                  )}`}
                </Typography>
                <Typography variant={'body1'} color={'secondary'}>
                  {item.price} {currencySymbol[CurrencyISO[item.currency]]}
                </Typography>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex justify={'space-between'} align={'center'} direction={'row'} fullWidth>
        <Typography variant={isMobile ? 'body1' : 'h4'}>{i18n.extendedPayment.toPay}</Typography>
        <Typography variant={isMobile ? 'h4' : 'h2'}>
          {revenue} {currencySymbol[CurrencyISO.PLN]}
        </Typography>
      </Flex>
      <Flex align={'flex-end'} fullWidth>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'large'}
          sx={{ textTransform: 'capitalize', width: isMobile ? '100%' : '50%' }}
          onClick={finalizeTransaction}
        >
          {i18n.extendedPayment.payNow}
        </Button>
      </Flex>
    </Flex>
  )
}
