import * as React from 'react'

import { Box, Typography } from '@mui/material'

import SimpleLoading from 'components/reusable/SimpleLoading'
import { IActionLoadingProps } from 'components/ticketManagement/TicketAction/ActionLoading/ActionLoading.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ActionLoading = ({ actionKey, hideText }: IActionLoadingProps) => {
  const { i18n } = useDictionary()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 'inherit',
      }}
    >
      <SimpleLoading relative />
      {!hideText && (
        <Typography
          mt={4}
          variant={'caption'}
          color={'text.secondary'}
          children={i18n.ticketManagement.myTicket.actions[actionKey].loading}
        />
      )}
    </Box>
  )
}
