import { ICheckEmailResponse, IGenerateTokenResponse } from 'services/$auth-api/types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'

export const checkEmail = createStandardAction(CONSTS.CHECK_EMAIL)<string>()
export const toggleLogin = createStandardAction(CONSTS.TOGGLE_LOGIN)()

export const submitEmailCheck = createAsyncAction(
  CONSTS._CHECK_EMAIL_REQUEST,
  CONSTS._CHECK_EMAIL_SUCCESS,
  CONSTS._CHECK_EMAIL_FAILURE
)<undefined, ICheckEmailResponse, Error>()

export const generateAuthToken = createAsyncAction(
  CONSTS._GENERATE_TOKEN_REQUEST,
  CONSTS._GENERATE_TOKEN_SUCCESS,
  CONSTS._GENERATE_TOKEN_FAILURE
)<undefined, IGenerateTokenResponse, Error>()
