import React, { FC, useEffect } from 'react'

import { Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Field, useField, useFormikContext } from 'formik'

import { IBuyingOnlineFormValues } from 'components/buying/Online/Online.types'
import RadioButton from 'components/formik/Radio'
import config from 'config'
import icons from 'misc/helpers/icons'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import * as CONST from './../../constants/constants'
import PickUpParameters from './../PickUpParameters'
import PostAddress from './../PostAddress'
import styles from './PickUpWay.module.scss'
import useStyles from './PickUpWay.styles'
import { IPickUpWayProps } from './PickUpWay.types'

const COURIER_COST_TEXT = `(${config.buy.courierDeliveryPrice}zł)`
const PICK_UP_FORM_NAME = 'pickUpWay'
const CURRENCY_TEXT = 'zł'

const PickUpWay: FC<IPickUpWayProps> = ({
  courierRequired,
  isDisabled,
  isProduct,
  number,
  showAddressForm,
  shipmentDeliveryMounted,
  shipments,
  selectedTheme,
}) => {
  const { i18n } = useDictionary()
  const { values } = useFormikContext<IBuyingOnlineFormValues>()
  // TODO: || courierAvailable
  const showPostDelivery = courierRequired
  const showMailDelivery = !showPostDelivery || (showPostDelivery && !courierRequired)
  const [field, , { setValue }] = useField(PICK_UP_FORM_NAME)
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const isDefaultTheme = selectedTheme === 'default'

  const checkIsOnShipmentsList = (id: string) =>
    shipments.some((shipment) => String(shipment.productId) === id)

  useEffect(() => {
    if (config.features.showNewShipmentDelivery) {
      shipmentDeliveryMounted(values.deliveryCountry)
    }
  }, [values.deliveryCountry])

  useEffect(() => {
    if (courierRequired) {
      setValue(String(shipments[0]?.productId))
    } else {
      setValue(CONST.PICK_UP_WAY_EMAIL)
    }
  }, [courierRequired, shipments])

  return (
    <div className={cn(styles.pickUpWay, isDisabled && styles.disabled)}>
      <Typography variant={'h3'} className={muiStyles.header} data-number={number}>
        {isProduct ? i18n.buy.pickUpWay.pickUpProduct : i18n.buy.pickUpWay.pickUp}
      </Typography>
      <fieldset
        className={cn(
          styles.formPanel,
          muiStyles.root,
          !isDefaultTheme && muiStyles.withoutPaddings
        )}
      >
        {showMailDelivery && (
          <div className={styles.label}>
            <Field component={RadioButton} name={PICK_UP_FORM_NAME} id={CONST.PICK_UP_WAY_EMAIL}>
              {i18n.buy.pickUpWay.sendAsEmail}
              <span className={cn(styles.icon, icons.mail, muiStyles.fontColor)} />
              <span className={cn(styles.specialInfo, muiStyles.fontColor)}>
                {i18n.buy.pickUpWay.rightAway}
              </span>
            </Field>
          </div>
        )}

        {config.features.showNewShipmentDelivery && showPostDelivery && (
          <>
            {shipments.map((shipment) => (
              <div className={styles.label}>
                <Field
                  component={RadioButton}
                  name={PICK_UP_FORM_NAME}
                  id={String(shipment.productId)}
                >
                  {`${shipment.description} (${shipment.price}${CURRENCY_TEXT})`}
                </Field>
              </div>
            ))}

            {checkIsOnShipmentsList(field.value) && <PostAddress />}
          </>
        )}

        {!config.features.showNewShipmentDelivery && showPostDelivery && (
          <>
            <div className={styles.label}>
              <Field component={RadioButton} name={PICK_UP_FORM_NAME} id={CONST.PICK_UP_WAY_POST}>
                {i18n.buy.pickUpWay.courierDelivery + COURIER_COST_TEXT}
                <span className={cn(styles.icon, icons.lorry, muiStyles.fontColor)} />
              </Field>
            </div>

            {config.features.giftWrapper && <PickUpParameters />}

            {showAddressForm && <PostAddress />}
          </>
        )}
      </fieldset>
    </div>
  )
}

export default PickUpWay
