import loadDOMScript from 'misc/helpers/loadDOMScript'

/**
 * Function evaluating all DOM scripts synchronously and in order.
 * Note: it filters out inline scripts as there is no src attribute.
 */
const evalPageScripts = () => {
  const paths = [] as string[]
  const scripts = document.querySelectorAll('script')

  scripts.forEach((script: HTMLScriptElement) => {
    if (script.src) {
      paths.push(script.src)
    }
  })

  const loopPaths = (array: string[]) => {
    if (array.length > 0) {
      const path = array.splice(0, 1)[0]
      loadDOMScript(path).then(() => {
        loopPaths(array)
      })
    }
  }

  loopPaths(paths)
}

export default evalPageScripts
