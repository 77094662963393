import React, { FC } from 'react'

import cn from 'classnames'

import { IChevronProps } from 'components/reusable/Chevron/Chevron.types'

import styles from './Chevron.module.scss'

const Chevron: FC<IChevronProps> = ({ isActive, open }) => {
  return <span className={cn(styles.chevron, isActive && styles.active)} onClick={open} />
}

export default Chevron
