import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { finalizeTransaction, handlePrevStep, skipToSelectedStep } from 'models/entryList/actions'
import { getIsViewOnly, getSubmissionsSummary } from 'models/entryList/selectors'

import SubmissionList from './SubmissionList.component'
import { ISubmissionsListFromDispatch, ISubmissionsListFromState } from './SubmissionsList.types'

const mapStateToProps = (state: _Store.IState): ISubmissionsListFromState => ({
  submissions: getSubmissionsSummary(state),
  isViewOnly: getIsViewOnly(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ISubmissionsListFromDispatch => ({
  finalizeTransaction: () => dispatch(finalizeTransaction()),
  handlePrevStep: () => dispatch(handlePrevStep()),
  skipToSelectedStep: (step) => dispatch(skipToSelectedStep(step)),
})

export default connect<ISubmissionsListFromState, ISubmissionsListFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionList)
