import React, { Component } from 'react'

import styles from './BeautyScroll.module.scss'
import { IBeautyScrollProps, IBeautyScrollState } from './BeautyScroll.types'

class BeautyScroll extends Component<IBeautyScrollProps, IBeautyScrollState> {
  public state: IBeautyScrollState = {
    height: 0,
    top: 0,
  }

  private scrollStep = (1.5 + 0.75) * 16 // 1 city -> height: 2.25rem, font: 16px;

  private scrollRef = React.createRef<HTMLDivElement>()

  constructor(props: IBeautyScrollProps) {
    super(props)

    this.onScroll = this.onScroll.bind(this)
    this.scrollUp = this.scrollUp.bind(this)
    this.scrollDown = this.scrollDown.bind(this)
  }

  public componentDidMount() {
    this.onScroll()
  }

  public render() {
    const { children } = this.props

    return (
      <div className={styles.scroll}>
        <div className={styles.buttons}>
          <button type="button" className={styles.up} onClick={this.scrollUp}>
            Scroll up
          </button>
          <button type="button" className={styles.down} onClick={this.scrollDown}>
            Scroll down
          </button>
        </div>
        <div className={styles.bar}>
          <div
            style={{
              height: `${this.state.height}%`,
              top: `${this.state.top}%`,
            }}
          />
        </div>
        <div className={styles.inner} ref={this.scrollRef} onScroll={this.onScroll}>
          {children}
        </div>
      </div>
    )
  }

  private scrollUp() {
    this.scrollBy(-1)
  }

  private scrollDown() {
    this.scrollBy(1)
  }

  private scrollBy(multi: number) {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollBy(0, this.scrollStep * multi)
    }
  }

  private onScroll() {
    if (this.scrollRef.current) {
      const { offsetHeight, scrollHeight, scrollTop } = this.scrollRef.current

      this.setState({
        height: (offsetHeight / scrollHeight) * 100,
        top: (scrollTop / scrollHeight) * 100,
      })
    }
  }
}

export default BeautyScroll
