import React from 'react'

import { Star } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import { Flex } from 'components/_layout/Flex'
import { StatsCard } from 'components/reusable/StatsCard'
import isNullOrUndefined from 'misc/helpers/isNullOrUndefined'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IStatsProps } from './Stats.types'

export const Stats = ({ data }: IStatsProps) => {
  const { i18n } = useDictionary()
  const theme = useTheme()

  if (!data) return null

  return (
    <Flex direction={'row'} justify={'space-evenly'} gap={4} sx={{ overflowX: 'scroll' }}>
      {!isNullOrUndefined(data.rating) && !isNullOrUndefined(data.opinion) && (
        <StatsCard
          title={i18n.stats.rating}
          value={data.rating || 0}
          subtitle={`${data.opinion} ${i18n.stats.opinion}`}
          startIcon={<Star htmlColor={theme.palette.other.activeRating} />}
        />
      )}
      {!isNullOrUndefined(data.events) && (
        <StatsCard
          title={i18n.stats.events}
          value={data.events || 0}
          subtitle={i18n.stats.future}
        />
      )}
      {!isNullOrUndefined(data.visited) && (
        <StatsCard title={i18n.stats.visited} value={data.visited || 0} isAvatar={true} />
      )}
      {!isNullOrUndefined(data.friends) && (
        <StatsCard title={i18n.stats.friends} value={data.friends || 0} isAvatar={true} />
      )}
      {!isNullOrUndefined(data.related) && (
        <StatsCard
          title={i18n.stats.related}
          value={data.related || 0}
          subtitle={i18n.stats.profile}
        />
      )}
      {!isNullOrUndefined(data.tookPlace) && (
        <StatsCard
          title={i18n.stats.tookPlace}
          value={data.tookPlace || 0}
          subtitle={i18n.stats.events.toLowerCase()}
          withoutDivider
        />
      )}
    </Flex>
  )
}
