import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import BasketButton from 'components/Basket/components/BasketButton'
import ImageFixed from 'components/reusable/ImageFixed'
import { SellerInfoButton } from 'components/reusable/SellerInfoButton'
import config from 'config'
import { CurrencyISO, currencySymbol } from 'constants/Currency'
import getFormattedBasketPools from 'misc/helpers/getBasketFormattedPools'
import getCurrencyFromCode from 'misc/helpers/getCurrencyFromCode'
import getNonEmptyObject from 'misc/helpers/getNonEmptyObject'
import getPriceFormatted from 'misc/helpers/getPriceFormatted'
import isEmptyObject from 'misc/helpers/isEmptyObject'
import translatableDate from 'misc/helpers/translatableDate'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import productIconEmpik from 'misc/styles/images/product-empik.svg'
import productIcon from 'misc/styles/images/product.svg'
import { IBasket } from 'models/basket/types'
import { IPoolsSummary } from 'models/pools/types'
import { IProductsSummary } from 'models/products/types'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

import Triangle from './../Triangle'
import classes from './Summary.classes'
import styles from './Summary.module.scss'
import useStyles from './Summary.styles'
import { ISummaryProps } from './Summary.types'
import Loading from './components/Loading'
import Pools from './components/Pools'

const Summary: FC<ISummaryProps> = ({
  event: potentialEvent,
  giftWrap,
  isBasketPay,
  isEmbed,
  isLoading,
  isProductsPay,
  isTransferTicket,
  module,
  products,
  selectedShipment,
  selectedDay,
  selectedDayFromSlot,
  selections,
  selectedProducts,
  selectedTheme,
  selectedTickets,
  slots,
  summary: _summary,
  summaryProducts: _summaryProducts,
  transferTicketFee,
  receivedTickets,
  withCourier,
  insurancePrice,
  isInsurance,
  selectedExtendedSlot,
  defaultSelections,
  setFinalPriceAction,
  setInsuranceSummaryData,
}) => {
  const { i18n } = useDictionary()
  const { code: language } = useLang()
  const [items, setItems] = useState<IBasket[]>([])
  const { isMobile, isDesktop } = useBreakpoints()
  const state = useContext(clearContext)
  const basketItems = isEmbed && !state?.basketItems ? [] : state?.basketItems
  const isProductsModule = module === Route.buyProducts || module === Route.pinBuyProducts
  const isHappeningsModule = module === Route.happening || module === Route.pinHappening
  const summary = _summary || ({ amount: 0 } as IPoolsSummary)
  const summaryProducts = _summaryProducts || ({ amount: 0 } as IProductsSummary)
  const event = getNonEmptyObject(potentialEvent)
  const withoutBottomSpace =
    summary.amount < 1 && summaryProducts.amount < 1 && !slots?.activityName
  const selectedDayIso = selectedDay?.toISOString()
  const selectedDayFromSlotIso = selectedDayFromSlot?.toISOString()
  const selectedDayWithoutHours = selectedDayIso?.split('T')[0]
  const selectedDayFromSlotWithoutHours = selectedDayFromSlotIso?.split('T')[0]
  const isChosenSlotAfterMidnight =
    selectedDayWithoutHours &&
    selectedDayFromSlotWithoutHours &&
    selectedDayWithoutHours !== selectedDayFromSlotWithoutHours
  const isExtendedUpsell =
    selectedExtendedSlot &&
    (selectedExtendedSlot.upsellPercentageValue || selectedExtendedSlot.upsellValue)

  const withoutEventDetails = !isBasketPay && !isProductsPay
  const isImageFixedRendered =
    ((!isMobile && isHappeningsModule) || isDesktop) && withoutEventDetails
  const receivedPrice = () =>
    receivedTickets && receivedTickets.pool
      ? receivedTickets.pool.pools
          .map((pool) =>
            pool.id === receivedTickets.currentPoolId
              ? (pool.price + pool.serviceFee) * receivedTickets.ticketNum
              : 0
          )
          .reduce((a, b) => a + b, 0) + (transferTicketFee || 0)
      : null

  const receivedSeatPool = receivedTickets?.pool?.pools.find(
    (pool) => pool.id === receivedTickets?.currentPoolId
  )

  const receivedSeat = receivedTickets?.pool?.seatsio
    ? receivedTickets?.pool?.seatsio.find(
        (seat) => seat?.ticketTypes[0].poolId === receivedTickets?.currentPoolId
      )
    : null

  const receivedSeatsPrice =
    receivedTickets && receivedSeatPool && receivedSeat
      ? (receivedSeat.ticketTypes[0].price + receivedSeatPool.serviceFee) *
          receivedTickets.ticketNum +
        (transferTicketFee || 0)
      : 0

  const totalHappeningProductPrice = products.reduce((acc, product) => acc + product.price, 0)

  const selectionsFilteredByPlayersNum = selections?.filter(
    (selection) => selection.numberOfPlayers && selection.numberOfPlayers > 0
  )

  const finalPrice =
    (summary.finalPrice || 0) +
    (slots?.price || 0) +
    totalHappeningProductPrice +
    (summaryProducts.finalPrice || 0) +
    (isInsurance ? insurancePrice || 0 : 0)

  const totalPriceWithExtendedSlot = useMemo(
    () =>
      defaultSelections?.reduce((prev, crr) => {
        return (
          prev +
          (crr.priceType?.value || 0) *
            (crr.numberOfPlayers || 1) *
            (selectedExtendedSlot?.priceMultiplier || 0)
        )
      }, 0),
    [finalPrice, selectedExtendedSlot?.priceMultiplier]
  )

  const discountValue = useMemo(
    () => ((totalPriceWithExtendedSlot || 0) - finalPrice).toFixed(2),
    [finalPrice]
  )

  useEffect(() => {
    setItems(getFormattedBasketPools(basketItems) || [])
  }, [state?.basketItems])

  const totalPrice = () =>
    basketItems?.length
      ? basketItems.map((item) => item.price).reduce((a, b) => a + b, 0) + finalPrice
      : null

  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const isDefaultTheme = selectedTheme === 'default'

  const isAnyNonFreeItemsInBasket =
    basketItems && basketItems.length > 0 && getPriceFormatted(totalPrice()) !== undefined

  const isTicketWithInsurance =
    isInsurance &&
    insurancePrice &&
    !basketItems?.length &&
    getPriceFormatted(insurancePrice) !== null

  useEffect(() => {
    if (isTicketWithInsurance) {
      setInsuranceSummaryData({
        isSelected: true,
        value: Number(getPriceFormatted(insurancePrice)),
      })
    }

    isAnyNonFreeItemsInBasket
      ? setFinalPriceAction(Number(getPriceFormatted(totalPrice())))
      : setFinalPriceAction(Number(getPriceFormatted(finalPrice)))
  }, [finalPrice])
  return (
    <div className={cn(styles.summaryBox, classes.summaryBox)}>
      <div className={cn(styles.wrapper, classes.wrapper)}>
        {isImageFixedRendered && (
          <div className={cn(styles.imageWrapper, classes.imageWrapper, muiStyles.imageWrapper)}>
            <ImageFixed
              src={event.externalImages?.[0] || event.imagesUrl}
              alt={event.title}
              transformation={{
                height: 700,
                width: 810,
              }}
            />
          </div>
        )}
        <div className={cn(styles.sticky, classes.sticky)}>
          {isLoading && <Loading />}
          {!isProductsModule && !isLoading && withoutEventDetails && (
            <div
              className={cn(
                styles.content,
                withoutBottomSpace && styles.withoutBottomSpace,
                basketItems?.length && !isDesktop && styles.withBasket,
                classes.content,
                muiStyles.root
              )}
            >
              <Typography
                className={cn(
                  config.theme.isGoing && isDefaultTheme && muiStyles.title,
                  isDefaultTheme && styles.title
                )}
                variant={'h4'}
              >
                {event.title}
              </Typography>
              <div
                className={cn(
                  styles.infoBox,
                  withoutBottomSpace && styles.withoutBottomSpace,
                  slots && styles.slotsInfoBox,
                  classes.infoBox,
                  muiStyles.content,
                  !isDefaultTheme && muiStyles.withoutSidePaddings
                )}
              >
                {!slots && (
                  <>
                    <h2 className={cn(styles.info, classes.info, muiStyles.fontColor)}>
                      {translatableDate(event.startDate, event.rundateDescription, i18n.dates)}
                      {config.theme.isGoing && (
                        <>
                          {event.hourDescription ? (
                            <span>, {event.hourDescription}</span>
                          ) : (
                            <span>, {event.friendlyHour}</span>
                          )}
                        </>
                      )}
                    </h2>

                    {config.theme.isEmpik && (
                      <>
                        {event.hourDescription ? (
                          <h2 className={cn(styles.info, classes.info, muiStyles.fontColor)}>
                            {event.hourDescription}
                          </h2>
                        ) : (
                          <h2 className={cn(styles.info, classes.info, muiStyles.fontColor)}>
                            {event.friendlyHour}
                          </h2>
                        )}
                      </>
                    )}
                    <h2 className={cn(styles.info, classes.info)}>
                      {event.placeSlug && !isEmbed && (
                        <Link
                          to={prepareRoute({
                            route: Route.place,
                            params: { slug: event.placeSlug },
                          })}
                          className={muiStyles.fontColor}
                        >
                          {event.placeFriendly}
                        </Link>
                      )}
                      {(!event.placeSlug || isEmbed) && event.placeFriendly}
                    </h2>
                  </>
                )}

                <Box sx={{ mt: 3 }} />
                {event.partnerInfo && !event.partnerInfo?.vatPayer ? (
                  <SellerInfoButton partnerInfo={event.partnerInfo} />
                ) : (
                  <>
                    <Typography variant={'subtitle2'}>{i18n.event.goingSeller}</Typography>
                  </>
                )}

                {slots && (
                  <Grid
                    container={true}
                    direction={'column'}
                    spacing={1}
                    className={muiStyles.infoBox}
                  >
                    <Grid item={true}>
                      <Typography variant={'body1'} color={'primary'}>
                        {translatableDate(event.startDate, event.rundateDescription, i18n.dates)}
                        {isChosenSlotAfterMidnight && i18n.eventBoxes.summary.afterMidnight}
                      </Typography>
                    </Grid>
                    <Grid container={true} item={true} direction={'row'} spacing={1}>
                      <Grid item={true}>
                        <Typography variant={'body1'}>{i18n.buy.summary.time}</Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography variant={'body1'} color={'primary'}>
                          {event.friendlyHour}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          )}
          {!withoutBottomSpace && (
            <>
              {!isDefaultTheme && <Divider />}
              <div
                className={cn(
                  styles.content,
                  withoutBottomSpace && styles.withoutBottomSpace,
                  classes.content,
                  styles.basket,
                  (isBasketPay || !isDefaultTheme) && styles.basketWithoutMargin,
                  styles.withoutTopPadding,
                  isProductsModule && styles.products,
                  isDesktop ? muiStyles.root : muiStyles.transparent
                )}
              >
                <section
                  className={cn(
                    styles.contentWrapper,
                    slots && styles.slotsWrapper,
                    muiStyles.root,
                    !isDefaultTheme && muiStyles.withoutSidePaddings,
                    isDefaultTheme && muiStyles.rootDefault
                  )}
                >
                  {slots && slots.activityName && (
                    <Grid container={true} spacing={6} direction={'column'}>
                      <Grid container={true} item={true} spacing={4} direction={'column'}>
                        <Grid item={true}>
                          <Typography variant={isDefaultTheme ? 'h6' : 'h4'}>
                            {i18n.buy.summary.yourReservation}
                          </Typography>
                        </Grid>
                        {isExtendedUpsell && (
                          <Grid item={true}>
                            <Grid container={true} spacing={1}>
                              <Grid item={true} xs={12}>
                                <Grid container={true} justifyContent={'space-between'}>
                                  <Grid item={true}>
                                    <Typography variant={'body1'}>
                                      {i18n.buy.summary.extendedSlotPrice}
                                    </Typography>
                                  </Grid>
                                  <Grid item={true}>
                                    <Typography variant={'body1'} className={muiStyles.lineThrough}>
                                      {totalPriceWithExtendedSlot?.toFixed(2)}{' '}
                                      {currencySymbol[CurrencyISO.PLN]}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item={true} xs={12}>
                                <Grid container={true} justifyContent={'space-between'}>
                                  <Grid item={true}>
                                    <Typography variant={'subtitle2'} color={'primary'}>
                                      {i18n.buy.summary.extendedSlotDiscount}
                                    </Typography>
                                  </Grid>
                                  <Grid item={true}>
                                    <Typography variant={'subtitle2'} color={'primary'}>
                                      {discountValue} {currencySymbol[CurrencyISO.PLN]}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item={true} container={true} spacing={1} direction={'column'}>
                          {slots &&
                            selectionsFilteredByPlayersNum &&
                            selectionsFilteredByPlayersNum?.map((selection, index) => {
                              const poolPrice = selection.priceType?.priceOverride
                                ? selection.priceType.priceOverride / 100
                                : (selection.value || 0) / (selection.numberOfPlayers || 1)

                              return (
                                <Pools
                                  isLast={index === selectionsFilteredByPlayersNum.length - 1}
                                  key={`tt-${selection.priceType?.type}`}
                                  pool={{
                                    amount: selection.numberOfPlayers || 0,
                                    id: 0,
                                    poolName:
                                      selection.priceType?.type !== 'default'
                                        ? language === 'en'
                                          ? selection.priceType?.titleEN || ''
                                          : selection.priceType?.title || ''
                                        : i18n.buy.summary.defaultPriceType,
                                    poolPrice,
                                    serviceFee: selection.fee || 0,
                                    totalPoolPrice: selection.value || 0,
                                    currency: config.app.defaultCurrency,
                                  }}
                                  withCourier={withCourier}
                                  giftWrap={giftWrap}
                                  cancelTicket={false}
                                  numberOfPlayers={selection.numberOfPlayers}
                                  priceType={selection.priceType?.type}
                                />
                              )
                            })}
                        </Grid>
                        <Grid
                          item={true}
                          container={true}
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Typography variant={'body2'}>{i18n.buy.summary.space}</Typography>
                          <Typography variant={'body1'}>{slots.activityName}</Typography>
                        </Grid>
                      </Grid>
                      {!isEmptyObject(selectedProducts) && (
                        <Grid container={true} item={true} spacing={4} direction={'column'}>
                          <Grid item={true}>
                            <Typography variant={'h6'}>
                              {i18n.buy.summary.additionalProducts}
                            </Typography>
                          </Grid>
                          {selectedProducts.map((product, index) => (
                            <Grid container={true} item={true} key={`slots-${product.id}`} xs={12}>
                              <Grid item={true} xs={12}>
                                <Pools
                                  isLast={index === selectedProducts.length - 1}
                                  pool={{
                                    amount: product.amount,
                                    id: product.id,
                                    poolName: product.name,
                                    poolPrice: product.price,
                                    serviceFee: 0,
                                    totalPoolPrice: product.amount * (product.price || 0),
                                    currency: config.app.defaultCurrency,
                                  }}
                                  product={product}
                                  withCourier={withCourier}
                                  giftWrap={giftWrap}
                                  cancelTicket={true}
                                />
                              </Grid>
                              {index !== selectedProducts.length - 1 && (
                                <Grid item={true}>
                                  <Divider />
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {selectedTickets.map((ticket, index) => (
                    <Pools
                      isLast={index === selectedTickets.length - 1}
                      key={`pool-${ticket.poolId}`}
                      pool={{
                        amount: ticket.amount,
                        id: ticket.poolId,
                        poolName: ticket.poolName,
                        poolPrice: ticket.price,
                        serviceFee: ticket.amount * (ticket.serviceFee || 0),
                        totalPoolPrice: ticket.amount * (ticket.price || 0),
                        currency: ticket.currency,
                      }}
                      withCourier={withCourier}
                      giftWrap={giftWrap}
                      cancelTicket={true}
                    />
                  ))}
                  {!slots &&
                    selectedProducts.map((product, index) => (
                      <Pools
                        isLast={index === selectedProducts.length - 1}
                        key={`pool-${product.id}`}
                        pool={{
                          amount: product.amount,
                          id: product.id,
                          poolName: product.name,
                          poolPrice: product.price,
                          serviceFee: 0,
                          totalPoolPrice: product.amount * (product.price || 0),
                          currency: config.app.defaultCurrency,
                        }}
                        product={product}
                        withCourier={withCourier}
                        giftWrap={giftWrap}
                        cancelTicket={true}
                      />
                    ))}
                  {isTransferTicket && !receivedTickets?.pool?.seatsio
                    ? receivedTickets?.pool?.pools.map((currentReceivedPool, index) => {
                        return currentReceivedPool.id === receivedTickets?.currentPoolId ? (
                          <Pools
                            isLast={
                              receivedTickets?.pool?.pools &&
                              index === receivedTickets?.pool?.pools.length - 1
                            }
                            key={`pool-${currentReceivedPool.id}`}
                            pool={{
                              amount: receivedTickets?.ticketNum,
                              id: currentReceivedPool.id,
                              poolName:
                                currentReceivedPool.poolDescription?.title ||
                                i18n.buy.summary.noData,
                              poolPrice: currentReceivedPool.price,
                              serviceFee:
                                receivedTickets?.ticketNum * currentReceivedPool.serviceFee,
                              totalPoolPrice:
                                receivedTickets?.ticketNum * currentReceivedPool.price,
                              currency: config.app.defaultCurrency,
                            }}
                            cancelTicket={true}
                            giftWrap={giftWrap}
                            isTransferTicket={isTransferTicket}
                            withCourier={withCourier}
                            isInsurance={isInsurance}
                          />
                        ) : null
                      })
                    : receivedTickets?.pool?.seatsio?.map((currentReceivedSeat) => {
                        const summedServiceFee = receivedSeatPool
                          ? receivedTickets?.ticketNum * receivedSeatPool?.serviceFee
                          : undefined
                        const summedPoolPrice =
                          receivedTickets?.ticketNum * currentReceivedSeat.ticketTypes[0].price

                        return currentReceivedSeat.ticketTypes[0].poolId ===
                          receivedTickets?.currentPoolId ? (
                          <Pools
                            key={`pool-${currentReceivedSeat.ticketTypes[0].poolId}`}
                            pool={{
                              amount: receivedTickets?.ticketNum,
                              id: currentReceivedSeat.ticketTypes[0].poolId,
                              poolName:
                                currentReceivedSeat.ticketTypes[0].label || i18n.buy.summary.noData,
                              poolPrice: currentReceivedSeat.ticketTypes[0].price,
                              serviceFee: summedServiceFee,
                              totalPoolPrice: summedPoolPrice,
                              currency: config.app.defaultCurrency,
                            }}
                            cancelTicket={true}
                            giftWrap={giftWrap}
                            isTransferTicket={isTransferTicket}
                            withCourier={withCourier}
                            isInsurance={isInsurance}
                            isLast={true}
                          />
                        ) : null
                      })}
                  {selectedShipment && (
                    <>
                      <Grid
                        container={true}
                        direction={'row'}
                        justifyContent={'space-between'}
                        className={muiStyles.insuranceShipment}
                      >
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'}>{i18n.buy.summary.shipment}</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'} align={'right'}>
                            {selectedShipment.price} {currencySymbol[CurrencyISO.PLN]}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <Typography variant={'caption'} color={'secondary'}>
                            {selectedShipment.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {isInsurance && insurancePrice && !basketItems?.length && (
                    <>
                      <Grid
                        container={true}
                        direction={'row'}
                        justifyContent={'space-between'}
                        className={muiStyles.insuranceShipment}
                      >
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'}>{i18n.buy.summary.insurance}</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'} align={'right'}>
                            {getPriceFormatted(insurancePrice)} {currencySymbol[CurrencyISO.PLN]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </section>
                {!basketItems?.length && (
                  <>
                    <p
                      className={cn(
                        styles.totalPrice,
                        !isDefaultTheme && muiStyles.withoutSidePaddings
                      )}
                    >
                      <span className={cn(styles.totalPriceTitle, muiStyles.fontColor)}>
                        {i18n.buy.summary.payment}
                      </span>
                      <span className={styles.totalPriceText}>
                        {!isTransferTicket
                          ? getPriceFormatted(finalPrice)
                          : !receivedTickets?.pool?.seatsio
                          ? getPriceFormatted(receivedPrice())
                          : receivedSeatsPrice}
                        <span>
                          {' '}
                          {getCurrencyFromCode(selectedTickets[0]?.currency) ||
                            currencySymbol[CurrencyISO.PLN]}
                        </span>
                      </span>
                    </p>
                  </>
                )}
              </div>
            </>
          )}
          {/* BASKET STARTS HERE */}
          {basketItems && basketItems.length > 0 && (
            <div
              className={cn(
                styles.content,
                withoutBottomSpace && styles.withoutBottomSpace,
                classes.content,
                styles.basket,
                isBasketPay && styles.basketWithoutMargin,
                isDesktop && muiStyles.root
              )}
            >
              {config.app.showUnfinishedFeatures &&
                (config.theme.isGoing || (!config.theme.isGoing && isDesktop)) && (
                  <div className={styles.yourBasketWrapper}>
                    <BasketButton />
                  </div>
                )}
              <Divider className={muiStyles.dividerPrimary} />
              {items.map((pool) => {
                const { cityName, hour, id, place, rundate, title, pools, isProduct } = pool

                return (
                  <section key={id} className={cn(styles.contentWrapper, muiStyles.root)}>
                    <div className={cn(styles.basketTitle, isProduct && styles.titleProduct)}>
                      <div className={styles.titleWrapper}>
                        {isDesktop && (
                          <>
                            {!isProduct ? (
                              <span className={cn(styles.ticketIcon, classes.ticketIcon)} />
                            ) : (
                              <img
                                src={config.theme.isEmpik ? productIconEmpik : productIcon}
                                className={styles.productIcon}
                                alt={''}
                              />
                            )}
                          </>
                        )}
                        {title}
                      </div>
                    </div>
                    {rundate && (
                      <p className={styles.basketRundate}>
                        {hour ? `${rundate}, ${hour}` : rundate}
                      </p>
                    )}
                    {place && cityName && (
                      <p className={styles.basketPlace}>{`${place}, ${cityName}`}</p>
                    )}
                    <div className={styles.poolsWrapper}>
                      <Pools pool={pool} />
                      {pools.map((element, index) => (
                        <Pools
                          key={`pool-${index}`}
                          isLast={index === pools.length - 1}
                          pool={element}
                        />
                      ))}
                    </div>
                  </section>
                )
              })}
              {isInsurance && insurancePrice && (
                <>
                  <Grid
                    container={true}
                    direction={'row'}
                    justifyContent={'space-between'}
                    className={cn(
                      muiStyles.insuranceShipment,
                      styles.contentWrapper,
                      muiStyles.root
                    )}
                  >
                    <Grid item={true} xs={6}>
                      <Typography variant={'body1'}>
                        {selectedTickets.length
                          ? i18n.buy.summary.insuranceWithCurrentEventTickets
                          : i18n.buy.summary.insurance}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={'body1'} align={'right'}>
                        {getPriceFormatted(insurancePrice)} {currencySymbol[CurrencyISO.PLN]}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <Divider className={muiStyles.dividerPrimary} />
              <p className={styles.totalPrice}>
                <span className={styles.totalPriceTitle}>{i18n.buy.summary.payment}</span>
                <span className={styles.totalPriceText}>
                  {getPriceFormatted(totalPrice())}{' '}
                  <span>
                    {getCurrencyFromCode(items[0]?.currency) || currencySymbol[CurrencyISO.PLN]}
                  </span>
                </span>
              </p>
            </div>
          )}
          {config.theme.isEmpik && isDesktop && !isEmptyObject(selectedProducts) && (
            <span className={cn(styles.triangleWrapper, classes.triangleWrapper)}>
              <Triangle light={true} />{' '}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Summary
