export const firebase = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyA6zsoU8oKEOSXL6D_vHzjizqZdxH5zEGo',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'going-app-dev.firebaseapp.com',
  databaseURL:
    process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    'https://going-app-dev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'going-app-dev',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'going-app-dev.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '222413143847',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:222413143847:web:d5f29f4800c712c3797302',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-46PN352WY7',
}
