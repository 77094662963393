import config from 'config'

import { IFacebookLoginStatus, IFacebookUserData } from './types'

const CONNECTED_STATUS = 'connected'
const FACEBOOK_API_ENDPOINT = '/me'
const FACEBOOK_PERMISSIONS = 'public_profile, email'
const METHOD_GET = 'GET'
const REQUIRED_USER_FIELDS = 'email,short_name,last_name'

class FacebookApi {
  public constructor() {
    if (typeof window !== 'undefined' && !window.FB) {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: config.social.facebookAppId,
          autoLogAppEvents: false,
          version: config.social.facebookApiVersion,
          xfbml: true,
        })
      }
      window.initializeFacebookApi(document, 'script', 'facebook-jssdk')
    }
  }

  public getLoginStatus(): Promise<IFacebookUserData> {
    return new Promise((resolve, reject) => {
      try {
        window.FB.getLoginStatus((user: IFacebookLoginStatus) => {
          if (user.status === CONNECTED_STATUS) {
            resolve(this.fetchData())
          } else {
            resolve(this.loginToFacebook())
          }
        })
      } catch (error: any) {
        reject(new Error(error.message))
      }
    })
  }

  public loginToFacebook(): Promise<IFacebookUserData> {
    return new Promise((resolve, reject) => {
      try {
        const getDataFromFacebook = () => {
          this.fetchData().then((data) => resolve(data))
        }
        window.FB.login(getDataFromFacebook, { scope: FACEBOOK_PERMISSIONS })
      } catch (error: any) {
        reject(new Error(error.message))
      }
    })
  }

  public fetchData(): Promise<IFacebookUserData> {
    return new Promise((resolve, reject) => {
      try {
        window.FB.api(
          FACEBOOK_API_ENDPOINT,
          METHOD_GET,
          { fields: REQUIRED_USER_FIELDS },
          (response: IFacebookUserData) => {
            resolve(response)
          }
        )
      } catch (error: any) {
        reject(new Error(error.message))
      }
    })
  }
}

export default new FacebookApi()
