import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { eventAddPaymentInfo } from 'models/analytics/actions'
import { getBasketData } from 'models/basket/selector'
import { isError } from 'models/errors/selectors'
import { getCustomTerms, getEvent, isCourierAvailable } from 'models/event/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import {
  allRequiredFormsAreSubmitted,
  getSelectedTickets,
  isAnyPoolSelected,
  isAnyPoolSelectedHasDetailed,
  isAnyPoolSelectedHasForm,
  isCourierRequired,
  isDataHydratedTransaction,
  isPoolHasDependency,
} from 'models/pools/selectors'
import getPoolsData from 'models/pools/selectors/getPoolsData'
import { getIsUserPremium } from 'models/premium/selectors'
import {
  getProducts,
  isAnyProductSelected,
  isAnyProductSelectedHasShipmentRequired,
  isAnySelectedProductHasPassType,
} from 'models/products/selectors'
import * as CONSTS from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'
import { getTheme } from 'models/theme/selectors'
import { getIsSelectionValid } from 'models/tickets/selectors'
import { isECardPaymentAvailable, isPayUPaymentAvailable } from 'models/transaction/selectors'

import FormLayout from './FormLayout.component'
import { IFormLayoutFromDispatch, IFormLayoutFromState } from './FormLayout.types'

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  allRequiredFormsAreSubmitted: allRequiredFormsAreSubmitted(state),
  currentBasketData: getBasketData(state),
  customTerms: getCustomTerms(state),
  event: getEvent(state),
  hasDependency: isPoolHasDependency(state),
  isAnyPaymentAvailable: isECardPaymentAvailable(state) || isPayUPaymentAvailable(state),
  isAnyPoolSelected: isAnyPoolSelected(state),
  isAnyPoolSelectedHasDetailed: isAnyPoolSelectedHasDetailed(state),
  isAnyPoolSelectedHasForm: isAnyPoolSelectedHasForm(state),
  isAnyProductSelected: isAnyProductSelected(state),
  isAnySelectedProductHasPassType: isAnySelectedProductHasPassType(state),
  isAnyProductSelectedHasShipmentRequired: isAnyProductSelectedHasShipmentRequired(state),
  isCourierAvailable: isCourierAvailable(state),
  isCourierRequired: isCourierRequired(state),
  isDataHydratedTransaction: isDataHydratedTransaction(state),
  isEmbed: !!getIframeParams(state),
  isError: isError(state),
  isSelectionValid: getIsSelectionValid(state),
  isTransactionLoading: getLoading(CONSTS.TRANSACTION)(state),
  isSeatsIoForm: !!getPoolsData(state).seatsIoPublicKey,
  isUserPremium: getIsUserPremium(state),
  products: getProducts(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
  poolsData: getPoolsData(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IFormLayoutFromDispatch => ({
  eventAddPaymentInfo: (value) => dispatch(eventAddPaymentInfo(value)),
})

export default connect<IFormLayoutFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout)
