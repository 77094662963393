import { connect } from 'react-redux'

import _Store from '@Store'

import { getData, isVirtualPaymentAvailable } from 'models/transaction/selectors'

import RedirectForSale from './RedirectForSale.component'
import { IRedirectForSaleData, IRedirectForSaleProps } from './RedirectForSale.types'

const mapStateToProps = (state: _Store.IState): IRedirectForSaleProps => ({
  data: getData(state) as IRedirectForSaleData,
  isPaymentAvailable: isVirtualPaymentAvailable(state),
})

export default connect<IRedirectForSaleProps, null, {}, _Store.IState>(mapStateToProps)(
  RedirectForSale
)
