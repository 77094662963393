import React, { ReactElement } from 'react'

import { useUser } from 'components/_functional/UserContextProvider'
import { LoginForm } from 'components/auth/LoginForm'
import { SignUpForm } from 'components/auth/SignUpForm'
import { Success } from 'components/auth/Success'
import { UseAnotherMethod } from 'components/auth/UseAnotherMethod'

import { EmailCheckForm } from '../EmailCheckForm'

export const useAuthFormSelector = () => {
  const { firebaseUser, goingMetadata, isUserAnonymous, loading, signInMethods, userEmail } =
    useUser()
  const isPasswordMethod = Boolean(
    signInMethods.length && signInMethods.some((method) => method === 'password')
  )

  const Form = (): ReactElement | null => {
    if ((!firebaseUser || isUserAnonymous) && !userEmail && !signInMethods.length) {
      return <EmailCheckForm />
    }

    if ((!firebaseUser || isUserAnonymous) && userEmail && !signInMethods.length) {
      return <SignUpForm />
    }

    if ((!firebaseUser || isUserAnonymous) && isPasswordMethod) {
      return <LoginForm />
    }

    if (!firebaseUser && !isPasswordMethod && signInMethods.length) {
      // @TODO: add merge sign in methods instead of an alert
      return <UseAnotherMethod />
    }

    if ((firebaseUser || !isUserAnonymous) && !firebaseUser?.email) {
      // TODO: add set emailForm
      return <UseAnotherMethod />
    }

    if (!!firebaseUser && !isUserAnonymous && !!goingMetadata) {
      return <Success />
    }

    // TODO: add login error screen
    return null
  }

  return {
    Form,
    isLoading: loading.isLoading,
  }
}
