import React, { useContext, useEffect, useRef, useState } from 'react'

import { Button, Typography } from '@mui/material'
import cn from 'classnames'
import { base64decode } from 'nodejs-base64'
import { Link, useSearchParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { clearContext } from 'components/Basket/BasketProvider/BasketProvider'
import Products from 'components/Products'
import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import Pools from 'components/buying/Pools'
import AdditionalField from 'components/buying/components/AdditionalField/'
import { IClientLastData } from 'components/buying/components/ClientData/ClientData.types'
import Insurance from 'components/buying/components/Insurance'
import PaymentMethod from 'components/buying/components/PaymentMethod'
import RedirectForCardPayment from 'components/buying/components/RedirectForCardPayment'
import EventDetailsBox from 'components/eventBoxes/Details'
import SummaryBox from 'components/eventBoxes/Summary'
import config from 'config'
import { StorageKeys } from 'constants/StorageKeys'
import autocompleteForm from 'misc/helpers/autocompleteForm'
import convertBinaryToUnicode from 'misc/helpers/convertBinaryToUnicode'
import scrollTo from 'misc/helpers/scrollTo'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { LocalStorage } from 'services/LocalStorage'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IFormsRef } from '../Forms/Forms.types'
import RedirectForFreePayment from '../RedirectForFreePayment'
import * as CONST from './../../constants/constants'
import ClientData from './..//ClientData'
import Agreements from './../Agreements'
import AuthId from './../AuthId'
import EmpikCardNumber from './../EmpikCardNumber'
import Forms from './../Forms'
import PickUpWay from './../PickUpWay'
import RedirectForECard from './../RedirectForECard'
import RedirectForPayU from './../RedirectForPayU'
import RedirectForSale from './../RedirectForSale'
import classes from './FormLayout.classes'
import styles from './FormLayout.module.scss'
import { IFormLayoutProps } from './FormLayout.types'

const CLIENT_DATA_ID = 'client-data-id'

const FormLayout = ({
  allRequiredFormsAreSubmitted,
  currentBasketData,
  customTerms,
  handleSubmit,
  handleTokenizeCard,
  hasDependency,
  isAnyPaymentAvailable,
  isAnyPoolSelected,
  isAnyProductSelected,
  isBasketPay,
  isCourierAvailable,
  isCourierRequired,
  isDataHydratedTransaction,
  isEmbed,
  isError,
  isProductsPay,
  isTransactionLoading,
  isUserPremium,
  isSelectionValid,
  isValid,
  products,
  values,
  selectedTickets,
  selectedTheme,
  setFieldValue,
  isAnyPoolSelectedHasDetailed,
  isAnyPoolSelectedHasForm,
  isAnySelectedProductHasPassType,
  isSeatsIoForm,
  touched,
  cardPaymentForm,
  payuInstance,
  eventAddPaymentInfo,
  event,
  isAnyProductSelectedHasShipmentRequired,
  poolsData,
  status,
}: IFormLayoutProps) => {
  const { isDesktop, isMobile, isTablet } = useBreakpoints()
  const { i18n, getExternalKey } = useDictionary()
  const [searchParams] = useSearchParams()
  const isWebview = searchParams.has('isWebview')
  const paramData = searchParams.has('data') ? base64decode(searchParams.get('data')!) : null
  const { dialog, isLoggedIn } = useUser()

  useEffect(() => {
    setDependencyCode(Boolean(values.discount?.length))
  }, [values.discount])

  const [isFormInvalid, setFormInvalid] = useState(false)
  const [isDependencyCode, setDependencyCode] = useState(false)
  const formsRef = useRef<IFormsRef | null>(null)

  const GIFT_CARDS_RUN_DATES = [2353795, 2357465]
  const isGiftCard = GIFT_CARDS_RUN_DATES.includes(poolsData?.id || 0)

  const label = getExternalKey('buyButtonFinal') || i18n.buy.formLayout.buyAndPay

  const showDependencyInput =
    (isAnyPoolSelected && hasDependency && isDependencyCode) ||
    (isAnyPoolSelected && !hasDependency)

  const isDisabled =
    ((!isAnyPoolSelected || isTransactionLoading || isAnyPaymentAvailable || !isSelectionValid) &&
      !isBasketPay &&
      !isAnyProductSelected) ||
    isError

  const isDefaultTheme = selectedTheme === 'default'

  const showAddressForm = values.pickUpWay === CONST.PICK_UP_WAY_POST

  const BagState = useContext(clearContext)

  const basketItems = BagState?.basketItems || []

  const isDefaultCurrency = ![...currentBasketData, ...basketItems].some(
    (item) => item.currency !== config.app.defaultCurrency
  )

  const showInsurance = isDefaultCurrency && config.app.onlineSale && !isProductsPay && !isEmbed

  const isAnyBasketItemHasCourierRequired = BagState?.basketItems.find(
    (item) => item.courierRequired
  )?.courierRequired

  const isAnyBasketItemHasDetailedRequired = BagState?.basketItems.some(
    (item) => item.additionalFields
  )

  const showCartButtons =
    !isEmbed &&
    (!isAnyPoolSelectedHasForm || (isAnyPoolSelectedHasForm && allRequiredFormsAreSubmitted))

  useEffect(() => {
    if (!isDefaultCurrency) {
      setFieldValue('prepaidCheckbox', false)
      setFieldValue('discountCheckbox', false)
    }
  }, [isDefaultCurrency])

  useEffect(() => {
    setFieldValue('customTerms', {})
  }, [BagState?.basketItems])

  const handleFormSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    const validationStatus = status ? status.isValid : true
    setFormInvalid(!isValid || !validationStatus)
    e?.preventDefault()

    if (validationStatus) {
      const paymentMethod = config.app.onlineSale ? values.paymentMethod?.type : undefined

      if (isAnyPoolSelectedHasForm && formsRef.current) {
        e?.preventDefault()
        formsRef.current.submitForm()
      } else {
        handleSubmit(e)
      }

      if (isValid) {
        eventAddPaymentInfo({
          buttonLabel: label,
          paymentMethod,
        })
        const formValues: IClientLastData = {
          email: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          customTerms: values.customTerms,
          terms: values.terms,
        }

        LocalStorage.set(StorageKeys.BuyForm, formValues)
      }
    }
  }

  /*
   *
   * below: If data param comes from new mobile app (isWebview === true) we need to convert binary string to unicode
   * If it comes from the expo form, we don't do that - otherwise the app would crash (and vice versa).
   *
   */
  const unicodeParamData = !!paramData
    ? isWebview
      ? convertBinaryToUnicode(paramData)
      : paramData
    : '{}'
  const autoCompleteData = JSON.parse(unicodeParamData)
  const shouldShowBasketButton: boolean =
    !!paramData && autoCompleteData.showBasket !== undefined ? autoCompleteData.showBasket : true
  const autocomplete = () => autocompleteForm(!!paramData, customTerms, setFieldValue)

  const handleAddToBasket = () => {
    if (BagState && currentBasketData) {
      currentBasketData.forEach((basketItem) => {
        if (basketItem.hasDependency) {
          BagState.addToBag({
            ...basketItem,
            artists: event?.artists,
            partnerId: event?.partnerId,
            partnerName: event?.partnerName,
            tags: event?.tags,
            placeFriendly: event?.placeFriendly,
            placeId: event?.placeId,
            dependencyCode: Boolean(values.discount.length) ? values.discount : '',
          })
        } else {
          BagState.addToBag({
            ...basketItem,
            artists: event?.artists,
            partnerId: event?.partnerId,
            partnerName: event?.partnerName,
            tags: event?.tags,
            placeFriendly: event?.placeFriendly,
            placeId: event?.placeId,
          })
        }
      })
      BagState.handleUserData(values)
    }
  }

  let step = 1

  return (
    <div className={cn(styles.buyForm, classes.buyForm)}>
      <div className={cn(styles.twoColumns, classes.twoColumns)}>
        {(isMobile || isTablet) && (
          <EventDetailsBox isBasketPay={isBasketPay} isProductsPay={isProductsPay} />
        )}

        {isDesktop && (
          <div className={cn(styles.fixedBox, classes.fixedBox)}>
            <SummaryBox
              isBasketPay={isBasketPay}
              isProductsPay={isProductsPay}
              isInsurance={values.isInsurance}
            />
          </div>
        )}

        <div className={cn(styles.form, classes.form)}>
          {!isBasketPay && (
            <>
              {!isProductsPay ? (
                <Pools number={step++} />
              ) : (
                <Products number={step++} isProductPay={isProductsPay} />
              )}

              {showCartButtons && (
                <div className={styles.buttonsWrapper}>
                  <a
                    onClick={(e) => scrollTo(e, CLIENT_DATA_ID)}
                    className={cn(
                      styles.buttonWrapper,
                      (!config.features.basket || isSeatsIoForm) && styles.fullWidth
                    )}
                  >
                    <button
                      type="button"
                      className={cn(
                        styles.buyButton,

                        classes.buyButton,
                        showDependencyInput ? styles.selectedPool : styles.noSelectedPool
                      )}
                    >
                      {showDependencyInput && i18n.buy.formLayout.buttonPay}
                    </button>
                  </a>

                  {!isAnyProductSelected &&
                    (shouldShowBasketButton || !paramData) &&
                    ((config.features.basket && !isSeatsIoForm) || isWebview) && (
                      <Link
                        to={routes[Route.cart]}
                        className={cn(
                          styles.buttonWrapper,
                          showDependencyInput ? styles.selectedPool : styles.noSelectedPool
                        )}
                      >
                        <button
                          type="button"
                          className={cn(
                            styles.buyButton,
                            classes.buyButton,
                            styles.basketButton,
                            showDependencyInput ? styles.selectedPool : styles.noSelectedPool
                          )}
                          disabled={isDisabled}
                          onClick={handleAddToBasket}
                        >
                          {showDependencyInput && i18n.buy.formLayout.buttonAdd}
                        </button>
                      </Link>
                    )}
                </div>
              )}
            </>
          )}

          {config.features.userLogin && !isLoggedIn && !isWebview && !isEmbed && (
            <SectionContainer
              title={`${step++}. ${i18n.auth.loginOrRegister}`}
              smallTitle
              container
            >
              <Flex gap={3}>
                <Typography children={i18n.auth.toKeepTickets} />
                <Button
                  variant={'contained'}
                  children={i18n.auth.login}
                  onClick={dialog.show}
                  fullWidth
                />
                <Button
                  onClick={() =>
                    document.getElementById(CLIENT_DATA_ID)?.scrollIntoView({ behavior: 'smooth' })
                  }
                  variant={'outlined'}
                  children={i18n.auth.withoutLogin}
                  fullWidth
                />
              </Flex>
            </SectionContainer>
          )}

          <form onSubmit={handleFormSubmit}>
            {config.app.onlineSale && (
              <div id={CLIENT_DATA_ID}>
                {!isDataHydratedTransaction && (
                  <ClientData
                    isBasketPay={isBasketPay}
                    isDisabled={isUserPremium || isDisabled}
                    number={step++}
                    showPremiumCardInput={values.userHavePremiumCheckbox}
                    showInvoiceForm={values.invoiceCheckbox}
                    setFieldValue={setFieldValue}
                    autocompleteForm={autocomplete}
                    isBasketItems={
                      !!BagState?.basketItems?.length && BagState?.basketItems?.length > 0
                    }
                    handleTokenizeCard={handleTokenizeCard}
                    payuInstance={payuInstance}
                    isProductsPay={isProductsPay}
                  />
                )}
                {isAnyPoolSelectedHasForm && (
                  <Forms
                    ref={formsRef}
                    number={step++}
                    firstName={values.firstname}
                    lastName={values.lastname}
                    handleSubmit={handleSubmit}
                  />
                )}

                {!isGiftCard && showInsurance && (
                  <Insurance
                    isDisabled={isDisabled}
                    values={values}
                    step={step++}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    isBasket={isBasketPay || !!BagState?.basketItems.length}
                  />
                )}
                {!!products.length && !isProductsPay && !isBasketPay && (
                  <Products
                    number={step++}
                    isProductPay={isProductsPay}
                    isDisabled={isDisabled}
                    userData={{
                      firstName: values.firstname,
                      lastName: values.lastname,
                    }}
                    isInvalid={isFormInvalid}
                  />
                )}

                <PickUpWay
                  courierAvailable={isCourierAvailable}
                  courierRequired={
                    isAnyBasketItemHasCourierRequired ||
                    isCourierRequired ||
                    (!isAnySelectedProductHasPassType && isAnyProductSelectedHasShipmentRequired)
                  }
                  isDisabled={isDisabled}
                  number={step++}
                  showAddressForm={showAddressForm}
                  isProduct={isAnyProductSelectedHasShipmentRequired}
                />
                <Agreements
                  isBasketPay={isBasketPay}
                  isDisabled={isDisabled}
                  isDetailedPurchase={
                    isAnyPoolSelectedHasDetailed || isAnyBasketItemHasDetailedRequired
                  }
                  number={step++}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
            )}
            {!config.app.onlineSale && (
              <div id={CLIENT_DATA_ID}>
                <EmpikCardNumber
                  isDisabled={isDisabled}
                  number={step++}
                  open={values.userHavePremiumCheckbox}
                  withoutHeader={false}
                />
                {config.app.showUnfinishedFeatures && isCourierRequired && (
                  <PickUpWay
                    courierAvailable={isCourierAvailable}
                    courierRequired={
                      isAnyBasketItemHasCourierRequired ||
                      isCourierRequired ||
                      isAnyProductSelectedHasShipmentRequired
                    }
                    isDisabled={isDisabled}
                    number={step++}
                    showAddressForm={showAddressForm}
                    isProduct={isAnyProductSelectedHasShipmentRequired}
                  />
                )}
                {config.app.showUnfinishedFeatures &&
                  selectedTickets.map((ticket) => {
                    let additionalFieldStep = 0

                    return [...Array(ticket.amount)].map((value, index) => {
                      if (ticket.additionalFields) {
                        return (
                          <div className={styles.onsiteAdditionalFields}>
                            <AdditionalField
                              key={`additional_${index}`}
                              ticket={{
                                id: ticket.poolId,
                                poolName: ticket.poolName,
                              }}
                              index={index}
                              step={additionalFieldStep++}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          </div>
                        )
                      }
                    })
                  })}
                <AuthId isDisabled={isDisabled} number={step++} />
              </div>
            )}

            {config.app.onlineSale && !isEmbed && (
              <PaymentMethod
                isDisabled={isDisabled}
                isBasketPay={isBasketPay}
                step={step++}
                cardPaymentForm={cardPaymentForm}
              />
            )}

            {(isMobile || isTablet) && (
              <div className={cn(styles.mobileSummary, classes.mobileSummary)}>
                <h3 className={cn(styles.header, classes.header)}>{i18n.buy.formLayout.summary}</h3>
                <SummaryBox isBasketPay={isBasketPay} isInsurance={values.isInsurance} />
              </div>
            )}

            <div className={styles.buyWrapper}>
              {isFormInvalid && (
                <div className={styles.errorContainer}>{i18n.buy.formLayout.formInvalid}</div>
              )}
              {isDefaultTheme ? (
                <button
                  className={cn(styles.buyButton, classes.buyButton, styles.halfButton)}
                  data-test-id={'form-layout-buyAndPay'}
                  type="submit"
                  disabled={isDisabled}
                  id="payButton"
                >
                  {label}
                </button>
              ) : (
                <Button
                  data-test-id={'form-layout-buyAndPay'}
                  type="submit"
                  disabled={isDisabled}
                  id="payButton"
                  size={'large'}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth={true}
                >
                  {label}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      <RedirectForCardPayment />
      <RedirectForECard />
      <RedirectForFreePayment />
      <RedirectForPayU />
      <RedirectForSale />
    </div>
  )
}

export default FormLayout
