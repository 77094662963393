import React from 'react'

import SimpleLoading from 'components/reusable/SimpleLoading'
import TextBlock from 'components/reusable/TextBlock'

import { ITextBlockProps } from './TextBlock.types'

const TextBlockBrick = ({
  color,
  data,
  isLoading,
  bgColor,
  id,
  compositionIdentifier,
}: ITextBlockProps) => {
  if (isLoading) {
    return <SimpleLoading />
  }

  return (
    <div id={id}>
      {data.map((item, index) => (
        <TextBlock
          color={color}
          key={index}
          {...item}
          sectionBgColor={bgColor}
          compositionIdentifier={compositionIdentifier}
          brickId={id}
        />
      ))}
    </div>
  )
}

export default TextBlockBrick
