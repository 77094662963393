import { createSelector } from 'reselect'

import _Store from '@Store'

import { ITicketReceiveData, ITicketsReducer } from '../types'
import get from './get'

const getReceivedTicketData = createSelector<
  _Store.IState,
  ITicketsReducer,
  ITicketReceiveData | null
>([get], (tickets) => tickets.receivingTicket)

export default getReceivedTicketData
