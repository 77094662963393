import React from 'react'

import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { Formik } from 'formik'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './PassDataForm.styles'
import { IPassDataFormPayload } from './PassDataForm.types'

export const PassDataForm = () => {
  const styles = useStyles({})
  const { i18n } = useDictionary()

  const submitForm = (payload: IPassDataFormPayload) => {
    window.location.assign(
      prepareRoute({
        route: Route.myPassDetails,
        params: {
          identity: payload.identity,
          email: payload.email,
        },
      })
    )
  }

  return (
    <Paper sx={styles.formWrapper}>
      <Typography variant={'h2'}>{i18n.passManagement.pageTitle}</Typography>
      <Typography variant={'body2'} sx={{ mt: 5, mb: 2 }}>
        {i18n.passManagement.passDataForm.usePassData}
      </Typography>
      <Formik initialValues={{ identity: '', email: '' }} onSubmit={submitForm}>
        {({ values, handleSubmit, handleBlur, handleChange, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextField
                  name={'identity'}
                  helperText={!!(touched.identity && errors.identity) && errors.identity}
                  value={values.identity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={i18n.passManagement.passDataForm.placeholder.identity}
                  variant={'outlined'}
                  fullWidth={true}
                  error={!!(touched.identity && errors.identity)}
                  autoCapitalize={'false'}
                  autoCorrect={'false'}
                  autoComplete={'off'}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  name={'email'}
                  helperText={!!(touched.email && errors.email) && errors.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={i18n.passManagement.passDataForm.placeholder.email}
                  variant={'outlined'}
                  fullWidth={true}
                  error={!!(touched.email && errors.email)}
                  inputProps={{ inputMode: 'email' }}
                  autoCapitalize={'false'}
                  autoCorrect={'false'}
                  autoComplete={'off'}
                />
              </Grid>
              <Grid item={true}>
                <Button
                  variant={'contained'}
                  size={'large'}
                  color={'primary'}
                  type={'submit'}
                  sx={styles.button}
                >
                  {i18n.passManagement.passDataForm.buttonSubmit}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Paper>
  )
}
