import { createSelector } from 'reselect'

import _Store from '@Store'

import { IPoolNormalized, IPoolsSelectedTicketsReducer } from './../types'
import getPools from './getPools'
import getSelectedTickets from './getSelectedTickets'

const isDataHydratedTransaction = createSelector<
  _Store.IState,
  IPoolNormalized[],
  IPoolsSelectedTicketsReducer,
  boolean
>(
  [getPools, getSelectedTickets],
  (pools: IPoolNormalized[], selectedTickets: IPoolsSelectedTicketsReducer) => {
    return selectedTickets
      .map((selectedTicket) => {
        const currentPool =
          pools.find((pool) => pool.id === selectedTicket.poolId) || ({} as IPoolNormalized)

        return currentPool.hasTransactionDataHydrator
      })
      .includes(true)
  }
)

export default isDataHydratedTransaction
