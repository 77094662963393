import React from 'react'

import cn from 'classnames'
import { Carousel } from 'react-responsive-carousel'

import slugifyString from 'misc/helpers/slugifyString'

import styles from './CarouselFixed.module.scss'
import { ICarouselFixedProps } from './CarouselFixed.types'
import './CarouselNative.module.scss'
import CarouselSlide from './components/CarouselSlide'

const ANIMATION_TRANSITION_TIME = 500

const CarouselFixed = ({
  images,
  displayTime = 5000,
  className,
  GAclass,
  compositionIdentifier,
  brickId,
}: ICarouselFixedProps) => {
  const showDots = images.length > 1

  const slides = images.map((slide) => (
    <CarouselSlide
      image={slide}
      key={slide.alt}
      GAclass={
        GAclass || `${compositionIdentifier}-${brickId}-${slugifyString(slide.title || slide.alt)}`
      }
    />
  ))

  return (
    <Carousel
      autoPlay={true}
      children={slides}
      dynamicHeight={true}
      emulateTouch={true}
      infiniteLoop={true}
      interval={displayTime}
      showArrows={showDots}
      showIndicators={showDots}
      showStatus={false}
      showThumbs={false}
      swipeable={true}
      transitionTime={ANIMATION_TRANSITION_TIME}
      useKeyboardArrows={false}
      className={cn(styles.carousel, className)}
    />
  )
}

export default CarouselFixed
