import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import Pool from 'components/Rundate/components/Pools/Pool/Pool.component'
import {
  IPoolFromDispatch,
  IPoolOwnProps,
} from 'components/Rundate/components/Pools/Pool/Pool.types'
import { eventProceed } from 'models/analytics/actions'
import { selectPool, selectTicket } from 'models/pools/actions'

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPoolFromDispatch => ({
  selectPool: (eventSlug, rundateSlug) => dispatch(selectPool({ eventSlug, rundateSlug })),
  selectTicket: (
    poolId,
    amount,
    poolName,
    currency,
    paymentMethods,
    price,
    serviceFee,
    additionalFields,
    forms
  ) =>
    dispatch(
      selectTicket({
        additionalFields,
        amount,
        currency,
        forms,
        poolId,
        poolName,
        paymentMethods,
        price,
        serviceFee,
      })
    ),
  eventProceed: (textDisplayed) => dispatch(eventProceed(textDisplayed)),
})

export default connect<void, IPoolFromDispatch, IPoolOwnProps, _Store.IState>(
  null,
  mapDispatchToProps
)(Pool)
