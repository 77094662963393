import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { generateIdempotencyKey } from 'models/happening/actions'
import { selectProduct } from 'models/products/actions'
import { getSelectedProducts } from 'models/products/selectors'
import { getTheme } from 'models/theme/selectors'

import Buttons from './Buttons.component'
import { IButtonsFromDispatch, IButtonsFromState } from './Buttons.types'

const mapStateToProps = (state: _Store.IState): IButtonsFromState => ({
  selectedProducts: getSelectedProducts(state),
  selectedTheme: getTheme(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IButtonsFromDispatch => {
  return {
    generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
    selectProducts: (product, amount) => dispatch(selectProduct({ product, amount })),
  }
}

export default connect<IButtonsFromState, IButtonsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Buttons)
