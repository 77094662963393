import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { getParams } from 'models/internalRouter/selectors'
import { ILandingPageResponse } from 'services/$landing-api/types'
import { isActionOf } from 'typesafe-actions'

import { getLandingPage, landingPageMounted } from './../actions'

export const getLandingPageWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(landingPageMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return [getLandingPage.request()]
    })
  )
}

export const fetchLandingPageWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { landingPageApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getLandingPage.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as RouteParams<Route.landingPage>

      if (params) {
        return from$(landingPageApi.getLandingPage(params.slug)).pipe(
          mergeMap$((data: ILandingPageResponse) => [
            getLandingPage.success({
              key: data.landingSlug,
              landingPage: data,
            }),
          ]),
          catchError$((error: Error) => of$(getLandingPage.failure(error)))
        )
      }

      return EMPTY$
    }),
    catchError$((error: Error) => of$(getLandingPage.failure(error)))
  )
}
