import { createSelector } from 'reselect'

import _Store from '@Store'

import { IGetTransactionInfoSuccessPayload, ITransactionReducer } from './../types'
import get from './get'

const getTransactionInfo = createSelector<
  _Store.IState,
  ITransactionReducer,
  IGetTransactionInfoSuccessPayload
>([get], (transaction) => transaction.transactionInfo || ({} as IGetTransactionInfoSuccessPayload))

export default getTransactionInfo
