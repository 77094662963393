import { ThemeOptions } from '@mui/material'

import colors from './colors/jumpcity'

const jumpcity = (): ThemeOptions => ({
  spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160],
  typography: {
    fontFamily: [
      'Point',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '4rem',
      fontWeight: 500,
      lineHeight: '7.8125rem',
      letterSpacing: -1.5,
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 700,
      lineHeight: '4.5rem',
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '3.5rem',
      letterSpacing: 0,
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: 600,
      lineHeight: '2.5rem',
      letterSpacing: 0.25,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: 0,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.375rem',
      letterSpacing: 0.1,
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.6875rem',
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5625rem',
      letterSpacing: 0.15,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.46,
      lineHeight: '1.625rem',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
      letterSpacing: 0.4,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '2rem',
      letterSpacing: 1,
    },
  },
  border: {
    color: colors.primary.border,
    radius: '0',
    style: 'solid',
    width: '0.1875rem',
  },
  palette: {
    action: {
      disabled: colors.actions.disabledText,
      disabledBackground: colors.actions.disabledBackground,
    },
    background: {
      default: colors.primary.contrastText,
      paper: colors.primary.contrastText,
    },
    other: {
      focused: colors.primary.main,
      stroke: colors.other.stroke,
      gray: colors.other.gray,
    },
    primary: {
      contrastText: colors.primary.contrastText,
      dark: colors.primary.dark,
      light: colors.primary.light,
      main: colors.primary.main,
    },
    secondary: {
      contrastText: colors.secondary.contrastText,
      dark: colors.secondary.dark,
      light: colors.secondary.light,
      main: colors.secondary.main,
    },
    text: {
      disabled: colors.text.disabled,
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
    gradient: {
      first: colors.gradient.first,
      second: colors.gradient.second,
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: {
          fontSize: 20,
          fontWeight: 800,
          letterSpacing: 0.46,
          lineHeight: '26px',
        },
        sizeSmall: {
          fontSize: 14,
          fontWeight: 700,
          letterSpacing: 0.4,
          lineHeight: '16px',
        },
        textPrimary: {
          WebkitBackgroundClip: 'text',
          backgroundImage: colors.primary.contrastText,
          display: 'inline-block',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            border: '3px solid',
            borderColor: colors.primary.border,
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            background: colors.primary.main,
            color: colors.text.primary,

            '&:disabled': {
              background: colors.actions.disabledBackground,
              borderColor: colors.actions.disabledBackground,
            },
            '&:hover': {
              backgroundColor: colors.secondary.main,
              color: colors.primary.contrastText,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            background: colors.secondary.main,

            '&:disabled': {
              background: colors.actions.disabledBackground,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: colors.primary.border,
            borderWidth: 3,
            color: colors.text.primary,

            '&:disabled': {
              borderColor: colors.text.disabled,
            },
            '&:hover': {
              backgroundColor: colors.primary.main,
              borderColor: colors.primary.border,
              borderWidth: 3,
            },
          },
        },
        {
          props: { variant: 'outlined', size: 'large' },
          style: {
            padding: '8px 22px',
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: colors.primary.main,
          },
        },
      ],
    },
    MuiInputLabel: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: colors.primary.contrastText,
            paddingLeft: 4,
            paddingRight: 4,
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: '3px !important',
          borderColor: colors.secondary.main,
        },
        root: {
          '&$focused $notchedOutline': {
            borderImageSlice: 1,
            borderImageSource: colors.primary.main,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          height: 24,
          width: 24,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: '2.1875rem',
        },
        root: {
          fontSize: '1.5rem',
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderColor: colors.primary.border,
          borderRadius: 0,
          borderStyle: 'solid',
          borderWidth: '0.1875rem',
          maxHeight: 'none',
          maxWidth: 396,
          padding: '0 16px',
          paddingBottom: 16,
          width: '100%',

          '& .MuiDayCalendar-monthContainer': {
            paddingTop: 32,
          },

          '& .MuiPickersCalendarHeader-root': {
            padding: 0,
          },

          '& .MuiPickersCalendarHeader-labelContainer': {
            marginLeft: 16,
            '& .MuiPickersCalendarHeader-label': {
              textTransform: 'capitalize',
            },
          },
          '& .MuiPickersSlideTransition-root': {
            minHeight: 292,
            position: 'static',
          },

          '& .MuiDayCalendar-weekDayLabel': {
            color: colors.text.primary,
            fontSize: 14,
            fontWeight: '500',
            width: '3rem',
          },

          '& .MuiPickersDay-root': {
            borderRadius: 0,
            fontSize: 16,
            fontWeight: '500',
            height: '3rem',
            width: '3rem',
            '&.Mui-selected': {
              backgroundColor: colors.primary.main,
            },
          },
        },
      },
    },
  },
})

export default jumpcity
