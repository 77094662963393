import React from 'react'

import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { Box, Card, CardContent, Grid, IconButton, Link, Typography, useTheme } from '@mui/material'
import cn from 'classnames'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import useSWR from 'swr'

import { IArtistCardProps } from 'components/Rundate/components/ArtistCard/ArtistCard.types'
import ImageFixed from 'components/reusable/ImageFixed'
import SimpleLoading from 'components/reusable/SimpleLoading'
import { CloudinaryPrefixes } from 'constants/Cloudinary'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { fetchCloudinaryList } from 'services/Api/Cloudinary'

import { FavoritesTypes } from '../../../../constants/Favorites'
import { useFavoritesContext } from '../../../favorites/FavoritesProvider'
import { useEditFavorites } from '../../../favorites/FavoritesProvider/useEditFavorites'
import useStyles from './ArtistCard'

export const ArtistCard = ({ artist, reversed }: IArtistCardProps) => {
  const { data: images, isValidating } = useSWR(
    !!artist.slug && { slug: artist.slug, prefix: CloudinaryPrefixes.ARTIST },
    fetchCloudinaryList
  )
  const { isMobile } = useBreakpoints()
  const muiStyles = useStyles(!!reversed)()
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isArtistInFavs = isItemInFavs(artist.id, FavoritesTypes.Artists)
  const artistLink = prepareRoute({
    route: Route.artist,
    params: { slug: artist.slug },
  })
  const trimFactor = 24
  const trimmedName =
    artist.name.length > trimFactor ? `${artist.name.slice(0, trimFactor)}...` : artist.name

  if (isValidating) return <SimpleLoading />

  const handleFollow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    handleEditFavorites({
      isFollowed: isArtistInFavs,
      type: FavoritesTypes.Artists,
      externalId: artist.id,
      itemName: artist.name,
    })
  }

  const FollowIcon = (
    <IconButton
      onClick={handleFollow}
      children={isArtistInFavs ? <Favorite /> : <FavoriteBorder />}
    />
  )

  if (isMobile)
    return (
      <Card style={{ borderRadius: 16 }}>
        <Link href={artistLink}>
          <ImageFixed src={images?.[0] || ''} transformation={{ width: 300, height: 260 }} />
        </Link>
        <CardContent className={muiStyles.cardContent}>
          <Typography variant={'body1'} children={trimmedName} />
          {FollowIcon}
        </CardContent>
      </Card>
    )

  return (
    <Grid container={true} item={true} xs={isMobile ? 12 : 6}>
      <Link className={cn(muiStyles.root, reversed && muiStyles.reversed)} href={artistLink}>
        <Grid item={true} container={true} xs={6}>
          <ImageFixed
            src={images?.[0] || ''}
            transformation={{ width: 300, height: 260 }}
            children={<Box className={muiStyles.followButton}>{FollowIcon}</Box>}
          />
        </Grid>
        <Grid
          item={true}
          container={true}
          alignItems={'flex-end'}
          xs={6}
          className={muiStyles.title}
        >
          <Typography
            variant={isMobile ? 'body1' : 'h5'}
            children={trimmedName}
            className={muiStyles.titleText}
          />
        </Grid>
      </Link>
    </Grid>
  )
}

export default ArtistCard
