import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const errorModalHeight = 150 // TODO: this will work 95% times, but calculate this properly later

const useStyles = (embedDialogPosition: number, isEmbed: boolean, isDesktop: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.paper,
        ...(isEmbed && {
          position: 'fixed !important' as 'fixed',
          top: `${embedDialogPosition - errorModalHeight}px`,
        }),
      },
      content: {
        padding: theme.spacing(4),

        '&:first-child': {
          paddingTop: theme.spacing(4),
        },
      },
      paper: {
        margin: `0 ${theme.spacing(3)}`,
        backgroundColor:
          theme.palette.background.default !== 'transparent'
            ? theme.palette.background.default
            : '#ffffff',
        boxShadow: '0.25rem 0.25rem 1.5rem 0rem rgba(66, 68, 90, 0.2)',
        WebkitBoxShadow: '0.25rem 0.25rem 1.5rem 0rem rgba(66, 68, 90, 0.2)',
        MozBoxShadow: '0.25rem 0.25rem 1.5rem 0rem rgba(66, 68, 90, 0.2)',
      },
    })
  )

export default useStyles
