import { ISlotPrice } from 'models/happening/types'

export const getSelectedPriceType = (
  priceTypeName: string,
  selectedSlotPriceTypes: ISlotPrice[] | undefined
) =>
  selectedSlotPriceTypes?.find((price) => price.type === priceTypeName && price) || {
    type: priceTypeName,
    value: 0,
  }
