import queryString from 'query-string'
import { Route, routes } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'
import { EMPTY, from as from$, fromEvent, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { StorageKeys } from 'constants/StorageKeys'
import { getLocation } from 'models/connectedRouter/selectors'
import { getEvent } from 'models/event/actions'
import { IGetEventByIdSuccessPayload } from 'models/event/types'
import { loadImages } from 'models/images/actions'
import { getPools } from 'models/pools/actions'
import { changeTheme } from 'models/theme/actions'
import { LocalStorage } from 'services/LocalStorage'
import { isActionOf } from 'typesafe-actions'

import {
  addToGlobalBasket,
  captureIframeEvent,
  clearEmbedInitTime,
  embedLoaded,
  getRundateSlug,
  initEmbed,
  locationChange,
  navigate,
  redirectParentTo,
  resizeEmbed,
  setBasketTheme,
} from './../actions'
import { EmbedActions, allowedActions } from './../constants/embedActions'
import { getModule } from './../selectors'

const WAITING_FOR_PONG = 5000
// tslint:disable-next-line:prefer-const
let redirectWhenNotEmbed: NodeJS.Timeout

export const initEmbeddedPage: _Store.IEpic = (action$, state$, { iframeProvider }) => {
  return action$.pipe(
    filter$(isActionOf(initEmbed)),
    tap$(
      () =>
        (redirectWhenNotEmbed = setTimeout(
          () => window.location.replace('/error'),
          WAITING_FOR_PONG
        ))
    ),
    mergeMap$((action) => {
      fromEvent(window, 'message').subscribe((event) => {
        iframeProvider.addEventListenerMethod(event as MessageEvent, action.payload)
      })

      iframeProvider.pingMethod()

      return EMPTY
    })
  )
}

export const clearEmbedInitTimeWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(clearEmbedInitTime)),
    tap$(() => clearTimeout(redirectWhenNotEmbed)),
    mergeMap$(() => EMPTY)
  )
}

export const establishIframeConnection: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$((action) => action.type === EmbedActions.PONG),
    tap$(() => clearTimeout(redirectWhenNotEmbed)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const module = getModule(state)
      const search = queryString.parse(getLocation(state).search)

      if (
        typeof search.transactionId === 'string' &&
        module !== Route.paymentFail &&
        module !== Route.paymentSuccess
      ) {
        const params = { transactionId: search.transactionId }
        if (search.error) {
          return of$(
            navigate({
              to: prepareRoute({ route: Route.paymentFail, params }) + `?error=${search.error}`,
            })
          )
        } else {
          return of$(navigate({ to: prepareRoute({ route: Route.paymentSuccess, params }) }))
        }
      }

      return of$(embedLoaded())
    })
  )
}

export const loadFormDataForEmbed: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$((action) => action.type === EmbedActions.PONG),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search = queryString.parse(getLocation(state).search)
      const searchFormData = search.data as string

      if (searchFormData) {
        LocalStorage.set(StorageKeys.BuyForm, { values: atob(searchFormData) })
      }

      return EMPTY
    })
  )
}

export const captureIframeEventWhenPostMessage: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(captureIframeEvent)),
    mergeMap$((action) => {
      const { type, payload } = action.payload
      const isAllowedAction = allowedActions.includes(type)

      if (isAllowedAction) {
        return of$({ type: EmbedActions[type], payload })
      }

      return EMPTY
    })
  )
}

export const runResizeEmbed: _Store.IEpic = (action$, state$, { iframeProvider }) => {
  return action$.pipe(
    filter$(isActionOf(resizeEmbed)),
    mergeMap$((action) => {
      iframeProvider.runResizeMethod((action.payload || 100) + 'px')

      return EMPTY
    })
  )
}

export const redirectParentToWhenAction: _Store.IEpic = (action$, state$, { iframeProvider }) => {
  return action$.pipe(
    filter$(isActionOf(redirectParentTo)),
    mergeMap$((action) => {
      iframeProvider.runRedirectParentMethod(action.payload)

      return EMPTY
    })
  )
}

export const redirectEventWhenLocationChange: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] === routes[Route.pinBuyLegacy].split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      return of$(getRundateSlug.request(Number(getLocation(state).pathname.split('/')[2])))
    })
  )
}

export const getRundateSlugWhenRequest: _Store.IEpic = (action$, state$, { eventsApi }) => {
  return action$.pipe(
    filter$(isActionOf(getRundateSlug.request)),
    mergeMap$((action) => {
      return from$(eventsApi.getEventById(action.payload)).pipe(
        map$((data: IGetEventByIdSuccessPayload) => {
          return getRundateSlug.success(data)
        }),
        catchError$((error: Error) => {
          navigate({ to: routes[Route.search] })

          return of$(getRundateSlug.failure(error))
        })
      )
    })
  )
}

export const redirectEventWhenRundateSlugSuccess: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getRundateSlug.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const params = {
        eventSlug: action.payload.event.slug,
        rundateSlug: action.payload.slug,
      }
      const module = getModule(state)

      if (module === Route.pinBuyLegacy)
        return of$(loadImages(), getPools.request(params), getEvent.request())

      return of$(
        locationChange({ module, params }),
        loadImages(),
        getPools.request(params),
        getEvent.request()
      )
    })
  )
}

export const setGlobalBasketAction: _Store.IEpic = (action$, state$, { iframeProvider }) => {
  return action$.pipe(
    filter$(isActionOf(addToGlobalBasket)),
    mergeMap$((action) => {
      const { setGlobalBasket } = iframeProvider
      setGlobalBasket(action.payload)
      return EMPTY
    })
  )
}

export const setBasketThemeToConfigured: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(setBasketTheme)),
    mergeMap$((action) => {
      return of$(changeTheme(action.payload))
    })
  )
}
