import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { informAboutError404 } from 'models/errors/actions'

import Error404 from './Error404.component'
import { IError404FromDispatch } from './Error404.types'

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IError404FromDispatch => ({
  informAboutError: () => dispatch(informAboutError404()),
})

export default connect<{}, IError404FromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Error404)
