import { from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { activateGiftCard, giftCardActivation } from 'models/giftCard/actions'
import { createNotification } from 'models/notifications/actions'
import { IGiftCardActivationBody, IGiftCardActivationResponse } from 'services/$giftCard-api/types'
import { isActionOf } from 'typesafe-actions'

export const giftCardActivationInit: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(giftCardActivation)),
    mergeMap$((action) => {
      return of$(activateGiftCard.request(action.payload))
    })
  )

export const submitCardActivation: _Store.IEpic = (action$, state$, { giftCardApi }) =>
  action$.pipe(
    filter$(isActionOf(activateGiftCard.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const body: IGiftCardActivationBody = {
        active: true,
        id: action.payload as number,
      }

      return from$(giftCardApi.activateGiftCard(body)).pipe(
        mergeMap$((data: IGiftCardActivationResponse) => {
          return [activateGiftCard.success(data), createNotification(data.message)]
        }),
        catchError$((error: Error) => [
          activateGiftCard.failure(error),
          createNotification(`ERROR: ${error.message}`),
        ])
      )
    }),
    catchError$((error: Error) => of$(activateGiftCard.failure(error)))
  )
