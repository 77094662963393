import { createSelector } from 'reselect'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import _Store from '@Store'

import { IGetTransactionInfoSuccessPayload } from './../types'
import getTransactionInfo from './getTransactionInfo'

const getRedirectUrl = createSelector<
  _Store.IState,
  IGetTransactionInfoSuccessPayload,
  string | null
>([getTransactionInfo], (details) => {
  if (details?.eventSlug && details?.rundateSlug) {
    return prepareRoute({
      route: Route.buy,
      params: {
        eventSlug: details.eventSlug,
        rundateSlug: details.rundateSlug,
      },
    })
  }

  return null
})

export default getRedirectUrl
