import { createSelector } from 'reselect'

import _Store from '@Store'

import { ICheckSubmissionsExistsResponse, IEntryListReducer } from './../types'
import get from './get'

const getFoundedSubmissions = createSelector<
  _Store.IState,
  IEntryListReducer,
  ICheckSubmissionsExistsResponse | null
>([get], (state) => state.foundedSubmissions)

export default getFoundedSubmissions
