import { makeSxStyles } from '../../../theme/makeSxStyles'

export const useStyles = makeSxStyles(() => ({
  timelineDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: 'text.secondary',
  },
  timelineLine: { width: '1px', height: 12, backgroundColor: 'text.secondary' },
  timelineIconWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '4px',
    position: 'absolute',
  },
}))
