import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import {
  setCalculatedPrices,
  setOnlyOnePriceType,
  setPriceType,
  toggleSelectedUpsell,
} from 'models/happening/actions'
import { getData, getSelected } from 'models/happening/selectors'

import PriceType from './PriceType.component'
import { IPriceTypeFromDispatch, IPriceTypeFromState } from './PriceType.types'

const mapStateToProps = (state: _Store.IState): IPriceTypeFromState => ({
  isCalculatedPerPerson: getData(state)?.calculatePricePerPerson,
  selectedSpace: getSelected(state).space,
  selections: getSelected(state).selectionsPerPriceType,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPriceTypeFromDispatch => ({
  calculatePrice: (value) => dispatch(setCalculatedPrices(value)),
  selectPriceType: (price) => dispatch(setPriceType(price)),
  setOnlyOnePrice: (value) => dispatch(setOnlyOnePriceType(value)),
  toggleUpsellState: (value) => dispatch(toggleSelectedUpsell(value)),
})

export default connect<IPriceTypeFromState, IPriceTypeFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(PriceType)
