import React, { PropsWithChildren } from 'react'

import { mdiSpotify } from '@mdi/js'
import { FacebookRounded, Instagram, YouTube } from '@mui/icons-material'
import { Divider, IconButton, SvgIcon, Typography } from '@mui/material'

import { ISocialLinksProps } from 'components/reusable/SocialLinks/SocialLinks.types'

import { Styled } from './SocialLinks.styles'

export const SocialLinks = ({ links }: ISocialLinksProps) => {
  const SocialLink = ({
    label,
    link,
    children,
  }: PropsWithChildren<{ label: string; link?: string }>) => {
    return (
      <Styled.SocialLink
        target={'_blank'}
        rel={'noreferrer noopener nofollow'}
        href={link}
        aria-disabled={!link}
      >
        <IconButton color={'inherit'} disabled={!link} size={'large'} children={children} />
        <Typography variant={'caption'} children={label} color={'inherit'} />
      </Styled.SocialLink>
    )
  }

  return (
    <Styled.Root>
      <SocialLink
        children={<FacebookRounded fontSize={'large'} />}
        link={links.fbLink}
        label={'Facebook'}
      />
      <Divider orientation={'vertical'} flexItem />
      <SocialLink
        children={<Instagram fontSize={'large'} />}
        link={links.instagramLink}
        label={'Instagram'}
      />
      <Divider orientation={'vertical'} flexItem />
      <SocialLink
        children={<YouTube fontSize={'large'} />}
        link={links.youtubeLink}
        label={'YouTube'}
      />
      <Divider orientation={'vertical'} flexItem />

      <SocialLink
        children={<SvgIcon fontSize={'large'} children={<path d={mdiSpotify} />} />}
        link={links.spotifyLink}
        label={'Spotify'}
      />
    </Styled.Root>
  )
}
