import * as React from 'react'

import useSWR from 'swr'

import { FollowingDialog } from 'components/FollowingDialog'
import { useToggle } from 'misc/hooks/useToggle'
import { useTransactionDetailsFromStore } from 'models/transaction/hooks/useTransactionDetailsFromStore'
import { useTransactionInfoFromStore } from 'models/transaction/hooks/useTransactionInfoFromStore'
import { URI } from 'services/URI'
import { useAppState } from 'state/app/hooks/useAppState'
import { EventCommonResponse } from 'types/EventCommon'

export const FollowAfterPurchase = () => {
  const { isVisible, handleHide, handleShow } = useToggle()
  const transaction = useTransactionInfoFromStore()
  const transactionDetails = useTransactionDetailsFromStore()
  const url = transaction?.eventSlug
    ? URI.GetEventRundate(transaction.eventSlug, transaction?.rundateSlug)
    : null
  const { data } = useSWR<EventCommonResponse>(url)
  const { isWebview } = useAppState()

  React.useEffect(() => {
    if (!!transactionDetails?.user && data) handleShow()
  }, [transactionDetails?.user, data])

  if (!transactionDetails?.user || !data || isWebview) return null

  return (
    <FollowingDialog
      eventData={data}
      userEmail={transactionDetails.user.userEmail}
      handleHide={handleHide}
      isVisible={isVisible}
    />
  )
}
