import * as React from 'react'

import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material'

import { ActionLoading } from 'components/ticketManagement/TicketAction/ActionLoading'
import { Styled } from 'components/ticketManagement/TicketAction/TicketAction.styles'
import { ITicketActionsProps } from 'components/ticketManagement/TicketAction/TicketAction.types'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const ChangeTime = ({ actionKey }: ITicketActionsProps) => {
  const { i18n } = useDictionary()
  const [activeStep, setActiveStep] = React.useState(0)
  const isLoading = false

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const submitLabel = () => {
    switch (activeStep) {
      case 1:
        return i18n.buttons.confirm
      case 2:
        return i18n.buttons.done
      default:
        return i18n.buttons.next
    }
  }

  // TODO: unfinished - this isn't used for now.
  return (
    <Styled.Root>
      <Stepper activeStep={activeStep}>
        {i18n.ticketManagement.myTicket.steps.map((label, index) => (
          <Step key={label} completed={activeStep > index || activeStep === 2}>
            <StepLabel children={label} />
          </Step>
        ))}
      </Stepper>
      <Styled.Body>
        {isLoading ? (
          <ActionLoading actionKey={actionKey} hideText={activeStep !== 1} />
        ) : /** STEP ① : START */ activeStep === 0 ? (
          <Styled.Content>
            <Typography variant={'h5'} mb={4} children={'Aktualnie niedostępne'} />
          </Styled.Content>
        ) : /** STEP ② : CONFIRM */ activeStep === 1 ? (
          <Styled.Content>
            <Typography
              variant={'h5'}
              mb={4}
              children={i18n.ticketManagement.myTicket.actions[actionKey].prompt}
            />
          </Styled.Content>
        ) : (
          /** STEP ③ : SUCCESS */ <Styled.Content>
            <Typography variant={'h5'} mb={4} children={'Aktualnie niedostępne'} />
          </Styled.Content>
        )}
        <Styled.Footer>
          <Button
            variant={'outlined'}
            children={i18n.buttons.back}
            onClick={handleBack}
            disabled={activeStep !== 1 || isLoading}
          />
          <Button
            disabled={isLoading}
            variant={'contained'}
            type={'submit'}
            children={submitLabel()}
            onClick={handleNext}
          />
        </Styled.Footer>
      </Styled.Body>
    </Styled.Root>
  )
}
