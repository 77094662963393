import { filter as filter$, map as map$ } from 'rxjs/operators'

import _Store from '@Store'

import {
  downloadTicketFromTicketDataOnRequest,
  downloadTicketFromTransactionDataOnRequest,
} from 'models/tickets/actions'
import { sendTransaction } from 'models/transaction/actions'
import { isActionOf } from 'typesafe-actions'

import { setError } from '../actions'

export const setErrorOnFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        downloadTicketFromTransactionDataOnRequest.failure,
        downloadTicketFromTicketDataOnRequest.failure,
        sendTransaction.failure,
      ])
    ),
    map$((action) => {
      return setError(action.payload)
    })
  )
}
