import * as React from 'react'

import { FavoritesTypes } from 'constants/Favorites'

import { IFavoritesIconProps } from './FavoritesIcon.types'

export const FavoritesIcon = ({ size, stroke, type, ...rest }: IFavoritesIconProps) => {
  switch (type) {
    case FavoritesTypes.Artists:
      return (
        <svg {...rest} viewBox={`0 0 64 64`} height={size} width={size}>
          <path
            d="M16.76 25.35c0 3.33-2.69 6.02-6.02 6.02m12.04 0c-3.33 0-6.02-2.69-6.02-6.02m0 12.1c0-3.33 2.69-6.02 6.02-6.02"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M10.75 31.44c3.33 0 6.02 2.69 6.02 6.02m7.17-2.79c0 1.66-1.35 3.01-3.01 3.01M26.95 37.68c-1.66 0-3.01-1.35-3.01-3.01m0 6.05c0-1.66 1.35-3.01 3.01-3.01m-6.02.01c1.66 0 3.01 1.35 3.01 3.01"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <circle
            cx={34}
            cy={22.18}
            r={7.31}
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M47.24 46.53c0-.28 0-.29 0 0M24.92 36.9A13.2 13.2 0 0 1 34 33.3c6.1 0 11.24 4.13 12.77 9.75m-26 3.48c0-2.88.92-5.55 2.48-7.72"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
        </svg>
      )
    case FavoritesTypes.Places:
      return (
        <svg {...rest} viewBox={`0 0 64 64`} height={size} width={size}>
          <path
            d="M43.86 27.15c0 5.16-7.01 18.3-10.51 24.54-.85 1.51-3.03 1.51-3.88 0-3.5-6.24-10.51-19.38-10.51-24.54 0-6.88 5.57-12.45 12.45-12.45s12.45 5.57 12.45 12.45Z"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="m31.41 18.79 2.48 5.01 5.53.81-4 3.9.94 5.52-4.95-2.61-4.95 2.61.94-5.52-4-3.9 5.54-.81 2.47-5.01zm15.63 6.12c0-7.6-6.15-13.74-13.74-13.74m3.75 33.68c3.66.4 6.14 1.17 6.14 2.06 0 1.29-5.27 2.34-11.77 2.34s-11.77-1.05-11.77-2.34c0-.89 2.48-1.66 6.14-2.06"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
        </svg>
      )
    case FavoritesTypes.Rundates:
      return (
        <svg {...rest} viewBox={`0 0 64 64`} height={size} width={size}>
          <path
            d="m16.02 45.96-2.49-.53m3.22 3.41-2.85 2.55m5.92-1.08-.18 2.41M45.3 24.88H31.02m-11.45-3.66v-3.65h17.29M20.21 40.02l.08 5.26h24.29v-20.4"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M44.58 24.88c0 6.61-6.52 9.85-16.54-3.66m-11.02 5.2c-1.69-.05-3.89-1.44-4.65-5.2m15.67 0H12.37m27.68 9.38-23.03-4.18M45.2 12.28c0 2.72-2.2 4.93-4.93 4.93m9.86 0c-2.72 0-4.93-2.2-4.93-4.93m0 9.91c0-2.72 2.2-4.93 4.93-4.93m-9.86 0c2.72 0 4.93 2.2 4.93 4.93m5.87-2.27c0 1.36-1.1 2.46-2.46 2.46m4.93 0c-1.36 0-2.46-1.1-2.46-2.46m-.01 4.95c0-1.36 1.1-2.46 2.46-2.46m-4.92 0c1.36 0 2.46 1.1 2.46 2.46"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M44.58 27c0 13.72-7.9 13.02-7.9 13.02H9.79S20.2 37.16 20.2 27"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
        </svg>
      )
    case FavoritesTypes.Selection:
      return (
        <svg {...rest} viewBox={`0 0 64 64`} height={size} width={size}>
          <path
            fill={stroke}
            d="M50.3 34.7V16.8c0-2.5-2-4.5-4.5-4.5H32.3c-2.5 0-4.5 2-4.5 4.5v1.9h-1.9c-2.5 0-4.5 2-4.5 4.5v2h-1.6c-2.5 0-4.5 1.9-4.5 4.4v18c0 2.5 2 4.5 4.5 4.5h13.5c2.5 0 4.5-2 4.5-4.5v-2.1h1.6c2.5 0 4.5-2 4.5-4.5v-1.8h1.9c2.5 0 4.5-2 4.5-4.5ZM28.8 16.8c0-1.9 1.6-3.5 3.5-3.5h13.5c1.9 0 3.5 1.6 3.5 3.5v11.6h-5.4v-5.2c0-2.5-2-4.5-4.5-4.5H28.8v-1.9Zm-6.4 6.4c0-1.9 1.6-3.5 3.5-3.5h13.5c1.9 0 3.5 1.6 3.5 3.5v11.6h-5.1v-5.1c0-2.5-2-4.5-4.5-4.5H22.4v-2Zm-2.6 3h13.5c1.9 0 3.5 1.6 3.5 3.5v11.6H16.3V29.6c0-1.9 1.5-3.4 3.5-3.4Zm17 21.4c0 1.9-1.6 3.5-3.5 3.5H19.8c-1.9 0-3.5-1.6-3.5-3.5v-5.3h20.5v5.3Zm2.6-3.1h-1.6v-8.7h5.1V41c0 1.9-1.6 3.5-3.5 3.5Zm4.5-15.1h5.4v5.3c0 1.9-1.6 3.5-3.5 3.5h-1.9v-8.8Z"
          />
          <path
            fill={stroke}
            d="M27.8 44.3h-8.9c-.3 0-.5.2-.5.5s.2.5.5.5h8.9c.3 0 .5-.2.5-.5s-.2-.5-.5-.5ZM23 46.9h-4.1c-.3 0-.5.2-.5.5s.2.5.5.5H23c.3 0 .5-.2.5-.5s-.2-.5-.5-.5ZM20.8 15.5c.3 0 .5-.2.5-.5l.2-3c0-.3-.2-.5-.5-.5s-.5.2-.5.5l-.2 3c0 .2.2.5.5.5ZM17.3 16.9c.1.1.2.1.3.1.1 0 .3-.1.4-.2.2-.2.1-.5-.1-.7l-3.6-2.9c-.2-.2-.5-.1-.7.1-.2.2-.1.5.1.7l3.6 2.9ZM17 20.3c0-.3-.3-.5-.5-.4l-2.9.3c-.3 0-.5.3-.4.5 0 .3.2.4.5.4h.1l2.9-.3c.1.1.3-.2.3-.5ZM43.2 48.5c-.3 0-.5.2-.5.5l-.2 3c0 .3.2.5.5.5s.5-.2.5-.5l.2-3c0-.2-.2-.5-.5-.5ZM46.7 47.1c-.2-.2-.5-.1-.7.1-.2.2-.1.5.1.7l3.6 2.9c.1.1.2.1.3.1.1 0 .3-.1.4-.2.2-.2.1-.5-.1-.7l-3.6-2.9ZM50.3 42.8l-2.9.3c-.3 0-.5.3-.4.5 0 .3.2.4.5.4h.1l2.9-.3c.3 0 .5-.3.4-.5 0-.2-.3-.4-.6-.4ZM23.9 34.7l-.5 2.7c0 .2 0 .4.2.5.1.1.2.1.3.1.1 0 .2 0 .2-.1l2.6-1.3 2.5 1.3c.2.1.4.1.5 0 .2-.1.2-.3.2-.5l-.5-2.7 2-1.9c.1-.1.2-.3.1-.5s-.2-.3-.4-.3l-2.7-.5-1.3-2.5c-.1-.2-.3-.3-.4-.3-.2 0-.4.1-.5.3l-1.3 2.5-2.8.5c-.2 0-.3.2-.4.3-.1.1 0 .4.1.5l2.1 1.9Zm1.4-2.3c.2 0 .3-.1.4-.3l.9-1.8.9 1.8c.1.1.2.2.4.3l2 .3-1.5 1.4c-.1.1-.2.3-.1.5l.4 2-1.8-.9c-.1-.1-.3-.1-.5 0l-1.9.9.4-2c0-.2 0-.3-.1-.5l-1.5-1.4 2-.3Z"
          />
        </svg>
      )
    case FavoritesTypes.Tags:
      return (
        <svg {...rest} viewBox={`0 0 64 64`} height={size} width={size}>
          <path
            d="m51.01 39.7-24.06 4.59-10.21-9.28 6.02-12.41 24.06-4.59 4.19 21.69zm-.21 4.41-.05 2.66m2.86-3.98 3.18 2.43m-2.28-5.86 2.51-.3"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <circle
            cx={22.37}
            cy={34.18}
            r={1.8}
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M22.37 33.77c0-2.15-1.74-3.89-3.89-3.89"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M14.6 33.77c0-2.15 1.74-3.89 3.89-3.89M8.98 41.62c0 1.55 1.25 2.81 2.81 2.81"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
          <path
            d="M14.6 41.62c0 1.55-1.25 2.81-2.81 2.81m2.81-2.98v-7.68m-5.62 7.29V27.54m25.03-9.53 15.47-2.78M33.33 25.32v11.66m4.99-11.66v11.66m3.43-8.33H30.09m11.66 4.99H30.09"
            fill={'none'}
            stroke={stroke}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
          />
        </svg>
      )
    default:
      return null
  }
}
