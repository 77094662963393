import { IParentData } from 'models/entryList/types'

const getButtonActiveHelper = (data: IParentData) => {
  if (!data.firstName || !data.lastName || !data.phoneNumber) {
    return true
  }
  return false
}

export default getButtonActiveHelper
