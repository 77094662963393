import React from 'react'

import { Grid, Paper, Typography } from '@mui/material'

import { Styled } from 'components/buying/Pools/components/GeneralAdmission/GeneralAdmission.styles'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { HallIcon, SeatsIcon } from 'misc/styles/muiIcons'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { IGeneralAdmissionProps } from './GeneralAdmission.types'

const GeneralAdmission = ({ toggleSeats, isOnlyGA }: IGeneralAdmissionProps) => {
  const { i18n } = useDictionary()
  const { isMobile, isTablet } = useBreakpoints()

  return (
    <Styled.Paper>
      <Grid container={true} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item={true} xs={12} md={'auto'} sx={{ mb: { xs: 3, md: 0 } }}>
          <Grid
            container={true}
            spacing={4}
            justifyContent={{ xs: 'center' }}
            alignItems={'center'}
          >
            {!(isMobile || isTablet) && (
              <Grid item={true}>
                {isOnlyGA ? (
                  <HallIcon sx={{ width: 48, height: 34 }} viewBox={'0 0 48 34'} />
                ) : (
                  <SeatsIcon sx={{ width: 48, height: 31 }} viewBox={'0 0 50 31'} />
                )}
              </Grid>
            )}

            <Grid item={true}>
              <Grid container={true} justifyContent={'center'}>
                <Grid item={true} xs={12}>
                  <Typography
                    textAlign={isMobile || isTablet ? 'center' : undefined}
                    variant={'h6'}
                    sx={{ fontWeight: 500 }}
                  >
                    {isOnlyGA ? i18n.buy.pools.unNumberedSeats : i18n.buy.pools.numberedSeats}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12} md={'auto'}>
          <Styled.Button
            fullWidth={isMobile || isTablet}
            size={'medium'}
            variant={'contained'}
            onClick={toggleSeats}
          >
            {isOnlyGA ? i18n.buy.pools.floorPlan : i18n.buy.pools.choosePlaces}
          </Styled.Button>
        </Grid>
      </Grid>
    </Styled.Paper>
  )
}

export default GeneralAdmission
