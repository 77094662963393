import { combineReducers } from 'redux'

import activities from './activities'
import availabilities from './availabilities'
import data from './data'
import metadata from './metadata'
import selected from './selected'
import terms from './terms'

const reducer = combineReducers({
  availabilities,
  data,
  metadata,
  selected,
  terms,
  activities,
})

export default reducer
