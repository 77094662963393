import React from 'react'

const StamenAttribution = () => (
  <span className="map-attribution">
    Map tiles by{' '}
    <a href="http://stamen.com" target="blank">
      Stamen Design
    </a>
    , under{' '}
    <a href="http://creativecommons.org/licenses/by/3.0" target="blank">
      CC BY 3.0
    </a>
    . Data by{' '}
    <a href="http://openstreetmap.org" target="blank">
      OpenStreetMap
    </a>
    , under{' '}
    <a href="http://www.openstreetmap.org/copyright" target="blank">
      ODbL
    </a>
    .
  </span>
)

const mapProviders = {
  osm: {
    Attribution: () => (
      <span className="map-attribution">
        © autorzy{' '}
        <a href="https://www.openstreetmap.org/copyright" target="blank">
          OpenStreetMap
        </a>
        .
      </span>
    ),
    provider: (x: any, y: any, z: any) => {
      const s = String.fromCharCode(97 + ((x + y + z) % 3))

      return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
    },
  },

  stamenBlackAndWhite: {
    Attribution: StamenAttribution,
    provider: (x: any, y: any, z: any, dpr: any) => {
      return `https://stamen-tiles.a.ssl.fastly.net/toner-lite/${z}/${x}/${y}${
        dpr >= 2 ? '@2x' : ''
      }.png`
    },
  },

  stamenGrey: {
    Attribution: StamenAttribution,
    provider: (x: any, y: any, z: any, dpr: any) => {
      return `https://stamen-tiles.a.ssl.fastly.net/toner-hybrid/${z}/${x}/${y}${
        dpr >= 2 ? '@2x' : ''
      }.png`
    },
  },

  stamenTerrain: {
    Attribution: StamenAttribution,
    provider: (x: any, y: any, z: any, dpr: any) => {
      return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${
        dpr >= 2 ? '@2x' : ''
      }.png`
    },
  },

  wikimedia: {
    Attribution: () => (
      <span className="map-attribution">
        <a
          href="https://foundation.wikimedia.org/w/index.php?title=Maps_Terms_of_Use"
          target="blank"
        >
          Wikimedia maps
        </a>{' '}
        | Map data ©{' '}
        <a href="https://www.openstreetmap.org/copyright" target="blank">
          OpenStreetMap
        </a>
        .
      </span>
    ),
    provider: (x: any, y: any, z: any, dpr: any) => {
      return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png`
    },
  },
}

export default mapProviders
