import { getType } from 'typesafe-actions'

import { resetTickets } from './../../actions'
import { IAction, ITicketsListData } from './../../types'

const reducer = (state: ITicketsListData = null, action: IAction): ITicketsListData => {
  switch (action.type) {
    case getType(resetTickets):
      return null

    default:
      return state
  }
}

export default reducer
