import { getTransactionDetails, unmount } from 'models/transaction/actions'
import { IAction, ITransactionDetailsReducer } from 'models/transaction/types'
import { getType } from 'typesafe-actions'

const reducer = (
  state: ITransactionDetailsReducer = null,
  action: IAction
): ITransactionDetailsReducer => {
  switch (action.type) {
    case getType(getTransactionDetails.success):
      return {
        ...state,
        ...action.payload,
      }
    // RESET_STORE
    case getType(unmount):
      return null

    default:
      return state
  }
}

export default reducer
