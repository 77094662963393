import { Location } from '@remix-run/router'
import { createSelector } from 'reselect'

import _Store from '@Store'

import { IInternalRouterReducer } from 'models/internalRouter/types'

import get from './get'

const getLocation = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  Location | null | undefined
>([get], ({ internalRouter }) => internalRouter.location)

export default getLocation
