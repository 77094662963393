import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import PayUContextProvider from 'components/_functional/PayUContextProvider/PayUContextProvider.component'
import { IPayUContextFromDispatch } from 'components/_functional/PayUContextProvider/PayUContextProvider.types'
import { setError } from 'models/errors/actions'
import { setCardCredentials } from 'models/transaction/actions'

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPayUContextFromDispatch => ({
  setCardCredentials: (cardCredentials) => dispatch(setCardCredentials(cardCredentials)),
  setError: (error) => dispatch(setError(error)),
})

export default connect(null, mapDispatchToProps)(PayUContextProvider)
