import * as React from 'react'

import Happening from 'components/Happening'
import PayUContextProvider from 'components/_functional/PayUContextProvider'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/BuyPage'

export const HappeningPage = () => {
  return (
    <PageWrapper>
      <Helmet />
      <PayUContextProvider>
        <Happening />
      </PayUContextProvider>
    </PageWrapper>
  )
}
