import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import Pools from 'components/buying/Pools/Pools.component'
import { IPoolsFromDispatch, IPoolsFromState } from 'components/buying/Pools/Pools.types'
import { poolsMounted } from 'models/pools/actions'
import { getPools, getSelectedTickets, shouldShowSeatsIo } from 'models/pools/selectors'
import * as CONSTS from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

const mapStateToProps = (state: _Store.IState): IPoolsFromState => ({
  arePoolsLoading: getLoading(CONSTS.POOLS)(state),
  isTransactionLoading: getLoading(CONSTS.TRANSACTION)(state),
  pools: getPools(state),
  selectedPools: getSelectedTickets(state),
  shouldShowSeatsIo: shouldShowSeatsIo(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IPoolsFromDispatch => {
  return {
    poolsMounted: () => dispatch(poolsMounted()),
  }
}

export default connect<IPoolsFromState, IPoolsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Pools)
