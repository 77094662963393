import { selector } from 'recoil'

import { CloudSearch, HitFields, SortOptions } from 'constants/CloudSearch'
import { RecoilKeys } from 'constants/RecoilKeys'
import { ICloudSearchSort } from 'types/CloudSearch'

import { sortingState } from '../atoms/sorting'

export const getSorting = selector<ICloudSearchSort[]>({
  get: ({ get }) => {
    const sorting = get(sortingState)
    const sort = sorting.split('_')[1] ?? 'asc'

    const getSortOption = (): ICloudSearchSort[] => {
      switch (sorting) {
        case SortOptions.PRICE_ASC:
          return [{ priority: 1, field: HitFields.price, sort }]
        case SortOptions.PRICE_DESC:
          return [{ priority: 1, field: HitFields.price, sort }]
        case SortOptions.START_DATE_ASC:
          return [{ priority: 1, field: HitFields.start_date, sort }]
        default:
          return CloudSearch.staticSorting
      }
    }

    return getSortOption()
  },
  key: RecoilKeys.getSorting,
})
