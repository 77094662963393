import React from 'react'

import { Apple } from '@mui/icons-material'
import { Button } from '@mui/material'
import { OAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'

import { useNotify } from 'components/_functional/NotificationProvider'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const AppleLogin = () => {
  const { i18n } = useDictionary()
  const { notify } = useNotify()

  const handleClick = async () => {
    try {
      const provider = new OAuthProvider('apple.com')
      provider.addScope('email')
      provider.addScope('name')
      provider.setCustomParameters({
        locale: i18n._lang,
      })
      await signInWithPopup(getAuth(), provider)
    } catch (error: any) {
      notify({ text: error.message, alertColor: 'error' })
    }
  }

  return (
    <Button
      startIcon={<Apple />}
      children={i18n.auth.appleLogin}
      sx={{
        backgroundColor: 'primary.contrastText',
        color: 'secondary.contrastText',
        '&:hover': {
          backgroundColor: '#1c1c1c',
        },
      }}
      onClick={handleClick}
      fullWidth
    />
  )
}
