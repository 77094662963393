import React from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'

import { Languages } from 'constants/Languages'
import { useLang } from 'state/locale/hooks/useLang'
import { useSetLang } from 'state/locale/hooks/useSetLang'

import stepNames from '../../steps'
import useStyles from './Paper.styles'
import { IPaperProps } from './Paper.types'
import Counter from './components/Counter'

const Paper = ({
  title,
  helperText,
  children,
  submissions,
  ticketData,
  activeStep,
}: IPaperProps) => {
  const classes = useStyles()
  const showCounter = !!(
    ticketData &&
    submissions.length &&
    (activeStep === stepNames.personalData || activeStep === stepNames.selectSubmission)
  )
  const { code: locale } = useLang()
  const { handleLanguageChange } = useSetLang()

  return (
    <Grid className={classes.border} container={true} justifyContent={'flex-end'}>
      <Grid item={true}>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={{ textTransform: 'uppercase' }}
          onClick={handleLanguageChange(
            locale === Languages.Polski ? Languages.English : Languages.Polski
          )}
        >
          {locale === Languages.Polski ? Languages.English : Languages.Polski}
        </Button>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} justifyContent="center" className={classes.root}>
          <Grid item={true} xs={12}>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <Grid
                  container={true}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  className={classes.header}
                >
                  <Grid item={true}>
                    <Typography variant="h5" className={classes.title}>
                      {title}
                    </Typography>
                  </Grid>
                  {showCounter && (
                    <Grid item={true}>
                      <Counter
                        submissionsAdded={submissions.length}
                        submissionsLeft={ticketData.entriesQuantity - 1}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {activeStep === stepNames.starter && (
                <Grid item={true} xs={12}>
                  <Box sx={{ height: 12 }} />
                </Grid>
              )}
              {helperText && (
                <Grid item={true} xs={12}>
                  <Typography variant="body1" className={classes.subtitle}>
                    {helperText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Paper
