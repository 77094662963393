import {
  IHorizontalListExtraData,
  IHorizontalListOwnProps,
} from 'components/Bricks/components/HorizontalList/HorizontalList.types'
import sectionsApi from 'services/$sections-api'

import {
  IComponentExtraProps,
  ISectionComponentData,
  ISectionsComponentDataMutable,
  ISectionsComponentExtendedHotSpot,
} from './../types/sections'

export const horizontal_list = (
  data: ISectionsComponentDataMutable,
  extra?: IHorizontalListExtraData,
  background?: string
): IHorizontalListOwnProps & ISectionComponentData => {
  const cardData = data as ISectionsComponentExtendedHotSpot[]
  const extraFlags: IComponentExtraProps = {
    disableDate: extra?.disableDate,
    disableDescription: extra?.disableDescription,
    disablePlace: extra?.disablePlace,
    disableTitle: extra?.disableTitle,
  }

  return {
    background,
    cards: sectionsApi.normalizeElementsToCardData(cardData, extraFlags),
    color: extra?.color,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  }
}
