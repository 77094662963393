import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButton: {
      border: `0.1250rem solid ${theme.palette.primary.main}`,
      borderRadius: theme.border.radius,
      width: '100%',
      margin: 0,
    },
    addNewSubmissionBtn: {
      textTransform: 'none',
    },
  })
)

export default useStyles
