import React, { useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Route } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { IHappeningProps } from 'components/Happening/Happening.types'
import PriceTypes from 'components/Happening/components/PriceTypes/'
import Products from 'components/Happening/components/Products/Products.container'
import TimeSlots from 'components/Happening/components/TimeSlots'
import { Flex } from 'components/_layout/Flex'
import classes from 'components/buying/components/FormLayout/FormLayout.classes'
import RedirectForCardPayment from 'components/buying/components/RedirectForCardPayment'
import RedirectForFreePayment from 'components/buying/components/RedirectForFreePayment'
import RedirectForPayU from 'components/buying/components/RedirectForPayU'
import RedirectForSale from 'components/buying/components/RedirectForSale/'
import EventDetailsBox from 'components/eventBoxes/Details'
import SimpleLoading from 'components/reusable/SimpleLoading'
import config from 'config'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { setPaymentMethods } from 'models/pools/actions'
import { HappeningApi } from 'services/Api/HappeningApi'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import styles from './Happening.module.scss'
import Calendar from './components/Calendar'
import ExtendedSlot from './components/ExtendedSlot'
import HappeningBuyForm from './components/HappeningBuyForm/'
import HappeningBuyFormOnsite from './components/HappeningBuyFormOnsite/'
import HappeningSummary from './components/HappeningSummary'
import MultipleSlotModal from './components/MultipleSlotModal'
import Spaces from './components/Spaces'
import Upsell from './components/Upsell'

const Happening = ({
  areAnyProductsAvailable,
  calculatePricePerPerson,
  canReserve,
  happeningEndDate,
  happeningSpaces,
  happeningStartDate,
  isEmbed,
  isActivityEventLoading,
  isHappeningLoading,
  isLoadingPrice,
  isSelectedSlotLastInCurrentDay,
  mounted,
  mountProducts,
  selectedSpace,
  slotHasUpsell,
  hasExtendedSlot,
}: IHappeningProps) => {
  let step = 1
  const { isMobile, isWideDesktop } = useBreakpoints()
  const { i18n } = useDictionary()
  const [allHappeningPrices, setAllHappeningPrices] = useState<string[]>([])

  const { slug } = useParams<RouteParams<Route.happening>>()
  const spaceSlug = happeningSpaces?.find((value) => value.id === selectedSpace)?.description[0]
    .slug

  const { data } = useSWR([slug, spaceSlug], HappeningApi.getPaymentMethods, {})
  const dispatch = useDispatch()

  const isNotCalculatedPerPersonAndHasOnePriceType =
    !calculatePricePerPerson && allHappeningPrices.length === 1

  const showUpsell = slotHasUpsell && !isSelectedSlotLastInCurrentDay && !hasExtendedSlot

  useEffect(() => mounted(), [])

  useEffect(() => {
    if (canReserve) mountProducts()
  }, [canReserve, selectedSpace])

  useEffect(() => {
    if (!isEmbed) {
      try {
        window.top?.scrollTo(-1, 0)
      } catch (noSSR) {}
    }

    if (!isHappeningLoading) {
      const newAllHappeningPrices: string[] = []
      happeningSpaces?.map((space) =>
        space.rulePrice.map((priceRule) =>
          priceRule.prices.map(
            (price) =>
              !newAllHappeningPrices.includes(price.type) && newAllHappeningPrices.push(price.type)
          )
        )
      )
      setAllHappeningPrices(newAllHappeningPrices)
    }
  }, [isHappeningLoading])

  useEffect(() => {
    if (data) dispatch(setPaymentMethods(data.paymentMethods))
  }, [data])

  if (isHappeningLoading || isActivityEventLoading) {
    return <SimpleLoading />
  }

  return (
    <div className={cn(styles.buyForm, classes.buyForm, isLoadingPrice && styles.disabled)}>
      <MultipleSlotModal />
      <div className={cn(styles.twoColumns, classes.twoColumns)}>
        {isMobile && <EventDetailsBox />}
        {!isMobile && (
          <div className={cn(styles.fixedBox, classes.fixedBox)}>
            <HappeningSummary />
          </div>
        )}
        <Flex gap={7} fullWidth>
          <Flex fullWidth>
            <Flex fullWidth gap={3}>
              <Flex fullWidth>
                {calculatePricePerPerson && (
                  <Typography variant={'h4'}>
                    {step++}. {i18n.buy.happening.selectNumberOfPlayers}
                  </Typography>
                )}
                {!calculatePricePerPerson && !isNotCalculatedPerPersonAndHasOnePriceType && (
                  <Typography variant={'h4'}>
                    {step++}. {i18n.buy.happening.selectTicketType}
                  </Typography>
                )}
              </Flex>
              <Flex fullWidth>
                <PriceTypes />
              </Flex>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex fullWidth gap={3}>
              <Flex fullWidth>
                <Typography variant={'h4'}>
                  {step++}. {i18n.buy.happening.selectDateAndTime}
                </Typography>
              </Flex>
              <Flex fullWidth gap={3} direction={isWideDesktop ? 'row' : 'column'}>
                <Flex fullWidth={isMobile}>
                  <Calendar
                    happeningStartDate={happeningStartDate}
                    happeningEndDate={happeningEndDate}
                  />
                </Flex>
                <Flex fullWidth>
                  <TimeSlots />
                </Flex>
                <ExtendedSlot />
              </Flex>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex fullWidth gap={3}>
              <Flex fullWidth>
                <Typography variant={'h4'}>
                  {step++}. {i18n.buy.happening.selectSpace}
                </Typography>
              </Flex>
              <Flex fullWidth>
                <Spaces />
              </Flex>
            </Flex>
          </Flex>

          {showUpsell && (
            <Flex fullWidth>
              <Flex fullWidth gap={3}>
                <Flex fullWidth>
                  <Typography variant={'h4'}>
                    {step++}. {i18n.buy.happening.upsell}
                  </Typography>
                </Flex>
                <Flex fullWidth>
                  <Upsell />
                </Flex>
              </Flex>
            </Flex>
          )}
          {canReserve && areAnyProductsAvailable && (
            <Flex fullWidth>
              <Flex fullWidth gap={3}>
                <Flex fullWidth>
                  <Typography variant={'h4'}>
                    {step++}. {i18n.buy.happening.relatedProducts}
                  </Typography>
                </Flex>
                <Flex fullWidth>
                  <Products />
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex fullWidth>
            {config.app.onlineSale ? (
              <HappeningBuyForm step={step} />
            ) : (
              <HappeningBuyFormOnsite step={step} />
            )}
          </Flex>
        </Flex>

        <RedirectForFreePayment />
        <RedirectForPayU />
        <RedirectForSale />
        <RedirectForCardPayment />
      </div>
    </div>
  )
}

export default Happening
