import * as React from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, IconButton, InputAdornment } from '@mui/material'
import {
  EmailAuthProvider,
  getAuth,
  sendPasswordResetEmail,
  signInWithCredential,
} from 'firebase/auth'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Form } from 'components/_forms/Form'
import { TextInput } from 'components/_forms/TextInput'
import { FormFields } from 'components/_forms/_constants/FieldNames'
import { validators } from 'components/_forms/_validators'
import { useNotify } from 'components/_functional/NotificationProvider'
import { useUser } from 'components/_functional/UserContextProvider'
import { Flex } from 'components/_layout/Flex'
import { ILoginFormInput } from 'components/auth/LoginForm/LoginForm.types'
import { DialogAppBar } from 'components/reusable/DialogAppBar'
import { useToggle } from 'misc/hooks/useToggle'
import Logger from 'services/Logger'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const LoginForm = () => {
  const { i18n } = useDictionary()
  const { resetForm, userEmail, loading } = useUser()
  const { control, handleSubmit, setError } = useForm<ILoginFormInput>({
    defaultValues: {
      [FormFields.EMAIL]: userEmail || '',
      [FormFields.PASSWORD]: '',
    },
  })
  const { isVisible, handleToggle } = useToggle()
  const { notify } = useNotify()
  const auth = getAuth()

  const onSubmit: SubmitHandler<ILoginFormInput> = async ({ email, password }) => {
    try {
      const credentials = EmailAuthProvider.credential(email, password)
      await signInWithCredential(auth, credentials)
    } catch (error: any) {
      if (error.message.includes('auth/wrong-password')) {
        setError(FormFields.PASSWORD, { type: 'manual', message: i18n.auth.wrongPassword })
      } else {
        notify({ text: error.message, alertColor: 'error' })
      }
    }
  }

  const onForgetPassword = async () => {
    loading.start()
    try {
      if (userEmail) {
        await sendPasswordResetEmail(auth, userEmail)
        notify({ text: i18n.auth.resetLinkSent, alertColor: 'success' })
      }
    } catch (error) {
      Logger.error('Password reset', error)
      notify({ text: i18n.notifications.genericError, alertColor: 'error' })
    } finally {
      loading.done()
    }
  }

  return (
    <Flex gap={4}>
      <DialogAppBar title={i18n.auth.login} onBack={resetForm} />
      <Form.Wrapper onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name={FormFields.EMAIL}
          label={i18n.forms.labels[FormFields.EMAIL]}
          control={control}
          rules={validators.email(
            i18n.forms.validation[FormFields.EMAIL].required,
            i18n.forms.validation[FormFields.EMAIL].correct
          )}
          disabled={!!userEmail}
          type={'email'}
        />
        <TextInput
          name={FormFields.PASSWORD}
          label={i18n.forms.labels[FormFields.PASSWORD]}
          control={control}
          type={isVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'start'}>
                <IconButton
                  onClick={handleToggle}
                  children={isVisible ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant={'contained'}
          type={'submit'}
          children={i18n.buttons.confirm}
          disabled={loading.isLoading}
          fullWidth
        />
        <Button
          sx={{ fontSize: 12 }}
          children={i18n.auth.forgotPassword}
          color={'secondary'}
          onClick={onForgetPassword}
          fullWidth
        />
      </Form.Wrapper>
    </Flex>
  )
}
