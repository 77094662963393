import Logger from 'services/Logger'
import { URI } from 'services/URI'
import { IPaymentMethodsResponse } from 'types/Happening'

import { Fetcher } from './Fetcher'

export class HappeningApi {
  public static getPaymentMethods = async ([happeningSlug, spaceSlug]: [
    happeningSlug: string,
    spaceSlug?: string
  ]) => {
    try {
      if (!spaceSlug) {
        return { paymentMethods: [] }
      }

      const happening = await Fetcher.get<IPaymentMethodsResponse>(
        URI.getPaymentMethods(happeningSlug, spaceSlug)
      )

      return {
        paymentMethods: happening.pools[0]?.payments || [],
      }
    } catch (e) {
      Logger.error('HappeningApi.getPaymentMethods', e)
    }
  }
}
