import { Actions, Cloudinary, CloudinaryFile, Qualifiers } from '@cloudinary/url-gen'
import { CloudinaryImage } from '@cloudinary/url-gen/assets/CloudinaryImage'

import config from 'config'
import { CloudinaryPrefixes } from 'constants/Cloudinary'
import { ICloudinaryList } from 'types/Cloudinary'

const cloudinaryConfig = {
  cloudName: config.images.cloudName,
}

const {
  Delivery: { format, quality },
  Resize: { fill },
} = Actions
const {
  Quality: { autoEco },
  Format: { auto },
} = Qualifiers

/**
 * Cloudinary SDK configured global instance.
 *
 * @type {Cloudinary}
 */
export const cloudinary = new Cloudinary({
  cloud: cloudinaryConfig,
  url: {
    secure: true,
  },
})

/**
 * Checks if source is Cloudinary Image instance / Cloudinary public id
 * or regular url, and returns its target url.
 *
 * @param {string | CloudinaryImage} source
 * @param dimensions
 * @returns {string}
 */
export const getImageUrl = (
  source: string | CloudinaryImage,
  dimensions?: { width: number; height: number }
): string => {
  const isString = typeof source === 'string'
  const isImageUrl = isString && source.startsWith('https://')

  if (isImageUrl) return source

  const image = isString ? cloudinary.image(source) : source
  const transformedImage = !!dimensions
    ? image?.resize(fill().width(dimensions.width).height(dimensions.height))
    : image

  return transformedImage?.delivery(format(auto())).delivery(quality(autoEco())).toURL()
}

/**
 * Service to fetch cloudinary images list.
 *
 * @param {string} slug
 * @param {CloudinaryPrefixes} prefix
 * @returns {Promise<string[]>}
 */
export const fetchCloudinaryList = async ({
  slug,
  additionalSlug,
  prefix,
}: {
  slug: string
  additionalSlug?: string
  prefix?: CloudinaryPrefixes
}): Promise<string[]> => {
  const publicId = `${prefix ? prefix + '/' : ''}${slug}${
    additionalSlug ? '/' + additionalSlug : ''
  }.json`
  const file = new CloudinaryFile(publicId, cloudinaryConfig)
    .setAssetType('image')
    .setDeliveryType('list')
  const data = await fetch(file.toURL(), { method: 'get' })

  if (data.ok) {
    const list: ICloudinaryList = await data.json()

    return list.resources.map((image) => image.public_id)
  }

  return []
}
