import { createSelector } from 'reselect'

import _Store from '@Store'

import { ILandingPageReducer, ILandingPageReducerRequestTimes } from './../types'
import get from './get'

const getRequestTimes = createSelector<
  _Store.IState,
  ILandingPageReducer,
  ILandingPageReducerRequestTimes
>([get], (landingPage) => landingPage.requestTimes)

export default getRequestTimes
