import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import config from 'config'
import { DateTime } from 'services/DateTime'
import { IRundateResponse } from 'types/EventRundate'

const AVAILABLE_EVENT_LABEL = 'http://schema.org/InStock'
const UNAVAILABLE_EVENT_LABEL = 'http://schema.org/LimitedAvailability'

const createSchemaFromEvent = (rundate: IRundateResponse, image: string) => {
  const {
    event,
    endDate,
    place,
    isAvailable,
    price,
    priceDescriptionPL,
    currency,
    slug,
    startDate,
  } = rundate

  return {
    '@context': 'https://schema.org',
    '@type': 'MusicEvent',
    description: event.description,
    endDate: endDate ? DateTime.getIsoDateString(endDate) : undefined,
    image,
    location: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: place.city.name,
        streetAddress: place.address,
      },
      name: place.name,
    },
    name: event.title,
    offers: {
      '@type': 'Offer',
      availability: isAvailable ? AVAILABLE_EVENT_LABEL : UNAVAILABLE_EVENT_LABEL,
      price: priceDescriptionPL ? priceDescriptionPL : price,
      priceCurrency: currency,
      url:
        config.app.baseUrl +
        prepareRoute({
          route: Route.buy,
          params: {
            eventSlug: event.slug,
            rundateSlug: rundate.slug,
          },
        }),
    },
    startDate: DateTime.getIsoDateString(startDate),
  }
}

export default createSchemaFromEvent
