import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 60,
    },
    disabled: {
      cursor: 'initial',
      opacity: 0.6,
      pointerEvents: 'none',
    },
    header: {
      fontSize: 28,
      marginBottom: theme.spacing(5),

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
  })
)

export default useStyles
