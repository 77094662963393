import axios, { CancelTokenSource } from 'axios'
import qs from 'query-string'

import config from 'config'
import catchHttpError from 'misc/helpers/api/catchHttpError'
import getData from 'misc/helpers/api/getData'
import withCacheHeader from 'misc/helpers/withCacheHeader'
import { IPremiumUser } from 'models/premium/types'

import { IPremiumCheckCardResponse, IPremiumCheckTokenResponse } from './types'

class PremiumApi {
  private static getTokenCheckUrl(token: string): string {
    return `${config.api.baseUrl}get-premium-empik-user/${token}`
  }
  private static getUserCardCheckUrl(cardNumber: string, email: string): string {
    return `${config.api.baseUrl}premium/check-card/${cardNumber}/${email}`
  }

  private cancelToken?: CancelTokenSource

  public checkIfUserIsPremiumByToken(jwt: string): Promise<IPremiumCheckTokenResponse> {
    return new Promise((resolve, reject) => {
      const url = PremiumApi.getTokenCheckUrl(jwt)

      this.cancelToken = axios.CancelToken.source()

      axios
        .get(url, withCacheHeader({ cancelToken: this.cancelToken.token }))
        .then(getData)
        .then((response: IPremiumCheckTokenResponse) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public checkIfUserIsPremiumByCardNumber(
    cardNumber: string,
    email: string
  ): Promise<IPremiumCheckCardResponse> {
    return new Promise((resolve, reject) => {
      const url = PremiumApi.getUserCardCheckUrl(cardNumber, email)

      this.cancelToken = axios.CancelToken.source()

      axios
        .get(url, withCacheHeader({ cancelToken: this.cancelToken.token }))
        .then(getData)
        .then((response: IPremiumCheckCardResponse) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancel() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }

  public normalize(data: IPremiumCheckTokenResponse): IPremiumUser {
    const { userEmail, userFirstname, userLastname } = data

    return {
      email: userEmail,
      firstName: userFirstname,
      lastName: userLastname,
    }
  }

  public getToken(searchString: string): string | null {
    const parts = qs.parse(searchString)
    const { tokenName } = config.premium

    if (tokenName in parts) {
      const token = parts[tokenName] as string

      if (token) {
        return token
      }
    }

    return null
  }
}

export default new PremiumApi()
