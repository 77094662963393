import React from 'react'

import { Flex } from 'components/_layout/Flex'
import scrollTo from 'misc/helpers/scrollTo'

import { Styled } from './SecondaryMenu.styles'
import { ISecondaryMenuProps } from './SecondaryMenu.types'

export const SecondaryMenu = ({ routes }: ISecondaryMenuProps) => {
  const filteredRoutes = routes.filter((route) => !route.isHidden)

  return (
    <Flex direction={'row'} gap={2} sx={{ overflowX: 'scroll', width: '100%' }}>
      {filteredRoutes.map((route, index) => (
        <Styled.Button
          size={'medium'}
          variant={'text'}
          color={'primary'}
          key={`secondary-menu-${index}`}
          children={route.label}
          onClick={(e) => scrollTo(e, route.anchor)}
        />
      ))}
    </Flex>
  )
}
