import { connect } from 'react-redux'

import _Store from '@Store'

import { staticPage } from 'models/meta/selectors'
import { IMetaForCurrentUrl } from 'models/meta/types'

import Helmet from './../../Helmet'

const mapStateToProps = (state: _Store.IState) => staticPage(state)

export default connect<IMetaForCurrentUrl, void, {}, _Store.IState>(mapStateToProps)(Helmet)
