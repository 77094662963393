import { createSelector } from 'reselect'

import _Store from '@Store'

import { IEventReducer, IEventReducerCurrentKey } from './../types'
import get from './get'

const getCurrentKey = createSelector<_Store.IState, IEventReducer, IEventReducerCurrentKey>(
  [get],
  (event) => event.currentKey
)

export default getCurrentKey
