import { connect } from 'react-redux'

import _Store from '@Store'

import { isBrowser } from 'models/__base__/selectors'
import { getIframeParams } from 'models/internalRouter/selectors'
import { getCardCredentials, getData, isFreePaymentAvailable } from 'models/transaction/selectors'

import PayURedirect from './RedirectForFreePayment.component'
import {
  IRedirectForFreePaymentData,
  IRedirectForFreePaymentProps,
} from './RedirectForFreePayment.types'

const mapStateToProps = (state: _Store.IState): IRedirectForFreePaymentProps => ({
  data: getData(state) as IRedirectForFreePaymentData,
  isBrowser: isBrowser(state) && !getIframeParams(state),
  isPaymentAvailable: isFreePaymentAvailable(state),
  isCardPayment: !!getCardCredentials(state),
})

export default connect<IRedirectForFreePaymentProps, null, {}, _Store.IState>(mapStateToProps)(
  PayURedirect
)
