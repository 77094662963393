import React from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'
import parse, { domToReact } from 'html-react-parser'

import { MarkdownText } from 'components/reusable/MarkdownText'
import Title from 'components/reusable/Title'
import { YouTube } from 'components/reusable/YouTube'

import styles from './Description.module.scss'
import useStyles from './Description.styles'
import { IDescriptionProps } from './Description.types'

const Description = ({ html, hideApostrophe, text, title, isFullWidth }: IDescriptionProps) => {
  const theme = useTheme()
  const muiStyles = useStyles(theme)

  if (!text && !html) return null

  return (
    <div
      className={cn(
        styles.description,
        muiStyles.root,
        muiStyles.fontColor,
        hideApostrophe && styles.hideApostrophe,
        isFullWidth && styles.fullWidth
      )}
    >
      {title && <Title title={title} customClassName={styles.defaultTitle} />}
      {html &&
        parse(html, {
          replace: (domNode) => {
            if (domNode.attribs && domNode.name === 'img') {
              return (
                <img
                  alt={title}
                  src={domNode.attribs['data-src'] || domNode.attribs.src}
                  className={styles.image}
                />
              )
            }
            if (domNode.name === 'h4' && domNode.children) {
              return <h4 className={styles.title}>{domToReact(domNode.children)}</h4>
            }
            if (domNode.attribs && domNode.attribs['data-plyr-provider'] === 'youtube') {
              return (
                <div className={styles.player}>
                  <YouTube source={domNode.attribs['data-plyr-embed-id']} />
                </div>
              )
            }
          },
        })}
      {!html && <MarkdownText text={text ? text : ''} highlightFirstParagraph={true} />}
    </div>
  )
}

export default Description
