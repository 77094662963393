import { createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import { IActionAgent } from './../types'

export const setUserAgent = createStandardAction(CONSTS.SET_USER_AGENT)<IActionAgent>()

export const appIsReady = createStandardAction(CONSTS.APP_IS_READY)()

export const emptyAction = { type: '_empty', payload: '' }
