import moment from 'moment'
import { createSelector, defaultMemoize } from 'reselect'
import { boolean, lazy, object, string } from 'yup'

import _Store from '@Store'

import config from 'config'
import { PaymentMethods } from 'constants/PaymentMethods'
import { getCustomTerms, getIsEventForPremiumUsers } from 'models/event/selectors'
import { getAvailablePaymentMethods, getSelectedTickets } from 'models/pools/selectors'
import { IPoolSelectedTicket } from 'models/pools/types'
import { getIsUserPremium, getUser } from 'models/premium/selectors'
import { IPremiumUser } from 'models/premium/types'
import { IPaymentMethodForTransactionAndDiscount } from 'services/$transactions-api/types'
import { ICustomTerm } from 'types/EventCommon'
import { ILangMap } from 'types/Locale'
import { IPaymentMethod } from 'types/Pools'

import * as CONSTS from './../constants/constants'
import {
  addressNumberValidator,
  addressValidator,
  customTermValidator,
  empikCardNumberValidator,
  invoiceCountryValidator,
  invoiceNipValidator,
  invoicePostCodeValidator,
  invoiceRequiredFieldValidator,
  postCodeValidator,
  termValidator,
} from './../constants/validators'
import { IBuyingOnlineFormValidators, IBuyingOnlineFormValues } from './Online.types'

export const getInitialValues = (state: _Store.IState, basketTerms?: ICustomTerm[]) =>
  createSelector<
    _Store.IState,
    IPremiumUser | null,
    ICustomTerm[],
    boolean,
    boolean,
    IPoolSelectedTicket[],
    IPaymentMethod[],
    IBuyingOnlineFormValues
  >(
    [
      getUser,
      getCustomTerms,
      getIsEventForPremiumUsers,
      getIsUserPremium,
      getSelectedTickets,
      getAvailablePaymentMethods,
    ],
    (user, terms, isEventForPremiumUsers, isUserPremium, selectedTickets, availablePayments) => {
      const isPayUAvailable = availablePayments.some(
        (payment) => payment.type === PaymentMethods.PAYU
      )
      /* tslint:disable:object-literal-sort-keys */
      const initialValues: IBuyingOnlineFormValues = {
        customTerms: {},
        newsletter: false,
        terms: false,
        hasDependency: false,

        deliveryCity: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryFirstname: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryLastname: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryLocalStreet: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryNumberStreet: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryPhone: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryStreet: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryZip: CONSTS.DEFAULT_EMPTY_TEXT,
        deliveryCountry: CONSTS.DEFAULT_COUNTRY,
        giftWrapper: false,

        dependencyCode: CONSTS.DEFAULT_EMPTY_TEXT,
        discount: CONSTS.DEFAULT_EMPTY_TEXT,
        discountCode: CONSTS.DEFAULT_EMPTY_TEXT,
        discountCheckbox: false,
        email: CONSTS.DEFAULT_EMPTY_TEXT,
        emailAdditional: {},
        empikPremiumNumber: CONSTS.DEFAULT_EMPTY_TEXT,
        firstname: CONSTS.DEFAULT_EMPTY_TEXT,
        firstnameAdditional: {},
        lastname: CONSTS.DEFAULT_EMPTY_TEXT,
        lastnameAdditional: {},
        paymentMethod:
          !isPayUAvailable && availablePayments.length
            ? availablePayments[0]
            : { type: config.buy.defaultPaymentMethod },
        prepaidCard: CONSTS.DEFAULT_EMPTY_TEXT,
        prepaidCheckbox: false,

        invoiceAddress: CONSTS.DEFAULT_EMPTY_TEXT,
        invoiceCheckbox: false,
        invoiceCity: CONSTS.DEFAULT_EMPTY_TEXT,
        invoiceName: CONSTS.DEFAULT_EMPTY_TEXT,
        invoiceNip: CONSTS.DEFAULT_EMPTY_TEXT,
        invoicePost: CONSTS.DEFAULT_EMPTY_TEXT,
        invoiceCountry: CONSTS.DEFAULT_EMPTY_TEXT,

        isInsurance: false,
        isInsuranceAdultCheckbox: false,
        isAccidentInsurance: false,
        insuranceBirthDate: CONSTS.DEFAULT_EMPTY_TEXT,
        insuranceIsLivingInPoland: false,

        pickUpWay: CONSTS.DEFAULT_PICKUP_WAY,

        userHavePremiumCheckbox: isEventForPremiumUsers && !isUserPremium,
      }
      /* tslint:enable:object-literal-sort-keys */

      if (user) {
        initialValues.firstname = initialValues.deliveryFirstname = user.firstName
        initialValues.lastname = initialValues.deliveryLastname = user.lastName
        initialValues.email = user.email
      }

      terms.forEach((term) => {
        initialValues.customTerms[term.id] = false
      })
      if (basketTerms) {
        basketTerms.forEach((term) => {
          initialValues.customTerms[term.id] = false
        })
      }

      const poolsWithAdditionalFields = selectedTickets.filter((ticket) => ticket.additionalFields)
      poolsWithAdditionalFields.map((pool) => {
        initialValues.firstnameAdditional[`id_${pool.poolId}`] = []
        initialValues.lastnameAdditional[`id_${pool.poolId}`] = []
        initialValues.emailAdditional[`id_${pool.poolId}`] = []

        for (let i = 0; i < pool.amount; i++) {
          initialValues.firstnameAdditional[`id_${pool.poolId}`].push(' ')
          initialValues.lastnameAdditional[`id_${pool.poolId}`].push(' ')
          initialValues.emailAdditional[`id_${pool.poolId}`].push(' ')
        }
      })

      return initialValues
    }
  )(state)

const makeValidationSchemaToMemoize = (
  customTerms: ICustomTerm[],
  lang: ILangMap,
  checkout: () => void,
  checkData: boolean,
  isEnglishEmbed?: boolean,
  isDefaultCurrency?: boolean
) => {
  /* tslint:disable:object-literal-sort-keys */
  const validators: Partial<IBuyingOnlineFormValidators> = {
    terms: termValidator(lang.errors.checkingThisRegulationsIsRequiredValidator),

    // Address - delivery by post
    deliveryCity: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryFirstname: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryLastname: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryLocalStreet: addressNumberValidator(lang.errors.incorrectData),
    deliveryNumberStreet: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      then: addressNumberValidator(lang.errors.incorrectData).required(
        lang.errors.thisFieldIsRequiredValidator
      ),
    }),
    deliveryPhone: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      // @TODO: remove when sale's ended and update PostAddress.component
      then: string().required(lang.errors.thisFieldIsRequiredValidator),
      // then: phoneNumberValidator(lang.errors.incorrectPhoneNumber).required(
      //   lang.errors.thisFieldIsRequiredValidator
      // ),
    }),
    deliveryStreet: addressValidator(lang.errors.thisFieldIsRequiredValidator),
    deliveryZip: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      then: postCodeValidator(lang.errors.incorrectPostCodeValidator).required(
        lang.errors.thisFieldIsRequiredValidator
      ),
    }),
    discount: checkData
      ? string().when('discountCheckbox', {
          is: true,
          then: string().required(lang.errors.discountCodeIsRequired),
        })
      : string().required(lang.errors.discountCodeIsRequired),
    dependencyCode: checkData
      ? string().when('hasDependency', {
          is: true,
          then: string().required(lang.errors.dependencyCodeIsRequired),
        })
      : string().required(lang.errors.dependencyCodeIsRequired),
    email: checkData
      ? string()
          .test('email', '', (val) => {
            if (checkout && val) {
              checkout()
            }

            return true
          })
          .required(lang.errors.emailAddressIsRequiredValidator)
          .email(lang.errors.providedEmailAddressIsIncorrectValidator)
      : undefined,
    empikPremiumNumber: empikCardNumberValidator(lang),
    firstname: checkData ? string().required(lang.errors.firstNameIsRequiredValidator) : undefined,
    lastname: checkData ? string().required(lang.errors.lastNameIsRequiredValidator) : undefined,

    invoiceAddress: invoiceRequiredFieldValidator(lang.errors.thisFieldIsRequiredValidator),
    invoiceCheckbox: boolean(),
    invoiceCity: invoiceRequiredFieldValidator(lang.errors.thisFieldIsRequiredValidator),
    invoiceName: invoiceRequiredFieldValidator(lang.errors.thisFieldIsRequiredValidator),
    invoiceNip: isDefaultCurrency
      ? invoiceNipValidator(lang.errors.incorrectNip, false)
      : undefined,
    invoicePost: invoicePostCodeValidator(lang, true),
    invoiceCountry: invoiceCountryValidator(
      lang.errors.countryIsRequiredValidator,
      isDefaultCurrency
    ),

    isAccidentInsurance: boolean().when('isInsurance', {
      is: true,
      then: termValidator(lang.errors.checkingThisRegulationsIsRequiredValidator),
    }),
    isInsuranceAdultCheckbox: boolean().when('isInsurance', {
      is: true,
      then: termValidator(lang.errors.checkingThisRegulationsIsRequiredValidator),
    }),
    insuranceBirthDate: string().when('isInsurance', {
      is: true,
      then: string()
        .required(lang.errors.thisFieldIsRequiredValidator)
        .test('isValidBirthDate', lang.errors.wrongBirthDate, (value) => {
          const pattern = /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/gi

          if (pattern.test(value)) {
            const d = new Date(value).getTime()

            return moment(d).isBefore(Date.now())
          }

          return false
        }),
    }),
    insuranceIsLivingInPoland: boolean().when('isInsurance', {
      is: true,
      then: termValidator(lang.errors.checkingThisRegulationsIsRequiredValidator),
    }),

    paymentMethod: object<IPaymentMethodForTransactionAndDiscount>().required(
      lang.errors.paymentMethodIsRequired
    ),

    prepaidCard: string().when('prepaidCheckbox', {
      is: true,
      then: string().required(lang.errors.thisFieldIsRequiredValidator),
    }),
    customTerms: lazy((value: { [key: string]: boolean }) =>
      customTermValidator(lang, customTerms, value, null).when('invoiceCheckbox', {
        is: true,
        then: customTermValidator(lang, customTerms, value, 'forFVat'),
        otherwise: customTermValidator(lang, customTerms, value, 'exceptFVat'),
      })
    ),
  }

  return object().shape(validators as IBuyingOnlineFormValidators)
}

export const makeValidationSchema = defaultMemoize(makeValidationSchemaToMemoize)
