import React from 'react'

import { createSvgIcon } from '@mui/material/utils'

const HallIcon = createSvgIcon(
  <>
    <g clip-path="url(#clip0_1904_2337)">
      <path
        d="M30.9542 10.6469C33.9953 10.6469 36.4632 13.4849 36.4632 16.9847H47.0898V14.6527C47.0898 8.07074 43.0664 2.57163 37.6937 1.23047L30.9542 10.6469Z"
        fill="#EFF200"
      />
      <path
        d="M30.9542 10.6469C33.9953 10.6469 36.4632 13.4849 36.4632 16.9847H47.0898V14.6527C47.0898 8.07074 43.0664 2.57163 37.6937 1.23047L30.9542 10.6469ZM30.9542 10.6469H30.9577"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
        fill="#EFF200"
      />
      <path
        d="M17.0488 10.6469L10.3093 1.23047C4.93652 2.57163 0.913086 8.06721 0.913086 14.6527V16.9847H11.5397C11.5397 13.4849 14.0041 10.6469 17.0488 10.6469Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M47.0864 19.3164V16.9844H36.4597C36.4597 20.4841 33.9953 23.3222 30.9507 23.3222L37.6867 32.7386C43.0594 31.3974 47.0829 25.8983 47.0829 19.3164H47.0864Z"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
        fill="#FFFFFF"
      />
      <path
        d="M17.0488 23.3222C14.0076 23.3222 11.5397 20.4841 11.5397 16.9844H0.913086V19.3164C0.913086 25.8983 4.93652 31.3974 10.3093 32.7386L17.0488 23.3222Z"
        fill="#EFF200"
      />
      <path
        d="M17.0488 23.3222C14.0076 23.3222 11.5397 20.4841 11.5397 16.9844H0.913086V19.3164C0.913086 25.8983 4.93652 31.3974 10.3093 32.7386L17.0488 23.3222ZM17.0488 23.3222H17.0453"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
        fill="#EFF200"
      />
      <path
        d="M37.6903 1.22998C36.8723 1.02474 36.0229 0.915039 35.149 0.915039H23.998V10.6464H31.0152L37.6903 1.22998Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M12.8509 0.915039C11.9805 0.915039 11.131 1.02474 10.3096 1.22998L16.6153 10.6464H24.0018V0.915039H12.8509Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M16.9845 23.3223L10.3096 32.7387C11.1275 32.9439 11.977 33.0536 12.8509 33.0536H24.0018V23.3223H16.9845Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M24.0015 23.3224V33.0538H35.1524C36.0228 33.0538 36.8723 32.9441 37.6937 32.7389L31.015 23.3223L24.0015 23.3224Z"
        fill="#EFF200"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1904_2337">
        <rect width="47.9998" height="33.9691" fill="white" />
      </clipPath>
    </defs>
  </>,
  'Hall'
)

export default HallIcon
