import * as React from 'react'

import { Event, Place, Schedule } from '@mui/icons-material'
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Route } from 'router/routes'
import { prepareRoute } from 'router/utils/prepareRoute'

import { IDateTimePlaceProps } from 'components/reusable/DateTimePlace/DateTimePlace.types'
import { DateTime } from 'services/DateTime'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import { useStyles } from './DateTime.styles'

export const DateTimePlace = ({
  startDate,
  runDate,
  place,
  endDate,
  rundateDescription,
  hourDescription,
  isTimeDisabled,
  placeDescription,
}: IDateTimePlaceProps) => {
  const { i18n } = useDictionary()
  const styles = useStyles({})
  const friendlyStartDate = startDate ? DateTime.getFriendlyTime(startDate) : undefined
  const friendlyRunDate = runDate ? DateTime.getFriendlyTime(runDate) : undefined

  return (
    <List>
      {!!startDate && (
        <ListItem sx={{ px: 0 }} disableGutters disablePadding>
          <ListItemIcon sx={{ color: 'text.primary' }} children={<Event />} />
          <ListItemText
            primary={
              rundateDescription ||
              DateTime.formatRundateDates({
                startDate,
                endDate,
                dateTranslate: i18n.dates,
              })
            }
          />
        </ListItem>
      )}
      {friendlyRunDate && friendlyStartDate && friendlyRunDate !== friendlyStartDate ? (
        <>
          <ListItem sx={{ px: 0, paddingTop: 0, paddingBottom: 0 }} disableGutters>
            <ListItemIcon
              style={{ paddingLeft: 8 }}
              children={
                <Box flexGrow={0} sx={{ ...styles.timelineIconWrapper, top: '12px' }}>
                  <Box sx={styles.timelineDot} />
                  <Box sx={styles.timelineLine} />
                </Box>
              }
            />
            <ListItemText
              primary={
                <>
                  {friendlyRunDate}{' '}
                  <Typography
                    variant={'caption'}
                    children={i18n.event.gatesOpening}
                    color={'text.secondary'}
                  />
                </>
              }
            />
          </ListItem>
          <ListItem sx={{ px: 0, paddingTop: 0, paddingBottom: 0 }} disableGutters>
            <ListItemIcon
              style={{ paddingLeft: 8 }}
              children={
                <Box flexGrow={0} sx={{ ...styles.timelineIconWrapper, top: '-2px' }}>
                  <Box sx={styles.timelineLine} />
                  <Box sx={styles.timelineDot} />
                </Box>
              }
            />
            <ListItemText
              primary={
                <>
                  {friendlyStartDate}{' '}
                  <Typography
                    variant={'caption'}
                    children={i18n.event.eventStart}
                    color={'text.secondary'}
                  />
                </>
              }
            />
          </ListItem>
        </>
      ) : (
        !!startDate &&
        !isTimeDisabled && (
          <ListItem sx={{ px: 0 }} disableGutters disablePadding>
            <ListItemIcon sx={{ color: 'text.primary' }} children={<Schedule />} />
            <ListItemText primary={hourDescription || DateTime.getFriendlyTime(startDate)} />
          </ListItem>
        )
      )}
      {!!place && (
        <ListItem
          component={RouterLink}
          to={prepareRoute({
            route: Route.place,
            params: { slug: place.slug },
          })}
          sx={{ px: 0 }}
          disableGutters
          disablePadding
        >
          <ListItemIcon sx={{ color: 'text.primary' }} children={<Place />} />
          <ListItemText
            sx={(theme) => ({
              color: theme.palette.text.primary,
              textDecoration: 'underline',
              textDecorationColor: theme.palette.primary.main,
              textDecorationThickness: 2,
              textUnderlineOffset: 4,
            })}
            primary={`${place.name}`}
          />
        </ListItem>
      )}
      {!!placeDescription && (
        <ListItem sx={{ px: 0 }} disableGutters disablePadding>
          <ListItemIcon sx={{ color: 'text.primary' }} children={<Place />} />
          <ListItemText
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
            primary={placeDescription}
          />
        </ListItem>
      )}
    </List>
  )
}
