import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoIcon: {
      marginRight: theme.spacing(3),
    },
    root: {
      margin: '0 auto',
    },
    slotDefault: {
      backgroundColor: theme.palette.background.paper,
      borderWidth: 0,
      '&:hover': {
        border: 0,
      },
      '&:disabled': {
        border: 0,
      },
    },
    defaultSelected: {
      backgroundColor: theme.palette.primary.main,
    },
    slot: {
      borderRadius: theme.border.radius,
      borderColor: theme.border.color,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
      padding: '0.75rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderWidth: theme.border.width,
      },
    },
  })
)

export default useStyles
