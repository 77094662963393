import { createSelector } from 'reselect'

import getAvailabilitiesSelector from './getAvailabilitiesSelector'
import getSelected from './getSelected'

const getIsSelectedSlotLastInCurrentDay = createSelector(
  [getAvailabilitiesSelector, getSelected],
  (availabilities, selected) => {
    const selectedSlotIndex = availabilities.findIndex((slot) => slot.start === selected.slot)

    return availabilities.length - 1 === selectedSlotIndex
  }
)

export default getIsSelectedSlotLastInCurrentDay
