import React from 'react'

import { ConfirmationNumberOutlined, Favorite, FavoriteBorder } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import qs from 'qs'
import { Link as RouterLink } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { Flex } from 'components/_layout/Flex'
import { SectionContainer } from 'components/_layout/SectionContainer'
import { TwoColumnsLayout } from 'components/_layout/TwoColumnsLayout'
import { CardDeck } from 'components/reusable/CardDeck'
import { CloudSearchCard } from 'components/reusable/CloudSearchCard'
import { DateTimePlace } from 'components/reusable/DateTimePlace'
import Description from 'components/reusable/Description'
import Gallery from 'components/reusable/Gallery'
import { SocialLinks } from 'components/reusable/SocialLinks'
import { AnchorLinks } from 'constants/AnchorLinks'
import { HitFields } from 'constants/CloudSearch'
import scrollTo from 'misc/helpers/scrollTo'
import { DataMutations } from 'services/DataMutations'
import { useDictionary } from 'state/locale/hooks/useDictionary'

import config from '../../config'
import { FavoritesTypes } from '../../constants/Favorites'
import { EntityRelatedArticles } from '../EntityRelatedArticles'
import { useFavoritesContext } from '../favorites/FavoritesProvider'
import { useEditFavorites } from '../favorites/FavoritesProvider/useEditFavorites'
import { IArtistProps } from './Artist.types.js'

export const Artist = ({
  data,
  artistEvents,
  cloudinaryImages,
  lowestPrice,
  relatedArticlesData,
  isArticlesLoading,
}: IArtistProps) => {
  const { i18n } = useDictionary()
  const isOnTour = !!artistEvents?.length
  const { handleEditFavorites } = useEditFavorites()
  const { isItemInFavs } = useFavoritesContext()
  const isArtistInFavs = isItemInFavs(data.id, FavoritesTypes.Artists)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TwoColumnsLayout
        boxComponent={
          <>
            {isOnTour && <Chip sx={{ my: 4 }} color={'primary'} label={i18n.artist.onTour} />}
            <Typography
              color={'text.secondary'}
              variant={'subtitle2'}
              children={isOnTour ? i18n.artist.nearest : i18n.artist.noUpcomingEvents}
            />
            {isOnTour && (
              <DateTimePlace
                place={{
                  name: artistEvents[0].fields[HitFields.caption] ?? '',
                  slug: artistEvents[0].fields[HitFields.place_slug],
                }}
                startDate={artistEvents[0].fields[HitFields.start_date]}
                endDate={artistEvents[0].fields[HitFields.end_date]}
              />
            )}
            <Box sx={{ mt: 4 }} />
            {!!lowestPrice && (
              <ListItem disableGutters disablePadding>
                <ListItemIcon children={<ConfirmationNumberOutlined />} />
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5' }}
                  children={`${DataMutations.friendlyPrice({
                    price: lowestPrice,
                    prefix: i18n.price.from,
                  })}`}
                />
              </ListItem>
            )}
            <Flex gap={3} direction={'column'} sx={{ mt: 2 }}>
              {isOnTour && (
                <Button
                  fullWidth={true}
                  variant={'contained'}
                  size={'large'}
                  onClick={(e) => scrollTo(e, AnchorLinks.UPCOMING_EVENTS)}
                  children={i18n.artist.upcomingEvents}
                />
              )}
              {config.features.userLogin && (
                <Button
                  fullWidth={true}
                  variant={'outlined'}
                  children={isArtistInFavs ? i18n.following.unfollow : i18n.following.artistHeader}
                  onClick={handleEditFavorites({
                    isFollowed: isArtistInFavs,
                    type: FavoritesTypes.Artists,
                    externalId: data.id,
                    itemName: data.name,
                  })}
                  startIcon={isArtistInFavs ? <Favorite /> : <FavoriteBorder />}
                />
              )}
            </Flex>
          </>
        }
        pageTitle={data.name}
        tags={data.tags}
        image={cloudinaryImages?.[0] ?? artistEvents?.[0]?.fields[HitFields.thumbnail]}
      >
        <Description text={data.bio} title={data.name} />
        <SectionContainer sx={{ display: 'flex', justifyContent: 'center' }} container={true}>
          <SocialLinks links={data} />
        </SectionContainer>
        <SectionContainer
          disabled={
            !cloudinaryImages || !cloudinaryImages.length || !artistEvents || !artistEvents.length
          }
        >
          <Gallery
            isLoading={false}
            photos={
              !!cloudinaryImages?.length
                ? cloudinaryImages
                : artistEvents?.map((event) => event.fields[HitFields.thumbnail])
            }
          />
        </SectionContainer>
      </TwoColumnsLayout>
      <SectionContainer
        id={AnchorLinks.UPCOMING_EVENTS}
        title={i18n.artist.upcomingEvents}
        disabled={!isOnTour}
      >
        <CardDeck>
          {artistEvents?.map((item) => (
            <CloudSearchCard key={item.id} item={item} />
          ))}
        </CardDeck>
        <Link
          align={'center'}
          display={'block'}
          mt={4}
          component={RouterLink}
          to={
            routes[Route.search] +
            qs.stringify(
              {
                [HitFields.artists_names]: data.name,
              },
              { addQueryPrefix: true }
            )
          }
          children={i18n.artist.showMore}
        />
      </SectionContainer>
      {config.theme.isGoing && (
        <EntityRelatedArticles
          isArticlesLoading={isArticlesLoading}
          relatedArticlesData={relatedArticlesData}
        />
      )}
      {/* TODO: add past events from Going. API events endpoint */}
    </Box>
  )
}
