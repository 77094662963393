import React from 'react'

import { Home } from '@mui/icons-material'
import { Link, Breadcrumbs as MuiBreadcrumbs, Skeleton, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import { IBreadcrumbsProps } from './Breadcrumbs.types'

export const Breadcrumbs = ({ base, current, isLoading, component }: IBreadcrumbsProps) => (
  <MuiBreadcrumbs aria-label={'breadcrumb'} separator={'/'}>
    <Link component={RouterLink} to={routes[Route.index]}>
      <Home fontSize={'small'} />
    </Link>
    {base.map((item) =>
      item.link ? (
        <Link
          component={RouterLink}
          to={item.link}
          key={item.link}
          color={'inherit'}
          underline={'hover'}
        >
          <Typography {...{ component }} variant={'inherit'}>
            {item.label}
          </Typography>
        </Link>
      ) : (
        <Typography {...{ component }} variant={'inherit'}>
          {item.label}
        </Typography>
      )
    )}
    {(!!current || isLoading) && (
      <Typography variant={'inherit'}>{isLoading ? <Skeleton width={72} /> : current}</Typography>
    )}
  </MuiBreadcrumbs>
)
