import React from 'react'

import { useTheme } from '@mui/material'
import cn from 'classnames'

import wrapStringLinks from 'misc/helpers/wrapStringLinks'

import styles from './Faq.module.scss'
import useStyles from './Faq.styles'
import { IFaqOwnProps } from './Faq.types'

const Faq = ({ faq, id, isBgDark, color }: IFaqOwnProps) => {
  const theme = useTheme()
  const muiStyles = useStyles(color, isBgDark)(theme)

  return (
    <ul className={cn(styles.faqWrapper, muiStyles.fontColor)}>
      {faq.map((element, index) => {
        const formattedText = wrapStringLinks(element.description)

        return (
          <li className={styles.faqElement} key={index} id={id}>
            <div className={styles.faqTitle}>{element.title}</div>
            <div className={styles.faqBody} dangerouslySetInnerHTML={{ __html: formattedText }} />
          </li>
        )
      })}
    </ul>
  )
}

export default Faq
