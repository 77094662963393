import { defaultMemoize } from 'reselect'
import { bool, object, string } from 'yup'

import { ILangMap } from 'types/Locale'

const personalDataValidation = (lang: ILangMap) => {
  const validators = {
    firstName: string().required(lang.entryList.validation.requiredField),
    lastName: string().required(lang.entryList.validation.requiredField),
    email: string()
      .required(lang.entryList.validation.requiredField)
      .email(lang.entryList.validation.incorrectEmail),
    dateOfBirth: string()
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
        lang.entryList.validation.invalidDate
      )
      .required(lang.entryList.validation.requiredField),
    privacyPolicy: bool().oneOf([true], lang.entryList.validation.privacyPolicy),
  }

  return object().shape(validators)
}

export const makeValidationSchema = (lang: ILangMap) => {
  const validationSchema = () => personalDataValidation(lang)

  return defaultMemoize(validationSchema)
}
