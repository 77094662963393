import * as React from 'react'

import { Chip } from '@mui/material'
import { useRecoilState } from 'recoil'

import { ITagsFilterProps } from 'components/search/TagsFilter/TagsFilter.types'
import { HitFields } from 'constants/CloudSearch'
import { setTagsFilter } from 'state/cloudSearch/selectors/setTagsFilter'
import { ICombinedFacet } from 'types/CloudSearch'

export const TagsFilter = ({ tags, searchParams, tagSlug }: ITagsFilterProps) => {
  const [tagsFilter, setTagsFilterState] = useRecoilState(setTagsFilter)
  const setTag = (tag: ICombinedFacet) => () =>
    setTagsFilterState((state) => [...state, { id: tag.id, name: tag.name, slug: tag.slug }])
  const clearTag = (tagId: string | number) => () =>
    setTagsFilterState((state) => state.filter((el) => el.id !== tagId))

  React.useEffect(() => {
    if (!!searchParams && !searchParams.entries().next().done) {
      const names = searchParams.get(HitFields.tags_names)
      const ids = searchParams.get(HitFields.tags_ids)
      const slugs = searchParams.get(HitFields.tags_slugs)

      if (names && ids) setTagsFilterState([{ name: names, id: ids, slug: slugs }])
      // TODO: add array of tags support
    }
  }, [searchParams])

  React.useEffect(() => {
    if (tagSlug) {
      const tag = tags.find((tag) => tag.slug === tagSlug)
      if (tag && !tagsFilter.length) setTagsFilterState([tag])
    }
  }, [tagSlug, tags])

  return (
    <>
      {tagsFilter.map((tag) => (
        <Chip
          key={'tag_' + tag.id}
          label={tag.name}
          onDelete={clearTag(tag.id)}
          onClick={clearTag(tag.id)}
          size={'small'}
        />
      ))}
      {tags
        .filter((tag) => tagsFilter.every(({ id }) => tag.id !== id))
        .map((tag) => (
          <Chip key={'tag_' + tag.id} label={tag.name} onClick={setTag(tag)} size={'small'} />
        ))}
    </>
  )
}
