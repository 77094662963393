import { Dispatch } from 'react'

import { connect } from 'react-redux'
import { AnyAction } from 'redux'

import _Store from '@Store'

import { productsMounted } from 'models/products/actions'
import { getProducts } from 'models/products/selectors'
import * as CONSTS from 'models/state/constants/constants'
import { getLoading } from 'models/state/selectors'

import Products from './Products.component'
import { IProductsFromDispatch, IProductsFromState } from './Products.types'

const mapStateToProps = (state: _Store.IState): IProductsFromState => ({
  isTransactionLoading: getLoading(CONSTS.TRANSACTION)(state),
  products: getProducts(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IProductsFromDispatch => ({
  productsMounted: () => dispatch(productsMounted()),
})

export default connect<IProductsFromState, IProductsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Products)
