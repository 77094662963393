import * as React from 'react'

import { Button, Typography } from '@mui/material'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { Route, routes } from 'router/routes'

import Brand from 'components/_layout/Brand'
import { Flex } from 'components/_layout/Flex'
import { PageWrapper } from 'components/_layout/PageWrapper'
import Helmet from 'components/helmet/components/StaticPage'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useDictionary } from 'state/locale/hooks/useDictionary'

export const MaintenancePage = () => {
  const { i18n } = useDictionary()
  const navigate = useNavigate()
  const { isMobile, isTablet } = useBreakpoints()
  const location = useLocation()

  const onGoToSearch = () => {
    navigate(routes[Route.search])
  }

  const onGoBack = () => {
    navigate(-1)
  }

  return (
    <PageWrapper>
      <Helmet pageName={i18n.maintenance.featureUnavailable} />
      <Flex
        gap={4}
        align={'center'}
        justify={'center'}
        sx={{ p: 8, backgroundColor: 'background.paper', borderRadius: 4 }}
      >
        <Typography
          textAlign={'center'}
          variant={'h4'}
          children={i18n.maintenance.featureUnavailable}
        />
        <Flex gap={2} direction={'column'}>
          <Button
            children={i18n.maintenance.goToSearch}
            size={'large'}
            variant={'contained'}
            onClick={onGoToSearch}
            fullWidth
          />
          {location.key !== 'default' && (
            <Button
              children={i18n.buttons.back}
              size={'large'}
              variant={'outlined'}
              onClick={onGoBack}
              fullWidth
            />
          )}
        </Flex>
        <Brand />
      </Flex>
    </PageWrapper>
  )
}
