import React, { FC, MouseEvent } from 'react'

import SectionLead from 'components/Bricks/components/LeadSection'
import Card from 'components/reusable/Card'
import { ICard } from 'components/reusable/Card/Card.types'
import fillDataWithEmptyElement from 'misc/helpers/fillDataWithEmptyElement'
import { BricksEnum } from 'models/pages/constants/bricks'
import Analytics from 'services/$analytics'

import styles from './HorizontalList.module.scss'
import { IHorizontalListProps } from './HorizontalList.types'

const DEFAULT_ELEMENTS_NUMBER = Math.floor(Math.random() * 3) + 3

const HorizontalList: FC<IHorizontalListProps> = ({
  background,
  cards,
  color,
  id,
  sectionDescription,
  sectionTitle,
  compositionIdentifier,
}) => {
  const emptyCardsArray = cards.length === 0

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(e, link, textDisplayed || '', componentName)
        : undefined

  if (emptyCardsArray) {
    cards = fillDataWithEmptyElement<ICard>(DEFAULT_ELEMENTS_NUMBER)
  }

  const cardDeck = cards.map((card, index) => (
    <Card
      color={color}
      date={card.date}
      description={card.description}
      highlightTitle={true}
      isSlider={true}
      key={index}
      link={card.link}
      maxInRow={emptyCardsArray ? 3 : cards.length}
      place={card.place}
      placeSlug={card.placeSlug}
      subtitle={card.subtitle}
      thumb={card.thumb}
      title={card.title}
      type={BricksEnum.horizontal_list}
      brickId={id}
      compositionIdentifier={compositionIdentifier}
      onClick={handleAddToDataLayerOnClick(card.link, card.title!, sectionTitle!)}
    />
  ))

  return (
    <>
      <SectionLead
        background={background}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <div className={styles.wrapper} id={id}>
        <ul className={styles.articlesList}>{cardDeck}</ul>
      </div>
    </>
  )
}

export default HorizontalList
