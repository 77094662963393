import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import _Store from '@Store'

import { setExtendedSlot } from 'models/happening/actions'
import { getSelectedExtendedSlot, getSelectedSlot } from 'models/happening/selectors'
import { getTheme } from 'models/theme/selectors'

import ExtendedSlot from './ExtendedSlot.component'
import { IExtendedSlotFromDispatch, IExtendedSlotFromState } from './ExtendedSlot.types'

const mapStateToProps = (state: _Store.IState): IExtendedSlotFromState => ({
  selectedExtendedSlot: getSelectedExtendedSlot(state),
  selectedSlot: getSelectedSlot(state),
  selectedTheme: getTheme(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IExtendedSlotFromDispatch => ({
  resetExtendedSlot: () => dispatch(setExtendedSlot(null)),
})

export default connect<IExtendedSlotFromState, IExtendedSlotFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(ExtendedSlot)
