import { IProduct, IProductSelect } from 'models/products/types'

class ProductSelector {
  public updateSelected(
    selectedProducts: IProductSelect[],
    product: IProduct,
    amount: number,
    poolIds?: number[]
  ): IProductSelect[] {
    const updatedSelected = []
    const alreadySelected = selectedProducts.find((item) => item.id === product.id)

    if (amount === 0) {
      // Already selected product/amount clicked, so just deselect it
      updatedSelected.push(...selectedProducts.filter((item) => item.id !== product.id))
    } else if (alreadySelected?.amount !== amount && alreadySelected?.amount !== 0) {
      // Selected different amount of already picked product,
      // so remove previous selection and add the new one
      updatedSelected.push(...selectedProducts.filter((item) => item.id !== product.id), {
        amount,
        poolIds,
        ...product,
      })
    } else {
      // Selected product which is not already selected - just select it
      updatedSelected.push(...selectedProducts, {
        amount,
        poolIds,
        ...product,
      })
    }

    return updatedSelected
  }
}

export default new ProductSelector()
