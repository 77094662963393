import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      maxWidth: '37.5rem',
    },
  })
)

export default useStyles
