import { ComponentType } from 'react'

import { InputLabel, InputLabelProps, styled } from '@mui/material'

export const Styled = {
  InputLabel: styled(InputLabel)(({ theme }) => ({
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transform: 'translate(6px, 16px) scale(1)',
    zIndex: 1,

    '&.Mui-focused': {
      transform: 'translate(6px, -9px) scale(0.75)',
    },

    '&.MuiInputLabel-shrink': {
      transform: 'translate(6px, -9px) scale(0.75)',
    },
  })) as ComponentType<InputLabelProps>,
}
