import { Component } from 'react'

import ReactDOM from 'react-dom'

class Portal extends Component {
  private readonly wrapper = typeof document !== 'undefined' && document.createElement('div')
  private readonly root = typeof document !== 'undefined' && document.body

  public componentDidMount() {
    if (this.root && this.wrapper) {
      this.root.appendChild(this.wrapper)
    }
  }

  public componentWillUnmount() {
    if (this.root && this.wrapper) {
      this.root.removeChild(this.wrapper)
    }
  }

  public render() {
    if (this.wrapper) {
      return ReactDOM.createPortal(this.props.children, this.wrapper)
    }

    return null
  }
}

export default Portal
