import { IBasket } from 'models/basket/types'
import { IGetEventByIdSuccessPayload } from 'models/event/types'
import { IAvailableThemes } from 'models/theme/types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import * as CONSTS from './../constants/actions'
import {
  IInternalRouterData,
  IInternalRouterEmbedInitial,
  IInternalRouterEvent,
  NavigateParams,
} from './../types'

export const locationChange = createStandardAction(CONSTS.LOCATION_CHANGE)<IInternalRouterData>()

export const captureIframeEvent = createStandardAction(
  CONSTS.CAPTURE_EVENT_FROM_IFRAME
)<IInternalRouterEvent>()

export const initEmbed = createStandardAction(CONSTS.INIT_EMBED)<IInternalRouterEmbedInitial>()

export const embedLoaded = createStandardAction(CONSTS.EMBED_LOADED)()

export const resizeEmbed = createStandardAction(CONSTS.RESIZE_EMBED)<number>()

export const navigate = createStandardAction(CONSTS.NAVIGATE)<NavigateParams>()

export const redirectParentTo = createStandardAction(CONSTS.REDIRECT_PARENT_TO)<string>()

export const addToGlobalBasket = createStandardAction(CONSTS.ADD_TO_GLOBAL_BASKET)<IBasket[]>()

export const setBasketTheme = createStandardAction(CONSTS.SET_BASKET_THEME)<IAvailableThemes>()

export const clearEmbedInitTime = createStandardAction(CONSTS.CLEAR_EMBED_INIT_TIME)()

export const getRundateSlug = createAsyncAction(
  CONSTS._GET_RUNDATE_SLUG_REQUEST,
  CONSTS._GET_RUNDATE_SLUG_SUCCESS,
  CONSTS._GET_RUNDATE_SLUG_FAILURE
)<number, IGetEventByIdSuccessPayload, Error>()
