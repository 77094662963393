import * as React from 'react'

import qs from 'qs'
import { useParams } from 'react-router-dom'
import { Route, routes } from 'router/routes'
import { RouteParams } from 'router/types'
import useSWR from 'swr'

import { ActionBar } from 'components/ActionBar'
import { ActionBarType } from 'components/ActionBar/ActionBar.types'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Rundate } from 'components/Rundate'
import { PageWrapper } from 'components/_layout/PageWrapper'
import { TwoColumnsSkeleton } from 'components/_skeleton/TwoColumnsSkeleton'
import Helmet from 'components/helmet/Helmet'
import { HitFields, SearchTypes } from 'constants/CloudSearch'
import { useBreakpoints } from 'misc/hooks/useBreakpoints'
import { useIframeParamsFromStore } from 'models/internalRouter/hooks/useIframeParamsFromStore'
import Analytics from 'services/$analytics'
import { EventApi } from 'services/Api/EventApi'
import { GoingMoreApi } from 'services/Api/GoingMoreApi'
import { DataMutations } from 'services/DataMutations'
import { useDictionary } from 'state/locale/hooks/useDictionary'
import { useLang } from 'state/locale/hooks/useLang'

export const RundatePage = () => {
  const { eventSlug, rundateSlug } = useParams<RouteParams<Route.rundate>>()
  const { code } = useLang()
  const { data, isLoading } = useSWR([eventSlug, rundateSlug, code], EventApi.getFullRundate, {})
  const { data: relatedArticlesData, isLoading: isArticlesLoading } = useSWR(
    !!data && `event-${data?.eventData.rundate.event.id}`,
    GoingMoreApi.GetPostsByTagSlug
  )
  const { i18n } = useDictionary()
  const iframeParams = useIframeParamsFromStore()
  const { isDesktop } = useBreakpoints()

  React.useEffect(() => {
    if (data?.eventData?.rundate.id) {
      Analytics.pushForViewEvent(data.eventData.rundate, !!iframeParams, iframeParams?.currentUrl)
    }
  }, [data?.eventData?.rundate.id])

  return (
    <PageWrapper>
      {!!data?.eventData && (
        <Helmet {...DataMutations.rundateHelmetMeta(data.eventData.rundate, i18n)} />
      )}
      <Breadcrumbs
        base={[
          {
            link:
              routes[Route.search] +
              qs.stringify({ [HitFields.type]: SearchTypes.RUNDATE }, { addQueryPrefix: true }),
            label: i18n.breadcrumbs.events,
          },
        ]}
        current={data?.eventData.rundate.event.title}
        isLoading={isLoading}
      />
      {isLoading ? (
        <TwoColumnsSkeleton />
      ) : (
        !!data && (
          <Rundate
            eventData={data.eventData}
            placeEvents={data.placeEvents}
            similarEvents={data.similarEvents}
            cloudinaryImages={data.cloudinaryImages}
            relatedArticlesData={relatedArticlesData}
            isArticlesLoading={isArticlesLoading}
          />
        )
      )}
      {!isDesktop && <ActionBar data={data?.eventData.rundate} type={ActionBarType.EVENT} />}
    </PageWrapper>
  )
}
