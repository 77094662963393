import * as React from 'react'

import { Grid, useTheme } from '@mui/material'
import cn from 'classnames'
import { useDispatch } from 'react-redux'

import { FollowAfterPurchase } from 'components/FollowAfterPurchase'
import { TradeDoubler } from 'components/payment/Success/components/TradeDoubler'
import { successSummaryMounted, unmount } from 'models/transaction/actions'

import classes from './Success.classes'
import useStyles from './Success.styles'
import DownloadSection from './components/DownloadSection'
import SellSuccessHeader from './components/SellSuccessHeader'
import SellSuccessInfo from './components/SellSuccessInfo'
import { SummaryAfterSellSegment } from './components/SummaryAfterSellSegment'

export const PaymentSuccess = () => {
  const theme = useTheme()
  const muiStyles = useStyles(theme)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(successSummaryMounted())

    return () => {
      dispatch(unmount())
    }
  }, [])

  return (
    <>
      <TradeDoubler />
      <FollowAfterPurchase />
      <Grid container={true} className={cn(muiStyles.wrapper, classes.successContainer)}>
        <Grid
          item={true}
          xs={12}
          className={cn(muiStyles.containerMargin, classes.sellSuccessHeader)}
        >
          <SellSuccessHeader />
        </Grid>
        <Grid
          item={true}
          xs={12}
          className={cn(muiStyles.containerMargin, classes.sellSuccessInfo)}
        >
          <SellSuccessInfo />
        </Grid>
        <Grid
          item={true}
          xs={12}
          className={cn(muiStyles.containerMargin, classes.summaryAfterSellSegment)}
        >
          <SummaryAfterSellSegment />
        </Grid>
        <Grid item={true} className={cn(muiStyles.marginForLastComponent, classes.downloadSection)}>
          <DownloadSection />
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentSuccess
