import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: theme.palette.text.disabled,
      borderRadius: theme.spacing(1),
      marginRight: '0.125rem !important',
      opacity: 1,
      [theme.breakpoints.up('sm')]: {
        width: '0.375rem',
        height: '0.375rem',
        borderRadius: theme.spacing(1),
      },
    },
    active: {
      backgroundColor: theme.palette.primary.dark,
    },
    activeDefaultTheme: {
      backgroundColor: theme.palette.primary.contrastText,
    },
  })
)

export default useStyles
