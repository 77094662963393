import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      marginTop: '2rem',
    },
  })
)

export default useStyles
