import { from as from$, of as of$ } from 'rxjs'
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators'

import _Store from '@Store'

import { getUserEmail } from 'models/auth/selectors'
import { isActionOf } from 'typesafe-actions'

import { checkEmail, generateAuthToken, submitEmailCheck } from './../actions'

export const emailCheckInit: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(checkEmail)),
    map$((action) => submitEmailCheck.request())
  )

export const emailCheckSubmit: _Store.IEpic = (action$, state$, { authApi }) =>
  action$.pipe(
    filter$(isActionOf(submitEmailCheck.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) =>
      from$(authApi.checkEmail(getUserEmail(state))).pipe(
        mergeMap$((data) => {
          if (data.user_exists) {
            return [submitEmailCheck.success(data), generateAuthToken.request()]
          }

          return [submitEmailCheck.success(data)]
        }),
        catchError$((error) => of$(submitEmailCheck.failure(error)))
      )
    )
  )

export const generateToken: _Store.IEpic = (action$, state$, { authApi }) =>
  action$.pipe(
    filter$(isActionOf(generateAuthToken.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) =>
      from$(authApi.generateToken(getUserEmail(state))).pipe(
        mergeMap$((data) => of$(generateAuthToken.success(data))),
        catchError$((error) => of$(generateAuthToken.failure(error)))
      )
    )
  )
