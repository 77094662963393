import { createSelector } from 'reselect'

import { Languages } from 'constants/Languages'
import getLocalizedDescription from 'misc/helpers/getLocalizedDescription'

import getData from './getData'

const getDescription = createSelector(
  [getData],
  (happening) => getLocalizedDescription(happening?.descriptions, Languages.Polski) // TODO: add and get from storage
)

export default getDescription
