import { useRecoilValue } from 'recoil'

import { translate } from 'state/locale/selectors/translate'
import {
  DictionaryKeys,
  getExternalDictionary,
} from 'state/remoteConfig/selectors/getExternalDictionary'

/**
 * Use i18n - translated in-app texts dictionary.
 */
export const useDictionary = () => {
  const i18n = useRecoilValue(translate)
  const externalDictionary = getExternalDictionary()

  /**
   * Fetches external (remote config) dictionary key for the current language.
   * @param key
   */
  const getExternalKey = (key: DictionaryKeys) => {
    try {
      return externalDictionary[key][i18n._lang]
    } catch (e) {
      return null
    }
  }

  return {
    i18n,
    getExternalKey,
  }
}
