/**
 * Function that checks if provided color is dark or light
 *
 * @param {string} color - color to check
 *
 * @return {boolean} true if the color is dark
 */
const isColorDark = (color: any) => {
  let r
  let g
  let b
  let hsp

  try {
    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
      // tslint:disable:no-bitwise
      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }
  } catch (colorUndefined) {
    return false
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  return hsp <= 127.5
}

export default isColorDark
