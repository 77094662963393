const theme = {
  /*
   * Default theme
   */
  isEmpik: process.env.THEME === 'empik',
  isGoing: process.env.THEME === 'going',

  name: process.env.THEME,
}

export default theme
