import { getType } from 'typesafe-actions'

import { getHappening } from './../../actions'
import { IAction, IHappeningDataReducer } from './../../types'

const reducer = (state: IHappeningDataReducer = null, action: IAction): IHappeningDataReducer => {
  switch (action.type) {
    case getType(getHappening.success):
      return action.payload

    default:
      return state
  }
}

export default reducer
