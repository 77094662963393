/**
 * Function to check if object has atributes
 *
 * @param {Object} object
 *
 * @return {boolean} is empty object
 *
 */
const isEmptyObject = (object: any) => {
  return object && Object.keys(object).length === 0
}

export default isEmptyObject
