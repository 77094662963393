import getIdempotencyKey from 'misc/helpers/happening/getIdempotencyKey'
import { generateIdempotencyKey } from 'models/happening/actions'
import { IAction, IHappeningMetadata } from 'models/happening/types'
import { getType } from 'typesafe-actions'

const initialState: IHappeningMetadata = {
  idempotencyKey: getIdempotencyKey(),
}

const reducer = (state: IHappeningMetadata = initialState, action: IAction): IHappeningMetadata => {
  switch (action.type) {
    case getType(generateIdempotencyKey):
      return {
        idempotencyKey: getIdempotencyKey(),
      }
    default:
      return state
  }
}

export default reducer
