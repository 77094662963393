import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      gap: theme.spacing(3),
      width: '100%',
      paddingTop: 0,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
        paddingTop: 0,
        gap: theme.spacing(5),
        width: '37.5rem',
      },
    },
    border: {
      backgroundColor: theme.palette.secondary.contrastText,
      border: `${theme.border.width} ${theme.border.style} ${theme.border.color}`,
      borderRadius: theme.border.radius,
    },
    title: {
      fontWeight: '700',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.75rem',
      },
    },
    subtitle: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
      fontWeight: 400,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
    },
    header: {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  })
)

export default useStyles
